import * as apiSW from 'api/tag/GetTagStartingWithV1';

export default class TagCategory {
	public id: string = '';
	public name: string = '';

	public constructor(input?: apiSW.TagCategory) {
		if (input) {
			this.id = input.id;
			this.name = input.name;
		}
	}
}
