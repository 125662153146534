import axios from 'axios';

import { NutriScoreCategoryBaseIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryBaseIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(
	nutrientId: string,
	pointType: NutriScoreCategoryBaseIngredientCategory
): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/NutriScoreBaseIngredientCategoryPoints/${nutrientId}`;
	return axios
		.get(url, {
			params: {
				PointType: pointType,
			},
		})
		.toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	baseIngredientCategoryId: string;
	pointType: NutriScoreCategoryBaseIngredientCategory;
	baseIngredientCategoryName: string | null;
	isPositive: boolean;
	points: Point[] | null;
};

export type Point = {
	points: number;
	minValue: number | null;
};
