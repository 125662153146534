import * as apiSW from 'api/unit/GetUnitStartingWithV1';
import * as apiPostSearch from 'api/unit/PostUnitSearchV1';
import Unit from 'classes/StandingData/Unit/Search/Unit';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class UnitList {
	public all: Unit[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const units: apiSW.ResponseUnit[] = await this.callApiSW(searchTerm, showOnlyDeleted);
		this.mapFromApiSW(units);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseUnit[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const unit: Unit = new Unit(i);
			this.all.push(unit);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseUnit[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
