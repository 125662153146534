import * as apiGet from 'api/menu/GetMenuIdV1';
import MenuRecipe from 'classes/MenuPlan/Detail/MenuRecipe';

export default class MenuRecipeList {
	public all: MenuRecipe[] = [];

	public constructor(input?: apiGet.Recipe[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiGet.Recipe[] | null) {
		if (input) {
			for (const i of input) {
				const allergen: MenuRecipe = new MenuRecipe(i);
				this.all.push(allergen);
			}
		}
	}

	public getNutrientPerPart(nutrientValue: number): number {
		let result: number = 0;
		this.all.forEach((recipe: MenuRecipe) => {
			const parts = recipe.parts ?? 0;
			if (parts <= 0) {
				result += 0;
			} else {
				result += nutrientValue / parts;
			}
		});
		return result;
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		let result: number = 0;
		this.all.forEach((recipe: MenuRecipe) => {
			const persons = recipe.persons ?? 0;
			if (persons <= 0) {
				result += 0;
			} else {
				result += nutrientValue / persons;
			}
		});
		return result;
	}

	public getNutrientTotal(nutrientValue: number): number {
		return nutrientValue;
	}
}
