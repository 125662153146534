import Archive from 'containers/desktop/StandingData/Tag/Archive/TagArchive';
import Management from 'containers/desktop/StandingData/Tag/Management/TagManagement';
import PERMISSIONS from 'enums/permissions';

export const tagRoutes = [
	{
		path: '/tag/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/tag/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
