import { useState } from 'react';
import { useEffect } from 'react';

import delay from 'functions/delay';
import SidebarState from 'types/_general/SidebarState';

export default function useClassNameForVisibilityChange(sidebarState: SidebarState): string {
	const [visible, _setVisible] = useState<string>('visible');

	useEffect(() => {
		setVisible();
	}, [sidebarState]);

	const setVisible = async (): Promise<void> => {
		await delay(200);
		if (sidebarState === 'closed') {
			_setVisible('');
		} else {
			_setVisible('visible');
		}
	};
	return visible;
}
