import MenuPlanSearchComponent from 'components/desktop/MenuPlan/Search/MenuPlanSearchComponent';

const MenuPlanSearchContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<MenuPlanSearchComponent />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuPlanSearchContainer;
