import * as apiDelete from 'api/accessory/DeleteAccessoryIdV1';
import * as apiRestore from 'api/accessory/PostAccessoryRestoreV1';
import * as apiPostSearch from 'api/accessory/PostAccessorySearchV1';
import CarbonDioxideLabel from 'classes/Accessory/Search/CarbonDioxideLabel';
import CategoryList from 'classes/Accessory/Search/CategoryList';
import Image from 'classes/Accessory/Search/Image';
import TagList from 'classes/Accessory/Search/TagList';

export default class Accessory {
	public id: string = '';
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public carbonDioxideValue: number | null = null;
	public categories: CategoryList = new CategoryList();
	public countOfRecipes: number = 0;
	public image: Image | null = new Image();
	public lastEditedDateUtc: string = '';
	public lastEditor: string | null = null;
	public name: string | null = '';
	public price: number | null = null;
	public tags: TagList = new TagList();

	public constructor(input?: apiPostSearch.Accessory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.Accessory) {
		this.id = input.id;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.categories.mapFromApi(input.categories);
		this.countOfRecipes = input.countOfRecipes;
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.lastEditor = input.lastEditor;
		this.name = input.name;
		this.price = input.price;
		this.tags.mapFromApi(input.tags);

		if (input.image) {
			this.image = new Image();
			this.image.mapFromApi(input.image);
		} else {
			this.image = null;
		}
	}
}
