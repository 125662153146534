import * as State from 'components/desktop/Recipe/Validation/RecipeValidationState';
import { useHandler } from 'hooks/useHandler';

export default function useRecipeValidationState() {
	const [state, rf] = useHandler<State.Type>(State.create());
	return {
		state,
		handlers: {
			changeId: rf.createReducer(State.changeId),
			updateOne: rf.createReducer(State.updateOne),
			updateAll: rf.createReducer(State.updateAll),
		},
	};
}
