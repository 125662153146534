import * as apiGet from 'api/status/GetAllStatusV1';
import Status from 'classes/MenuPlan/Detail/Status';
import { SelectOption } from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { ToggleItem } from 'types/_general/ToggleItem';

export default class StatusList {
	public all: Status[] = [];

	public constructor(input?: apiGet.ResponseStatus[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiGet(): Promise<void> {
		const response = await apiGet.callApi();
		response.hasValue() && this.mapFromApi(response.get());
	}

	public mapFromApi(input: apiGet.ResponseStatus[] | null) {
		this.all = [];
		if (input) {
			for (const i of input) {
				const status: Status = new Status(i);
				this.all.push(status);
			}
		}
	}

	public getById(id: string): Status | undefined {
		return this.all.find((e: Status) => {
			return e.id === id;
		});
	}

	public mapToSelectOptionArray(): SelectOption[] {
		return this.all.map((e: Status) => {
			return {
				id: e.id,
				text: e.name ?? '',
			};
		});
	}

	public mapToToggleItemList(): ToggleItem[] {
		return this.all.map((x) => {
			return {
				id: x.id,
				label: x.name ?? '',
				checked: true,
			};
		});
	}
}
