import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRef } from 'react';

import 'components/desktop/Ingredient/IngredientProducts/ProductCardSlider/ProductCardSlider.scss';
import { SliderCard } from 'components/desktop/_general/CardSlider/CardSlider';

interface IProps {
	cards: SliderCard[];
}

const ProductCardSlider = (props: IProps) => {
	const sliderRef = useRef<HTMLDivElement>(null);

	const slideLeft = () => {
		if (sliderRef.current) sliderRef.current.scrollLeft -= 500;
	};

	const slideRight = () => {
		if (sliderRef.current) sliderRef.current.scrollLeft += 500;
	};

	return (
		<div className="card-slider-container">
			{props.cards.length > 0 && (
				<>
					<div className="card-slider-icon" onClick={slideLeft}>
						<FontAwesomeIcon icon={faChevronLeft} size="2x" />
					</div>
					<div className="card-slider" ref={sliderRef}>
						{props.cards.map((element, index) => (
							<React.Fragment key={index}>{element.content}</React.Fragment>
						))}
					</div>
					<div className="card-slider-icon" onClick={slideRight}>
						<FontAwesomeIcon icon={faChevronRight} size="2x" />
					</div>
				</>
			)}
		</div>
	);
};

export default ProductCardSlider;
