import { NutriscoreContext } from 'context/nutriscoreLabelContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram.scss';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	nutriScore: NutriScore | null;
}

const NutriScoreDiagram = (props: IProps) => {
	const { t } = useTranslation();
	const labels = useContext(NutriscoreContext);
	const labelList = labels.nutriscoreLabels.map((x) => x.label).distinct();

	const renderLabel = (label: string, key: number): JSX.Element => {
		if (label == props.nutriScore?.label) {
			return (
				<div
					className="nutri-score-diagram-score score"
					style={{ backgroundColor: props.nutriScore.color }}
					key={key}
				>
					<span className="nutri-score-label">{label}</span>
				</div>
			);
		} else {
			return (
				<div className="nutri-score-diagram-score score" key={key}>
					<span className="nutri-score-label">{label}</span>
				</div>
			);
		}
	};

	return (
		<div className="nutri-score-diagram-container-outer">
			<label>{t('_general:NUTRI_SCORE')}</label>
			<div className="nutri-score-diagram-container-inner">
				{labelList.map((l: string, key: number) => renderLabel(l, key))}
			</div>
		</div>
	);
};

export default NutriScoreDiagram;
