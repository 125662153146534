import axios from 'axios';

import { ConstraintStatus } from 'enums/ConstraintStatus';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function apiCall(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/MenuPlanner/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	allergenIds?: AllergenLogicMap | null;
	caloriesConstraintStatus?: ConstraintStatus[];
	caloriesRange?: Range | null;
	carbonDioxideLabelIds?: string[] | null;
	carbonDioxideRange?: Range | null;
	menuIds?: string[] | null;
	nutrientRanges?: NutrientRange[] | null;
	pageIndex?: number | null;
	pageSize?: number | null;
	priceConstraintStatus?: ConstraintStatus[];
	priceRange?: Range | null;
	recipeIds?: string[] | null;
	searchTerm?: string | null;
	seasonIds?: string[] | null;
	showOnlyDeleted?: boolean | null;
	sortOrder?: string | null;
	startDateRange?: RangeDate;
	statusIds?: string[] | null;
	systemStatus?: SystemStatus[] | null;
	tagIds?: string[] | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: MenuPlanner[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxCaloriesRange: Range;
	minMaxCarbonDioxideRange: Range;
	minMaxPriceRange: Range;
	minMaxStartingDateRange: RangeDate;
	minMaxNutrientRanges: MinMaxNutrientRange[];
};

export type MinMaxNutrientRange = {
	nutrientInfo: NutrientInfo;
	range: Range;
};

export type SortOrder =
	| 'NameAsc'
	| 'NameDesc'
	| 'LastEditedDateAsc'
	| 'LastEditedDateDesc'
	| 'PriceAsc'
	| 'PriceDesc'
	| 'CaloriesAsc'
	| 'CaloriesDesc'
	| 'CarbonDioxideAsc'
	| 'CarbonDioxideDesc';

export type NutrientInfo = {
	id: string;
	sortOrder: number;
	name: string;
	unitShortNameSingular: string;
	nutrientCategory: NutrientCategory;
};

export type NutrientCategory = {
	id: string;
	name: string;
};

export type Status = {
	id: string;
	color: string;
	order: number;
	name: string;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type Season = {
	id: string;
	iconSvgUrl: string;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string;
	name: string;
	display: number;
	logic: string;
};

export type Tag = {
	id: string;
	iconSvgUrl: string;
	display: boolean;
	name: string;
	tagCategory: TagCategory | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type PrepareTime = {
	timeUnit: string;
	timeValue: number;
};

export type RecipeStatus = {
	id: string;
	color: string;
	order: number;
	name: string;
};

export type MenuPlanner = {
	id: string;
	status: Status;
	lastEditor: string;
	title: string;
	lastEditedDateUtc: string;
	caloriesLimit: number;
	priceLimit: number;
	isPriceConstraintMet: ConstraintStatus;
	isCaloriesConstraintMet: ConstraintStatus;
	startDate: string;
	durationDays: number;
	carbonDioxideValue: number;
	carbonDioxideLabel: CarbonDioxideLabel;
	rows: Row[];
	seasons: Season[];
	tags: Tag[];
};

export type Row = {
	name: string;
	items: RowItem;
};

export type RowItem = {
	[key: string]: string;
};

export type RangeDate = {
	minimum: string | null;
	maximum: string | null;
};

export type Range = {
	minimum: number | null;
	maximum: number | null;
};

export type NutrientRange = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};
