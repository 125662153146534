import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/TimeUnit';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	factorToSecond: number;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	shortNamePluralTranslations: Translation;
	shortNameSingularTranslations: Translation;
}

export type Translation = {
	[key: string]: string;
};

export interface Response {
	data: string;
}
