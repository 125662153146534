import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';
import { AllergenLogic } from 'enums/allergenLogic';

export default class Allergen {
	public id: string = '';
	public iconSvgUrl: string = '';
	public name: string = '';
	public display: boolean = false;
	public logic: AllergenLogic = AllergenLogic.Positive;

	public constructor(input?: postMenuSearchV1.Allergen) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: postMenuSearchV1.Allergen): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl ?? '';
		this.name = input.name ?? '';
		this.display = input.display;
		this.logic = input.logic;
	}
}
