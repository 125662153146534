import * as api from 'api/menu/PostMenuSearchV1';
import Tag from 'classes/Menu/Search/Tag';
import { Icon } from 'types/Icon/Icon';

export default class TagList {
	public all: Tag[] = [];

	public constructor(input?: api.Tag[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): (Icon | null)[] {
		return this.all.map((e: Tag) => {
			return e.mapToIcon();
		});
	}

	public mapFromApi(input: api.Tag[] | null) {
		if (input) {
			for (const i of input) {
				const tag: Tag = new Tag(i);
				this.all.push(tag);
			}
		}
	}
}
