import { Dispatch, SetStateAction } from 'react';

export const createHandler = <T, K>(
	dispatch: Dispatch<SetStateAction<T>>,
	f: (t: T, k: K) => T
) => {
	return (k: K): void => {
		dispatch((t: T) => {
			return f({ ...t }, k);
		});
	};
};

export const createHandlerAsync = <T, K>(
	dispatch: Dispatch<SetStateAction<T>>,
	reducer: (state: T, payload?: K) => Promise<T>
) => {
	const createPromise = (abc?: K) =>
		new Promise((resolve) => {
			const dispatchLater = async (t: T, k?: K) => {
				const newData = await reducer(t, k);
				dispatch((_t: T) => {
					resolve(newData);
					return newData;
				});
				return newData;
			};

			dispatch((t: T) => {
				dispatchLater(t, abc);
				return t;
			});
		});

	return async (k?: K): Promise<void> => {
		await createPromise(k);
	};
};
