import * as apiSW from 'api/baseIngredientCategory/GetBaseIngredientCategoryStartingWithV1';
import * as apiPostSearch from 'api/baseIngredientCategory/PostBaseIngredientCategorySearchV1';
import BaseIngredientCategory from 'classes/StandingData/BaseIngredientCategory/Search/BaseIngredientCategory';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class BaseIngredientCategoryList {
	public all: BaseIngredientCategory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const baseIngredientCategories = await apiSW.callApi(searchTerm, showOnlyDeleted);
		baseIngredientCategories.hasValue() && this.mapFromApiSW(baseIngredientCategories.get());
	}

	public async callApi(
		input: apiPostSearch.Request
	): Promise<Optional<apiPostSearch.ResponseData>> {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const baseIngredientCategory: BaseIngredientCategory = new BaseIngredientCategory(i);
			this.all.push(baseIngredientCategory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseCategory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
