export type NutriScore = {
	label: string;
	color: string;
};

export const defaultNutriScore = (): NutriScore => {
	return {
		label: '',
		color: '',
	};
};
