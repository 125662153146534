import { t } from 'i18next';

import CharacteristicToggle from 'classes/_general/Search/Characteristic/CharacteristicToggle';
import CharacteristicToggleList from 'classes/_general/Search/Characteristic/CharacteristicToggleList';
import Toggle from 'components/desktop/_general/Toggle/Toggle';

interface IProps {
	characteristics: CharacteristicToggleList;
	handleChange: () => void;
}

const CharacteristicToggleSelect = (props: IProps) => {
	const handleToggle = (toggle: CharacteristicToggle): void => {
		toggle.checked = !toggle.checked;
		if (props.characteristics.everyItemIsFalse()) {
			props.characteristics.reset();
		}
		props.handleChange();
	};

	const renderToggle = (toggle: CharacteristicToggle) => {
		return (
			<Toggle
				checked={toggle.checked}
				elementId={toggle.id}
				handleToggle={() => handleToggle(toggle)}
				label={`characteristic:${toggle.name}`}
			/>
		);
	};

	return (
		<div>
			<label>{t('_general:CHARACTERISTIC')}</label>
			{props.characteristics.all.map((e: CharacteristicToggle, i: number) => {
				return (
					<div key={i} className="mt-1">
						{renderToggle(e)}
					</div>
				);
			})}
		</div>
	);
};

export default CharacteristicToggleSelect;
