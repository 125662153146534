import { t } from 'i18next';
import { useMemo, useState } from 'react';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { enforceNonNullable } from 'functions/functionExtensions';
import { isUserWriter } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RecipeCategoryLight } from 'types/RecipeCategory/RecipeCategoryLight';

interface IProps {
	allRecipeCategories: RecipeCategoryLight[];
	recipeCategories: RecipeCategoryLight[];
	setRecipeCategories: (recipeCategories: RecipeCategoryLight[]) => void;
}

const CategoryEditor = (props: IProps) => {
	const readOnly: boolean = !isUserWriter();
	const [filteredCategories, setFilteredCategories] = useState<RecipeCategoryLight[]>([]);

	const allCategoriesById = useMemo(() => {
		return props.allRecipeCategories.toDictionary(
			(k) => k.id,
			(k) => k
		);
	}, [props.allRecipeCategories]);

	function getCategoryById(id: string): RecipeCategoryLight | null {
		return allCategoriesById[id];
	}

	const handleAddCategory = (savedItem: ISelectItem): void => {
		var recipeCategoriesCpy = [...props.recipeCategories];
		var category = savedItem as RecipeCategoryLight;
		var isFound = recipeCategoriesCpy.some((element) => element.id == category.id);

		if (!isFound) {
			recipeCategoriesCpy.push({
				id: category.id,
				name: category.name,
			} as RecipeCategoryLight);
		}

		props.setRecipeCategories(recipeCategoriesCpy);
	};

	const createHandleDeleteCategory = (id: string): (() => void) | undefined => {
		if (readOnly) return undefined;
		return () => {
			const recipeCategoriesCpy: RecipeCategoryLight[] = props.recipeCategories.filter(
				(category: RecipeCategoryLight) => {
					if (category.id !== id) {
						return category;
					}
				}
			);
			props.setRecipeCategories(recipeCategoriesCpy);
		};
	};

	const filterCategoriesBySearchTerm = (searchTerm: string): void => {
		const categoryIds: string[] = props.recipeCategories.map((e: RecipeCategoryLight) => e.id);

		const categories: RecipeCategoryLight[] = props.allRecipeCategories
			.filter((category) => category.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
			.filter((category) => !categoryIds.includes(category.id))
			.map((category) => {
				return { ...category, name: category.name };
			});

		setFilteredCategories(categories);
	};

	return (
		<>
			{!readOnly && (
				<div className="form-group">
					<label>{t('_general:RECIPE_CATEGORIES')}</label>
					<div className="input-group">
						<SuggestionSearchInput
							className="form-control"
							handleChangeSearchTerm={filterCategoriesBySearchTerm}
							setSavedItem={handleAddCategory}
							triggerSearchLetterAmount={0}
							suggestedItems={filteredCategories}
							debounceMilliSeconds={500}
							errorKey="RecipeCategories"
						/>
					</div>
				</div>
			)}
			<div className="overflow-auto">
				{props.recipeCategories
					.map((x) => getCategoryById(x.id))
					.keep(enforceNonNullable)
					.map((category: RecipeCategoryLight, i: number) => (
						<ListItem
							key={i}
							text={category.name}
							handleDelete={createHandleDeleteCategory(category.id)}
						/>
					))}
			</div>
			{readOnly && props.recipeCategories.length === 0 && (
				<>
					<span className="grey">{t('_general:NO_CATEGORIES')}</span>
				</>
			)}
		</>
	);
};

export default CategoryEditor;
