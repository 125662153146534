import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/UserAvatar/UserAvatar.scss';
import PERMISSIONS from 'enums/permissions';
import { logout } from 'functions/tokenFunctions';

interface IProps {
	userName: string;
	path: string;
}

const UserAvatar: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	const getPath = (): string => {
		if (props.path) {
			return props.path;
		} else {
			return '/img/navigation/default_user_icon.jpg';
		}
	};

	return (
		<>
			<li className="nav-item dropdown" style={{ listStyle: 'none', padding: '0px' }}>
				<div
					className="nav-link d-flex align-items-center"
					id="navbarDropdown"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<img alt="User Avatar" className="navigation-user-img" src={getPath()} />
					<span style={{ marginLeft: '8px', color: 'rgba(0,0,0,.7)' }}>
						{props.userName}
					</span>
				</div>
				<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
					<li>
						<NavLink to="/user/settings" className="dropdown-item">
							{t('_general:SETTINGS')}
						</NavLink>
					</li>
					<RenderIf permissions={[PERMISSIONS.ADMINISTRATE]}>
						<li>
							<NavLink to="/systemInfo" className="dropdown-item">
								{t('_general:SYSTEM_INFO')}
							</NavLink>
						</li>
					</RenderIf>
					<li>
						<div className="dropdown-item" onClick={logout}>
							{t('header:LOGOUT')}
						</div>
					</li>
				</ul>
			</li>
		</>
	);
};

export default UserAvatar;
