import Management from 'containers/desktop/StandingData/NutriScoreNutrients/NutriScoreNutrientManagement';
import PERMISSIONS from 'enums/permissions';

export const nutriScoreNutrientsRoutes = [
	{
		path: '/nutriScoreNutrients',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
