type Input = {
	url: string | null;
	availableSizes: number[] | null;
};

export function getPicturePath(image: Input | null, minWidth: number | 'max'): string {
	if (image === null) return '';
	if (!image.url) return '';
	if (minWidth === 'max') return image.url;
	if (!image.availableSizes) return image.url;

	const pathInfos: PathInfos = getPathInfos(image.url);
	for (const width of image.availableSizes) {
		if (width >= minWidth) {
			return pathInfos.basePath + '_' + String(width) + '.' + pathInfos.suffix;
		}
	}
	return image.url;
}

function getPathInfos(input: string): PathInfos {
	const stringArray: string[] = input.split('.');
	const basePath: string = stringArray.slice(0, -1).join('.');
	const suffix: string = stringArray[stringArray.length - 1];
	return {
		basePath: basePath,
		suffix: suffix,
	};
}

type PathInfos = {
	basePath: string;
	suffix: string;
};
