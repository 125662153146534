import axios from 'axios';

import { IngredientSortOrder } from 'enums/SortOrder/IngredientSortOrder';
import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/Ingredient/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	allergenIds?: AllergenLogicMap | null;
	baseIngredient?: string | null;
	caloriesRange?: Range | null;
	carbonDioxideLabelIds?: string[] | null;
	carbonDioxideRange?: Range | null;
	categories?: string[] | null;
	characteristics?: string[] | null;
	nutriScoreLabelIds?: string[] | null;
	nutrientRanges?: NutrientRange[] | null;
	pageIndex?: number | null;
	pageSize?: number | null;
	priceRange?: Range | null;
	searchTerm?: string | null;
	seasonIds?: string[] | null;
	showOnlyDeleted?: boolean | null;
	sortOrder?: IngredientSortOrder | null;
	tagIds?: string[] | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseIngredient[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxCaloriesRange: Range;
	minMaxCarbonDioxideRange: Range;
	minMaxPriceRange: Range;
	minMaxNutrientRanges: ResponseNutrient[];
};

export type ResponseIngredient = {
	id: string;
	allergens: Allergen[];
	calories: number | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number | null;
	categories: IngredientCategory[];
	characteristic: Characteristic;
	countOfRecipes: number;
	density: number | null;
	nameSingularTranslation: string;
	hasNutriScore: boolean;
	image: Image | null;
	lastEditedDateUtc: string;
	lastEditor: string | null;
	nutriScore: NutriScore;
	price: number | null;
	seasons: Season[];
	tags: Tag[];
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	associatedMonthsFlag: number | null;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	logic: AllergenLogic;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string | null;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string | null;
};

export type IngredientCategory = {
	id: string;
	name: string;
	nutriScoreCategory: NutriScoreCategory;
};

export type ResponseNutrient = {
	nutrientInfo: {
		id: string;
		sortOrder: number;
		name: string;
		unitShortNameSingular: string;
		nutrientCategory: {
			id: string;
			name: string;
		};
	};
	range: Range;
};

export type CarbonDioxideLabel = {
	color: string;
	label: string;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

type Range = {
	minimum: number | null;
	maximum: number | null;
};

type NutrientRange = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export type IconSvg = {
	id: string;
	url: string | null;
	availableSizes: number[] | null;
};

export type NutriScore = {
	label: string | null;
	color: string | null;
};
