import { TagCategoryLight } from 'types/TagCategory/TagCategoryLight';

import { TagLight } from './TagLight';

export type TagMappingDto = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	tagCategory: TagCategoryLight | null;
	source: string | null;
};

export const defaultTagMappingDto = (): TagMappingDto => {
	return {
		id: '',
		iconSvgUrl: null,
		name: '',
		display: false,
		tagCategory: null,
		source: null,
	};
};

export const mapTagLightsToTagMappingDtos = (tags: TagLight[]): TagMappingDto[] => {
	return tags.map((x) => ({
		...x,
		source: null,
	}));
};
