import { SearchLogicMap } from 'api/recipe/PostRecipeSearchV1';
import { SearchLogic, SearchLogic as SearchLogicEnum } from 'enums/searchLogic';
import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';

export interface Selectable {
	all: any[];
	filtered: IExclusionLogicSelectItem[];
	initialize: () => Promise<void>;
	search: (searchTerm: string) => Promise<void>;
	getById: (id: string) => any | undefined;
}

export interface Saveable extends ISelectItem {
	logic: SearchLogicEnum;
}

export default class ExclusionMultiSelect<
	SuggestedType extends Selectable,
	SavedType extends Saveable,
> implements IAdvancedSearchKey
{
	public id: string;
	public suggested: SuggestedType;
	public saved: SavedType[] = [];
	public cultureCode: string | null = getCultureCodeFromLocalStorage();

	public constructor(c: new () => SuggestedType, id: string) {
		this.id = id;
		this.suggested = new c();
	}

	public async initialize() {
		const cultureCode: string | null = getCultureCodeFromLocalStorage();
		if (cultureCode !== this.cultureCode) {
			this.cultureCode = cultureCode;
			await this.suggested.initialize();
			this.translateSaved();
		}
		if (this.suggested.all.length === 0) {
			await this.suggested.initialize();
		}
	}

	public reset() {
		this.saved = [];
	}

	private translateSaved() {
		const output: SavedType[] = [];
		for (const i of this.saved) {
			const object: SavedType | undefined = this.suggested.getById(i.id);
			if (!object) continue;
			output.push(object);
		}
		this.saved = output;
	}

	public pushToSaved(list: SavedType[]): void {
		this.saved = list;
	}

	public mapToApi(): SearchLogicMap {
		const output: SearchLogicMap = {};
		this.saved.map((e: any) => {
			output[e.id] = e.logic;
		});
		return output;
	}

	public mapToUrl(): string | null {
		if (this.saved.length === 0) return null;
		const output: string[] = [];
		for (const i of this.saved) {
			output.push(i.id);
			output.push(i.logic);
		}
		return this.id + '=' + output.join(';');
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key !== this.id) continue;
			if (typeof value !== 'string') continue;

			const id: string | undefined = value.split(';')[0];
			const behaviour: string | undefined = value.split(';')[1];
			if (!id) continue;

			if (this.saved.find((e) => e.id === id)) continue;
			const object = this.suggested.getById(id);
			if (!object) continue;

			if (behaviour === 'Exclude') object.logic = SearchLogic.Exclude;
			this.saved.push(object);
		}
	}

	public async search(searchTerm: string): Promise<void> {
		await this.suggested.search(searchTerm);
	}
}
