import * as apiGet from 'api/nutriScoreLabel/GetNutriScoreLabelIdV1';
import * as apiPost from 'api/nutriScoreLabel/PostNutriScoreLabelV1';
import * as apiPut from 'api/nutriScoreLabel/PutNutriScoreLabelV1';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class NutriScoreLabel {
	public id: string = '';
	public isFluid: boolean = false;
	public maxValue: number | null = null;
	public color: string = '';
	public rank: number = 0;
	public labelTranslations: LocalizedTranslation<string> = {};

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiPost(): apiPost.Request {
		return {
			isFluid: this.isFluid,
			maxValue: this.maxValue == 0 ? null : this.maxValue,
			color: this.color,
			rank: this.rank,
			labelTranslations: this.labelTranslations,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			isFluid: this.isFluid,
			maxValue: this.maxValue == 0 ? null : this.maxValue,
			color: this.color,
			rank: this.rank,
			labelTranslations: this.labelTranslations,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.isFluid = input.isFluid;
		this.maxValue = input.maxValue;
		this.color = input.color;
		this.rank = input.rank;
		this.labelTranslations = input.labelTranslations;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}
}
