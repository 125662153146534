import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseChannel[]>> {
	const url: string = '/backend-service/Channel/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.channels);
}

interface Parameter {
	SearchTerm: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	channels: ResponseChannel[];
};

export type ResponseChannel = {
	id: string;
	name: string;
	iconSvgUrl: string;
};
