import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import * as apiPut from 'api/menuPlanner/PutMenuPlannerIdV1';
import Row from 'classes/MenuPlan/Detail/Row';
import TagList from 'classes/MenuPlan/Detail/TagList';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';

export default class RowList {
	public all: Row[] = [];
	public tagList: TagList = new TagList();

	public constructor(input?: apiGet.Row[]) {
		if (!input) return;
		this.mapFromApiGet(input);
	}

	public async initialize() {
		this.initializeTagList();
		if (!this.all.length) this.addRow();
	}

	private async initializeTagList() {
		if (this.tagList.all.length === 0) {
			await this.tagList.initialize();
		}
		for (const row of this.all) {
			row.tagList.all = this.tagList.all;
			row.tagList.filtered = this.tagList.filtered;
		}
	}

	public removeItem(day: number, rowId: string) {
		for (const row of this.all) {
			if (row.id === rowId) {
				row.removeItem(day);
			}
		}
	}

	public addRow(): void {
		this.all.push(new Row());
		this.initializeTagList();
	}

	public removeRow(row: Row): void {
		const output: Row[] = [];
		for (const i of this.all) {
			if (i !== row) {
				output.push(i);
			}
		}
		this.all = output;
	}

	public mapFromApiGet(input: apiGet.Row[] | null): void {
		if (!input) return;
		this.all = input.map((e) => new Row(e));
	}

	public mapFromApiPut(input: apiGet.Row[] | null): void {
		if (input) {
			for (const i of this.all) {
				const inputRow = input.find((e: apiGet.Row) => {
					return e.id === i.id;
				});
				if (inputRow) i.mapFromApiPut(inputRow);
			}
		}
	}

	public mapFromApiCalculate(data: apiPostCalculate.ResponseData, total: boolean) {
		this.all.forEach((row: Row, i: number) => {
			row.mapFromApiCalculate(data[i], total);
		});
	}

	public mapToApiPut(): apiPut.Row[] {
		const output: apiPut.Row[] = [];
		for (const i of this.all) {
			output.push(i.mapToApiPut());
		}
		return output;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		let output: NutrientCategoryGroup[] = [];
		for (const row of this.all) {
			if (!output.length) {
				output = [...row.getNutrientCategoryGroups()];
			} else {
				const groups: NutrientCategoryGroup[] = row.getNutrientCategoryGroups();
				for (let i = 0; i < groups.length; i++) {
					const groupIndex: number = output.findIndex((e: NutrientCategoryGroup) => {
						return groups[i].name === e.name;
					});
					if (groupIndex !== -1) {
						output[i] = this.mergeNutrientCategoryGroups(output[i], groups[i]);
					} else {
						output.push(groups[i]);
					}
				}
			}
		}
		return output;
	}

	private mergeNutrientCategoryGroups(
		a: NutrientCategoryGroup,
		b: NutrientCategoryGroup
	): NutrientCategoryGroup {
		const mergedGroup: NutrientCategoryGroup = {
			name: a.name,
			sortOrder: a.sortOrder,
			nutrients: [],
		};
		const mergedNutrients: CalculatedNutrient[] = [];

		for (const nutrientA of a.nutrients) {
			const duplicateIndex = mergedNutrients.findIndex(
				(nutrient) => nutrient.nutrient === nutrientA.nutrient
			);
			if (duplicateIndex !== -1) {
				mergedNutrients[duplicateIndex].total += nutrientA.total;
			} else {
				mergedNutrients.push({ ...nutrientA });
			}
		}

		for (const nutrientB of b.nutrients) {
			const duplicateIndex = mergedNutrients.findIndex(
				(nutrient) => nutrient.nutrient === nutrientB.nutrient
			);
			if (duplicateIndex !== -1) {
				mergedNutrients[duplicateIndex].total += nutrientB.total;
			} else {
				mergedNutrients.push({ ...nutrientB });
			}
		}

		mergedGroup.nutrients = mergedNutrients;
		return mergedGroup;
	}
}
