import { ContextBuilder } from 'context/nutriscoreLabelContext';

import IngredientSearchComponent from 'components/desktop/Ingredient/Search/IngredientSearchComponent';

const IngredientArchiveContainer = () => {
	return (
		<main className="container">
			<IngredientSearchComponent archive={true} />
		</main>
	);
};

export default ContextBuilder.From(IngredientArchiveContainer).wrapWithNutriscoreContext().build();
