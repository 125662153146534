import axios from 'axios';

import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/IngredientCategory';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	parentId: string | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nameTranslations: {
		[key: string]: string;
	};
}

export interface Response {
	data: string;
}
