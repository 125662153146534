import { Tree } from 'components/desktop/_general/Input/TreeInput/Tree';

const treeContainsSearchTerm = <T extends Tree<T>>(input: T, searchTerm: string): boolean => {
	if (input.name.toLowerCase().includes(searchTerm.toLowerCase())) return true;
	for (const i of input.children) {
		if (treeContainsSearchTerm(i, searchTerm)) return true;
	}
	return false;
};

export const subTreeContainsSearchTerm = <T extends Tree<T>>(
	input: T,
	searchTerm: string
): boolean => {
	for (const i of input.children) {
		if (treeContainsSearchTerm(i, searchTerm)) return true;
	}
	return false;
};

export const isOpened = <T extends Tree<T>>(
	tree: T,
	searchTerm: string,
	opened: string[]
): boolean => {
	if (searchTerm && treeContainsSearchTerm(tree, searchTerm)) return true;
	if (opened.includes(tree.id)) return true;
	return false;
};

export const isUnClosebale = <T extends Tree<T>>(tree: T, searchTerm: string): boolean => {
	if (searchTerm && treeContainsSearchTerm(tree, searchTerm)) return true;
	return false;
};

export const addIdToOpened = (
	id: string,
	opened: string[],
	setOpened: (input: string[]) => void
): void => {
	setOpened([...opened, id]);
};

export const removeIdFromOpened = (
	id: string,
	opened: string[],
	setOpened: (input: string[]) => void
): void => {
	setOpened([
		...opened.filter((e: string) => {
			if (e !== id) {
				return e;
			}
		}),
	]);
};

export const handleChangeSearchTerm = (
	e: React.ChangeEvent<HTMLInputElement>,
	setSearchTerm: (input: string) => void
): void => {
	const value: string = e.target.value;
	setSearchTerm(value);
};

export const isSelected = <T extends Tree<T>>(id: string, selected: T[]): boolean => {
	for (const i of selected) {
		if (i.id === id) return true;
	}
	return false;
};

export const addToSelected = <T extends Tree<T>>(
	tree: T,
	selected: T[],
	setSelected: (input: T[]) => void
): void => {
	setSelected([...selected, tree]);
};

const removeFromSelected = <T extends Tree<T>>(
	tree: T,
	selected: T[],
	setSelected: (input: T[]) => void
): void => {
	setSelected([
		...selected.filter((e: T) => {
			if (e.id !== tree.id) {
				return e;
			}
		}),
	]);
};

export const createRemoveFromSelected = <T extends Tree<T>>(
	selected: T[],
	setSelected: (input: T[]) => void
) => {
	return (tree: T): void => {
		setSelected([
			...selected.filter((e: T) => {
				if (e.id !== tree.id) {
					return e;
				}
			}),
		]);
	};
};

export const handleSelectionClick = <T extends Tree<T>>(
	tree: T,
	selected: T[],
	setSelected: (input: T[]) => void
): void => {
	if (!isSelected(tree.id, selected)) {
		addToSelected(tree, selected, setSelected);
	} else {
		removeFromSelected(tree, selected, setSelected);
	}
};

export const handleOpenCloseClick = <T extends Tree<T>>(
	tree: T,
	searchTerm: string,
	opened: string[],
	setOpened: (input: string[]) => void
): void => {
	if (!isOpened(tree, searchTerm, opened)) {
		addIdToOpened(tree.id, opened, setOpened);
	} else {
		removeIdFromOpened(tree.id, opened, setOpened);
	}
};

export const getTreeForRendering = <T extends Tree<T>>(tree: T[], searchTerm: string): T[] => {
	if (searchTerm === '') return tree;
	const output: T[] = [];
	for (const i of tree) {
		if (treeContainsSearchTerm(i, searchTerm)) {
			output.push(i);
		}
	}
	return output;
};

export const createOnBlur = (setShowSuggested: (input: boolean) => void) => {
	return () => {
		setShowSuggested(false);
	};
};

export const createOnFocus = (setShowSuggested: (input: boolean) => void) => {
	return () => {
		setShowSuggested(true);
	};
};
