import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiSearch from 'api/unit/PostUnitSearchV1';
import Search from 'classes/StandingData/Unit/Search/Search';
import Unit from 'classes/StandingData/Unit/Search/Unit';
import UnitList from 'classes/StandingData/Unit/Search/UnitList';
import UnitDetailComponent from 'components/desktop/StandingData/Unit/Detail/UnitDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import PERMISSIONS from 'enums/permissions';
import { UnitType } from 'enums/unitType';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import { getSeparatedNumberString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const UnitManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [unitList, setUnitList] = useState<UnitList>(new UnitList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getUnits();
		} else {
			await getUnits();
		}
		setSearch(clone(search));
	};

	const getUnits = async () => {
		setIsLoading(true);
		try {
			const response: apiSearch.ResponseData | null = await unitList.callApi(
				search.mapToRequest()
			);
			if (response) {
				unitList.mapFromApi(response);
				setUnitList(clone(unitList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getSuggestedUnits = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await unitList.search(searchTerm, props.archive ?? false);
		} else {
			unitList.filtered = [];
		}

		setUnitList(clone(unitList));
		return unitList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getUnits();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveUnit = async (unit: Unit): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:UNIT_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await unit.callApiDelete();
				await Swal.fire({
					title: t('standingData:UNIT_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getUnits();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreUnit = async (unit: Unit): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:UNIT_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await unit.callApiRestore();
				await Swal.fire({
					title: t('standingData:UNIT_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getUnits();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getButtonDataDelete = (input: Unit): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveUnit(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: Unit): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreUnit(input),
			};
		}
	};

	const renderButtons = (input: Unit): JSX.Element => {
		if (input.isDisabled()) {
			return <BtnGroup view={getButtonDataEdit(input.id)} />;
		}
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderUnitType = (type: UnitType): string => {
		switch (type) {
			case UnitType.Container:
				return t('standingData:CONTAINER');
			case UnitType.Quantifier:
				return t('standingData:QUANTIFIER');
			case UnitType.Volume:
				return t('standingData:VOLUME');
			case UnitType.Weight:
				return t('standingData:WEIGHT');
		}
	};

	const renderLabel = (input: string): JSX.Element => {
		return (
			<span style={{ fontSize: '0.8em' }} className="grey">
				{input}:
			</span>
		);
	};

	const renderListRows = (): Row[] => {
		return unitList.all.map((unit: Unit) => {
			return {
				id: unit.id,
				jsx: (
					<div className="d-flex align-items-center" style={{ gap: '10px' }}>
						<div className="flex-grow-1">
							<span style={{ fontWeight: 'bold' }}>{unit.nameSingular}</span>
							{unit.nameSingular !== unit.shortNameSingular
								? `, ${unit.shortNameSingular}`
								: ''}
							<br />
							<span className="grey">{t('standingData:TYPE')}:</span>{' '}
							{renderUnitType(unit.type)}
						</div>
						<div style={{ width: '10em' }}>
							{renderLabel(t('standingData:FACTOR_TO_MILLILITER'))}
							<br />
							{unit.factorToMilliliter != null
								? getSeparatedNumberString(
										String(unit.factorToMilliliter),
										reduxCultureCode
								  )
								: '-'}
						</div>
						<div style={{ width: '10em' }}>
							{renderLabel(t('standingData:FACTOR_TO_GRAM'))}
							<br />
							{unit.factorToGram != null
								? getSeparatedNumberString(
										String(unit.factorToGram),
										reduxCultureCode
								  )
								: '-'}
						</div>
						<div style={{ width: '10em' }}>
							{renderLabel(t('standingData:FACTOR_TO_ONE'))}
							<br />
							{unit.factorToOne != null
								? getSeparatedNumberString(
										String(unit.factorToOne),
										reduxCultureCode
								  )
								: '-'}
						</div>
						<div style={{ minWidth: '100px', textAlign: 'right' }}>
							{renderButtons(unit)}
						</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						{!props.archive ? (
							<>
								<h1>{t('standingData:UNIT_MANAGEMENT')}</h1>
								<RenderIf
									condition={arePermissionsInUserPermissions([
										PERMISSIONS.WRITEUNIT,
									])}
								>
									<div className="d-flex flex-gap-25">
										<BtnNew
											onClick={handleChangeIdToNew}
											name={t('standingData:CREATE_UNIT')}
										/>
									</div>
								</RenderIf>
							</>
						) : (
							<h1>{t('standingData:UNIT_ARCHIVE')}</h1>
						)}
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedUnits}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{`${unitList.totalCount} ${t('_general:UNITS')}`}</p>
					<StandingDataList
						archive={props.archive}
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<UnitDetailComponent />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={unitList.totalCount}
						pageIndex={search.getPageIndex()}
						setPageIndex={handleChangePageIndex}
						listItemAmount={search.getPageSize()}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default UnitManagementComponent;
