import { Dispatch, SetStateAction } from 'react';

import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import MediaManagement from 'components/desktop/_general/MediaManagement/MediaManagement';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	readOnly: boolean;
	recipe: Recipe;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
	updateChannels: (mediaId: string, channels: ChannelLight[]) => void;
}

const RecipeMediaComponent = (props: IProps) => {
	return (
		<div className="margin-top-25">
			<MediaManagement
				handleDeleteImage={handlers.createDeleteImageHandler(props.setRecipe)}
				handleDeleteVideo={handlers.createDeleteVideoHandler(props.setRecipe)}
				images={props.recipe.images}
				mainImage={props.recipe.image}
				readOnly={props.readOnly}
				setMainImage={handlers.createSetImageHandler(props.setRecipe)}
				updateChannels={props.updateChannels}
				uploadMedia={handlers.createSetFormDataHandler(props.setRecipe)}
				videos={props.recipe.videos}
			/>
		</div>
	);
};

export default RecipeMediaComponent;
