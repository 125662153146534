export type ColumnInformation = {
	key: string;
	columnName: string;
	numeric?: boolean;
	className?: string;
	sticky?: boolean;
	editable?: boolean;
	onInputChange?: (e: string, index: number) => void;
};
export type ColumnTypeWithWidth = ColumnInformation & { width: number; start: number };
export type ColumnTypeIndex = { [key: string]: ColumnTypeWithWidth | ColumnInformation };

export type RowData = { [key: string]: any };
export type TableData = RowData[];

export function isColumnTypeWithWidth(obj: ColumnTypeWithWidth | ColumnInformation): // @ts-ignore
obj is ColumnTypeWithWidth {
	const o = obj as any;
	return o.width !== undefined && o.start !== undefined;
}
