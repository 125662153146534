import 'components/desktop/_general/Loading/LoadingAnimation.scss';

interface IProps {
	isLoading?: boolean;
}

export const AirSvgIcon = (props: { height?: string; width?: string }) => (
	<svg
		version="1.1"
		id="LoadingAnimation"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width={props.width ?? '200px'}
		height={props.height ?? '200px'}
		viewBox="0 0 566.9 566.9"
		enableBackground="new 0 0 566.9 566.9"
		xmlSpace="preserve"
	>
		<g>
			<path
				className="multiply layer purple"
				d="M339.6,437.1c-19.8,0-39.9-6.2-57.6-19.8l-83.9-64.4c-60.2-46.2-44.9-141.6,26.9-168.5l93.5-35
c11.5-4.3,23-6.3,34.2-6.3c54,0,100.3,46.4,94.6,105.2l-9.6,99.4C432.6,401.6,387.1,437.1,339.6,437.1"
			/>
			<path
				className="multiply layer green"
				d="M275.7,453.6c-23.8,0-48.1-8.7-67.6-27.7l-72-69.8c-51.4-49.9-33.4-136.3,33.6-161.6l93.9-35.4
c11.7-4.4,23.4-6.4,34.7-6.4c58.9,0,108.5,54.5,95.4,117.8l-21.9,105.2C361.7,424.5,319.3,453.6,275.7,453.6"
			/>
			<path
				className="multiply layer blue"
				d="M222.7,394.4c-74.8,0-121.2-83.4-82.1-148.6l51.4-85.6c18.7-31.1,50.4-46.8,82.1-46.8
c30.6,0,61.3,14.6,80.3,44l54.3,83.7c41.8,64.3-2.8,150.1-78.6,151.5l-105.7,1.8C223.8,394.3,223.3,394.4,222.7,394.4"
			/>
		</g>
	</svg>
);
const LoadingAnimation = (props: IProps) => {
	if (props.isLoading) {
		return (
			<>
				<div className="loading-screen-animation">
					<div
						className="d-flex align-items-center justify-content-center"
						style={{ height: '100%' }}
					>
						<AirSvgIcon />
					</div>
				</div>
			</>
		);
	}

	return <></>;
};

export default LoadingAnimation;
