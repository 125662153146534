import LanguageManagementComponent from 'components/desktop/StandingData/Language/Management/LanguageManagementComponent';

const LanguageArchive = () => {
	const renderContent = () => {
		return (
			<main className="containter">
				<LanguageManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default LanguageArchive;
