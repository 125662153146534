import * as api from 'api/nutriScoreBaseIngredientCategoryPoints/GetNutriScoreBaseIngredientCategoryPointsV1';
import { BaseIngredientCategoryPointLight } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPointLight';

export const mapFromRequest = (
	input: api.NutriScoreBaseIngredientCategoryPoint[]
): BaseIngredientCategoryPointLight[] => {
	return input.map((e: api.NutriScoreBaseIngredientCategoryPoint) => {
		return {
			baseIngredientCategoryId: e.baseIngredientCategoryId,
			pointType: e.pointType,
			baseIngredientCategoryName: e.baseIngredientCategoryName,
			isPositive: e.isPositive,
			points: e.points,
		};
	});
};
