import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function apiCall(
	searchTerm: string,
	pageIndex: number,
	pageSize: number
): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/NutriScoreNutrientPoints';
	const params: Parameter = {
		SearchTerm: searchTerm,
		PageIndex: pageIndex,
		PageSize: pageSize,
	};

	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data);
}

interface Parameter {
	PointType?: 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water';
	SearchTerm: string;
	PageIndex: number;
	PageSize: number;
}

export type Response = {
	data: ResponseData;
};

export type ResponseData = {
	data: NutriScoreNutrient[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type NutriScoreNutrient = {
	nutrientId: string;
	pointType: 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water';
	nutrientName: string | null;
	isPositive: boolean;
	isProtein: boolean;
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};
