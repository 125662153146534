import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	formData: FormData,
	nrOfRecommendations: number
): Promise<Optional<string[]>> {
	const url = '/backend-service/AiFunctions/Suggest/SimilarRecipesByImage';
	const requestConfig = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	formData.set('NrOfRecommendations', String(nrOfRecommendations));
	return axios.post<string[]>(url, formData, requestConfig).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: string[];
}
