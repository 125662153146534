import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConstraintStatus } from 'enums/ConstraintStatus';

export default function getConstraintIcon(input: ConstraintStatus): JSX.Element {
	let icon: IconProp = faCircle as IconProp;
	let color: string = '#fd7e14';

	switch (input) {
		case ConstraintStatus.FullyMet:
			icon = faCircleCheck as IconProp;
			color = '#198754';
			break;
		case ConstraintStatus.NotMet:
			icon = faCircleXmark as IconProp;
			color = '#dc3545';
			break;
	}
	return <FontAwesomeIcon color={color} style={{ marginRight: '8px' }} icon={icon} />;
}
