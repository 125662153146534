import axios from 'axios';

import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/IngredientCategory/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	parent: ParentCategory | null;
	nutriScoreCategory: NutriScoreCategory;
	nameTranslations: {
		[key: string]: string;
	};
};

export type ParentCategory = {
	id: string;
	name: string;
};

export const defaultResponseData = (): ResponseData => {
	return {
		id: '',
		parent: null,
		nutriScoreCategory: NutriScoreCategory.StandardFood,
		nameTranslations: {},
	};
};
