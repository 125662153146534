import Archive from 'containers/desktop/StandingData/ProductionMethod/Archive/ProductionMethodArchive';
import Management from 'containers/desktop/StandingData/ProductionMethod/Management/ProductionMethodManagement';
import PERMISSIONS from 'enums/permissions';

export const productionMethodRoutes = [
	{
		path: '/productionMethod/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/productionMethod/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
