import { useTranslation } from 'react-i18next';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import 'components/desktop/_general/Co2Select/Co2Select.scss';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';

interface IProps {
	carbonDioxideLabels: ResponseCarbonDioxideLabel[];
	savedItems: ResponseCarbonDioxideLabel[];
	setSavedItems: (items: ResponseCarbonDioxideLabel[]) => void;
}

const Co2Select = (props: IProps) => {
	const { t } = useTranslation();

	const handleClick = (input: ResponseCarbonDioxideLabel) => {
		const savedItemsCpy = [...props.savedItems];
		if (savedItemsCpy.some((x) => x.label == input.label)) {
			const index = savedItemsCpy.findIndex((x) => x.label == input.label);
			savedItemsCpy.splice(index, 1);
		} else {
			savedItemsCpy.push(input);
		}
		props.setSavedItems(savedItemsCpy);
	};

	const renderLabel = (input: ResponseCarbonDioxideLabel, key: number): JSX.Element => {
		if (props.savedItems.some((x) => x.label == input.label)) {
			return (
				<div
					className="carbon-dioxide-select-item"
					onClick={() => handleClick(input)}
					key={key}
				>
					<IconCo2Round color={input.color} label={input.label} />
				</div>
			);
		} else {
			return (
				<div
					className="carbon-dioxide-select-item"
					onClick={() => handleClick(input)}
					key={key}
				>
					<IconCo2Round color={input.color} label={input.label} opacity={0.3} />
				</div>
			);
		}
	};

	return (
		<div className="carbon-dioxide-select-container-outer">
			<label>{t('_general:CARBON_DIOXIDE_LABEL')}</label>
			<div className="carbon-dioxide-select-container-inner">
				{props.carbonDioxideLabels.map((c: ResponseCarbonDioxideLabel, i: number) =>
					renderLabel(c, i)
				)}
			</div>
		</div>
	);
};

export default Co2Select;
