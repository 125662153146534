import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';

export default class RecipePrepareTime {
	public timeUnit: string = '';
	public timeValue: number = 0;

	public constructor(input?: postMenuSearchV1.PrepareTime | null) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: postMenuSearchV1.PrepareTime | null): void {
		if (input) {
			this.timeUnit = input.timeUnit ?? '';
			this.timeValue = input.timeValue;
		}
	}
}
