import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faDollarSign, faTimer, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { ThumbnailCard } from 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnail';
import 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnail.scss';
import ENTITLEMENTS from 'enums/entitlements';
import { getPriceString } from 'functions/numberToString';
import { getNumberString } from 'functions/numberToString';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

const SearchResultThumbnailCard = (props: ThumbnailCard) => {
	const [showButtons, setShowButtons] = useState<boolean>(false);
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getTitle = (): string => {
		if (props.title) {
			return props.title;
		}
		return t('_general:UNKNOWN_TITLE');
	};

	const getAuthor = (): string => {
		if (props.author) {
			return props.author;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(props.valueScope ?? null, null, true);
	};

	const getStatus = (): JSX.Element => {
		if (props.status) {
			return (
				<>
					{props.status}&ensp;
					<FontAwesomeIcon color={props.statusColor} icon={faCircle as IconProp} />
				</>
			);
		}
		return (
			<>
				{t('_general:UNKNOWN_STATUS')}&ensp;
				<FontAwesomeIcon icon={faCircle as IconProp} />
			</>
		);
	};

	const getInfos = (): JSX.Element => {
		return (
			<>
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faUtensils as IconProp} />
					</span>
					{props.calories ? (
						<>
							<b>{getNumberString(props.calories, reduxCultureCode, true)}</b>
							{' ' + t('_general:CALORIES') + getValueScope()}
						</>
					) : (
						<>–</>
					)}
				</p>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<p>
						<span id="icon">
							<FontAwesomeIcon icon={faDollarSign as IconProp} />
						</span>
						{props.price ? (
							<>
								<b>{getPriceString(props.price, reduxCultureCode)}</b>{' '}
								{' ' + props.currency + getValueScope()}{' '}
							</>
						) : (
							<>–</>
						)}
					</p>
				</RenderIf>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<p>
						<span id="icon">
							<FontAwesomeIcon icon={faTimer as IconProp} />
						</span>
						<RenderIf condition={Boolean(props.prepareTime.timeValue)}>
							<b>{Math.round(props.prepareTime.timeValue)}</b>
							<>{' ' + props.prepareTime.timeUnit}</>
						</RenderIf>
						<RenderIf condition={!props.prepareTime.timeValue}>
							<>–</>
						</RenderIf>
					</p>
				</RenderIf>
			</>
		);
	};

	const getActionButtonClass = (): string => {
		if (showButtons) {
			return 'card-action-buttons text-center';
		}
		return 'card-action-buttons hide text-center';
	};

	const onMouseOver = (): void => {
		setShowButtons(true);
	};

	const onMouseLeave = (): void => {
		setShowButtons(false);
	};

	return (
		<div
			className="card recipe-card"
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			key={props.title}
		>
			<div className={getActionButtonClass()} style={{ zIndex: 3000 }}>
				{props.buttons}
			</div>
			{props.archive ? (
				<div className="card-body">
					{!props.imageUrl ? (
						<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
							<FontAwesomeIcon size="3x" icon={faCamera} color={props.statusColor} />
						</div>
					) : (
						<div className="card-picture">
							<img alt="thumbnail" src={props.imageUrl} />
						</div>
					)}
					<div>
						<div id="title">
							<strong>{getTitle()}</strong>
						</div>
						<div id="info">{getInfos()}</div>
						<hr />
						<div id="author-status">
							<div>{getAuthor()}</div>
							<div>{getStatus()}</div>
						</div>
					</div>
				</div>
			) : (
				<Link to={props.href} className="unstyled-link">
					<div className="card-body">
						{!props.imageUrl ? (
							<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
								<FontAwesomeIcon
									size="3x"
									icon={faCamera}
									color={props.statusColor}
								/>
							</div>
						) : (
							<div className="card-picture">
								<img alt="thumbnail" src={props.imageUrl} />
							</div>
						)}
						<div>
							<div id="title">
								<strong>{getTitle()}</strong>
							</div>
							<div id="info">{getInfos()}</div>
							<hr />
							<div id="author-status">
								<div>{getAuthor()}</div>
								<div>{getStatus()}</div>
							</div>
						</div>
					</div>
				</Link>
			)}
		</div>
	);
};

export default SearchResultThumbnailCard;
