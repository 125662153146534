import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Translation';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	fromCultureCode: string;
	toCultureCode: string;
	text: string;
}

export interface Response {
	data: string;
}
