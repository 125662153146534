import * as apiPostSearch from 'api/accessory/PostAccessorySearchV1';
import NullableAccessorySortOrder from 'classes/Accessory/Search/NullableAccessorySortOrder';
import AccessoryCategoryList from 'classes/_general/Search/AccessoryCategory/AccessoryCategoryList';
import CharacteristicToggleList from 'classes/_general/Search/Characteristic/CharacteristicToggleList';
import Co2Label from 'classes/_general/Search/Co2Label/Co2Label';
import Co2LabelList from 'classes/_general/Search/Co2Label/Co2LabelList';
import { MultiSelect } from 'classes/_general/Search/MultiSelect';
import NullableNumber from 'classes/_general/Search/NullableNumber';
import NullableString from 'classes/_general/Search/NullableString';
import Slider from 'classes/_general/Search/Slider';
import TagList from 'classes/_general/Search/Tag/TagList';
import { AccessorySortOrder } from 'enums/SortOrder/AccessorySortOrder';
import ENTITLEMENTS from 'enums/entitlements';
import { QueryParams } from 'functions/getQueryParams';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Search {
	public pageIndex: NullableNumber = new NullableNumber('pi', 0);
	public pageSize: NullableNumber = new NullableNumber('ps', 12);
	public searchTerm: NullableString = new NullableString('st');
	public sortOrder: NullableAccessorySortOrder = new NullableAccessorySortOrder(
		'so',
		AccessorySortOrder.NameAsc
	);
	public showOnlyDeleted: boolean = false;

	public selectTag: MultiSelect<TagList, ISelectItem> = new MultiSelect(TagList, 'tag');
	public selectCo2Label: MultiSelect<Co2LabelList, Co2Label> = new MultiSelect(
		Co2LabelList,
		'co2'
	);
	public selectCategory: MultiSelect<AccessoryCategoryList, ISelectItem> = new MultiSelect(
		AccessoryCategoryList,
		'cat'
	);

	public sliderCo2: Slider = new Slider('co2');
	public sliderPrice: Slider = new Slider('pri');

	public toggleListCharacteristic: CharacteristicToggleList = new CharacteristicToggleList(
		'char'
	);

	public async initialize() {
		await this.selectTag.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE]))
			await this.selectCo2Label.initialize();
		await this.selectCategory.initialize();
		this.toggleListCharacteristic.initialize();
	}

	public getPageIndex(): number {
		return this.pageIndex.get();
	}

	public getPageSize(): number {
		return this.pageSize.get();
	}

	public getSortOrder(): AccessorySortOrder {
		return this.sortOrder.value ?? this.sortOrder.defaultValue;
	}

	public setSearchTerm(input: string) {
		this.searchTerm.value = input;
		this.pageIndex.value = 0;
	}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset();
			}
		}
	}

	public mapFromApi(input: apiPostSearch.ResponseData) {
		this.sliderCo2.mapFromApi(input.minMaxCarbonDioxideRange);
		this.sliderPrice.mapFromApi(input.minMaxPriceRange);
	}

	public mapToRequest(): apiPostSearch.Request {
		return {
			carbonDioxideLabelIds: this.selectCo2Label.mapToApi(),
			carbonDioxideRange: this.sliderCo2.mapToApi(),
			categoryIds: this.selectCategory.mapToApi(),
			characteristics: this.toggleListCharacteristic.mapToApi(),
			pageIndex: this.pageIndex.mapToApi(),
			pageSize: this.pageSize.mapToApi(),
			priceRange: this.sliderPrice.mapToApi(),
			searchTerm: this.searchTerm.mapToApi(),
			showOnlyDeleted: this.showOnlyDeleted,
			sortOrder: this.sortOrder.mapToApi(),
			tagIds: this.selectTag.mapToApi(),
		};
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input);
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = [];
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl());
			}
		}
		return this.mapUrlArrayToString(output);
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e;
			}
		});
		return '?' + outputFiltered.join('&');
	}
}
