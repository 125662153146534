import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class CarbonDioxideLabel {
	public label: string = '';
	public color: string = '';

	public constructor(input?: api.CarbonDioxideLabel) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.CarbonDioxideLabel) {
		this.label = input.label;
		this.color = input.color;
	}
}
