import { useTranslation } from 'react-i18next';

import Unit from 'classes/StandingData/Unit/Unit';

interface IProps {
	unit: Unit;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UnitLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<div className="row mb-3">
							<label>{t('standingData:DESCRIPTION_SINGULAR')}</label>
							<div>
								<input
									value={
										props.unit.nameSingularTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="nameSingularTranslations"
									error-key={'NameSingularTranslations'}
									disabled={props.unit.isDisabled()}
								/>
							</div>
						</div>
						<div className="row mb-3">
							<label>{t('standingData:DESCRIPTION_PLURAL')}</label>
							<div>
								<input
									value={
										props.unit.namePluralTranslations[props.selectedLanguage] ||
										''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="namePluralTranslations"
									error-key={'NamePluralTranslations'}
									disabled={props.unit.isDisabled()}
								/>
							</div>
						</div>
						<div className="row mb-3">
							<label>{t('standingData:SHORT_DESCRIPTION_SINGULAR')}</label>
							<div>
								<input
									value={
										props.unit.shortNameSingularTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="shortNameSingularTranslations"
									error-key={'ShortNameSingularTranslations'}
									disabled={props.unit.isDisabled()}
								/>
							</div>
						</div>
						<div className="row mb-3">
							<label>{t('standingData:SHORT_DESCRIPTION_PLURAL')}</label>
							<div>
								<input
									value={
										props.unit.shortNamePluralTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="shortNamePluralTranslations"
									error-key={'ShortNamePluralTranslations'}
									disabled={props.unit.isDisabled()}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default UnitLanguageInput;
