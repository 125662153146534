import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as apiGetAccessoryId from 'api/accessory/GetAccessoryIdV1';
import * as apiPostAccessoryId from 'api/accessory/PostAccessoryIdV1';
import * as apiPutAccessoryId from 'api/accessory/PutAccessoryIdV1';
import { Category as CategoryTree } from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import * as apiGetTagSW from 'api/tag/GetTagStartingWithV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import * as apiGetUnitSW from 'api/unit/GetUnitStartingWithV1';
import * as handlers from 'components/desktop/Accessory/AccessoryComponentHandlers';
import AccessoryLanguageInput from 'components/desktop/Accessory/LanguageInput/AccessoryLanguageInput';
import AccessorySidebar from 'components/desktop/Accessory/Sidebar/AccessorySidebar';
import 'components/desktop/Ingredient/IngredientComponent.scss';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PictureBig from 'components/desktop/_general/PictureBig/PictureBig';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SynonymsComponent from 'components/desktop/_general/Synonyms/Synonyms';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import PERMISSIONS from 'enums/permissions';
import { getPicturePath } from 'functions/getPicturePath';
import { mapToAccessory } from 'functions/mappers/Accessory/mapToAccessory';
import { mapToSaveAccessory } from 'functions/mappers/Accessory/mapToSaveAccessory';
import { getSeparatedNumberString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { uploadMedia } from 'functions/uploadMedia';
import { useApi } from 'hooks/useApi';
import { useWarnOnLeave } from 'hooks/useWarnOnLeave';
import { RootState } from 'reducers/rootReducer';
import { Accessory, Tags, defaultAccessory } from 'types/Accessory/Accessory';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { Language, defaultLanguage } from 'types/Language/Language';
import { TagLight } from 'types/Tag/TagLight';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';
import { UnitLight } from 'types/Unit/UnitLight';

interface IProps {
	id?: string;
}

const AccessoryComponent = (props: IProps) => {
	const { t } = useTranslation();
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [tags, setTags] = useState<TagLight[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLanguage, setSelectedLanguage] = useState<Language>(defaultLanguage());
	const [units, setUnits] = useState<UnitLight[]>([]);
	const [accessory, setAccessory] = useState<Accessory>(defaultAccessory());
	const { setObjectCachedAsync } = useWarnOnLeave(accessory, handleSaveAccessory);
	const [categoryTree] = useApi<CategoryTree[], string>([], handlers.getCategoryTree, '');

	const accessoryEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);
	const navigate = useNavigate();

	useEffect(() => {
		if (reduxCultureCode) {
			initialize();
			const language: Language | undefined = reduxLanguages.find((e: Language) => {
				return e.cultureCode === reduxCultureCode;
			});
			if (language) setSelectedLanguage(language);
		}
	}, [reduxCultureCode, reduxLanguages]);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		await getAccessory();
		await getTags();
		await getUnits();
		setIsLoading(false);
	};

	const getAccessory = async (): Promise<void> => {
		if (props.id) {
			const accessory = await apiGetAccessoryId
				.callApi(props.id)
				.mapAsync((x) => x.map(mapToAccessory));
			accessory.do((x) => {
				setAccessory(x);
				setObjectCachedAsync(x);
			});
		} else {
			setObjectCachedAsync(accessory);
		}
	};

	const getUnits = async (): Promise<void> => {
		const response = await apiGetUnitSW.callApi('', false);
		response.do((x) => setUnits(x));
	};

	const getTags = async () => {
		const response = await apiGetTagSW.callApi('', false);
		response.do((x) => setTags(x));
	};

	const handleCharacteristicChange = async (checked: boolean, elementId: string) => {
		const accessoryCpy = { ...accessory };

		switch (elementId) {
			case Characteristic.Fluid:
				if (checked) {
					accessoryCpy.characteristic = Characteristic.Fluid;
				}
				break;
			case Characteristic.SolidNonQuantifiable:
				if (checked) {
					accessoryCpy.characteristic = Characteristic.SolidNonQuantifiable;
				}
				break;
			case Characteristic.SolidQuantifiable:
				if (checked) {
					accessoryCpy.characteristic = Characteristic.SolidQuantifiable;
				}
				break;
		}

		setAccessory(accessoryCpy);
	};

	async function handleSaveAccessory(): Promise<Optional<any>> {
		setIsLoading(true);
		const request: apiPutAccessoryId.Request & apiPostAccessoryId.Request =
			mapToSaveAccessory(accessory);
		if (props.id) {
			const accessoryCpy = await apiPutAccessoryId
				.callApi(props.id, request)
				.mapAsync((x) => x.map(mapToAccessory));

			if (accessoryCpy.hasValue()) {
				setAccessory(accessoryCpy.get());
				setObjectCachedAsync(accessoryCpy.get());
			}
			setIsLoading(false);
			return accessoryCpy;
		}
		const id = await apiPostAccessoryId.callApi(request);

		id.do(async (x) => {
			await setObjectCachedAsync(accessory);
			navigate(`/accessory/detail/${x}`);
		});
		setIsLoading(false);
		return id;
	}

	const handleSetFormData = (input: FormData | null) => {
		handleUploadMedia(input);
	};

	const handleUploadMedia = async (input: FormData | null) => {
		setIsLoading(true);
		let accessoryCpy: Accessory = { ...accessory };
		if (input) {
			accessoryCpy = await uploadMedia<Accessory>(
				accessoryCpy,
				input,
				MEDIACOLLECTION.Accessory
			);
		}
		setIsLoading(false);
		setAccessory(accessoryCpy);
	};

	const getIcons = (): TagLight[] => {
		if (accessory.tags === null) return [];
		const output: TagLight[] = accessory.tags.map((e: Tags) => {
			return {
				id: e.id,
				display: e.display,
				iconSvgUrl: e.iconSvgUrl ?? '',
				name: e.name ?? '',
				tagCategory: e.tagCategory
					? {
							id: e.tagCategory.id ?? '',
							name: e.tagCategory.name ?? '',
					  }
					: null,
			};
		});
		return output.filter((e: TagLight) => {
			if (e.display) {
				return e;
			}
		});
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedLanguage(
			reduxLanguages[
				reduxLanguages.findIndex((element) => element.cultureCode == selectedLanguage)
			]
		);
	};

	const handleInputValueChange = (key: keyof Accessory, value: string) => {
		const accessoryCpy = { ...accessory };
		accessoryCpy[key][selectedLanguage.cultureCode] = value;
		setAccessory(accessoryCpy);
	};

	const handleTranslate = async () => {
		await handleTranslateInput('nameSingularTranslations');
		await handleTranslateInput('namePluralTranslations');
	};

	const handleTranslateInput = async (name: string) => {
		const accessoryCpy = { ...accessory };
		const text: string = accessoryCpy[name][reduxCultureCode];

		if (!text) {
			return;
		}

		const translationDto = {
			fromCultureCode: reduxCultureCode,
			toCultureCode: selectedLanguage.cultureCode,
			text: text,
		} as PostTranslationDto;

		const response = await apiPostTranslation.callApi(translationDto);
		accessoryCpy[name][selectedLanguage.cultureCode] = response.getOrDefault('');
		setAccessory(accessoryCpy);
	};

	const renderPrice = (): JSX.Element => {
		const price: string = accessory.price ? String(accessory.price) : '–';
		return (
			<div>
				<label>{`${t('_general:PER')} 100g`}</label>
				<div className="form-control">
					<b>{`${reduxCurrency} ${getSeparatedNumberString(price, reduxCultureCode)}`}</b>
				</div>
			</div>
		);
	};

	const getTitle = (): string => {
		if (!props.id) return t('accessory:NEW_ACCESSORY');
		if (!accessoryEditor) return t('accessory:VIEW_ACCESSORY');
		return t('accessory:EDIT_ACCESSORY');
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div className="row">
				<div className="d-flex flex-row justify-content-between">
					<div>
						<h1>{getTitle()}</h1>
					</div>
					<div>
						<RenderIf condition={accessoryEditor}>
							<BtnSave
								handleSave={() => handleSaveAccessory()}
								isLoading={isLoading}
							/>
						</RenderIf>
					</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-4 col-xl-3 col-sm-12 col-12 sidebar">
					<PictureBig path={getPicturePath(accessory.image, 376)} />
					<div className="margin-top-5">
						<RenderIf condition={accessoryEditor}>
							<PictureUpload setFormData={handleSetFormData} />
						</RenderIf>
					</div>
					<AccessorySidebar
						accessory={accessory}
						setAccessory={setAccessory}
						categoryTree={categoryTree}
						handleChangeCategory={handlers.createCategoriesHandler(setAccessory)}
						units={units}
						tags={tags}
						cultureCode={selectedLanguage.cultureCode}
						handleCharacteristicChange={handleCharacteristicChange}
					/>
				</div>
				<div className="col-md-8 col-xl-9 col-sm-12 col-12">
					<div className="d-flex justify-content-between flex-wrap flex-gap-10 mb-4">
						<div className="d-flex flex-gap-25">
							<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>{renderPrice()}</RenderIf>
							<Co2Display
								value={accessory.carbonDioxideValue}
								color={accessory.carbonDioxideLabel?.color || '#000'}
								fluid={null}
								valueScopeInfo={null}
							/>
						</div>
						<div className="d-flex flex-gap-10 align-items-center">
							{getIcons().map((icon: AllergenLight | TagLight, i: number) => (
								<IconComponent icon={icon} key={i} />
							))}
						</div>
					</div>
					<LanguageSwitch
						handleSelectLanguage={handleSelectLanguage}
						selectedLanguage={selectedLanguage.cultureCode}
						translatable={true}
						onTranslateClick={handleTranslate}
						entryLanguage={reduxCultureCode}
						content={
							<>
								<AccessoryLanguageInput
									accessory={accessory}
									selectedLanguage={selectedLanguage.cultureCode}
									handleValueChange={handleInputValueChange}
									onTranslateClick={handleTranslateInput}
									disabled={!accessoryEditor}
								/>
								<SynonymsComponent
									input={accessory}
									setInput={setAccessory}
									cultureCode={selectedLanguage.cultureCode}
									disabled={!accessoryEditor}
								/>
							</>
						}
					/>
				</div>
			</div>
		</>
	);
};

export default AccessoryComponent;
