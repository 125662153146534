export type ForgotPasswordDto = {
	email: string;
	passwordRecoveryPageUrl: string;
};

export const defaultForgotPasswordDto = (): ForgotPasswordDto => {
	return {
		email: '',
		passwordRecoveryPageUrl: '',
	};
};
