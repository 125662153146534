import { useTranslation } from 'react-i18next';

import 'components/desktop/_general/SeasonalityBar/SeasonalityBar.scss';

interface IProps {
	seasonalityCode: number;
	className?: string;
	showIfSeasonalityCodeIsZero?: boolean;
}

type Season = {
	name: number;
	active: boolean;
	value: number;
};

const SeasonalityBarBig = (props: IProps) => {
	const { t } = useTranslation();

	if (props.seasonalityCode !== 0 || props.showIfSeasonalityCodeIsZero) {
		const months: string[] = [
			t('months:JANUARY'),
			t('months:FEBRUARY'),
			t('months:MARCH'),
			t('months:APRIL'),
			t('months:MAY'),
			t('months:JUNE'),
			t('months:JULY'),
			t('months:AUGUST'),
			t('months:SEPTEMBER'),
			t('months:OCTOBER'),
			t('months:NOVEMBER'),
			t('months:DECEMBER'),
		];
		const seasons: Season[] = [];
		let binaryNumber: string = props.seasonalityCode.toString(2);
		binaryNumber = binaryNumber.split('').reverse().join('');

		for (let i = 0; i < 12; i++) {
			seasons.push({
				name: i + 1,
				active: Boolean(binaryNumber[i] === '1'),
				value: 1 << i,
			});
		}

		return (
			<>
				<div className={props.className ? props.className : ''}>
					<div className="seasonality-bar">
						{seasons.map((e: Season, i: number) => (
							<div
								key={i}
								className="seasonality-bar-element big"
								title={months[e.name - 1]}
							>
								<div
									id="square"
									className={e.active ? 'active text-center' : 'text-center'}
								>
									{months[e.name - 1].slice(0, 3)}
								</div>
							</div>
						))}
					</div>
				</div>
			</>
		);
	} else {
		return <></>;
	}
};

export default SeasonalityBarBig;
