import * as api from 'api/nutriScoreIngredientCategoryPoints/GetNutriScoreIngredientCategoryPointsV1';
import { IngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';

export const mapFromRequest = (
	input: api.NutriScoreIngredientCategoryPoint[]
): IngredientCategoryPoint[] => {
	return input.map((e: api.NutriScoreIngredientCategoryPoint) => {
		return {
			ingredientCategoryId: e.ingredientCategoryId,
			pointType: e.pointType,
			ingredientCategoryName: e.ingredientCategoryName,
			isPositive: e.isPositive,
			points: e.points,
		};
	});
};
