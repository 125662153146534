import { t } from 'i18next';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { IconsBar } from 'components/desktop/Recipe/IconsBar/IconsBar';
import { RecipeSummary } from 'components/desktop/Recipe/RecipeSummary/RecipeSummary';
import DetailHeader from 'components/desktop/_general/DetailHeader/DetailHeader';
import { ChannelMedia } from 'types/Media/ChannelMedia';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	entryLanguageCultureCode: string;
	handleSave: () => void;
	handleToggleOverlay: () => void;
	id: string | null;
	image: ChannelMedia | null;
	isLoading: boolean;
	masterLanguageCultureCode: string;
	readOnly: boolean;
	recipe: Recipe;
	recipeCalculation: Calculation | null;
	segmentInEntryLanguage: Segment[];
	nutriScore: NutriScore | null;
	setDisplayOverlay: (input: boolean) => void;
}

const RecipeHeader = (props: IProps) => {
	const getTitle = (): string => {
		if (props.recipe.titleTranslations[props.masterLanguageCultureCode]) {
			return props.recipe.titleTranslations[props.masterLanguageCultureCode];
		} else if (props.recipe.titleTranslations[props.entryLanguageCultureCode]) {
			return props.recipe.titleTranslations[props.entryLanguageCultureCode];
		}
		if (!props.id) return t('recipe:RECIPE_NEW');
		if (props.readOnly) return t('recipe:RECIPE_VIEW');
		return t('recipe:RECIPE_EDIT');
	};

	const renderBottomArea = (): JSX.Element => {
		return (
			<div
				className="d-flex flex-row justify-content-between align-items-end flex-grow-1"
				style={{ gap: '15px' }}
			>
				<IconsBar tags={props.recipe.tags} allergens={props.recipe.allergens} />
				<RecipeSummary
					calculation={props.recipeCalculation}
					segmentInEntryLanguage={props.segmentInEntryLanguage}
					recipe={props.recipe}
					nutriScore={props.nutriScore}
				/>
			</div>
		);
	};

	return (
		<DetailHeader
			handleSave={props.handleSave}
			title={getTitle()}
			image={props.image}
			isLoading={props.isLoading}
			readOnly={props.readOnly}
			bottomArea={renderBottomArea()}
			handleToggleOverlay={props.handleToggleOverlay}
			id={props.id ?? undefined}
			setDisplayOverlay={props.setDisplayOverlay}
		/>
	);
};

export default RecipeHeader;
