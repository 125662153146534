import * as apiGetByType from 'api/unit/GetUnitGetByTypeV1';
import { UnitType } from 'enums/unitType';
import { UnitLight } from 'types/Unit/UnitLight';

export type Type = {
	id: string;
	name: string;
	type: UnitType;
};

export function create(): Type {
	return {
		id: '',
		name: '',
		type: UnitType.Container,
	};
}

export function mapFromApi(input: apiGetByType.ResponseUnit, type: UnitType) {
	return {
		id: input.id,
		name: input.description,
		type: type,
	};
}

export function mapToUnitLight(obj: Type): UnitLight {
	return {
		id: obj.id,
		description: obj.name,
	};
}
