import { t } from 'i18next';

import { ValueScope } from 'enums/valueScope';

export function getValueScope(input: ValueScope): string {
	switch (input) {
		case ValueScope.Person:
			return `/ ${t('_general:PERSON')}`;
		case ValueScope.Parts:
			return `${t('_general:PART')}`;
		case ValueScope.HundredGrams:
			return `/ 100${t('measurments:GRAMM')}`;
		case ValueScope.Total:
			return ` ${t('_general:TOTAL')}`;
		default:
			return '';
	}
}
