import * as apiPostSearch from 'api/product/PostProductSearchV1';
import * as Search from 'types/Product/Search/Search';
import * as Range from 'types/_general/Store/Range';

export type Type = {
	co2: Range.Type;
	price: Range.Type;
};

export function create(): Type {
	return {
		co2: Range.create(),
		price: Range.create(),
	};
}

export async function getFromApi(): Promise<Type> {
	const response = await apiPostSearch.callApi(Search.mapToApi(Search.create()));
	const responseData = response.toNullable();
	if (responseData) {
		return mapFromApi(responseData);
	}
	return create();
}

export function mapFromApi(input: apiPostSearch.ResponseData): Type {
	return {
		co2: input.minMaxCarbonDioxideRange,
		price: input.minMaxPriceRange,
	};
}
