import { QueryParams } from 'functions/getQueryParams';
import { hasSliderChanges } from 'functions/sliderFunctions';

type Range = {
	minimum: number | null;
	maximum: number | null;
};

type Request = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export type NutrientInfo = {
	id: string;
	sortOrder: number;
	name: string | null;
	unitShortNameSingular: string | null;
	nutrientCategory: NutrientCategory;
};

export type NutrientCategory = {
	id: string;
	name: string | null;
};

export default class SliderWithId {
	public id: string;
	public name: string;
	unit: string;
	public min: number | null = null;
	public max: number | null = null;
	public left: number | null = null;
	public right: number | null = null;
	public minArea: number | null = null;
	public maxArea: number | null = null;

	public constructor(id: string, name: string, unit: string) {
		this.id = id;
		this.name = name;
		this.unit = unit;
	}

	public getMaxRoundUp(): number {
		if (this.max) {
			return Math.ceil(this.max);
		} else {
			return 0;
		}
	}

	public getMinRoundDown(): number {
		if (this.min) {
			return Math.floor(this.min);
		} else {
			return 0;
		}
	}

	public hasSliderChanges(left: number, right: number): boolean {
		return hasSliderChanges(left, right, this);
	}

	public reset() {
		this.min = null;
		this.max = null;
		this.left = null;
		this.right = null;
		this.minArea = null;
		this.maxArea = null;
	}

	public mapFromApi(input: Range, nutrientInfo: NutrientInfo) {
		if (nutrientInfo.name) {
			this.name = nutrientInfo.name;
		}
		this.minArea = input.minimum;
		this.maxArea = input.maximum;
		if (this.min === null) {
			this.min = input.minimum;
		}
		if (this.max === null) {
			this.max = input.maximum;
		}
		if (this.left === null) {
			this.left = input.minimum;
		}
		if (this.right === null) {
			this.right = input.maximum;
		}
	}

	public mapToApi(): Request {
		let minimum: number | null = null;
		let maximum: number | null = null;
		if (this.left !== this.min) minimum = this.left;
		if (this.right !== this.max) maximum = this.right;
		return {
			id: this.id,
			minimum: minimum,
			maximum: maximum,
		};
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				if (typeof value === 'boolean') return;
				const split: string[] = value.split(';');
				if (split[2]) this.left = Number(split[2]);
				if (split[3]) this.right = Number(split[3]);
				if (split[4]) this.min = Number(split[4]);
				if (split[5]) this.max = Number(split[5]);
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.hasNoValues()) return null;
		const output: string[] = [];
		output.push(this.name);
		if (this.unit === '%') {
			output.push('%25');
		} else {
			output.push(this.unit);
		}
		output.push(String(this.left ? this.left : this.min));
		output.push(String(this.right ? this.right : this.max));
		output.push(String(this.min ?? ''));
		output.push(String(this.max ?? ''));
		return this.id + '=' + output.join(';');
	}

	public hasNoValues(): boolean {
		return !this.min && !this.max && !this.left && !this.right;
	}
}
