import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faVideoSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import MediaCarousel from 'components/desktop/_general/MediaCarousel/MediaCarousel';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import 'components/desktop/_general/VideoCarousel/VideoCarousel.scss';
import { MEDIATYPES } from 'enums/mediaTypes';
import { validateFileSizes } from 'functions/validateFileSizes';
import { CarouselVideo } from 'types/Media/CarouselVideo';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';

interface IProps {
	handleDeleteVideo: (id: string) => void;
	readOnly: boolean;
	uploadVideo: (input: File[], video: boolean) => void;
	videos: SegmentMedia[];
}

const SegmentVideoCarousel = (props: IProps) => {
	const [videos, setVideos] = useState<CarouselVideo[]>([]);
	const [selectedVideo, setSelectedVideo] = useState<CarouselVideo | null>(null);
	const [displayVideo, setDisplayVideo] = useState<boolean>(false);
	const [thumbnailsAreLoading, setThumbnailsAreLoading] = useState<boolean>(false);

	const videoRef = useRef<HTMLVideoElement>(null);

	const handleOpenOverlay = (): void => setDisplayVideo(true);
	const handleCloseOverlay = (): void => setDisplayVideo(false);

	const loadCarouselVideos = async (videos: SegmentMedia[]) => {
		setThumbnailsAreLoading(true);
		const carouselVideos: CarouselVideo[] = [];

		for (const video of videos.filter((x) => x.id && x.url)) {
			let previewBlob: Blob | null = null;
			try {
				previewBlob = await getVideoCover(video.url, 0);
			} catch (e) {
				previewBlob = null;
			}

			const carouselVideo: CarouselVideo = {
				url: video.url,
				previewUrl: previewBlob != null ? URL.createObjectURL(previewBlob) : '',
				id: video.id,
			};

			carouselVideos.push(carouselVideo);
		}

		setThumbnailsAreLoading(false);
		return carouselVideos;
	};

	useEffect(() => {
		const updateVideos = async () => {
			const newCarouselVideos = await loadCarouselVideos(props.videos);

			setVideos(newCarouselVideos);

			if (newCarouselVideos.length > 0) {
				setSelectedVideo(newCarouselVideos[0]);
			}
		};
		updateVideos();
	}, [props.videos, props.videos.length]);

	const getVideoCover = (file: string, seekTo: number = 0.0): Promise<Blob> => {
		return new Promise((resolve, reject) => {
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', file);
			videoPlayer.crossOrigin = 'anonymous';
			videoPlayer.addEventListener('error', (_ex) => {
				reject('error when loading video file');
			});

			videoPlayer.addEventListener('loadeddata', () => {
				if (videoPlayer.duration < seekTo) {
					reject('video is too short.');
					return;
				}
				videoPlayer.currentTime = seekTo;
			});

			videoPlayer.addEventListener('seeked', () => {
				setTimeout(() => {
					const canvas = document.createElement('canvas');
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;

					const ctx = canvas.getContext('2d');
					ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

					ctx?.canvas.toBlob(
						(blob) => {
							if (blob === null) {
								reject('blob is null');
							} else {
								resolve(blob);
							}
						},
						'image/jpeg',
						0.75
					);
				}, 100);
			});

			videoPlayer.addEventListener('error', () => {
				reject('preview could not be created.');
				return;
			});
			videoPlayer.load();
		});
	};

	const handleUploadMedia = async (input: FileList): Promise<void> => {
		validateFileSizes(input).do((files) => {
			props.uploadVideo(Array.from(files), true);
		});
	};

	const handleVideoSelect = (video: CarouselVideo) => setSelectedVideo(video);

	const handleVideoRemove = (video: CarouselVideo) => {
		const resultVideos = videos.filter((v) => v.id !== video.id);
		setVideos(resultVideos);

		if (selectedVideo && selectedVideo.id === video.id && resultVideos.length > 0) {
			setSelectedVideo(resultVideos[0]);
		}

		if (resultVideos.length === 0) {
			setSelectedVideo(null);
		}

		props.handleDeleteVideo(video.id);
	};

	const renderThumbnail = (video: CarouselVideo, i: number) => {
		return (
			<div
				onClick={() => handleVideoSelect(video)}
				key={i}
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					borderBottom: '1px solid #ddd',
					height: '100px',
					backgroundColor:
						selectedVideo && selectedVideo.url === video.url ? '#d8d8d8' : 'white',
				}}
			>
				<img
					src={video.previewUrl}
					style={{
						objectFit: 'cover',
						width: props.readOnly ? '100%' : '70%',
						height: '100px',
					}}
				/>
				{props.readOnly ? null : (
					<div
						style={{ width: '30%' }}
						className="d-flex flex-column justify-content-center p-3"
					>
						<button
							type="button"
							className="btn btn-outline-secondary p-2"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title={t('_general:DELETE')}
							onClick={(e) => {
								e.stopPropagation();
								handleVideoRemove(video);
							}}
						>
							<FontAwesomeIcon icon={faTrash} />
						</button>
						<button
							type="button"
							className="btn btn-outline-secondary p-2"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title={t('_general:VIEW')}
							onClick={(e) => {
								e.stopPropagation();
								handleOpenOverlay();
							}}
						>
							<FontAwesomeIcon icon={faEye} />
						</button>
					</div>
				)}
			</div>
		);
	};

	const renderVideoPreview = (): JSX.Element => {
		if (thumbnailsAreLoading) {
			return (
				<div>
					<LoadingSpinner />
				</div>
			);
		}

		if (!selectedVideo) {
			return (
				<div>
					<FontAwesomeIcon icon={faVideoSlash as IconProp} size="7x" />
				</div>
			);
		}

		return (
			<div>
				<video
					ref={videoRef}
					src={selectedVideo.url}
					style={{ width: '100%', minWidth: '300px', maxHeight: '500px' }}
					controls
				/>
			</div>
		);
	};

	const renderVideoThumbnails = () => {
		return <>{videos?.map((video, i) => renderThumbnail(video, i))}</>;
	};

	return (
		<>
			<MediaCarousel
				handleUploadMedia={handleUploadMedia}
				renderMediaThumbnails={renderVideoThumbnails}
				buttonLabel={t('_general:UPLOAD_NEW_VIDEO')}
				accept={[MEDIATYPES.MP4]}
				renderMediaPreview={renderVideoPreview}
				disabled={props.readOnly}
			/>
			<Overlay
				contentStyle={{ maxHeight: '100vh' }}
				containerStyle={{ maxHeight: '100vh' }}
				handleClose={handleCloseOverlay}
				displayOverlay={displayVideo}
			>
				<video
					ref={videoRef}
					controls
					src={selectedVideo?.url}
					style={{ maxHeight: '500px' }}
				/>
			</Overlay>
		</>
	);
};

export default SegmentVideoCarousel;
