import { Recipe } from 'api/menu/GetMenuIdV1';
import { Menu } from 'types/Menu/Menu';
import { SaveMenuDto } from 'types/Menu/SaveMenuDto';
import { TagLight } from 'types/Tag/TagLight';

export function mapToSaveMenuDto(input: Menu): SaveMenuDto {
	return {
		nameTranslations: input.nameTranslations,
		recipes: mapRecipes(input.recipes),
		shortDescriptionTranslations: input.shortDescriptionTranslations,
		statusId: input.status.id,
		systemStatus: input.systemStatus,
		tags: mapTags(input.tags),
	};
}

function mapRecipes(recipes: Recipe[]): string[] {
	var output: string[] = [];

	for (const recipe of recipes) {
		output.push(recipe.id ?? '');
	}

	return output;
}

function mapTags(tags: TagLight[]): string[] {
	var output: string[] = [];

	for (const tag of tags) {
		output.push(tag.id);
	}

	return output;
}
