import MailAccountManagementComponent from 'components/desktop/StandingData/MailAccount/Management/MailAccountManagementComponent';

const MailAccountArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<MailAccountManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default MailAccountArchiveContainer;
