import * as api from 'api/recipe/PostRecipeCalculateV1';
import Allergen from 'classes/Recipe/Detail/Calculation/Allergen';

export default class AllergensPerIngredient {
	public ingredientIndex: number = 0;
	public ingredientId: string = '';
	public allergens: Allergen[] = [];

	public constructor(input?: api.AllergensPerIngredient) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.AllergensPerIngredient) {
		this.ingredientIndex = input.ingredientIndex;
		this.ingredientId = input.ingredientId;
		this.allergens = [];
		for (const i of input.allergens) {
			this.allergens.push(new Allergen(i));
		}
	}
}
