import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(key: string, request: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/UserSetting/${key}`;
	return axios
		.put(url, `"${request}"`, {
			headers: { 'Content-Type': 'application/json' },
		})
		.toOptionalVoid();
}

export type Request = string;
