import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Menu from 'classes/Menu/Search/Menu';
import Recipe from 'classes/Menu/Search/Recipe';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import 'components/desktop/Menu/Search/SearchResultList.scss';
import SearchResultThumbnailCard from 'components/desktop/Menu/Search/SearchResultThumbnailCard';
import BtnGroup from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { getPriceString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive: boolean;
	handleDeleteMenu: (input: Menu) => Promise<void>;
	handleRestoreMenu: (input: Menu) => Promise<void>;
	menu: Menu;
}

const SearchResultListRow = (props: IProps) => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const menuPlanId: string | undefined = params.menuPlanId;
	const rowIndex: string | undefined = params.menuPlanRowIndex;
	const day: string | undefined = params.menuPlanDay;

	const getValueScope = (): string => {
		return getValueScopeLabel(ValueScope.Person, null, true);
	};

	const renderInformation = (): JSX.Element => {
		return (
			<div className="d-flex flex-column justify-content-between information">
				<div>
					<div id="title-info-box">
						<strong>{props.menu.nameTranslation}</strong>
					</div>
					<div>
						{!!props.menu.calories && (
							<>
								<span className="icon">
									<FontAwesomeIcon icon={faUtensils as IconProp} />
								</span>
								<b>
									{getNumberString(props.menu.calories, reduxCultureCode, true)}
								</b>
								{` ${t('_general:CALORIES')} ${getValueScope()}`}
							</>
						)}
						&nbsp;
					</div>
					<div>
						{!!props.menu.price && (
							<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
								<>
									<span className="icon">
										<FontAwesomeIcon icon={faDollarSign as IconProp} />
									</span>
									<b>{getPriceString(props.menu.price, reduxCultureCode)}</b>
									{` ${reduxCurrency} ${getValueScope()}`}
								</>
							</RenderIf>
						)}
						&nbsp;
					</div>
				</div>
				<div>{renderButtons()}</div>
			</div>
		);
	};

	const renderButtons = (): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit()}
				delete={getButtonDataDelete()}
				add={getButtonDataAdd()}
				copy={getButtonDataCopy()}
				restore={getButtonDataRestore()}
				view={getButtonDataView()}
			/>
		);
	};

	const getButtonDataCopy = (): BtnData | undefined => {
		if (!props.archive && !params.menuPlanId && menuEditor) {
			return {
				onClick: () => {
					navigate(`/menu/copy/${props.menu.id}`);
				},
			};
		}
	};

	const getButtonDataDelete = (): BtnData | undefined => {
		if (!props.archive && !params.menuPlanId && menuEditor) {
			return {
				onClick: () => props.handleDeleteMenu(props.menu),
			};
		}
	};

	const getButtonDataEdit = (): BtnData | undefined => {
		if (!props.archive && !params.menuPlanId && menuEditor) {
			return {
				onClick: () => {
					navigate(`/menu/detail/${props.menu.id}`);
				},
			};
		}
	};

	const getButtonDataView = (): BtnData | undefined => {
		if (!props.archive && !params.menuPlanId && !menuEditor) {
			return {
				onClick: () => {
					navigate(`/menu/detail/${props.menu.id}`);
				},
			};
		}
	};

	const getButtonDataAdd = (): BtnData | undefined => {
		if (menuPlanId && rowIndex && day && !props.archive) {
			return {
				onClick: addToMenu,
			};
		}
	};

	const getButtonDataRestore = (): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => props.handleRestoreMenu(props.menu),
			};
		}
	};

	const addToMenu = async (): Promise<void> => {
		if (menuPlanId && rowIndex && day) {
			const menuPlan = new MenuPlan();
			await menuPlan.callApiGet(menuPlanId);
			menuPlan.setMenuToRow(Number(day), Number(rowIndex), props.menu.id);
			await menuPlan.saveToApi();
		}
		navigate(`/menuPlan/detail/${params.menuPlanId}`);
	};

	const getAllRecipes = (): Recipe[] => {
		if (props.menu.recipeList.all) {
			return props.menu.recipeList.all;
		}
		return [];
	};

	return (
		<div className="d-flex flex-row" style={{ gap: '10px' }}>
			{renderInformation()}
			<div className="recipe-row">
				<div className="d-flex flex-row" style={{ gap: '10px' }}>
					{getAllRecipes().map((e: Recipe, i: number) => {
						return (
							<div key={i}>
								<SearchResultThumbnailCard recipe={e} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SearchResultListRow;
