import * as apiSW from 'api/tag/GetTagStartingWithV1';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import Tag from 'classes/_general/Search/Tag/Tag';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class TagList implements Selectable {
	public all: Tag[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public getById(id: string): Tag | undefined {
		return this.all.find((e: Tag) => {
			return e.id === id;
		});
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Tag[] = this.all.filter((e: Tag) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	private mapToSelectItems(input: Tag[]): ISelectItem[] {
		return input.map((e: Tag) => {
			return e.mapToSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseTag[]): void {
		this.all = [];
		for (const i of input) {
			const tag: Tag = new Tag(i);
			this.all.push(tag);
		}
	}
}
