import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

interface IProps {
	defaultValue: string;
	handleValueChange: (value: string) => void;
	validateRegex: RegExp;
	className?: string;
	disabled?: boolean;
	disabledValue?: string;
	handleEnterDown?: (value: string) => void;
	id?: string;
	placeholder?: string;
	style?: React.CSSProperties;
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const InputFieldSimple: React.FC<IProps> = (props: IProps) => {
	const [value, setValue] = useState<string>('0');

	useEffect((): void => {
		setValue(props.defaultValue);
	}, [props.defaultValue]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value: string = event.target.value;
		setValue(value);
	};

	const onBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value: string = event.target.value;
		if (validateValue(value)) {
			props.handleValueChange(value);
		} else {
			setValue(props.defaultValue);
		}
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (props.handleEnterDown) {
			if (event.key === 'Enter') {
				props.handleEnterDown(value);
			}
		}
	};

	const validateValue = (value: string): boolean => {
		const isValid: boolean = props.validateRegex.test(value);
		return isValid;
	};

	if (props.disabled === true && props.disabledValue) {
		return (
			<input
				className={props.className ? 'form-control ' + props.className : 'form-control'}
				style={props.style}
				disabled={true}
				id={props.id ?? ''}
				placeholder={props.placeholder ?? ''}
				type="text"
				value={props.disabledValue}
				onClick={props.onClick}
			/>
		);
	} else {
		return (
			<input
				className={props.className ? 'form-control ' + props.className : 'form-control'}
				style={props.style}
				disabled={props.disabled ?? false}
				id={props.id ?? ''}
				onBlur={onBlur}
				onChange={onChange}
				onKeyDown={onKeyDown}
				placeholder={props.placeholder ?? ''}
				type="text"
				value={value}
				onClick={props.onClick}
			/>
		);
	}
};

export default InputFieldSimple;
