import { t } from 'i18next';

import { ToggleItem } from 'types/_general/ToggleItem';

interface IProps {
	toggleList: ToggleItem[];
	handleChange: (input: ToggleItem) => void;
	handleReset: () => void;
}

const MenuPlanStatusSelect = (props: IProps) => {
	const isEveryItemFalse = (input: ToggleItem[]): boolean => {
		for (const i of input) {
			if (i.checked) {
				return false;
			}
		}
		return true;
	};

	const handleToggle = (toggle: ToggleItem): void => {
		toggle.checked = !toggle.checked;
		if (isEveryItemFalse(props.toggleList)) {
			props.handleReset();
		}
		props.handleChange(toggle);
	};

	const renderToggle = (toggle: ToggleItem) => {
		return (
			<div className="form-check form-switch">
				<label className="form-check-label" htmlFor={toggle.id}>
					{t(toggle.label)}
				</label>
				<input
					className="form-check-input"
					type="checkbox"
					role="switch"
					id={toggle.id}
					onChange={() => handleToggle(toggle)}
					checked={toggle.checked}
				/>
			</div>
		);
	};

	return (
		<div>
			<label>{t('recipe:STATUS')}</label>
			{props.toggleList.map((e: ToggleItem, i: number) => {
				return (
					<div key={i} className="mt-1">
						{renderToggle(e)}
					</div>
				);
			})}
		</div>
	);
};

export default MenuPlanStatusSelect;
