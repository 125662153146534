import AccessoryComponent from 'components/desktop/Accessory/AccessoryComponent';

const AccessoryNewContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<AccessoryComponent />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryNewContainer;
