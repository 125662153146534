import { useTranslation } from 'react-i18next';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import ENTITLEMENTS from 'enums/entitlements';
import { asUndefined } from 'functions/coercions';
import { getPriceString } from 'functions/numberToString';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';

interface IProps {
	calculation: Calculation | null;
	nutrients: CalculatedNutrient[];
	currency: string;
	cultureCode: string;
	price: number | null;
}

const RecipeGeneralInformationsComponent = (props: IProps) => {
	const { t } = useTranslation();

	const getCosts = (): JSX.Element => {
		return (
			<>
				<RenderIf condition={Boolean(props.price)}>
					<>
						{props.currency + ' '}
						<b>{getPriceString(props.price!, props.cultureCode)}</b>
					</>
				</RenderIf>
				<RenderIf condition={!props.price}>
					<span className="grey">{t('recipe:NO_PRICE')}</span>
				</RenderIf>
			</>
		);
	};

	const getAllergens = (): JSX.Element => {
		if (props.calculation && props.calculation.allergens) {
			return (
				<div className="overflow-auto">
					{props.calculation.allergens.items.map((allergen, i) => (
						<ListItem
							iconSvgUrl={asUndefined(allergen.iconSvgUrl)}
							key={i}
							text={allergen.allergen}
							textColor={allergen.logic === 'Positive' ? '#e35359' : undefined}
						/>
					))}
				</div>
			);
		} else {
			return <span className="grey">{t('_general:NO_ALLERGENS')}</span>;
		}
	};

	const getSeasons = (): JSX.Element => {
		if (props.calculation && props.calculation.seasons) {
			return (
				<div className="overflow-auto">
					{props.calculation.seasons.items.map((season, i) => (
						<ListItem
							iconSvgUrl={asUndefined(season.iconSvgUrl)}
							key={i}
							text={season.season}
						/>
					))}
				</div>
			);
		} else {
			return <span className="grey">{t('_general:NO_SEASONS')}</span>;
		}
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (props.nutrients) {
			const macroWeight = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.reduce((p, c) => p + c.total, 0);
			const macros = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.map((n) => {
					return { label: n.nutrient, value: n.total / macroWeight };
				});
			return macros;
		}
		return [];
	};

	return (
		<>
			<div className="margin-top-25">
				<div className="row">
					<div className="col-4">
						<FoldableCard
							content={<NutritionDiagram data={getMacroNutrientChartData()} />}
							defaultOpen={true}
							title={t('_general:NUTRI_DIAGRAM')}
						/>
					</div>
					<div className="col-4">
						<FoldableCard
							content={getAllergens()}
							defaultOpen={true}
							info={'_general:ALLERGEN_INFO_TOOLTIP'}
							title={t('_general:ALLERGENS')}
						/>
					</div>
					<div className="col-4">
						<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
							<FoldableCard
								defaultOpen={true}
								title={t('_general:COSTS')}
								content={getCosts()}
							/>
							<br />
						</RenderIf>
						<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
							<FoldableCard
								content={getSeasons()}
								defaultOpen={true}
								title={t('_general:SEASONS')}
							/>
						</RenderIf>
					</div>
				</div>
			</div>
		</>
	);
};

export default RecipeGeneralInformationsComponent;
