interface IProps {
	id: string;
	value: boolean | null;
	label: string;
	status: boolean | null;
	onClick: (value: true | false | null) => void;
}

const getStatus = (input: boolean | null, status: boolean | null) => {
	if (status === input) return true;
	return false;
};

const RadioButton = (props: IProps) => {
	return (
		<div className="form-check">
			<input
				className="form-check-input"
				type="radio"
				id={props.id}
				checked={getStatus(props.value, props.status)}
				onChange={() => props.onClick(props.value)}
			/>
			<label className="form-check-label" htmlFor={props.id}>
				{props.label}
			</label>
		</div>
	);
};

export default RadioButton;
