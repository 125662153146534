import { MailTemplateAdvancedSearchDto } from 'types/MailTemplate/Search/MailTemplateAdvancedSearchDto';

export function mapAdvancedSearchToUrl(advancedSearch: MailTemplateAdvancedSearchDto): string {
	const output: string[] = [];
	if (advancedSearch.searchTerm !== undefined) output.push('st=' + advancedSearch.searchTerm);
	if (advancedSearch.pageIndex !== undefined) output.push('pi=' + advancedSearch.pageIndex);
	if (advancedSearch.pageSize !== undefined) output.push('ps=' + advancedSearch.pageSize);
	if (advancedSearch.id !== undefined) output.push('id=' + advancedSearch.id);
	return '?' + output.join('&');
}

export function mapStateToAdvancedSearch(state: any): MailTemplateAdvancedSearchDto {
	return {
		pageIndex: state.pageIndex,
		pageSize: state.pageSize,
		searchTerm: state.searchTerm,
		id: state.id,
	};
}

export function mapUrlToAdvancedSearch(params: URLSearchParams): MailTemplateAdvancedSearchDto {
	return {
		searchTerm: params.get('st') ?? '',
		pageIndex: Number(params.get('pi')),
		pageSize: Number(params.get('ps')),
		id: params.get('id') === 'null' ? null : params.get('id'),
	};
}
