import * as apiPostMedia from 'api/media/PostMediaV1';
import { MEDIACOLLECTION } from 'enums/mediaCollection';

type InputObject = {
	id?: string | null;
	profilePicture?: Media | null;
	images?: Media[];
	image?: Media | null;
	videos?: Media[];
	iconSvg?: Media | null;
	positiveIconSvg?: Media | null;
	negativeIconSvg?: Media | null;
	mayContainIconSvg?: Media | null;
};

type Media = {
	id: string | null;
	url: string | null;
	availableSizes: number[] | null;
};

export async function uploadMedia<T extends InputObject>(
	inputObject: T,
	formData: FormData | null,
	mediaCollection: MEDIACOLLECTION,
	specialAttributeName?: 'positiveIconSvg' | 'negativeIconSvg' | 'mayContainIconSvg'
): Promise<T> {
	switch (mediaCollection) {
		case MEDIACOLLECTION.User:
			inputObject.profilePicture = await uploadAsset(formData, mediaCollection);
			break;
		case MEDIACOLLECTION.Recipe: {
			const image: Media | null = await uploadAsset(formData, mediaCollection);
			if (image) {
				return {
					...inputObject,
					image: inputObject.image ? inputObject.image : image,
					images:
						inputObject.image && inputObject.images
							? [...inputObject.images, ...[image]]
							: inputObject.images,
				};
			}
			return { ...inputObject };
		}
		case MEDIACOLLECTION.RecipeVideo: {
			const video = await uploadAsset(formData, mediaCollection);
			return {
				...inputObject,
				videos: inputObject.videos ? inputObject.videos.addIfNotNull(video) : [video],
			};
		}
		case MEDIACOLLECTION.Icon:
			if (specialAttributeName) {
				inputObject[specialAttributeName] = await uploadAsset(formData, mediaCollection);
			} else {
				inputObject.iconSvg = await uploadAsset(formData, mediaCollection);
			}
			break;
		default:
			inputObject.image = await uploadAsset(formData, mediaCollection);
			break;
	}

	return inputObject;
}

async function uploadAsset(
	formData: FormData | null,
	mediaCollection: MEDIACOLLECTION
): Promise<Media | null> {
	const media = await apiPostMedia.callApi({
		formData: formData,
		mediaCollection: mediaCollection,
	});
	return media.toNullable();
}
