import { ContextBuilder } from 'context/nutriscoreLabelContext';

import RecipeSearchComponent from 'components/desktop/Recipe/Search/RecipeSearchComponent';

const RecipeArchiveContainer = () => {
	return (
		<main className="container">
			<RecipeSearchComponent archive={true} />
		</main>
	);
};

export default ContextBuilder.From(RecipeArchiveContainer).wrapWithNutriscoreContext().build();
