import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';
import { ValidationEntityType } from 'types/Validation/ValidationEntityType';

export async function callApi(
	type: ValidationEntityType,
	id: string,
	acknowledge: boolean
): Promise<Optional<void>> {
	const url: string =
		'/backend-service/validatorResults/Acknowledge/' +
		type +
		'/' +
		id +
		'/' +
		String(acknowledge);
	return axios.patch(url).toOptionalVoid();
}
