import { useTranslation } from 'react-i18next';

import * as apiSimilarRecipes from 'api/aiFunctions/PostSimilarRecipesV1';
import RecipeCard from 'components/desktop/_general/Card/RecipeCard/RecipeCard';
import CardSlider, { SliderCard } from 'components/desktop/_general/CardSlider/CardSlider';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { useApi } from 'hooks/useApi';

interface IProps {
	recipeId: string | null;
	recipeText: string | null;
	uiLanguage: string;
}

const SimilarRecipesList = (props: IProps) => {
	const { t } = useTranslation();

	const [recipes, isLoadingDone] = useApi(
		[],
		async (recipeId: string | null, recipeText: string | null) => {
			const response = await apiSimilarRecipes.callApi(recipeId, 8, recipeText);
			return response.getOrDefault([]);
		},
		props.recipeId,
		props.recipeText
	);

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={!isLoadingDone} />
				{recipes.length > 0 ? (
					<>
						<CardSlider
							cards={recipes.map(
								(x) =>
									({
										content: <RecipeCard recipe={x} />,
									}) as SliderCard
							)}
						/>
					</>
				) : (
					<div style={{ textAlign: 'center' }}>
						<h2>{t('recipe:NO_SIMILAR_RECIPES_FOUND')}</h2>
					</div>
				)}
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default SimilarRecipesList;
