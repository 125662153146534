import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetBaseIngredientCategories from 'api/baseIngredientCategory/GetAllBaseIngredientCategoryV1';
import * as apiGet from 'api/nutriScoreBaseIngredientCategoryPoints/GetNutriScoreBaseIngredientCategoryPointsIdV1';
import * as apiPost from 'api/nutriScoreBaseIngredientCategoryPoints/PostNutriScoreBaseIngredientCategoryPointsV1';
import * as apiPut from 'api/nutriScoreBaseIngredientCategoryPoints/PutNutriScoreBaseIngredientCategoryPointsV1';
import BaseIngredientSelect from 'components/desktop/StandingData/BaseIngredientCategoryPoints/BaseIngredientSelect';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NutriScorePointsComponent from 'components/desktop/_general/NutriScorePoints/NutriScorePointsComponent';
import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { SelectOption } from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import { NutriScoreCategoryBaseIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryBaseIngredientCategory';
import PERMISSIONS from 'enums/permissions';
import { mapFromRequest } from 'functions/mappers/BaseIngredientCategoryPoints/mapFromRequest';
import { mapToRequest } from 'functions/mappers/BaseIngredientCategoryPoints/mapToRequest';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { BaseIngredientCategoryLight } from 'types/BaseIngredientCategory/BaseIngredientCategoryLight';
import { BaseIngredientCategoryPoint } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPoint';
import { defaultBaseIngredientCategoryPoint } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPoint';

type ParsedUrl = {
	id: string;
	pointType: string;
};

const BaseIngredientCategoryPointsDetail = () => {
	const { t } = useTranslation();
	const isUserAdmin: boolean = arePermissionsInUserPermissions([PERMISSIONS.ADMINISTRATE]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [nutriScoreNutrient, setNutriScoreNutrient] = useState<BaseIngredientCategoryPoint>(
		defaultBaseIngredientCategoryPoint()
	);
	const [baseIngredientCategories, setBaseIngredientCategories] = useState<
		BaseIngredientCategoryLight[]
	>([]);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		await getBaseIngredientCategories();
		const parsedUrl: ParsedUrl | null = parseUrl();
		if (parsedUrl === null) return;
		setIsLoading(true);
		const response = await apiGet.callApi(
			parsedUrl.id,
			parsedUrl.pointType as NutriScoreCategoryBaseIngredientCategory
		);
		response.do((x) => setNutriScoreNutrient(mapFromRequest(x)));
		setIsLoading(false);
	};

	const parseUrl = (): ParsedUrl | null => {
		let output: ParsedUrl | null = null;
		if (window.location.href.includes('?')) {
			if (window.history.state.id !== undefined) {
				const id: string = window.history.state.id;
				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;
				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			} else {
				const url = new URL(String(window.location)).searchParams;
				const id: string | null = url.get('id') === null ? null : url.get('pointType');
				if (!id) return output;

				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;

				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			}
		}
		return output;
	};

	const handleSaveNutriScoreNutrient = (): void => {
		const id: string | null = getIdFromUrl();
		if (id === null) return;

		if (id === 'new') {
			postNutriScoreNutrient();
		} else {
			putNutriScoreNutrient();
		}
	};

	const getBaseIngredientCategories = async () => {
		try {
			const response = await apiGetBaseIngredientCategories.callApi();
			if (response.hasValue()) {
				const groups: BaseIngredientCategoryLight[] =
					response.get().baseIngredientCategories;
				setBaseIngredientCategories([...groups]);
			}
		} catch (e: unknown) {
			console.error(e);
		}
	};

	const renderNutrientSelect = (): JSX.Element => {
		const id: string | null = getIdFromUrl();
		if (id === null) return <></>;

		if (id === 'new' && isUserAdmin) {
			return (
				<BaseIngredientSelect
					baseIngredientCategories={baseIngredientCategories}
					baseIngredientCategoryPoint={nutriScoreNutrient}
					setBaseIngredientCategoryPoint={setNutriScoreNutrient}
				/>
			);
		}
		return (
			<>
				<label className="input-label">{t('standingData:CHANGE_CATEGORIES')}</label>
				<div className="input-group mb-3">
					<input
						className="form-control"
						placeholder={nutriScoreNutrient.baseIngredientCategoryName ?? ''}
						disabled={true}
					/>
				</div>
			</>
		);
	};

	const putNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const putObject: apiPut.Request = mapToRequest(nutriScoreNutrient);
			await apiPut.callApi(putObject);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const postNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const postObject: apiPost.Request = mapToRequest(nutriScoreNutrient);
			await apiPost.callApi(postObject);
			if (!nutriScoreNutrient.baseIngredientCategoryId) return;
			setIdToUrl(
				nutriScoreNutrient.baseIngredientCategoryId + ':' + nutriScoreNutrient.pointType
			);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const getSelectOptions = (): SelectOption[] => {
		return Object.keys(NutriScoreCategoryBaseIngredientCategory).map((key) => {
			return {
				id: key,
				text: key,
			};
		});
	};

	const handlePointTypeChange = (pointType: string): void => {
		const nutriScoreNutrientCpy: BaseIngredientCategoryPoint = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.pointType = pointType as NutriScoreCategoryBaseIngredientCategory;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	const handleTogglePositive = (input: boolean): void => {
		const nutriScoreNutrientCpy: BaseIngredientCategoryPoint = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.isPositive = input;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div style={{ minHeight: '400px' }}>
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							<h2>
								{nutriScoreNutrient.baseIngredientCategoryName
									? nutriScoreNutrient.baseIngredientCategoryName
									: '–'}
							</h2>
							<div>
								<span className="badge bg-secondary">
									{nutriScoreNutrient.pointType}
								</span>
							</div>
						</div>
						<div>
							{isUserAdmin && (
								<BtnSave
									handleSave={() => handleSaveNutriScoreNutrient()}
									isLoading={isLoadingSave}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="d-flex" style={{ gap: '20px' }}>
					<div style={{ width: '220px' }}>
						<label style={{ marginTop: 0 }}>{t('standingData:CHANGE_CATEGORY')}:</label>
						<SelectSimple
							options={getSelectOptions()}
							currentValue={nutriScoreNutrient.pointType}
							handleValueChange={handlePointTypeChange}
							disabled={!isUserAdmin || getIdFromUrl() !== 'new'}
							errorKey="PointType"
						/>
						<hr />
						<Toggle
							checked={nutriScoreNutrient.isPositive ?? false}
							handleToggle={handleTogglePositive}
							elementId="isPositive"
							label="_general:POSITIVE"
							className="mb-2"
							errorKey="IsPositive"
						/>
					</div>
					<div style={{ width: '900px' }}>
						{renderNutrientSelect()}
						<NutriScorePointsComponent
							input={nutriScoreNutrient}
							setInput={setNutriScoreNutrient}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BaseIngredientCategoryPointsDetail;
