import * as apiPostMenuSearch from 'api/menu/PostMenuSearchV1';
import * as apiPostAutoFill from 'api/menuPlanner/PostMenuPlannerGetAutoFillV1';
import * as apiPostRecipeSearch from 'api/recipe/PostRecipeSearchV1';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { AllergenLogic } from 'enums/allergenLogic';
import { SearchLogic } from 'enums/searchLogic';
import { IAllergenSelectItem } from 'interfaces/IAllergenSelectItem';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';
import * as Slider from 'types/_general/Slider/Slider';
import * as SliderWithId from 'types/_general/Slider/SliderWithId';
import * as SliderWithIdList from 'types/_general/Slider/SliderWithIdList';
import { ToggleItem } from 'types/_general/ToggleItem';

export type PinnedItem = {
	day: number;
	id: string;
	type: MENUPLANNERITEMTYPE;
};

export type Type = {
	accessories: IExclusionLogicSelectItem[];
	allergens: IAllergenSelectItem[];
	caloriesLimit: number | null;
	carbonDioxideLabels: Co2Label[];
	categories: ISelectItem[];
	ingredients: IExclusionLogicSelectItem[];
	numberOfItems: number | null;
	nutriScoreLabels: NutriScoreLabel[];
	pinnedItems: PinnedItem[];
	priceLimit: number | null;
	seasons: ISelectItem[];
	sliderCalories: Slider.Type;
	sliderCarbonDioxide: Slider.Type;
	sliderNutrient: SliderWithId.Type[];
	sliderPrice: Slider.Type;

	statusToggleList: ToggleItem[];
	tags: ISelectItem[];
	total: number;
	count: {
		recipes: number;
		menues: number;
	};
};

export function create(): Type {
	return {
		accessories: [],
		allergens: [],
		caloriesLimit: null,
		carbonDioxideLabels: [],
		categories: [],
		ingredients: [],
		numberOfItems: 7,
		nutriScoreLabels: [],
		pinnedItems: [],
		priceLimit: null,
		seasons: [],
		sliderCalories: Slider.create(),
		sliderCarbonDioxide: Slider.create(),
		sliderNutrient: [],
		sliderPrice: Slider.create(),
		statusToggleList: [],
		tags: [],
		total: 0,
		count: {
			recipes: 0,
			menues: 0,
		},
	};
}

export async function initialize(
	search: Type,
	status: ToggleItem[],
	caloriesLimit: number | null,
	priceLimit: number | null
): Promise<Type> {
	const adjustedSearch = {
		...search,
		sliderCalories: Slider.initialize(caloriesLimit),
		sliderPrice: Slider.initialize(priceLimit),
	};
	const recipes = await getFromApiRecipeSearch(adjustedSearch);
	const menues = await getFromApiMenuSearch(adjustedSearch);
	return mapFromApi(
		{
			...adjustedSearch,
			statusToggleList: status,
		},
		recipes,
		menues
	);
}

export async function update(search: Type): Promise<Type> {
	const recipes = await getFromApiRecipeSearch(search);
	const menues = await getFromApiMenuSearch(search);
	return mapFromApi(search, recipes, menues);
}

export function reset(search: Type): Type {
	return {
		...search,
		accessories: [],
		allergens: [],
		carbonDioxideLabels: [],
		categories: [],
		ingredients: [],
		nutriScoreLabels: [],
		seasons: [],
		tags: [],
		sliderCalories: Slider.reset(search.sliderCalories),
		sliderPrice: Slider.reset(search.sliderPrice),
		sliderCarbonDioxide: Slider.reset(search.sliderCarbonDioxide),
		sliderNutrient: SliderWithIdList.reset(search.sliderNutrient),
		statusToggleList: resetStatusList(search.statusToggleList),
	};
}

export function resetStatusList(input: ToggleItem[]): ToggleItem[] {
	return input.map((e) => {
		return { ...e, checked: true };
	});
}

function mapToApiBasic(obj: Type) {
	return {
		allergenIds: mapToAllergenLogicMap(obj.allergens),
		caloriesRange: Slider.mapToApi(obj.sliderCalories),
		carbonDioxideLabelIds: obj.carbonDioxideLabels.map((e) => e.id),
		carbonDioxideRange: Slider.mapToApi(obj.sliderCarbonDioxide),
		nutrientRanges: SliderWithIdList.mapToApi(obj.sliderNutrient),
		priceRange: Slider.mapToApi(obj.sliderPrice),
		seasonIds: obj.seasons.map((e) => e.id),
		statusIds: obj.statusToggleList.filter((e) => e.checked).map((e) => e.id),
		tagIds: obj.tags.map((e) => e.id),
		pageSize: 1,
	};
}

export function mapToApiMenuSearch(obj: Type): apiPostMenuSearch.Request {
	return mapToApiBasic(obj);
}

export function mapToApiRecipeSearch(obj: Type): apiPostRecipeSearch.Request {
	return {
		...mapToApiBasic(obj),
		accessories: mapToSearchLogicMap(obj.accessories),
		categoryIds: obj.categories.map((e) => e.id),
		ingredients: mapToSearchLogicMap(obj.ingredients),
		nutriScoreLabelIds: obj.nutriScoreLabels.map((e) => e.ids).flat(),
		tagIds: obj.tags.map((e) => e.id),
	};
}

export function mapToApiAutoFill(obj: Type): apiPostAutoFill.Request {
	return {
		accessories: mapToSearchLogicMap(obj.accessories),
		allergenIds: mapToAllergenLogicMap(obj.allergens),
		caloriesRange: Slider.mapToApi(obj.sliderCalories),
		categoryIds: obj.categories.map((category: ISelectItem) => {
			return category.id;
		}),
		carbonDioxideRange: Slider.mapToApi(obj.sliderCarbonDioxide),
		carbonDioxideLabelIds: obj.carbonDioxideLabels.map((co2Label: Co2Label) => {
			return co2Label.id;
		}),
		nutriScoreLabelIds: obj.nutriScoreLabels.map((e) => e.ids).flat(),
		nutrientRanges: SliderWithIdList.mapToApi(obj.sliderNutrient),
		ingredients: mapToSearchLogicMap(obj.ingredients),
		seasonIds: obj.seasons.map((season: ISelectItem) => {
			return season.id;
		}),
		statusIds: obj.statusToggleList.map((status: ToggleItem) => {
			return status.id;
		}),
		priceRange: Slider.mapToApi(obj.sliderPrice),
		tagIds: obj.tags.map((tag: ISelectItem) => {
			return tag.id;
		}),
		pinnedItems: obj.pinnedItems,
		numberOfItems: obj.numberOfItems,
		caloriesLimit: obj.caloriesLimit,
		priceLimit: obj.priceLimit,
	};
}

function mapToAllergenLogicMap(input: { id: string; logic: AllergenLogic }[]): {
	[key: string]: AllergenLogic;
} {
	return input.toDictionary(
		(x) => x.id,
		(x) => x.logic
	);
}

function mapToSearchLogicMap(input: { id: string; logic: SearchLogic }[]): {
	[key: string]: SearchLogic;
} {
	return input.toDictionary(
		(x) => x.id,
		(x) => x.logic
	);
}

async function getFromApiMenuSearch(obj: Type): Promise<apiPostMenuSearch.ResponseData> {
	const menues = await apiPostMenuSearch.callApi(mapToApiMenuSearch(obj));
	return menues.get();
}

async function getFromApiRecipeSearch(obj: Type): Promise<apiPostRecipeSearch.ResponseData> {
	const recipes = await apiPostRecipeSearch.callApi(mapToApiRecipeSearch(obj));
	return recipes.get();
}

function mapFromApi(
	search: Type,
	recipes: apiPostRecipeSearch.ResponseData,
	menues: apiPostMenuSearch.ResponseData
): Type {
	return {
		...search,
		count: {
			recipes: recipes.totalCount,
			menues: menues.totalCount,
		},
		sliderCalories: Slider.mapFromApiSpecial(
			search.sliderCalories,
			menues.minMaxCaloriesRange,
			recipes.minMaxCaloriesRange
		),
		sliderCarbonDioxide: Slider.mapFromApiSpecial(
			search.sliderCarbonDioxide,
			menues.minMaxCarbonDioxideRange,
			recipes.minMaxCarbonDioxideRange
		),
		sliderPrice: Slider.mapFromApiSpecial(
			search.sliderPrice,
			menues.minMaxPriceRange,
			recipes.minMaxPriceRange
		),
		sliderNutrient: SliderWithIdList.mapFromApiSpecial(
			search.sliderNutrient,
			menues.minMaxNutrientRanges,
			recipes.minMaxNutrientRanges
		),
	};
}
