import { SearchLogic } from 'enums/searchLogic';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';

type Input = {
	id: string;
	name?: string;
	description?: string | null;
	label?: string;
};

export function mapToIExclusionLogicSelectItem(input: Input[]): IExclusionLogicSelectItem[] {
	return input.map<IExclusionLogicSelectItem>((x) => ({
		id: x.id,
		name: mapName(x),
		logic: SearchLogic.Include,
	}));
}

function mapName(input: Input): string {
	if (input.name) {
		return input.name;
	} else if (input.description) {
		return input.description;
	} else if (input.label) {
		return input.label;
	} else {
		return '';
	}
}
