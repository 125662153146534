import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(putObject: Request['status']): Promise<Optional<void>> {
	const url = '/backend-service/Status';
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	status: RequestStatus[];
}

export type RequestStatus = {
	id: string;
	order: number;
};
