import i18n from 'i18next';

import { AirUrlParamsClass } from 'hooks/useAirNavigation';

export type Type = {
	cultureCode: string;
};

export function create(): Type {
	return {
		cultureCode: 'en-US',
	};
}

export function getFromUrl(p: AirUrlParamsClass): Type {
	const params = p.params;
	if (!params.cultureCode) return create();
	const output: Type = {
		cultureCode: String(params.cultureCode),
	};
	i18n.changeLanguage(getLanguage(output));
	return output;
}

export function getLanguage(obj: Type): string {
	return obj.cultureCode.split('-')[0];
}
