import { t } from 'i18next';

interface IProps {
	checked: boolean;
	disabled?: boolean;
	elementId: string;
	errorKey?: string;
	handleChange: (checked: boolean, elementId: string) => void;
	label: string;
}

const RadioButton = (props: IProps) => {
	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const checked: boolean = e.target.checked;
		props.handleChange(checked, props.elementId);
	};

	return (
		<div className="form-check">
			<input
				className="form-check-input"
				type="radio"
				id={props.elementId}
				error-key={props.errorKey}
				checked={props.checked}
				onChange={handleCheck}
				disabled={props.disabled}
			/>
			<label className="form-check-label" htmlFor={props.elementId}>
				{t(props.label)}
			</label>
		</div>
	);
};

export default RadioButton;
