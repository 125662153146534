import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';

export default class Nutrient {
	public nutrientId: string = '';
	public nutrient: string | null = null;
	public categoryId: string = '';
	public category: string | null = null;
	public total: number = 0;
	public unitId: string = '';
	public unit: string | null = null;
	public isMacroNutrient: boolean = false;
	public nutrientCategorySortOrder: number = 0;

	public constructor(input?: api.Nutrient) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Nutrient): void {
		this.nutrientId = input.nutrientId;
		this.nutrient = input.nutrient;
		this.categoryId = input.categoryId;
		this.category = input.category;
		this.total = input.total;
		this.unitId = input.unitId;
		this.unit = input.unit;
		this.isMacroNutrient = input.isMacroNutrient;
		this.nutrientCategorySortOrder = input.nutrientCategorySortOrder;
	}

	public mapToApi(): api.Nutrient {
		return {
			nutrientId: this.nutrientId,
			nutrient: this.nutrient,
			categoryId: this.categoryId,
			category: this.category,
			total: this.total,
			unitId: this.unitId,
			unit: this.unit,
			isMacroNutrient: this.isMacroNutrient,
			nutrientCategorySortOrder: this.nutrientCategorySortOrder,
		};
	}

	public mapToCalculatedNutrient(): CalculatedNutrient {
		return {
			nutrient: this.nutrient ?? '',
			category: this.category ?? '',
			total: this.total,
			unit: this.unit ?? '',
			isMacroNutrient: this.isMacroNutrient,
			nutrientCategorySortOrder: this.nutrientCategorySortOrder,
		};
	}
}
