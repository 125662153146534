import * as apiDelete from 'api/originLocation/DeleteOriginLocationIdV1';
import * as apiRestore from 'api/originLocation/PostOriginLocationRestoreV1';
import * as apiPostSearch from 'api/originLocation/PostOriginLocationSearchV1';

export default class OriginLocation {
	public id: string = '';
	public name: string = '';
	public code: string = '';

	public constructor(input?: apiPostSearch.ResponseOrigin) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseOrigin) {
		this.id = input.id;
		this.code = input.code;
		this.name = input.name;
	}
}
