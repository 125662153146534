import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { TagLight } from 'types/Tag/TagLight';

interface IProps {
	allergens: AllergenLight[];
	tags: TagLight[];
}

const getIcons = ({
	tags,
	allergens,
}: {
	tags: TagLight[];
	allergens: AllergenLight[];
}): Array<AllergenLight | TagLight> => {
	const output: Array<AllergenLight | TagLight> = [];
	for (const tag of tags) {
		if (tag.iconSvgUrl && tag.display) {
			output.push(tag);
		}
	}
	for (const allergen of allergens) {
		if (allergen.iconSvgUrl && allergen.display) {
			output.push(allergen);
		}
	}
	return output;
};

export const IconsBar = (props: IProps) => {
	return (
		<div className="d-flex flex-gap-10 justify-content-end align-items-center">
			{getIcons({ ...props }).map((icon: AllergenLight | TagLight, i: number) => (
				<IconComponent icon={icon} key={i} />
			))}
		</div>
	);
};
