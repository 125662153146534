import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import PriceDisplay from 'components/desktop/_general/PriceDisplay';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import ENTITLEMENTS from 'enums/entitlements';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import { RootState } from 'reducers/rootReducer';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import * as UserSettings from 'types/User/UserProfile/UserSettings';
import { NutriScore } from 'types/_general/NutriScore';

export type IProps = {
	segmentInEntryLanguage: Segment[];
	recipe: Recipe;
	calculation: Calculation | null;
	nutriScore: NutriScore | null;
};

export const RecipeSummary = (props: IProps) => {
	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const seasonalityCode = useMemo((): number => {
		if (props.calculation?.seasons.items) {
			return getSeasonalityCode(
				props.calculation?.seasons.items.map((x) => ({
					associatedMonthsFlag: x.associatedMonthsFlag ?? undefined,
					display: true,
				}))
			);
		}
		return getSeasonalityCode(props.recipe.seasons);
	}, [props.recipe.seasons, props.calculation?.seasons]);

	return (
		<div className="d-flex" style={{ gap: '15px' }}>
			<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
				<SeasonalityBar seasonalityCode={seasonalityCode} />
			</RenderIf>
			<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
				<NutriScoreDiagram
					nutriScore={props.nutriScore ?? props.recipe.nutriScore ?? null}
				/>
			</RenderIf>
			<RenderIf
				condition={Boolean(props.calculation ?? props.recipe.price)}
				entitlements={[ENTITLEMENTS.PRICE]}
			>
				<PriceDisplay
					value={props.calculation?.price ?? props.recipe.price ?? null}
					currency={reduxCurrency}
					valueScopeInfo={{
						valueScope: reduxUserSetting['Recipe.List.ValueScope'],
						persons: props.recipe.persons,
						parts: props.recipe.parts,
						weight:
							props.calculation?.weight ??
							props.recipe.weight ??
							props.recipe.ingredientWeight,
					}}
				/>
			</RenderIf>
			<RenderIf condition={Boolean(props.calculation ?? props.recipe.carbonDioxideValue)}>
				<Co2Display
					value={props.calculation?.carbonDioxideValue ?? props.recipe.carbonDioxideValue}
					color={
						props.calculation?.carbonDioxideLabel?.color ??
						props.recipe.carbonDioxideLabel?.color ??
						'#000'
					}
					valueScopeInfo={{
						valueScope: reduxUserSetting['Recipe.List.ValueScope'],
						persons: props.recipe.persons,
						parts: props.recipe.parts,
						weight:
							props.calculation?.weight ??
							props.recipe.weight ??
							props.recipe.ingredientWeight,
					}}
				/>
			</RenderIf>
		</div>
	);
};
