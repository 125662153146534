import React, { createContext, useContext, useEffect, useState } from 'react';

import * as apiSW from 'api/nutriScoreLabel/GetNutriScoreLabelStartingWithV1';
import { ResponseNutriScoreLabel } from 'api/nutriScoreLabel/GetNutriScoreLabelStartingWithV1';
import ENTITLEMENTS from 'enums/entitlements';
import { Optional } from 'functions/promiseExtensions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';

export const NutriscoreContext = createContext({
	parent: false,
	nutriscoreLabels: [] as ResponseNutriScoreLabel[],
});

export async function initializeNutriscoreContext(): Promise<{
	nutriscoreLabels: ResponseNutriScoreLabel[];
}> {
	if (areEntitlementsInUserEntitlements([ENTITLEMENTS.NUTRI_SCORE])) {
		const response = await apiSW.callApi('', false);
		return { nutriscoreLabels: response.getOrDefault([]) };
	}
	return { nutriscoreLabels: [] };
}

export class ContextBuilder<P> {
	component: React.FunctionComponent<P>;

	private constructor(component: React.FunctionComponent<P>) {
		this.component = component;
	}

	public static From<P>(component: React.FunctionComponent<P>): ContextBuilder<P> {
		return new ContextBuilder(component);
	}

	public build = () => {
		return this.component;
	};

	public wrapWithNutriscoreContext() {
		return this.wrap(NutriscoreContext, initializeNutriscoreContext);
	}

	public wrap<T extends { parent: boolean }>(
		context: React.Context<T>,
		initializer: () => Promise<Omit<T, 'parent'>>
	) {
		return new ContextBuilder((p: P) => {
			const ctx = useContext(context);

			if (ctx.parent) {
				// Prevent double-loading
				return React.createElement(this.component as any, p as any);
			}

			const [state, setState] = useState<Optional<Omit<T, 'parent'>>>(Optional.None());

			useEffect(() => {
				const f = async () => {
					const contextState = await initializer();
					setState(Optional.Just(contextState));
				};
				f();
			}, []);

			return state
				.map((x) => {
					return (
						<context.Provider value={{ parent: true, ...x } as any}>
							{React.createElement(this.component as any, p as any)}
						</context.Provider>
					);
				})
				.getOrDefault(<div />);
		});
	}
}
