import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown, faCaretLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { getAllInvolvedUrls } from 'components/desktop/_general/MainNavigationLeft/MainNavigationLeftHandlers';
import { isItemSelected } from 'components/desktop/_general/MainNavigationLeft/MainNavigationLeftHandlers';
import { SidebarItem } from 'components/desktop/_general/MainNavigationLeft/constants/SidebarItems';
import SIDEBAR_ITEMS from 'components/desktop/_general/MainNavigationLeft/constants/SidebarItems';
import useClassNameForVisibilityChange from 'components/desktop/_general/MainNavigationLeft/hooks/useClassNameForVisibiltyChange';
import { usePathname } from 'components/desktop/_general/MainNavigationLeft/hooks/usePathname';
import PERMISSIONS from 'enums/permissions';
import {
	areEntitlementsInUserEntitlements,
	arePermissionsInUserPermissions,
} from 'functions/tokenFunctions';
import { isUserWriter } from 'functions/tokenFunctions';
import { isUserAdmin } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import SidebarState from 'types/_general/SidebarState';

interface IProps {
	sidebarState: SidebarState;
}

export default function Body(props: IProps) {
	const { t } = useTranslation();
	const visible = useClassNameForVisibilityChange(props.sidebarState);
	const [openItemIds, setOpenItemIds] = useState<string[]>([]);
	const pathname: string = usePathname();
	const navigate = useNavigate();
	const reduxMenuBarOrder: string[] = useSelector((state: RootState) => state.menuBarOrder);

	const renderIconItem = (item: SidebarItem, level: number): JSX.Element => {
		const size: string = '24px';
		let marginLeft: string = '0px';
		if (props.sidebarState !== 'closed') {
			marginLeft = 20 * level + 'px';
		}
		if (item.icon) {
			return (
				<FontAwesomeIcon
					icon={item.icon}
					style={{ height: size, width: size, marginLeft: marginLeft }}
				/>
			);
		}
		return <></>;
	};

	const openLink = (url: string): void => {
		navigate(url);
	};

	const renderIconCaret = (item: SidebarItem): JSX.Element => {
		const open: boolean = Boolean(openItemIds.includes(item.url) && item.children?.length);
		return (
			<FontAwesomeIcon icon={open ? (faCaretDown as IconProp) : (faCaretLeft as IconProp)} />
		);
	};

	const clickItem = (e: React.MouseEvent<HTMLDivElement>, item: SidebarItem): void => {
		e.stopPropagation();
		if (!item.children?.length) return;
		const allInvolvedUrls: string[] = getAllInvolvedUrls(SIDEBAR_ITEMS, item.url);
		if (item.defaultUrl) {
			openLink(item.defaultUrl);
		}
		setOpenItemIds(allInvolvedUrls);
	};

	const renderNavLink = (item: SidebarItem, level: number): JSX.Element => {
		return (
			<div
				className={'item-display ' + visible}
				role="button"
				onClick={() => openLink(item.url)}
			>
				{renderIconItem(item, level)}
				<div className="item-title">{t(item.translationKey)}</div>
			</div>
		);
	};

	const renderNavContainer = (item: SidebarItem, level: number): JSX.Element => {
		return (
			<>
				<div
					className={'item-display ' + visible}
					role="button"
					onClick={(e: React.MouseEvent<HTMLDivElement>) => clickItem(e, item)}
				>
					{renderIconItem(item, level)}
					<div className="item-title">{t(item.translationKey)}</div>
					<div className="carret">{renderIconCaret(item)}</div>
				</div>
				<ul>
					{item.children?.map((item: SidebarItem, i: number) => {
						return renderMenuItem(item, i, level + 1);
					})}
				</ul>
			</>
		);
	};

	const getSelectedClass = (item: SidebarItem): string => {
		if (isItemSelected(item, pathname)) return 'selected';
		return '';
	};

	const itShouldRender = (item: SidebarItem): boolean => {
		return checkEntitlements(item) && checkPermissions(item);
	};

	const checkEntitlements = (item: SidebarItem): boolean => {
		if (!item.entitlements.length) return true;
		return areEntitlementsInUserEntitlements(item.entitlements);
	};

	const checkPermissions = (item: SidebarItem): boolean => {
		if (!item.permissions.length) return true;
		if (item.permissions.includes(PERMISSIONS.ADMINISTRATE) && isUserAdmin()) return true;
		if (item.permissions.includes(PERMISSIONS.WRITE) && isUserWriter()) return true;
		if (arePermissionsInUserPermissions(item.permissions)) return true;
		return false;
	};

	const renderMenuItem = (item: SidebarItem, index: number, level: number): JSX.Element => {
		return (
			<RenderIf condition={itShouldRender(item)} key={index}>
				<li
					className={[
						openItemIds.includes(item.url) ? 'open' : '',
						'level-' + level,
						getSelectedClass(item),
						item.children?.length ? 'selected-container' : '',
					].join(' ')}
				>
					<RenderIf condition={!item.hidden}>
						{item.children?.length
							? renderNavContainer(item, level)
							: renderNavLink(item, level)}
					</RenderIf>
				</li>
			</RenderIf>
		);
	};

	const sortSidebarItems = (): SidebarItem[] => {
		const result: SidebarItem[] = [];
		result.push(SIDEBAR_ITEMS[0]);

		reduxMenuBarOrder.forEach((e: string) => {
			const index = SIDEBAR_ITEMS.findIndex((x) =>
				x.url.toLowerCase().includes(e.toLowerCase())
			);
			result.push(SIDEBAR_ITEMS[index]);
		});

		return result;
	};

	return (
		<div className="navigation-body">
			<ul>
				{sortSidebarItems().map((e: SidebarItem, i: number) => {
					return renderMenuItem(e, i, 0);
				})}
			</ul>
		</div>
	);
}
