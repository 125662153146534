import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/desktop/_general/Tooltip/Tooltip';
import { validateFileSizes } from 'functions/validateFileSizes';

interface IProps {
	setFormData: (input: FormData) => void;
}

const ImageSearch = (props: IProps) => {
	const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined);
	const { t } = useTranslation();

	const buttonBackgroundColor: string = '#6699cc';
	const allowedFileTypes: string[] = ['image/png', 'image/jpeg'];
	const inputFile = useRef<any>(null);

	const handleChooseFile = () => {
		inputFile.current.click();
	};

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		validateFileSizes(e.target.files).do((files) => {
			const file: File = files[0];
			setFormData(file);
		});
	};

	const setFormData = (file: File) => {
		const newFormData = new FormData();
		newFormData.append('File', file);
		props.setFormData(newFormData);
	};

	const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (backgroundColor !== buttonBackgroundColor) {
			setBackgroundColor(buttonBackgroundColor);
		}
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
		e.preventDefault();

		if (e.dataTransfer.items) {
			Array.from(e.dataTransfer.items).forEach((item, i) => {
				if (item.kind !== 'file') return;
				if (i !== 0) return;
				const file = item.getAsFile();
				if (!file) return;
				if (!allowedFileTypes.includes(file.type)) return;
				setFormData(file);
			});
		} else {
			Array.from(e.dataTransfer.files).forEach((file, i) => {
				if (i !== 0) return;
				if (!allowedFileTypes.includes(file.type)) return;
				setFormData(file);
			});
		}

		setBackgroundColor(undefined);
	};

	const renderContent = () => {
		return (
			<Tooltip
				content={
					<>
						<FontAwesomeIcon
							icon={faMicrochipAi as IconProp}
							size={'lg'}
							style={{ marginRight: '5px' }}
						/>
						{t('recipe:UPLOAD_RECIPE_AI_PICTURE')}
					</>
				}
			>
				<div
					className="btn btn-outline-primary btn-100"
					onClick={handleChooseFile}
					onDragOver={handleDragOver}
					onDragLeave={() => setBackgroundColor(undefined)}
					onDrop={handleDrop}
					style={{ backgroundColor: backgroundColor }}
				>
					<FontAwesomeIcon icon={faCamera} />
					<input
						style={{ display: 'none' }}
						ref={inputFile}
						type="file"
						onChange={handleImageChange}
						accept="image/png, image/jpeg"
					/>
				</div>
			</Tooltip>
		);
	};

	return <>{renderContent()}</>;
};

export default ImageSearch;
