import { useTranslation } from 'react-i18next';

import Toggle from 'components/desktop/_general/Toggle/Toggle';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';

interface IProps {
	hasNutriScore: boolean;
	nutriScoreCategory: NutriScoreCategory | null;
	setHasNutriScore: (hasNutriScore: boolean) => void;
	setNutriScoreCategory: (category: NutriScoreCategory) => void;
	canImplyNutriScoreMethod?: boolean;
	disabled?: boolean;
}

const NutriScoreSelect = (props: IProps) => {
	const { t } = useTranslation();

	const getNutriScoreCategories = (): JSX.Element => {
		return (
			<>
				{props.canImplyNutriScoreMethod && (
					<option key="-999" value="__default" disabled={true}>
						{t('_general:IMPLYED_NUTRISCORE_METHOD')}
					</option>
				)}
				{Object.values(NutriScoreCategory).map((s, i) => (
					<option key={i} value={s}>
						{t('nutriScoreCategory:' + s)}
					</option>
				))}
			</>
		);
	};

	const setNutriScoreCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value: NutriScoreCategory = e.currentTarget.value as NutriScoreCategory;
		props.setNutriScoreCategory(value);
	};

	return (
		<>
			<Toggle
				checked={props.hasNutriScore}
				handleToggle={props.setHasNutriScore}
				elementId={'hasnutriscore'}
				label={'_general:HASNUTRISCORE'}
				disabled={props.disabled}
			/>
			<div className="form-group" style={{ marginTop: '10px' }}>
				<label>{t('_general:NUTRI_SCORE_CATEGORY')}</label>
				<div className="input-group">
					<select
						className="form-select"
						value={props.nutriScoreCategory ?? '__default'}
						name="nutriScoreSelect"
						onChange={setNutriScoreCategory}
						disabled={props.disabled}
						error-key="NutriScoreCategory"
					>
						{getNutriScoreCategories()}
					</select>
				</div>
			</div>
		</>
	);
};

export default NutriScoreSelect;
