import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/AiFunctions/AnalyzeRecipeFromUrl';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export type Request = {
	recipeUrl: string;
};

export type Response = {
	data: ResponseData;
};

export type ResponseData = {
	source: string | null;
	entryLanguage: EntryLanguage;
	systemStatus: 'New' | 'Published';
	status: Status;
	calories: number | null;
	ingredientCalories: number | null;
	weight: number | null;
	ingredientWeight: number | null;
	persons: number | null;
	scaledPersons: number | null;
	parts: number | null;
	scaledParts: number | null;
	isSeoActive: boolean | null;
	price: number | null;
	hasNutriScore: boolean | null;
	nutriScoreCategory: 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water';
	nutriScore: NutriScore;
	carbonDioxideValue: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	titleTranslations: Translation;
	shortDescriptionTranslations: Translation;
	notesTranslations: Translation;
	ingredientsTranslations: Translation;
	stepsTranslations: Translation;
	seoTranslations: Translation;
	tags: Tags[];
	activityTimes: ActivityTimes[];
	seasons: Seasons[];
	allergens: Allergens[];
	nutrientValues: NutrientValues[];
	categories: Categories[];
	carbonDioxide: CarbonDioxide[];
	incorporationShares: IncorporationShares[];
	ingredientProducts: IngredientProducts[];
	accessoryProducts: AccessoryProducts[];
};

export type EntryLanguage = {
	id: string;
	description: string;
};

export type Status = {
	id: string;
	color: string;
	order: number;
	name: string;
};

export type NutriScore = {
	label: string;
	color: string;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type Translation = {
	[key: string]: string;
};

export type Tags = {
	id: string;
	iconSvgUrl?: string | null;
	display: boolean;
	name: string;
	tagCategory: TagCategory | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type ActivityTimes = {
	id: string;
	name: string;
	timeUnit: TimeUnit;
	timeValue: number;
};

export type TimeUnit = {
	id: string;
	description: string;
};

export type Seasons = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	associatedMonthsFlag: number | null;
};

export type Allergens = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	logic: 'Positive' | 'Negative' | 'MayContain';
};

export type NutrientValues = {
	nutrientId: string;
	nutrient: string;
	categoryId: string;
	category: string;
	total: number;
	unitId: string;
	unit: string;
	isMacroNutrient: boolean;
};

export type Categories = {
	id: string;
	name: string;
};

export type CarbonDioxide = {
	originLocationId: string;
	origin: string;
	productionMethodId: string;
	productionMethod: string;
	value: number;
};

export type IncorporationShares = {
	segmentIndex: number;
	ingredientIndex: number;
	incorporationShare: number;
	quantity: number | null;
	unitId: string | null;
	ingredientId: string;
};

export type IngredientProducts = {
	id: string;
	externalId: string | null;
	brand: string | null;
	producer: string | null;
	price: number | null;
	imageUrl: string | null;
	externalUrl: string | null;
	quantity: string | null;
	nameTranslations: Translation;
};

export type AccessoryProducts = {
	id: string;
	externalId: string | null;
	brand: string | null;
	producer: string | null;
	price: number | null;
	imageUrl: string | null;
	externalUrl: string | null;
	quantity: string | null;
	nameTranslations: Translation;
};
