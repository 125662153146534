import * as deleteMenuIdV1 from 'api/menu/DeleteMenuIdV1';
import * as apiRestore from 'api/menu/PostMenuRestoreV1';
import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';
import AllergenList from 'classes/Menu/Search/AllergenList';
import CarbonDioxideLabel from 'classes/Menu/Search/CarbonDioxideLabel';
import RecipeList from 'classes/Menu/Search/RecipeList';
import SeasonList from 'classes/Menu/Search/SeasonList';
import Status from 'classes/Menu/Search/Status';
import TagList from 'classes/Menu/Search/TagList';

export default class Menu {
	public id: string = '';
	public lastEditor: string = '';
	public nameTranslation: string = '';
	public lastEditedDateUtc: string = '';
	public status: Status = new Status();
	public calories: number = 0;
	public weight: number = 0;
	public price: number = 0;
	public carbonDioxideValue: number = 0;
	public carbonDioxideLabel: CarbonDioxideLabel | null = null;
	public seasonList: SeasonList = new SeasonList();
	public allergenList: AllergenList = new AllergenList();
	public tagList: TagList = new TagList();
	public recipeList: RecipeList = new RecipeList();

	public constructor(input?: postMenuSearchV1.Menu) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await deleteMenuIdV1.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: postMenuSearchV1.Menu) {
		this.id = input.id;
		this.lastEditor = input.lastEditor ?? '';
		this.nameTranslation = input.nameTranslation ?? '';
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.status.mapFromApi(input.status);
		this.calories = input.calories ?? 0;
		this.weight = input.weight ?? 0;
		this.price = input.price ?? 0;
		this.carbonDioxideValue = input.carbonDioxideValue ?? 0;
		this.seasonList.mapFromApi(input.seasons);
		this.allergenList.mapFromApi(input.allergens);
		this.tagList.mapFromApi(input.tags);
		this.recipeList.mapFromApi(input.recipes);

		if (input.carbonDioxideLabel) {
			this.carbonDioxideLabel = new CarbonDioxideLabel();
			this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		} else {
			this.carbonDioxideLabel = null;
		}
	}
}
