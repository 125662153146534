import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/EmailAccount/SendTestMail';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	emailAccountId: string;
	email: string;
	cultureCode: string;
}

export interface Response {
	data: string;
}

export const defaultRequest = () => {
	return {
		emailAccountId: '',
		email: '',
		cultureCode: '',
	};
};
