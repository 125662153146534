import * as apiSW from 'api/nutrient/GetNutrientStartingWithV1';
import * as apiPostSearch from 'api/nutrient/PostNutrientSearchV1';
import Nutrient from 'classes/StandingData/Nutrient/Search/Nutrient';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class NutrientList {
	public all: Nutrient[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const nutrients = await apiSW.callApi(searchTerm, showOnlyDeleted);
		nutrients.hasValue() && this.mapFromApiSW(nutrients.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const nutrient: Nutrient = new Nutrient(i);
			this.all.push(nutrient);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseNutrient[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
