import { emptyGuid } from 'constants/emptyGuid';
import { AllergenLogic } from 'enums/allergenLogic';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { mapCarbonDioxide } from 'types/Co2Footprint/SaveCo2Footprint';
import { Ingredient } from 'types/Ingredient/Ingredient';
import { SaveIngredientDto } from 'types/Ingredient/SaveIngredientDto';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';
import { SaveNutrientValue } from 'types/NutrientValue/SaveNutrientValue';
import { SeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { TagLight } from 'types/Tag/TagLight';
import { SaveUnitWeight } from 'types/UnitWeight/SaveUnitWeight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

type SaveAllergen = {
	[key: string]: AllergenLogic;
};

export function mapToSaveIngredientDto(ingredient: Ingredient): SaveIngredientDto {
	return {
		isScalingFactorOverridden: ingredient.isScalingFactorOverridden,
		scalingFactor: ingredient.scalingFactor,
		density: ingredient.density,
		calories: ingredient.calories,
		characteristic: ingredient.characteristic,
		imageId: ingredient.image?.id ?? null,
		price: ingredient.price,
		products: ingredient.products.map((p) => p.id),
		baseIngredientId: ingredient.baseIngredient.id ?? emptyGuid,
		namePluralTranslations: ingredient.namePluralTranslations,
		nameSingularTranslations: ingredient.nameSingularTranslations,
		variations: ingredient.variations,
		nutrientValues: mapNutrientValues(ingredient.nutrientValues),
		categories: ingredient.categories.map((c) => c.id),
		allergens: mapAllergens(ingredient.allergens),
		isAllergenOverridden: ingredient.isAllergenOverridden,
		seasons: ingredient.seasons.map((season: SeasonMappingDto) => season.id),
		isSeasonOverridden: ingredient.isSeasonOverridden,
		tags: ingredient.tags.map((tag: TagLight) => tag.id),
		isTagOverridden: ingredient.isTagOverridden,
		unitWeights: mapUnitWeights(ingredient.unitWeights),
		isUnitWeightOverridden: ingredient.isUnitWeightOverridden,
		hasNutriScore: ingredient.hasNutriScore,
		nutriScoreCategory: ingredient.nutriScoreCategoryIsSetByUser
			? ingredient.nutriScoreCategory
			: null,
		nutriScoreCategoryIsSetByUser: ingredient.nutriScoreCategoryIsSetByUser,
		isCarbonDioxideOverridden: ingredient.isCarbonDioxideOverridden,
		carbonDioxide: mapCarbonDioxide(ingredient.carbonDioxide),
		synonyms: ingredient.synonyms,
	};
}

function mapNutrientValues(nutrientValues: NutrientValue[]): SaveNutrientValue {
	const result: SaveNutrientValue = {};
	for (const nutrientValue of nutrientValues) {
		result[nutrientValue.id] = nutrientValue.value;
	}
	return result;
}

function mapAllergens(allergens: AllergenLight[]): SaveAllergen {
	var map = {} as SaveAllergen;

	allergens.map((a) => {
		map[a.id] = a.logic;
	});

	return map;
}

function mapUnitWeights(unitWeights: UnitWeight[]): SaveUnitWeight<number> {
	var map = {} as SaveUnitWeight<number>;

	unitWeights.forEach((u) => {
		map[u.id] = u.weight;
	});

	return map;
}
