import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetAllIngredientCategories from 'api/ingredientCategory/GetIngredientCategoryGetAllV1';
import * as apiGet from 'api/nutriScoreIngredientCategoryPoints/GetNutriScoreIngredientCategoryPointsIdV1';
import * as apiPost from 'api/nutriScoreIngredientCategoryPoints/PostNutriScoreIngredientCategoryPointsV1';
import * as apiPut from 'api/nutriScoreIngredientCategoryPoints/PutNutriScoreIngredientCategoryPointsV1';
import IngredientCategorySelect from 'components/desktop/StandingData/IngredientCategoryPoints/IngredientCategorySelect';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NutriScorePointsComponent from 'components/desktop/_general/NutriScorePoints/NutriScorePointsComponent';
import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { SelectOption } from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { mapFromRequest } from 'functions/mappers/IngredientCategoryPoints/mapFromRequest';
import { mapToRequest } from 'functions/mappers/IngredientCategoryPoints/mapToRequest';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { IngredientCategoryLight } from 'types/Ingredient/IngredientCategory/IngredientCategoryLight';
import { IngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';
import { defaultIngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';

type ParsedUrl = {
	id: string;
	pointType: string;
};

const IngredientCategoryPointsDetail = () => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [nutriScoreNutrient, setNutriScoreNutrient] = useState<IngredientCategoryPoint>(
		defaultIngredientCategoryPoint()
	);
	const [ingredientCategorys, setIngredientCategorys] = useState<IngredientCategoryLight[]>([]);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		await getIngredientCategorys();
		const parsedUrl: ParsedUrl | null = parseUrl();
		if (parsedUrl === null) return;
		setIsLoading(true);
		const response = await apiGet.callApi(
			parsedUrl.id,
			parsedUrl.pointType as NutriScoreCategoryIngredientCategory
		);
		response.do((x) => setNutriScoreNutrient(mapFromRequest(x)));
		setIsLoading(false);
	};

	const parseUrl = (): ParsedUrl | null => {
		let output: ParsedUrl | null = null;
		if (window.location.href.includes('?')) {
			if (window.history.state.id !== undefined) {
				const id: string = window.history.state.id;
				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;
				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			} else {
				const url = new URL(String(window.location)).searchParams;
				const id: string | null = url.get('id') === null ? null : url.get('pointType');
				if (!id) return output;

				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;

				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			}
		}
		return output;
	};

	const handleSaveNutriScoreNutrient = (): void => {
		const id: string | null = getIdFromUrl();
		if (id === null) return;

		if (id === 'new') {
			postNutriScoreNutrient();
		} else {
			putNutriScoreNutrient();
		}
	};

	const getIngredientCategorys = async () => {
		const response = await apiGetAllIngredientCategories.callApi();
		response.do((x) => {
			const categories: IngredientCategoryLight[] = [];
			for (const category of x.ingredientCategories) {
				categories.push({
					...category,
					source: null,
				});
			}
			setIngredientCategorys([...categories]);
		});
	};

	const renderNutrientSelect = (): JSX.Element => {
		const id: string | null = getIdFromUrl();
		if (id === null) return <></>;

		if (id === 'new') {
			return (
				<IngredientCategorySelect
					ingredientCategorys={ingredientCategorys}
					ingredientCategoryPoint={nutriScoreNutrient}
					setIngredientCategoryPoint={setNutriScoreNutrient}
				/>
			);
		}
		return (
			<>
				<label className="input-label">{t('standingData:CHANGE_CATEGORY')}</label>
				<div className="input-group mb-3">
					<input
						className="form-control"
						placeholder={nutriScoreNutrient.ingredientCategoryName ?? ''}
						disabled={true}
					/>
				</div>
			</>
		);
	};

	const putNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const putObject: apiPut.Request = mapToRequest(nutriScoreNutrient);
			await apiPut.callApi(putObject);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const postNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const postObject: apiPost.Request = mapToRequest(nutriScoreNutrient);
			await apiPost.callApi(postObject);
			if (!nutriScoreNutrient.ingredientCategoryId) return;
			setIdToUrl(
				nutriScoreNutrient.ingredientCategoryId + ':' + nutriScoreNutrient.pointType
			);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const getSelectOptions = (): SelectOption[] => {
		return Object.keys(NutriScoreCategoryIngredientCategory).map((key) => {
			return {
				id: key,
				text: key,
			};
		});
	};

	const handlePointTypeChange = (pointType: string): void => {
		const nutriScoreNutrientCpy: IngredientCategoryPoint = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.pointType = pointType as NutriScoreCategoryIngredientCategory;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	const handleTogglePositive = (input: boolean): void => {
		const nutriScoreNutrientCpy: IngredientCategoryPoint = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.isPositive = input;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div style={{ minHeight: '400px' }}>
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							<h2>
								{nutriScoreNutrient.ingredientCategoryName
									? nutriScoreNutrient.ingredientCategoryName
									: '–'}
							</h2>
							<div>
								<span className="badge bg-secondary">
									{nutriScoreNutrient.pointType}
								</span>
							</div>
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveNutriScoreNutrient()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex" style={{ gap: '20px' }}>
					<div style={{ width: '220px' }}>
						<label style={{ marginTop: 0 }}>{t('standingData:CHANGE_CATEGORY')}:</label>
						<SelectSimple
							options={getSelectOptions()}
							currentValue={nutriScoreNutrient.pointType}
							handleValueChange={handlePointTypeChange}
							disabled={getIdFromUrl() !== 'new'}
							errorKey="PointType"
						/>
						<hr />
						<Toggle
							checked={nutriScoreNutrient.isPositive ?? false}
							handleToggle={handleTogglePositive}
							elementId="isPositive"
							label="_general:POSITIVE"
							className="mb-2"
							errorKey="IsPositive"
						/>
					</div>
					<div style={{ width: '900px' }}>
						{renderNutrientSelect()}
						<NutriScorePointsComponent
							input={nutriScoreNutrient}
							setInput={setNutriScoreNutrient}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default IngredientCategoryPointsDetail;
