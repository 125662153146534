import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';
import { ISelectItem } from 'interfaces/ISelectItem';

export interface Selectable {
	all: any[];
	filtered: ISelectItem[];
	initialize: () => Promise<void>;
	search: (searchTerm: string) => Promise<void>;
	getById: (id: string) => any | undefined;
}

export interface Saveable {
	id: string;
}

export class SingleSelect<SuggestedType extends Selectable, SavedType extends Saveable>
	implements IAdvancedSearchKey
{
	public id: string;
	public suggested: SuggestedType;
	public saved: SavedType | null = null;
	public cultureCode: string | null = getCultureCodeFromLocalStorage();

	constructor(c: new () => SuggestedType, id: string) {
		this.id = id;
		this.suggested = new c();
	}

	public async initialize() {
		const cultureCode: string | null = getCultureCodeFromLocalStorage();
		if (cultureCode !== this.cultureCode) {
			this.cultureCode = cultureCode;
			await this.suggested.initialize();
			this.translateSaved();
		}
		if (this.suggested.all.length === 0) {
			await this.suggested.initialize();
		}
	}

	public reset() {
		this.saved = null;
	}

	private translateSaved() {
		const id = this.saved?.id;
		if (!id) return;
		const object: SavedType | undefined = this.suggested.getById(id);
		if (!object) return;
		this.saved = object;
	}

	public save(input: SavedType | null): void {
		if (input) {
			this.saved = input;
		} else {
			this.saved = null;
		}
	}

	public mapToApi(): string | null {
		if (this.saved) {
			return this.saved.id;
		}
		return null;
	}

	public mapToUrl(): string {
		if (this.saved) {
			return this.id + '=' + this.saved.id;
		}
		return this.id + '=';
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				if (typeof value === 'string') {
					for (const i of value.split(';')) {
						const object: any = this.suggested.getById(i);
						if (object) {
							this.saved = object;
						}
					}
				}
			}
		}
	}

	public async search(searchTerm: string): Promise<void> {
		await this.suggested.search(searchTerm);
	}
}
