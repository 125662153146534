import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class NutrientValue {
	public nutrientId: string = '';
	public nutrient: string = '';
	public category: string = '';
	public total: number = 0;
	public unit: string = '';
	public isMacroNutrient: boolean = false;
	public nutrientCategorySortOrder: number = 0;

	public constructor(input?: api.NutrientValues) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.NutrientValues) {
		this.nutrient = input.nutrient;
		this.nutrientId = input.nutrientId;
		this.category = input.category;
		this.total = input.total;
		this.unit = input.unit;
		this.isMacroNutrient = input.isMacroNutrient;
		this.nutrientCategorySortOrder = input.nutrientCategorySortOrder;
	}
}
