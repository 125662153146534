import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/EmailAccount';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	senderEmail: string;
	host: string;
	port: number;
	username: string | null;
	password: string | null;
	useSsl: boolean;
	useDefaultCredentials: boolean;
}

export interface Response {
	data: string;
}
