import * as api from 'api/recipe/PostRecipeCalculateV1';
import CarbonDioxideValuePerIngredient from 'classes/Recipe/Detail/Calculation/CarbonDioxideValuePerIngredient';

export default class CarbonDioxideValuePerIngredientList {
	public items: CarbonDioxideValuePerIngredient[] = [];

	public constructor(input?: api.CarbonDioxideValuePerIngredient[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.CarbonDioxideValuePerIngredient[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new CarbonDioxideValuePerIngredient(i));
		}
	}
}
