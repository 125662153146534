import * as apiDelete from 'api/menuPlanner/DeleteMenuPlannerIdV1';
import * as apiRestore from 'api/menuPlanner/PostMenuPlannerRestoreV1';
import * as apiSearch from 'api/menuPlanner/PostMenuPlannerSearchV1';
import CarbonDioxideLabel from 'classes/Menu/Search/CarbonDioxideLabel';
import SeasonList from 'classes/Menu/Search/SeasonList';
import Status from 'classes/Menu/Search/Status';
import TagList from 'classes/Menu/Search/TagList';
import RowList from 'classes/MenuPlan/Search/RowList';
import { ConstraintStatus } from 'enums/ConstraintStatus';
import { Icon } from 'types/Icon/Icon';

export default class MenuPlan {
	public id: string = '';
	public status: Status = new Status();
	public lastEditor: string = '';
	public title: string = '';
	public lastEditedDateUtc: string = '';
	public caloriesLimit: number = 0;
	public priceLimit: number = 0;
	public isPriceConstraintMet: ConstraintStatus = ConstraintStatus.FullyMet;
	public isCaloriesConstraintMet: ConstraintStatus = ConstraintStatus.FullyMet;
	public startDate: Date | null = null;
	public endDate: Date | null = null;
	public durationDays: number = 0;
	public carbonDioxideValue: number = 0;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public rowList: RowList = new RowList();
	public seasonList: SeasonList = new SeasonList();
	public tagList: TagList = new TagList();

	public constructor(input?: apiSearch.MenuPlanner) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public getIcons(): Icon[] {
		const tagIcons: (Icon | null)[] = this.tagList.mapToIcons();
		const output: Icon[] = [];
		for (const i of tagIcons) {
			if (i !== null) output.push(i);
		}
		return output;
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private calculateEndDate(startDate: Date, durationDays: number): Date {
		const tempDate: Date = new Date(startDate);
		const endDateNumber: number = tempDate.setDate(startDate.getDate() + durationDays);
		return new Date(endDateNumber);
	}

	private mapFromApi(input: apiSearch.MenuPlanner) {
		this.id = input.id;
		this.status.mapFromApi(input.status);
		this.lastEditor = input.lastEditor;
		this.title = input.title;
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.caloriesLimit = input.caloriesLimit;
		this.priceLimit = input.priceLimit;
		this.isPriceConstraintMet = input.isPriceConstraintMet;
		this.isCaloriesConstraintMet = input.isCaloriesConstraintMet;
		if (input.startDate) {
			this.startDate = new Date(input.startDate);
			this.endDate = this.calculateEndDate(new Date(input.startDate), input.durationDays);
		}
		this.durationDays = input.durationDays;
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.rowList.mapFromApi(input.rows);
		this.seasonList.mapFromApi(input.seasons);
		this.tagList.mapFromApi(input.tags);
	}
}
