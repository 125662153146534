import i18next from 'i18next';

export function mapMonth(modification: string): string {
	var result = '';
	switch (modification.substring(5, 7)) {
		case '01':
			result = i18next.t('months:JANUARY');
			break;
		case '02':
			result = i18next.t('months:FEBRUARY');
			break;
		case '03':
			result = i18next.t('months:MARCH');
			break;
		case '04':
			result = i18next.t('months:APRIL');
			break;
		case '05':
			result = i18next.t('months:MAY');
			break;
		case '06':
			result = i18next.t('months:JUNE');
			break;
		case '07':
			result = i18next.t('months:JULY');
			break;
		case '08':
			result = i18next.t('months:AUGUST');
			break;
		case '09':
			result = i18next.t('months:SEPTEMBER');
			break;
		case '10':
			result = i18next.t('months:OCTOBER');
			break;
		case '11':
			result = i18next.t('months:NOVEMBER');
			break;
		case '12':
			result = i18next.t('months:DECEMBER');
			break;
		default:
			break;
	}

	return result;
}
