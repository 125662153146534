import * as api from 'api/menuPlanner/PostMenuPlannerSearchV1';
import Row from 'classes/MenuPlan/Search/Row';

export default class RowList {
	public all: Row[] = [];

	public constructor(input?: api.Row[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Row[] | null) {
		if (input) {
			for (const i of input) {
				const row: Row = new Row(i);
				this.all.push(row);
			}
		}
	}
}
