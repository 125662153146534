import AllergenManagementComponent from 'components/desktop/StandingData/Allergen/Management/AllergenManagementComponent';

const AllergenManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<AllergenManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default AllergenManagementContainer;
