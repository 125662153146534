import 'components/desktop/_general/NutritionFactsLabel/NutritionFactsLabel.scss';

export type Value = {
	value: number;
	unit: string;
	percent?: number;
};

export type Values = {
	calories: Value;
	fatTotal: Value;
	fatSaturated: Value;
	fatTrans: Value;
	cholesterol: Value;
	sodium: Value;
	carbohydrateTotal: Value;
	fiberDietary: Value;
	sugarsTotal: Value;
	protein: Value;
	vitaminD: Value;
	calcium: Value;
	iron: Value;
	potassium: Value;
};

export interface Props {
	values: Values;
	servingSize: Value;
}

const NutritionFactsLabel = (props: Props) => {
	const state: Values = {} as Values;
	for (const [key, value] of Object.entries(props.values)) {
		state[key as keyof Values] = {} as Value;
		state[key as keyof Values].value = Math.round(value.value);
		state[key as keyof Values].unit = value.unit;
		if (value.percent !== undefined) {
			state[key as keyof Values].percent = Math.round(value.percent);
		}
	}

	return (
		<div className={'nutrientFactsLabel'}>
			<div className={'title'}>Nutrition Facts</div>
			<div className={'servingSize'}>
				<div>Serving size</div>
				<div>
					{Math.round(props.servingSize.value)}
					{props.servingSize.unit}
				</div>
			</div>
			<div className={'amountPerServing'}>Amount per serving</div>
			<div className={'caloriesContainer'}>
				<div className={'calories'}>Calories</div>
				<div className={'caloriesAmount'}>{state.calories.value}</div>
			</div>
			<div className={'dailyValue'}>% Daily Value *</div>

			<div className={'valuesCategory'}>
				<div>
					<span className={'valuesBold'}>Total Fat</span> {state.fatTotal.value}
					{state.fatTotal.unit}
				</div>
				<div className={'valuesBold'}>{state.fatTotal.percent}%</div>
			</div>
			<div className={'valuesDetail'}>
				<div>
					Saturated Fat {state.fatSaturated.value}
					{state.fatSaturated.unit}
				</div>
				<div className={'valuesBold'}>{state.fatSaturated.percent}%</div>
			</div>
			<div className={'valuesDetail'}>
				<div>
					<span className={'valuesItalic'}>Trans</span> Fat {state.fatTrans.value}
					{state.fatTrans.unit}
				</div>
			</div>
			<div className={'valuesCategory'}>
				<div>
					<span className={'valuesBold'}>Cholesterol</span> {state.cholesterol.value}
					{state.cholesterol.unit}
				</div>
				<div className={'valuesBold'}>{state.cholesterol.percent}%</div>
			</div>
			<div className={'valuesCategory'}>
				<div>
					<span className={'valuesBold'}>Sodium</span> {state.sodium.value}
					{state.sodium.unit}
				</div>
				<div className={'valuesBold'}>{state.sodium.percent}%</div>
			</div>
			<div className={'valuesCategory'}>
				<div>
					<span className={'valuesBold'}>Total Carbohydrate</span>{' '}
					{state.carbohydrateTotal.value}
					{state.carbohydrateTotal.unit}
				</div>
				<div className={'valuesBold'}>{state.carbohydrateTotal.percent}%</div>
			</div>
			<div className={'valuesDetail'}>
				<div>
					Dietary Fiber {state.fiberDietary.value}
					{state.fiberDietary.unit}
				</div>
				<div className={'valuesBold'}>{state.fiberDietary.percent}%</div>
			</div>
			<div className={'sugar'}>
				<div>
					Total Sugars {state.sugarsTotal.value}
					{state.sugarsTotal.unit}
				</div>
			</div>
			<div className={'protein'}>
				<div>
					<span className={'valuesBold'}>Protein</span> {state.protein.value}
					{state.protein.unit}
				</div>
			</div>

			<div className={'valuesCategory'}>
				<div>
					Vitamin D {state.vitaminD.value}
					{state.vitaminD.unit}
				</div>
				<div>{state.vitaminD.percent}%</div>
			</div>
			<div className={'valuesCategory'}>
				<div>
					Calcium {state.calcium.value}
					{state.calcium.unit}
				</div>
				<div>{state.calcium.percent}%</div>
			</div>
			<div className={'valuesCategory'}>
				<div>
					Iron {state.iron.value}
					{state.iron.unit}
				</div>
				<div>{state.iron.percent}%</div>
			</div>
			<div className={'potassium'}>
				<div>
					Potassium {state.potassium.value}
					{state.potassium.unit}
				</div>
				<div>{state.potassium.percent}%</div>
			</div>

			<div className={'footnote'}>
				<div>*</div>
				<div>
					The % Daily Value (DV) tells you how much a nutrient in
					<br />a serving of food contributes to a daily diet. 2,000 calories
					<br />a day is used for general nutrition advice.
				</div>
			</div>
		</div>
	);
};

export default NutritionFactsLabel;
