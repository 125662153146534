import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url = `/backend-service/CarbonDioxideLabel/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	labelTranslations: {
		[key: string]: string;
	};
	maxValue: number | null;
	color: string;
}
