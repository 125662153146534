import NutrientCategoryManagementComponent from 'components/desktop/StandingData/NutrientCategory/Management/NutrientCategoryManagementComponent';

const NutrientCategoryManagementArchive = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<NutrientCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientCategoryManagementArchive;
