import axios from 'axios';

import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/BaseIngredientCategory/GetAll';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	baseIngredientCategories: ResponseCategory[];
};

export type ResponseCategory = {
	id: string;
	name: string;
	parentName: string;
	nutriScoreCategory: NutriScoreCategory;
};
