import StatusManagementComponent from 'components/desktop/StandingData/Status/Management/StatusManagementComponent';

const StatusArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<StatusManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default StatusArchiveContainer;
