import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ingredientsNeedReformating } from 'components/desktop/Recipe/RecipeComponentHandlers';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import TranslatableTextArea from 'components/desktop/_general/TranslatableTextArea/TranslatableTextArea';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';

interface IProps {
	recipe: Recipe;
	selectedLanguage: string;
	entryLanguage: string;
	handleValueChange: (key: keyof Recipe, value: string) => void;
	onTranslateClick: (name: string) => void;
	onIngredientReformatClick: (input: { segments: Segment[]; cultureCode: string }) => void;
	ingredientSuggestions: string[];
	getIngredientSuggestions: (input: string, language: string) => void;
	segments: Segment[];
}

const RecipeTextInput = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxAllowedAutoCompleteLanguages: string[] = useSelector(
		(state: RootState) => state.allowedAutoCompleteLanguages
	);

	const getIngredientSuggestionsCallback = useCallback(
		(input: string) => {
			props.getIngredientSuggestions(input, props.selectedLanguage);
		},
		[props.selectedLanguage]
	);

	const getAutoComplete = (): boolean => {
		if (reduxAllowedAutoCompleteLanguages.includes(props.selectedLanguage)) {
			return true;
		} else {
			return false;
		}
	};

	const createHandleValueChangeFunction = (key: keyof Recipe): ((value: string) => void) => {
		return function (value: string) {
			props.handleValueChange(key, value);
		};
	};

	const reformatIngredients = () => {
		props.onIngredientReformatClick({
			segments: props.segments,
			cultureCode: props.selectedLanguage,
		});
	};

	const shouldBeReformated = (): boolean => {
		if (!props.segments.length) return false;
		return ingredientsNeedReformating(props.recipe, props.segments, props.selectedLanguage);
	};

	const isTranslatable = (): boolean => {
		if (!recipeEditor) return false;

		if (props.entryLanguage === props.selectedLanguage) {
			return false;
		}

		return true;
	};

	if (reduxCultureCode !== undefined && reduxLanguages !== undefined) {
		return (
			<>
				<div className="row">
					<div className="col-6">
						<label>{t('recipe:SHORT_DESCRIPTION')}</label>
						<div>
							<TranslatableTextArea
								value={
									props.recipe.shortDescriptionTranslations[
										props.selectedLanguage
									] || ''
								}
								name="shortDescriptionTranslations"
								rows={4}
								handleValueChange={createHandleValueChangeFunction(
									'shortDescriptionTranslations'
								)}
								translatable={isTranslatable()}
								onTranslateClick={props.onTranslateClick}
								disabled={!recipeEditor}
								errorKey="ShortDescriptionTranslations"
							/>
						</div>
					</div>
					<div className="col-6">
						<label>{t('recipe:TIP')}</label>
						<div>
							<TranslatableTextArea
								value={props.recipe.notesTranslations[props.selectedLanguage] || ''}
								name="notesTranslations"
								rows={4}
								handleValueChange={createHandleValueChangeFunction(
									'notesTranslations'
								)}
								translatable={isTranslatable()}
								onTranslateClick={props.onTranslateClick}
								disabled={!recipeEditor}
								errorKey="NotesTranslations"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<label>
							{t('_general:INGREDIENTS')}
							<RenderIf
								condition={
									props.entryLanguage === props.selectedLanguage && recipeEditor
								}
							>
								<span
									className="text-primary"
									style={{
										marginLeft: '8px',
										opacity: shouldBeReformated() ? 1 : 0.5,
										cursor: shouldBeReformated() ? 'pointer' : undefined,
									}}
									onClick={reformatIngredients}
								>
									{t('_general:FORMAT')}{' '}
									<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
								</span>
							</RenderIf>
						</label>
						<div>
							<TranslatableTextArea
								value={
									props.recipe.ingredientsTranslations[props.selectedLanguage] ||
									''
								}
								name="ingredientsTranslations"
								rows={14}
								handleValueChange={createHandleValueChangeFunction(
									'ingredientsTranslations'
								)}
								translatable={isTranslatable()}
								onTranslateClick={props.onTranslateClick}
								suggestions={
									getAutoComplete() ? props.ingredientSuggestions : undefined
								}
								getSuggestions={
									getAutoComplete() ? getIngredientSuggestionsCallback : undefined
								}
								disabled={!recipeEditor}
								errorKey="IngredientsTranslations"
							/>
						</div>
					</div>
					<div className="col-6">
						<label>{t('recipe:STEPS')}</label>
						<div>
							<TranslatableTextArea
								value={props.recipe.stepsTranslations[props.selectedLanguage] || ''}
								name="stepsTranslations"
								rows={14}
								handleValueChange={createHandleValueChangeFunction(
									'stepsTranslations'
								)}
								translatable={isTranslatable()}
								onTranslateClick={props.onTranslateClick}
								disabled={!recipeEditor}
								errorKey="StepsTranslations"
							/>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <></>;
	}
};

export default RecipeTextInput;
