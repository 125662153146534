import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ListItem from 'components/desktop/Recipe/Validation/ListItem';
import RadioButtons from 'components/desktop/Recipe/Validation/RadioButtons';
import RecipeValidationDetail from 'components/desktop/Recipe/Validation/RecipeValidationDetailComponent';
import useRecipeValidationState from 'components/desktop/Recipe/Validation/RecipeValidationStateHook';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import UrlParams from 'enums/Recipe/Validation/UrlParams';
import useAirNavigation from 'hooks/useAirNavigation';
import { useReaction } from 'hooks/useReaction';
import * as Search from 'types/Validation/Search';
import * as Validation from 'types/Validation/Validation';
import { Type } from 'types/Validation/Validation';
import * as ValidationList from 'types/Validation/ValidationList';

const maxNumberOfButtons = 10;

const RecipeValidationComponent = () => {
	const { t } = useTranslation();
	const { state, handlers } = useRecipeValidationState();
	const { params, navigate } = useAirNavigation();
	const search = useMemo(() => Search.getFromUrl(params), [params.toString()]);

	const [validationList, isLoaded] = useReaction<ValidationList.Type, Search.Type>(
		ValidationList.create(),
		ValidationList.getFromApi,
		search
	);

	const renderListRows = () => {
		return validationList?.data.map((e: Type, i: number) => {
			return (
				<ListItem
					validation={e}
					checked={state.checked}
					key={i}
					changeId={handlers.changeId}
				/>
			);
		});
	};

	const navigateToDifferentStatus = (value: boolean | null) => {
		const newParams = params
			.delete(UrlParams.SearchOnlyUnacknowledge)
			.delete(UrlParams.PageIndex);
		if (value === null) {
			navigate(newParams);
		} else {
			navigate(newParams.add(UrlParams.SearchOnlyUnacknowledge, String(value)));
		}
	};

	const navigateToDifferentPage = (index: number) => {
		const newParams = params.delete(UrlParams.PageIndex);
		if (index === 0) {
			navigate(newParams);
		} else {
			navigate(newParams.add(UrlParams.PageIndex, String(index)));
		}
	};

	return (
		<>
			<Overlay
				displayOverlay={state.currentId !== ''}
				handleClose={() => handlers.changeId('')}
			>
				<RecipeValidationDetail
					validation={
						validationList.data.find((e) => e.entityId === state.currentId) ??
						Validation.create()
					}
					updateOne={handlers.updateOne}
					updateAll={handlers.updateAll}
					checked={state.checked}
				/>
			</Overlay>
			<div className="row">
				<div className="d-flex flex-column" style={{ gap: '20px' }}>
					<h1>{t('recipe:RECIPE_VALIDATION')}</h1>

					<RadioButtons
						status={search.showOnlyUnacknowledged}
						onClick={navigateToDifferentStatus}
					/>
					<RenderIf condition={isLoaded}>
						<div>{renderListRows()}</div>
					</RenderIf>
					<RenderIf condition={validationList.data.isEmpty() && isLoaded}>
						<div>
							<h4>{t('_general:NO_DATA_FOUND')}</h4>
							<RenderIf condition={Boolean(validationList.data.length)}>
								<Link to={'/recipe/validation'}>{t('_general:GO_TO_PAGE_1')}</Link>
							</RenderIf>
						</div>
					</RenderIf>
					<RenderIf condition={!isLoaded}>
						<div
							style={{
								padding: '100px 0px',
								borderBottom: '1px solid rgba(210, 210, 210, 1)',
								borderTop: '1px solid rgba(210, 210, 210, 1)',
							}}
						>
							<LoadingSpinner />
						</div>
					</RenderIf>
					<Pagination
						countOfAllListItems={validationList?.totalCount ?? 0}
						pageIndex={search.pageIndex ?? 0}
						setPageIndex={navigateToDifferentPage}
						listItemAmount={search.pageSize}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</div>
			</div>
		</>
	);
};

export default RecipeValidationComponent;
