import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { Tree } from 'components/desktop/_general/Input/TreeInput/Tree';

interface IProps<T extends Tree<T>> {
	selected: T[];
	removeFromSelected: (input: T) => void;
	readOnly?: boolean;
}

const SelectedItems = <T extends Tree<T>>(props: IProps<T>) => {
	return (
		<div>
			{props.selected.map((item: T) => (
				<div
					className="d-flex justify-content-between select-saveditems"
					style={{ marginTop: '0px' }}
					key={item.id}
				>
					{item.name}
					<RenderIf condition={!props.readOnly}>
						<span onClick={() => props.removeFromSelected(item)}>
							<i className="bi bi-x" />
						</span>
					</RenderIf>
				</div>
			))}
		</div>
	);
};

export default SelectedItems;
