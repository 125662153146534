export default function isNameValid(input: string): boolean {
	if (input.replace(/\s+/g, ' ') === ' ') {
		return false;
	}
	if (input.replace(/\d+/g, ' ') === ' ') {
		return false;
	}
	if (input === '') {
		return false;
	}
	return true;
}
