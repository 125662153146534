import { useState } from 'react';

import { ValidationState } from 'enums/inputValidationState';

interface IProps {
	id: string;
	label: string;
	value: string;
	type: string;
	isValid: (input: string) => boolean;
	onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	errorKey?: string;
}

const ValidatedInput = (props: IProps) => {
	const [validation, setValidation] = useState(ValidationState.Neutral);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		validate(e.target.value);
		props.handleValueChange(e);
	};

	const onBlur = () => {
		validate();
	};

	const validate = (input?: string) => {
		const value: string = input ? input : props.value;
		if (props.isValid(value)) {
			setValidation(ValidationState.Valid);
		} else {
			setValidation(ValidationState.Invalid);
		}
	};

	return (
		<>
			<div className="form-group">
				<label>{props.label}</label>
				<input
					className={['form-control', validation, props.className ?? ''].join(' ')}
					defaultValue={props.value}
					error-key={props.errorKey}
					id={props.id}
					name={props.id}
					onBlur={onBlur}
					onChange={onChange}
					onKeyDown={props.onKeyDown}
					type={props.type}
				/>
			</div>
		</>
	);
};

export default ValidatedInput;
