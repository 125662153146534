import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

type StartDateRange = {
	minimum: string | null;
	maximum: string | null;
};

export default class DateRange implements IAdvancedSearchKey {
	public id: string;
	public start: Date | null = null;
	public end: Date | null = null;

	constructor(id: string) {
		this.id = id;
	}

	public reset(): void {
		this.start = null;
		this.end = null;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id && typeof value === 'string') {
				const valueArray: string[] = value.split(':');
				if (!valueArray[0] || !valueArray[1]) return;

				if (valueArray[0] !== 'null') this.start = new Date(valueArray[0]);
				if (valueArray[1] !== 'null') this.end = new Date(valueArray[1]);
			}
		}
	}

	public mapToUrl(): string | null {
		let start: string = 'null';
		let end: string = 'null';

		if (this.start) start = this.getUrlDate(this.start);
		if (this.end) end = this.getUrlDate(this.end);
		if (this.start || this.end) {
			return this.id + '=' + start + ':' + end;
		}
		return null;
	}

	public mapToApi(): StartDateRange {
		return {
			minimum: this.start ? this.start.toJSON() : null,
			maximum: this.end ? this.end.toJSON() : null,
		};
	}

	private getUrlDate(input: Date): string {
		return input.toJSON().slice(0, 10);
	}
}
