import * as apiGetUser from 'api/user/GetUserIdV1';
import * as apiPostUser from 'api/user/PostUserCreateV1';
import * as apiPutUser from 'api/user/PutUserIdV1';
import * as apiGetUserProfile from 'api/userProfile/GetUserProfileV1';
import * as apiPutUserProfile from 'api/userProfile/PutUserProfileV1';
import { Image } from 'types/Media/Image';
import { UserRole } from 'types/User/Admin/UserRole/UserRole';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

export type User = {
	firstName: string;
	lastName: string;
	email: string | null;
	passwordCurrent: string | null;
	passwordNew1: string | null;
	passwordNew2: string | null;
	languageId: string;
	cultureCode: string | null;
	profilePicture: Image | null;
	userSettings: UserSettings.Type | null;
	roles: UserRole[] | null;
	nutrients: UserNutrients[] | null;
	isActive: boolean | null;
};

export type UserNutrients = {
	id: string;
	sortOrder: number;
	name: string;
	unitShortNameSingular: string;
	nutrientCategory: {
		id: string;
		name: string;
	};
};

export const defaultUser = (): User => {
	return {
		firstName: '',
		lastName: '',
		email: null,
		passwordCurrent: null,
		passwordNew1: null,
		passwordNew2: null,
		languageId: '',
		cultureCode: null,
		profilePicture: null,
		userSettings: UserSettings.create(),
		roles: null,
		nutrients: null,
		isActive: null,
	};
};

export function mapUserFromApi(user: apiGetUser.ResponseData): User {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email,
		passwordCurrent: null,
		passwordNew1: null,
		passwordNew2: null,
		languageId: user.languageId,
		cultureCode: null,
		profilePicture: user.profilePicture,
		userSettings: UserSettings.mapUserSettingsFromApi(user.userSettings),
		roles: user.roles,
		nutrients: user.nutrients,
		isActive: user.isActive,
	};
}

export function mapUserToApi(user: User): apiPutUser.Request {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		email: user.email ?? '',
		languageId: user.languageId,
		profilePictureId: user.profilePicture?.id ? user.profilePicture.id : undefined,
		nutrients: user.nutrients?.map((e) => e.id) ?? [],
		userSettings: UserSettings.mapUserSettingsToApi(user.userSettings),
		roles: user.roles?.map((e) => e.id) ?? [],
		isActive: user.isActive ?? true,
	};
}

export function mapNewUserToApi(user: User): apiPostUser.Request {
	return {
		user: {
			isActive: user.isActive ?? true,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email ?? '',
			languageId: user.languageId,
			profilePictureId: user.profilePicture?.id ? user.profilePicture.id : undefined,
			nutrients: user.nutrients?.map((e) => e.id) ?? [],
			userSettings: UserSettings.mapUserSettingsOfNewUserToApi(user.userSettings),
			roles: user.roles?.map((e) => e.id) ?? [],
		},
		confirmationPageUrl: window.origin + '/verify',
	};
}

export function mapUserProfileFromApi(user: apiGetUserProfile.ResponseData): User {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		email: null,
		passwordCurrent: null,
		passwordNew1: null,
		passwordNew2: null,
		languageId: user.languageId,
		cultureCode: null,
		profilePicture: user.profilePicture,
		userSettings: UserSettings.mapUserProfileSettingsFromApi(user.userSettings),
		roles: null,
		nutrients: user.nutrients,
		isActive: null,
	};
}

export function mapUserProfileToApi(user: User): apiPutUserProfile.Request {
	return {
		firstName: user.firstName,
		lastName: user.lastName,
		password: user.passwordCurrent ?? undefined,
		newPassword: user.passwordNew1 ?? undefined,
		languageId: user.languageId,
		profilePictureId: user.profilePicture?.id ? user.profilePicture.id : undefined,
		nutrients: user.nutrients?.map((e) => e.id) ?? [],
		userSettings: UserSettings.mapUserProfileSettingsToApi(user.userSettings),
	};
}
