import { asNullable } from './coercions';
import { Optional } from './promiseExtensions';

type RecordMap<K extends string | number, B> = Record<K, B> & {
	map<S>(f: (k: K, b: B) => S): S[];
};

type RemovalTuple<T> = [T, T[]];
type RemovalTupleOptional<T> = [T | null, T[]];

class ArrayAsync<T> {
	array: Promise<T>[];
	constructor(array: Array<Promise<T>>) {
		this.array = array;
	}
	awaitAll(): Promise<Array<T>> {
		return Promise.all(this.array);
	}
}

class Removal<T> {
	private constructor(
		private value: T | null,
		private continuation: T[]
	) {}

	static some<T>(value: T, array: T[]) {
		if (!value) {
			throw Error('Provided value must not be empty');
		}
		return new Removal(value, array);
	}

	static none<T>(t: T[]) {
		return new Removal<T>(null, t);
	}

	orElseRemove(f: (t: T) => boolean) {
		if (this.value === null) return this.continuation.remove(f);

		return this;
	}

	get(): RemovalTupleOptional<T> {
		return [this.value, this.continuation];
	}

	orElse(f: () => T): RemovalTuple<T> {
		if (this.value === null) return [f(), this.continuation];

		return [this.value, this.continuation];
	}
}

declare global {
	interface Array<T> {
		/**
		 * Return the first n items of the array into a new array.
		 * @param n
		 */
		take(n: number): Array<T>;

		/**
		 * Return the unique items in this array, sort order is preserved.
		 */
		distinct(): Array<T>;
		/**
		 * Like map(), but an async version. Returns a Promise that we can await.
		 *
		 * @param f
		 */

		/**
		 * 		 * Return the unique items in this array, sort order is preserved.
		 * 		 		 */
		distinctBy(f: (x: T) => string): Array<T>;

		mapAsync<S>(f: (x: T) => Promise<S>): ArrayAsync<S>;
		/**
		 * Like the map() function, but filters out nulls and undefines before they're passed to the
		 * mapping functrion. This allows us to build an array of not-nulls. e.g. myArray.keep((x : T | null) => x!)
		 * @param t
		 */
		keep<S>(t: (x: T) => S): Array<S>;

		keepOptional<S>(t: (x: T) => Optional<S>): Array<S>;

		/**
		 * Order the array given a selector function that operates on an element T.
		 * @param f
		 */
		orderBy<S>(f: (x: T) => S): Array<T>;

		zip<S1>(s1: Array<S1>): Array<[T, S1]>;
		zip<S1, S2>(s1: Array<S1>, s2: Array<S2>): Array<[T, S1, S2]>;
		zip<S1, S2, S3>(s1: Array<S1>, s2: Array<S2>, s3: Array<S3>): Array<[T, S1, S2, S3]>;
		zip<S1, S2, S3, S4>(
			s1: Array<S1>,
			s2: Array<S2>,
			s3: Array<S3>,
			s4: Array<S4>
		): Array<[T, S1, S2, S3, S4]>;

		/**
		 * Like the splice() function but makes a copy
		 *
		 * @param lengtgh
		 */
		drop(length: number): Array<T>;

		/**
		 * Group the array given a grouping function
		 * @param fn
		 */
		groupBy<K extends string | number>(fn: (item: T) => K): RecordMap<K, T[]>;

		/**
		 * Like push() but if input is null, do nothing
		 * @param x
		 */
		addIfNotNull<T>(x: T | null): Array<T>;

		/**
		 * Select the minimal element of the array, given a selector function
		 * @param f
		 */
		minBy(f: (t: T) => number): number;

		/**
		 * Select the maximal element of the array, given a selector function
		 * @param f
		 */
		maxBy(f: (t: T) => number): number;

		/**
		 * Like reduce function on the array, but returns the sequence of all reductions.
		 *
		 * @param f The aggregation function.
		 * @param initial The aggregation start value.
		 */
		reductions<S>(f: (acc: S | null, el: T) => S, initial?: S): Array<S>;

		/**
		 * Removes the first occurrence where the predicate f succeeds.
		 * Returns a Removal Object which can be used for further computation, or can be used to
		 * get the results directly.
		 *
		 * @param f The predicate.
		 *
		 */
		remove(f: (t: T) => boolean): Removal<T>;
		/**
		 * Like the map() function, but filters out nulls and undefines before they're passed to the
		 * mapping functrion. This allows us to build an array of not-nulls. e.g. myArray.keep((x : T | null) => x!)
		 */
		isEmpty(): boolean;

		last(): T;

		butlast(): Array<T>;
		/**
		 * Build up a dictionary based on an array. Requires a function to extract the key and a function
		 * to extract the value (for each element). Note that if the keyFunction returns duplicates, then
		 * the latter will succeed.
		 */
		firstOrDefault(): T | null;

		/**
		 * Returns the first element of the array, ensuring it's not null.
		 */
		first(): T;

		/**
		 * Transform the array into an index, given a key function and a value selector function
		 * @param keyFunction
		 * @param valueFunction
		 */
		toDictionary<V>(
			keyFunction: (x: T) => string | number,
			valueFunction: (x: T) => V
		): { [key: string]: V } & { count: () => number };

		relativePositions<K>(
			groupSelector: (t: T) => string,
			idSelector: (id: T) => string,
			mapper: (t: T, relativeIndex: number) => K
		): K[];
	}
}

Array.prototype.firstOrDefault = function () {
	if (this.length === 0) return null;

	return this[0];
};

Array.prototype.addIfNotNull = function <T>(x: T | null) {
	if (x) return [...this, x];
	return this;
};

const tupleComparer = <T, S>(f: (x: T) => S) => {
	return (a: any, b: any): number => {
		const a1 = f(a);
		const b1 = f(b);

		if (Array.isArray(a1) && Array.isArray(b1)) {
			for (const el of a1.zip(b1)) {
				const [el1, el2] = el;

				if (el1 > el2) return 1;
				if (el1 < el2) return -1;
			}
			return 0;
		}
		if (a1 > b1) return 1;
		if (a1 < b1) return -1;
		return 0;
	};
};

Array.prototype.orderBy = function <T, S>(f: (x: T) => S) {
	const copy = [...this];
	copy.sort(tupleComparer(f));
	return copy;
};

Array.prototype.minBy = function <T>(f: (t: T) => number) {
	return Math.min.apply(null, this.map(f));
};

Array.prototype.maxBy = function <T>(f: (t: T) => number) {
	return Math.max.apply(null, this.map(f));
};

Array.prototype.reductions = function <T, S>(
	f: (acc: S | null, el: T) => S,
	initial?: S
): Array<S> {
	if (this.isEmpty()) return [];

	const result: Array<S> = [f(asNullable(initial), this[0])];

	for (let i = 1; i < this.length; i++) {
		const el = f(result[i - 1], this[i]);
		result.push(el);
	}

	return result;
};

Array.prototype.zip = function (...arrays: Array<any>): Array<any> {
	const dictionaries = arrays.map((a: Array<any>) =>
		a
			.map((el, index) => [index, el])
			.toDictionary(
				([i, _el]) => i as number,
				([_i, el]) => el
			)
	);

	return this.map((x, index) => [x, ...dictionaries.map((x) => x[index])]);
};

Array.prototype.drop = function (length: number) {
	return this.slice(length, this.length);
};

Array.prototype.first = function () {
	if (this.length === 0) {
		throw 'List is empty, cannot get first element';
	}

	return this[0];
};

Array.prototype.isEmpty = function (): boolean {
	return this.length === 0;
};

Array.prototype.mapAsync = function <S, T>(f: (x: T) => Promise<S>) {
	return new ArrayAsync(this.map((t) => f(t)));
};

Array.prototype.take = function (n: number) {
	return [...this].slice(0, n);
};

Array.prototype.distinct = function () {
	return Array.from(new Set(this));
};

Array.prototype.distinctBy = function <T>(f: (x: T) => string) {
	const output: T[] = [];
	const filter = new Set<string>();
	for (const i of this) {
		const key = f(i);
		if (!filter.has(key)) {
			filter.add(key);
			output.push(i);
		}
	}
	return output;
};

Array.prototype.keep = function <T, S>(f: (x: T) => S) {
	return this.filter((a) => a).map(f);
};

Array.prototype.keepOptional = function <T, S>(f: (x: T) => Optional<S>) {
	return this.filter((a) => a)
		.map(f)
		.flatMap((x) => x.toArray((x) => x));
};

Array.prototype.last = function <T>(): T {
	return this[this.length - 1];
};

Array.prototype.butlast = function <T>(): Array<T> {
	return [...this].slice(0, this.length - 1);
};

Array.prototype.toDictionary = function <T, V>(
	keyFunction: (x: T) => string,
	valueFunction: (x: T) => V
) {
	return this.reduce(
		(acc, el) => {
			acc[keyFunction(el)] = valueFunction(el);
			return acc;
		},
		{ count: () => this.length }
	);
};

Array.prototype.groupBy = function <T, K extends string | number>(
	fn: (item: T) => K
): RecordMap<K, T[]> {
	const x: Record<K, T[]> = this.reduce<Record<K, T[]>>((prev, curr) => {
		const groupKey = fn(curr);
		const group = prev[groupKey] || [];
		group.push(curr);
		return { ...prev, [groupKey]: group };
	}, {} as any);

	return {
		...x,
		map: <U>(f: (k: K, el: T[]) => U) => {
			const result: U[] = [];
			for (const [groupKey, groupItems] of Object.entries(x)) {
				result.push(f(groupKey as any, groupItems as T[]) as U);
			}
			return result;
		},
	};
};

export function relativePositions<T, K>(
	array: Array<T>,
	groupSelector: (t: T) => string,
	idSelector: (id: T) => string,
	mapper: (t: T, relativeIndex: number) => K
) {
	const result: K[] = [];
	const groups = array.groupBy(groupSelector);

	for (const [groupKey, groupItems] of Object.entries(groups)) {
		if (groupKey === 'map') continue;

		const occurrences: { [k: string]: number } = {};
		const itemsWithRelativePositions = (groupItems as T[]).reduce((acc: K[], el: T) => {
			const id = idSelector(el);
			if (id in occurrences) {
				const occurrence = occurrences[id];
				acc.push(mapper(el, occurrence + 1));
				occurrences[id] = occurrence + 1;
			} else {
				acc.push(mapper(el, 0));
				occurrences[id] = 0;
			}
			return acc;
		}, [] as K[]);

		result.push(...itemsWithRelativePositions);
	}

	return result;
}

Array.prototype.remove = function <T>(f: (t: T) => boolean) {
	const index = this.findIndex(f);

	if (index >= 0) {
		const subArray = [...this];
		subArray.splice(index, 1);
		return Removal.some(this[index], subArray);
	}

	return Removal.none(this);
};

Array.prototype.relativePositions = function <T, K>(
	groupSelector: (t: T) => string,
	idSelector: (id: T) => string,
	mapper: (t: T, relativeIndex: number) => K
) {
	const result: K[] = [];
	const groups = this.groupBy(groupSelector);

	for (const [groupKey, groupItems] of Object.entries(groups)) {
		if (groupKey === 'map') continue;

		const occurrences: { [k: string]: number } = {};
		const itemsWithRelativePositions = (groupItems as T[]).reduce((acc: K[], el: T) => {
			const id = idSelector(el);
			if (id in occurrences) {
				const occurrence = occurrences[id];
				acc.push(mapper(el, occurrence + 1));
				occurrences[id] = occurrence + 1;
			} else {
				acc.push(mapper(el, 0));
				occurrences[id] = 0;
			}
			return acc;
		}, [] as K[]);

		result.push(...itemsWithRelativePositions);
	}

	return result;
};
export const recordToArray = <K extends string, B>(r: Record<K, B> | null | undefined) => {
	const result: [K, B][] = [];

	if (r) {
		for (const [key, value] of Object.entries(r)) {
			result.push([key as K, value as B]);
		}
	}
	return result;
};
