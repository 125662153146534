import * as apiSW from 'api/baseIngredient/GetBaseIngredientStartingWithV1';
import BaseIngredient from 'classes/_general/Search/BaseIngredient/BaseIngredient';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class BaseIngredientList implements Selectable {
	public all: BaseIngredient[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: BaseIngredient[] = this.all.filter((e: BaseIngredient) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	private mapToSelectItems(input: BaseIngredient[]): ISelectItem[] {
		return input.map((e: BaseIngredient) => {
			return e.toSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseBaseIngredient[]): void {
		this.all = [];
		for (const i of input) {
			const baseIngredient: BaseIngredient = new BaseIngredient(i);
			this.all.push(baseIngredient);
		}
	}

	public getById(id: string): BaseIngredient | undefined {
		return this.all.find((e: BaseIngredient) => {
			return e.id === id;
		});
	}
}
