import axios from 'axios';

import { Characteristic } from 'enums/characteristic';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Accessory/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	carbonDioxide: CarbonDioxide[] | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	carbonDioxideValue: number | null;
	categories: Category[] | null;
	characteristic: Characteristic;
	density: number | null;
	descriptionTranslations: Translation;
	generalInfoTranslations: Translation;
	id: string;
	image: Image;
	isPriceRecipeRelevant: boolean;
	namePluralTranslations: Translation | null;
	nameSingularTranslations: Translation | null;
	price: number | null;
	source: string | null;
	synonyms: Synonyms;
	tags: Tag[] | null;
	unitWeights: UnitWeights[] | null;
};

export type Image = {
	availableSizes: number[];
	id: string;
	url: string;
};

export type CarbonDioxideLabel = {
	color: string | null;
	label: string | null;
};

export type Translation = {
	[key: string]: string;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	namePlural: string;
	nameSingular: string;
};

export type UnitWeights = {
	id: string;
	name: string | null;
	source: string | null;
	weight: number;
};

export type Tag = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	source: string | null;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Category = {
	id: string;
	name: string | null;
	source: string | null;
};

export type CarbonDioxide = {
	origin: string | null;
	originLocationId: string;
	productionMethod: string | null;
	productionMethodId: string;
	source: string | null;
	value: number;
};
