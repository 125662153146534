import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import BtnDownloadPdf from 'components/desktop/_general/Button/BtnDownloadPdf/BtnDownloadPdf';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import { getPicturePath } from 'functions/getPicturePath';
import { Image } from 'types/Media/Image';

interface IProps {
	handleSave: () => void;
	title: string;
	image: Image | null;
	isLoading: boolean;
	readOnly: boolean;
	bottomArea: JSX.Element;
	handleToggleOverlay?: () => void;
	id?: string;
	setDisplayOverlay?: (input: boolean) => void;
}

const DetailHeader = (props: IProps) => {
	const { t } = useTranslation();

	const renderImage = (): JSX.Element | null => {
		if (!props.image) {
			return (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ backgroundColor: 'grey', width: '150px', height: '150px' }}
				>
					<FontAwesomeIcon size="4x" icon={faCamera} style={{ color: 'white' }} />
				</div>
			);
		}

		return (
			<img
				src={getPicturePath(props.image, 230)}
				alt="thumbnail"
				style={{ width: '150px', height: '150px', objectFit: 'cover' }}
			/>
		);
	};

	const renderTopArea = (): JSX.Element => {
		return (
			<div className="d-flex flex-row justify-content-between" style={{ width: '100%' }}>
				<div style={{ fontSize: '1.7em', fontWeight: 'bold', marginTop: '-9px' }}>
					{props.title}
				</div>
				<RenderIf condition={!props.readOnly}>
					<div>
						{props.setDisplayOverlay && (
							<BtnDownloadPdf
								setDisplayOverlay={props.setDisplayOverlay}
								id={props.id}
							/>
						)}
						<RenderIf
							entitlements={[ENTITLEMENTS.URL_ANALYSIS]}
							condition={Boolean(props.handleToggleOverlay)}
						>
							<button
								type="button"
								style={{ marginRight: '10px' }}
								className="btn btn-outline-primary"
								onClick={props.handleToggleOverlay}
							>
								<FontAwesomeIcon icon={faCloudArrowDown as IconProp} />
								<span style={{ paddingLeft: '6px' }}>{t('_general:IMPORT')}</span>
							</button>
						</RenderIf>
						<BtnSave
							handleSave={() => props.handleSave()}
							isLoading={props.isLoading}
						/>
					</div>
				</RenderIf>
			</div>
		);
	};

	return (
		<fieldset style={{ padding: '10px' }}>
			<div className="d-flex" style={{ gap: '10px' }}>
				{renderImage()}
				<div
					className="d-flex flex-column justify-content-between"
					style={{ flexGrow: '1' }}
				>
					{renderTopArea()}
					<hr />
					{props.bottomArea}
				</div>
			</div>
		</fieldset>
	);
};

export default DetailHeader;
