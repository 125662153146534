import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import MenuPlanCalories from 'components/desktop/MenuPlan/Calories/MenuPlanCalories';
import MenuPlanNutrients from 'components/desktop/MenuPlan/Nutrients/MenuPlanNutrients';

interface IProps {
	selectedItem: Recipe | Menu | Row | Item | Column | null;
	menuPlan: MenuPlan;
}

const MenuPlanNutrientInformations = (props: IProps) => {
	const renderCalories = (): JSX.Element => {
		if (props.selectedItem instanceof Recipe) {
			return <MenuPlanCalories object={props.selectedItem} />;
		}
		return <></>;
	};

	const renderNutrients = (): JSX.Element => {
		if (!(props.selectedItem instanceof Item)) {
			return <MenuPlanNutrients object={props.selectedItem ?? props.menuPlan} />;
		}
		return <></>;
	};

	return (
		<div>
			{renderCalories()}
			{renderNutrients()}
		</div>
	);
};

export default MenuPlanNutrientInformations;
