import { RefObject } from 'react';

export type TextInfo = {
	indexCursor: number;
	indexWordStart: number;
	indexWordEnd: number;
	word: string;
	textBeforeWord: string;
	textAfterWord: string;
	textBeforeCursor: string;
	textAfterCursor: string;
	textUntilAfterWord: string;
};

function getAnalyseWordList(input: string): string[] {
	let output: string = input.replaceAll(/\s/g, '<++> <++>');
	output = output.replaceAll('<++><++>', '<++>');
	return output.split('<++>');
}

export function getTextInfoFromSelection(text: string, indexCursor: number): TextInfo {
	let indexWordStart: number = 0;
	let indexWordStartSet: boolean = false;
	let indexWordEnd: number = 0;

	let index: number = 0;
	const wordList: string[] = getAnalyseWordList(text);
	for (const word of wordList) {
		index = index + word.length;
		if (index >= indexCursor && indexWordStartSet === false) {
			indexWordStart = index - word.length;
			indexWordStartSet = true;
		}
		if (index >= indexCursor && indexWordEnd === 0) {
			indexWordEnd = index;
		}
	}

	const word: string = text.slice(indexWordStart, indexWordEnd);

	const textBeforeWord: string = text.slice(0, indexWordStart);
	const textAfterWord: string = text.slice(indexWordEnd);

	const textBeforeCursor: string = text.slice(0, indexCursor);
	const textAfterCursor: string = text.slice(indexCursor);

	const textUntilAfterWord: string = text.slice(0, indexWordEnd);

	return {
		indexCursor: indexCursor,
		indexWordStart: indexWordStart,
		indexWordEnd: indexWordEnd,
		word: word,
		textBeforeWord: textBeforeWord,
		textAfterWord: textAfterWord,
		textBeforeCursor: textBeforeCursor,
		textAfterCursor: textAfterCursor,
		textUntilAfterWord: textUntilAfterWord,
	};
}

export function getCursorOffset(
	spanForWidth: RefObject<HTMLSpanElement> | null,
	divForHeight: RefObject<HTMLDivElement> | null,
	divParent: RefObject<HTMLDivElement> | null,
	textArea: RefObject<HTMLTextAreaElement> | null,
	textInfo: TextInfo
) {
	if (
		spanForWidth &&
		spanForWidth.current &&
		divForHeight &&
		divForHeight.current &&
		textArea &&
		textArea.current &&
		divParent &&
		divParent.current
	) {
		divParent.current.style.display = 'inherit';

		const textAreaRects = textArea.current.getClientRects();
		if (textAreaRects[0]) {
			divParent.current.style.maxWidth = textAreaRects[0].width + 'px';

			spanForWidth.current.innerText = textInfo.textUntilAfterWord;
			const dummyWidthRects = spanForWidth.current.getClientRects();

			divForHeight.current.innerText = textInfo.textUntilAfterWord;
			const dummyHeightRects = divForHeight.current.getClientRects();

			const top: number = dummyHeightRects[0].height + 5 - textArea.current.scrollTop;
			const left: number = dummyWidthRects[dummyWidthRects.length - 1].width;
			return { top: top, left: left };
		}
	}
	return { top: 0, left: 0 };
}
