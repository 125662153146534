import StatusManagementComponent from 'components/desktop/StandingData/Status/Management/StatusManagementComponent';

const StatusManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<StatusManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default StatusManagementContainer;
