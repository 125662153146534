import ChannelManagementComponent from 'components/desktop/StandingData/Channel/Management/ChannelManagementComponent';

const ChannelArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ChannelManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ChannelArchiveContainer;
