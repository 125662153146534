import * as apiPostRecipeCalculateV1 from 'api/recipe/PostRecipeCalculateV1';
import { IngredientType } from 'enums/ingredientType';
import { SegmentStatus } from 'types/Recipe/SegmentStatus';

import { IncorporationShare } from './Recipe';

export type SegmentIngredient = {
	quantityText: null | string;
	quantity: null | number;
	maxQuantity: null | number;
	unit: null | string;
	unitId: null | string;
	additionBefore: null | string;
	ingredient: null | string;
	ingredientId: null | string;
	additionAfter: null | string;
	isFluid: boolean | null;
	scalingFactor: number | null;
	status: SegmentStatus;
	type: IngredientType;
	errorMessages: string[];
	errorActions: string[];
};

export const mapIngredientToAPIIngredient = (
	x: {
		index: number;
		segmentIngredient: SegmentIngredient;
	},
	incorporationShares: (IncorporationShare & { index: number })[]
): apiPostRecipeCalculateV1.Ingredients => {
	const share = incorporationShares.find((y) => y.index === x.index);
	return {
		ingredientIndex: x.index,
		incorporationShare: share?.incorporationShare ?? null,
		ingredientId: x.segmentIngredient.ingredientId!,
		maxQuantity: x.segmentIngredient.maxQuantity,
		quantity: x.segmentIngredient.quantity,
		unitId: x.segmentIngredient.unitId,
	};
};

export const mapAccessoryToAPIAccessory = (x: {
	index: number;
	segmentIngredient: SegmentIngredient;
}): apiPostRecipeCalculateV1.Accessories => {
	return {
		accessoryIndex: x.index,
		accessoryId: x.segmentIngredient.ingredientId!,
		maxQuantity: x.segmentIngredient.maxQuantity,
		quantity: x.segmentIngredient.quantity,
		unitId: x.segmentIngredient.unitId,
	};
};
