import { BaseIngredientSortOrder } from 'enums/SortOrder/BaseIngredientSortOrder';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

export default class NullableBaseIngredientSortOrder implements IAdvancedSearchKey {
	public id: string;
	public value: BaseIngredientSortOrder | null;
	public defaultValue: BaseIngredientSortOrder;

	constructor(id: string, defaultValue: BaseIngredientSortOrder) {
		this.id = id;
		this.value = null;
		this.defaultValue = defaultValue;
	}

	public reset() {
		this.value = null;
	}

	public mapResponse(input: BaseIngredientSortOrder) {
		this.value = input;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? (value as BaseIngredientSortOrder) : null;
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value;
		}
		return null;
	}

	public mapToApi(): BaseIngredientSortOrder {
		if (this.value === null) {
			return this.defaultValue;
		}
		return this.value;
	}
}
