import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetStartingWith from 'api/mailTemplate/GetMailTemplateStartingWithV1';
import * as apiGetList from 'api/mailTemplate/GetMailTemplateV1';
import MailTemplateDetail from 'components/desktop/StandingData/MailTemplate/MailTemplateDetail';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import {
	mapAdvancedSearchToUrl,
	mapStateToAdvancedSearch,
	mapUrlToAdvancedSearch,
} from 'functions/mappers/MailTemplate/URL/mapAdvancedSearchToUrl';
import { ISelectItem } from 'interfaces/ISelectItem';
import {
	MailTemplateAdvancedSearchDto,
	defaultMailTemplateAdvancedSearchDto,
} from 'types/MailTemplate/Search/MailTemplateAdvancedSearchDto';

const maxNumberOfButtons = 5;

const MailTemplateManagement = () => {
	const { t } = useTranslation();

	const [mailTemplates, setMailTemplates] = useState<apiGetList.ResponseMailTemplate[]>([]);
	const [mailTemplatesStartingWith, setMailTemplatesStartingWith] = useState<
		apiGetStartingWith.ResponseMailTemplate[]
	>([]);
	const [advancedSearch, setAdvancedSearch] = useState<MailTemplateAdvancedSearchDto>(
		defaultMailTemplateAdvancedSearchDto()
	);
	const [searchTerm, setSearchTerm] = useState<string>(advancedSearch.searchTerm ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState<number>(0);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		await getMailTemplatesStartingWith();
		try {
			if (window.location.href.includes('?')) {
				let advancedSearchCpy: MailTemplateAdvancedSearchDto =
					defaultMailTemplateAdvancedSearchDto();
				if (window.history.state.searchTerm !== undefined) {
					advancedSearchCpy = mapStateToAdvancedSearch(window.history.state);
					setAdvancedSearch(advancedSearchCpy);
					getMailTemplates(advancedSearchCpy);
				} else {
					const params = new URL(String(window.location)).searchParams;
					advancedSearchCpy = mapUrlToAdvancedSearch(params);
					setAdvancedSearch(advancedSearchCpy);
					getMailTemplates(advancedSearchCpy);
				}
				if (advancedSearchCpy.id !== null) {
					setDisplayOverlay(true);
				}
			} else {
				window.history.pushState(advancedSearch, '');
				getMailTemplates();
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getMailTemplates = async (dto?: MailTemplateAdvancedSearchDto) => {
		const searchTerm = dto?.searchTerm ?? '';
		const pageIndex = dto?.pageIndex ?? 0;
		const pageSize = dto?.pageSize ?? 12;
		const response = await apiGetList.callApi(searchTerm, pageIndex, pageSize);
		response.do((x) => {
			setMailTemplates(x.data);
			setTotalCount(x.totalCount);
		});
	};

	const getMailTemplatesStartingWith = async () => {
		const response = await apiGetStartingWith.callApi('');
		response.do((x) => setMailTemplatesStartingWith(x));
	};

	const getSuggestedMailTemplates = async (): Promise<ISelectItem[]> => {
		let output: apiGetStartingWith.ResponseMailTemplate[] = [];
		output = mailTemplatesStartingWith.filter((e: apiGetStartingWith.ResponseMailTemplate) => {
			if (e.name.toLowerCase().startsWith(searchTerm.toLowerCase())) {
				return e;
			}
		});
		return mapToISelectItem(output);
	};

	const handleChangeUrl = (dto: MailTemplateAdvancedSearchDto) => {
		setAdvancedSearch(dto);
		window.history.replaceState(dto, '', mapAdvancedSearchToUrl(dto));
		getMailTemplates(dto);
	};

	const handleSearch = (input?: string) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.searchTerm = input ?? searchTerm;
		advancedSearchCpy.pageIndex = 0;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangePageIndex = (index: number) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.pageIndex = index;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangeId = (id: string | null) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.id = id;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		return {
			onClick: () => handleOverlayOpen(id),
		};
	};

	const renderButtons = (id: string): JSX.Element => {
		return <BtnGroup edit={getButtonDataEdit(id)} />;
	};

	const renderListRows = (): Row[] => {
		return mailTemplates.map((template: apiGetList.ResponseMailTemplate) => {
			return {
				id: template.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="flex-fill">{template.name}</div>
						<div>{renderButtons(template.id)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						<h1>{t('standingData:MAIL_TEMPLATE_MANAGEMENT')}</h1>
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedMailTemplates}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{`${totalCount} ${t('_general:MAIL_TEMPLATES')}`}</p>
					<StandingDataList
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<MailTemplateDetail />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={totalCount}
						pageIndex={advancedSearch.pageIndex}
						setPageIndex={handleChangePageIndex}
						listItemAmount={advancedSearch.pageSize}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MailTemplateManagement;
