import * as api from 'api/recipe/PostRecipeCalculateV1';

import SeasonsPerIngredient from './SeasonsPerIngredient';

export default class SeasonsPerIngredientList {
	public items: SeasonsPerIngredient[] = [];

	public constructor(input?: api.SeasonsPerIngredient[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.SeasonsPerIngredient[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new SeasonsPerIngredient(i));
		}
	}
}
