import * as apiSW from 'api/tag/GetTagStartingWithV1';
import TagCategory from 'classes/_general/Search/Tag/TagCategory';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Tag {
	public id: string = '';
	public name: string = '';
	public title?: string;
	public iconSvgUrl?: string;
	public display?: boolean;
	public tagCategory?: apiSW.TagCategory;

	public constructor(input?: apiSW.ResponseTag) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToSelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name,
			iconSvgUrl: this.iconSvgUrl,
			display: this.display,
		};
	}

	private mapFromApi(input: apiSW.ResponseTag): void {
		this.id = input.id;
		this.name = input.name;
		this.iconSvgUrl = input.iconSvgUrl;
		this.display = input.display;
		this.tagCategory = new TagCategory(input.tagCategory);
	}
}
