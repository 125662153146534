export function show(errorKey: string): void {
	const elements: any = document.querySelectorAll('[error-key="' + errorKey + '"]');
	for (const element of elements) {
		element.classList.add('is-invalid');
	}
}

export function hide(errorKey: string): void {
	const elements: any = document.querySelectorAll('[error-key="' + errorKey + '"]');
	for (const element of elements) {
		element.classList.remove('is-invalid');
	}
}
