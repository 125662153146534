import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	recipeId: string | null,
	nrOfRecommendations: number,
	recipeText: string | null
): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/AiFunctions/Suggest/AccessoryProducts';
	const params: Parameters = {
		RecipeId: recipeId,
		NrOfRecommendations: nrOfRecommendations,
		RecipeText: recipeText,
	};
	return axios.post(url, params).toOptionalMapped((x) => x.data);
}

export type Parameters = {
	RecipeId: string | null;
	NrOfRecommendations: number | null;
	RecipeText: string | null;
};

export type Response = {
	data: ResponseData; // required
};

export type ResponseData = Data[];
export type Data = {
	id: string;
	externalId: string | null;
	name: string;
	brand: string | null;
	producer: string | null;
	price: number | null;
	imageUrl: string | null;
	externalUrl: string | null;
	quantity: string | null;
};
