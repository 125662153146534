import { useMemo } from 'react';

import { AccessoryProductSlider } from 'components/desktop/Recipe/AccessoryProductSlider/AccessoryProductSlider';
import { IngredientsProductSlider } from 'components/desktop/Recipe/IngredientsProductSlider/IngredientsProductSlider';
import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import { SimilarRecipes } from 'components/desktop/Recipe/SimilarRecipes/SimilarRecipes';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { useApi } from 'hooks/useApi';
import * as Product from 'types/Product/RecipeProduct';
import { RecipeProduct } from 'types/Recipe/Recipe';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	selectedCultureCode: string;
	ingredientProducts: Product.Type[];
	recipe: Recipe;
}

const nrOfRecommendationsToFetch = 16;

const RecipeSuggestionsComponent = (props: IProps) => {
	const mapToProduct = (products: RecipeProduct[], cultureCode: string): Product.Type[] =>
		products.map<Product.Type>((rp) => ({
			...rp,
			name: rp.nameTranslations[cultureCode] ? rp.nameTranslations[cultureCode] : '',
		}));

	const accessoryProducts = useMemo(
		(): Product.Type[] =>
			mapToProduct(props.recipe.accessoryProducts, props.selectedCultureCode),
		[props.recipe.accessoryProducts, props.selectedCultureCode]
	);

	const [aiAccessories] = useApi(
		[],
		async (recipeId: string | null, products: Product.Type[]) => {
			if (areEntitlementsInUserEntitlements([ENTITLEMENTS.ACCESSORY_PRODUCT])) {
				const maxNrOfRecommendations = nrOfRecommendationsToFetch - products.length;

				const shortDescriptionTranslation = props.recipe.shortDescriptionTranslations[
					props.selectedCultureCode
				]
					? props.recipe.shortDescriptionTranslations[props.selectedCultureCode]
					: '';

				const stepsTranslation = props.recipe.stepsTranslations[props.selectedCultureCode]
					? props.recipe.stepsTranslations[props.selectedCultureCode]
					: '';

				const recipeText = `${shortDescriptionTranslation} ${stepsTranslation}`;

				if (maxNrOfRecommendations > 0) {
					return await handlers.getAiAccessories(
						recipeId,
						maxNrOfRecommendations,
						recipeText
					);
				}
			}
			return [];
		},
		props.recipe.id,
		accessoryProducts
	);

	return (
		<>
			<div className="margin-top-25">
				<RenderIf entitlements={[ENTITLEMENTS.SIMILAR_RECIPES]}>
					<SimilarRecipes
						recipeId={props.recipe.id}
						stepsTranslations={props.recipe.stepsTranslations}
						shortDescriptionTranslations={props.recipe.shortDescriptionTranslations}
					/>
					<br />
				</RenderIf>
				<IngredientsProductSlider products={props.ingredientProducts} />
				<br />
				<AccessoryProductSlider products={[...accessoryProducts, ...aiAccessories]} />
			</div>
		</>
	);
};

export default RecipeSuggestionsComponent;
