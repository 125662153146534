import { Link } from 'react-router-dom';

import 'components/desktop/Dashboard/ButtonCard/ButtonCard.scss';
import Card from 'components/desktop/_general/Card/Card';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';

type Button = {
	title: string;
	link: string;
	disabled?: boolean;
};

interface Props {
	title: string;
	icon: JSX.Element;
	btn1: Button;
	btn2: Button;
	btn3: Button;
}

const ButtonGroup = (props: Props) => {
	const getClassName = (button: Button): string => {
		if (button.disabled) {
			return 'btn btn-primary disabled';
		}
		return 'btn btn-primary';
	};

	const renderContent = (): JSX.Element => {
		return (
			<>
				<div
					className="text-center"
					style={{
						marginTop: '8px',
						marginBottom: '20px',
						color: '#696969',
					}}
				>
					{props.icon}
				</div>
				<div className="d-grid gap-2">
					<Link to={props.btn1.link} className="btn btn-outline-primary" type="button">
						{props.btn1.title}
					</Link>
					<RenderIf condition={!props.btn2.disabled}>
						<Link
							to={props.btn2.link}
							className={getClassName(props.btn2)}
							type="button"
						>
							{props.btn2.title}
						</Link>
					</RenderIf>
					<RenderIf condition={!props.btn3.disabled}>
						<Link to={props.btn3.link} className="btn btn-secondary" type="button">
							{props.btn3.title}
						</Link>
					</RenderIf>
				</div>
			</>
		);
	};

	return <Card title={props.title} content={renderContent()} />;
};

export default ButtonGroup;
