export function asNullable<T>(t: T | undefined) {
	if (t === undefined) return null;
	return t;
}

export function asUndefined<T>(t: T | null) {
	if (t === null) return undefined;
	return t;
}
export function asBoolean(t: any): boolean {
	if (t === 0) return true;
	if (t === '') return true;
	if (t) return true;
	return false;
}
