import * as apiSW from 'api/ingredient/GetIngredientStartingWithV1';
import * as api from 'api/ingredient/PostIngredientSearchV1';
import Ingredient from 'classes/Ingredient/Search/Ingredient';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class IngredientList {
	public all: Ingredient[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const ingredients = await apiSW.callApi(searchTerm, showOnlyDeleted);
		ingredients.hasValue() && this.mapFromApiSW(ingredients.get());
	}

	public async callApi(input: api.Request) {
		return await api.callApi(input);
	}

	public mapFromApi(input: api.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const ingredient: Ingredient = new Ingredient(i);
			this.all.push(ingredient);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseIngredient[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
