export default function getSeasonalityCode(
	input: { display?: boolean; associatedMonthsFlag?: number | null }[]
): number {
	let output: number = 0;
	for (const season of input) {
		if (season.display) {
			if (season.associatedMonthsFlag) {
				output = output | season.associatedMonthsFlag;
			}
		}
	}
	return output;
}
