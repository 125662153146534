import Archive from 'containers/desktop/StandingData/Unit/Archive/UnitArchive';
import Management from 'containers/desktop/StandingData/Unit/Management/UnitManagement';
import PERMISSIONS from 'enums/permissions';

export const unitRoutes = [
	{
		path: '/unit/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/unit/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
