import { NutriScoreCategoryBaseIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryBaseIngredientCategory';

export type BaseIngredientCategoryPoint = {
	baseIngredientCategoryId: string;
	pointType: NutriScoreCategoryBaseIngredientCategory;
	baseIngredientCategoryName: string | null;
	isPositive: boolean;
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};

export const defaultBaseIngredientCategoryPoint = (): BaseIngredientCategoryPoint => {
	return {
		baseIngredientCategoryId: '',
		pointType: NutriScoreCategoryBaseIngredientCategory.StandardFood,
		baseIngredientCategoryName: null,
		isPositive: false,
		points: [],
	};
};
