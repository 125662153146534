import { faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface IProps {
	handleSave: () => void;
	isLoading: boolean;
}

const BtnSave = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="btn-group" role="group" aria-label="Basic example">
				{props.isLoading ? (
					<button type="button" className="btn btn-primary" disabled={true}>
						<FontAwesomeIcon className="fa-spin" icon={faSync} />
						<span style={{ paddingLeft: '6px' }}>{t('_general:BTN_SAVE')}</span>
					</button>
				) : (
					<button type="button" className="btn btn-primary" onClick={props.handleSave}>
						<FontAwesomeIcon icon={faSave} />
						<span style={{ paddingLeft: '6px' }}>{t('_general:BTN_SAVE')}</span>
					</button>
				)}
			</div>
		</>
	);
};

export default BtnSave;
