import axios from 'axios';

import * as Get from 'api/ingredient/GetIngredientIdV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<Get.ResponseData>> {
	const url: string = `/backend-service/Ingredient/${id}`;
	return axios.put<Get.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	allergens: Allergens;
	baseIngredientId: string;
	calories: number | null;
	carbonDioxide: CarbonDioxideRequest[];
	categories: string[];
	characteristic: Characteristic | null;
	density: number | null;
	hasNutriScore: boolean;
	imageId: string | null;
	isAllergenOverridden: boolean;
	isCarbonDioxideOverridden: boolean;
	isScalingFactorOverridden: boolean;
	isSeasonOverridden: boolean;
	isTagOverridden: boolean;
	isUnitWeightOverridden: boolean;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValues;
	price: number | null;
	products: string[];
	scalingFactor: number | null;
	seasons: string[];
	synonyms: Synonyms;
	tags: string[];
	unitWeights: UnitWeights;
}

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	namePlural: string;
	nameSingular: string;
};

export type CarbonDioxideRequest = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};

export type NutrientValues = {
	[key: string]: number;
};

export type UnitWeights = {
	[key: string]: number;
};

export type Translation = {
	[key: string]: string;
};

export type Allergens = {
	[key: string]: AllergenLogic;
};
