import * as apiGetAll from 'api/currency/GetAllCurrencyV1';
import * as apiGetStartingWithV1 from 'api/currency/GetCurrencyStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';

export type Type = {
	id: string;
	name: string;
	currencyCode: string;
};

export function create(): Type {
	return {
		id: '',
		name: '',
		currencyCode: '',
	};
}

export function mapToISelectItems(input: apiGetStartingWithV1.ResponseCurrency[]): ISelectItem[] {
	return input.map<ISelectItem>((x: apiGetStartingWithV1.ResponseCurrency) => ({
		id: x.id,
		name: x.description,
	}));
}

export async function getAllFromApi(): Promise<Type[]> {
	const response = await apiGetAll.callApi();
	const responseData = response.toNullable();
	if (responseData) {
		return responseData;
	}
	return [];
}
