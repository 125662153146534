import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { getIngredientOrAccessoryClassName } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import NutritionFactsLabelWrapper from 'components/desktop/_general/NutritionFactsLabel/RecipeWrapper';
import { roundUpTo } from 'functions/roundUpTo';
import { RootState } from 'reducers/rootReducer';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import * as UserSettings from 'types/User/UserProfile/UserSettings';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	segments: Segment[] | undefined;
	calculation: Calculation | null;
	recipe: Recipe;
	nutrients: CalculatedNutrient[];
	nutriScore: NutriScore | null;
}

const NutritionFactsLabelView = (props: IProps) => {
	const { t } = useTranslation();

	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);

	const getClassName = (ingredient: SegmentIngredient): string => {
		return ['col-2', 'ingredient-data-col', getIngredientOrAccessoryClassName(ingredient)].join(
			' '
		);
	};

	const handleIngredientLinkClicked = (ingredient: SegmentIngredient) => {
		window.open(`/ingredient/detail/${ingredient.ingredientId}`, '_blank');
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (props.nutrients) {
			const macroWeight = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.reduce((p, c) => p + c.total, 0);
			const macros = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.map((n) => {
					return { label: n.nutrient, value: n.total / macroWeight };
				});
			return macros;
		}
		return [];
	};

	const renderIngredientsTable = (ingredients: SegmentIngredient[]) => {
		return (
			<div className="recipe-rendering-ingredient-container">
				<table
					className="table table-sm table-bordered allergen-rendering-table"
					style={{ marginBottom: '0' }}
				>
					<tbody>
						{ingredients.map((ingredient, i) => (
							<tr key={i}>
								<td
									className="air-table-numeric-column"
									style={{ minWidth: '76px' }}
								>
									{roundUpTo(ingredient.quantity ?? 0, 2)}
								</td>
								<td style={{ minWidth: '96px' }}>{ingredient.unit}</td>
								{ingredient.status.ingredient === 'Recognized' ||
								ingredient.status.ingredient === 'PartiallyRecognized' ? (
									<td
										className={getClassName(ingredient)}
										onClick={() => handleIngredientLinkClicked(ingredient)}
										style={{ cursor: 'pointer', minWidth: '300px' }}
									>
										{ingredient.ingredient}
									</td>
								) : (
									<>
										<td
											className={getClassName(ingredient)}
											style={{ minWidth: '300px' }}
										>
											{ingredient.ingredient}
										</td>
										<td>–</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const renderContent = () => {
		if (props.segments && props.segments.length > 0) {
			return (
				<div className="d-flex">
					<div>
						{props.segments.map((segment, i) => {
							return (
								<div className="recipe-rendering" key={i}>
									<div className="row">
										<div className="col-12">
											{renderIngredientsTable(segment.ingredients)}
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div
						style={{
							marginTop: '20px',
							padding: '20px 0px',
							backgroundColor: '#454545',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: '10px',
						}}
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'row',
								justifyContent: 'center',
								gap: '10px',
							}}
						>
							<NutritionFactsLabelWrapper
								calculation={props.calculation}
								recipe={props.recipe}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '10px',
									height: '100%',
								}}
							>
								<div
									style={{
										backgroundColor: 'white',
										padding: '4px 10px 10px 10px',
										border: '2px solid black',
									}}
								>
									<div
										style={{
											fontWeight: 'bold',
											fontSize: '1.1em',
											marginBottom: '10px',
											paddingBottom: '3px',
											borderBottom: '1px solid black',
										}}
									>
										{t('_general:NUTRI_DIAGRAM')}
									</div>
									<div style={{ width: '270px' }}>
										<NutritionDiagram data={getMacroNutrientChartData()} />
									</div>
								</div>
								<div
									style={{
										backgroundColor: 'white',
										padding: '2px 10px 10px 10px',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										border: '2px solid black',
										flexGrow: 1,
									}}
								>
									<NutriScoreDiagram
										nutriScore={
											props.nutriScore ?? props.recipe.nutriScore ?? null
										}
									/>
									<Co2Display
										value={
											props.calculation?.carbonDioxideValue ??
											props.recipe.carbonDioxideValue
										}
										color={
											props.calculation?.carbonDioxideLabel?.color ??
											props.recipe.carbonDioxideLabel?.color ??
											'#000'
										}
										valueScopeInfo={{
											valueScope: reduxUserSetting['Recipe.List.ValueScope'],
											persons: props.recipe.persons,
											parts: props.recipe.parts,
											weight:
												props.calculation?.weight ??
												props.recipe.weight ??
												props.recipe.ingredientWeight,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};
	return <>{renderContent()}</>;
};
export default NutritionFactsLabelView;
