import Archive from 'containers/desktop/StandingData/Language/Archive/LanguageArchive';
import Management from 'containers/desktop/StandingData/Language/Management/LanguageManagement';
import PERMISSIONS from 'enums/permissions';

export const languageRoutes = [
	{
		path: '/language/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/language/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
