export type ComponentVersion = {
	componentName: string;
	version: string;
	buildDateUtc: string;
	buildId: number;
	branch: string;
	hash: string;
};

export const defaultComponentVersion = (): ComponentVersion => {
	return {
		componentName: '',
		version: '',
		buildDateUtc: '',
		buildId: 0,
		branch: '',
		hash: '',
	};
};
