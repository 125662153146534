import * as api from 'api/recipe/PostRecipeSearchV1';

export default class Image {
	public url: string = '';
	public availableSizes: number[] | null = null;

	public constructor(input?: api.Image) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Image): void {
		this.url = input.url;
		this.availableSizes = input.availableSizes;
	}
}
