import { useParams } from 'react-router-dom';

import UserComponent from 'components/desktop/User/User/UserComponent';

const UserDetailContainer = () => {
	const { id } = useParams();

	return (
		<>
			<main className="container">
				<UserComponent id={id} type="detail" />
			</main>
		</>
	);
};

export default UserDetailContainer;
