import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import Season from 'classes/Ingredient/Search/Season';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import { Icon } from 'types/Icon/Icon';

export default class SeasonList {
	public all: Season[] = [];

	public constructor(input?: apiPostSearch.Season[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): (Icon | null)[] {
		return this.all.map((s: Season) => {
			return s.mapToIcon();
		});
	}

	public getSeasonalityCode() {
		return getSeasonalityCode(this.all);
	}

	public mapFromApi(input: apiPostSearch.Season[] | null) {
		if (input) {
			for (const i of input) {
				const season: Season = new Season(i);
				this.all.push(season);
			}
		}
	}
}
