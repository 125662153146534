import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function apiCall(
	nutrientId: string,
	pointType: 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water'
): Promise<Optional<void>> {
	const url: string = `/backend-service/NutriScoreNutrientPoints/${nutrientId}`;
	return axios
		.delete(url, {
			params: {
				PointType: pointType,
			},
		})
		.toOptionalVoid();
}
