import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { Recipe } from 'types/Recipe/Recipe';

import RecipeNutrients from '../LanguageInput/RecipeNutrients';

interface IProps {
	recipeCalculation: Calculation | null;
	recipe: Recipe;
}

export const RecipeCalculation = (props: IProps) => {
	if (
		props.recipeCalculation &&
		props.recipeCalculation?.nutrientValues &&
		props.recipeCalculation?.nutrientValues.items.length > 0
	) {
		return (
			<RecipeNutrients
				parts={props.recipe.parts ?? 0}
				persons={props.recipe.persons ?? 0}
				nutrients={props.recipeCalculation?.nutrientValues.items}
				weight={props.recipe.weight ?? props.recipe.ingredientWeight ?? 0}
			/>
		);
	}

	return <></>;
};
