import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimer } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as apiGet from 'api/recipe/GetRecipeIdV1';
import Informations from 'containers/desktop/Recipe/Print/Informations';
import { style } from 'containers/desktop/Recipe/Print/RecipePrint';
import * as Recipe from 'types/Recipe/Print/Recipe';

type IProps = {
	recipe: Recipe.Type;
	cultureCode: string;
};
const Overview = ({ recipe, cultureCode }: IProps): JSX.Element => {
	const returnPreparationTime = (): JSX.Element => {
		return (
			<>
				{recipe.activityTimes.map((e, i) => {
					return (
						<div className="mt-2" key={i}>
							<span id="icon">
								<FontAwesomeIcon
									style={{ color: '#aaa' }}
									size="lg"
									icon={faTimer as IconProp}
								/>
							</span>

							{' ' + e.name + ': ' + e.timeValue + ' ' + e.timeUnit.description}
						</div>
					);
				})}
			</>
		);
	};

	const renderIcon = (input: string): JSX.Element => {
		if (input) {
			return (
				<svg
					width="512"
					height="512"
					viewBox="0 0 512 512"
					xmlns="http://www.w3.org/2000/svg"
					style={{
						width: '35px',
						height: '35px',
					}}
					dangerouslySetInnerHTML={{ __html: input }}
				/>
			);
		} else {
			return <></>;
		}
	};

	const returnIcons = (): JSX.Element => {
		const filteredTags: apiGet.Tag[] = recipe.tags.filter((tag: apiGet.Tag) => {
			return tag.display;
		});
		return (
			<div className="d-flex flex-row" style={{ gap: '10px' }}>
				{filteredTags.map((tag: apiGet.Tag, i: number) => {
					return <div key={i}>{renderIcon(tag.iconSvgUrl ?? '')}</div>;
				})}
			</div>
		);
	};

	return (
		<>
			<div className="row">
				<h2 style={style.h1}>{Recipe.getTitle(recipe, cultureCode)}</h2>
				<div className="col-sm-6 col-md-6 mb-3 mb-sm-0">
					<img
						src={recipe.image?.url}
						style={{
							width: '100%',
							objectFit: 'contain',
						}}
					/>
				</div>
				<div className="col-sm-6 col-md-6">
					<div className={'mb-4'}>
						<div style={style.text}>{Recipe.getDescription(recipe, cultureCode)}</div>
						<div style={style.text}>{returnPreparationTime()}</div>
					</div>
					<div>{returnIcons()}</div>
					<Informations recipe={recipe} />
				</div>
			</div>
		</>
	);
};

export default Overview;
