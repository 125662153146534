import * as api from 'api/recipe/PostRecipeCalculateV1';

import Season from './Season';

export default class SeasonList {
	public items: Season[] = [];

	public constructor(input?: api.Seasons[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.Seasons[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new Season(i));
		}
	}
}
