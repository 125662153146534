export const setReduxAutoCompleteLanguages = (allowedAutoCompleteLanguages: string[]) => {
	return {
		type: 'allowedAutoCompleteLanguages/SET',
		payload: allowedAutoCompleteLanguages,
	};
};

export type AllowedAutoCompleteLanguagesAction = {
	type: string;
	payload: string[];
};

export const allowedAutoCompleteLanguagesReducer = (
	state: string[] = ['en-US'],
	action: AllowedAutoCompleteLanguagesAction
): string[] => {
	switch (action.type) {
		case 'allowedAutoCompleteLanguages/SET':
			return action.payload;
		default:
			return state;
	}
};
