import * as apiSW from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import NutriScoreLabel from 'classes/_general/Search/NutriScoreLabel/NutriScoreLabel';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class NutriScoreLabelList implements Selectable {
	public all: NutriScoreLabel[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.translateFromApi(response.get());
	}

	public async search(_searchTerm: string): Promise<void> {}

	public getById(id: string): NutriScoreLabel | undefined {
		return this.all.find((e: NutriScoreLabel) => {
			return e.ids.includes(id);
		});
	}

	private translateFromApi(input: apiSW.ResponseNutriScoreLabel[]): void {
		this.all = [];
		for (const i of input) {
			const nutriScoreLabel: NutriScoreLabel = new NutriScoreLabel(i);
			this.all.push(nutriScoreLabel);
		}
	}
}
