import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/Ingredient/Archive/IngredientArchive';
import Detail from 'containers/desktop/Ingredient/Detail/IngredientDetail';
import New from 'containers/desktop/Ingredient/New/IngredientNew';
import Search from 'containers/desktop/Ingredient/Search/IngredientSearch';
import PERMISSIONS from 'enums/permissions';

const BaseIngredientIdParamsName = 'baseIngredientId';

const routes = [
	{
		path: '/ingredient/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READINGREDIENT],
	},
	{
		path: '/ingredient/search',
		element: <Search />,
		permissions: [PERMISSIONS.READINGREDIENT],
	},
	{
		path: `/ingredient/search/:${BaseIngredientIdParamsName}`,
		element: <Search />,
		permissions: [PERMISSIONS.READINGREDIENT],
	},
	{
		path: '/ingredient/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITEINGREDIENT],
	},
	{
		path: '/ingredient/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITEINGREDIENT],
	},
];

export default [...routes.map(wrapProtectedRoute)];
