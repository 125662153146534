import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import RecipeNutrientCheckboxes from 'components/desktop/Recipe/LanguageInput/RecipeNutrientCheckboxes';
import 'components/desktop/Recipe/RecipeCalories.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import { kiloCaloriesToKilojouleFactor } from 'constants/kiloCaloriesToKilojouleFactor';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import {
	calculateParts,
	calculatePer100Gram,
	calculatePersons,
} from 'functions/calculation/Recipe/calculateRecipeCaloriesNutrients';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	parts: number;
	persons: number;
	calories: number;
	weight: number;
	updateCalories: (value: number | null) => void;
	defaultLocked: boolean;
}

const RecipeCalories = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxColumns = useSelector(
		(state: RootState) => state.userSettings['Recipe.Nutrient.Columns']
	);

	const [isLocked, setIsLocked] = useState<boolean>(props.defaultLocked);
	const numberRegex = /[0-9]+(\\.[0-9]+)?/;

	useEffect(() => {
		setIsLocked(props.defaultLocked);
	}, [props.defaultLocked]);

	const unlockCalories = () => {
		setIsLocked(!isLocked);
		props.updateCalories(null);
	};

	const updateCalories = (input: number) => {
		props.updateCalories(input);
	};

	return (
		<>
			<fieldset id="recipe-calories-view">
				<legend className="mb-3">
					<div className="d-flex justify-content-between" style={{ gap: '10px' }}>
						<div>
							<span>{t('recipe:ENERGY')}</span>
							<RenderIf condition={recipeEditor}>
								<span
									className="lock input-label-field-button"
									id="basic-addon1"
									onClick={unlockCalories}
								>
									{isLocked ? (
										<FontAwesomeIcon icon={faLock} className="icon" />
									) : (
										<FontAwesomeIcon icon={faUnlock} className="icon" />
									)}
								</span>
							</RenderIf>
						</div>
						<RecipeNutrientCheckboxes />
					</div>
				</legend>
				<table
					className="align-middle table table-hover table-sm mb-0"
					style={{ marginBottom: '0px' }}
				>
					<thead className="recipe-nutrients-thead">
						<tr>
							<th style={{ minWidth: '100px' }}>
								<label>{t('_general:UNIT')}</label>
							</th>
							<RenderIf condition={reduxColumns.includes(ValueScope.Parts)}>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_PART')}</label>
								</th>
							</RenderIf>
							<RenderIf condition={reduxColumns.includes(ValueScope.Person)}>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_PERSON')}</label>
								</th>
							</RenderIf>
							<RenderIf condition={reduxColumns.includes(ValueScope.HundredGrams)}>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_100_G')}</label>
								</th>
							</RenderIf>
							<RenderIf condition={reduxColumns.includes(ValueScope.Total)}>
								<th className="text-align-right">
									<label>{t('_general:TOTAL')}</label>
								</th>
							</RenderIf>
						</tr>
					</thead>
					<tbody>
						<tr style={{ backgroundColor: '#ededed', borderColor: 'white' }}>
							<td className="text-align-left">kcal</td>
							<RenderIf condition={reduxColumns.includes(ValueScope.Parts)}>
								<td className="text-align-right">
									{calculateParts(props.calories, props.parts, reduxCultureCode)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.Person)}>
								<td className="text-align-right">
									{calculatePersons(
										props.calories,
										props.persons,
										reduxCultureCode
									)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.HundredGrams)}>
								<td className="text-align-right">
									{calculatePer100Gram(
										props.calories,
										props.weight,
										reduxCultureCode
									)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.Total)}>
								<td className="text-align-right">
									<InputFieldSimple
										handleValueChange={(v: string) =>
											updateCalories(parseFloat(v))
										}
										defaultValue={String(
											Math.round(props.calories * 100) / 100
										)}
										validateRegex={numberRegex}
										disabled={isLocked || !recipeEditor}
										disabledValue={String(
											Math.round(props.calories * 100) / 100
										)}
										className="text-align-right input-sm"
										style={{
											padding: '.25rem .5rem',
											margin: '0',
										}}
									/>
								</td>
							</RenderIf>
						</tr>
						<tr style={{ backgroundColor: '#ededed' }}>
							<td className="text-align-left">kJ</td>
							<RenderIf condition={reduxColumns.includes(ValueScope.Parts)}>
								<td className="text-align-right">
									{calculateParts(
										props.calories,
										props.parts,
										reduxCultureCode,
										true
									)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.Person)}>
								<td className="text-align-right">
									{calculatePersons(
										props.calories,
										props.persons,
										reduxCultureCode,
										true
									)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.HundredGrams)}>
								<td className="text-align-right">
									{calculatePer100Gram(
										props.calories,
										props.weight,
										reduxCultureCode,
										true
									)}
								</td>
							</RenderIf>

							<RenderIf condition={reduxColumns.includes(ValueScope.Total)}>
								<td className="text-align-right">
									<InputFieldSimple
										handleValueChange={(v: string) =>
											updateCalories(
												parseFloat(v) / kiloCaloriesToKilojouleFactor
											)
										}
										defaultValue={String(
											Math.round(
												props.calories * kiloCaloriesToKilojouleFactor * 100
											) / 100
										)}
										validateRegex={numberRegex}
										disabled={isLocked || !recipeEditor}
										disabledValue={String(
											Math.round(
												props.calories * kiloCaloriesToKilojouleFactor * 100
											) / 100
										)}
										className="text-align-right"
										style={{
											padding: '.25rem .5rem',
											margin: '0',
										}}
									/>
								</td>
							</RenderIf>
						</tr>
					</tbody>
				</table>
			</fieldset>
		</>
	);
};

export default RecipeCalories;
