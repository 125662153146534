import { Media } from 'api/media/PostMediaV1';
import * as apiPostMedia from 'api/media/PostMediaV1';
import * as apiPutMedia from 'api/media/PutMediaV1';
import * as apiGet from 'api/season/GetSeasonIdV1';
import * as apiPost from 'api/season/PostSeasonV1';
import * as apiPut from 'api/season/PutSeasonIdV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { IconSvg } from 'types/IconSvg/IconSvg';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Season {
	public id: string = '';
	public iconSvg: IconSvg | null = null;
	public display: boolean = false;
	public associatedMonthsFlag: number | null = 0;
	public sortOrder: number = 0;
	public nameTranslations: LocalizedTranslation<string> = {};

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiUploadMedia(input: FormData | null): Promise<void> {
		const response = await apiPostMedia.callApi(
			this.mapToApiMedia(input) as apiPostMedia.Request
		);
		response.hasValue() && this.mapFromApiUploadMedia(response.get());
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiMedia(
		formData: FormData | null,
		id?: string
	): apiPostMedia.Request | apiPutMedia.Request {
		if (id) {
			return {
				id: id,
				mediaCollection: MEDIACOLLECTION.Icon,
				formData: formData,
			};
		} else {
			return {
				mediaCollection: MEDIACOLLECTION.Icon,
				formData: formData,
			};
		}
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	hasAssociatedMonthsSelected = () => {
		if (this.associatedMonthsFlag === null) return false;
		if (this.associatedMonthsFlag === 0) return false;
		return true;
	};

	private mapToApiPost(): apiPost.Request {
		return {
			iconSvgId: this.iconSvg?.id ?? null,
			display: true,
			associatedMonths: this.hasAssociatedMonthsSelected()
				? String(this.associatedMonthsFlag)
				: null,
			sortOrder: this.sortOrder,
			nameTranslations: this.nameTranslations,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			iconSvgId: this.iconSvg?.id ?? null,
			display: this.display,
			associatedMonths: this.hasAssociatedMonthsSelected()
				? String(this.associatedMonthsFlag)
				: null,
			sortOrder: this.sortOrder,
			nameTranslations: this.nameTranslations,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.iconSvg = input.iconSvg;
		this.display = input.display;
		this.associatedMonthsFlag = input.associatedMonthsFlag;
		this.sortOrder = input.sortOrder;
		this.nameTranslations = input.nameTranslations;
	}

	private mapFromApiUploadMedia(input: Media | null) {
		this.iconSvg = input;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
	}
}
