import axios from 'axios';

import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	calculationMethod: ValueScope,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseCarbonDioxideLabel[]>> {
	const url: string = '/backend-service/CarbonDioxideLabel/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		CalculationMethod: calculationMethod,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.carbonDioxideLabels);
}

interface Parameter {
	SearchTerm: string;
	CalculationMethod: ValueScope;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	carbonDioxideLabels: ResponseCarbonDioxideLabel[];
};

export type ResponseCarbonDioxideLabel = {
	id: string;
	label: string;
	color: string;
};
