import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBoxArchive, faCopy, faEye, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCanUndo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';

export type BtnData = {
	onClick: () => void;
};

interface IProps {
	restore?: BtnData;
	edit?: BtnData;
	delete?: BtnData;
	copy?: BtnData;
	add?: BtnData;
	view?: BtnData;
}

const BtnGroup = (props: IProps) => {
	const { t } = useTranslation();

	const renderButton = (
		title: string,
		onClickFunction: ((e?: any) => void) | undefined,
		icon: JSX.Element
	): JSX.Element => {
		return (
			<button
				type="button"
				className={'btn btn-outline-secondary'}
				data-bs-toggle="tooltip"
				data-bs-placement="top"
				title={title}
				onClick={onClickFunction}
			>
				{icon}
			</button>
		);
	};

	const onClickAdd = (e: any): void => {
		e.stopPropagation();
		if (props.add) {
			props.add.onClick();
			e.preventDefault();
		}
	};

	const onClickEdit = (e: any): void => {
		e.stopPropagation();
		if (props.edit) {
			props.edit.onClick();
			e.preventDefault();
		}
	};

	const onClickView = (e: any): void => {
		e.stopPropagation();
		if (props.view) {
			props.view.onClick();
			e.preventDefault();
		}
	};

	const onClickDelete = (e: any): void => {
		e.stopPropagation();
		if (props.delete) {
			props.delete.onClick();
			e.preventDefault();
		}
	};

	const onClickCopy = (e: any): void => {
		e.stopPropagation();
		if (props.copy) {
			props.copy.onClick();
			e.preventDefault();
		}
	};

	const onClickRestore = (e: any): void => {
		e.stopPropagation();
		if (props.restore) {
			props.restore.onClick();
			e.preventDefault();
		}
	};

	return (
		<>
			<div className="btn-group" role="group" aria-label="Basic example">
				<RenderIf condition={Boolean(props.add)}>
					{renderButton(t('_general:ADD'), onClickAdd, <FontAwesomeIcon icon={faPlus} />)}
				</RenderIf>
				<RenderIf condition={Boolean(props.edit)}>
					{renderButton(
						t('_general:EDIT'),
						onClickEdit,
						<FontAwesomeIcon icon={faPen} />
					)}
				</RenderIf>
				<RenderIf condition={Boolean(props.copy)}>
					{renderButton(
						t('_general:COPY'),
						onClickCopy,
						<FontAwesomeIcon icon={faCopy} />
					)}
				</RenderIf>
				<RenderIf condition={Boolean(props.delete)}>
					{renderButton(
						t('_general:ARCHIVE'),
						onClickDelete,
						<FontAwesomeIcon icon={faBoxArchive} />
					)}
				</RenderIf>
				<RenderIf condition={Boolean(props.restore)}>
					{renderButton(
						t('_general:RESTORE'),
						onClickRestore,
						<FontAwesomeIcon icon={faTrashCanUndo as IconProp} />
					)}
				</RenderIf>
				<RenderIf condition={Boolean(props.view)}>
					{renderButton(
						t('_general:VIEW'),
						onClickView,
						<FontAwesomeIcon icon={faEye} />
					)}
				</RenderIf>
			</div>
		</>
	);
};

export default BtnGroup;
