import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	ingredientId: string,
	unitId: string,
	cultureCode: string = 'de-CH'
): Promise<Optional<number>> {
	const url = '/backend-service/AiFunctions/IngredientUnitWeight/';
	const params: Parameter = {
		CultureCode: cultureCode,
		IngredientId: ingredientId,
		UnitId: unitId,
	};
	return axios
		.get(url, { params: params })
		.toOptionalMapped((x) => Math.floor(x.data * 100) / 100);
}
interface Parameter {
	CultureCode: string;
	IngredientId: string;
	UnitId: string;
}
