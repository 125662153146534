export function calculateOverflowSegmentInformation(l: any, r: any) {
	const result: any[] = [];
	const d: number = l.segmentIndex - r.segmentIndex;
	if (d <= 1) return [];
	for (let i: number = r.segmentIndex + 1; i < l.segmentIndex; i++) {
		result.push(i);
	}
	return result;
}

export function calculateIndexesOfLastRowInSegment(input: any): boolean[] {
	const indexesOfLastRowInSegment = input
		.zip(input.drop(1))
		.map(([a, b]: any) => (b === a ? false : true));

	return indexesOfLastRowInSegment;
}

export function calculateIndexesOfFirstRowInSegment(input: any): boolean[] {
	const indexesOfFirstRowInSegment = input
		.zip([NaN, ...input])
		.map(([a, b]: any) => (b === a ? false : true));

	return indexesOfFirstRowInSegment;
}

export function calculateRowSpanBySegmentIndex(input: any) {
	const rowSpanBySegmentIndex = input
		.groupBy((x: any) => x)
		.map((a: any, b: any) => [a, b.length])
		.toDictionary(
			([a, _b]: any) => a,
			([_a, b]: any) => b
		);

	return rowSpanBySegmentIndex;
}

export function calculateDataWithSegmentInformation(
	data: { [key: string]: any },
	indexesOfFirstRowInSegment: boolean[],
	indexesOfLastRowInSegment: boolean[],
	rowSpanBySegmentIndex: any
) {
	const dataWithSegmentInformation = data
		.zip(indexesOfFirstRowInSegment)
		.map(([d, i]: any) => ({ ...d, isFirstRow: i }))
		.zip(indexesOfLastRowInSegment)
		.map(([d, i]: any) => ({ ...d, isLastRow: i }))
		.map((x: any) => ({ ...x, rowSpan: rowSpanBySegmentIndex[x.segmentIndex] }));

	return dataWithSegmentInformation;
}

export function calculateDataWithOverflowSegmentInformation(input: any) {
	const dataWithOverflowSegmentInformation = input.zip([NaN, ...input]).map(([l, r]: any) => ({
		...l,
		overflowSegments: calculateOverflowSegmentInformation(l, r),
	}));

	return dataWithOverflowSegmentInformation;
}

export function calculateOverflowSegments(
	input: JSX.Element[],
	dataWithOverflowSegmentInformation: any
): JSX.Element[] {
	if (dataWithOverflowSegmentInformation.last()) {
		const overflowSegments = input.drop(
			dataWithOverflowSegmentInformation.last().segmentIndex + 1
		);
		return overflowSegments;
	}
	return [];
}
