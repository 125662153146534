import * as React from 'react';
import { Spinner } from 'react-bootstrap';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/Loading/Loading.scss';
import { AirSvgIcon } from 'components/desktop/_general/Loading/LoadingAnimation';

const Loading: React.FC = () => {
	return (
		<div className="loading-screen">
			<Spinner animation="border" />
		</div>
	);
};

export const SpinnerWhenLoading = (props: { children: JSX.Element; isLoading: boolean }) => {
	return (
		<div style={{ position: props.isLoading ? 'relative' : undefined }}>
			<div style={{ opacity: props.isLoading ? '0.5' : undefined }}>{props.children}</div>
			<RenderIf condition={props.isLoading}>
				<div
					className="d-flex"
					style={{
						justifyContent: 'center',
						position: 'absolute',
						top: 0,
						zIndex: 100,
						left: 0,
						right: 0,
						bottom: 0,
						alignItems: 'center',
					}}
				>
					<AirSvgIcon width="100px" height="100px" />
				</div>
			</RenderIf>
		</div>
	);
};

export default Loading;
