import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Allergen {
	public id: string = '';
	public iconSvgUrl: string | null = null;
	public name: string | null = null;
	public display: boolean = false;
	public logic: AllergenLogic = AllergenLogic.MayContain;
	public source: string | null = null;

	public constructor(input?: api.Allergen) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Allergen): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl;
		this.name = input.name;
		this.display = input.display;
		this.logic = input.logic;
		this.source = input.source;
	}

	public mapToApi(): api.Allergen {
		return {
			id: this.id,
			iconSvgUrl: this.iconSvgUrl,
			name: this.name,
			display: this.display,
			logic: this.logic,
			source: this.source,
		};
	}

	public mapToISelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name ?? '',
			iconSvgUrl: this.iconSvgUrl ?? '',
			display: this.display,
		};
	}
}
