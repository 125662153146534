import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Category as CategoryTree } from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import * as apiGetCategoryTree from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import { getSelectedCategoriesTree } from 'components/desktop/Accessory/AccessoryComponentHandlers';
import UnitWeightEditor from 'components/desktop/Accessory/UnitWeightEditor/UnitWeightEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import CharacteristicRadioSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicRadioSelect';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import TreeInput from 'components/desktop/_general/Input/TreeInput/TreeInput';
import TagEditor from 'components/desktop/_general/TagEditor/TagEditor';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { regexValidationDensity } from 'regex/validation/Density';
import { regexValidationPrice } from 'regex/validation/Price';
import { Accessory, UnitWeights } from 'types/Accessory/Accessory';
import { TagLight } from 'types/Tag/TagLight';
import { UnitLight } from 'types/Unit/UnitLight';

interface IProps {
	accessory: Accessory;
	categoryTree: CategoryTree[];
	cultureCode: string;
	handleChangeCategory: (tree: apiGetCategoryTree.Category[]) => void;
	handleCharacteristicChange: (checked: boolean, elementId: string) => void;
	setAccessory: (accessory: Accessory) => void;
	tags: TagLight[];
	units: UnitLight[];
}

const AccessorySidebar = (props: IProps) => {
	const { t } = useTranslation();
	const accessoryEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const handleAttributeChange = (key: keyof Accessory, value: string) => {
		const accessoryCpy = { ...props.accessory };
		accessoryCpy[key] = value;
		props.setAccessory(accessoryCpy);
	};

	const handleAddUnitWeight = (items: UnitWeights[]) => {
		const accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.unitWeights = [];
		for (const uw of items) {
			accessoryCpy.unitWeights.push(uw);
		}
		props.setAccessory(accessoryCpy);
	};

	const getLabelPrice = (): string => {
		return `${reduxCurrency} ${t('_general:PER')} 100g`;
	};

	const changeAccessoryPrice = (_key: string, value: string): void => {
		const accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.price = Number.parseFloat(value);
		props.setAccessory(accessoryCpy);
	};

	const resetAccessoryAttribute = (_key: string): number => {
		const accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.price = null;
		props.setAccessory(accessoryCpy);

		const value: number | null = props.accessory.price;
		if (value !== null) {
			return value;
		}
		return 0;
	};

	const handleAddTag = (items: TagLight[]) => {
		var accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.tags = [];
		for (const i of items) {
			accessoryCpy.tags.push({
				id: i.id,
				iconSvgUrl: i.iconSvgUrl ?? null,
				display: i.display,
				name: i.name,
				tagCategory: i.tagCategory,
				source: null,
			});
		}
		props.setAccessory(accessoryCpy);
	};

	const getLabelCategories = (): string => {
		if (!accessoryEditor) {
			if (props.accessory.categories) {
				if (props.accessory.categories.length === 0) {
					return t('_general:NO_CATEGORIES');
				}
			}
			return '';
		}
		return t('_general:CATEGORIES');
	};

	return (
		<>
			<FoldableCard
				defaultOpen={true}
				title={t('standingData:WEIGHT')}
				hasLock={true}
				defaultLocked={false}
				content={
					<div className="card-body">
						<UnitWeightEditor
							units={props.units}
							setUnitWeights={handleAddUnitWeight}
							accessory={props.accessory}
							setAccessory={props.setAccessory}
						/>
					</div>
				}
				unlockable={accessoryEditor}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:CHARACTERISTICS')}
				content={
					<>
						<div className="card-body">
							<CharacteristicRadioSelect
								checked={props.accessory.characteristic}
								disabled={!accessoryEditor}
								handleCharacteristicChange={props.handleCharacteristicChange}
							/>
						</div>
					</>
				}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:DENSITY')}
				content={
					<>
						<div className="card-body">
							<LockableInput
								id="density"
								label={`${t('_general:DENSITY')} (${t(
									'measurments:GRAMMS_PER_QUBIC_CM'
								)})`}
								defaultValue={String(props.accessory.density ?? 0)}
								handleValueChange={handleAttributeChange}
								placeholder=""
								withLock={false}
								withTrashCan={false}
								validateRegex={regexValidationDensity}
								disabled={!accessoryEditor}
								errorKey="Density"
							/>
						</div>
					</>
				}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:CATEGORIES')}
				content={
					<>
						<div className="card-body">
							<TreeInput
								input={props.categoryTree}
								setSelected={props.handleChangeCategory}
								selected={getSelectedCategoriesTree(
									props.accessory,
									props.categoryTree
								)}
								errorKey={'Categories'}
								disabled={!accessoryEditor}
								label={getLabelCategories()}
								noObjectsFoundText={t('_general:NO_CATEGORIES')}
							/>
						</div>
					</>
				}
			/>
			<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:COSTS')}
					content={
						<>
							<div className="card-body">
								<LockableInput
									id="price"
									label={getLabelPrice()}
									placeholder=""
									withLock={false}
									withTrashCan={false}
									defaultLocked={false}
									defaultValue={
										props.accessory.price ? String(props.accessory.price) : ''
									}
									validateRegex={regexValidationPrice}
									handleValueChange={changeAccessoryPrice}
									handleLockClick={resetAccessoryAttribute}
									disabled={!accessoryEditor}
									errorKey="Price"
								/>
							</div>
						</>
					}
				/>
			</RenderIf>
			<FoldableCard
				defaultOpen={true}
				defaultLocked={false}
				hasLock={true}
				title={t('_general:TAGS')}
				content={
					<>
						<div className="card-body">
							<TagEditor
								allTags={props.tags}
								tags={props.accessory.tags}
								setTags={handleAddTag}
								cultureCode={props.cultureCode}
								description={
									props.accessory.nameSingularTranslations[props.cultureCode]
								}
								disabled={!accessoryEditor}
							/>
						</div>
					</>
				}
				unlockable={accessoryEditor}
			/>
		</>
	);
};

export default AccessorySidebar;
