import React from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/desktop/_general/Tooltip/Tooltip';
import { IngredientType } from 'enums/ingredientType';
import { regexNonTechnicalAction, regexTechnicalAction } from 'regex/parsing/InstructionAction';
import { Segment } from 'types/Recipe/Segment';

const delayOption = { show: 650, hide: 400 };

export const HighlightingSegment = (props: { segment: Segment }) => {
	const { t } = useTranslation();

	const getActionHighlightedText = (text: string): JSX.Element[] => {
		const technicalActionClass = 'action technical';
		const nonTechnicalActionClass = 'action';
		const output: JSX.Element[] = [];

		function highlightText(
			text: string,
			highlight: RegExp,
			className: string,
			tooltipText: string,
			notMatchedTextFunc?: (text: string) => void
		) {
			if (text === undefined || highlight === undefined) {
				return;
			}

			const parts: string[] = text.split(highlight);

			parts.map((part: string, i: number) =>
				i % 2 === 1
					? output.push(
							<Tooltip delay={delayOption} content={tooltipText}>
								<span className={className}>{part}</span>
							</Tooltip>
					  )
					: notMatchedTextFunc
					? notMatchedTextFunc(part)
					: output.push(<>{part}</>)
			);
		}

		highlightText(
			text,
			regexTechnicalAction,
			technicalActionClass,
			t('recipe:TECHNICAL_ACTION'),
			(text) => {
				highlightText(
					text,
					regexNonTechnicalAction,
					nonTechnicalActionClass,
					t('recipe:ACTION')
				);
			}
		);

		return output;
	};

	const getInstructionTooltipText = (type: IngredientType, isListed: boolean): string => {
		return t('recipe:' + (isListed ? 'USED' : 'UNUSED') + '_' + type.toUpperCase());
	};

	const renderInstruction = (segment: Segment): JSX.Element => {
		const input: string = segment.instruction;
		if (input) {
			const highlights = segment.highlightings;
			const output: JSX.Element[] = [];
			let currentIndex: number = 0;

			for (const highlight of highlights) {
				const { startIndex, endIndex } = highlight.position;

				output.push(...getActionHighlightedText(input.substring(currentIndex, startIndex)));
				output.push(
					<Tooltip
						delay={delayOption}
						content={getInstructionTooltipText(highlight.type, highlight.isListed)}
					>
						<span
							className={
								highlight.type +
								' ' +
								(highlight.isListed ? 'is-listed' : 'is-unlisted')
							}
						>
							{input.substring(startIndex, endIndex)}
						</span>
					</Tooltip>
				);
				currentIndex = endIndex;
			}

			output.push(...getActionHighlightedText(input.substring(currentIndex, input.length)));

			return (
				<>
					{output.map((element: JSX.Element, i: number) => {
						return <React.Fragment key={i}>{element}</React.Fragment>;
					})}
				</>
			);
		}
		return <></>;
	};

	return (
		<div style={{ height: '100%', paddingLeft: '8px', width: '100%' }}>
			<div className="recipe-rendering-instruction-container">
				{renderInstruction(props.segment)}
			</div>
		</div>
	);
};
