import * as apiPatch from 'api/validatorResults/PatchValidatorResultsAcknowledgeEntityTypeEntityIdAcknowledgeV1';
import * as Checked from 'types/Validation/Checked';
import * as ValidationEntityType from 'types/Validation/ValidationEntityType';
import * as ValidationError from 'types/Validation/ValidationError';

export type Type = {
	entityId: string;
	entityType: ValidationEntityType.Type;
	name: string | null;
	lastValidationDateUtc: string;
	validationErrors: ValidationError.Type[];
};

export function create(): Type {
	return {
		entityId: '',
		entityType: ValidationEntityType.create(),
		name: null,
		lastValidationDateUtc: '',
		validationErrors: [],
	};
}

export async function patchToApi(obj: Type, value: boolean): Promise<void> {
	await apiPatch.callApi(obj.entityType, obj.entityId, value);
}

export function getAmountOfAcknowledged(obj: Type, checked: Checked.Type): number {
	let output: number = 0;
	for (const i of obj.validationErrors) {
		for (const z of i.errors) {
			if (checked[z.id] !== undefined) {
				if (!checked[z.id]) continue;
				output = output + 1;
				continue;
			}
			if (z.isAcknowledged) output = output + 1;
		}
	}
	return output;
}

export function getAmountOfUnacknowledged(obj: Type, checked: Checked.Type): number {
	return getNumberOfAllErrors(obj) - getAmountOfAcknowledged(obj, checked);
}

function getNumberOfAllErrors(obj: Type): number {
	let output: number = 0;
	for (const i of obj.validationErrors) {
		output = output + i.errors.length;
	}
	return output;
}

export function getAllErrorIds(obj: Type): string[] {
	const output: string[] = [];
	for (const i of obj.validationErrors) {
		for (const z of i.errors) {
			output.push(z.id);
		}
	}
	return output;
}
