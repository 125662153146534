import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(
	nutrientId: string,
	pointType: NutriScoreCategoryIngredientCategory
): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/NutriScoreIngredientCategoryPoints/${nutrientId}`;
	return axios
		.get(url, {
			params: {
				PointType: pointType,
			},
		})
		.toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	ingredientCategoryId: string;
	pointType: NutriScoreCategoryIngredientCategory;
	ingredientCategoryName: string | null;
	isPositive: boolean;
	points: Point[] | null;
};

export type Point = {
	points: number;
	minValue: number | null;
};
