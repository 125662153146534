import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IUserListRow } from 'components/desktop/_general/SearchResultList/IUserListRow';
import 'components/desktop/_general/SearchResultList/SearchResultListRow.scss';

const UserSearchResultListRow = (props: IUserListRow) => {
	const { t } = useTranslation();

	const getTitle = (): string => {
		if (props.title) {
			return props.title;
		}

		return t('_general:UNKNOWN_TITLE');
	};

	const getPicture = (): JSX.Element => {
		if (!props.imageUrl) {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		} else {
			return (
				<div className="card-picture">
					<img alt="thumbnail" src={props.imageUrl} />
				</div>
			);
		}
	};

	const getCultureCode = (): string => {
		if (props.cultureCode) {
			return props.cultureCode;
		}

		return t('_general:NO_CULTURE_CODE');
	};

	const getIsActiveIcon = (isActive: boolean): JSX.Element => {
		if (isActive) {
			return <FontAwesomeIcon icon={faCheck as IconProp} />;
		} else {
			return <FontAwesomeIcon icon={faXmark as IconProp} />;
		}
	};

	const getEmail = (): string => {
		return props.email;
	};

	const getRoles = (): string => {
		if (props.roles.length === 0) {
			return t('user:USER_HAS_NO_ROLES');
		}

		return props.roles.join(', ');
	};

	return (
		<Link to={props.href} className="unstyled-link">
			<div className="recipe-table-item">
				{getPicture()}

				<div id="column-first">
					<div id="title">
						<strong>{getTitle()}</strong>
					</div>
					<div id="info">
						<p>
							<span id="icon">{getIsActiveIcon(props.isActive)}</span>
							{t('admin:IS_ACTIVE')}
						</p>
					</div>
				</div>

				<div id="column-second" className="justify-content-start">
					<div>
						<strong>{t('_general:EMAIL')}:</strong>
						<p>{getEmail()}</p>
						<p>
							<strong>{t('_general:CULTURE_CODE')}:</strong>
							<br />
							{getCultureCode()}
						</p>
					</div>
					<div>
						<strong>{t('admin:ROLES')}:</strong>
						<p>{getRoles()}</p>
					</div>
				</div>
				<div id="column-last" className="justify-content-end">
					<div>{props.buttons}</div>
				</div>
			</div>
		</Link>
	);
};

export default UserSearchResultListRow;
