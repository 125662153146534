import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string | undefined,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseNutrient[]>> {
	const url: string = '/backend-service/Nutrient/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.nutrients);
}

interface Parameter {
	SearchTerm?: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	nutrients: ResponseNutrient[];
};

export type ResponseNutrient = {
	id: string;
	description: string;
};
