import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

export function useSelectedLanguage(initialValue: Language): Language {
	const [data, setData] = useState(initialValue);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const selectedCultureCode = reduxLanguages
		.filter((x) => x.cultureCode === reduxCultureCode)
		.firstOrDefault();

	useEffect(() => {
		const updateFunction = () => {
			if (selectedCultureCode) {
				setData(selectedCultureCode);
			}
		};
		updateFunction();
	}, [reduxLanguages, reduxCultureCode]);

	return data;
}
