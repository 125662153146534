import { useTranslation } from 'react-i18next';

import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import { fireAlert } from 'functions/fireAlert';
import isEmailValid from 'functions/validation/isEmailValid';

interface IProps {
	email: string;
	setEmail: (email: string) => void;
	handleSendLink: () => void;
}

const ForgotPasswordForm = (props: IProps) => {
	const { t } = useTranslation();

	const validateEmail = (): boolean => {
		if (!isEmailValid(props.email)) {
			fireAlert(
				2,
				t('alerts:ALERT_VALIDATION_ERROR'),
				`<b>${t('_general:EMAIL')}</b>${t('alerts:ALERT_EMAIL_ERROR')}`,
				20000
			);

			return false;
		}

		return true;
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.setEmail(e.currentTarget.value);
	};

	const handleSendLink = () => {
		if (validateEmail()) {
			props.handleSendLink();
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			handleSendLink();
		}
	};

	const renderContent = () => {
		return (
			<div className="password-recovery-form">
				<h2>{t('user:RECOVER_PASSWORD')}</h2>
				<ValidatedInput
					id="email"
					type="email"
					label={t('_general:EMAIL')}
					value={props.email}
					isValid={isEmailValid}
					onKeyDown={onKeyDown}
					handleValueChange={handleValueChange}
					className="mb-2"
				/>
				<div className="d-grid gap-2">
					<button className="btn btn-primary mb-2" onClick={handleSendLink}>
						{t('user:SEND_LINK')}
					</button>
				</div>
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default ForgotPasswordForm;
