export default function isNumberValid(input: string): boolean {
	if (input.replace(/\s+/g, ' ') === ' ') {
		return false;
	}
	if (input === '') {
		return false;
	}
	if (isNaN(Number(input))) {
		return false;
	}
	return true;
}
