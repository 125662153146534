export enum RecipeSortOrder {
	CaloriesAsc = 'CaloriesAsc',
	CaloriesDesc = 'CaloriesDesc',
	CarbonDioxideAsc = 'CarbonDioxideAsc',
	CarbonDioxideDesc = 'CarbonDioxideDesc',
	LastEditedDateAsc = 'LastEditedDateAsc',
	LastEditedDateDesc = 'LastEditedDateDesc',
	PriceAsc = 'PriceAsc',
	PriceDesc = 'PriceDesc',
	RecipeFilterOrder = 'RecipeFilterOrder',
	Relevance = 'Relevance',
	TitleAsc = 'TitleAsc',
	TitleDesc = 'TitleDesc',
}
