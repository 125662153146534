import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useState } from 'react';

import * as apiPostSuggestedRecipeTag from 'api/aiFunctions/PostAiFunctionsSuggestRecipeTagV1';
import { Tag } from 'api/aiFunctions/PostAiFunctionsSuggestRecipeTagV1';
import * as apiPostSuggestedTag from 'api/aiFunctions/PostAiFunctionsSuggestSimilarTagsV1';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import ENTITLEMENTS from 'enums/entitlements';
import { Optional } from 'functions/promiseExtensions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';
import { TagLight } from 'types/Tag/TagLight';
import { TagMappingDto } from 'types/Tag/TagMappingDto';

interface IProps {
	allTags: TagLight[];
	cultureCode?: string;
	description?: string;
	disabled?: boolean;
	recipeId?: string | null;
	recipeText?: string | null;
	setTags: (tags: TagLight[]) => void;
	tags: TagMappingDto[];
}

const TagEditor = (props: IProps) => {
	const [filteredTags, setFilteredTags] = useState<TagLight[]>([]);
	const [isLoadingAi, setIsLoadingAi] = useState(false);

	const handleAddTag = (savedItem: ISelectItem) => {
		var tagsCpy = [...props.tags];
		var tag = savedItem as TagLight;
		var isFound = tagsCpy.some((element) => element.id == tag.id);

		if (!isFound) {
			tagsCpy.push({
				id: tag.id,
				name: tag.name,
				iconSvgUrl: tag.iconSvgUrl,
				display: tag.display,
				tagCategory: tag.tagCategory,
				source: null,
			});
		}

		props.setTags(tagsCpy);
	};

	const createHandleDeleteTag = (id: string): (() => void) | undefined => {
		if (props.disabled) return undefined;
		return () => {
			const tagsCpy: TagLight[] = props.tags.filter((tag: TagLight) => {
				if (tag.id !== id) {
					return tag;
				}
			});
			props.setTags(tagsCpy);
		};
	};

	const getTagTitle = (tag: TagLight): string => {
		if (tag.tagCategory) {
			return `${tag.name} (${tag.tagCategory.name})`;
		} else {
			return tag.name;
		}
	};

	const filterTagsBySearchTerm = (searchTerm: string): void => {
		const tagIds: string[] = props.tags.map((e: TagLight) => e.id);
		const tags: TagLight[] = props.allTags
			.filter((tag) => tag.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
			.filter((tag) => !tagIds.includes(tag.id))
			.map((tag) => {
				return { ...tag, name: getTagTitle(tag) };
			});

		setFilteredTags(tags);
	};

	const getSuggestedTags = async (): Promise<Optional<Tag[]>> => {
		if (props.recipeText) {
			return await apiPostSuggestedRecipeTag.callApi({
				nrOfRecommendations: 8,
				recipeId: props.recipeId ?? '',
				recipeText: props.recipeText ?? null,
			});
		} else if (props.description && props.cultureCode) {
			return await apiPostSuggestedTag.callApi({
				cultureCode: props.cultureCode,
				description: props.description,
			});
		}
		return Optional.Just([]);
	};

	const handleGetSuggestedTagsClick = async (): Promise<void> => {
		setIsLoadingAi(true);

		const suggestedTags = await getSuggestedTags();

		suggestedTags.do((tags) => {
			const tagsCpy = [...props.tags];

			tags.forEach((t) => {
				if (tagsCpy.find((x) => x.id === t.id) == undefined) {
					tagsCpy.push({
						id: t.id,
						iconSvgUrl: t.iconSvgUrl ?? null,
						display: t.display,
						name: t.name,
						tagCategory: t.tagCategory,
						source: null,
					});
				}
			});
			props.setTags(tagsCpy);
		});
		setIsLoadingAi(false);
	};

	const renderAiButton = (): JSX.Element | null => {
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.SIMILAR_TAGS])) {
			return (
				<span
					className="input-group-text select-button btn-primary"
					id="basic-addon1"
					onClick={handleGetSuggestedTagsClick}
				>
					<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
				</span>
			);
		}
		return null;
	};

	return (
		<SpinnerWhenLoading isLoading={isLoadingAi}>
			<>
				<RenderIf condition={!props.disabled}>
					<div className="form-group">
						<label>{t('_general:TAGS')}</label>
						<div className="input-group">
							<SuggestionSearchInput
								className="form-control"
								handleChangeSearchTerm={filterTagsBySearchTerm}
								setSavedItem={handleAddTag}
								triggerSearchLetterAmount={0}
								suggestedItems={filteredTags}
								debounceMilliSeconds={500}
								errorKey="Tags"
							/>
							{renderAiButton()}
						</div>
					</div>
				</RenderIf>
				<div className="overflow-auto">
					{props.tags.map((tag: TagMappingDto, i: number) => (
						<ListItem
							key={i}
							text={tag.name}
							title={tag.source}
							iconSvgUrl={tag.iconSvgUrl}
							handleDelete={createHandleDeleteTag(tag.id)}
						/>
					))}
				</div>
				{props.disabled && props.tags.length === 0 && (
					<>
						<span className="grey">{t('_general:NO_TAGS')}</span>
					</>
				)}
			</>
		</SpinnerWhenLoading>
	);
};

export default TagEditor;
