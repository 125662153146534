import { PricePerAccessory } from 'api/recipe/PostRecipeCalculateV1';
import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import CarbonDioxideValuePerIngredient from 'classes/Recipe/Detail/Calculation/CarbonDioxideValuePerIngredient';
import NutrientValuesPerIngredient from 'classes/Recipe/Detail/Calculation/NutrientValuesPerIngredient';
import PricePerIngredient from 'classes/Recipe/Detail/Calculation/PricePerIngredient';
import { RowData } from 'components/desktop/_general/AirTable/AirTableTypes';
import { getNumberString } from 'functions/numberToString';
import { assoc } from 'functions/objectExtensions';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { Segment } from 'types/Recipe/Segment';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';

/**
 * Rationale: The Analysis result of recipe text contains an array of segments, and each segment contains an array of ingredients.
 * Flattening the ingredients loses the information about the segment and the position of the ingredient within the segment. With this
 * type definition, we'd like to keep track of the 'lost' information. Also, when there are multiple ingredients withint the same segment,
 * we'd like to enumerate them starting at 0. This is what we call the relative position. If a segment has only distinct ingredients,
 * then all relative positions will be 0. If there are duplicates, then they will get enumerated as 0, 1, 2, etc...
 */
export type SegmentIngredientType = SegmentIngredient & {
	index: number;
	segment: number;
	ingredientIndex: number;
	relativePosition: number;
};

export type Translation = (s: string) => string;

/**
 * Various informations about an ingredient is spread out through various sources that come from the analysis step, calculation step,
 * and the recipe itself. We'd like to join them together and create a combined view of all these sources. That's what this type stands for.
 */
export type JoinedIngredientType = {
	ingredient: SegmentIngredientType;
	nutrientValue: NutrientValuesPerIngredient;
	price: number | null;
	co2Value: CarbonDioxideValuePerIngredient;
	incorporationShare: IncorporationShareType | null;
	ingredientIndex: number;
	segmentIndex: number;
	index: number;
};

export type FilterableColumnInfo = { columnName: string; key: string };

type NutrientsIndex = { [key: number]: NutrientValuesPerIngredient };

/**
 * Helper function for our joins: given an array of nutrients, create a dictionary, using the ingredient index as key
 */
const createNutrientsIndex = (
	nutrientValuesPerIngredient: NutrientValuesPerIngredient[]
): NutrientsIndex => {
	return nutrientValuesPerIngredient.toDictionary(
		(x) => x.ingredientIndex,
		(x) => x
	);
};

type PriceIndex = { [key: number]: PricePerIngredient };
type AccessoryIndex = { [key: number]: PricePerAccessory };

/**
 * Helper function for our joins: given an array of prices, create a dictionary, using the ingredient index as key
 */
const createPriceIndex = (pricesPerIngredient: PricePerIngredient[]): PriceIndex => {
	return pricesPerIngredient.toDictionary(
		(x) => x.ingredientIndex,
		(x) => x
	);
};

const createAccessoryIndex = (pricesPerAccessory: PricePerAccessory[]): AccessoryIndex => {
	return pricesPerAccessory.toDictionary(
		(x) => x.accessoryIndex,
		(x) => x
	);
};

type Co2Index = { [key: number]: CarbonDioxideValuePerIngredient };

/**
 * Helper function for our joins: given an array of co2 values, create a dictionary, using the ingredient index as key
 */
const createCo2Index = (pricesPerIngredient: CarbonDioxideValuePerIngredient[]): Co2Index => {
	return pricesPerIngredient.toDictionary(
		(x) => x.ingredientIndex,
		(x) => x
	);
};

/**
 * Given an array of segments, return a flatted array of all ingredients withing, and
 * mark the additional relative positions.
 *
 * @param segments
 * @returns
 */
export const flattenSegments = (segments: Segment[]): SegmentIngredientType[] => {
	return segments
		.flatMap((x, index) =>
			x.ingredients.map((g, i) => ({ ...g, segment: index, ingredientIndex: i }))
		)
		.map((x, i) => ({ ...x, index: i }))
		.relativePositions(
			(g) => `${g.segment}`,
			(e) => `${e.ingredientId}`,
			(e, p) => ({ ...e, relativePosition: p })
		);
};

/**
 * Rationale: Given an incorporation-share and an array of ingredients, try to find the best match of
 * the share in the array. If a match is found, the corresponding ingredient is returned, as well as
 * the remaining ingredients (such that we don't look for the same match again). If no match is found, then
 * the ingredient will be null, and the array will be returned unaltered.
 *
 * @param incorporationShare a share (from the recipe model) to look for in the ingredients array
 * @param ingredients the (remaining) array from the ingredients that come from the analysis step. It might contain the share.
 * @returns
 */
const findMatchingIngredientHeuristically = (
	incorporationShare: IncorporationShareType,
	ingredients: SegmentIngredientType[]
): { ingredient: SegmentIngredientType | null; remainingIngredients: SegmentIngredientType[] } => {
	const [ingredient, remainingIngredients] = ingredients
		.remove(
			(x) =>
				x.ingredientId === incorporationShare.ingredientId &&
				x.unitId === incorporationShare.unitId &&
				x.quantity === incorporationShare.quantity &&
				x.relativePosition === incorporationShare.relativePosition
		)
		.orElseRemove(
			(x) =>
				x.ingredientId === incorporationShare.ingredientId &&
				x.unitId === incorporationShare.unitId &&
				x.quantity === incorporationShare.quantity
		)
		.orElseRemove(
			(x) =>
				x.ingredientId === incorporationShare.ingredientId &&
				x.unitId === incorporationShare.unitId
		)
		.orElseRemove((x) => x.ingredientId === incorporationShare.ingredientId)
		.get();

	return { ingredient, remainingIngredients };
};

/**
 * Create a default incorporation share for an ingredient
 */
const createDefaultIncorporationShare = (
	ingredient: SegmentIngredient & { segment: number; ingredientIndex: number }
): IncorporationShareType => {
	if (ingredient.type === 'Accessory') {
		return {
			incorporationShare: null,
			ingredientId: ingredient.ingredientId,
			ingredientIndex: ingredient.ingredientIndex,
			quantity: null,
			segmentIndex: ingredient.segment,
			unitId: ingredient.unitId,
			relativePosition: 0,
		};
	}
	return {
		incorporationShare: ingredient.ingredientId ? 1 : null,
		ingredientId: ingredient.ingredientId,
		ingredientIndex: ingredient.ingredientIndex,
		quantity: ingredient.quantity,
		segmentIndex: ingredient.segment,
		unitId: ingredient.unitId,
		relativePosition: 0,
	};
};

/**
 * Return a merge function based on the nutrients, prices, co2 values found in the given
 * calculation, which will enrich an ingredient with this auxillary data. The join is
 * based on the ingredient.ingredientIndex.
 *
 * @param calculation
 * @returns
 */
const createMerger = (calculation: Calculation | null) => {
	let nutrientsIndex = {} as NutrientsIndex;
	let pricesIndex = {} as PriceIndex;
	let accessoryIndex = {} as AccessoryIndex;
	let co2Index = {} as Co2Index;

	if (calculation) {
		nutrientsIndex = createNutrientsIndex(calculation.nutrientValuesPerIngredient.items);
		pricesIndex = createPriceIndex(calculation.pricePerIngredient.items);
		co2Index = createCo2Index(calculation.carbonDioxideValuePerIngredient.items);
		accessoryIndex = createAccessoryIndex(calculation.pricePerAccessory.items);
	}

	return (ingredient: SegmentIngredientType, share: IncorporationShareType) => {
		return {
			ingredient: ingredient,
			nutrientValue: nutrientsIndex[ingredient.index],
			price:
				ingredient.type === 'Accessory'
					? accessoryIndex[ingredient.index]?.price
					: pricesIndex[ingredient.index]?.price,
			co2Value: co2Index[ingredient.index],
			incorporationShare: share,
			index: ingredient.index,
			segmentIndex: ingredient.segment,
			ingredientIndex: ingredient.ingredientIndex,
		};
	};
};

/**
 * Merge the ingredients from the calculation result, from the analyis result and from the recipe.
 *
 * @param calculation Calculation result from the /calculation endpoint
 * @param incorporationShares The incorporation shares (normally from the recipe model)
 * @param flatIngredients The ingredients from the analysis step.
 * @returns a merged array of JoinedIngredientType.
 */
export const enrichIngredients = (
	calculation: Calculation | null,
	incorporationShares: IncorporationShareType[],
	flatIngredients: SegmentIngredientType[]
): JoinedIngredientType[] => {
	const merger = createMerger(calculation);

	type IngredientFindType = {
		remainingIngredients: SegmentIngredientType[];
		mappedIngredients: JoinedIngredientType[];
	};

	const initial: IngredientFindType = {
		remainingIngredients: flatIngredients,
		mappedIngredients: [],
	};

	const { mappedIngredients, remainingIngredients } = incorporationShares.reduce((acc, share) => {
		const { ingredient, remainingIngredients } = findMatchingIngredientHeuristically(
			share,
			acc.remainingIngredients
		);
		if (ingredient) {
			const el = merger(ingredient, share);
			return { remainingIngredients, mappedIngredients: [...acc.mappedIngredients, el] };
		}
		return { remainingIngredients, mappedIngredients: acc.mappedIngredients };
	}, initial);

	const remainingMappedIngredients = remainingIngredients.map((el) =>
		merger(el, createDefaultIncorporationShare(el))
	);

	return mappedIngredients.concat(remainingMappedIngredients).orderBy((x) => x.index);
};

export const calculateSegmentLengths = (segments: Segment[]) => {
	return segments.map((x) => x.ingredients.length).reductions<number>((x, y) => x! + y, 0);
};

const roundUpTo2Digits = (n: number | undefined | null, cultureCode: string) => {
	if (n) return getNumberString(n, cultureCode);

	return '-';
};

const formatQuantity = (
	ingredient: SegmentIngredientType | null,
	cultureCode: string,
	quantityMethod: string
): string => {
	if (!ingredient) return '–';

	if (quantityMethod === 'Min' && ingredient.quantity) {
		return getNumberString(ingredient.quantity, cultureCode);
	}

	if (quantityMethod === 'Max' && ingredient.maxQuantity) {
		return getNumberString(ingredient.maxQuantity, cultureCode);
	}

	if (quantityMethod === 'Mean' && ingredient.quantity && ingredient.maxQuantity) {
		return getNumberString((ingredient.quantity + ingredient.maxQuantity) / 2, cultureCode);
	}

	if (ingredient.quantity) {
		return getNumberString(ingredient.quantity, cultureCode);
	}

	if (!ingredient.quantityText) {
		return '–';
	}

	if (isNaN(parseFloat(ingredient.quantityText))) {
		return ingredient.quantityText;
	}

	return getNumberString(parseFloat(ingredient.quantityText), cultureCode);
};

const createBasicIngredientTableData = (
	ingredient: JoinedIngredientType,
	cultureCode: string,
	quantityMethod: string
): RowData => {
	return {
		segmentIndex: ingredient.segmentIndex,
		incorporationShare: ingredient.incorporationShare?.incorporationShare
			? Math.round(ingredient.incorporationShare?.incorporationShare * 100)
			: null,
		ingredientAmount: formatQuantity(ingredient.ingredient, cultureCode, quantityMethod),
		ingredientUnit: ingredient.ingredient?.unit || '-',
		ingredientName: ingredient.ingredient?.ingredient,
		ingredient: ingredient.ingredient,
		additionAfter: ingredient.ingredient.additionAfter,
		additionBefore: ingredient.ingredient.additionBefore,
		price: roundUpTo2Digits(ingredient.price, cultureCode),
		calories: ingredient.nutrientValue?.nutrientValues.firstOrDefault()?.total || '-',
		co2Value: roundUpTo2Digits(ingredient.co2Value?.value, cultureCode),
	};
};

export const mapToTableData = (
	ingredient: JoinedIngredientType,
	ingredientNutrientsTableColumnKeys: FilterableColumnInfo[],
	cultureCode: string,
	quantityMethod: string
): RowData => {
	const basicTableData = createBasicIngredientTableData(ingredient, cultureCode, quantityMethod);

	return ingredientNutrientsTableColumnKeys.reduce(
		(acc, el) =>
			assoc(
				acc,
				el.key,
				roundUpTo2Digits(
					ingredient.nutrientValue?.nutrientValues
						.filter((x) => x.nutrientId === el.key)
						.firstOrDefault()?.total,
					cultureCode
				)
			),
		basicTableData
	);
};
