/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

export type ReducerFunctionAsync<T, P> = (type: T, property?: P) => Promise<T>;
export type ReducerFunction<T, P> = (type: T, property: P) => T;
export type ReducerFunctionNoArg<T> = (type: T) => T;

export class ReducerFactory<T> {
	private setState: Dispatch<SetStateAction<T>>;

	constructor(setState: Dispatch<SetStateAction<T>>) {
		this.setState = setState;
	}

	createReducer<P>(reducer: ReducerFunction<T, P>): (k: P) => void {
		const setState = this.setState;
		return function (k: P) {
			{
				setState((t: T) => {
					return reducer({ ...t }, k);
				});
			}
		};
	}

	createNoArgReducer(reducer: ReducerFunctionNoArg<T>): () => void {
		const setState = this.setState;
		return function () {
			{
				setState((t: T) => {
					return reducer({ ...t });
				});
			}
		};
	}

	createReducerAsync<P>(reducerAsync: ReducerFunctionAsync<T, P>): (k?: P) => Promise<void> {
		const setState = this.setState;
		const dispatchLater = async (resolve: any, t: T, k?: P) => {
			const newData = await reducerAsync(t, k);
			setState(newData);
			resolve(newData);
		};

		return (k?: P): Promise<void> => {
			return new Promise((resolve) => {
				setState((t: T) => {
					dispatchLater(resolve, t, k);
					return t;
				});
			});
		};
	}
}

export function useHandler<T>(initialState: T): [T, ReducerFactory<T>] {
	const [state, setState] = useState<T>(initialState);

	return [state, new ReducerFactory(setState)];
}
