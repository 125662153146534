import { NutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';

export function mapStateToAdvancedSearch(state: any): NutriScoreNutrientsAdvancedSearch {
	return {
		pageIndex: state.pageIndex,
		pageSize: state.pageSize,
		searchTerm: state.searchTerm,
		id: state.id,
	};
}
