import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/season/GetSeasonIdV1';
import Season from 'classes/StandingData/Season/Season';
import SeasonLanguageInput from 'components/desktop/StandingData/Season/LanguageInput/SeasonLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import isNumberValid from 'functions/validation/isNumberValid';
import { RootState } from 'reducers/rootReducer';

const SeasonDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [season, setSeason] = useState<Season>(new Season());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getSeason(id);
		}
	};

	const getSeason = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.do((x) => setSeason(new Season(x)));
		} finally {
			setIsLoading(false);
		}
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveSeason = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await season.callApiPut();
			} else {
				await season.callApiPost();
				if (!season.id) return;
				setId(season.id);
				setIdToUrl(season.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleInputNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		season.nameTranslations[selectedCultureCode] = e.target.value;
		setSeason(clone(season));
	};

	const handleTranslate = async () => {
		season.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSortOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.currentTarget.value;
		if (isNumberValid(value)) {
			season.sortOrder = Number(value);
			setSeason(clone(season));
		}
	};

	const handleToggle = (input: boolean): void => {
		season.display = input;
		setSeason(clone(season));
	};

	const setSeasonalityCode = (input: number): void => {
		season.associatedMonthsFlag = input;
		setSeason(clone(season));
	};

	const setPictureUpload = async (input: FormData | null): Promise<void> => {
		if (input) {
			await season.callApiUploadMedia(input);
			setSeason(clone(season));
		}
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div className="row">
				<div className="d-flex flex-row justify-content-between mb-3">
					<div>
						{season.id ? (
							<>
								<h1>{t('standingData:EDIT_SEASON')}</h1>
							</>
						) : (
							<>
								<h1>{t('standingData:NEW_SEASON')}</h1>
							</>
						)}
					</div>
					<div>
						<BtnSave handleSave={() => handleSaveSeason()} isLoading={isLoadingSave} />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
					<SvgBig url={season.iconSvg?.url ?? null} width={120} height={120} />
					<PictureUpload setFormData={setPictureUpload} accept="image/svg+xml" />
				</div>
				<div className="col-md-8 col-xl-9 col-sm-8 col-12">
					<div
						className="d-flex flex-row justify-content-between mb-4"
						style={{ gap: '2em' }}
					>
						<div>
							<label>{t('standingData:SORT_ORDER')}</label>
							<input
								className={'form-control'}
								id="sortOrder"
								value={String(season.sortOrder)}
								type="number"
								onChange={handleSortOrderChange}
								error-key={'SortOrder'}
							/>
						</div>
						<SeasonalityBar
							seasonalityCode={season.associatedMonthsFlag}
							setSeasonalityCode={setSeasonalityCode}
							showIfSeasonalityCodeIsZero={true}
							withErrorKey={true}
						/>
						<Toggle
							checked={season.display ?? false}
							handleToggle={handleToggle}
							elementId="display"
							label="standingData:DISPLAY"
							errorKey="Display"
						/>
					</div>
					<LanguageSwitch
						handleSelectLanguage={handleSelectLanguage}
						selectedLanguage={selectedCultureCode}
						translatable={true}
						onTranslateClick={handleTranslate}
						entryLanguage={reduxCultureCode}
						content={
							<SeasonLanguageInput
								season={season}
								selectedLanguage={selectedCultureCode}
								handleValueChange={handleInputNameChange}
							/>
						}
					/>
				</div>
			</div>
		</>
	);
};

export default SeasonDetailComponent;
