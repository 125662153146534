import { Dispatch, SetStateAction } from 'react';

import * as apiPostScaling from 'api/aiFunctions/PostAiFunctionsScalingCalculateRecipeV1';
import * as apiPostAiAccessories from 'api/aiFunctions/PostAiFunctionsSuggestAccessoryProductsV1';
import * as apiSetChannelOnMedia from 'api/channel/SetChannelOnMediaV1';
import * as apiGetIngredientStartingWith from 'api/ingredient/GetIngredientStartingWithV1';
import * as apiPostMedia from 'api/media/PostMediaV1';
import * as apiGetRecipeIdV1 from 'api/recipe/GetRecipeIdV1';
import * as apiPostRecipeAnalyseV1 from 'api/recipe/PostRecipeAnalyseV1';
import * as apiPostRecipeCalculateV1 from 'api/recipe/PostRecipeCalculateV1';
import * as apiNutriScore from 'api/recipe/PostRecipeNutriScoreV1';
import * as apiPost from 'api/recipe/PostRecipeV1';
import * as apiPut from 'api/recipe/PutRecipeV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { IngredientType } from 'enums/ingredientType';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { createHandler, createHandlerAsync } from 'functions/handlers';
import { mapToSaveRecipeDto } from 'functions/mappers/Recipe/mapToSaveRecipeDto';
import { assoc } from 'functions/objectExtensions';
import { Optional } from 'functions/promiseExtensions';
import { roundUpTo } from 'functions/roundUpTo';
import { uploadMedia } from 'functions/uploadMedia';
import { ActivityTime } from 'types/ActivityTime/ActivityTime';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { Language } from 'types/Language/Language';
import { ChannelMedia } from 'types/Media/ChannelMedia';
import { EntryLanguage } from 'types/Recipe/EntryLanguage';
import { IncorporationShare, Recipe, mapToRecipe } from 'types/Recipe/Recipe';
import { Segment, mapApiSegmentToSegment } from 'types/Recipe/Segment';
import {
	SegmentIngredient,
	mapAccessoryToAPIAccessory,
	mapIngredientToAPIIngredient,
} from 'types/Recipe/SegmentIngredient';
import { RecipeCategoryLight } from 'types/RecipeCategory/RecipeCategoryLight';
import { StatusLight } from 'types/Status/StatusLight';
import { TagLight } from 'types/Tag/TagLight';
import { mapTagLightsToTagMappingDtos } from 'types/Tag/TagMappingDto';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';
import { NutriScore } from 'types/_general/NutriScore';

export const fetchRecipe = async (id: string): Promise<Recipe | null> => {
	const response = await apiGetRecipeIdV1.callApi(id);

	return response.map(mapToRecipe).toNullable();
};

export const getAiAccessories = async (
	recipeId: string | null,
	nrOfRecommendations: number,
	recipeText: string
) => {
	const accessorySuggestion = await apiPostAiAccessories.callApi(
		recipeId,
		nrOfRecommendations,
		recipeText
	);

	return accessorySuggestion.getOrDefault([]);
};

export const parseSegments = async (
	recipeId: string | null,
	ingredientsTranslations: LocalizedTranslation<string>,
	stepsTranslations: LocalizedTranslation<string>,
	language: string
) => {
	if (ingredientsTranslations[language] || stepsTranslations[language]) {
		const segments = await apiPostRecipeAnalyseV1.callApi({
			recipeId: recipeId,
			ingredients: ingredientsTranslations[language],
			instructions: stepsTranslations[language],
			cultureCode: language,
		});
		return segments.map((x) => x.segments.map(mapApiSegmentToSegment)).getOrDefault([]);
	} else {
		return [];
	}
};

export const parseScaledSegments = async (
	scaledPersons: number | null,
	persons: number | null,
	language: string,
	segments: Segment[]
) => {
	if (scaledPersons && persons && segments.length > 0) {
		const response = await apiPostScaling.callApi({
			originalQuantity: persons,
			desiredQuantity: scaledPersons,
			cultureCode: language,
			segments: segments,
		});
		return response.map((x) => x.segments.map(mapApiSegmentToSegment)).getOrDefault([]);
	} else {
		return [];
	}
};

export const potentiallyParseAdditionalLanguage = async (
	segments: Segment[],
	recipeId: string | null,
	ingredientsTranslations: LocalizedTranslation<string>,
	stepsTranslations: LocalizedTranslation<string>,
	entryLanguage: string,
	language: string
) => {
	if (entryLanguage === language) return segments;
	if (segments.length === 0) return segments;

	return await parseSegments(
		recipeId,
		assoc(ingredientsTranslations, 'test', 'bla'),
		stepsTranslations,
		entryLanguage
	);
};

export const calculate = async (
	segments: Segment[],
	incorporationShares: (IncorporationShare & { index: number })[]
): Promise<Calculation | null> => {
	if (!segments) return null;

	const ingredients = segments
		.flatMap((x) => x.ingredients)
		.map((x, i) => ({ index: i, segmentIngredient: x }))
		.filter(
			(x) =>
				x.segmentIngredient.type === IngredientType.Ingredient &&
				x.segmentIngredient.ingredientId
		);

	const accessories = segments
		.flatMap((x) => x.ingredients)
		.map((x, i) => ({ index: i, segmentIngredient: x }))
		.filter(
			(x) =>
				x.segmentIngredient.type === IngredientType.Accessory &&
				x.segmentIngredient.ingredientId
		);

	if (ingredients.isEmpty() && accessories.isEmpty()) return null;

	const result = await apiPostRecipeCalculateV1.callApi({
		accessories: accessories.map(mapAccessoryToAPIAccessory),
		ingredients: ingredients.map((x) => mapIngredientToAPIIngredient(x, incorporationShares)),
	});

	return result.map((x) => new Calculation(x)).toNullable();
};

export const getNutriScore = async (segments: Segment[]): Promise<NutriScore | null> => {
	if (!segments) return null;

	const ingredients: SegmentIngredient[] = segments
		.flatMap((x) => x.ingredients)
		.filter((x) => x.type === IngredientType.Ingredient && x.ingredientId);

	if (ingredients && ingredients.length > 0) {
		const response = await apiNutriScore.callApi({
			ingredients: ingredients.map((x) => ({
				quantity: x.quantity,
				maxQuantity: x.maxQuantity,
				unitId: x.unitId,
				ingredientId: x.ingredientId,
			})),
		});

		return response.map((x) => ({ label: x.label, color: x.color })).toNullable();
	}

	return null;
};

export const createSeoActiveHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) =>
	createHandler(setRecipe, (r: Recipe, flag: boolean) => ({ ...r, isSeoActive: flag }));

export const createTagsHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) =>
	createHandler(setRecipe, (r: Recipe, tags: TagLight[]) => ({
		...r,
		tags: mapTagLightsToTagMappingDtos(tags),
	}));

export const createRecipeCategoriesHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) =>
	createHandler(setRecipe, (r: Recipe, categories: RecipeCategoryLight[]) => ({
		...r,
		categories: categories,
	}));

export const createDeleteSegmentMediaHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handler = createHandler(
		setRecipe,
		(recipeCpy: Recipe, data: { index: number; id: string; video: boolean }) => {
			const segmentIndex = String(data.index);
			const mediaArray = recipeCpy.segmentMedia[segmentIndex];

			const updatedVideos = data.video
				? mediaArray.videos.filter((x) => x.id !== data.id)
				: mediaArray.videos;

			const updatedImages = !data.video
				? mediaArray.images.filter((x) => x.id !== data.id)
				: mediaArray.images;

			recipeCpy.segmentMedia[segmentIndex] = {
				...mediaArray,
				videos: updatedVideos,
				images: updatedImages,
			};

			return { ...recipeCpy };
		}
	);
	return (index: number, id: string, video: boolean) => handler({ index, id, video });
};

export const createSetSegmentFormDataHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handleUploadSegmentMedia = createUploadSegmentMediaHandler(setRecipe);
	return (index: number, files: File[], video: boolean) => {
		if (video) {
			handleUploadSegmentMedia(index, [], files);
		} else {
			handleUploadSegmentMedia(index, files, []);
		}
	};
};

export const getRecipeAttribute = (t: Recipe, key: keyof Recipe): any => {
	return t[key];
};

export const createRecipeAttributeHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	function assocRecipe(recipe: Recipe, key: keyof Recipe, value: any) {
		const result: any = { ...recipe };
		if (key === 'persons' || key === 'parts') {
			if (!result.scaledParts && !result.scaledPersons) {
				result.scaledParts = value;
				result.scaledPersons = value;
			}
		}
		result[key] = value;
		return result;
	}

	const handler = createHandler(
		setRecipe,
		(
			recipe: Recipe,
			data: {
				key: keyof Recipe;
				value:
					| StatusLight
					| number
					| string
					| ActivityTime[]
					| NutriScoreCategory
					| boolean
					| null;
			}
		) => {
			const newRecipe: Recipe = assocRecipe(recipe, data.key, data.value);
			if (data.key === 'scaledParts')
				return assocRecipe(
					newRecipe,
					'scaledPersons',
					convertScalePartsToScalePersons(newRecipe, Number(data.value))
				);
			if (data.key === 'scaledPersons')
				return assocRecipe(
					newRecipe,
					'scaledParts',
					convertScalePersonsToScaleParts(newRecipe, Number(data.value))
				);
			return newRecipe;
		}
	);

	return (
		key: keyof Recipe,
		value: StatusLight | number | string | ActivityTime[] | NutriScoreCategory | boolean | null
	) => handler({ key: key, value: value });
};

const convertScalePartsToScalePersons = (recipe: Recipe, scaledPartsInput: number) => {
	if (recipe.parts && recipe.persons)
		return roundUpTo((scaledPartsInput * recipe.persons) / recipe.parts, 2);
	return null;
};

const convertScalePersonsToScaleParts = (recipe: Recipe, scaledPersonInput: number) => {
	if (recipe.parts && recipe.persons)
		return roundUpTo((scaledPersonInput * recipe.parts) / recipe.persons, 2);
	return null;
};

export const createUpdateChannelsHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handler = createHandler(
		setRecipe,
		(recipeCpy: Recipe, data: { mediaId: string; channels: ChannelLight[] }) => {
			return {
				...recipeCpy,
				image:
					data.mediaId === recipeCpy.image?.id
						? { ...recipeCpy.image, channels: data.channels }
						: recipeCpy.image,
				videos: recipeCpy.videos.map((x) =>
					x.id === data.mediaId ? { ...x, channels: data.channels } : x
				),
				images: recipeCpy.images.map((x) =>
					x.id === data.mediaId ? { ...x, channels: data.channels } : x
				),
			};
		}
	);

	return (mediaId: string, channels: ChannelLight[]) => handler({ mediaId, channels });
};

export const createChangeScaledPartsHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handler = createHandler(
		setRecipe,
		(recipeCpy: Recipe, data: { scaledPartsInput: number | null }) => {
			return {
				...recipeCpy,
				scaledParts: data.scaledPartsInput,
				scaledPersons: data.scaledPartsInput,
			};
		}
	);
	return (scaledPartsInput: number | null) => handler({ scaledPartsInput });
};

export const createUpdateCaloriesHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (recipe: Recipe, value: number | null) => {
		return {
			...recipe,
			calories: value,
		};
	});
};

export const createSelectEntryLanguageHandler = (
	setRecipe: Dispatch<SetStateAction<Recipe>>,
	availableLanguages: Language[]
) => {
	const handler = createHandler(setRecipe, (recipeCpy: Recipe, language: EntryLanguage) => {
		return { ...recipeCpy, entryLanguage: language };
	});

	return (e: any) => {
		const language = availableLanguages
			.filter((x) => x.cultureCode == e.currentTarget.value)
			.firstOrDefault();
		language && handler({ description: language.name, id: language.id });
	};
};

const fetchTranslation = async (
	text: string,
	entryLanguageCultureCode: string,
	languageSwitcherCultureCode: string
) => {
	const translationDto = {
		fromCultureCode: entryLanguageCultureCode,
		toCultureCode: languageSwitcherCultureCode,
		text: text,
	} as PostTranslationDto;

	return await apiPostTranslation.callApi(translationDto);
};

const translationKeys = [
	'titleTranslations',
	'shortDescriptionTranslations',
	'notesTranslations',
	'ingredientsTranslations',
	'stepsTranslations',
	'seoTranslations',
];

export const createTranslationHandler = (
	setRecipe: Dispatch<SetStateAction<Recipe>>,
	entryLanguageCultureCode: string,
	language: string
) => {
	return createHandlerAsync(setRecipe, async (recipe: Recipe, name?: string) => {
		var translations = await translationKeys
			.filter((x) => (name ? x === name : true))
			.map((key) => [
				key,
				getRecipeAttribute(recipe, key as keyof Recipe)[entryLanguageCultureCode],
			])
			.filter(([_key, text]) => text && text.length > 0)
			.mapAsync(async ([key, text]) => [
				key,
				await fetchTranslation(text, entryLanguageCultureCode, language),
			])
			.awaitAll();

		return translations.reduce((recipe, [key, translationOptional]) => {
			const recipeTextAtrribute = getRecipeAttribute(recipe, key as keyof Recipe);
			const t = translationOptional as Optional<string>;
			const translation = t.getOrDefault('');
			const translated = assoc(recipeTextAtrribute, language, translation);
			return assoc(recipe, key as keyof Recipe, translated);
		}, recipe);
	});
};

export const createInputValueChangeHandler = (
	setRecipe: Dispatch<SetStateAction<Recipe>>,
	selectedCultureCode: string
) => {
	const handler = createHandler(
		setRecipe,
		(recipe: Recipe, p: { key: keyof Recipe; value: string }) => {
			const translation: LocalizedTranslation<string> = getRecipeAttribute(
				recipe,
				p.key as keyof Recipe
			);
			const inputField = assoc(translation, selectedCultureCode as any, p.value);

			return assoc<Recipe>(recipe, p.key, inputField);
		}
	);

	return (key: keyof Recipe, value: string) => {
		handler({ key, value });
	};
};

export const suggestIngredients = async (searchTerm: string | null, cultureCode: string) => {
	if (searchTerm) {
		const response = await apiGetIngredientStartingWith.callApi(searchTerm, false, cultureCode);
		return response.map((x) => x.map((e) => e.description)).getOrDefault([]);
	}
	return [];
};
export const createDeleteVideoHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (recipe: Recipe, id: string) => ({
		...recipe,
		videos: recipe.videos.filter((x) => x.id !== id),
	}));
};

const createUploadVideoHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handler = createHandlerAsync(
		setRecipe,
		async (recipe: Recipe, media?: { input: File[]; inputVideo: File[] }) => {
			if (!media) return recipe;

			let recipeResult = recipe;

			for (const imageIdentifier in media.input) {
				if (Object.prototype.hasOwnProperty.call(media.input, imageIdentifier)) {
					const imageFile: File = media.input[imageIdentifier];
					const imageFormData = new FormData();
					imageFormData.append('Media.File', imageFile);
					recipeResult = await uploadMedia<Recipe>(
						recipeResult,
						imageFormData,
						MEDIACOLLECTION.Recipe
					);
				}
			}

			for (const videoIdentifier in media.inputVideo) {
				if (Object.prototype.hasOwnProperty.call(media.inputVideo, videoIdentifier)) {
					const videoFile = media.inputVideo[videoIdentifier];
					const videoFormData = new FormData();
					videoFormData.append('Media.File', videoFile);
					recipeResult = await uploadMedia<Recipe>(
						recipeResult,
						videoFormData,
						MEDIACOLLECTION.RecipeVideo
					);
				}
			}

			return recipeResult;
		}
	);

	return (input: File[], inputVideo: File[]) => handler({ input, inputVideo });
};

const createUploadSegmentMediaHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handler = createHandlerAsync(
		setRecipe,
		async (recipe: Recipe, media?: { index: number; images: File[]; videos: File[] }) => {
			if (!media) return recipe;

			const recipeResult = { ...recipe };
			const mediaIndex = String(media.index);

			for (const imageIdentifier in media.images) {
				if (Object.prototype.hasOwnProperty.call(media.images, imageIdentifier)) {
					const imageFile: File = media.images[imageIdentifier];
					const imageFormData = new FormData();
					imageFormData.append('Media.File', imageFile);
					const image = await apiPostMedia.callApi({
						formData: imageFormData,
						mediaCollection: MEDIACOLLECTION.RecipeSegment,
					});
					if (image.hasValue()) {
						if (recipeResult.segmentMedia[mediaIndex]) {
							recipeResult.segmentMedia[mediaIndex].images.push(image.get());
						} else {
							recipeResult.segmentMedia[mediaIndex] = {
								images: [image.get()],
								videos: [],
							};
						}
					}
				}
			}

			for (const videoIdentifier in media.videos) {
				if (Object.prototype.hasOwnProperty.call(media.videos, videoIdentifier)) {
					const videoFile: File = media.videos[videoIdentifier];
					const videoFormData = new FormData();
					videoFormData.append('Media.File', videoFile);
					const video = await apiPostMedia.callApi({
						formData: videoFormData,
						mediaCollection: MEDIACOLLECTION.RecipeSegmentVideo,
					});
					if (video.hasValue()) {
						if (recipeResult.segmentMedia[mediaIndex]) {
							recipeResult.segmentMedia[mediaIndex].videos.push(video.get());
						} else {
							recipeResult.segmentMedia[mediaIndex] = {
								images: [],
								videos: [video.get()],
							};
						}
					}
				}
			}

			return recipeResult;
		}
	);
	return (index: number, images: File[], videos: File[]) => handler({ index, images, videos });
};

export const createSetImageHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (recipe: Recipe, input: ChannelMedia) => {
		const images: ChannelMedia[] = recipe.images;
		let imagesArray: ChannelMedia[] = [];
		if (recipe.image) {
			imagesArray = [...[recipe.image], ...images].filter((e: ChannelMedia) => {
				return e.id !== input.id;
			});
		} else {
			imagesArray = [
				...images.filter((e: ChannelMedia) => {
					return e.id !== input.id;
				}),
			];
		}
		return {
			...recipe,
			image: input,
			images: imagesArray,
		} as Recipe;
	});
};

export const createDeleteImageHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (recipe: Recipe, id: string) => {
		const remainingImages = recipe.images.filter((e) => e.id !== id);
		return {
			...recipe,
			images: remainingImages,
			image: recipe.image?.id === id ? null : recipe.image,
		} as Recipe;
	});
};

export const createSetFormDataHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	const handleUploadMedia = createUploadVideoHandler(setRecipe);
	return (input: File[], video: boolean) => {
		if (video) {
			handleUploadMedia([], input);
		} else {
			handleUploadMedia(input, []);
		}
	};
};

export const createSelectLanguageHandler = (
	setLanguageSwitcherCultureCode: Dispatch<SetStateAction<string>>
) => {
	const handler = createHandler(setLanguageSwitcherCultureCode, (_s: string, n: string) => n);

	return (_e: any, selectedLanguage: string) => {
		handler(selectedLanguage);
	};
};

export const createIncorporationHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (s: Recipe, shares: IncorporationShare[]) => {
		return { ...s, incorporationShares: [...shares] };
	});
};

async function updateMediaChannels(recipe: Recipe, mediaMarkedForPatch: string[]): Promise<void> {
	const imagesMarkedForUpdate = recipe.images.filter((x) => mediaMarkedForPatch.includes(x.id));

	if (recipe.image && mediaMarkedForPatch.includes(recipe.image.id)) {
		imagesMarkedForUpdate.push(recipe.image);
	}

	for (const media of imagesMarkedForUpdate) {
		const setChannelOnMediaRequest: apiSetChannelOnMedia.Request = {
			mediaId: media.id,
			channelIds: media.channels.map((x) => x.id),
		};
		await apiSetChannelOnMedia.callApi(setChannelOnMediaRequest);
	}

	const videosMarkedForUpdate = recipe.videos.filter((x) => mediaMarkedForPatch.includes(x.id));

	for (const media of videosMarkedForUpdate) {
		const setChannelOnMediaRequest: apiSetChannelOnMedia.Request = {
			mediaId: media.id,
			channelIds: media.channels.map((x) => x.id),
		};
		await apiSetChannelOnMedia.callApi(setChannelOnMediaRequest);
	}
}

export async function handleSave(
	recipe: Recipe,
	mediaMarkedForPatch: string[]
): Promise<Optional<Recipe>> {
	await updateMediaChannels(recipe, mediaMarkedForPatch);

	const response = await apiPut.callApi(recipe.id!, mapToSaveRecipeDto(recipe));
	return response.map(mapToRecipe);
}

export async function handleCreateRecipe(
	recipe: Recipe,
	mediaMarkedForPatch: string[]
): Promise<Optional<string>> {
	await updateMediaChannels(recipe, mediaMarkedForPatch);
	return await apiPost.callApi(mapToSaveRecipeDto(recipe));
}

export const createLockScalingHandler = (setRecipe: Dispatch<SetStateAction<Recipe>>) => {
	return createHandler(setRecipe, (recipe: Recipe, isLocked: boolean): Recipe => {
		if (isLocked) {
			return { ...recipe, scaledParts: null, scaledPersons: null };
		}
		return { ...recipe, scaledParts: recipe.parts, scaledPersons: recipe.persons };
	});
};

export function createIngredientReformatHandler(setRecipe: Dispatch<SetStateAction<Recipe>>) {
	return createHandler(
		setRecipe,
		(recipe: Recipe, input: { segments: Segment[]; cultureCode: string }) => {
			const text: string = getIngredientText(input.segments);
			const ingredientTranslations = {
				...recipe.ingredientsTranslations,
				[input.cultureCode]: text,
			};
			return { ...recipe, ingredientsTranslations: ingredientTranslations } as Recipe;
		}
	);
}

function getIngredientText(input: Segment[]): string {
	const output: string[] = [];
	for (const i of input) {
		const segmentText: string = getSegmentText(i);
		output.push(segmentText);
	}
	return output.join('\n\n');
}

function getSegmentText(input: Segment): string {
	const output: string[] = [];
	for (const ingredient of input.ingredients) {
		const text: string = [
			ingredient.quantity,
			ingredient.unit,
			ingredient.additionBefore,
			ingredient.ingredient,
			ingredient.additionAfter,
		].join(' ');
		output.push(fixText(text));
	}
	return output.join('\n');
}

function fixText(input: string): string {
	return input
		.replaceAll(' ,', ',')
		.replaceAll(' :', ':')
		.replaceAll(' ;', ';')
		.replaceAll(' .', '.')
		.replaceAll(`' `, `'`)
		.replaceAll('’ ', '’')
		.trim()
		.split(/[\s\t\n]+/)
		.join(' ');
}

export function ingredientsNeedReformating(
	recipe: Recipe,
	segments: Segment[],
	cultureCode: string
): boolean {
	return !(recipe.ingredientsTranslations[cultureCode] === getIngredientText(segments));
}
