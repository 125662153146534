import IngredientCategoryManagementComponent from 'components/desktop/StandingData/IngredientCategory/Management/IngredientCategoryManagementComponent';

const IngredientCategoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<IngredientCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default IngredientCategoryArchiveContainer;
