import * as apiGetIngredientIdV1 from 'api/ingredient/GetIngredientIdV1';
import { Ingredient } from 'types/Ingredient/Ingredient';

export function mapToIngredient(input: apiGetIngredientIdV1.ResponseData): Ingredient {
	return {
		allergens: input.allergens,
		baseIngredient: input.baseIngredient,
		calories: input.calories,
		carbonDioxide: input.carbonDioxide,
		carbonDioxideLabel: input.carbonDioxideLabel,
		carbonDioxideValue: input.carbonDioxideValue,
		categories: input.categories,
		characteristic: input.characteristic,
		density: input.density,
		hasNutriScore: input.hasNutriScore,
		id: input.id,
		image: input.image,
		isAllergenOverridden: input.isAllergenOverridden,
		isCarbonDioxideOverridden: input.isCarbonDioxideOverridden,
		isScalingFactorOverridden: input.isScalingFactorOverridden,
		isSeasonOverridden: input.isSeasonOverridden,
		isTagOverridden: input.isTagOverridden,
		isUnitWeightOverridden: input.isUnitWeightOverridden,
		namePluralTranslations: input.namePluralTranslations,
		nameSingularTranslations: input.nameSingularTranslations,
		nutriScore: input.nutriScore,
		nutriScoreCategory: input.nutriScoreCategory,
		nutriScoreCategoryIsSetByUser: input.nutriScoreCategoryIsSetByUser,
		nutrientValues: input.nutrientValues,
		price: input.price,
		products: input.products,
		scalingFactor: input.scalingFactor,
		seasons: input.seasons,
		source: input.source,
		synonyms: input.synonyms,
		tags: input.tags,
		unitWeights: input.unitWeights,
	};
}
