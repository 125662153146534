enum PERMISSIONS {
	ADMINISTRATE = 'Administrate',
	READ = 'Read',
	READACCESSORY = 'ReadAccessory',
	READBASEINGREDIENT = 'ReadBaseIngredient',
	READINGREDIENT = 'ReadIngredient',
	READMENU = 'ReadMenu',
	READMENUPLAN = 'ReadMenuPlan',
	READMODIFICATIONSANALYTICS = 'ReadModificationsAnalytics',
	READPRODUCT = 'ReadProduct',
	READRECIPE = 'ReadRecipe',
	READRECIPESTATUSANALYTICS = 'ReadRecipeStatusAnalytics',
	READSTATISTICSANALYTICS = 'ReadStatisticsAnalytics',
	READUNIT = 'ReadUnit',
	WRITE = 'Write',
	WRITEACCESSORY = 'WriteAccessory',
	WRITEBASEINGREDIENT = 'WriteBaseIngredient',
	WRITEINGREDIENT = 'WriteIngredient',
	WRITEMENU = 'WriteMenu',
	WRITEMENUPLAN = 'WriteMenuPlan',
	WRITERECIPE = 'WriteRecipe',
	WRITEUNIT = 'WriteUnit',
}

export default PERMISSIONS;
