import * as apiSW from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';

export default class Co2Label {
	public id: string = '';
	public color: string = '';
	public label: string = '';

	public constructor(input?: apiSW.ResponseCarbonDioxideLabel) {
		if (input) {
			this.id = input.id;
			this.color = input.color;
			this.label = input.label;
		}
	}
}
