import ExternalLogoContainer from 'containers/desktop/StandingData/ExternalLogo/ExternalLogo';
import PERMISSIONS from 'enums/permissions';

export const externalLogoRoutes = [
	{
		path: '/logoUpload',
		element: <ExternalLogoContainer />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
