import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseErrorMessage[]>> {
	const url: string = '/backend-service/ErrorMessage/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.errorMessages);
}

interface Parameter {
	SearchTerm: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	errorMessages: ResponseErrorMessage[];
};

export type ResponseErrorMessage = {
	id: string;
	errorKey: string;
	errorMessage: string;
};
