import { combineReducers } from 'redux';

import { allowedAutoCompleteLanguagesReducer } from 'features/AllowedAutoCompleteLanguagesFeature';
import { allowedEntryLanguagesReducer } from 'features/AllowedEntryLanguagesFeature';
import { allowedUiLanguagesReducer } from 'features/AllowedUiLanguagesFeature';
import { cultureCodeReducer } from 'features/CultureCodeFeature';
import { currencyReducer } from 'features/CurrencyFeature';
import { languagesReducer } from 'features/LanguagesFeature';
import { menuBarOrderReducer } from 'features/MenuBarOrderFeature';
import { quantityMethodReducer } from 'features/QuantityMethodFeature';
import { userReducer } from 'features/UserFeature';
import { userSettingsReducer } from 'features/UserSettingsFeature';

export const rootReducer = combineReducers({
	allowedAutoCompleteLanguages: allowedAutoCompleteLanguagesReducer,
	allowedEntryLanguages: allowedEntryLanguagesReducer,
	allowedUiLanguages: allowedUiLanguagesReducer,
	cultureCode: cultureCodeReducer,
	currency: currencyReducer,
	languages: languagesReducer,
	menuBarOrder: menuBarOrderReducer,
	quantityMethod: quantityMethodReducer,
	user: userReducer,
	userSettings: userSettingsReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
