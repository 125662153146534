import * as apiPost from 'api/translation/PostTranslationV1';

export type Type<T> = {
	[key: string]: T;
};

export function create() {
	return {};
}

export function change<T>(obj: Type<T>, key: string, value: T) {
	if (!value) {
		const output = {
			...obj,
		};
		delete output[key];
		return output;
	}
	return {
		...obj,
		[key]: value,
	};
}

export async function getTranslationFromApi(request: apiPost.Request): Promise<string> {
	const response = await apiPost.callApi(request);
	const responseData = response.toNullable();
	if (!responseData) return '';
	return responseData;
}
