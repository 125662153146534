import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/language/GetLanguageIdV1';
import Language from 'classes/StandingData/Language/Language';
import LanguageLanguageInput from 'components/desktop/StandingData/Language/LanguageInput/LanguageLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const AccessoryCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [language, setLanguage] = useState<Language>(new Language());

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getLanguage(id);
		}
	};

	const getLanguage = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setLanguage(new Language(x)));
		setIsLoading(false);
	};

	const handleTranslate = async () => {
		language.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		switch (name) {
			case 'isoCode6391':
				language.isoCode6391 = e.currentTarget.value;
				break;
			case 'isoCode6393':
				language.isoCode6393 = e.currentTarget.value;
				break;
			case 'descriptionTranslations':
				language.nameTranslations[selectedCultureCode] = e.currentTarget.value;
				break;
			case 'cultureCode':
				language.cultureCode = e.currentTarget.value;
				break;
			case 'translationCode':
				language.translationCode = e.currentTarget.value ? e.currentTarget.value : null;
				break;
		}
		setLanguage(clone(language));
	};

	const handleArticleToggle = (input: boolean) => {
		language.hasArticleBeforeIngredientName = input;
		setLanguage(clone(language));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveLanguage = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await language.callApiPut();
			} else {
				await language.callApiPost();
				if (!language.id) return;
				setId(language.id);
				setIdToUrl(language.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{language.id ? (
								<>
									<h1>{t('standingData:EDIT_LANGUAGE')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_LANGUAGE')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveLanguage()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<Toggle
							checked={language.hasArticleBeforeIngredientName ?? false}
							handleToggle={handleArticleToggle}
							elementId="hasArticleBeforeIngredientName"
							label="standingData:HASARTICLEBEFOREINGREDIENTNAME"
							errorKey="HasArticleBeforeIngredientName"
						/>
						<hr />
						<label className="form-control-label" htmlFor="cultureCode">
							{t('_general:CULTURE_CODE')}
						</label>
						<input
							value={language.cultureCode}
							type="text"
							onChange={handleValueChange}
							className="form-control"
							name="cultureCode"
							id="cultureCode"
							error-key={'CultureCode'}
						/>
						<label className="form-control-label" htmlFor="translationCode">
							{t('standingData:TRANSLATION_CODE')}
						</label>
						<input
							value={language.translationCode ?? undefined}
							type="text"
							onChange={handleValueChange}
							className="form-control"
							name="translationCode"
							id="translationCode"
							error-key={'TranslationCode'}
						/>
						<hr />
						<label className="form-control-label" htmlFor="isoCode6391">
							{t('standingData:ISOCODE6391')}
						</label>
						<input
							value={language.isoCode6391}
							type="text"
							onChange={handleValueChange}
							className="form-control"
							name="isoCode6391"
							id="isoCode6391"
							error-key={'IsoCode6391'}
						/>
						<label className="form-control-label" htmlFor="isoCode6393">
							{t('standingData:ISOCODE6393')}
						</label>
						<input
							value={language.isoCode6393}
							type="text"
							onChange={handleValueChange}
							className="form-control"
							name="isoCode6393"
							id="isoCode6393"
							error-key={'IsoCode6393'}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<LanguageLanguageInput
									language={language}
									handleValueChange={handleValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryCategoryDetailComponent;
