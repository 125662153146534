import { useTranslation } from 'react-i18next';

import RadioButton from 'components/desktop/Recipe/Validation/RadioButton';

interface IProps {
	status: boolean | null;
	onClick: (value: true | false | null) => void;
}

const RadioButtons = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<div className="d-flex" style={{ gap: '20px' }}>
			<RadioButton {...props} id={'all'} value={null} label={t('_general:ALL')} />
			<RadioButton {...props} id={'accepted'} value={false} label={t('_general:ACCEPTED')} />
			<RadioButton {...props} id={'denied'} value={true} label={t('_general:DENIED')} />
		</div>
	);
};

export default RadioButtons;
