import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/nutrient/GetNutrientIdV1';
import Nutrient from 'classes/StandingData/Nutrient/Nutrient';
import NutrientCategoryList from 'classes/StandingData/NutrientCategory/Search/NutrientCategoryList';
import UnitList from 'classes/StandingData/Unit/Search/UnitList';
import NutrientLanguageInput from 'components/desktop/StandingData/Nutrient/LanguageInput/NutrientLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

const NutrientDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [nutrient, setNutrient] = useState<Nutrient>(new Nutrient());
	const [unitList, setUnitList] = useState<UnitList>(new UnitList());
	const [nutrientCategoryList, setNutrientCategoryList] = useState<NutrientCategoryList>(
		new NutrientCategoryList()
	);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		getUnits();
		getNutrientCategories();
		setId(id);
		if (id !== null && id !== 'new') {
			getNutrient(id);
		}
	};

	const getUnits = async (): Promise<void> => {
		await unitList.search('', false);
		setUnitList(clone(unitList));
	};

	const getNutrientCategories = async (): Promise<void> => {
		await nutrientCategoryList.search('', false);
		setNutrientCategoryList(clone(nutrientCategoryList));
	};

	const getNutrient = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setNutrient(new Nutrient(x)));
		setIsLoading(false);
	};

	const handleTranslate = async () => {
		nutrient.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		switch (name) {
			case 'descriptionTranslations':
				nutrient.nameTranslations[selectedCultureCode] = e.target.value;
				break;
			case 'shortDescriptionTranslations':
				nutrient.shortNameTranslations[selectedCultureCode] = e.target.value;
				break;
		}
		setNutrient(clone(nutrient));
	};

	const handleToggleMacroNutrient = (input: boolean) => {
		nutrient.isMacroNutrient = input;
		setNutrient(clone(nutrient));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		if (nutrient.unit.id === '') {
			nutrient.unit.id = unitList.filtered[0].id;
			nutrient.unit.name = unitList.filtered[0].name;
		}
		if (nutrient.nutrientCategory.id === '') {
			nutrient.nutrientCategory.id = nutrientCategoryList.filtered[0].id;
			nutrient.nutrientCategory.name = nutrientCategoryList.filtered[0].name;
		}
		try {
			if (id !== null && id !== 'new') {
				await nutrient.callApiPut();
			} else {
				await nutrient.callApiPost();
				if (!nutrient.id) return;
				setId(nutrient.id);
				setIdToUrl(nutrient.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleUnitChange = (unitId: string): void => {
		const unit = unitList.filtered.find((x) => x.id == unitId);
		nutrient.unit.id = unitId;
		nutrient.unit.name = unit?.name ?? '';
		setNutrient(clone(nutrient));
	};

	const handleNutrientCategoryChange = (nutrientCategoryId: string): void => {
		const nutrientCategory = nutrientCategoryList.filtered.find(
			(x) => x.id == nutrientCategoryId
		);
		nutrient.nutrientCategory.id = nutrientCategoryId;
		nutrient.nutrientCategory.name = nutrientCategory?.name ?? '';
		setNutrient(clone(nutrient));
	};

	const getUnitOptions = (): SelectOption[] => {
		return unitList.filtered.map((unit: ISelectItem) => ({
			id: unit.id,
			text: unit.name,
		}));
	};

	const getNutrientCategoryOptions = (): SelectOption[] => {
		return nutrientCategoryList.filtered.map((nutrientCategory: ISelectItem) => ({
			id: nutrientCategory.id,
			text: nutrientCategory.name,
		}));
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{nutrient.id ? (
								<>
									<h1>{t('standingData:EDIT_NUTRIENT')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_NUTRIENT')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveNutrient()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<Toggle
							checked={nutrient.isMacroNutrient ?? false}
							handleToggle={handleToggleMacroNutrient}
							elementId="isMacroNutrient"
							label="standingData:IS_MACRONUTRIENT"
							className="mb-2"
							errorKey="IsMacroNutrient"
						/>
						<hr />
						<label>{t('standingData:UNIT')}:</label>
						<SelectSimple
							options={getUnitOptions()}
							currentValue={nutrient.unit.id}
							handleValueChange={handleUnitChange}
							errorKey="Unit"
						/>
						<label>{t('standingData:NUTRIENT_CATEGORY')}:</label>
						<SelectSimple
							options={getNutrientCategoryOptions()}
							currentValue={nutrient.nutrientCategory.id}
							handleValueChange={handleNutrientCategoryChange}
							errorKey="NutrientCategory"
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<NutrientLanguageInput
									nutrient={nutrient}
									handleValueChange={handleValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientDetailComponent;
