import SeasonManagementComponent from 'components/desktop/StandingData/Season/Management/SeasonManagementComponent';

const SeasonArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<SeasonManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default SeasonArchiveContainer;
