import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import * as Product from 'types/Product/Search/Product';

interface IProps {
	handleAddToIngredient: (id: string) => Promise<void>;
	product: Product.Type;
}

// TODO: After the ERP PR is merged on main, reuse this component again.
const ImageWithFallback = (props: { className?: string; src: string; onClick?: () => void }) => {
	const [hasError, setError] = useState(false);
	const onError = () => {
		setError(true);
	};
	if (hasError) {
		return (
			<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
				<FontAwesomeIcon size="3x" icon={faCamera} color="white" />
			</div>
		);
	}

	return (
		<div className="card-picture">
			<img
				alt="thumbnail"
				onError={onError}
				className={props.className}
				src={props.src}
				onClick={props.onClick}
			></img>
		</div>
	);
};

const SearchResultListRow = (props: IProps) => {
	const getButtonDataAdd = (): BtnData | undefined => {
		return {
			onClick: () => props.handleAddToIngredient(props.product.id),
		};
	};

	const getButtons = (): JSX.Element => {
		return <BtnGroup add={getButtonDataAdd()} />;
	};

	const getPicture = (): JSX.Element => {
		if (props.product.imageUrl) {
			return <ImageWithFallback src={props.product.imageUrl} className="card-picture" />;
		} else {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		}
	};

	return (
		<div className="product-table-item">
			{getPicture()}
			<div id="column-first">
				<div id="title">
					<strong>{props.product.nameTranslation}</strong>
				</div>
			</div>
			<div id="column-last">
				<div>{getButtons()}</div>
			</div>
		</div>
	);
};

export default SearchResultListRow;
