import * as apiPostSearch from 'api/baseIngredient/PostBaseIngredientSearchV1';
import Allergen from 'classes/BaseIngredient/Search/Allergen';
import { Icon } from 'types/Icon/Icon';

export default class AllergenList {
	public all: Allergen[] = [];

	public constructor(input?: apiPostSearch.Allergen[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): Icon[] {
		const icons: Icon[] = this.all
			.map((e: Allergen) => {
				return e.mapToIcon();
			})
			.filter((e: Icon | null) => {
				if (e !== null) {
					return e;
				}
			}) as Icon[];
		return icons;
	}

	public mapFromApi(input: apiPostSearch.Allergen[] | null) {
		if (input) {
			for (const i of input) {
				const allergen: Allergen = new Allergen(i);
				this.all.push(allergen);
			}
		}
	}
}
