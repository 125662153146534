export const incorporationShareKey = 'incorporationShare';
export const ingredientAmountKey = 'ingredientAmount';
export const ingredientUnitKey = 'ingredientUnit';
export const ingredientNameKey = 'ingredientName';
export const priceKey = 'price';
export const co2ValueKey = 'co2Value';

export const nutrientColumnClassName = 'nutrient-column';

export const NutrientFilterSearchComponentWrapper = (props: { children: JSX.Element }) => (
	<div className="price-nutrient-search-component">{props.children}</div>
);
