import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ImageSearch from 'components/desktop/_general/Search/ImageSearch';
import ENTITLEMENTS from 'enums/entitlements';
import useDebounce from 'hooks/useDebounce';
import { ISelectItem } from 'interfaces/ISelectItem';

interface IProps {
	debounceMilliSeconds?: number;
	disabled?: boolean;
	getSuggestedItems: () => Promise<ISelectItem[]>;
	handleImageSearch?: (input: FormData | null) => void;
	handleSearch: (searchTerm?: string) => void;
	searchTerm: string;
	setSearchTerm: (input: string) => void;
	triggerSearchLetterAmount?: number;
}

const AdvancedSearchInput = (props: IProps) => {
	const [showSuggested, setShowSuggested] = useState<boolean>(false);
	const [selectionIndex, setSelectionIndex] = useState<number>(-1);
	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	const currentListItem = useRef<HTMLDivElement | null>(null);
	const inputElement = useRef<HTMLDivElement | null>(null);
	const debouncedValue = useDebounce<string>(props.searchTerm, props.debounceMilliSeconds ?? 500);

	useEffect(() => {
		if (currentListItem.current)
			currentListItem.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
				inline: 'nearest',
			});
	}, [selectionIndex]);

	useEffect(() => {
		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (debouncedValue.length >= triggerSearchLetterAmount) {
			handleGetSuggestedItems();
		}
	}, [debouncedValue]);

	const handleClick = (): void => {
		props.handleSearch();
	};

	const handleGetSuggestedItems = async (): Promise<void> => {
		const items: ISelectItem[] = await props.getSuggestedItems();
		setSuggestedItems(items);
	};

	const handleSelectItem = (item: ISelectItem) => {
		props.handleSearch(item.name);

		inputElement.current?.blur();
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		props.setSearchTerm(value);
		setSelectionIndex(-1);

		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (value.length >= triggerSearchLetterAmount) {
			setShowSuggested(true);
		} else {
			setShowSuggested(false);
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		const selectedItem = suggestedItems[selectionIndex];
		switch (e.key) {
			case 'ArrowDown':
				if (selectionIndex < suggestedItems.length - 1) {
					setSelectionIndex(selectionIndex + 1);
				}
				e.preventDefault();
				break;
			case 'ArrowUp':
				if (selectionIndex > 0) {
					setSelectionIndex(selectionIndex - 1);
				}
				e.preventDefault();
				break;
			case 'Enter':
				if (selectedItem) {
					handleSelectItem(selectedItem);
				} else {
					onEnter();
					inputElement.current?.blur();
				}
				break;
			case 'Escape':
				inputElement.current?.blur();
				break;
		}
	};

	const onEnter = (): void => {
		props.handleSearch();
	};

	const onFocus = () => {
		if (!props.triggerSearchLetterAmount) {
			props.setSearchTerm('');
			handleGetSuggestedItems();
			setShowSuggested(true);
		}
	};

	const onBlur = () => {
		setShowSuggested(false);
		setSelectionIndex(-1);
	};

	return (
		<>
			<input
				className="form-control"
				disabled={props.disabled}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				placeholder={
					props.disabled
						? t('_general:INPUT_PLACEHOLDER_NO_MORE_ENTRIES')
						: t('_general:INPUT_PLACEHOLDER_SEARCHTERM')
				}
				type="text"
				value={props.searchTerm}
			/>
			{showSuggested && suggestedItems.length > 0 && (
				<div className="select-suggestions">
					{suggestedItems.map((item, index) => (
						<div
							className={index === selectionIndex ? 'active' : ''}
							ref={index === selectionIndex ? currentListItem : undefined}
							key={item.name + item.id}
							onMouseDown={() => handleSelectItem(item)}
						>
							{item.name}
						</div>
					))}
				</div>
			)}
			<RenderIf
				entitlements={[ENTITLEMENTS.AI_IMAGE_SEARCH]}
				condition={Boolean(props.handleImageSearch)}
			>
				<>
					{props.handleImageSearch && (
						<ImageSearch setFormData={props.handleImageSearch} />
					)}
				</>
			</RenderIf>
			<button
				className="btn btn-outline-primary advancedSearch btn-200"
				type="button"
				onClick={handleClick}
			>
				{t('_general:BTN_SEARCH')}
			</button>
		</>
	);
};

export default AdvancedSearchInput;
