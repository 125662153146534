import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/Recipe/GetNutriScore';
	return axios.post(url, request).toOptionalMapped((x) => x.data.label);
}

interface Request {
	ingredients: RequestIngredients[];
}

interface RequestIngredients {
	quantity: number | null;
	maxQuantity: number | null;
	unitId: string | null;
	ingredientId: string | null;
}

export interface Response {
	data: { label: ResponseData };
}

export type ResponseData = {
	label: string;
	color: string;
};
