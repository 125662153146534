import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import * as Checked from 'types/Validation/Checked';
import * as Validation from 'types/Validation/Validation';
import { Type } from 'types/Validation/Validation';

interface IProps {
	validation: Type;
	checked: Checked.Type;
	changeId: (input: string) => void;
}

const ListItem = (props: IProps) => {
	const renderDate = (): JSX.Element => {
		return (
			<div>
				<FormatedDate date={props.validation.lastValidationDateUtc} />
			</div>
		);
	};

	const renderBadges = (): JSX.Element => {
		const amountOfUnacknowledged: number = Validation.getAmountOfUnacknowledged(
			props.validation,
			props.checked
		);
		const amountOfAcknowledged: number = Validation.getAmountOfAcknowledged(
			props.validation,
			props.checked
		);
		return (
			<div style={{ minWidth: '110px', textAlign: 'right' }}>
				<RenderIf condition={Boolean(amountOfUnacknowledged)}>
					<span className="badge bg-danger">{amountOfUnacknowledged}</span>
				</RenderIf>
				<RenderIf condition={Boolean(amountOfAcknowledged)}>
					<span className="badge bg-success" style={{ marginLeft: '2px' }}>
						{amountOfAcknowledged}
					</span>
				</RenderIf>
			</div>
		);
	};

	return (
		<div
			style={{
				padding: '10px',
				border: '1px solid rgba(210, 210, 210, 1)',
				marginBottom: '-1px',
				display: 'flex',
				flexDirection: 'row',
				cursor: 'pointer',
			}}
			onClick={() => props.changeId(props.validation.entityId)}
		>
			<div className="flex-grow-1">
				<div className="d-flex flex-row" style={{ gap: '10px' }}>
					<div className="flex-grow-1">
						<strong>{props.validation.name}</strong>
					</div>
					<div className="d-flex flex-row">
						{renderDate()}
						{renderBadges()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListItem;
