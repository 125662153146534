import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(putObject: Request): Promise<Optional<void>> {
	const url: string = '/backend-service/UserProfile';
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	firstName: string;
	lastName: string;
	password: string | undefined;
	newPassword: string | undefined;
	languageId: string;
	profilePictureId: string | undefined;
	nutrients: string[];
	userSettings: UserSettings | undefined;
}

export type UserSettings = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope'?: ValueScope;
};

export enum ValueScope {
	Total = 'Total',
	Person = 'Person',
	Parts = 'Parts',
	HundredGrams = 'HundredGrams',
}
