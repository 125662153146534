import { useParams } from 'react-router-dom';

import MenuPlanComponent from 'components/desktop/MenuPlan/MenuPlanComponent';

const MenuPlanDetailContainer = () => {
	const { id } = useParams();

	const renderContent = () => {
		return (
			<main className="container">
				<MenuPlanComponent
					id={id}
					copy={location.pathname.includes('/copy') ? true : false}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuPlanDetailContainer;
