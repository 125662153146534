import * as apiSW from 'api/nutriScoreLabel/GetNutriScoreLabelStartingWithV1';
import * as apiPostSearch from 'api/nutriScoreLabel/PostNutriScoreLabelSearchV1';
import NutriScoreLabel from 'classes/StandingData/NutriScoreLabel/Search/NutriScoreLabel';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class NutriScoreLabelList {
	public all: NutriScoreLabel[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const nutriScoreLabels = await apiSW.callApi(searchTerm, showOnlyDeleted);
		nutriScoreLabels.hasValue() && this.mapFromApiSW(nutriScoreLabels.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const nutriScoreLabel: NutriScoreLabel = new NutriScoreLabel(i);
			this.all.push(nutriScoreLabel);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseNutriScoreLabel[]): void {
		this.filtered = [];
		for (const i of input) {
			const existingItem = this.filtered.find(
				(element: ISelectItem) => element.name === i.label
			);
			if (!existingItem) {
				this.filtered.push({
					id: i.id,
					name: i.label,
				});
			}
		}
	}
}
