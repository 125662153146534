import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import Activity from 'classes/StandingData/Activity/Search/Activity';
import ActivityList from 'classes/StandingData/Activity/Search/ActivityList';
import Search from 'classes/StandingData/Activity/Search/Search';
import ActivityDetailComponent from 'components/desktop/StandingData/Activity/Detail/ActivityDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons: number = 5;

const ActivityManagement = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [activityList, setActivityList] = useState<ActivityList>(new ActivityList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getActivities();
		} else {
			await getActivities();
		}
		setSearch(clone(search));
	};

	const getActivities = async (): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await activityList.callApi(search.mapToRequest());
			if (response.hasValue()) {
				activityList.mapFromApi(response.get());
				setActivityList(clone(activityList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getSuggestedActivities = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await activityList.search(searchTerm, props.archive ?? false);
		} else {
			activityList.filtered = [];
		}
		setActivityList(clone(activityList));
		return activityList.filtered;
	};

	const handleChangeUrl = (): void => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getActivities();
	};

	const handleSearch = (input?: string): void => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number): void => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null): void => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleRestoreActivity = async (activity: Activity): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:ACTIVITY_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await activity.callApiRestore();
				await Swal.fire({
					title: t('standingData:ACTIVITY_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getActivities();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveActivity = async (activity: Activity): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:ACTIVITY_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await activity.callApiDelete();
				await Swal.fire({
					title: t('standingData:ACTIVITY_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getActivities();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getButtonDataDelete = (input: Activity): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveActivity(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: Activity): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreActivity(input),
			};
		}
	};

	const renderButtons = (input: Activity): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return activityList.all.map((activity: Activity) => {
			return {
				id: activity.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="d-flex flex-row" style={{ gap: '0.8em' }}>
							<span className="grey">{t('standingData:SORT_ORDER')}: </span>
							{activity.sortOrder}
						</div>
						<div className="flex-fill">
							<span className="grey">{t('_general:DESCRIPTION')}: </span>
							{activity.name}
						</div>
						<div>{renderButtons(activity)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						{!props.archive ? (
							<>
								<h1>{t('standingData:ACTIVITY_MANAGEMENT')}</h1>
								<div className="d-flex flex-gap-25">
									<BtnNew
										onClick={handleChangeIdToNew}
										name={t('standingData:CREATE_ACTIVITY')}
									/>
								</div>
							</>
						) : (
							<h1>{t('standingData:ACTIVITY_ARCHIVE')}</h1>
						)}
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedActivities}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p react-key="numberOfActivities">
						{activityList.totalCount + ' ' + t('_general:ACTIVITIES')}
					</p>
					<StandingDataList
						archive={props.archive}
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<ActivityDetailComponent />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={activityList.totalCount}
						pageIndex={search.getPageIndex()}
						setPageIndex={handleChangePageIndex}
						listItemAmount={search.getPageSize()}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ActivityManagement;
