import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Allergen/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	negativeIconSvg: IconSvg | null;
	positiveIconSvg: IconSvg | null;
	mayContainIconSvg: IconSvg | null;
	display: boolean;
	negativeNameTranslations: {
		[key: string]: string;
	};
	positiveNameTranslations: {
		[key: string]: string;
	};
	mayContainNameTranslations: {
		[key: string]: string;
	};
};

export type IconSvg = {
	id: string;
	url: string;
	availableSizes: number[];
};
