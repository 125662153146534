import * as apiPostSearch from 'api/baseIngredient/PostBaseIngredientSearchV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';

export default class BaseIngredientCategory {
	id: string = '';
	name: string = '';
	parentName: string | null = '';
	nutriScoreCategory: NutriScoreCategory = NutriScoreCategory.StandardFood;

	public constructor(input?: apiPostSearch.Category) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.Category): void {
		this.id = input.id;
		this.name = input.name ?? '';
		this.parentName = input.parentName;
		this.nutriScoreCategory = input.nutriScoreCategory;
	}
}
