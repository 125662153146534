import React from 'react';
import { ReactNode } from 'react';

export {};

export class Optional<T> {
	readonly t: T[] = [];

	private constructor(instance?: T) {
		if (instance !== undefined) {
			this.t.push(instance);
		}
	}

	public hasValue() {
		return !this.t.isEmpty();
	}

	public static WrapToJust = <B>(e: B, f: () => Promise<void>) => {
		return async () => {
			await f();
			return Optional.Just(e);
		};
	};

	public static Maybe = <B>(b: B | null | undefined): Optional<B> => {
		if (b) return this.Just(b);
		return this.None();
	};

	public static Just = <B>(b: B): Optional<B> => {
		return new Optional(b);
	};

	public static None = <B>(): Optional<B> => {
		return new Optional();
	};

	public map<B>(f: (t: T) => B): Optional<B> {
		if (this.t.isEmpty()) return Optional.None();
		return Optional.Just(f(this.t[0]));
	}

	public keep<B>(f: (t: T) => B | null): Optional<B> {
		if (this.t.isEmpty()) return Optional.None();
		const result = f(this.t[0]);
		if (result) return Optional.Just(result);
		return Optional.None();
	}

	public toNode(f: (t: T) => ReactNode): ReactNode {
		if (this.t.isEmpty()) return React.createElement('div');
		return f(this.t[0]);
	}

	public flatMap<B>(f: (t: T) => B[]): B[] {
		if (this.t.isEmpty()) return [];
		return f(this.t[0]);
	}

	public async mapAsync<B>(f: (t: T) => Promise<Optional<B>>): Promise<Optional<B>> {
		if (this.t.isEmpty()) return Optional.None();
		return await f(this.t[0]);
	}

	public filter(f: (t: T) => boolean): Optional<T> {
		if (this.t.isEmpty()) return Optional.None();
		var filtered = this.t.filter(f);
		if (filtered.isEmpty()) return Optional.None();

		return this;
	}

	public do(f: (t: T) => void): Optional<T> {
		if (this.t.isEmpty()) return this;
		f(this.t[0]);
		return this;
	}

	public async doAsync(f: (t: T) => Promise<any>): Promise<Optional<T>> {
		if (this.t.isEmpty()) return this;
		await f(this.t[0]);
		return this;
	}

	public orElse(f: () => T): T {
		if (this.t.isEmpty()) return f();
		return this.t[0];
	}
	public async orElseAsync(f: () => Promise<Optional<T>>): Promise<Optional<T>> {
		if (this.t.isEmpty()) return await f();
		return this;
	}

	public orElseDo(f: () => void): Optional<T> {
		if (this.t.isEmpty()) {
			f();
			return this;
		}
		return this;
	}

	public get(): T {
		if (this.t.isEmpty()) {
			throw 'Attempt blocked to force a default value without providing it explicitly.';
		}
		return this.t[0];
	}
	public getOrDefault(defaultValue: T): T {
		if (this.t.isEmpty()) {
			return defaultValue;
		}
		return this.t[0];
	}

	public toNullable(): T | null {
		if (this.t.isEmpty()) {
			return null;
		}
		return this.t[0];
	}

	public toArray<B>(f: (t: T) => B) {
		return this.t.map(f);
	}
}

declare global {
	interface Promise<T> {
		toOptional(): Promise<Optional<T>>;
		toOptionalMapped<B>(f: (t: T) => B): Promise<Optional<B>>;
		toOptionalVoid(): Promise<Optional<void>>;
		mapAsync<B>(f: (t: T) => B): Promise<B>;
	}
}

Promise.prototype.toOptional = async function <T>(): Promise<Optional<T>> {
	try {
		const t: T = await this;
		return Optional.Just(t);
	} catch (e) {
		return Optional.None();
	}
};

Promise.prototype.toOptionalVoid = async function (): Promise<Optional<void>> {
	const response = await this.toOptional();
	return response.map((_) => {});
};

Promise.prototype.toOptionalMapped = async function <T, B>(f: (t: T) => B): Promise<Optional<B>> {
	const response = await this.toOptional();
	return response.map((t) => f(t));
};

Promise.prototype.mapAsync = async function <T, B>(f: (t: T) => B): Promise<B> {
	const t = await this;
	return f(t);
};
