import { NutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';

export function mapAdvancedSearchToUrl(advancedSearch: NutriScoreNutrientsAdvancedSearch): string {
	const output: string[] = [];
	if (advancedSearch.searchTerm !== undefined) output.push('st=' + advancedSearch.searchTerm);
	if (advancedSearch.pageIndex !== undefined) output.push('pi=' + advancedSearch.pageIndex);
	if (advancedSearch.pageSize !== undefined) output.push('ps=' + advancedSearch.pageSize);
	if (advancedSearch.id !== undefined) output.push('id=' + advancedSearch.id);
	return '?' + output.join('&');
}
