import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/nutriScoreLabel/GetNutriScoreLabelIdV1';
import NutriScoreLabel from 'classes/StandingData/NutriScoreLabel/NutriScoreLabel';
import NutriScoreLabelLanguageInput from 'components/desktop/StandingData/NutriScoreLabel/LanguageInput/NutriScoreLabelLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const NutriScoreLabelDetail = () => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');
	const [nutriScoreLabel, setNutriScoreLabel] = useState<NutriScoreLabel>(new NutriScoreLabel());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getNutriScoreLabel(id);
		}
	};

	const getNutriScoreLabel = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setNutriScoreLabel(new NutriScoreLabel(x)));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		switch (name) {
			case 'labelTranslations':
				nutriScoreLabel.labelTranslations[selectedCultureCode] = value;
				break;
			case 'color':
				nutriScoreLabel.color = value;
				break;
			case 'rank':
				nutriScoreLabel.rank = Number(value);
				break;
			case 'maxValue':
				nutriScoreLabel.maxValue = Number(value);
				break;
		}
		setNutriScoreLabel(clone(nutriScoreLabel));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveNutriScoreLabel = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await nutriScoreLabel.callApiPut();
			} else {
				await nutriScoreLabel.callApiPost();
				if (!nutriScoreLabel.id) return;
				setId(nutriScoreLabel.id);
				setIdToUrl(nutriScoreLabel.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleFluidToggle = (input: boolean) => {
		nutriScoreLabel.isFluid = input;
		setNutriScoreLabel(clone(nutriScoreLabel));
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{nutriScoreLabel.id ? (
								<>
									<h1>{t('standingData:EDIT_NUTRISCORELABEL')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_NUTRISCORELABEL')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveNutriScoreLabel()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<Toggle
							checked={nutriScoreLabel.isFluid ?? false}
							handleToggle={handleFluidToggle}
							elementId="isFluid"
							label="standingData:FLUID"
							errorKey="IsFluid"
						/>
						<label className="form-control-label" htmlFor="maxValue">
							{t('standingData:MAX_VALUE')}
						</label>
						<input
							value={nutriScoreLabel.maxValue ?? 0}
							type="number"
							onChange={handleInputValueChange}
							className="form-control"
							name="maxValue"
							id="maxValue"
							error-key="MaxValue"
						/>
						<label className="form-control-label" htmlFor="rank">
							{t('standingData:RANK')}
						</label>
						<input
							value={nutriScoreLabel.rank}
							type="number"
							onChange={handleInputValueChange}
							className="form-control"
							name="rank"
							id="rank"
							error-key="Rank"
						/>
						<label className="form-control-label">{t('standingData:COLOR')}</label>
						<input
							value={nutriScoreLabel.color}
							type="text"
							onChange={handleInputValueChange}
							className="form-control"
							name="color"
							id="color"
							error-key="Color"
						/>
						<input
							type="color"
							onChange={handleInputValueChange}
							value={nutriScoreLabel.color}
							name="color"
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={false}
							content={
								<NutriScoreLabelLanguageInput
									nutriScoreLabel={nutriScoreLabel}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default NutriScoreLabelDetail;
