import Archive from 'containers/desktop/StandingData/OriginLocation/Archive/OriginLocationArchive';
import Management from 'containers/desktop/StandingData/OriginLocation/Management/OriginLocationManagement';
import PERMISSIONS from 'enums/permissions';

export const originLocationRoutes = [
	{
		path: '/originLocation/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/originLocation/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
