import * as apiSW from 'api/menu/GetMenuStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Menu {
	public id: string = '';
	public description: string = '';

	public constructor(input?: apiSW.ResponseMenu) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToSelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.description,
		};
	}

	private mapFromApi(input: apiSW.ResponseMenu): void {
		this.id = input.id;
		this.description = input.description ?? '';
	}
}
