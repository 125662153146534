interface IProps {
	title: string;
	content: JSX.Element;
}

const Card = (props: IProps) => {
	return (
		<>
			<div className="card">
				<div className="card-header card-header-secondary">{props.title}</div>
				<div className="card-body text-center">{props.content}</div>
			</div>
		</>
	);
};

export default Card;
