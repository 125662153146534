import React from 'react';

import 'components/desktop/_general/AirTable/AirTable.scss';
import { TableHeaders } from 'components/desktop/_general/AirTable/AirTableHeaders';
import * as AirTableLogic from 'components/desktop/_general/AirTable/AirTableLogic';
import { OverflowSegment, TableRows } from 'components/desktop/_general/AirTable/AirTableRows';
import { getColumnWidth } from 'components/desktop/_general/AirTable/AirTableStyles';
import {
	ColumnInformation,
	ColumnTypeWithWidth,
} from 'components/desktop/_general/AirTable/AirTableTypes';
import { DividerRow } from 'components/desktop/_general/AirTable/DividerRow';

type IProps = {
	columns: ColumnInformation[];
	dividers: number[];
	data: { [key: string]: any }[];
	readOnly: boolean;
};

const calculateColumnIndex = (props: IProps) => {
	const fixedColumns: (ColumnTypeWithWidth | ColumnInformation)[] = props.columns
		.filter((c) => c.sticky)
		.map((col) => ({ ...col, width: getColumnWidth(col, props.data), start: 0 }))
		.reductions<ColumnTypeWithWidth>((acc, col) =>
			acc ? { ...col, start: acc.width + acc.start } : col
		);

	const nonFixedColumns = props.columns.filter((c) => !c.sticky);
	return fixedColumns.concat(nonFixedColumns).toDictionary(
		(col) => col.key,
		(col) => col
	);
};

const Table = (props: IProps & { children: JSX.Element[] }) => {
	const columnsIndex = calculateColumnIndex(props);

	const segmentIndexes = props.data.map((x) => x.segmentIndex);

	const indexesOfLastRowInSegment =
		AirTableLogic.calculateIndexesOfLastRowInSegment(segmentIndexes);

	const indexesOfFirstRowInSegment =
		AirTableLogic.calculateIndexesOfFirstRowInSegment(segmentIndexes);

	const rowSpanBySegmentIndex = AirTableLogic.calculateRowSpanBySegmentIndex(segmentIndexes);

	const dataWithSegmentInformation = AirTableLogic.calculateDataWithSegmentInformation(
		props.data,
		indexesOfFirstRowInSegment,
		indexesOfLastRowInSegment,
		rowSpanBySegmentIndex
	);

	const dataWithOverflowSegmentInformation =
		AirTableLogic.calculateDataWithOverflowSegmentInformation(dataWithSegmentInformation);

	const overflowSegments = AirTableLogic.calculateOverflowSegments(
		props.children,
		dataWithOverflowSegmentInformation
	);

	return (
		<table className="air-table-recipe-table">
			<thead>
				<tr>
					<TableHeaders columnsIndex={columnsIndex} columns={props.columns} />
				</tr>
			</thead>

			<tbody>
				<TableRows
					{...props}
					data={dataWithOverflowSegmentInformation}
					columnsIndex={columnsIndex}
					children={props.children}
					readOnly={props.readOnly}
				/>
				{overflowSegments.map((x, i) => (
					<React.Fragment key={i}>
						<tr>
							<OverflowSegment
								segment={x}
								columns={props.columns}
								columnsIndex={columnsIndex}
							/>
						</tr>
						<DividerRow />
					</React.Fragment>
				))}
			</tbody>
		</table>
	);
};

export const AirTable = (props: IProps & { children?: JSX.Element[] }) => {
	const children: JSX.Element[] = props.children ? props.children : [];
	return (
		<div className="air-table-slider-container">
			<div className="air-table-view">
				<div className="air-table-wrapper">
					<Table
						{...props}
						data={props.data.map((x, i) => ({ ...x, index: i }))}
						children={children}
					/>
				</div>
			</div>
		</div>
	);
};
