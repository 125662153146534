import axios from 'axios';

import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function apiCall(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/MenuPlanner';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	systemStatus?: SystemStatus;
	statusId: string;
	caloriesLimit?: number | null;
	priceLimit?: number | null;
	startDate?: string;
	durationDays?: number;
	titleTranslations: Translation;
	rows?: Row[] | null;
}

export type Row = {
	id?: string | null;
	nameTranslations: Translation;
	items: Item[] | null;
	tags: string[] | null;
};

export type Translation = {
	[key: string]: string;
};

export type Item = {
	id: string;
	type: MENUPLANNERITEMTYPE;
	day: number;
};

export interface Response {
	data: string;
}
