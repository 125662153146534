import { AllergenLogic } from 'enums/allergenLogic';

export type Type = {
	id: string;
	iconSvgUrl: string;
	name: string;
	display: boolean;
	logic: AllergenLogic;
	source: string | null;
};

export function create() {
	return {
		id: '',
		iconSvgUrl: '',
		name: '',
		display: false,
		logic: AllergenLogic.MayContain,
		source: null,
	};
}

export function createId(obj: Type): string {
	return `${obj.id}:${obj.logic}`;
}

export function decreateId(obj: string): { id: string; logic: AllergenLogic } {
	const [id, logic] = obj.split(':');
	return {
		id: id,
		logic: logic as AllergenLogic,
	};
}
