import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class PricePerAccessory {
	public accessoryIndex: number = 0;
	public accessoryId: string = '';
	public price: number = 0;

	public constructor(input?: api.PricePerAccessory) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.PricePerAccessory) {
		this.accessoryIndex = input.accessoryIndex;
		this.accessoryId = input.accessoryId;
		this.price = input.price;
	}
}
