import ChannelManagementComponent from 'components/desktop/StandingData/Channel/Management/ChannelManagementComponent';

const ChannelManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ChannelManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ChannelManagementContainer;
