import { AllergenLogic } from 'enums/allergenLogic';
import { assoc } from 'functions/objectExtensions';
import * as Allergen from 'types/BaseIngredient/Detail/Allergen';

export type Type = Allergen.Type[];

export function create(): Type {
	return [];
}

export function mapToApi(obj: Type): {
	[key: string]: AllergenLogic;
} {
	const output: {
		[key: string]: AllergenLogic;
	} = {};
	for (const i of obj) {
		output[i.id] = i.logic;
	}
	return output;
}

export function mapToUniqIdList(obj: Type): Type {
	return obj.map((e) => {
		return {
			...e,
			id: Allergen.createId(e),
			icon: e.iconSvgUrl,
		};
	});
}

export function mapToUniqIdStringArray(obj: Type): string[] {
	return obj.map((e) => Allergen.createId(e));
}

export function mapFromUniqIdList(input: string[]): { [key: string]: AllergenLogic } {
	return input.reduce(
		(a, i) => {
			const output = Allergen.decreateId(i);
			return assoc(a, output.id, output.logic);
		},
		{} as { [key: string]: AllergenLogic }
	);
}
