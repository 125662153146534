import * as apiSW from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';

export default class NutriScoreLabel {
	public ids: string[] = [];
	public label: string = '';
	public color: string = '';

	public constructor(input?: apiSW.ResponseNutriScoreLabel) {
		if (input) {
			this.ids = input.ids;
			this.color = input.color;
			this.label = input.label;
		}
	}
}
