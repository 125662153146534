import * as api from 'api/recipe/PostRecipeCalculateV1';
import NutrientValue from 'classes/Recipe/Detail/Calculation/NutrientValue';

export default class NutrientValueList {
	public items: NutrientValue[] = [];

	public constructor(input?: api.NutrientValues[]) {
		if (input) this.mapFromApi(input);
	}

	public getById(id: string[]): NutrientValue | undefined {
		return this.items.find((e) => id.some((y) => e.nutrientId === y));
	}

	public getTotalById(id: string[], parts: number): number {
		const nutrientValue: NutrientValue | undefined = this.getById(id);
		if (!nutrientValue) return 0;
		return nutrientValue.total / parts;
	}

	public getUnitById(id: string[]): string {
		const nutrientValue: NutrientValue | undefined = this.getById(id);
		if (!nutrientValue) return '';
		return nutrientValue.unit;
	}

	public calculateDailyValueById(ids: string[], parts: number, dailyValues: number[]): number {
		const nutrientValue: NutrientValue | undefined = this.getById(ids);
		const dailyValue = dailyValues
			.zip(ids)
			.filter(([_dailyValue, id]) => this.items.some((y) => y.nutrientId === id))
			.map(([dailyValue, _id]) => dailyValue)
			.firstOrDefault();

		if (!nutrientValue || !dailyValue) return 0;

		return (nutrientValue.total / parts / dailyValue) * 100;
	}

	private mapFromApi(input: api.NutrientValues[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new NutrientValue(i));
		}
	}
}
