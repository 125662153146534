import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function apiCall(nutrientId: string, pointType: PointType): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/NutriScoreNutrientPoints/${nutrientId}`;
	return axios
		.get(url, {
			params: {
				PointType: pointType,
			},
		})
		.toOptionalMapped((x) => x.data);
}

export type Response = {
	data: ResponseData;
};

export type ResponseData = {
	nutrientId: string;
	nutrientName: string | null;
	pointType: PointType;
	points: Point[];
	isPositive: boolean;
	isProtein: boolean;
};

export type Point = {
	points: number;
	minValue: number | null;
};

export type PointType = 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water';
