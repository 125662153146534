import * as apiPostSearch from 'api/accessory/PostAccessorySearchV1';

import Category from './Category';

export default class CategoryList {
	public all: Category[] = [];

	public constructor(input?: apiPostSearch.Category[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.Category[] | null) {
		if (input) {
			for (const i of input) {
				const category: Category = new Category(i);
				this.all.push(category);
			}
		}
	}
}
