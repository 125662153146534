import * as apiSW from 'api/recipeCategory/GetRecipeCategoryStartingWithV1';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import RecipeCategory from 'classes/_general/Search/RecipeCategory/RecipeCategory';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class RecipeCategoryList implements Selectable {
	public all: RecipeCategory[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: RecipeCategory[] = this.all.filter((e: RecipeCategory) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.toSelectItemList(filtered);
	}

	private toSelectItemList(input: RecipeCategory[]): ISelectItem[] {
		return input.map((e: RecipeCategory) => {
			return e.toSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseRecipeCategory[]): void {
		this.all = [];
		for (const i of input) {
			const recipeCategory: RecipeCategory = new RecipeCategory(i);
			this.all.push(recipeCategory);
		}
	}

	public getById(id: string): RecipeCategory | undefined {
		return this.all.find((e: RecipeCategory) => {
			return e.id === id;
		});
	}
}
