import UrlParams from 'enums/Product/Search/UrlParams';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';
import { AirUrlParams } from 'hooks/useAirNavigation';
import * as Range from 'types/Product/Search/Range';

export type Type = {
	id: string;
	left: number | null;
	right: number | null;
};

export function create(): Type {
	return {
		id: '',
		left: null,
		right: null,
	};
}

export function mapToApi(obj: Type): Range.Type {
	return {
		minimum: obj.left,
		maximum: obj.right,
	};
}

export function getFromUrl(params: AirUrlParams, obj: Type, urlKey: UrlParams): Type {
	const result = { ...obj };
	for (const [key, value] of Object.entries(params)) {
		switch (key) {
			case `${urlKey}Left`:
				result.left = Number(value);
				break;
			case `${urlKey}Right`:
				result.right = Number(value);
				break;
		}
	}
	return result;
}

export function getMaxRoundUp(max: number): number {
	return Math.ceil(max);
}

export function getMinRoundDown(min: number): number {
	return Math.floor(min);
}

export function mapToUrl(
	params: AirUrlParamsClass,
	range: Range.Type,
	key: UrlParams,
	newLeft: number | null,
	newRight: number | null
): AirUrlParamsClass {
	const keyLeft: string = `${key}Left`;
	const keyRight: string = `${key}Right`;
	let newParams = params;
	if (newRight !== null) {
		if (newRight >= (range.maximum ?? 0)) newParams = newParams.delete(keyRight);
		newParams = newParams.add(keyRight, String(newRight));
	}
	if (newLeft !== null) {
		if (newLeft <= (range.minimum ?? 0)) newParams = newParams.delete(keyLeft);
		newParams = newParams.add(keyLeft, String(newLeft));
	}
	return newParams;
}
