import SeasonsPerIngredient from 'classes/Recipe/Detail/Calculation/SeasonsPerIngredient';
import { getIngredientOrAccessoryClassName } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import SeasonalityBarBig from 'components/desktop/_general/SeasonalityBar/SeasonalityBarBig';
import { Segment } from 'types/Recipe/Segment';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';

interface IProps {
	segments: Segment[] | undefined;
	seasonsPerIngredient: SeasonsPerIngredient[];
}
const SeasonalityView = (props: IProps) => {
	const getClassName = (ingredient: SegmentIngredient): string => {
		return ['col-2', 'ingredient-data-col', getIngredientOrAccessoryClassName(ingredient)].join(
			' '
		);
	};

	const renderSeasonalityBar = (ingredientId: string | null): JSX.Element => {
		let seasonalityCode: number = 0;

		const seasonality: SeasonsPerIngredient | undefined = props.seasonsPerIngredient.find(
			(e: SeasonsPerIngredient) => {
				return e.ingredientId === ingredientId;
			}
		);
		if (seasonality) {
			seasonalityCode = seasonality.getSeasonalityCode();
		}
		return (
			<SeasonalityBarBig
				seasonalityCode={seasonalityCode}
				showIfSeasonalityCodeIsZero={true}
			/>
		);
	};

	const handleIngredientLinkClicked = (ingredient: SegmentIngredient) => {
		window.open(`/ingredient/detail/${ingredient.ingredientId}`, '_blank');
	};

	const renderIngredientsTable = (ingredients: SegmentIngredient[]) => {
		return (
			<div className="recipe-rendering-ingredient-container">
				<table
					className="table table-sm table-bordered allergen-rendering-table"
					style={{ marginBottom: '0' }}
				>
					<tbody>
						{ingredients.map((ingredient, i) => (
							<tr key={i}>
								{ingredient.status.ingredient === 'Recognized' ||
								ingredient.status.ingredient === 'PartiallyRecognized' ? (
									<>
										<td
											className={getClassName(ingredient)}
											onClick={() => handleIngredientLinkClicked(ingredient)}
											style={{ cursor: 'pointer' }}
										>
											{ingredient.ingredient}
										</td>
										<td className="align-middle">
											{renderSeasonalityBar(ingredient.ingredientId)}
										</td>
									</>
								) : (
									<>
										<td className={getClassName(ingredient)}>
											{ingredient.ingredient}
										</td>
										<td>–</td>
									</>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};
	const renderContent = () => {
		if (props.segments && props.segments.length > 0) {
			return (
				<>
					{props.segments.map((segment, i) => {
						return (
							<div className="recipe-rendering" key={i}>
								<div className="row">
									<div className="col-12">
										{renderIngredientsTable(segment.ingredients)}
									</div>
								</div>
							</div>
						);
					})}
				</>
			);
		}
	};
	return <>{renderContent()}</>;
};
export default SeasonalityView;
