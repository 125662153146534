import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/NutrientCategory/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	parentId: string | null;
	nameTranslations: {
		[key: string]: string;
	};
	sortOrder: number;
}
