import * as apiSW from 'api/season/GetSeasonStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Season {
	public id: string = '';
	public name: string = '';
	public iconSvgUrl: string = '';
	public display: boolean = false;
	public associatedMonthsFlag: number = 0;

	public constructor(input?: apiSW.ResponseSeason) {
		if (input) {
			this.translateFromApi(input);
		}
	}

	public toSelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name,
			iconSvgUrl: this.iconSvgUrl,
			display: this.display,
		};
	}

	private translateFromApi(input: apiSW.ResponseSeason): void {
		this.id = input.id;
		this.name = input.name;
		this.iconSvgUrl = input.iconSvgUrl;
		this.display = input.display;
		this.associatedMonthsFlag = input.associatedMonthsFlag;
	}
}
