import { RecipeSortOrder } from 'enums/SortOrder/RecipeSortOrder';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

export default class NullableRecipeSortOrder implements IAdvancedSearchKey {
	public id: string;
	public value: RecipeSortOrder | null;
	public defaultValue: RecipeSortOrder;

	constructor(id: string, defaultValue: RecipeSortOrder) {
		this.id = id;
		this.value = null;
		this.defaultValue = defaultValue;
	}

	public reset() {
		this.value = null;
	}

	public mapResponse(input: RecipeSortOrder) {
		this.value = input;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? (value as RecipeSortOrder) : null;
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value;
		}
		return null;
	}

	public changeToRelevanceIfValueIsNull(): void {
		if (this.value === null) {
			this.value = RecipeSortOrder.Relevance;
		}
	}

	public mapToApi(): RecipeSortOrder {
		if (this.value === null) {
			return this.defaultValue;
		}
		return this.value;
	}
}
