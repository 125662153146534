import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class Season {
	public season: string = '';
	public associatedMonthsFlag: number | null = null;
	public iconSvgUrl: string | null = null;

	public constructor(input?: api.Seasons) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.Seasons) {
		this.season = input.season;
		this.associatedMonthsFlag = input.associatedMonthsFlag;
		this.iconSvgUrl = input.iconSvgUrl;
	}
}
