export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export const defaultImage = (): Image => {
	return {
		id: '',
		url: '',
		availableSizes: [],
	};
};
