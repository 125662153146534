import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';
import { ValidationEntityType } from 'types/Validation/ValidationEntityType';

export async function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/validatorResults/List';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export type Request = {
	pageIndex: number | null;
	pageSize: number | null;
	type: 'Recipe';
	showOnlyUnacknowledged: boolean | null;
};

export type Response = {
	data: ResponseData; // required
};

export type ResponseData = {
	data: Data[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type Data = {
	entityId: string;
	entityType: ValidationEntityType;
	name: string | null;
	lastValidationDateUtc: string;
	validationErrors: ValidationError[];
};

export type ValidationError = {
	validationType: ValidationType;
	errors: ErrorEntry[];
};

export type ErrorEntry = {
	id: string;
	createdDateUtc: string;
	acknowledgedDateUtc: string | null;
	isAcknowledged: boolean;
	validationMessage: string | null;
	causativeEntity: CausativeEntity | null;
};

export type CausativeEntity = {
	id: string;
	type: string;
	name: string | null;
};

export type ValidationType =
	| 'RecipeHasNoIngredientText'
	| 'RecipeSegmentHasUnrecognizedIngredient'
	| 'RecipeSegmentHasUnrecognizedUnit'
	| 'RecipeSegmentHasUnrecognizedQuantity'
	| 'SeasonCountInconsistent'
	| 'SeasonInconsistent'
	| 'AllergenCountInconsistent'
	| 'AllergenInconsistent'
	| 'CarbonDioxideValueInconsistent'
	| 'CarbonDioxideLabelInconsistent'
	| 'PriceInconsistent'
	| 'CalorieInconsistent'
	| 'WeightInconsistent'
	| 'NutrientValueCountInconsistent'
	| 'NutrientValueInconsistent';
