import { Language } from 'types/Language/Language';

export const setReduxLanguages = (languages: Language[]) => {
	return {
		type: 'languages/SET',
		payload: languages,
	};
};

export type LanguagesAction = {
	type: string;
	payload: Language[];
};

export const languagesReducer = (state: Language[] = [], action: LanguagesAction): Language[] => {
	switch (action.type) {
		case 'languages/SET':
			return action.payload;
		default:
			return state;
	}
};
