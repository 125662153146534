import { Dispatch, SetStateAction } from 'react';

import * as apiGetCategoryTree from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import { Category as CategoryTree } from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import { createHandler } from 'functions/handlers';
import { Accessory } from 'types/Accessory/Accessory';
import { Categories } from 'types/Accessory/Accessory';

export const getCategoryTree = async (
	searchTerm: string
): Promise<apiGetCategoryTree.Category[]> => {
	const response = await apiGetCategoryTree.callApi(searchTerm, false);
	return response.getOrDefault([]);
};

export const getSelectedCategoriesTree = (
	accessory: Accessory,
	tree: CategoryTree[]
): CategoryTree[] => {
	if (!accessory.categories) return [];
	const output: CategoryTree[] = [];
	for (const i of accessory.categories) {
		const element: CategoryTree | undefined = findElementInTree(i.id, tree);
		if (element) {
			output.push(element);
		}
	}
	return output;
};

const findElementInTree = (id: string, tree: CategoryTree[]): CategoryTree | undefined => {
	for (const i of tree) {
		if (i.id === id) return i;
		for (const child of i.children) {
			const found = findElementInTree(id, [child]);
			if (found) return found;
		}
	}
	return undefined;
};

export const createCategoriesHandler = (setAccessory: Dispatch<SetStateAction<Accessory>>) =>
	createHandler(setAccessory, (accessory: Accessory, tree: apiGetCategoryTree.Category[]) => ({
		...accessory,
		categories: tree.map((e: apiGetCategoryTree.Category) => ({
			id: e.id,
			name: e.name,
		})) as Categories[] | null,
	}));
