export type Type = {
	minimum: number | null;
	maximum: number | null;
};

export function create(): Type {
	return {
		minimum: null,
		maximum: null,
	};
}

export function getMinimum(inputA: Type, inputB: Type): number | null {
	if (inputA.minimum === null) return inputB.minimum;
	if (inputB.minimum === null) return inputA.minimum;
	return inputA.minimum < inputB.minimum ? inputA.minimum : inputB.minimum;
}

export function getMaximum(inputA: Type, inputB: Type): number | null {
	if (!inputA.maximum) return inputB.maximum;
	if (!inputB.maximum) return inputA.maximum;
	return inputA.maximum > inputB.maximum ? inputA.maximum : inputB.maximum;
}
