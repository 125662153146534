import { Store, StoreContext, useStoreJustOnceInTheWholeApp } from 'context/Store';
import * as React from 'react';

import Routing from 'containers/desktop/routing';

const DesktopContainer: React.FC = () => {
	const store: Store = useStoreJustOnceInTheWholeApp();
	return (
		<StoreContext.Provider value={store}>
			<Routing store={store} />
		</StoreContext.Provider>
	);
};

export default DesktopContainer;
