import { getColumnStyle } from 'components/desktop/_general/AirTable/AirTableStyles';
import {
	ColumnInformation,
	ColumnTypeWithWidth,
	RowData,
} from 'components/desktop/_general/AirTable/AirTableTypes';
import { Match } from 'components/desktop/_general/Conditional/Match';
import { asBoolean } from 'functions/coercions';

const InputCell = (props: {
	cellData: any;
	column: ColumnInformation;
	index: number;
	firstCell: boolean;
	lastRow: boolean;
	disabled: boolean;
}) => {
	const emptyCellDataClass = props.cellData
		? 'air-table-not-empty-input-cell'
		: 'air-table-empty-input-cell';
	const lastCellClassName = props.firstCell ? 'air-table-data-cell-last-child' : '';
	const lastRowClassName = props.lastRow ? 'air-table-data-cell-last-row' : '';
	const firstCellClassName = props.firstCell ? 'air-table-data-cell-first-cell' : '';
	return (
		<div
			className={`air-table-data-cell ${lastCellClassName} ${firstCellClassName} ${lastRowClassName}`}
		>
			<input
				className={`form-control form-control-sm air-table-input-cell ${emptyCellDataClass}`}
				disabled={props.cellData === null || props.disabled}
				value={props.cellData || ''}
				onChange={(e) => {
					if (props.column.onInputChange) {
						props.column.onInputChange(e.target.value, props.index);
					}
				}}
			/>
		</div>
	);
};

const DataCell = (props: {
	cellData: any;
	rowData: RowData;
	col: ColumnInformation;
	firstCell: boolean;
	lastRow: boolean;
}) => {
	const columnClassName = props.col.className ? props.col.className : '';
	const firstCellClassName = props.firstCell ? 'air-table-data-cell-first-cell' : '';
	const lastRowClassName = props.lastRow ? 'air-table-data-cell-last-row' : '';
	return (
		<div
			className={`air-table-data-cell ${columnClassName} ${firstCellClassName} ${lastRowClassName}`}
			title={props.col.numeric ? props.cellData : undefined}
		>
			<span>{props.cellData}</span>
		</div>
	);
};

export const TableCell = (props: {
	rowData: RowData;
	column: ColumnTypeWithWidth | ColumnInformation;
	index: number;
	firstCell: boolean;
	lastRow: boolean;
	readOnly: boolean;
}) => {
	const stickyClassName = props.column.sticky ? 'air-table-sticky-column' : '';
	const numericClassName = props.column.numeric ? 'air-table-numeric-column' : '';

	const cellData = props.rowData[props.column.key];
	const customCell =
		props.rowData.customCell && (props.rowData.customCell[props.column.key] as JSX.Element);

	return (
		<td
			className={`air-table-cell ${stickyClassName} ${numericClassName}  `}
			style={getColumnStyle(props.column)}
		>
			<Match conditions={[asBoolean(props.column.editable), asBoolean(customCell)]}>
				<InputCell
					index={props.index}
					column={props.column}
					cellData={cellData}
					firstCell={props.firstCell}
					lastRow={props.lastRow}
					disabled={props.readOnly}
				/>
				{customCell}
				<DataCell
					col={props.column}
					rowData={props.rowData}
					cellData={cellData}
					firstCell={props.firstCell}
					lastRow={props.lastRow}
				/>
			</Match>
		</td>
	);
};
