import * as apiPostSearch from 'api/baseIngredient/PostBaseIngredientSearchV1';
import Tag from 'classes/BaseIngredient/Search/Tag';
import { Icon } from 'types/Icon/Icon';

export default class TagList {
	public all: Tag[] = [];

	public constructor(input?: apiPostSearch.Tag[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): Icon[] {
		const icons: Icon[] = this.all
			.map((e: Tag) => {
				return e.mapToIcon();
			})
			.filter((e: Icon | null) => {
				if (e !== null) {
					return e;
				}
			}) as Icon[];
		return icons;
	}

	public mapFromApi(input: apiPostSearch.Tag[] | null) {
		if (input) {
			for (const i of input) {
				const tag: Tag = new Tag(i);
				this.all.push(tag);
			}
		}
	}
}
