import * as apiSW from 'api/ingredientCategory/GetIngredientCategoryStartingWithV1';
import IngredientCategory from 'classes/_general/Search/IngredientCategory/IngredientCategory';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class IngredientCategoryGroupList implements Selectable {
	public all: IngredientCategory[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: IngredientCategory[] = this.all.filter((e: IngredientCategory) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.toSelectItemList(filtered);
	}

	public getById(id: string): IngredientCategory | undefined {
		return this.all.find((e: IngredientCategory) => {
			return e.id === id;
		});
	}

	private toSelectItemList(input: IngredientCategory[]): ISelectItem[] {
		return input.map((e: IngredientCategory) => {
			return e.toSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseIngredientCategory[]): void {
		this.all = [];
		for (const i of input) {
			const ingredientCategory: IngredientCategory = new IngredientCategory(i);
			this.all.push(ingredientCategory);
		}
	}
}
