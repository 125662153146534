import MenuPlanSearchComponent from 'components/desktop/MenuPlan/Search/MenuPlanSearchComponent';

const MenuPlanArchiveContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<MenuPlanSearchComponent archive={true} />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuPlanArchiveContainer;
