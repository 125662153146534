import { ToggleItem } from 'types/_general/ToggleItem';

export type GetAllStatusDto = {
	id: string;
	name: string;
	order: number;
	color: string;
};

export function mapToToggleItemList(input: GetAllStatusDto[]): ToggleItem[] {
	return input.map((x) => {
		return {
			id: x.id,
			label: x.name,
			checked: true,
		};
	});
}
