export type PasswordResetDto = {
	password: string;
	token: string | null;
};

export const defaultPasswordResetDto = (): PasswordResetDto => {
	return {
		password: '',
		token: '',
	};
};
