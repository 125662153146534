import UrlParams from 'enums/Product/Search/UrlParams';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';

export type Type = string;

export function create(): Type {
	return '';
}

export function mapToUrl(
	obj: Type,
	params: AirUrlParamsClass,
	urlParam: UrlParams
): AirUrlParamsClass {
	if (!obj) return params.delete(urlParam);
	return params.add(urlParam, obj);
}
