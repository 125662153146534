import * as apiGet from 'api/nutrient/GetNutrientIdV1';

export default class Unit {
	public id: string = '';
	public name: string = '';

	public constructor(input?: apiGet.Unit) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiGet.Unit): void {
		this.id = input.id;
		this.name = input.name ?? '';
	}
}
