import { useTranslation } from 'react-i18next';

import Season from 'classes/StandingData/Season/Season';

interface IProps {
	season: Season;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SeasonLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<div className="row mb-3">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={
										props.season.nameTranslations[props.selectedLanguage] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="positiveNameTranslations"
									error-key="NameTranslations"
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default SeasonLanguageInput;
