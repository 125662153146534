import OriginLocationManagementComponent from 'components/desktop/StandingData/OriginLocation/Management/OriginLocationManagementComponent';

const OriginLocationArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<OriginLocationManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default OriginLocationArchiveContainer;
