import Archive from 'containers/desktop/StandingData/TimeUnit/Archive/TimeUnitArchive';
import Management from 'containers/desktop/StandingData/TimeUnit/Management/TimeUnitManagement';
import PERMISSIONS from 'enums/permissions';

export const timeUnitRoutes = [
	{
		path: '/timeUnit/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/timeUnit/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
