import * as apiPost from 'api/nutriScoreBaseIngredientCategoryPoints/PostNutriScoreBaseIngredientCategoryPointsV1';
import * as apiPut from 'api/nutriScoreBaseIngredientCategoryPoints/PutNutriScoreBaseIngredientCategoryPointsV1';
import { BaseIngredientCategoryPoint } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPoint';

export const mapToRequest = (
	input: BaseIngredientCategoryPoint
): apiPut.Request & apiPost.Request => {
	return {
		baseIngredientCategoryId: input.baseIngredientCategoryId,
		pointType: input.pointType,
		points: input.points,
		isPositive: input.isPositive,
	};
};
