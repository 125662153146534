import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faImageSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import MediaChannelEditor from 'components/desktop/Recipe/MediaChannelEditor/MediaChannelEditor';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ImageCarouselButtons from 'components/desktop/_general/ImageCarousel/ImageCarouselButtons';
import MediaCarousel from 'components/desktop/_general/MediaCarousel/MediaCarousel';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { MEDIATYPES } from 'enums/mediaTypes';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { ChannelMedia } from 'types/Media/ChannelMedia';

interface IProps {
	handleDeleteImage: (id: string) => void;
	images: ChannelMedia[];
	mainImage: ChannelMedia | null;
	readOnly: boolean;
	setMainImage: (input: ChannelMedia) => void;
	updateChannels: (mediaId: string, channels: ChannelLight[]) => void;
	uploadImage: (input: File[], video: boolean) => void;
}

const ImageCarousel = (props: IProps) => {
	const [showButtons, setShowButtons] = useState<boolean>(false);
	const [currentChannelMedia, setCurrentChannelMedia] = useState<ChannelMedia | null>(null);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [selectedImage, setSelectedImage] = useState<ChannelMedia | null>(null);

	const closeOverlay = (): void => setDisplayOverlay(false);
	const onMouseOver = (): void => setShowButtons(true);
	const onMouseLeave = (): void => setShowButtons(false);

	const actionButtonClass = `thumbnail-action-buttons text-center ${showButtons ? '' : 'hide'}`;

	useEffect(() => {
		if (props.mainImage) {
			setSelectedImage(props.mainImage);
		} else {
			if (props.images.length > 0) {
				setSelectedImage(props.images[0]);
			}
		}
	}, [props.images, props.images.length, props.mainImage]);

	const getAllImages = (): ChannelMedia[] => {
		if (props.mainImage) {
			return [...[props.mainImage], ...props.images];
		}
		return props.images;
	};

	const handleOpenOverlay = (): void => {
		setCurrentChannelMedia(selectedImage);
		setDisplayOverlay(true);
	};

	const handleUploadMedia = async (input: FileList) => {
		props.uploadImage(Array.from(input), false);
	};

	const handleImageSelect = (image: ChannelMedia) => {
		setSelectedImage(image);
	};

	const handleSetMainImage = () => {
		if (selectedImage) {
			props.setMainImage(selectedImage);
		}
	};

	const handleImageRemove = (id: string) => {
		props.handleDeleteImage(id);
		if (id === props.mainImage?.id) {
			if (props.images.length > 1) {
				props.setMainImage(props.images[1]);
			}
		}
	};

	const renderThumbnail = (image: ChannelMedia, i: number): JSX.Element => {
		return (
			<div
				onClick={() => handleImageSelect(image)}
				key={i}
				style={{
					cursor: 'pointer',
					boxShadow:
						selectedImage && selectedImage.url === image.url
							? '0px 0px 0px 8px #ddd'
							: 'inherit',
				}}
			>
				<img
					src={image.url}
					style={{
						objectFit: 'cover',
						width: '100%',
						height: '150px',
					}}
				/>
				<RenderIf condition={Boolean(currentChannelMedia)}>
					<Overlay
						contentStyle={{ overflow: 'visible' }}
						containerStyle={{ minHeight: 'fit-content', maxHeight: '100vh' }}
						handleClose={closeOverlay}
						displayOverlay={displayOverlay}
					>
						<MediaChannelEditor
							channelMedia={currentChannelMedia!}
							updateChannels={props.updateChannels}
						/>
					</Overlay>
				</RenderIf>
			</div>
		);
	};

	const renderImagePreview = (): JSX.Element | null => {
		if (!selectedImage)
			return (
				<div>
					<FontAwesomeIcon icon={faImageSlash as IconProp} size={'7x'} />
				</div>
			);

		if (!props.readOnly) {
			return (
				<>
					<div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
						<img src={selectedImage.url} />
						<div className={actionButtonClass} style={{ zIndex: 200 }}>
							<ImageCarouselButtons
								handleDelete={handleImageRemove}
								id={selectedImage.id}
								setMainImage={handleSetMainImage}
								onMediaButtonClick={handleOpenOverlay}
							/>
						</div>
					</div>
					{renderChannelOverlay()}
				</>
			);
		} else {
			return (
				<>
					<div>
						<img src={selectedImage.url} />
					</div>
				</>
			);
		}
	};

	const renderChannelOverlay = (): JSX.Element | null => {
		if (props.readOnly || !currentChannelMedia) {
			return null;
		}

		return (
			<Overlay
				contentStyle={{ overflow: 'visible' }}
				containerStyle={{ minHeight: 'fit-content', maxHeight: '100vh' }}
				handleClose={closeOverlay}
				displayOverlay={displayOverlay}
			>
				<MediaChannelEditor
					channelMedia={currentChannelMedia}
					updateChannels={props.updateChannels}
				/>
			</Overlay>
		);
	};

	const renderImageThumbnails = (): JSX.Element | null => {
		return <>{getAllImages().map((image, i) => renderThumbnail(image, i))}</>;
	};

	return (
		<MediaCarousel
			accept={[MEDIATYPES.TIFF, MEDIATYPES.JPEG, MEDIATYPES.GIF, MEDIATYPES.PNG]}
			buttonLabel={t('_general:UPLOAD_NEW_IMAGE')}
			disabled={props.readOnly}
			handleUploadMedia={handleUploadMedia}
			renderMediaPreview={renderImagePreview}
			renderMediaThumbnails={renderImageThumbnails}
		/>
	);
};

export default ImageCarousel;
