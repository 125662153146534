import axios from 'axios';

import { UnitType } from 'enums/unitType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(unitType: UnitType): Promise<Optional<ResponseUnit[]>> {
	const url: string = '/backend-service/Unit/GetByType';
	return axios.get(url, { params: { Type: unitType } }).toOptionalMapped((x) => x.data.units);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	units: ResponseUnit[];
};

export type ResponseUnit = {
	id: string;
	description: string;
};
