import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<void>> {
	const url = '/backend-service/Channel/SetOnMedia';
	return axios.patch(url, request).toOptionalVoid();
}

export interface Request {
	mediaId: string;
	channelIds: string[];
}
