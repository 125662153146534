import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign, faTimer, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as apiSimilarRecipes from 'api/aiFunctions/PostSimilarRecipesV1';
import 'components/desktop/_general/Card/RecipeCard/RecipeCard.scss';
import { getNumberString, getPriceString } from 'functions/numberToString';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

interface IProps {
	recipe: apiSimilarRecipes.Recipe;
}

const RecipeCard = (props: IProps) => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);

	const getValueScope = (): string => {
		return getValueScopeLabel(reduxUserSetting['Recipe.List.ValueScope'] ?? null, null, true);
	};

	const getInfos = (): JSX.Element => {
		return (
			<>
				<p>
					{props.recipe.calories && (
						<>
							<span id="icon">
								<FontAwesomeIcon icon={faUtensils as IconProp} />
							</span>
							<b>{getNumberString(props.recipe.calories, reduxCultureCode, true)}</b>
							{' ' + t('_general:CALORIES') + getValueScope()}
						</>
					)}
					&nbsp;
				</p>
				<p>
					{props.recipe.price && (
						<>
							<span id="icon">
								<FontAwesomeIcon icon={faDollarSign as IconProp} />
							</span>
							<b>{getPriceString(props.recipe.price, reduxCultureCode)}</b>
							{' ' + reduxCurrency + getValueScope()}
						</>
					)}
					&nbsp;
				</p>
				<p>
					{props.recipe.prepareTime && (
						<>
							<span id="icon">
								<FontAwesomeIcon icon={faTimer as IconProp} />
							</span>
							<b>{Math.round(props.recipe.prepareTime.timeValue)}</b>
							{' ' + props.recipe.prepareTime.timeUnit}
						</>
					)}
				</p>
			</>
		);
	};

	const renderContent = () => {
		return (
			<>
				<Link
					to={'/recipe/detail/' + props.recipe.id}
					target="_blank"
					className="unstyled-link"
				>
					<div className="card recipe-recomendation-card">
						<div className="card-body">
							{!props.recipe.image ? (
								<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
									<FontAwesomeIcon size="3x" icon={faCamera} />
								</div>
							) : (
								<div className="card-picture">
									<img alt="thumbnail" src={props.recipe.image.url} />
								</div>
							)}
							<div>
								<div id="title">
									<strong>{props.recipe.titleTranslation}</strong>
								</div>
								<div id="info">{getInfos()}</div>
							</div>
						</div>
					</div>
				</Link>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeCard;
