import { Optional } from './promiseExtensions';

export const getDataUrlFromUrl = async (url: string): Promise<Optional<string>> => {
	try {
		const data = await fetch(url);
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.onerror = () => {
				resolve(Optional.None());
			};
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result as string;
				resolve(Optional.Just(base64data));
			};
		});
	} catch (e) {
		return Optional.None();
	}
};
