import { useTranslation } from 'react-i18next';

import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import 'containers/desktop/User/PasswordRecovery/PasswordRecovery.scss';

const LinkSentConfirmation = () => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="password-recovery-container">
					<h2>Artificial Intelligence Recipes</h2>
					<div className="password-recovery-inner-container">
						<LogoAnimation />
						<div className="password-recovery-inner-container-div">
							<div className="password-recovery-form">
								<h2>{t('user:LINK_SENT_TITLE')}</h2>
								<p>{t('user:LINK_SENT_TEXT')}</p>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default LinkSentConfirmation;
