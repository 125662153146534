import * as api from 'api/menu/PostMenuSearchV1';
import Recipe from 'classes/Menu/Search/Recipe';

export default class RecipeList {
	public all: Recipe[] = [];

	public constructor(input?: api.Recipe[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Recipe[] | null) {
		if (input) {
			for (const i of input) {
				const recipe: Recipe = new Recipe(i);
				this.all.push(recipe);
			}
		}
	}
}
