import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(userId: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/User/${userId}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	languageId: string;
	profilePicture: Image;
	roles: UserRole[];
	nutrients: UserNutrients[];
	userSettings: UserSettings;
	isActive: boolean;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type UserRole = {
	id: string;
	name: string;
	permissions: [
		{
			id: string;
			systemName: string;
		},
	];
};

export type UserNutrients = {
	id: string;
	sortOrder: number;
	name: string;
	unitShortNameSingular: string;
	nutrientCategory: {
		id: string;
		name: string;
	};
};

export type UserSettings = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope': ValueScope;
};

export enum ValueScope {
	Total = 'Total',
	Person = 'Person',
	Parts = 'Parts',
	HundredGrams = 'HundredGrams',
}
