import * as apiDelete from 'api/recipeCategory/DeleteRecipeCategoryIdV1';
import * as apiRestore from 'api/recipeCategory/PostRecipeCategoryRestoreV1';
import * as apiPostSearch from 'api/recipeCategory/PostRecipeCategorySearchV1';

export default class RecipeCategory {
	public id: string = '';
	public sortOrder: number = 0;
	public name: string = '';

	public constructor(input?: apiPostSearch.ResponseRecipeCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseRecipeCategory) {
		this.id = input.id;
		this.sortOrder = input.sortOrder;
		this.name = input.name;
	}
}
