import * as userLogout from 'api/user/PostUserLogoutV1';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { runOnce } from 'functions/concurrency';
import { getUserRefreshToken } from 'functions/getUserRefreshToken';
import { Optional } from 'functions/promiseExtensions';
import IToken from 'interfaces/IToken';
import ITokenJWT from 'interfaces/ITokenJWT';
import ITokens from 'interfaces/ITokens';

const TOKEN_LOCAL_STORAGE_KEY = 'tokens';

function isTimeStampValid(time: number): boolean {
	const currentTime: number = Math.floor(new Date().getTime() / 1000);
	if (currentTime < time) {
		return true;
	}
	return false;
}

function parseJwt(token: string): ITokenJWT {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
	return JSON.parse(jsonPayload);
}

export function setTokensToLocalStorage(tokens: ITokens): void {
	localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(tokens));
}

export function getTokensFromLocalStorage(): Optional<ITokens> {
	return Optional.Maybe(localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)).map(JSON.parse);
}

function removeTokensFromLocalStorage(): void {
	localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
}

export function getValidAccessTokenFromLocalStorage(): Optional<IToken> {
	return getTokensFromLocalStorage()
		.keep((token: ITokens) => token.accessToken)
		.filter((x) => isTimeStampValid(x.expiresAtUnixTimestamp));
}

function getValidRefreshTokenFromLocalStorage(): Optional<IToken> {
	return getTokensFromLocalStorage()
		.keep((token) => token.refreshToken)
		.filter((x) => isTimeStampValid(x.expiresAtUnixTimestamp));
}

function getPotentiallyExpiredJwtFromAccessToken(): Optional<ITokenJWT> {
	return getTokensFromLocalStorage()
		.keep((token: ITokens) => token.accessToken)
		.map((x: IToken) => x.token)
		.map(parseJwt);
}

async function getUserRefreshTokenAndStoreLocally(refreshToken: IToken) {
	var tokens = await getUserRefreshToken(refreshToken);

	return tokens
		.do(setTokensToLocalStorage)
		.orElseDo(removeTokensFromLocalStorage)
		.keep((x) => x.accessToken);
}

export async function refreshToken(): Promise<Optional<IToken>> {
	var actualRefreshToken = getValidRefreshTokenFromLocalStorage();

	// Multiple callers might cause the refresh fn to be executed more then once.
	return await actualRefreshToken.mapAsync((token) =>
		runOnce(token.token, () => getUserRefreshTokenAndStoreLocally(token))
	);
}
export async function getAccessTokenPotentiallyRenewingIt(): Promise<Optional<IToken>> {
	const accessToken = await getValidAccessTokenFromLocalStorage().orElseAsync(refreshToken);

	return accessToken;
}

export function restart(): void {
	localStorage.clear();
	window.location.href = window.location.origin + '/login';
}

export async function logout(): Promise<void> {
	var refreshToken = await getAccessTokenPotentiallyRenewingIt();

	return refreshToken.doAsync(userLogout.callApi).then(restart);
}

export function isUserAdmin(): boolean {
	return getPotentiallyExpiredJwtFromAccessToken()
		.flatMap((x: ITokenJWT) => x.permission ?? [])
		.includes(PERMISSIONS.ADMINISTRATE);
}

export function getUserPermissions(): Optional<PERMISSIONS[]> {
	return getPotentiallyExpiredJwtFromAccessToken().map((x: ITokenJWT) => x.permission ?? []);
}

export function getUserEntitlements(): Optional<ENTITLEMENTS[]> {
	return getPotentiallyExpiredJwtFromAccessToken().map((x: ITokenJWT) => x.entitlement ?? []);
}

export function isUserWriter(): boolean {
	return getPotentiallyExpiredJwtFromAccessToken()
		.flatMap((x) => x.permission ?? [])
		.includes(PERMISSIONS.WRITE);
}

export function getUserEmail(): string {
	return getPotentiallyExpiredJwtFromAccessToken()
		.map((x) => x.email)
		.getOrDefault('');
}

export function arePermissionsInUserPermissions(permissions: PERMISSIONS[]): boolean {
	if (!permissions.length) return true;

	const userPermissions: PERMISSIONS[] = getUserPermissions().getOrDefault([]);

	return permissions.some((i) => userPermissions.includes(i));
}

export function areEntitlementsInUserEntitlements(entitlements: ENTITLEMENTS[]): boolean {
	if (!entitlements.length) return true;

	const userEntitlements: ENTITLEMENTS[] = getUserEntitlements().getOrDefault([]);

	return entitlements.some((i) => userEntitlements.includes(i));
}
