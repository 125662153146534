import * as apiTranslate from 'api/translation/PostTranslationV1';
import * as apiGet from 'api/unit/GetUnitIdV1';
import * as apiPost from 'api/unit/PostUnitV1';
import * as apiPut from 'api/unit/PutUnitV1';
import { Synonyms } from 'classes/StandingData/Unit/Synonyms';
import PERMISSIONS from 'enums/permissions';
import { UnitType } from 'enums/unitType';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export type TranslationKeys =
	| 'nameSingularTranslations'
	| 'namePluralTranslations'
	| 'shortNameSingularTranslations'
	| 'shortNamePluralTranslations';

export default class Unit {
	public id: string = '';
	public type: UnitType | null = null;
	public factorToMilliliter: number | null = null;
	public factorToGram: number | null = null;
	public factorToOne: number | null = null;
	public nameSingularTranslations: LocalizedTranslation<string> = {};
	public namePluralTranslations: LocalizedTranslation<string> = {};
	public shortNameSingularTranslations: LocalizedTranslation<string> = {};
	public shortNamePluralTranslations: LocalizedTranslation<string> = {};
	public synonyms: Synonyms = new Synonyms();
	public isAirDefinition: boolean = false;

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public isDisabled(): boolean {
		if (arePermissionsInUserPermissions([PERMISSIONS.ADMINISTRATE])) return false;
		if (this.isAirDefinition) return true;
		if (!arePermissionsInUserPermissions([PERMISSIONS.WRITEUNIT])) return true;
		return false;
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const keys: TranslationKeys[] = [
			'nameSingularTranslations',
			'namePluralTranslations',
			'shortNameSingularTranslations',
			'shortNamePluralTranslations',
		];

		await Promise.all(
			keys.map(async (key) => {
				const response = await apiTranslate.callApi(
					this.mapToApiTranslate(key, fromCultureCode, toCultureCode)
				);
				response.hasValue() && this.mapFromApiTranslate(key, response.get(), toCultureCode);
			})
		);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiTranslate(
		key: TranslationKeys,
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this[key][fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			type: this.type,
			factorToMilliliter: this.factorToMilliliter == 0 ? null : this.factorToMilliliter,
			factorToGram: this.factorToGram == 0 ? null : this.factorToGram,
			factorToOne: this.factorToOne == 0 ? null : this.factorToOne,
			nameSingularTranslations: this.nameSingularTranslations,
			namePluralTranslations: this.namePluralTranslations,
			shortNameSingularTranslations: this.shortNameSingularTranslations,
			shortNamePluralTranslations: this.shortNamePluralTranslations,
			synonyms: this.synonyms.mapToApi(),
			isAirDefinition: this.isAirDefinition,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			type: this.type,
			factorToMilliliter: this.factorToMilliliter == 0 ? null : this.factorToMilliliter,
			factorToGram: this.factorToGram == 0 ? null : this.factorToGram,
			factorToOne: this.factorToOne == 0 ? null : this.factorToOne,
			nameSingularTranslations: this.nameSingularTranslations,
			namePluralTranslations: this.namePluralTranslations,
			shortNameSingularTranslations: this.shortNameSingularTranslations,
			shortNamePluralTranslations: this.shortNamePluralTranslations,
			synonyms: this.synonyms.mapToApi(),
			isAirDefinition: this.isAirDefinition,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.type = input.type;
		this.factorToMilliliter = input.factorToMilliliter;
		this.factorToGram = input.factorToGram;
		this.factorToOne = input.factorToOne;
		this.nameSingularTranslations = input.nameSingularTranslations;
		this.namePluralTranslations = input.namePluralTranslations;
		this.shortNameSingularTranslations = input.shortNameSingularTranslations;
		this.shortNamePluralTranslations = input.shortNamePluralTranslations;
		this.synonyms.mapFromApi(input.synonyms);
		this.isAirDefinition = input.isAirDefinition;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(key: TranslationKeys, input: string, toCultureCode: string) {
		this[key][toCultureCode] = input;
	}
}
