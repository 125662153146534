import axios from 'axios';

import { MenuSortOrder } from 'enums/SortOrder/MenuSortOrder';
import { AllergenLogic } from 'enums/allergenLogic';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/Menu/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	allergenIds?: AllergenLogicMap | null;
	caloriesRange?: Range | null;
	carbonDioxideLabelIds?: string[] | null;
	carbonDioxideRange?: Range | null;
	nutrientRanges?: NutrientRange[] | null;
	pageIndex?: number | null;
	pageSize?: number | null;
	priceRange?: Range | null;
	recipeIds?: string[] | null;
	searchTerm?: string | null;
	seasonIds?: string[] | null;
	showOnlyDeleted?: boolean | null;
	sortOrder?: MenuSortOrder | null;
	statusIds?: string[] | null;
	systemStatus?: SystemStatus[] | null;
	tagIds?: string[] | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: Menu[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxCaloriesRange: Range;
	minMaxCarbonDioxideRange: Range;
	minMaxPriceRange: Range;
	minMaxNutrientRanges: MinMaxNutrientRange[];
};

export type MinMaxNutrientRange = {
	nutrientInfo: NutrientInfo;
	range: Range;
};

export type NutrientInfo = {
	id: string;
	sortOrder: number;
	name: string | null;
	unitShortNameSingular: string | null;
	nutrientCategory: NutrientCategory;
};

export type NutrientCategory = {
	id: string;
	name: string | null;
};

export type Status = {
	id: string;
	color: string;
	order: number;
	name: string | null;
};

export type CarbonDioxideLabel = {
	label: string | null;
	color: string;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	associatedMonthsFlag: number | null;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	logic: AllergenLogic;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string | null;
	tagCategory: TagCategory | null;
};

export type TagCategory = {
	id: string;
	name: string | null;
};

export type Recipe = {
	id: string;
	title: string | null;
	lastEditor: string | null;
	status: RecipeStatus | null;
	image: Image | null;
	calories: number | null;
	price: number | null;
	prepareTime: PrepareTime | null;
};

export type PrepareTime = {
	timeUnit: string | null;
	timeValue: number;
};

export type RecipeStatus = {
	id: string;
	color: string;
	order: number;
	name: string | null;
};

export type Menu = {
	id: string;
	lastEditor: string | null;
	nameTranslation: string | null;
	lastEditedDateUtc: string;
	status: Status;
	calories: number | null;
	weight: number | null;
	price: number | null;
	carbonDioxideValue: number | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	seasons: Season[];
	allergens: Allergen[];
	tags: Tag[];
	recipes: Recipe[];
};

export type Range = {
	minimum: number | null;
	maximum: number | null;
};

export type NutrientRange = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export type Image = {
	url: string;
	availableSizes: number[] | null;
};
