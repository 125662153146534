import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import 'components/desktop/_general/Card/FoldableCard/FoldableCard.scss';

interface IProps {
	content: JSX.Element;
	defaultLocked?: boolean;
	defaultOpen: boolean;
	handleLockClick?: (isLocked: boolean) => void;
	hasLock?: boolean;
	info?: string;
	isInActive?: boolean;
	title: string;
	unlockable?: boolean;
	ai?: boolean;
	disabled?: boolean;
}

const FoldableCard = (props: IProps) => {
	const [classHeader, setClassHeader] = useState<string>(
		'foldable-card-header card-header card-header-secondary collapsed d-flex flex-row flex-grow-1'
	);
	const [classBody, setClassBody] = useState<string>('collapse');
	const [isLocked, setIsLocked] = useState<boolean>(false);
	const [regexTitle, setRegexTitle] = useState<string>('');

	useEffect(() => {
		if (props.defaultOpen !== null) {
			setStyle();
		}
	}, []);

	useEffect(() => {
		if (props.defaultOpen !== null) {
			setStyle();
		}
	}, [props.defaultOpen]);

	useEffect(() => {
		setIsLocked(props.defaultLocked ?? false);
	}, [props.defaultLocked]);

	useEffect(() => {
		setRegexTitle(props.title.replace(/[^\w]/gi, ''));
	}, [props.title]);

	const setStyle = () => {
		if (!props.isInActive || props.isInActive == undefined) {
			if (props.defaultOpen) {
				setClassHeader(
					'foldable-card-header card-header card-header-secondary d-flex flex-row flex-grow-1'
				);
				setClassBody('collapse show');
			}
		}
	};

	const isInActive = (): string => {
		if ((props.isInActive || isLocked) && !props.disabled) {
			return ' disabled';
		}
		return '';
	};

	const handleClick = (): void => {
		if (props.hasLock) {
			if (props.unlockable == true || props.unlockable == undefined) {
				setIsLocked(!isLocked);
				if (props.handleLockClick) {
					props.handleLockClick(!isLocked);
				}
			}
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="card">
					<div className="d-flex flex-row">
						<div
							className={classHeader + isInActive()}
							data-bs-toggle="collapse"
							data-bs-target={'#' + regexTitle}
							aria-expanded={props.defaultOpen}
							aria-controls={regexTitle}
						>
							<i className="bi bi-caret-right-fill" />
							<i className="bi bi-caret-down-fill" />
							<div className="flex-grow-1">
								&nbsp;&nbsp;{props.title}{' '}
								{props.info && (
									<FontAwesomeIcon
										style={{
											pointerEvents: 'all',
											cursor: 'default',
											marginBottom: '1px',
										}}
										title={t(props.info)}
										icon={faCircleInfo}
									></FontAwesomeIcon>
								)}
								{props.ai && <FontAwesomeIcon icon={faMicrochipAi as IconProp} />}
							</div>
						</div>
						{!props.disabled && (
							<div
								className={
									props.unlockable == false
										? 'foldable-card-header card-header card-header-secondary cursor-default'
										: 'foldable-card-header card-header card-header-secondary '
								}
								onClick={handleClick}
							>
								{props.hasLock && isLocked === true && (
									<FontAwesomeIcon icon={faLock} className="icon" />
								)}
								{props.hasLock && isLocked === false && (
									<FontAwesomeIcon icon={faUnlock} className="icon" />
								)}
							</div>
						)}
					</div>
					<div className={classBody + isInActive()} id={regexTitle}>
						<div className="card-body foldable-card-body">{props.content}</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default FoldableCard;
