import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseStatus[]>> {
	const url = '/backend-service/Status';
	return axios.get(url).toOptionalMapped((x) => x.data.statusList);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	statusList: ResponseStatus[];
};

export type ResponseStatus = {
	id: string;
	name: string;
	order: number;
	color: string;
};
