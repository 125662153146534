import axios from 'axios';

import * as Get from 'api/recipe/GetRecipeIdV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(id: string, putObject: Request): Promise<Optional<Get.ResponseData>> {
	const url: string = `/backend-service/Recipe/${id}`;
	return axios.put<Get.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	activityTimes: RequestActivityTime | null;
	calories: number | null;
	categories: string[];
	entryLanguageId: string;
	hasNutriScore: boolean;
	imageId: string | null;
	ingredientsTranslations: Translation | null;
	isSeoActive: boolean;
	notesTranslations: Translation | null;
	nutriScoreCategory: NutriScoreCategory;
	parts: number | null;
	persons: number | null;
	scaledParts: number | null;
	scaledPersons: number | null;
	segmentMedia: RequestSegmentMedia | null;
	seoTranslations: Translation | null;
	shortDescriptionTranslations: Translation | null;
	statusId: string | null;
	stepsTranslations: Translation | null;
	systemStatus: SystemStatus;
	tags: string[];
	titleTranslations: Translation;
	videos: string[] | null;
	weight: number | null;
}

export type RequestSegmentMedia = {
	[key: string]: {
		imageIds: string[];
		videoIds: string[];
	};
};

export type Translation = {
	[key: string]: string;
};

export type RequestActivityTime = {
	[key: string]: {
		timeUnitId: string;
		timeValue: number;
	};
};
