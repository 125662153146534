import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Recipe } from 'api/menu/GetMenuIdV1';
import 'components/desktop/Menu/RecipeList/RecipeList.scss';
import RecipeListItem from 'components/desktop/Menu/RecipeList/RecipeListItem';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';

interface IProps {
	addNewRecipe: () => void;
	recipes: Recipe[];
	selectedRecipe: number;
	setRecipes: (recipes: Recipe[]) => void;
	setSelectedRecipe: (index: number) => void;
}

const RecipeList = (props: IProps) => {
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);

	const handleDelete = (index: number) => {
		const recipesCpy = [...props.recipes];
		recipesCpy.splice(index, 1);
		props.setRecipes(recipesCpy);
	};

	const setSelectedRecipe = (e: any, index: number) => {
		e.preventDefault();
		props.setSelectedRecipe(index);
	};

	const getButtonDataDelete = (input: number): BtnData | undefined => {
		if (menuEditor) {
			return {
				onClick: () => handleDelete(input),
			};
		}
	};

	const renderButtons = (index: number): JSX.Element => {
		return <BtnGroup delete={getButtonDataDelete(index)} />;
	};

	const renderContent = () => {
		return (
			<div className="row recipe-list-container" style={{ rowGap: '30px' }}>
				{props.recipes.map((element, index) => (
					<div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-sm-6" key={index}>
						<RecipeListItem
							buttons={renderButtons(index)}
							recipe={element}
							index={index}
							setSelectedRecipe={setSelectedRecipe}
							isActive={index === props.selectedRecipe}
						/>
					</div>
				))}
				<RenderIf condition={menuEditor}>
					<div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-sm-6">
						<div className="card recipe-card">
							<div className="card-body">
								<div
									className="card-picture-placeholder d-flex justify-content-center align-items-center"
									onClick={props.addNewRecipe}
								>
									<FontAwesomeIcon size="3x" icon={faPlusCircle} />
								</div>
							</div>
						</div>
					</div>
				</RenderIf>
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeList;
