import { style } from 'containers/desktop/Recipe/Print/RecipePrint';
import { roundUpTo } from 'functions/roundUpTo';
import * as Analysis from 'types/Recipe/Print/Analysis/Analysis';
import * as Ingredient from 'types/Recipe/Print/Analysis/Ingredient';

const Ingredients = ({ analysis }: { analysis: Analysis.Type }) => {
	const renderIngredient = (ingredient: Ingredient.Type): JSX.Element => {
		const additionAfter: string = ingredient.additionAfter ?? '';
		if ([','].includes(additionAfter.slice(0, 1))) {
			return (
				<>
					<b>{ingredient.ingredient}</b>
					{ingredient.additionAfter}
				</>
			);
		}
		return (
			<>
				<b>{ingredient.ingredient}</b> {ingredient.additionAfter}
			</>
		);
	};

	const getAmount = (ingredient: Ingredient.Type): string => {
		if (ingredient.quantity) {
			roundUpTo(ingredient.quantity, 2);
		}
		return ingredient.quantityText ?? '';
	};

	const renderIngredients = (): JSX.Element => {
		const trArray: JSX.Element[] = [];
		let key: number = 0;

		const relevantSegments = analysis.segments.filter((e) => {
			return e.ingredients.length;
		});

		for (const segment of relevantSegments) {
			const isLastSegment: boolean =
				relevantSegments.length - 1 === relevantSegments.indexOf(segment);
			for (const ingredient of segment.ingredients) {
				trArray.push(
					<tr key={key} style={{ verticalAlign: 'baseline' }}>
						<td>
							<b>{getAmount(ingredient)}</b>
						</td>
						<td style={{ paddingRight: '5px' }}>
							<b>{ingredient.unit}</b>
						</td>
						<td>{renderIngredient(ingredient)}</td>
					</tr>
				);
				key++;
			}
			if (isLastSegment) continue;
			trArray.push(
				<tr
					key={key}
					style={{
						height: '10px',
						borderBottom: '1px solid #bbb',
						verticalAlign: 'baseline',
					}}
				></tr>
			);
			trArray.push(<tr key={key + 2} style={{ height: '10px' }}></tr>);
			key = key + 3;
		}

		return (
			<>
				<table className="ingredient-table">
					<tbody>
						{trArray.map((e: JSX.Element, _i: number) => {
							return e;
						})}
					</tbody>
				</table>
			</>
		);
	};

	return <div style={style.text}>{renderIngredients()}</div>;
};

export default Ingredients;
