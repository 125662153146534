import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import * as apiPut from 'api/menuPlanner/PutMenuPlannerIdV1';
import ImageList from 'classes/MenuPlan/Detail/ImageList';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Item {
	public id: string = '';
	public type: MENUPLANNERITEMTYPE = MENUPLANNERITEMTYPE.MENU;
	public day: number = 0;
	public images: ImageList = new ImageList();
	public title: LocalizedTranslation<string> | null = null;
	public pinned: boolean = false;
	public amountOfServings: number = 1;

	public constructor(input?: apiGet.Item) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiGet.Item): void {
		this.id = input.id;
		this.type = input.type;
		this.day = input.day;
		this.images.mapFromApi(input.images);
		this.title = input.titleTranslations;
		this.pinned = false;
	}

	public mapToApi(): apiPut.Item {
		return {
			id: this.id,
			type: this.type,
			day: this.day,
		};
	}

	public mapToApiCalculate(total: boolean): apiPostCalculate.Row[] {
		if (!total) return [{ id: this.id, type: this.type }];
		const output: apiPostCalculate.Row[] = [];
		for (let i = 0; i < this.amountOfServings; i++) {
			output.push({
				id: this.id,
				type: this.type,
			});
		}
		return output;
	}
}
