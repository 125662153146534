import axios from 'axios';

import { UnitType } from 'enums/unitType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/Unit/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	searchTerm: string | null;
	showOnlyDeleted: boolean;
	pageIndex: number | null;
	pageSize: number | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseUnit[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type ResponseUnit = {
	id: string;
	type: UnitType;
	factorToMilliliter: number | null;
	factorToGram: number | null;
	factorToOne: number | null;
	nameSingular: string;
	shortNameSingular: string;
	isAirDefinition: boolean;
};
