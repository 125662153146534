import i18n from 'i18next';
import XHR from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(XHR)
	.use(initReactI18next)
	.init({
		lng: 'de',
		fallbackLng: 'en' || 'de',
		keySeparator: false,
		ns: [
			'_general',
			'accessory',
			'admin',
			'alerts',
			'baseIngredient',
			'characteristic',
			'dashboard',
			'header',
			'ingredient',
			'measurments',
			'menu',
			'menuPlan',
			'months',
			'nutriScoreCategory',
			'product',
			'recipe',
			'sortOrder',
			'standingData',
			'systemInfo',
			'systemStatus',
			'user',
		], // enter names of translation json files here
		defaultNS: '_general',
		load: 'currentOnly',
		backend: {
			loadPath: '/language/{{lng}}/{{ns}}.json',
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
