import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<string>> {
	const url: string = '/backend-service/User/Create';
	return axios.post(url, requestBody).toOptionalMapped((x) => x.data);
}

export interface Request {
	user: RequestUser;
	confirmationPageUrl: string;
}

export interface Response {
	data: string;
}

export type RequestUser = {
	firstName: string;
	lastName: string;
	email: string;
	languageId: string;
	profilePictureId?: string;
	nutrients: string[];
	userSettings: UserSettings | undefined;
	roles: string[];
	isActive: boolean;
};

export type UserSettings = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope'?: ValueScope;
};

export enum ValueScope {
	Total = 'Total',
	Person = 'Person',
	Parts = 'Parts',
	HundredGrams = 'HundredGrams',
}
