import { Dispatch, SetStateAction } from 'react';

import MenuPlan, { Check } from 'classes/MenuPlan/Detail/MenuPlan';
import AutoFillSearch from 'components/desktop/MenuPlan/AutoFillSearch/AutoFillSearch';
import * as handlers from 'components/desktop/MenuPlan/AutoFillSearch/AutoFillSearchHandlers';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { useIsHandlerLoaded } from 'hooks/useIsLoading';
import { IAllergenSelectItem } from 'interfaces/IAllergenSelectItem';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';
import { ToggleItem } from 'types/_general/ToggleItem';

interface IProps {
	accessories: IExclusionLogicSelectItem[];
	allergens: IAllergenSelectItem[];
	autoFillCheck: Check | null;
	co2Labels: Co2Label[];
	currency: string;
	displayOverlay: boolean;
	handleAutoFill: () => Promise<void>;
	ingredients: IExclusionLogicSelectItem[];
	isLoading: boolean;
	menuPlan: MenuPlan;
	numberOfItems: number;
	nutriScoreLabels: NutriScoreLabel[];
	rowIndex: number;
	search: Search.Type;
	seasons: ISelectItem[];
	setDisplayOverlay: (input: false) => void;
	setMenuPlan: (input: MenuPlan) => void;
	setSearch: Dispatch<SetStateAction<Search.Type>>;
	status: ToggleItem[];
	tags: ISelectItem[];
}

const DefaultValueComponent = (props: IProps) => {
	const [carbonDioxideHandlerDone, carbonDioxideHandler] = useIsHandlerLoaded<void>(
		handlers.createCarbonDioxideHandler(props.setSearch)
	);
	const [caloriesHandlerDone, caloriesHandler] = useIsHandlerLoaded<void>(
		handlers.createCaloriesHandler(props.setSearch)
	);
	const [accessoriesHandlerDone, acessoriesHandler] = useIsHandlerLoaded<void>(
		handlers.createAccessoriesHandler(props.setSearch)
	);
	const [allergensHandlerDone, allergensHandler] = useIsHandlerLoaded<void>(
		handlers.createAllergensHandler(props.setSearch)
	);
	const [co2HandlerDone, co2Handler] = useIsHandlerLoaded<void>(
		handlers.createCo2Handler(props.setSearch)
	);
	const [ingredientsHandlerDone, ingredientsHandler] = useIsHandlerLoaded<void>(
		handlers.createIngredientsHandler(props.setSearch)
	);
	const [nutriScoreHandlerDone, nutriScoreHandler] = useIsHandlerLoaded<void>(
		handlers.createNutriScoreHandler(props.setSearch)
	);
	const [nutrientsHandlerDone, nutrientsHandler] = useIsHandlerLoaded<void>(
		handlers.createNutrientsHandler(props.setSearch)
	);
	const [priceHandlerDone, priceHandler] = useIsHandlerLoaded<void>(
		handlers.createPriceHandler(props.setSearch)
	);
	const [seasonsHandlerDone, seasonsHandler] = useIsHandlerLoaded<void>(
		handlers.createSeasonsHandler(props.setSearch)
	);
	const [statusHandlerDone, statusHandler] = useIsHandlerLoaded<void>(
		handlers.createStatusHandler(props.setSearch)
	);
	const [tagsHandlerDone, tagsHandler] = useIsHandlerLoaded<void>(
		handlers.createTagsHandler(props.setSearch)
	);
	const [resetAutoFillSearchHandlerDone, resetAutoFillSearchHandler] = useIsHandlerLoaded<void>(
		handlers.createResetAutoFillSearchHandler(props.setSearch)
	);
	const [resetStatusHandlerDone, resetStatusHandler] = useIsHandlerLoaded<void>(
		handlers.createResetStatusHandler(props.setSearch)
	);

	const isLoading = (): boolean => {
		return !(
			carbonDioxideHandlerDone &&
			caloriesHandlerDone &&
			accessoriesHandlerDone &&
			allergensHandlerDone &&
			co2HandlerDone &&
			ingredientsHandlerDone &&
			nutriScoreHandlerDone &&
			nutrientsHandlerDone &&
			priceHandlerDone &&
			seasonsHandlerDone &&
			statusHandlerDone &&
			tagsHandlerDone &&
			resetAutoFillSearchHandlerDone &&
			resetStatusHandlerDone &&
			!props.isLoading
		);
	};

	return (
		<Overlay
			contentStyle={{ maxHeight: '100vh' }}
			containerStyle={{ maxHeight: '100vh', width: '300vh' }}
			handleClose={(): void => props.setDisplayOverlay(false)}
			displayOverlay={props.displayOverlay}
		>
			<AutoFillSearch
				accessories={props.accessories}
				allergens={props.allergens}
				closeOverlay={(): void => props.setDisplayOverlay(false)}
				co2Labels={props.co2Labels}
				currency={props.currency}
				handleChangeAccessories={acessoriesHandler}
				handleChangeAllergens={allergensHandler}
				handleChangeCalories={caloriesHandler}
				handleChangeCarbonDioxide={carbonDioxideHandler}
				handleChangeCo2={co2Handler}
				handleChangeIngredients={ingredientsHandler}
				handleChangeNutriScore={nutriScoreHandler}
				handleChangeNutrients={nutrientsHandler}
				handleChangePrice={priceHandler}
				handleChangeSeasons={seasonsHandler}
				handleChangeStatus={statusHandler}
				handleChangeTags={tagsHandler}
				handleResetSearch={resetAutoFillSearchHandler}
				handleAutoFill={props.handleAutoFill}
				handleResetStatus={resetStatusHandler}
				ingredients={props.ingredients}
				isLoading={isLoading()}
				menuPlan={props.menuPlan}
				nutriScoreLabels={props.nutriScoreLabels}
				rowIndex={props.rowIndex}
				search={props.search}
				seasons={props.seasons}
				setSearch={props.setSearch}
				status={props.status}
				tags={props.tags}
				autoFillCheck={props.autoFillCheck}
			/>
		</Overlay>
	);
};

export default DefaultValueComponent;
