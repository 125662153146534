import React from 'react';
import { useTranslation } from 'react-i18next';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import { LoginDto } from 'types/User/Login/LoginDto';

interface IProps {
	loginDto: LoginDto;
	setLoginDto: (login: LoginDto) => void;
	handleLogin: () => void;
	isLoading: boolean;
}

const LoginForm = (props: IProps) => {
	const { t } = useTranslation();

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			handleLogin();
		}
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		var loginCpy = { ...props.loginDto };
		loginCpy[e.currentTarget.id] = e.currentTarget.value;
		props.setLoginDto(loginCpy);
	};

	const handleLogin = () => {
		props.handleLogin();
	};

	const renderContent = () => {
		return (
			<div className="login-form">
				<h2>LOGIN</h2>
				<label>{t('_general:EMAIL')}</label>
				<input
					id="email"
					type="email"
					value={props.loginDto.email}
					onKeyDown={onKeyDown}
					onChange={handleValueChange}
					className="mb-2 form-control"
					error-key="Email"
				/>
				<label>{t('_general:PASSWORD')}</label>
				<input
					id="password"
					type="password"
					value={props.loginDto.password}
					onKeyDown={onKeyDown}
					onChange={handleValueChange}
					className="mb-2 form-control"
					error-key="Password"
				/>
				<div className="text-end mb-3">
					<a href="/forgotPassword">{t('user:FORGOT_PASSWORD')}</a>
				</div>
				<div className="d-grid gap-2">
					<button
						className="btn btn-primary mb-2"
						onClick={handleLogin}
						disabled={props.isLoading}
					>
						<RenderIf condition={!props.isLoading}>
							<>{t('user:BTN_LOGIN')}</>
						</RenderIf>
						<RenderIf condition={props.isLoading}>
							<LoadingSpinner width={'1em'} height={'1em'} />
						</RenderIf>
					</button>
				</div>
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default LoginForm;
