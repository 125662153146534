import { composeWithDevTools } from '@redux-devtools/extension';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reportWebVitals from 'reportWebVitals';

import { configAxios } from 'axiosConfig';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import App from 'containers/App';
import 'css/Main.scss';
import 'functions/arrayExtensions';
import 'functions/promiseExtensions';
import { rootReducer } from 'reducers/rootReducer';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 10 });
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

configAxios();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<Suspense fallback={<LoadingAnimation isLoading={true} />}>
		<Provider store={store}>
			<App />
		</Provider>
	</Suspense>
);

reportWebVitals();
