import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';
import SliderWithId from 'classes/_general/Search/SliderWithId/SliderWithId';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

type Range = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export default class SliderWithIdList implements IAdvancedSearchKey {
	public all: SliderWithId[] = [];

	public reset() {
		for (const i of this.all) {
			i.reset();
		}
	}

	public mapFromApi(input: postMenuSearchV1.MinMaxNutrientRange[]) {
		for (const i of input) {
			const sliderWithId: SliderWithId | undefined = this.getSlider(i.nutrientInfo.id);
			if (sliderWithId) {
				sliderWithId.mapFromApi(i.range, i.nutrientInfo);
			} else {
				const slider = new SliderWithId(
					i.nutrientInfo.id,
					i.nutrientInfo.name ?? '',
					i.nutrientInfo.unitShortNameSingular ?? ''
				);
				slider.mapFromApi(i.range, i.nutrientInfo);
				this.all.push(slider);
			}
		}
	}

	public mapToApi(): Range[] {
		return this.all.map((e: SliderWithId) => {
			return e.mapToApi();
		});
	}

	public mapFromUrl(input: QueryParams): void {
		this.all = [];
		for (const [key, value] of Object.entries(input)) {
			if (key.length === 36) {
				if (typeof value !== 'boolean') {
					const split: string[] = value.split(';');
					const sliderWithId: SliderWithId = new SliderWithId(
						key,
						split[0] ?? '',
						split[1] ?? ''
					);
					sliderWithId.mapFromUrl(input);
					this.all.push(sliderWithId);
				}
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.hasNoValues()) return null;
		const output: string[] = [];
		this.all.forEach((e: SliderWithId) => {
			const urlPart: string | null = e.mapToUrl();
			if (urlPart !== null) {
				output.push(urlPart);
			}
		});
		return output.join('&');
	}

	public getSlider(id: string): SliderWithId | undefined {
		return this.all.find((e: SliderWithId) => {
			return e.id === id;
		});
	}

	private hasNoValues(): boolean {
		for (const i of this.all) {
			if (!i.hasNoValues()) {
				return false;
			}
		}
		return true;
	}
}
