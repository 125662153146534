export type MailTemplateAdvancedSearchDto = {
	searchTerm: string;
	pageIndex: number;
	pageSize: number;
	id: string | null;
};

export const defaultMailTemplateAdvancedSearchDto = (): MailTemplateAdvancedSearchDto => {
	return {
		searchTerm: '',
		pageIndex: 0,
		pageSize: 12,
		id: null,
	};
};
