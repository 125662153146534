import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'components/desktop/Recipe/LanguageInput/RecipeNutrients.scss';
import { ValueScope } from 'enums/valueScope';
import { setReduxUserSetting } from 'features/UserSettingsFeature';
import { useThunkDispatch } from 'features/featuresHelper';
import { RootState } from 'reducers/rootReducer';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

type Checkbox = {
	key: ValueScope;
	label: string;
};

const RecipeNutrientCheckboxes = () => {
	const { t } = useTranslation();
	const reduxColumns: ValueScope[] = useSelector(
		(state: RootState) => state.userSettings['Recipe.Nutrient.Columns']
	);
	const reduxUserSettings: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);
	const dispatch = useThunkDispatch();

	const checkboxes: Checkbox[] = [
		{
			key: ValueScope.Parts,
			label: t('measurments:PER_PART'),
		},
		{
			key: ValueScope.Person,
			label: t('measurments:PER_PERSON'),
		},
		{
			key: ValueScope.HundredGrams,
			label: t('measurments:PER_100_G'),
		},
		{
			key: ValueScope.Total,
			label: t('_general:TOTAL'),
		},
	];

	const handleChange = async (key: ValueScope): Promise<void> => {
		const newSettings: UserSettings.Type = UserSettings.setRecipeNutrientColumns(
			reduxUserSettings,
			key
		);
		dispatch(setReduxUserSetting(newSettings));
		await UserSettings.putToApi(newSettings);
	};

	return (
		<div className="d-flex" style={{ gap: '15px' }}>
			{checkboxes.map((e, i) => {
				return (
					<div className="form-check" key={i}>
						<input
							className="form-check-input"
							type="checkbox"
							value=""
							id={e.key}
							onChange={() => handleChange(e.key)}
							checked={reduxColumns.includes(e.key)}
						/>
						<label className="form-check-label" htmlFor={e.key}>
							{e.label}
						</label>
					</div>
				);
			})}
		</div>
	);
};

export default RecipeNutrientCheckboxes;
