import axios from 'axios';

import { NutriScoreCategoryBaseIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryBaseIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<string>> {
	const url = '/backend-service/NutriScoreBaseIngredientCategoryPoints';
	return axios.post(url, requestBody).toOptionalMapped((x) => x.data);
}

export interface Request {
	baseIngredientCategoryId: string;
	pointType: NutriScoreCategoryBaseIngredientCategory;
	points?: Point[];
	isPositive: boolean;
}

export interface Response {
	data: string;
}

export type Point = {
	points: number;
	minValue: number | null;
};
