import {
	ActivityTime,
	ActivityTimeLight,
	ActivityTimesLight,
} from 'types/ActivityTime/ActivityTime';
import { Image } from 'types/Media/Image';
import { RequestSegmentMediaCollection } from 'types/Media/SegmentMedia/RequestSegmentMediaCollection';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';
import { SegmentMediaCollection } from 'types/Media/SegmentMedia/SegmentMediaCollection';
import { Category, Recipe } from 'types/Recipe/Recipe';
import { SaveRecipeDto } from 'types/Recipe/SaveRecipeDto';
import { TagLight } from 'types/Tag/TagLight';

export function mapToSaveRecipeDto(input: Recipe): SaveRecipeDto {
	const recipe: Recipe = cleanUpRecipeObject(input);

	return {
		activityTimes: mapActivityTimes(recipe.activityTimes),
		calories: recipe.calories,
		categories: mapCategories(recipe.categories),
		entryLanguageId: recipe.entryLanguage?.id ?? '',
		hasNutriScore: recipe.hasNutriScore,
		imageId: recipe.image?.id ?? null,
		images: mapImages(recipe.images),
		ingredientIncorporationShares: input.incorporationShares,
		ingredientsTranslations: recipe.ingredientsTranslations,
		isSeoActive: recipe.isSeoActive,
		notesTranslations: recipe.notesTranslations,
		nutriScoreCategory: recipe.nutriScoreCategory,
		parts: recipe.parts,
		persons: recipe.persons,
		scaledParts: recipe.scaledParts,
		scaledPersons: recipe.scaledPersons,
		segmentMedia: mapSegmentMedia(recipe.segmentMedia),
		seoTranslations: recipe.seoTranslations,
		shortDescriptionTranslations: recipe.shortDescriptionTranslations,
		statusId: recipe.status?.id ?? null,
		stepsTranslations: recipe.stepsTranslations,
		systemStatus: recipe.systemStatus,
		tags: mapTags(recipe.tags),
		titleTranslations: recipe.titleTranslations,
		videos: recipe.videos.map((x) => x.id) ?? null,
		weight: recipe.weight,
	};
}

function mapImages(images: Image[]): string[] {
	var output: string[] = [];

	for (const image of images.filter((x) => x.id)) {
		output.push(image.id!);
	}

	return output;
}

function mapSegmentMedia(medias: SegmentMediaCollection): RequestSegmentMediaCollection {
	const output: RequestSegmentMediaCollection = {} as RequestSegmentMediaCollection;

	for (const segmentMedia in medias) {
		const media = medias[segmentMedia];

		output[segmentMedia] = { imageIds: [], videoIds: [] };

		for (const segmentImage in media.images) {
			const image: SegmentMedia = media.images[segmentImage];

			if (!image.id) {
				continue;
			}

			output[segmentMedia].imageIds.push(image.id);
		}

		for (const segmentVideo in media.videos) {
			const video: SegmentMedia = media.videos[segmentVideo];

			if (!video.id) {
				continue;
			}

			output[segmentMedia].videoIds.push(video.id);
		}
	}

	return output;
}

function mapCategories(categories: Category[]): string[] {
	var output: string[] = [];

	for (const category of categories) {
		output.push(category.id);
	}

	return output;
}

function mapTags(tags: TagLight[]): string[] {
	var output: string[] = [];

	for (const tag of tags) {
		output.push(tag.id);
	}

	return output;
}

function mapActivityTimes(activityTimes: ActivityTime[]): ActivityTimesLight {
	const output: ActivityTimesLight = {};
	for (const activityTime of activityTimes) {
		output[activityTime.id] = {} as ActivityTimeLight;
		output[activityTime.id].timeUnitId = activityTime.timeUnit.id;
		output[activityTime.id].timeValue = activityTime.timeValue;
	}
	return output;
}

function cleanUpRecipeObject(recipe: Recipe): Recipe {
	const obj = { ...recipe } as any;
	for (const key in obj) {
		for (const subKey in obj[key]) {
			if (obj[key][subKey] === '') {
				delete obj[key][subKey];
			}
		}
	}
	return obj;
}
