import ProductionMethodManagementComponent from 'components/desktop/StandingData/ProductionMethod/Management/ProductionMethodManagementComponent';

const ProductionMethodManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ProductionMethodManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ProductionMethodManagementContainer;
