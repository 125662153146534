import CarbonDioxideLabelManagementComponent from 'components/desktop/StandingData/CarbonDioxideLabel/Management/CarbonDioxideLabelManagementComponent';

const CarbonDioxideLabelManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<CarbonDioxideLabelManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default CarbonDioxideLabelManagementContainer;
