import i18next from 'i18next';

import { Characteristic } from 'enums/characteristic';
import { ValueScope } from 'enums/valueScope';

export type ValueScopeInfo = {
	valueScope: keyof typeof ValueScope | null;
	persons: number | null;
	parts: number | null;
	weight: number | null;
};

export function getValueScopeLabel(
	valueScope: keyof typeof ValueScope | null,
	characteristic?: Characteristic | null,
	useSlash: boolean = false
): string {
	switch (valueScope) {
		case ValueScope.Person:
			return useSlash
				? '/' + i18next.t('_general:PERSON')
				: i18next.t('measurments:PER_PERSON');
		case ValueScope.Parts:
			return useSlash ? '/' + i18next.t('_general:PART') : i18next.t('measurments:PER_PART');
		case ValueScope.HundredGrams:
			return useSlash
				? '/100' +
						(characteristic === Characteristic.Fluid
							? i18next.t('measurments:MILLILITERS')
							: i18next.t('measurments:GRAMM'))
				: characteristic === Characteristic.Fluid
				? i18next.t('measurments:PER_100_ML')
				: i18next.t('measurments:PER_100_G');
		case ValueScope.Total:
		default:
			return useSlash ? '' : i18next.t('_general:TOTAL');
	}
}

export function convertToValueScope(
	value: number | null,
	valueScopeInfo: ValueScopeInfo | null
): number {
	if (value !== null) {
		if (valueScopeInfo?.valueScope) {
			switch (valueScopeInfo.valueScope) {
				case ValueScope.Person:
					return value / (valueScopeInfo.persons ?? 1);
				case ValueScope.Parts:
					return value / (valueScopeInfo.parts ?? 1);
				case ValueScope.HundredGrams:
					return (value / (valueScopeInfo?.weight ?? 1)) * 100;
				default:
					return value;
			}
		}
		return value;
	} else {
		return 0;
	}
}
