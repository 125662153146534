import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/TimeUnit/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	factorToSecond: number;
	shortNamePluralTranslations: Translation;
	shortNameSingularTranslations: Translation;
};

export type Translation = {
	[key: string]: string;
};

export const defaultResponseData = (): ResponseData => {
	return {
		id: '',
		namePluralTranslations: {},
		nameSingularTranslations: {},
		factorToSecond: 0,
		shortNamePluralTranslations: {},
		shortNameSingularTranslations: {},
	};
};
