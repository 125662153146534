import { Placement } from '@popperjs/core';
import uniqueId from 'lodash/uniqueId';
import { useRef } from 'react';
import { Tooltip as BsTooltip, OverlayTrigger } from 'react-bootstrap';

import 'components/desktop/_general/Tooltip/Tooltip.scss';

type Delay =
	| number
	| {
			show: number;
			hide: number;
	  };

type TriggerType = 'hover' | 'click' | 'focus';

interface IProps {
	children: JSX.Element;
	content: string | JSX.Element;
	placement?: Placement;
	delay?: Delay;
	trigger?: TriggerType | TriggerType[];
	show?: boolean;
}

const Tooltip = (props: IProps) => {
	const id = useRef(uniqueId('tooltip-'));

	return (
		<OverlayTrigger
			placement={props.placement}
			delay={props.delay}
			trigger={props.trigger}
			show={props.show}
			overlay={<BsTooltip id={id.current}>{props.content}</BsTooltip>}
		>
			{props.children}
		</OverlayTrigger>
	);
};

export default Tooltip;
