import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string | undefined,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseRecipe[]>> {
	const url: string = '/backend-service/Recipe/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.recipes);
}

interface Parameter {
	SearchTerm?: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	recipes: ResponseRecipe[];
};

export type ResponseRecipe = {
	id: string;
	description: string;
};

export type RecipeCategory = {
	id: string;
	name: string;
};
