const Header = (): JSX.Element => {
	return (
		<div
			className={'d-flex align-items-center justify-content-end'}
			style={{
				marginBottom: '10px',
				paddingBottom: '5px',
				gap: '5px',
				borderBottom: '1px solid #eee',
			}}
		>
			<div style={{ fontSize: '12px', color: 'grey' }}>AI Recipes</div>
			<img
				src="/img/logo/logo_sm.png"
				alt=""
				height="20"
				className="d-inline-block align-text-middle"
			/>
		</div>
	);
};

export default Header;
