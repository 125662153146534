import * as api from 'api/recipe/PostRecipeSearchV1';
import NullableRecipeSortOrder from 'classes/Recipe/Search/NullableRecipeSortOrder';
import AccessoryList from 'classes/_general/Search/Accessory/AccessoryList';
import { AllergenSelect } from 'classes/_general/Search/AllergenSelect';
import Co2Label from 'classes/_general/Search/Co2Label/Co2Label';
import Co2LabelList from 'classes/_general/Search/Co2Label/Co2LabelList';
import ExclusionMultiSelect from 'classes/_general/Search/ExclusionMultiSelect';
import IngredientList from 'classes/_general/Search/Ingredient/IngredientList';
import { MultiSelect } from 'classes/_general/Search/MultiSelect';
import NullableNumber from 'classes/_general/Search/NullableNumber';
import NullableString from 'classes/_general/Search/NullableString';
import NutriScoreLabel from 'classes/_general/Search/NutriScoreLabel/NutriScoreLabel';
import NutriScoreLabelList from 'classes/_general/Search/NutriScoreLabel/NutriScoreLabelList';
import { NutriScoreLabelSelect } from 'classes/_general/Search/NutriScoreLabelSelect';
import RecipeCategoryList from 'classes/_general/Search/RecipeCategory/RecipeCategoryList';
import SeasonList from 'classes/_general/Search/Season/SeasonList';
import Slider from 'classes/_general/Search/Slider';
import SliderWithIdList from 'classes/_general/Search/SliderWithId/SliderWithIdList';
import TagList from 'classes/_general/Search/Tag/TagList';
import ToggleList from 'classes/_general/Search/Toggle/ToggleList';
import { RecipeSortOrder } from 'enums/SortOrder/RecipeSortOrder';
import ENTITLEMENTS from 'enums/entitlements';
import { QueryParams } from 'functions/getQueryParams';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Search {
	public recipeIds: string[] = [];
	public pageIndex: NullableNumber = new NullableNumber('pi', 0);
	public pageSize: NullableNumber = new NullableNumber('ps', 12);
	public searchTerm: NullableString = new NullableString('st');
	public sortOrder: NullableRecipeSortOrder = new NullableRecipeSortOrder(
		'so',
		RecipeSortOrder.LastEditedDateDesc
	);
	public showOnlyDeleted: boolean = false;

	public selectAllergen: AllergenSelect = new AllergenSelect('alg');
	public selectCo2Label: MultiSelect<Co2LabelList, Co2Label> = new MultiSelect(
		Co2LabelList,
		'co2'
	);
	public selectCategory: MultiSelect<RecipeCategoryList, ISelectItem> = new MultiSelect(
		RecipeCategoryList,
		'rca'
	);
	public selectSeason: MultiSelect<SeasonList, ISelectItem> = new MultiSelect(SeasonList, 'sea');
	public selectTag: MultiSelect<TagList, ISelectItem> = new MultiSelect(TagList, 'tag');
	public selectAccessory: ExclusionMultiSelect<AccessoryList, IExclusionLogicSelectItem> =
		new ExclusionMultiSelect(AccessoryList, 'acc');
	public selectIngredient: ExclusionMultiSelect<IngredientList, IExclusionLogicSelectItem> =
		new ExclusionMultiSelect(IngredientList, 'ing');
	public selectNutriScoreLabel: NutriScoreLabelSelect<NutriScoreLabelList, NutriScoreLabel> =
		new NutriScoreLabelSelect(NutriScoreLabelList, 'nsl');

	public sliderCalories: Slider = new Slider('cal');
	public sliderCo2: Slider = new Slider('co2');
	public sliderCost: Slider = new Slider('cost');
	public sliderNutrient: SliderWithIdList = new SliderWithIdList();

	public toggleListStatus: ToggleList = new ToggleList('stat');

	public async initialize() {
		await this.selectCategory.initialize();
		await this.selectAccessory.initialize();
		await this.selectAllergen.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE]))
			await this.selectCo2Label.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.NUTRI_SCORE]))
			await this.selectNutriScoreLabel.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON]))
			await this.selectSeason.initialize();
		await this.selectIngredient.initialize();
		await this.selectTag.initialize();
		await this.toggleListStatus.initialize();
	}

	public getPageIndex(): number {
		return this.pageIndex.get();
	}

	public getPageSize(): number {
		return this.pageSize.get();
	}

	public getSortOrder(): RecipeSortOrder {
		return this.sortOrder.value ?? this.sortOrder.defaultValue;
	}

	public setSearchTerm(input: string) {
		this.searchTerm.value = input;
		this.sortOrder.changeToRelevanceIfValueIsNull();
		this.pageIndex.value = 0;
	}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset();
			}
		}
		this.recipeIds = [];
	}

	public mapFromApi(input: api.ResponseData) {
		this.sliderCost.mapFromApi(input.minMaxPriceRange);
		this.sliderCo2.mapFromApi(input.minMaxCarbonDioxideRange);
		this.sliderCalories.mapFromApi(input.minMaxCaloriesRange);
		this.sliderNutrient.mapFromApi(input.minMaxNutrientRanges);
	}

	public mapToRequest(): api.Request {
		return {
			accessories: this.selectAccessory.mapToApi(),
			allergenIds: this.selectAllergen.mapToApi(),
			caloriesRange: this.sliderCalories.mapToApi(),
			carbonDioxideLabelIds: this.selectCo2Label.mapToApi(),
			carbonDioxideRange: this.sliderCo2.mapToApi(),
			categoryIds: this.selectCategory.mapToApi(),
			ingredients: this.selectIngredient.mapToApi(),
			nutriScoreLabelIds: this.selectNutriScoreLabel.mapToApi(),
			nutrientRanges: this.sliderNutrient.mapToApi(),
			pageIndex: this.pageIndex.mapToApi(),
			pageSize: this.pageSize.mapToApi(),
			priceRange: this.sliderCost.mapToApi(),
			recipeIds: this.recipeIds,
			searchTerm: this.searchTerm.mapToApi(),
			seasonIds: this.selectSeason.mapToApi(),
			showOnlyDeleted: this.showOnlyDeleted,
			sortOrder: this.sortOrder.mapToApi(),
			statusIds: this.toggleListStatus.mapToApi(),
			tagIds: this.selectTag.mapToApi(),
		};
	}

	public mapRecipeIdsToUrl(): string | null {
		if (this.recipeIds.length === 0) return null;
		return 're=' + this.recipeIds.join(';');
	}

	public mapRecipeIdsFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === 're') {
				if (typeof value === 'string') {
					for (const i of value.split(';')) {
						this.recipeIds.push(i);
					}
				}
			}
		}
	}

	public mapFromUrl(input: QueryParams): void {
		this.mapRecipeIdsFromUrl(input);
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input);
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = [];
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl());
			}
		}
		output.push(this.mapRecipeIdsToUrl());
		return this.mapUrlArrayToString(output);
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e;
			}
		});
		return '?' + outputFiltered.join('&');
	}
}
