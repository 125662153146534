import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/Recipe/Archive/RecipeArchive';
import Detail from 'containers/desktop/Recipe/Detail/RecipeDetail';
import New from 'containers/desktop/Recipe/New/RecipeNew';
import Print from 'containers/desktop/Recipe/Print/RecipePrint';
import Search from 'containers/desktop/Recipe/Search/RecipeSearch';
import Validation from 'containers/desktop/Recipe/Validation/RecipeValidation';
import PERMISSIONS from 'enums/permissions';

const IngredientIdParamsName = 'ingredientId';
const MenuIdParamsName = 'menuId';
const MenuPlanIdParamsName = 'menuPlanId';
const MenuPlanRowIndexParamsName = 'menuPlanRowIndex';
const MenuPlanDayParamsName = 'menuPlanDay';

const publicRoutes = [
	{
		path: '/recipe/print/:id',
		element: <Print />,
	},
];

const protectedRoutes = [
	{
		path: '/recipe/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READRECIPE],
	},
	{
		path: '/recipe/search',
		element: <Search />,
		permissions: [PERMISSIONS.READRECIPE],
	},
	{
		path: `/recipe/search/:${IngredientIdParamsName}`,
		element: <Search />,
		permissions: [PERMISSIONS.READRECIPE],
	},
	{
		path: `/recipe/search/menu/:${MenuIdParamsName}`,
		element: <Search />,
		permissions: [PERMISSIONS.READRECIPE],
	},
	{
		path: `/recipe/search/menuPlan/:${MenuPlanIdParamsName}/:${MenuPlanRowIndexParamsName}/:${MenuPlanDayParamsName}`,
		element: <Search />,
		permissions: [PERMISSIONS.READRECIPE],
	},
	{
		path: '/recipe/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITERECIPE],
	},
	{
		path: '/recipe/copy/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.WRITERECIPE],
	},
	{
		path: '/recipe/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITERECIPE],
	},
	{
		path: '/recipe/validation',
		element: <Validation />,
		permissions: [PERMISSIONS.READRECIPE],
	},
];

export default [...publicRoutes, ...protectedRoutes.map(wrapProtectedRoute)];
