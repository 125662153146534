import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/baseIngredientCategory/GetBaseIngredientCategoryIdV1';
import BaseIngredientCategory from 'classes/StandingData/BaseIngredientCategory/BaseIngredientCategory';
import BaseIngredientCategoryList from 'classes/StandingData/BaseIngredientCategory/Search/BaseIngredientCategoryList';
import BaseIngredientCategoryLanguageInput from 'components/desktop/StandingData/BaseIngredientCategory/LanguageInput/BaseIngredientCategoryLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getIdFromUrl } from 'functions/url';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

const BaseIngredientCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const isUserAdmin: boolean = arePermissionsInUserPermissions([PERMISSIONS.ADMINISTRATE]);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [baseIngredientCategory, setBaseIngredientCategory] = useState<BaseIngredientCategory>(
		new BaseIngredientCategory()
	);

	const [parentList, setParentList] = useState<BaseIngredientCategoryList>(
		new BaseIngredientCategoryList()
	);
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		await getParents();
		if (id !== null && id !== 'new') {
			await getBaseIngredientCategory(id);
		}
	};

	const getParents = async (): Promise<void> => {
		await parentList.search('', false);
		setParentList(clone(parentList));
	};

	const getBaseIngredientCategory = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() &&
			setBaseIngredientCategory(new BaseIngredientCategory(response.get()));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		baseIngredientCategory.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setBaseIngredientCategory(clone(baseIngredientCategory));
	};

	const handleTranslate = async () => {
		baseIngredientCategory.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveBaseIngredientCategory = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await baseIngredientCategory.callApiPut();
			} else {
				await baseIngredientCategory.callApiPost();
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleParentChange = (id: string) => {
		const parent = parentList.filtered.find((x) => x.id == id);
		if (parent) {
			baseIngredientCategory.parent = { id: parent.id, name: parent.name };
		} else {
			baseIngredientCategory.parent = null;
		}
		setBaseIngredientCategory(clone(baseIngredientCategory));
	};

	const getNutriScoreCategories = (): JSX.Element => {
		return (
			<>
				<option key="-999" value="__default" disabled={true}>
					{t('_general:NO_NUTRI_SCORE_CATEGORY')}
				</option>
				{Object.values(NutriScoreCategory).map((s, i) => (
					<option key={i} value={s}>
						{t('nutriScoreCategory:' + s)}
					</option>
				))}
			</>
		);
	};

	const setNutriScoreCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value: NutriScoreCategory = e.currentTarget.value as NutriScoreCategory;
		baseIngredientCategory.nutriScoreCategory = value;
		setBaseIngredientCategory(clone(baseIngredientCategory));
	};

	const getParentOptions = (): SelectOption[] => {
		const output: SelectOption[] = [];
		output.push({ id: '', text: t('standingData:NOTHING_SELECTED') });
		parentList.filtered.forEach((parent: ISelectItem) => {
			output.push({ id: parent.id, text: parent.name });
		});
		return output;
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{baseIngredientCategory.id ? (
								<>
									<h1>{t('standingData:EDIT_BASEINGREDIENTCATEGORY')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_BASEINGREDIENTCATEGORY')}</h1>
								</>
							)}
						</div>
						<div>
							{isUserAdmin && (
								<BtnSave
									handleSave={() => handleSaveBaseIngredientCategory()}
									isLoading={isLoadingSave}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-lg-4 col-sm-5 col-12">
						<label>{t('standingData:PARENT')}:</label>
						<SelectSimple
							options={getParentOptions()}
							currentValue={baseIngredientCategory.parent?.id ?? null}
							handleValueChange={handleParentChange}
							errorKey="Parent"
						/>
						<br />
						<label>{t('_general:NUTRI_SCORE_CATEGORY')}</label>
						<select
							className="form-select"
							value={baseIngredientCategory.nutriScoreCategory ?? '__default'}
							name="nutriScoreSelect"
							onChange={setNutriScoreCategory}
							error-key="NutriScorecategory"
						>
							{getNutriScoreCategories()}
						</select>
					</div>
					<div className="col-mg-7 col-xl-8 col-sm-7 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<BaseIngredientCategoryLanguageInput
									baseIngredientCategory={baseIngredientCategory}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default BaseIngredientCategoryDetailComponent;
