import 'components/desktop/_general/Loading/LoadingOverarching.scss';

interface IProps {
	isLoading?: boolean;
}

const LoadingOverarching = (props: IProps) => {
	if (props.isLoading) {
		return (
			<div className="loading-screen-overarching">
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ height: '100%' }}
				>
					<div
						className="spinner-border"
						style={{ width: '5rem', height: '5rem' }}
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		);
	}
	return <></>;
};

export default LoadingOverarching;
