import { ComponentVersion, defaultComponentVersion } from 'types/VersionInfo/ComponentVersion';
import { UIVersionInfoDto } from 'types/VersionInfo/UIVersionInfoDto';

export function mapToComponentVersion(uiVersionInfoDto: UIVersionInfoDto): ComponentVersion {
	const output: ComponentVersion = defaultComponentVersion();
	output.componentName = 'AIR UI';
	output.branch = uiVersionInfoDto.git_branch;
	output.buildDateUtc = uiVersionInfoDto.build_date;
	output.buildId = uiVersionInfoDto.build_id;
	output.hash = uiVersionInfoDto.git_hash;
	output.version = uiVersionInfoDto.version;

	return output;
}
