import axios from 'axios';

import { Characteristic } from 'enums/characteristic';
import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Accessory';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	carbonDioxide: CarbonDioxide[];
	categories: string[] | null; // required
	characteristic: Characteristic;
	density: number | null;
	descriptionTranslations: Translation;
	generalInfoTranslations: Translation;
	imageId: string | null;
	isPriceRecipeRelevant: boolean;
	namePluralTranslations: Translation; // required
	nameSingularTranslations: Translation; // required
	price: number | null;
	synonyms: Synonyms;
	tags: string[] | null; // required
	unitWeights: UnitWeights | null;
}

export interface Response {
	data: string;
}

export type Translation = {
	[key: string]: string;
};

export type UnitWeights = {
	[key: string]: number;
};

export type CarbonDioxide = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};
