import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';

export const setReduxCultureCode = (cultureCode: string) => {
	return {
		type: 'cultureCode/SET',
		payload: cultureCode,
	};
};

export type CultureCodeAction = {
	type: string;
	payload: string;
};

const defaultState: string = getCultureCodeFromLocalStorage() ?? 'de-CH';

export const cultureCodeReducer = (
	state: string = defaultState,
	action: CultureCodeAction
): string => {
	switch (action.type) {
		case 'cultureCode/SET':
			return action.payload;
		default:
			return state;
	}
};
