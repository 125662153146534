import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(): Promise<Optional<ResponseCurrency[]>> {
	const url = '/backend-service/Currency/GetAll';
	return axios.get(url).toOptionalMapped((x) => x.data.currencies);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	currencies: ResponseCurrency[];
};

export type ResponseCurrency = {
	id: string;
	name: string;
	currencyCode: string;
};
