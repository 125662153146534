import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAppleCore, faCamera, faKey } from '@fortawesome/pro-duotone-svg-icons';
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { faConveyorBelt } from '@fortawesome/pro-duotone-svg-icons';
import { faCopy } from '@fortawesome/pro-duotone-svg-icons';
import { faDatabase } from '@fortawesome/pro-duotone-svg-icons';
import { faEarthEurope } from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopesBulk } from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faFolders } from '@fortawesome/pro-duotone-svg-icons';
import { faHandDots } from '@fortawesome/pro-duotone-svg-icons';
import { faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import { faScaleBalanced } from '@fortawesome/pro-duotone-svg-icons';
import { faShareNodes } from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBars } from '@fortawesome/pro-duotone-svg-icons';
import { faTag } from '@fortawesome/pro-duotone-svg-icons';
import { faTags } from '@fortawesome/pro-duotone-svg-icons';
import { faTree } from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { faWavePulse } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarWeek } from '@fortawesome/pro-duotone-svg-icons';
import { faCarrot } from '@fortawesome/pro-duotone-svg-icons';
import { faSeedling } from '@fortawesome/pro-duotone-svg-icons';
import { faUtensils } from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerSoda } from '@fortawesome/pro-duotone-svg-icons';
import { faKitchenSet } from '@fortawesome/pro-duotone-svg-icons';
import { faScrewdriverWrench } from '@fortawesome/pro-duotone-svg-icons';
import { faPencil } from '@fortawesome/pro-thin-svg-icons';
import { faSquarePlus } from '@fortawesome/pro-thin-svg-icons';
import { faArchive } from '@fortawesome/pro-thin-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-thin-svg-icons';

import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';

export type SidebarItem = {
	url: string;
	translationKey: string;
	icon: IconProp;
	hidden?: boolean;
	defaultUrl?: string;
	permissions: PERMISSIONS[];
	entitlements: ENTITLEMENTS[];
	children?: SidebarItem[];
};

const SIDEBAR_ITEMS: SidebarItem[] = [
	{
		url: '/dashboard',
		translationKey: 'header:DASHBOARD',
		icon: faChartNetwork as IconProp,
		permissions: [],
		entitlements: [],
	},
	{
		url: '/menu',
		defaultUrl: '/menu/search',
		translationKey: '_general:MENU',
		permissions: [PERMISSIONS.READMENU],
		entitlements: [ENTITLEMENTS.MENU],
		icon: faBurgerSoda as IconProp,
		children: getChildrenMainItems('menu', [PERMISSIONS.WRITEMENU]),
	},
	{
		url: '/menuPlan',
		defaultUrl: '/menuPlan/search',
		translationKey: '_general:MENUPLAN',
		permissions: [PERMISSIONS.READMENUPLAN],
		entitlements: [ENTITLEMENTS.MENU_PLAN],
		icon: faCalendarWeek as IconProp,
		children: getChildrenMainItems('menuPlan', [PERMISSIONS.WRITEMENUPLAN]),
	},
	{
		url: '/recipe',
		defaultUrl: '/recipe/search',
		translationKey: '_general:RECIPES',
		icon: faUtensils as IconProp,
		permissions: [PERMISSIONS.READRECIPE],
		entitlements: [],
		children: [
			...getChildrenMainItems('recipe', [PERMISSIONS.WRITERECIPE]),

			{
				url: '/recipe/validation',
				translationKey: 'header:VALIDATION',
				permissions: [],
				entitlements: [],
				icon: faSquareCheck as IconProp,
			},
			{
				url: '/recipeCategory',
				defaultUrl: '/recipeCategory/management',
				translationKey: '_general:CATEGORIES',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faFolders as IconProp,
				children: getChildrenMasterData('recipeCategory'),
			},
		],
	},
	{
		url: '/ingredient',
		defaultUrl: '/ingredient/search',
		translationKey: '_general:INGREDIENTS',
		permissions: [PERMISSIONS.READINGREDIENT],
		entitlements: [],
		icon: faCarrot as IconProp,
		children: [
			...getChildrenMainItems('ingredient', [PERMISSIONS.WRITEINGREDIENT]),
			{
				url: '/ingredientCategory',
				defaultUrl: '/ingredientCategory/management',
				translationKey: '_general:CATEGORIES',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faFolders as IconProp,
				children: getChildrenMasterData('ingredientCategory'),
			},
		],
	},
	{
		url: '/baseIngredient',
		defaultUrl: '/baseIngredient/search',
		translationKey: '_general:BASEINGREDIENTS',
		permissions: [PERMISSIONS.READBASEINGREDIENT],
		entitlements: [],
		icon: faSeedling as IconProp,
		children: [
			...getChildrenMainItems('baseIngredient', [PERMISSIONS.WRITEBASEINGREDIENT]),

			{
				url: '/baseIngredientCategory',
				defaultUrl: '/baseIngredientCategory/management',
				translationKey: '_general:CATEGORIES',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faFolders as IconProp,
				children: getChildrenMasterData('baseIngredientCategory'),
			},
		],
	},
	{
		url: '/accessory',
		defaultUrl: '/accessory/search',
		translationKey: '_general:ACCESSORIES',
		permissions: [PERMISSIONS.READACCESSORY],
		entitlements: [],
		icon: faKitchenSet as IconProp,
		children: [
			...getChildrenMainItems('accessory', [PERMISSIONS.WRITEACCESSORY]),
			{
				url: '/accessoryCategory',
				defaultUrl: '/accessoryCategory/management',
				translationKey: '_general:CATEGORIES',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faFolders as IconProp,
				children: getChildrenMasterData('accessoryCategory'),
			},
		],
	},
	{
		url: '/masterData',
		icon: faDatabase as IconProp,
		translationKey: 'header:STANDING_DATA',
		permissions: [PERMISSIONS.ADMINISTRATE, PERMISSIONS.READUNIT, PERMISSIONS.WRITEUNIT],
		entitlements: [],
		children: [
			{
				url: '/activity',
				defaultUrl: '/activity/management',
				translationKey: '_general:ACTIVITIES',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.ACTIVITY_TIMES],
				icon: faWavePulse as IconProp,
				children: getChildrenMasterData('activity'),
			},
			{
				url: '/allergen',
				defaultUrl: '/allergen/management',
				translationKey: '_general:ALLERGENS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faHandDots as IconProp,
				children: getChildrenMasterData('allergen'),
			},
			{
				url: '/carbonDioxideLabel',
				defaultUrl: '/carbonDioxideLabel/management',
				translationKey: '_general:CARBONDIOXIDELABELS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.CARBONDIOXIDE],
				icon: faTag as IconProp,
				children: getChildrenMasterData('carbonDioxideLabel'),
			},
			{
				url: '/channel',
				defaultUrl: '/channel/management',
				translationKey: '_general:CHANNELS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faShareNodes as IconProp,
				children: getChildrenMasterData('channel'),
			},
			{
				url: '/nutrient',
				defaultUrl: '/nutrient/management',
				translationKey: '_general:NUTRIENTS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faAppleCore as IconProp,
				children: [
					...getChildrenMasterData('nutrient'),
					{
						url: '/nutrientCategory',
						defaultUrl: '/nutrientCategory/management',
						translationKey: '_general:CATEGORIES',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [],
						icon: faFolders as IconProp,
						children: getChildrenMasterData('nutrientCategory'),
					},
				],
			},
			{
				url: '/nutriScore',
				translationKey: '_general:NUTRISCORE',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.NUTRI_SCORE],
				icon: faTag as IconProp,
				children: [
					{
						url: '/nutriScoreLabel',
						defaultUrl: '/nutriScoreLabel/management',
						translationKey: '_general:NUTRISCORELABELS',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [ENTITLEMENTS.NUTRI_SCORE],
						icon: faTag as IconProp,
						children: getChildrenMasterData('nutriScoreLabel'),
					},
					{
						url: '/nutriScoreNutrients',
						translationKey: '_general:NUTRISCORENUTRIENTS',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [ENTITLEMENTS.NUTRI_SCORE],
						icon: faTag as IconProp,
					},
					{
						url: '/baseIngredientCategoryPoints',
						translationKey: '_general:NUTRISCORE_BASEINGREDIENTCATEGORY',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [ENTITLEMENTS.NUTRI_SCORE],
						icon: faTag as IconProp,
					},
					{
						url: '/ingredientCategoryPoints',
						translationKey: '_general:NUTRISCORE_INGREDIENTCATEGORY',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [ENTITLEMENTS.NUTRI_SCORE],
						icon: faTag as IconProp,
					},
				],
			},

			{
				url: '/originLocation',
				defaultUrl: '/originLocation/management',
				translationKey: '_general:ORIGIN_LOCATION',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faEarthEurope as IconProp,
				children: getChildrenMasterData('originLocation'),
			},
			{
				url: '/productionMethod',
				defaultUrl: '/productionMethod/management',
				translationKey: '_general:PRODUCTION_METHODS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faConveyorBelt as IconProp,
				children: getChildrenMasterData('productionMethod'),
			},
			{
				url: '/season',
				defaultUrl: '/season/management',
				translationKey: '_general:SEASONS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.SEASON],
				icon: faTree as IconProp,
				children: getChildrenMasterData('season'),
			},
			{
				url: '/status',
				defaultUrl: '/status/management',
				translationKey: '_general:STATUS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faSignalBars as IconProp,
				children: getChildrenMasterData('status'),
			},
			{
				url: '/tag',
				defaultUrl: '/tag/management',
				translationKey: '_general:TAGS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faTags as IconProp,
				children: [
					...getChildrenMasterData('tag'),

					{
						url: '/tagCategory',
						defaultUrl: '/tagCategory/management',
						translationKey: '_general:CATEGORIES',
						permissions: [PERMISSIONS.WRITE],
						entitlements: [],
						icon: faFolders as IconProp,
						children: getChildrenMasterData('tagCategory'),
					},
				],
			},
			{
				url: '/timeUnit',
				defaultUrl: '/timeUnit/management',
				translationKey: '_general:TIMEUNITS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.ACTIVITY_TIMES],
				icon: faClock as IconProp,
				children: getChildrenMasterData('timeUnit'),
			},
			{
				url: '/unit',
				defaultUrl: '/unit/management',
				translationKey: '_general:UNITS',
				permissions: [
					PERMISSIONS.ADMINISTRATE,
					PERMISSIONS.READUNIT,
					PERMISSIONS.WRITEUNIT,
				],
				entitlements: [],
				icon: faScaleBalanced as IconProp,
				children: getChildrenMasterData('unit', [
					PERMISSIONS.ADMINISTRATE,
					PERMISSIONS.READUNIT,
					PERMISSIONS.WRITEUNIT,
				]),
			},
		],
	},
	{
		url: '/special',
		translationKey: '_general:USER',
		hidden: true,
		icon: faPencil as IconProp,
		permissions: [],
		entitlements: [],
		children: [
			{
				url: '/user/settings',
				translationKey: '_general:SETTINGS',
				hidden: true,
				icon: faPencil as IconProp,
				permissions: [],
				entitlements: [],
			},
		],
	},
	{
		url: '/admin',
		icon: faScrewdriverWrench as IconProp,
		translationKey: '_general:ADMIN',
		permissions: [PERMISSIONS.ADMINISTRATE],
		entitlements: [],
		children: [
			{
				url: '/user',
				defaultUrl: '/user/search',
				translationKey: '_general:USER_MANAGEMENT',
				permissions: [],
				entitlements: [],
				icon: faUsers as IconProp,
				children: [
					{
						translationKey: 'header:SEARCH',
						url: '/user/search',
						icon: faMagnifyingGlass as IconProp,
						permissions: [PERMISSIONS.ADMINISTRATE],
						entitlements: [],
					},
					{
						translationKey: 'header:CREATE',
						url: '/user/new',
						icon: faSquarePlus as IconProp,
						permissions: [PERMISSIONS.ADMINISTRATE],
						entitlements: [],
					},
				],
			},
			{
				url: '/errorMessage',
				defaultUrl: '/errorMessage/management',
				translationKey: '_general:ERRORMESSAGES',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faTriangleExclamation as IconProp,
				children: getChildrenMasterData('errorMessage'),
			},
			{
				url: '/language',
				defaultUrl: '/language/management',
				translationKey: '_general:LANGUAGES',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [ENTITLEMENTS.LANGUAGES],
				icon: faLanguage as IconProp,
				children: getChildrenMasterData('language'),
			},
			{
				url: '/licensing',
				defaultUrl: '/licensing',
				translationKey: '_general:LICENCING',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faKey as IconProp,
			},
			{
				url: '/logoUpload',
				defaultUrl: '/logoUpload',
				translationKey: '_general:EXTERNAL_LOGO_UPLOAD',
				permissions: [PERMISSIONS.ADMINISTRATE],
				entitlements: [],
				icon: faCamera as IconProp,
			},
			{
				url: '/mailTemplate',
				defaultUrl: '/mailTemplate/management',
				translationKey: '_general:MAIL_TEMPLATES',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faCopy as IconProp,
			},
			{
				url: '/mailAccount',
				defaultUrl: '/mailAccount/management',
				translationKey: '_general:MAIL_ACCOUNTS',
				permissions: [PERMISSIONS.WRITE],
				entitlements: [],
				icon: faEnvelopesBulk as IconProp,
				children: getChildrenMasterData('mailAccount'),
			},
		],
	},
];

export default SIDEBAR_ITEMS;

function getChildrenMainItems(id: string, permissions: PERMISSIONS[]): SidebarItem[] {
	return [
		{
			translationKey: 'header:SEARCH',
			url: `/${id}/search`,
			icon: faMagnifyingGlass as IconProp,
			permissions: [],
			entitlements: [],
			children: [],
		},
		{
			translationKey: 'header:CREATE',
			url: `/${id}/new`,
			permissions: permissions,
			entitlements: [],
			icon: faSquarePlus as IconProp,
		},
		{
			translationKey: '_general:EDIT',
			url: `/${id}/detail`,
			hidden: true,
			icon: faPencil as IconProp,
			permissions: [],
			entitlements: [],
			children: [],
		},
		{
			translationKey: 'header:ARCHIVE',
			url: `/${id}/archive`,
			permissions: permissions,
			entitlements: [],
			icon: faArchive as IconProp,
			children: [],
		},
	];
}

function getChildrenMasterData(id: string, permissions?: PERMISSIONS[]): SidebarItem[] {
	return [
		{
			translationKey: 'header:MANAGEMENT',
			url: `/${id}/management`,
			permissions: permissions ? permissions : [PERMISSIONS.ADMINISTRATE],
			entitlements: [],
			icon: faMagnifyingGlass as IconProp,
			children: [],
		},
		{
			translationKey: 'header:ARCHIVE',
			url: `/${id}/archive`,
			permissions: permissions ? permissions : [PERMISSIONS.ADMINISTRATE],
			entitlements: [],
			icon: faArchive as IconProp,
			children: [],
		},
	];
}
