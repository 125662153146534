import Archive from 'containers/desktop/StandingData/Nutrient/Archive/NutrientArchive';
import Management from 'containers/desktop/StandingData/Nutrient/Management/NutrientManagement';
import PERMISSIONS from 'enums/permissions';

export const nutrientRoutes = [
	{
		path: '/nutrient/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/nutrient/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
