import UrlParams from 'enums/Product/Search/UrlParams';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';

export type Type = string[];

export function create(): Type {
	return [];
}

export function addOrRemove(obj: Type, input: string): Type {
	const found: string | undefined = obj.find((e) => e === input);
	if (found) {
		return obj.filter((e) => e !== input);
	}
	return [...obj, input];
}

export function mapToUrl(
	obj: Type,
	params: AirUrlParamsClass,
	urlParam: UrlParams
): AirUrlParamsClass {
	if (!obj.length) return params.delete(urlParam);
	return params.add(urlParam, obj.join(';'));
}
