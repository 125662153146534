const Footer = () => {
	const margin: string = '14px';

	const style: React.CSSProperties = {
		fontSize: '0.8em',
		color: '#666',
		marginTop: '-3px',
	};

	return (
		<div id="footer-container">
			<div className="container">
				<hr style={{ margin: '0px' }} />
			</div>
			<div
				className="container d-flex align-items-center"
				style={{
					gap: margin,
					paddingTop: margin,
					paddingBottom: margin,
				}}
			>
				<img
					src="/img/logo/logo_sm.png"
					alt=""
					height="40"
					className="d-inline-block align-text-middle"
				/>
				<div style={style}>Artificial Intelligence Recipes</div>
				<div style={style}>│</div>
				<div style={style}>Demoversion / Copyright: PrePress &amp; Multimedia AG</div>
			</div>
		</div>
	);
};

export default Footer;
