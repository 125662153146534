import { useTranslation } from 'react-i18next';

const RenderPasswordInformation = (): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div
			style={{
				fontSize: '.85em',
				backgroundColor: '#fff3cd',
				color: '#664d03',
				padding: '5px 10px 7px 10px',
				border: '1px solid #ddd',
			}}
		>
			<b>{t('PASSWORD_NEEDS_TO_BE')}</b>
			<ul style={{ marginBottom: '0', paddingLeft: '.8rem' }}>
				<li>{t('PASSWORD_VERIFICATION_6_SINGS')}</li>
				<li>{t('PASSWORD_VERIFICATION_UPPERCASE')}</li>
				<li>{t('PASSWORD_VERIFICATION_LOWERCASE')}</li>
				<li>{t('PASSWORD_VERIFICATION_1_NUMBER')}</li>
				<li>{t('PASSWORD_VERIFICATION_1_SPECIAL_CHAR')}</li>
			</ul>
		</div>
	);
};

export default RenderPasswordInformation;
