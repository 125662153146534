import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faChessBoard, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import BaseIngredient from 'classes/BaseIngredient/Search/BaseIngredient';
import Search from 'classes/Ingredient/Search/Search';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { Icon } from 'types/Icon/Icon';

interface IProps {
	archive: boolean;
	baseIngredient: BaseIngredient;
	handleDeleteBaseIngredient: (input: BaseIngredient) => Promise<void>;
	handleRestoreBaseIngredient: (input: BaseIngredient) => Promise<void>;
}

const SearchResultListRow = (props: IProps) => {
	const navigate = useNavigate();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);

	const onIngredientLinkClick = async (id: string, name: string, e: any) => {
		e.preventDefault();
		const ingredientAdvancedSearch = new Search();
		ingredientAdvancedSearch.selectBaseIngredient.save({ id: id, name: name } as ISelectItem);
		const url: string | null = ingredientAdvancedSearch.mapToUrl();
		if (url) navigate(`/ingredient/search${url}`);
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(
			ValueScope.HundredGrams,
			props.baseIngredient.characteristic,
			true
		);
	};

	const getAuthor = (): string => {
		if (props.baseIngredient.lastEditor) {
			return props.baseIngredient.lastEditor;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const getIngredientLink = (): JSX.Element => {
		if (props.baseIngredient.countOfIngredients > 0) {
			if (arePermissionsInUserPermissions([PERMISSIONS.READINGREDIENT])) {
				return (
					<span
						className="primary"
						onClick={(e: any) =>
							onIngredientLinkClick(
								props.baseIngredient.id,
								props.baseIngredient.name,
								e
							)
						}
						style={{ zIndex: 1000 }}
					>
						{`${props.baseIngredient.countOfIngredients} ${t('_general:INGREDIENT', {
							count: props.baseIngredient.countOfIngredients,
						})}`}
					</span>
				);
			} else {
				return (
					<>
						{`${props.baseIngredient.countOfIngredients} ${t('_general:INGREDIENT', {
							count: props.baseIngredient.countOfIngredients,
						})}`}
					</>
				);
			}
		}
		return (
			<>
				{`0	${t('_general:INGREDIENTS', {
					count: props.baseIngredient.countOfIngredients,
				})}`}
			</>
		);
	};

	const getPicture = (): JSX.Element => {
		if (props.baseIngredient.image) {
			return (
				<div className="card-picture">
					<img alt="thumbnail" src={props.baseIngredient.image.url} />
				</div>
			);
		} else {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		}
	};

	const getCalories = (): JSX.Element => {
		if (props.baseIngredient.calories) {
			return (
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faUtensils as IconProp} />
					</span>
					<b>{getNumberString(props.baseIngredient.calories, reduxCultureCode, true)}</b>
					{` ${t('_general:CALORIES')} ${getValueScope()}`}
				</p>
			);
		}
		return <></>;
	};

	const getDensity = (): JSX.Element => {
		if (props.baseIngredient.density) {
			return (
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faChessBoard as IconProp} />
					</span>
					<b>{getNumberString(props.baseIngredient.density, reduxCultureCode)}</b>
					{` ${t('measurments:GRAMMS_PER_QUBIC_CM')}`}
				</p>
			);
		}
		return <></>;
	};

	const getIcons = (): Icon[] => {
		const output: Icon[] = [
			...props.baseIngredient.tags.mapToIcons(),
			...props.baseIngredient.allergens.mapToIcons(),
		];
		return output;
	};

	const getButtonDataRestore = (): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => props.handleRestoreBaseIngredient(props.baseIngredient),
			};
		}
	};

	const getButtonDataDelete = (): BtnData | undefined => {
		if (!props.archive && baseIngredientEditor) {
			return {
				onClick: () => props.handleDeleteBaseIngredient(props.baseIngredient),
			};
		}
	};

	const getButtonDataEdit = (): BtnData | undefined => {
		if (!props.archive && baseIngredientEditor) {
			return {
				onClick: handleBaseIngredientDetailClick,
			};
		}
	};

	const getButtonDataView = (): BtnData | undefined => {
		if (!props.archive && !baseIngredientEditor) {
			return {
				onClick: handleBaseIngredientDetailClick,
			};
		}
	};

	const handleBaseIngredientDetailClick = () => {
		navigate(`/baseIngredient/detail/${props.baseIngredient.id}`);
	};

	const renderButtons = (): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit()}
				delete={getButtonDataDelete()}
				restore={getButtonDataRestore()}
				view={getButtonDataView()}
			/>
		);
	};

	if (props.archive) {
		return (
			<div className="recipe-table-item">
				{getPicture()}

				<div id="column-first">
					<div id="title">
						<strong>{props.baseIngredient.name}</strong>
					</div>
					<div id="info">
						{getCalories()}
						{getDensity()}
					</div>
				</div>
				<div id="column-second">
					<div className="icon-column justify-content-start">
						<SeasonalityBar
							seasonalityCode={props.baseIngredient.seasons.getSeasonalityCode()}
						/>
					</div>
					<div className="row">
						<div className="col-5">
							<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
							<br />
							<span className="grey">{t('_general:LAST_EDITOR')}:</span>
							<br />
						</div>
						<div className="col-7">
							<FormatedDate date={props.baseIngredient.lastEditedDateUtc} />
							<br />
							<span>{getAuthor()}</span>
						</div>
					</div>
				</div>
				<div id="column-last">
					{props.baseIngredient.hasNutriScore && props.baseIngredient.nutriScore && (
						<div className="justify-content-end">
							<NutriScoreDiagram nutriScore={props.baseIngredient.nutriScore} />
						</div>
					)}
					<div id="icon-rows">
						{getIcons()
							.slice(0, 4)
							.map((icon: Icon, i: number) => (
								<IconComponent icon={icon} key={i} />
							))}
						<IconCo2Round
							color={props.baseIngredient.carbonDioxideLabel.color!}
							label={props.baseIngredient.carbonDioxideLabel.label!}
						/>
					</div>
					<div>{renderButtons()}</div>
				</div>
			</div>
		);
	} else {
		return (
			<Link
				to={`/baseIngredient/detail/${props.baseIngredient.id}`}
				className="unstyled-link"
			>
				<div className="recipe-table-item">
					{getPicture()}

					<div id="column-first">
						<div id="title">
							<strong>{props.baseIngredient.name}</strong>
						</div>
						<div id="info">
							{getIngredientLink()}
							<br />
							{getCalories()}
							{getDensity()}
						</div>
					</div>
					<div id="column-second">
						<div className="icon-column justify-content-start">
							<SeasonalityBar
								seasonalityCode={props.baseIngredient.seasons.getSeasonalityCode()}
							/>
						</div>
						<div className="row">
							<div className="col-5">
								<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
								<br />
								<span className="grey">{t('_general:LAST_EDITOR')}:</span>
								<br />
							</div>
							<div className="col-7">
								<FormatedDate date={props.baseIngredient.lastEditedDateUtc} />
								<br />
								<span>{getAuthor()}</span>
							</div>
						</div>
					</div>
					<div id="column-last">
						{props.baseIngredient.hasNutriScore && props.baseIngredient.nutriScore && (
							<div className="justify-content-end">
								<NutriScoreDiagram nutriScore={props.baseIngredient.nutriScore} />
							</div>
						)}
						<div id="icon-rows">
							{getIcons()
								.slice(0, 4)
								.map((icon: Icon, i: number) => (
									<IconComponent icon={icon} key={i} />
								))}
							<IconCo2Round
								color={props.baseIngredient.carbonDioxideLabel.color!}
								label={props.baseIngredient.carbonDioxideLabel.label!}
							/>
						</div>
						<div>{renderButtons()}</div>
					</div>
				</div>
			</Link>
		);
	}
};

export default SearchResultListRow;
