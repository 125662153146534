import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, postObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/Activity/${id}`;
	return axios.put(url, postObject).toOptionalVoid();
}

export interface Request {
	sortOrder: number;
	nameTranslations: {
		[key: string]: string;
	};
}
