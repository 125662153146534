import { ISelectItem } from 'interfaces/ISelectItem';
import { SeasonLight } from 'types/Season/SeasonLight';

export type SeasonMappingDto = {
	id: string;
	name: string;
	iconSvgUrl: string | null;
	display: boolean;
	associatedMonthsFlag: number | null;
	source: string | null;
};

export const defaultSeasonMappingDto = (): SeasonMappingDto => {
	return {
		id: '',
		name: '',
		iconSvgUrl: null,
		display: false,
		associatedMonthsFlag: null,
		source: null,
	};
};

export const mapISelectItemToSeasonMappingDto = (input: ISelectItem): SeasonMappingDto => {
	return {
		id: input.id,
		name: input.name,
		iconSvgUrl: input.iconSvgUrl ?? null,
		display: input.display ?? false,
		associatedMonthsFlag: null,
		source: null,
	};
};

export const mapSeasonLightToSeasonMappingDto = (input: SeasonLight): SeasonMappingDto => {
	return {
		id: input.id,
		name: input.name,
		iconSvgUrl: input.iconSvgUrl ?? null,
		display: input.display ?? false,
		associatedMonthsFlag: input.associatedMonthsFlag ?? null,
		source: null,
	};
};
