import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/BaseIngredient';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	density: number | null;
	calories: number | null;
	imageId: string | null;
	characteristic: Characteristic;
	hasNutriScore: boolean;
	nutriScoreCategoryIsSetByUser: boolean;
	nutriScoreCategory: NutriScoreCategory | null;
	nameTranslations: {
		[key: string]: string;
	};
	nutrientValues: {
		[key: string]: number;
	};
	unitWeights: {
		[key: string]: number;
	};
	seasons: string[];
	allergens: {
		[key: string]: AllergenLogic;
	};
	tags: string[];
	categories: string[];
	carbonDioxide: CarbonDioxideValue[];
	scalingFactor: number;
}

export interface Response {
	data: string;
}

export type CarbonDioxideValue = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};
