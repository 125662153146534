export type Co2Footprint = {
	origin: string;
	originLocationId: string;
	productionMethod: string;
	productionMethodId: string;
	value: number;
	source: string | null;
};

export const defaultCo2Footprint = (): Co2Footprint => {
	return {
		origin: '',
		originLocationId: '',
		productionMethod: '',
		productionMethodId: '',
		value: 0,
		source: null,
	};
};
