import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class Allergen {
	public allergen: string = '';
	public logic: 'Positive' | 'Negative' | 'MayContain' = 'MayContain';
	public iconSvgUrl: string | null = null;

	public constructor(input?: api.Allergens) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.Allergens) {
		this.allergen = input.allergen;
		this.logic = input.logic;
		this.iconSvgUrl = input.iconSvgUrl;
	}
}
