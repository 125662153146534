import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	showOnlyDeleted: boolean
): Promise<Optional<ResponseSeason[]>> {
	const url: string = '/backend-service/Season/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.seasons);
}

interface Parameter {
	SearchTerm: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	seasons: ResponseSeason[];
};

export type ResponseSeason = {
	id: string;
	name: string;
	iconSvgUrl: string;
	display: boolean;
	associatedMonthsFlag: number;
};
