export enum IngredientType {
	Ingredient = 'Ingredient',
	Accessory = 'Accessory',
}

export const mapIngredientTypeFromString = (x: 'Ingredient' | 'Accessory'): IngredientType => {
	if (x === 'Accessory') return IngredientType.Accessory;

	return IngredientType.Ingredient;
};
