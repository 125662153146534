import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	showOnlyDeleted: boolean,
	cultureCode?: string
): Promise<Optional<ResponseIngredient[]>> {
	const url: string = '/backend-service/Ingredient/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	const config = cultureCode
		? {
				params: params,
				headers: { 'Accept-Language': cultureCode },
		  }
		: {
				params: params,
		  };
	return axios.get(url, config).toOptionalMapped((x) => x.data.ingredients);
}

interface Parameter {
	SearchTerm: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	ingredients: ResponseIngredient[];
};

export type ResponseIngredient = {
	id: string;
	description: string;
};
