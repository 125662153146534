import AllergenSuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/AllergenSuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { IAllergenSelectItem } from 'interfaces/IAllergenSelectItem';

type Display = 'top' | 'bottom';

interface IProps {
	savedItems: IAllergenSelectItem[];
	displaySavedItems: Display;
	setSavedItems: (savedItems: IAllergenSelectItem[]) => void;
	handleChangeSearchTerm: (searchTerm: string) => void;
	suggestedItems: IAllergenSelectItem[];
	label?: string;
	backgroundColor?: string;
	triggerSearchLetterAmount?: number;
	className?: string;
	debounceMilliSeconds?: number;
	disabled?: boolean;
	errorKey?: string;
}

const AllergenMultiSearchSelect = (props: IProps) => {
	const addToSavedItems = (item: IAllergenSelectItem): void => {
		const check = props.savedItems.find((x) => x.id === item.id);

		if (!check) {
			const items = [...props.savedItems];
			items.push(item);
			props.setSavedItems(items);
		}
	};

	const handleRemoveItem = (id: string): void => {
		var savedItemsCpy = [...props.savedItems];
		var index = savedItemsCpy.findIndex((element) => element.id == id);

		savedItemsCpy.splice(index, 1);

		props.setSavedItems(savedItemsCpy);
	};

	const createHandleRemoveItem = (id: string) => {
		return () => {
			var savedItemsCpy = [...props.savedItems];
			var index = savedItemsCpy.findIndex((element) => element.id == id);

			savedItemsCpy.splice(index, 1);

			props.setSavedItems(savedItemsCpy);
		};
	};

	const getSuggestedItems = (): IAllergenSelectItem[] => {
		const output: IAllergenSelectItem[] = [];

		for (const suggestedItem of props.suggestedItems) {
			let found: boolean = false;
			for (const savedItem of props.savedItems) {
				if (savedItem.id === suggestedItem.id) found = true;
			}
			if (!found) output.push(suggestedItem);
		}
		return output;
	};

	return (
		<div className={props.className ? 'row ' + props.className : 'row'}>
			{props.label && <label>{props.label}</label>}
			<div className="col-12">
				{props.displaySavedItems === 'top' && (
					<>
						{props.savedItems.map((item) => (
							<div
								className="d-block d-flex justify-content-between select-saveditems"
								key={item.id}
							>
								{item.name}
								<span
									onClick={() => handleRemoveItem(item.id)}
									style={{ display: props.disabled ? 'none' : 'inherit' }}
								>
									<i className="bi bi-x" />
								</span>
							</div>
						))}
					</>
				)}
				<div className="input-group" style={{ display: props.disabled ? 'none' : 'flex' }}>
					<AllergenSuggestionSearchInput
						className="form-control"
						handleChangeSearchTerm={props.handleChangeSearchTerm}
						setSavedItem={addToSavedItems}
						triggerSearchLetterAmount={props.triggerSearchLetterAmount}
						suggestedItems={getSuggestedItems()}
						debounceMilliSeconds={props.debounceMilliSeconds}
						errorKey={props.errorKey}
					/>
				</div>
				{props.displaySavedItems === 'bottom' && (
					<div className="mt-2 overflow-auto">
						{props.savedItems.map((item: IAllergenSelectItem, i: number) => (
							<ListItem
								key={i}
								text={item.name}
								title={item.title ?? undefined}
								backgroundColor={props.backgroundColor}
								iconSvgUrl={item.iconSvgUrl}
								handleDelete={
									props.disabled ? undefined : createHandleRemoveItem(item.id)
								}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default AllergenMultiSearchSelect;
