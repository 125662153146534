import * as apiDelete from 'api/carbonDioxideLabel/DeleteCarbonDioxideLabelIdV1';
import * as apiRestore from 'api/carbonDioxideLabel/PostCarbonDioxideLabelRestoreV1';
import * as apiPostSearch from 'api/carbonDioxideLabel/PostCarbonDioxideLabelSearchV1';

export default class CarbonDioxideLabel {
	public id: string = '';
	public label: string = '';
	public maxValue: number | null = null;
	public color: string = '';

	public constructor(input?: apiPostSearch.ResponseCarbonDioxideLabel) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseCarbonDioxideLabel) {
		this.id = input.id;
		this.label = input.label;
		this.maxValue = input.maxValue;
		this.color = input.color;
	}
}
