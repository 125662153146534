export const setReduxMenuBarOrder = (menuBarOrder: string[]) => {
	return {
		type: 'menuBarOrder/SET',
		payload: menuBarOrder,
	};
};

export type MenuBarOrderAction = {
	type: string;
	payload: string[];
};

export const menuBarOrderReducer = (
	state: string[] = [
		'Recipe',
		'Menu',
		'MenuPlan',
		'Ingredient',
		'BaseIngredient',
		'Accessory',
		'MasterData',
		'Admin',
	],
	action: MenuBarOrderAction
): string[] => {
	switch (action.type) {
		case 'menuBarOrder/SET':
			return action.payload;
		default:
			return state;
	}
};
