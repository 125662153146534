import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/productionMethod/GetProductionMethodIdV1';
import ProductionMethod from 'classes/StandingData/ProductionMethod/ProductionMethod';
import ProductionMethodLanguageInput from 'components/desktop/StandingData/ProductionMethod/LanguageInput/ProductionMethodLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const ProductionMethodDetailComponent = () => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');
	const [method, setMethod] = useState<ProductionMethod>(new ProductionMethod());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getProductionMethod(id);
		}
	};

	const getProductionMethod = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setMethod(new ProductionMethod(x)));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		method.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setMethod(clone(method));
	};

	const handleTranslate = async () => {
		method.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveMethod = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await method.callApiPut();
			} else {
				await method.callApiPost();
				if (!method.id) return;
				setId(method.id);
				setIdToUrl(method.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{method.id ? (
								<>
									<h1>{t('standingData:EDIT_PRODUCTION_METHOD')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_PRODUCTION_METHOD')}</h1>
								</>
							)}
						</div>
						<div style={{ paddingLeft: '50px' }}>
							<BtnSave
								handleSave={() => handleSaveMethod()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-mg-12 col-xl-12 col-sm-12 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<ProductionMethodLanguageInput
									method={method}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ProductionMethodDetailComponent;
