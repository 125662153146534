import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function apiCall(
	nutrientId: string,
	pointType: NutriScoreCategoryIngredientCategory
): Promise<Optional<void>> {
	const url: string = `/backend-service/NutriScoreIngredientCategoryPoints/${nutrientId}`;
	return axios
		.delete(url, {
			params: {
				PointType: pointType,
			},
		})
		.toOptionalVoid();
}
