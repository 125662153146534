import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';

import * as apiGetActivityStartingWith from 'api/activity/GetActivityStartingWithV1';
import * as apiGetTimeUnitStartingWith from 'api/timeUnit/GetTimeUnitStartingWithV1';
import 'components/desktop/Recipe/ActivityTimeEditor/ActivityTimeEditor.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import PERMISSIONS from 'enums/permissions';
import getActivityTimeIcon from 'functions/getActivityTimeIcon';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { useLanguageDependentApi } from 'hooks/useLanguageDependentApi';
import { ActivityLight } from 'types/Activity/ActivityLight';
import { ActivityTime } from 'types/ActivityTime/ActivityTime';
import { Recipe } from 'types/Recipe/Recipe';
import { TimeUnitLight } from 'types/TimeUnit/TimeUnitLight';

interface IProps {
	activityTimes: ActivityTime[];
	handleSetRecipeAttribute: (key: keyof Recipe, value: ActivityTime[]) => void;
}

const getCurrentActivities = (
	activityTimes: ActivityTime[],
	activities: ActivityLight[]
): ActivityLight[] => {
	const alreadyUsedActivityIds: string[] = activityTimes.map((activityTime: ActivityTime) => {
		return activityTime.id;
	});
	return activities.filter((activity: ActivityLight) => {
		if (!alreadyUsedActivityIds.includes(activity.id)) {
			return activity;
		}
	});
};

const ActivityTimeEditor = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const [apiActivities] = useLanguageDependentApi([], () =>
		apiGetActivityStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([]))
	);

	const [timeUnits] = useLanguageDependentApi([], () =>
		apiGetTimeUnitStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([]))
	);

	const allActivitiesById = useMemo(() => {
		return apiActivities.toDictionary(
			(k) => k.id,
			(k) => k
		);
	}, [apiActivities]);

	function getActivityById(id: string): apiGetActivityStartingWith.ResponseActivity | null {
		return allActivitiesById[id];
	}

	const [timeValue, setTimeValue] = useState<number>(0);
	const [selectedActivity, setSelectedActivity] = useState<number>(0);
	const [selectedTimeUnit, setSelectedTimeUnit] = useState<number>(0);

	const activities: ActivityLight[] = getCurrentActivities(props.activityTimes, apiActivities);

	useEffect((): void => {
		setTimeValue(0);
		setSelectedActivity(0);
		setSelectedTimeUnit(0);
	}, [props.activityTimes]);

	const isDisabled = (): boolean => {
		return !activities.length;
	};

	const handleAddActivityTime = () => {
		if (!isDisabled()) {
			const newActivityTimes: ActivityTime[] = [...props.activityTimes];
			newActivityTimes.push({
				id: activities[selectedActivity].id,
				name: activities[selectedActivity].description,
				timeUnit: {
					id: timeUnits[selectedTimeUnit].id,
					description: timeUnits[selectedTimeUnit].description,
				},
				timeValue: timeValue,
			});
			props.handleSetRecipeAttribute('activityTimes', newActivityTimes);
		}
	};

	const handleDeleteActivityTime = (activityTime: ActivityTime) => {
		const newActivityTimes: ActivityTime[] = [];
		for (const i of props.activityTimes) {
			if (i.id !== activityTime.id) {
				newActivityTimes.push(i);
			}
		}
		props.handleSetRecipeAttribute('activityTimes', newActivityTimes);
	};

	const handleValueChange = (value: string): void => {
		setTimeValue(Number(value));
	};

	const handleSelectActivity = (e: any) => {
		setSelectedActivity(e.currentTarget.value);
	};

	const handleSelectTimeUnit = (e: any) => {
		setSelectedTimeUnit(e.currentTarget.value);
	};

	const renderSelectTimeUnits = (): JSX.Element => {
		return (
			<select
				className="form-select"
				disabled={isDisabled()}
				name="timeUnitSelect"
				onChange={(e: any) => handleSelectTimeUnit(e)}
				value={selectedTimeUnit}
				error-key={'TimeUnit'}
			>
				{timeUnits.map((unit: TimeUnitLight, key: number) => (
					<option key={key} value={String(key)}>
						{unit.description}
					</option>
				))}
			</select>
		);
	};

	const renderSelectActivities = (): JSX.Element => {
		return (
			<select
				className="form-select"
				disabled={isDisabled()}
				name="activitySelect"
				onChange={(e: any) => handleSelectActivity(e)}
				value={selectedActivity}
				error-key={'Activity'}
			>
				{activities.map((activity: ActivityLight, key: number) => (
					<option key={key} value={String(key)}>
						{activity.description}
					</option>
				))}
			</select>
		);
	};

	const renderInputTimeValue = (): JSX.Element => {
		return (
			<InputFieldSimple
				disabled={isDisabled()}
				defaultValue={String(timeValue)}
				validateRegex={/^[0-9]{1,}(\.[0-9]{1,}){0,1}$/}
				handleValueChange={handleValueChange}
			/>
		);
	};

	const renderTableRow = (activityTime: ActivityTime, key: number): JSX.Element => {
		const timeunit = getActivityById(activityTime.timeUnit.id);
		const activity = getActivityById(activityTime.id);
		return (
			<tr key={key} error-key={'ActivityTimes[' + key + ']'}>
				<td>{getActivityTimeIcon(activityTime.name)}</td>
				<td>
					<span className="table-activity-time-editor-span">
						{activityTime.timeValue}
					</span>
				</td>
				<td className="grey">
					{timeunit ? timeunit.description : activityTime.timeUnit.description}
				</td>
				<td>{activity ? activity.description : activityTime.name}</td>
				<td className="table-activity-time-editor-td-trash-can">
					<RenderIf condition={recipeEditor}>
						<FontAwesomeIcon
							className="trash-can"
							icon={faTrashCan}
							onClick={() => handleDeleteActivityTime(activityTime)}
						/>
					</RenderIf>
				</td>
			</tr>
		);
	};

	const renderPlusButton = () => {
		return (
			<span
				className="input-group-text select-button btn-primary"
				id="basic-addon1"
				onClick={handleAddActivityTime}
			>
				<FontAwesomeIcon icon={faPlus} />
			</span>
		);
	};

	return (
		<>
			<RenderIf condition={recipeEditor}>
				<div className="form-group">
					<label>{t('recipe:TIMES')}</label>
					<div className="input-group">
						{renderInputTimeValue()}
						{renderSelectTimeUnits()}
						{renderSelectActivities()}
						{renderPlusButton()}
					</div>
				</div>
			</RenderIf>
			<div style={{ overflow: 'auto' }}>
				<table
					className="table table-activity-time-editor"
					style={{ backgroundColor: 'white', overflowX: 'auto' }}
				>
					<tbody>
						{Array.from(props.activityTimes).map(
							(activityTime: ActivityTime, i: number) => {
								return renderTableRow(activityTime, i);
							}
						)}
					</tbody>
				</table>
			</div>
			{!recipeEditor && props.activityTimes.length === 0 && (
				<>
					<span className="grey">{t('recipe:NO_ACTIVITY_TIMES')}</span>
				</>
			)}
		</>
	);
};

export default ActivityTimeEditor;
