import MenuPlanComponent from 'components/desktop/MenuPlan/MenuPlanComponent';

const MenuPlanNewContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<MenuPlanComponent />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuPlanNewContainer;
