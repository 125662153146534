import { useTranslation } from 'react-i18next';

import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import { ValueScope } from 'enums/valueScope';

interface Props {
	valueScope: keyof typeof ValueScope | null;
	changeRecipeListValueScope: (input: ValueScope) => void;
}

type RecipeListValueScope = {
	enumName: keyof typeof ValueScope;
	translation: string;
};

const ChangeRecipeListValueScope = (props: Props) => {
	const { t } = useTranslation();

	const recipeListValueScope: RecipeListValueScope[] = [
		{ enumName: 'Total', translation: t('_general:TOTAL') },
		{ enumName: 'Person', translation: t('measurments:PER_PERSON') },
		{ enumName: 'Parts', translation: t('measurments:PER_PART') },
		{ enumName: 'HundredGrams', translation: t('measurments:PER_100_G') },
	];

	return (
		<CardTransparent
			title={t('user:UNIT_IN_RECIPESEARCH')}
			className="mb-3"
			content={
				<>
					{recipeListValueScope.map((e: any, i: number) => (
						<div className="form-check" key={i}>
							<input
								className="form-check-input"
								type="radio"
								name={'searchPreference' + i}
								id={'searchPreference' + i}
								checked={props.valueScope === e.enumName}
								onChange={() => props.changeRecipeListValueScope(e.enumName)}
							/>
							<label className="form-check-label" htmlFor={'searchPreference' + i}>
								{e.translation}
							</label>
						</div>
					))}
				</>
			}
		/>
	);
};

export default ChangeRecipeListValueScope;
