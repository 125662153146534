import * as apiGet from 'api/nutriScoreBaseIngredientCategoryPoints/GetNutriScoreBaseIngredientCategoryPointsIdV1';
import { BaseIngredientCategoryPoint } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPoint';

export const mapFromRequest = (input: apiGet.ResponseData): BaseIngredientCategoryPoint => {
	return {
		baseIngredientCategoryId: input.baseIngredientCategoryId,
		pointType: input.pointType,
		baseIngredientCategoryName: input.baseIngredientCategoryName,
		isPositive: input.isPositive,
		points: input.points ?? [],
	};
};
