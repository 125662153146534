import { useLocation, useParams } from 'react-router-dom';

import MenuComponent from 'components/desktop/Menu/MenuComponent';

const MenuDetailContainer = () => {
	const { id } = useParams();
	const location = useLocation();

	const renderContent = () => {
		return (
			<main className="container">
				<MenuComponent id={id} copy={location.pathname.includes('/copy') ? true : false} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuDetailContainer;
