import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

interface IProps {
	link?: string;
	onClick?: () => void;
	name: string;
}

const BtnNew = (props: IProps) => {
	if (props.link !== undefined) {
		return (
			<NavLink to={props.link} className="d-flex align-items-center flex-gap-10">
				<div>{props.name}</div>
				<FontAwesomeIcon
					icon={faPlusCircle as IconProp}
					style={{ width: '35px', height: '35px' }}
				/>
			</NavLink>
		);
	}
	if (props.onClick !== undefined) {
		return (
			<div
				onClick={props.onClick}
				className="d-flex align-items-center flex-gap-10 link-primary"
				style={{ cursor: 'pointer' }}
			>
				<div>{props.name}</div>
				<FontAwesomeIcon
					icon={faPlusCircle as IconProp}
					style={{ width: '35px', height: '35px' }}
				/>
			</div>
		);
	}
	return <></>;
};

export default BtnNew;
