import * as apiGetStartingWithV1 from 'api/allergen/GetAllergenStartingWithV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { assoc } from 'functions/objectExtensions';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import * as Allergen from 'types/_general/Store/Allergen';

export type Type = Allergen.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGetStartingWithV1.callApi('', false);
	return response.getOrDefault([]);
}

export function search(obj: Type, searchTerm: string): Type {
	return obj.filter((e: Allergen.Type) =>
		e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
	);
}

export function mapToAllergenLightList(obj: Type): AllergenLight[] {
	return obj.map((e) => {
		return { ...e, iconSvgUrl: e.iconSvgUrl ?? null };
	});
}

export function mapToUniqIdList(obj: Type): Type {
	return obj.map((e) => {
		return {
			...e,
			id: Allergen.createId(e),
			icon: e.iconSvgUrl,
		};
	});
}

export function getFromIdArray(obj: Type, ids: string[]): AllergenLight[] {
	const output = obj.filter((e) => {
		return ids.includes(e.id + ':' + e.logic);
	});
	return mapToAllergenLightList(output);
}

export function mapFromUniqIdList(input: Type): { [key: string]: AllergenLogic } {
	return input.reduce(
		(a, i) => {
			const output = Allergen.decreateId(i);
			return assoc(a, output.id, output.logic);
		},
		{} as { [key: string]: AllergenLogic }
	);
}
