import i18next from 'i18next';

export type DataSet = {
	[key: string]: any;
	label: string;
	data: number[];
	backgroundColor: string[];
	borderColor: string[];
	borderWidth: number;
};

export type BarChartData = {
	[key: string]: any;
	labels: string[];
	datasets: DataSet[];
};

export const defaultDataSet = (): DataSet => {
	return {
		label: '',
		data: [] as number[],
		backgroundColor: [] as string[],
		borderColor: [] as string[],
		borderWidth: 1,
	};
};

export const defaultModifiedResourcesBarChartData = (): BarChartData => {
	return {
		labels: [] as string[],
		datasets: [
			{
				label: i18next.t('_general:BASEINGREDIENTS'),
				data: [] as number[],
				backgroundColor: [] as string[],
				borderColor: [] as string[],
				borderWidth: 1,
			} as DataSet,
			{
				label: i18next.t('_general:INGREDIENTS'),
				data: [] as number[],
				backgroundColor: [] as string[],
				borderColor: [] as string[],
				borderWidth: 1,
			} as DataSet,
			{
				label: i18next.t('_general:RECIPES'),
				data: [] as number[],
				backgroundColor: [] as string[],
				borderColor: [] as string[],
				borderWidth: 1,
			} as DataSet,
		],
	};
};
