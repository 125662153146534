import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Language/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	cultureCode: string;
	sortOrder: number;
	isoCode6391: string;
	isoCode6393: string;
	hasArticleBeforeIngredientName: boolean;
	translationCode: string | null;
	nameTranslations: {
		[key: string]: string;
	};
};
