import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/AiFunctions/Scaling/CalculateRecipe';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export type Request = {
	originalQuantity: number;
	desiredQuantity: number;
	cultureCode: string; // required
	segments: Segments[];
};

export type Segments = {
	index: number;
	name: string | null;
	instruction: string | null;
	ingredients: Ingredients[];
	highlightings: Highlightings[];
};

export type Ingredients = {
	quantityText: string | null;
	quantity: number | null;
	maxQuantity: number | null;
	unit: string | null;
	unitId: string | null;
	additionBefore: string | null;
	ingredient: string | null;
	ingredientId: string | null;
	additionAfter: string | null;
	isFluid: boolean | null;
	scalingFactor: number | null;
	type: 'Ingredient' | 'Accessory';
	status: Status;
	errorMessages: string[];
	errorActions: string[];
};

export type Status = {
	quantity: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
	unit: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
	ingredient: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
};

export type Highlightings = {
	word: string;
	position: Position;
	isListed: boolean;
	type: 'Ingredient' | 'Accessory';
};

export type Position = {
	startIndex: number;
	endIndex: number;
};

export type Response = {
	data: ResponseData; // required
};

export type ResponseData = {
	segments: ResSegments[];
};

export type ResSegments = {
	index: number;
	name: string | null;
	instruction: string | null;
	ingredients: ResIngredients[];
	highlightings: ResHighlightings[];
};

export type ResIngredients = {
	quantityText: string | null;
	quantity: number | null;
	maxQuantity: number | null;
	unit: string | null;
	unitId: string | null;
	additionBefore: string | null;
	ingredient: string | null;
	ingredientId: string | null;
	additionAfter: string | null;
	isFluid: boolean | null;
	scalingFactor: number | null;
	type: 'Ingredient' | 'Accessory';
	status: Status;
	errorMessages: string[];
	errorActions: string[];
};

export type ResStatus = {
	quantity: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
	unit: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
	ingredient: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy';
};

export type ResHighlightings = {
	word: string;
	position: Position;
	isListed: boolean;
	type: 'Ingredient' | 'Accessory';
};

export type ResPosition = {
	startIndex: number;
	endIndex: number;
};
