import { NutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';

export function mapUrlToAdvancedSearch(params: URLSearchParams): NutriScoreNutrientsAdvancedSearch {
	return {
		searchTerm: params.get('st') ?? '',
		pageIndex: Number(params.get('pi')),
		pageSize: Number(params.get('ps')),
		id: params.get('id') === 'null' ? null : params.get('id'),
	};
}
