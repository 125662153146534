import yaml from 'js-yaml';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import versionInfoYml from 'versioninfo.yml';

import * as apiGetVersionInfo from 'api/version/GetVersionV1';
import SystemInfoComponent from 'components/desktop/SystemInfo/SystemInfoComponent';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { mapToComponentVersion } from 'functions/mappers/UIVersionInfo/mapToVersionInfo';
import { RootState } from 'reducers/rootReducer';
import { ComponentVersion, defaultComponentVersion } from 'types/VersionInfo/ComponentVersion';
import { UIVersionInfoDto } from 'types/VersionInfo/UIVersionInfoDto';
import { VersionInfo, defaultVersionInfo } from 'types/VersionInfo/VersionInfo';

const SystemInfoContainer = () => {
	const [versionInfo, setVersionInfo] = useState<VersionInfo>(defaultVersionInfo());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	useEffect(() => {
		if (reduxCultureCode) {
			initialize();
		}
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		await getVersionInfo();
		setIsLoading(false);
	};

	const getVersionInfo = async (): Promise<void> => {
		const response = await apiGetVersionInfo.callApi();
		const uiVersionInfo = await getUIVersionInfo();
		let output: VersionInfo = { ...versionInfo };
		if (response.hasValue()) {
			output = response.get();
			output.componentVersions.push(uiVersionInfo);
			setVersionInfo(output);
		}
	};

	const getUIVersionInfo = async (): Promise<ComponentVersion> => {
		let componentVersion: ComponentVersion = defaultComponentVersion();
		try {
			const response = await fetch(versionInfoYml);
			const text = await response.text();
			const data = yaml.load(text);
			const uiVersionInfo: UIVersionInfoDto = data as UIVersionInfoDto;
			componentVersion = mapToComponentVersion(uiVersionInfo);
		} catch (error) {
			console.error('Error loading YAML file:', error);
		}
		return componentVersion;
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<SystemInfoComponent versionInfo={versionInfo} />
		</>
	);
};

export default SystemInfoContainer;
