import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	ingredient: string,
	unit: string,
	density: number,
	characteristic: Characteristic | null,
	cultureCode: string
): Promise<Optional<number>> {
	const url = '/backend-service/AiFunctions/IngredientUnitWeightByText/';
	const params: Parameter = {
		CultureCode: cultureCode,
		Ingredient: ingredient,
		Density: density,
		Characteristic: characteristic,
		Unit: unit,
	};
	return axios
		.get(url, { params: params })
		.toOptionalMapped((x) => Math.floor(x.data * 100) / 100);
}

interface Parameter {
	CultureCode: string;
	Ingredient: string;
	Density: number;
	Characteristic: Characteristic | null;
	Unit: string;
}

export enum Characteristic {
	Fluid = 'Fluid',
	SolidQuantifiable = 'SolidQuantifiable',
	SolidNonQuantifiable = 'SolidNonQuantifiable',
}
