import * as api from 'api/nutriScoreNutrientPoints/GetNutriScoreNutrientPointsListPointsGroupedByNutrientAndMethodV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { NutriScoreNutrientLight } from 'types/NutriScoreNutrient/NutriScoreNutrientLight';

export const mapFromRequest = (input: api.NutriScoreNutrient[]): NutriScoreNutrientLight[] => {
	return input.map((e: api.NutriScoreNutrient) => {
		return {
			nutrientId: e.nutrientId,
			pointType: e.pointType as NutriScoreCategory,
			nutrientName: e.nutrientName,
			isPositive: e.isPositive,
			isProtein: e.isProtein,
			points: e.points,
		};
	});
};
