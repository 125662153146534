import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import UserSearch from 'containers/desktop/User/Admin/Search/UserSearch';
import UserDetail from 'containers/desktop/User/Detail/UserDetail';
import Login from 'containers/desktop/User/Login/Login';
import UserNew from 'containers/desktop/User/New/UserNew';
import ForgotPassword from 'containers/desktop/User/PasswordRecovery/ForgotPassword';
import LinksSentConfirmation from 'containers/desktop/User/PasswordRecovery/LinkSentConfirmation';
import ResetPassword from 'containers/desktop/User/PasswordRecovery/PasswordReset';
import ResendConfirmation from 'containers/desktop/User/ResendConfirmation/ResendConfirmation';
import UserSettings from 'containers/desktop/User/Settings/UserSettings';
import Verify from 'containers/desktop/User/Verify/Verify';
import PERMISSIONS from 'enums/permissions';

const publicRoutes = [
	{
		path: '/login',
		Component: Login,
	},
	{
		path: '/forgotPassword',
		Component: ForgotPassword,
	},
	{
		path: '/linkSentConfirmation',
		Component: LinksSentConfirmation,
	},
	{
		path: '/passwordReset',
		Component: ResetPassword,
	},
	{
		path: '/verify/:userId',
		Component: Verify,
	},
	{
		path: '/verify',
		Component: Verify,
	},
	{
		path: '/verifyEmail',
		Component: () => <Verify passwordLess={true} />,
	},
	{
		path: '/resendConfirmation',
		Component: ResendConfirmation,
	},
];

const protectedRoutes = [
	{
		path: '/user/settings',
		element: <UserSettings />,
		permissions: [
			PERMISSIONS.ADMINISTRATE,
			PERMISSIONS.READ,
			PERMISSIONS.READACCESSORY,
			PERMISSIONS.READBASEINGREDIENT,
			PERMISSIONS.READINGREDIENT,
			PERMISSIONS.READMENU,
			PERMISSIONS.READMENUPLAN,
			PERMISSIONS.READRECIPE,
			PERMISSIONS.WRITE,
			PERMISSIONS.WRITEACCESSORY,
			PERMISSIONS.WRITEACCESSORY,
			PERMISSIONS.WRITEBASEINGREDIENT,
			PERMISSIONS.WRITEINGREDIENT,
			PERMISSIONS.WRITEMENU,
			PERMISSIONS.WRITEMENUPLAN,
			PERMISSIONS.WRITERECIPE,
		],
	},
	{
		path: '/user/detail/:id',
		element: <UserDetail />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/user/new',
		element: <UserNew />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/user/search',
		element: <UserSearch />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];

export default [...publicRoutes, ...protectedRoutes.map(wrapProtectedRoute)];
