import Archive from 'containers/desktop/StandingData/Season/Archive/SeasonArchive';
import Management from 'containers/desktop/StandingData/Season/Management/SeasonManagement';
import PERMISSIONS from 'enums/permissions';

export const seasonRoutes = [
	{
		path: '/season/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/season/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
