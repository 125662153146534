import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import RenderPasswordInformation from 'components/desktop/User/RenderPasswordInformation';
import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import { fireAlert } from 'functions/fireAlert';
import getQueryParam from 'functions/getQueryParam';
import isPasswordValid from 'functions/validation/isPasswordValid';
import { UserVerificationDto } from 'types/User/Verification/UserVerificationDto';

interface IProps {
	dto: UserVerificationDto;
	setDto: (dto: UserVerificationDto) => void;
	confirm: () => void;
	passwordLess?: boolean;
}

const VerifyForm = (props: IProps) => {
	const { t } = useTranslation();
	const email: string | null = getQueryParam('email');
	const [password1, setPassword1] = useState<string>('');
	const [password2, setPassword2] = useState<string>('');

	const isCodeValid = (input: string) => {
		if (input.length === 6) {
			return true;
		}
		return false;
	};

	const areInputsValid = (): boolean => {
		const messageArray: string[] = [];
		var result: boolean = true;
		if (!isCodeValid(props.dto.confirmationCode)) {
			messageArray.push(
				`<b>${t('_general:PASSWORD')}</b>${t('alerts:ALERT_VALIDATION_WARNING_MESSAGE')}`
			);
			result = false;
		}
		if (!props.passwordLess) {
			if (!isPasswordValid(password1)) {
				messageArray.push(
					`<b>${t('_general:PASSWORD')}</b>${t('alerts:ALERT_PASSWORD_ERROR_1')}`
				);
				result = false;
			}
			if (password1 !== password2) {
				messageArray.push(
					`<b>${t('_general:PASSWORD')}</b>${t('alerts:ALERT_PASSWORD_ERROR_2')}`
				);
				result = false;
			}
		}

		if (!result) {
			fireAlert(
				2,
				t('alerts:ALERT_VALIDATION_ERROR'),
				messageArray.join('<br /><br />'),
				20000
			);
		}
		return result;
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			handleConfirm();
		}
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dtoCpy = { ...props.dto };

		if (e.currentTarget.id != 'password1' && e.currentTarget.id != 'password2') {
			dtoCpy[e.currentTarget.id] = e.currentTarget.value;
		}
		if (e.currentTarget.id == 'password1') {
			dtoCpy.password = e.currentTarget.value;
			setPassword1(e.currentTarget.value);
		} else if (e.currentTarget.id == 'password2') {
			setPassword2(e.currentTarget.value);
		}
		props.setDto(dtoCpy);
	};

	const handleConfirm = () => {
		if (areInputsValid()) {
			props.confirm();
		}
	};

	const renderContent = () => {
		return (
			<>
				<p className="text-center">
					{email && (
						<>
							<Trans i18nKey="alerts:ALERT_EMAIL_GOOD_1" email={email}>
								{{ email }}
							</Trans>
							<br />
						</>
					)}
					{t('alerts:ALERT_EMAIL_GOOD_2')}
				</p>
				<ValidatedInput
					id="confirmationCode"
					type="text"
					label={t('user:LABEL_CODE')}
					value={props.dto.confirmationCode}
					isValid={isCodeValid}
					onKeyDown={onKeyDown}
					handleValueChange={handleValueChange}
				/>
				{!props.passwordLess && (
					<>
						<div style={{ marginBottom: '-5px' }}>
							<RenderPasswordInformation />
						</div>
						<ValidatedInput
							id="password1"
							type="password"
							label={t('_general:PASSWORD')}
							value={password1}
							isValid={isPasswordValid}
							onKeyDown={onKeyDown}
							handleValueChange={handleValueChange}
						/>
						<ValidatedInput
							id="password2"
							type="password"
							label={t('user:PASSWORD_VERIFY')}
							value={password2}
							isValid={isPasswordValid}
							onKeyDown={onKeyDown}
							handleValueChange={handleValueChange}
						/>
					</>
				)}
				<div className="d-grid gap-2">
					<button className="btn btn-secondary" onClick={handleConfirm}>
						{t('user:BTN_CONFIRM')}
					</button>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default VerifyForm;
