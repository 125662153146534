import Archive from 'containers/desktop/StandingData/CarbonDioxideLabel/Archive/CarbonDioxideLabelArchive';
import Management from 'containers/desktop/StandingData/CarbonDioxideLabel/Management/CarbonDioxideLabelManagement';
import PERMISSIONS from 'enums/permissions';

export const carbonDioxideLabelRoutes = [
	{
		path: '/carbonDioxideLabel/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/carbonDioxideLabel/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
