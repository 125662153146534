import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import { ResponseNutriScoreLabel } from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import Search from 'classes/Ingredient/Search/Search';
import SliderWithId from 'classes/_general/Search/SliderWithId/SliderWithId';
import CharacteristicToggleSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicToggleSelect';
import Co2Select from 'components/desktop/_general/Co2Select/Co2Select';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import NutriScoreDiagramSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreDiagramSelect';
import RangeDoubleLabel from 'components/desktop/_general/RangeDoubleLabel/RangeDoubleLabel';
import SingleSearchSelect from 'components/desktop/_general/Select/SingleSearchSelect/SingleSearchSelect';
import ENTITLEMENTS from 'enums/entitlements';
import clone from 'functions/clone';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	advancedSearch: Search;
	handleChangeUrl: () => void;
	handleReset: () => void;
	renderRanges: boolean;
	disabled: boolean;
	setAdvancedSearch: (input: Search) => void;
}

const AdvancedSearch = (props: IProps) => {
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const handleChangeBaseIngredient = (item: ISelectItem | null) => {
		props.advancedSearch.selectBaseIngredient.save(item);
		props.handleChangeUrl();
	};

	const handleChangeTags = (items: ISelectItem[]) => {
		props.advancedSearch.selectTag.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeAllergens = (items: ISelectItem[]) => {
		props.advancedSearch.selectAllergen.mapFromISelectItem(items);
		props.handleChangeUrl();
	};

	const handleChangeSeasons = (items: ISelectItem[]) => {
		props.advancedSearch.selectSeason.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCategories = (items: ISelectItem[]) => {
		props.advancedSearch.selectCategory.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxideLabels = (items: ResponseCarbonDioxideLabel[]) => {
		props.advancedSearch.selectCo2Label.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeNutriScoreLabels = (items: ResponseNutriScoreLabel[]) => {
		props.advancedSearch.selectNutriScoreLabel.pushToSaved(items);
		props.handleChangeUrl();
	};

	const getSavedBaseIngredient = (): ISelectItem | null => {
		return props.advancedSearch.selectBaseIngredient.saved;
	};

	const getSavedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.saved;
	};

	const getSavedSeasons = (): ISelectItem[] => {
		return props.advancedSearch.selectSeason.saved;
	};

	const getSavedAllergens = (): ISelectItem[] => {
		return props.advancedSearch.selectAllergen.saved;
	};

	const getSavedCategories = (): ISelectItem[] => {
		return props.advancedSearch.selectCategory.saved;
	};

	const getSuggestedBaseIngredients = (): ISelectItem[] => {
		return props.advancedSearch.selectBaseIngredient.suggested.filtered;
	};

	const getSuggestedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.suggested.filtered;
	};

	const getSuggestedAllergens = (): ISelectItem[] => {
		return props.advancedSearch.selectAllergen.suggested.filtered;
	};

	const getSuggestedSeasons = (): ISelectItem[] => {
		return props.advancedSearch.selectSeason.suggested.filtered;
	};

	const getSuggestedCategories = (): ISelectItem[] => {
		return props.advancedSearch.selectCategory.suggested.filtered;
	};

	const setSuggestedTags = async (input: string): Promise<void> => {
		await props.advancedSearch.selectTag.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedBaseIngredients = async (input: string): Promise<void> => {
		await props.advancedSearch.selectBaseIngredient.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedAllergens = async (input: string): Promise<void> => {
		await props.advancedSearch.selectAllergen.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedSeasons = async (input: string): Promise<void> => {
		await props.advancedSearch.selectSeason.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedCategories = async (input: string): Promise<void> => {
		await props.advancedSearch.selectCategory.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const handleChangeCalories = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderCalories.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderCalories.right = right;
		props.advancedSearch.sliderCalories.left = left;
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxide = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderCo2.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderCo2.right = right;
		props.advancedSearch.sliderCo2.left = left;
		props.handleChangeUrl();
	};

	const handleChangePrice = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderPrice.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderPrice.right = right;
		props.advancedSearch.sliderPrice.left = left;
		props.handleChangeUrl();
	};

	const handleChangeNutrients = (left: number, right: number, id?: string): void => {
		if (id) {
			const sliderWithId: SliderWithId | undefined =
				props.advancedSearch.sliderNutrient.getSlider(id);
			if (sliderWithId) {
				if (!sliderWithId.hasSliderChanges(left, right)) return;
				sliderWithId.right = right;
				sliderWithId.left = left;
				props.handleChangeUrl();
			}
		}
	};

	const handleChangeCharacteristics = (): void => {
		props.handleChangeUrl();
	};

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<SingleSearchSelect
					disabled={props.disabled}
					handleChangeSearchTerm={setSuggestedBaseIngredients}
					selectedItem={getSavedBaseIngredient()}
					setSavedItem={handleChangeBaseIngredient}
					suggestedItems={getSuggestedBaseIngredients()}
					debounceMilliSeconds={350}
					label={t('_general:BASEINGREDIENT')}
					triggerSearchLetterAmount={0}
					errorKey="BaseIngredient"
				/>
				<MultiSearchSelect
					className="mb-2"
					disabled={props.disabled}
					displaySavedItems="top"
					handleChangeSearchTerm={setSuggestedTags}
					label={t('_general:TAGS')}
					savedItems={getSavedTags()}
					setSavedItems={handleChangeTags}
					suggestedItems={getSuggestedTags()}
					triggerSearchLetterAmount={0}
					errorKey="Tags"
				/>
				<MultiSearchSelect
					className="mb-2"
					disabled={props.disabled}
					displaySavedItems="top"
					handleChangeSearchTerm={setSuggestedAllergens}
					label={t('_general:ALLERGENS')}
					savedItems={getSavedAllergens()}
					setSavedItems={handleChangeAllergens}
					suggestedItems={getSuggestedAllergens()}
					triggerSearchLetterAmount={0}
					errorKey="Allergens"
				/>
				<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
					<MultiSearchSelect
						className="mb-2"
						disabled={props.disabled}
						displaySavedItems="top"
						handleChangeSearchTerm={setSuggestedSeasons}
						label={t('_general:SEASONS')}
						savedItems={getSavedSeasons()}
						setSavedItems={handleChangeSeasons}
						suggestedItems={getSuggestedSeasons()}
						triggerSearchLetterAmount={0}
						errorKey="Seasons"
					/>
				</RenderIf>
				<MultiSearchSelect
					className="mb-2"
					disabled={props.disabled}
					displaySavedItems="top"
					handleChangeSearchTerm={setSuggestedCategories}
					label={t('_general:CATEGORIES')}
					savedItems={getSavedCategories()}
					setSavedItems={handleChangeCategories}
					suggestedItems={getSuggestedCategories()}
					triggerSearchLetterAmount={0}
					errorKey="Categories"
				/>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		if (props.renderRanges) {
			return (
				<>
					<RangeDoubleLabel
						className="mb-2"
						label={`${t('_general:CALORIES')} (kcal)`}
						min={props.advancedSearch.sliderCalories.getMinRoundDown()}
						max={props.advancedSearch.sliderCalories.getMaxRoundUp()}
						minArea={props.advancedSearch.sliderCalories.minArea ?? 0}
						maxArea={props.advancedSearch.sliderCalories.maxArea ?? 0}
						left={props.advancedSearch.sliderCalories.left ?? undefined}
						right={props.advancedSearch.sliderCalories.right ?? undefined}
						handleChange={handleChangeCalories}
					/>

					{props.advancedSearch.sliderNutrient.all.map((e: SliderWithId, i: number) => {
						return (
							<div key={i}>
								<RangeDoubleLabel
									id={e.id}
									className="mb-2"
									label={`${e.name} (${e.unit})`}
									min={e.getMinRoundDown()}
									max={e.getMaxRoundUp()}
									minArea={e.minArea ?? 0}
									maxArea={e.maxArea ?? 0}
									left={e.left ?? undefined}
									right={e.right ?? undefined}
									handleChange={handleChangeNutrients}
								/>
							</div>
						);
					})}

					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<label className="mb-2 mt-2">{`${t('_general:COSTS')} ${t(
							'_general:TOTAL'
						)}`}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={`${t('_general:COSTS')} (${reduxCurrency})`}
							min={props.advancedSearch.sliderPrice.getMinRoundDown()}
							max={props.advancedSearch.sliderPrice.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderPrice.minArea ?? 0}
							maxArea={props.advancedSearch.sliderPrice.maxArea ?? 0}
							left={props.advancedSearch.sliderPrice.left ?? undefined}
							right={props.advancedSearch.sliderPrice.right ?? undefined}
							handleChange={handleChangePrice}
						/>
					</RenderIf>

					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<label className="mb-2 mt-2">{t('_general:CARBON_DIOXIDE')}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={`${t('_general:CARBON_DIOXIDE')} (g)`}
							min={props.advancedSearch.sliderCo2.getMinRoundDown()}
							max={props.advancedSearch.sliderCo2.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderCo2.minArea ?? 0}
							maxArea={props.advancedSearch.sliderCo2.maxArea ?? 0}
							left={props.advancedSearch.sliderCo2.left ?? undefined}
							right={props.advancedSearch.sliderCo2.right ?? undefined}
							handleChange={handleChangeCarbonDioxide}
						/>
					</RenderIf>
				</>
			);
		}
		return <></>;
	};

	const renderCol3 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<Co2Select
						carbonDioxideLabels={props.advancedSearch.selectCo2Label.suggested.all}
						savedItems={props.advancedSearch.selectCo2Label.saved}
						setSavedItems={handleChangeCarbonDioxideLabels}
					/>
				</RenderIf>
				<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
					<NutriScoreDiagramSelect
						nutriScoreLabels={props.advancedSearch.selectNutriScoreLabel.suggested.all}
						savedItems={props.advancedSearch.selectNutriScoreLabel.saved}
						setSavedItems={handleChangeNutriScoreLabels}
					/>
				</RenderIf>
				<CharacteristicToggleSelect
					characteristics={props.advancedSearch.toggleListCharacteristic}
					handleChange={handleChangeCharacteristics}
				/>
			</div>
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-lg-4">{renderCol1()}</div>
				<div className="col-lg-6">{renderCol2()}</div>
				<div className="col-lg-2">{renderCol3()}</div>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{ textAlign: 'end' }}>
					<button className="btn btn-primary" onClick={props.handleReset}>
						{t('_general:RESET')}
					</button>
				</div>
			</div>
		</>
	);
};

export default AdvancedSearch;
