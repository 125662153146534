import Archive from 'containers/desktop/StandingData/MailAccount/Archive/MailAccountArchive';
import Management from 'containers/desktop/StandingData/MailAccount/Management/MailAccountManagement';
import PERMISSIONS from 'enums/permissions';

export const mailAccountRoutes = [
	{
		path: '/mailAccount/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/mailAccount/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
