export type Type = {
	maximum: number | null;
	minimum: number | null;
};

export function create(): Type {
	return {
		maximum: null,
		minimum: null,
	};
}
