import { Allergens, AllergensPerIngredient } from 'api/recipe/PostRecipeCalculateV1';
import { getIngredientOrAccessoryClassName } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import { enforceNonNullable } from 'functions/functionExtensions';
import { getDataUrlFromUrl } from 'functions/getDataUrlFromUrl';
import { Optional } from 'functions/promiseExtensions';
import { useApi } from 'hooks/useApi';
import { Segment } from 'types/Recipe/Segment';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';

type SVGDictionary = { [url: string]: string };

const getClassName = (ingredient: SegmentIngredient): string => {
	return ['col-2', 'ingredient-data-col', getIngredientOrAccessoryClassName(ingredient)].join(
		' '
	);
};

const handleIngredientLinkClicked = (ingredient: SegmentIngredient) => {
	window.open(`/ingredient/detail/${ingredient.ingredientId}`, '_blank');
};

interface AllergenNameCellProps {
	ingredient: SegmentIngredient;
}

const AllergenNameCell = ({ ingredient }: AllergenNameCellProps) => {
	if (
		ingredient.status.ingredient === 'Recognized' ||
		ingredient.status.ingredient === 'PartiallyRecognized'
	) {
		return (
			<td
				className={getClassName(ingredient)}
				onClick={() => handleIngredientLinkClicked(ingredient)}
				style={{ cursor: 'pointer' }}
			>
				{ingredient.ingredient}
			</td>
		);
	}
	return <td className={getClassName(ingredient)}>{ingredient.ingredient}</td>;
};

interface SVGRowProps {
	allergens: Allergens[];
	svgDataUrls: SVGDictionary;
}

const SVGRow = ({ allergens, svgDataUrls }: SVGRowProps) => {
	return (
		<>
			{allergens.map((allergen, i) => (
				<td key={i} className="allergen">
					{allergen.iconSvgUrl && (
						<img
							src={svgDataUrls[allergen.iconSvgUrl]}
							style={{
								width: '24px',
								height: '24px',
							}}
						/>
					)}
				</td>
			))}
		</>
	);
};

interface IngredientsTableProps {
	ingredients: SegmentIngredient[];
	loadedSVGs: SVGDictionary;
	allergensPerIngredient: AllergensPerIngredient[];
}

const IngredientsTable = ({
	ingredients,
	loadedSVGs,
	allergensPerIngredient,
}: IngredientsTableProps) => {
	return (
		<div className="recipe-rendering-ingredient-container">
			<table
				className="table table-sm table-bordered allergen-rendering-table"
				style={{ marginBottom: '0' }}
			>
				<tbody>
					{ingredients.map((ingredient, i) => (
						<tr key={i}>
							<AllergenNameCell ingredient={ingredient} key={2 * i} />
							{allergensPerIngredient
								.filter((x) => x.ingredientId === ingredient.ingredientId)
								.take(1)
								.map((allergenPerIngredient, _id) => (
									<SVGRow
										key={2 * i + 1}
										allergens={allergenPerIngredient.allergens}
										svgDataUrls={loadedSVGs}
									/>
								))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

interface IngredientsSectionProps {
	segment: Segment;
	loadedSVGs: SVGDictionary;
	allergensPerIngredient: AllergensPerIngredient[];
}

const IngredientsSection = ({
	segment,
	loadedSVGs,
	allergensPerIngredient,
}: IngredientsSectionProps) => {
	return (
		<div className="recipe-rendering">
			<div className="row">
				<div className="col-12">
					<IngredientsTable
						loadedSVGs={loadedSVGs}
						ingredients={segment.ingredients}
						allergensPerIngredient={allergensPerIngredient}
					/>
				</div>
			</div>
		</div>
	);
};

const loadSVGsAsync = async (urls: string[]): Promise<SVGDictionary> => {
	const dataUrls = await urls
		.mapAsync(async (url) => [url, await getDataUrlFromUrl(url)] as [string, Optional<string>])
		.awaitAll();

	return dataUrls
		.flatMap(([url, dataUrl]) => dataUrl.toArray((x) => [url, x]))
		.toDictionary(
			([url, _]) => url,
			([_, dataurl]) => dataurl
		);
};

const loadSVGs = async (allergensPerIngredient: AllergensPerIngredient[]) => {
	const uniqueSVGUrls = allergensPerIngredient
		.flatMap((x) => x.allergens)
		.map((a) => a.iconSvgUrl)
		.keep(enforceNonNullable)
		.distinct();

	return await loadSVGsAsync(uniqueSVGUrls);
};

interface IProps {
	segments: Segment[];
	allergensPerIngredient: AllergensPerIngredient[];
}

const AllergenView = (props: IProps) => {
	const [loadedSVGs] = useApi({}, loadSVGs, props.allergensPerIngredient);

	return (
		<>
			{props.segments.map((segment, id) => (
				<IngredientsSection
					segment={segment}
					key={id}
					allergensPerIngredient={props.allergensPerIngredient}
					loadedSVGs={loadedSVGs}
				/>
			))}
		</>
	);
};

export default AllergenView;
