import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url = `/backend-service/EmailAccount/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	senderEmail: string;
	host: string;
	port: number;
	username: string;
	useSsl: boolean;
	useDefaultCredentials: boolean;
};

export const defaultResponseData = (): ResponseData => {
	return {
		id: '',
		senderEmail: '',
		host: '',
		port: 0,
		username: '',
		useSsl: false,
		useDefaultCredentials: false,
	};
};
