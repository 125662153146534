import * as apiGet from 'api/allergen/GetAllergenIdV1';
import * as apiPost from 'api/allergen/PostAllergenV1';
import * as apiPut from 'api/allergen/PutAllergenV1';
import { Media } from 'api/media/PostMediaV1';
import * as apiPostMedia from 'api/media/PostMediaV1';
import * as apiPutMedia from 'api/media/PutMediaV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { IconSvg } from 'types/IconSvg/IconSvg';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export type TranslationKeys =
	| 'negativeNameTranslations'
	| 'positiveNameTranslations'
	| 'mayContainNameTranslations';

export type SvgKeys = 'negativeIconSvg' | 'positiveIconSvg' | 'mayContainIconSvg';

export default class Allergen {
	public id: string = '';
	public negativeIconSvg: IconSvg | null = null;
	public positiveIconSvg: IconSvg | null = null;
	public mayContainIconSvg: IconSvg | null = null;
	public display: boolean = false;
	public negativeNameTranslations: LocalizedTranslation<string> = {};
	public positiveNameTranslations: LocalizedTranslation<string> = {};
	public mayContainNameTranslations: LocalizedTranslation<string> = {};

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const keys: TranslationKeys[] = [
			'positiveNameTranslations',
			'negativeNameTranslations',
			'mayContainNameTranslations',
		];

		await Promise.all(
			keys.map(async (key) => {
				const response = await apiTranslate.callApi(
					this.mapToApiTranslate(key, fromCultureCode, toCultureCode)
				);
				response.hasValue() && this.mapFromApiTranslate(key, response.get(), toCultureCode);
			})
		);
	}

	public async callApiUploadMedia(input: FormData | null, key: SvgKeys): Promise<void> {
		let response = null;
		response = await apiPostMedia.callApi(this.mapToApiMedia(input) as apiPostMedia.Request);
		response.hasValue() && this.mapFromApiUploadMedia(response.get(), key);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiMedia(
		formData: FormData | null,
		id?: string
	): apiPostMedia.Request | apiPutMedia.Request {
		if (id) {
			return {
				id: id,
				mediaCollection: MEDIACOLLECTION.Icon,
				formData: formData,
			};
		} else {
			return {
				mediaCollection: MEDIACOLLECTION.Icon,
				formData: formData,
			};
		}
	}

	private mapToApiTranslate(
		key: TranslationKeys,
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this[key][fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			negativeIconSvgId: this.negativeIconSvg?.id ?? null,
			positiveIconSvgId: this.positiveIconSvg?.id ?? null,
			mayContainIconSvgId: this.mayContainIconSvg?.id ?? null,
			display: this.display,
			positiveNameTranslations: this.positiveNameTranslations,
			negativeNameTranslations: this.negativeNameTranslations,
			mayContainNameTranslations: this.mayContainNameTranslations,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			negativeIconSvgId: this.negativeIconSvg?.id ?? null,
			positiveIconSvgId: this.positiveIconSvg?.id ?? null,
			mayContainIconSvgId: this.mayContainIconSvg?.id ?? null,
			display: this.display,
			positiveNameTranslations: this.positiveNameTranslations,
			negativeNameTranslations: this.negativeNameTranslations,
			mayContainNameTranslations: this.mayContainNameTranslations,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.negativeIconSvg = input.negativeIconSvg;
		this.positiveIconSvg = input.positiveIconSvg;
		this.mayContainIconSvg = input.mayContainIconSvg;
		this.display = input.display;
		this.negativeNameTranslations = input.negativeNameTranslations;
		this.positiveNameTranslations = input.positiveNameTranslations;
		this.mayContainNameTranslations = input.mayContainNameTranslations;
	}

	private mapFromApiUploadMedia(input: Media | null, key: SvgKeys) {
		this[key] = input;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(key: TranslationKeys, input: string, toCultureCode: string) {
		this[key][toCultureCode] = input;
	}
}
