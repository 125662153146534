import * as apiGetCategoryTree from 'api/baseIngredientCategory/GetBaseIngredientCategoryTreeViewStartingWithV1';
import * as Category from 'types/_general/Store/Category';

export type Type = Category.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<apiGetCategoryTree.Category[]> {
	const response = await apiGetCategoryTree.callApi('', false);
	return response.getOrDefault([]);
}

function search(obj: Type, id: string): Category.Type | undefined {
	for (const i of obj) {
		if (i.id === id) return i;
		for (const child of i.children) {
			const found = search([child], id);
			if (found) return found;
		}
	}
	return undefined;
}

export function getFromBaseIngredientCategories(obj: Type, ids: string[]): Type {
	if (!ids.length) return [];
	const output: Type = [];
	for (const id of ids) {
		const element: Category.Type | undefined = search(obj, id);
		if (element) output.push(element);
	}
	return output;
}
