import * as React from 'react';

import 'components/desktop/_general/LogoAnimation/LogoAnimation.scss';

const LogoAnimation: React.FC = () => {
	return (
		<div className="logo-animation-container">
			<img alt="logo" src={'img/animation/logo_0.png'} className="img-fluid show-icon-0" />
			<img alt="logo" src={'img/animation/logo_1.png'} className="img-fluid show-icon-1" />
			<img alt="logo" src={'img/animation/logo_2.png'} className="img-fluid show-icon-2" />
			<img alt="logo" src={'img/animation/logo_3.png'} className="img-fluid show-icon-3" />
		</div>
	);
};

export default LogoAnimation;
