import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';

export default class TagCategory {
	public id: string = '';
	public name: string | null = null;

	public constructor(input?: api.TagCategory | null) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.TagCategory | null): void {
		if (input) {
			this.id = input.id;
			this.name = input.name;
		}
	}
}
