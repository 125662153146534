import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { ISelectItem } from 'interfaces/ISelectItem';
import { NutriScoreNutrient } from 'types/NutriScoreNutrient/NutriScoreNutrient';
import { Nutrient } from 'types/Nutrient/Nutrient';

interface IProps {
	nutrients: Nutrient[];
	nutriScoreNutrient: NutriScoreNutrient;
	setNutriScoreNutrient: (input: NutriScoreNutrient) => void;
}

const NutrientSelect = (props: IProps) => {
	const { t } = useTranslation();

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingNutrients: Nutrient[] = props.nutrients.filter((nutrient: Nutrient) => {
			const current: boolean = props.nutriScoreNutrient.nutrientId === nutrient.id;
			if (current) return false;
			if (searchTerm) {
				const name = nutrient.name.toLowerCase();
				searchTerm = searchTerm.toLowerCase();
				return name.startsWith(searchTerm);
			} else {
				return true;
			}
		});

		return matchingNutrients.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				name: nutrient.name + ' (' + nutrient.nutrientCategory.name + ')',
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const changeNutrient = (savedItem: ISelectItem): void => {
		const nutriScoreNutrient: NutriScoreNutrient = { ...props.nutriScoreNutrient };
		nutriScoreNutrient.nutrientId = savedItem.id;
		nutriScoreNutrient.name = savedItem.name;
		props.setNutriScoreNutrient(nutriScoreNutrient);
	};

	return (
		<>
			<label className="input-label">{t('standingData:CHANGE_NUTRIENT')}</label>
			<div className="input-group mb-3">
				<SuggestionSearchInput
					className="form-control"
					placeholderText={props.nutriScoreNutrient.name ?? undefined}
					placeholderIsBlack={true}
					handleChangeSearchTerm={handleChangeSearchTerm}
					setSavedItem={changeNutrient}
					triggerSearchLetterAmount={0}
					suggestedItems={suggestedItems}
				/>
			</div>
		</>
	);
};

export default NutrientSelect;
