import RecipeCategoryManagementComponent from 'components/desktop/StandingData/RecipeCategory/Management/RecipeCategoryManagementComponent';

const RecipeCategoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<RecipeCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeCategoryArchiveContainer;
