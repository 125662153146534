import axios from 'axios';

import * as Get from 'api/baseIngredient/GetBaseIngredientIdV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<Get.ResponseData>> {
	const url: string = `/backend-service/BaseIngredient/${id}`;
	return axios.put<Get.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	density: number | null;
	scalingFactor: number;
	calories: number | null;
	imageId: string | null;
	characteristic: Characteristic;
	hasNutriScore: boolean;
	nutriScoreCategoryIsSetByUser: boolean;
	nutriScoreCategory: NutriScoreCategory | null;
	nameTranslations: {
		[key: string]: string;
	};
	nutrientValues: {
		[key: string]: number;
	};
	unitWeights: {
		[key: string]: number;
	};
	seasons: string[];
	allergens: {
		[key: string]: AllergenLogic;
	};
	tags: string[];
	categories: string[];
	carbonDioxide: RequestCarbonDioxide[];
}

export type RequestCarbonDioxide = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};
