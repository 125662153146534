import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import * as apiPut from 'api/menuPlanner/PutMenuPlannerIdV1';
import AllergenList from 'classes/MenuPlan/Detail/AllergenList';
import CarbonDioxideLabel from 'classes/MenuPlan/Detail/CarbonDioxideLabel';
import ItemList from 'classes/MenuPlan/Detail/ItemList';
import NutrientList from 'classes/MenuPlan/Detail/NutrientList';
import TagList from 'classes/MenuPlan/Detail/TagList';
import { ConstraintStatus } from 'enums/ConstraintStatus';
import randomUUIDUnsafe from 'functions/randomUUIDUnsafe';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

const idPrefix: string = 'temporaryGuid';

export default class Row {
	public id: string = idPrefix + randomUUIDUnsafe();
	public isPriceConstraintMet: boolean = true;
	public isCaloriesConstraintMet: boolean = true;
	public caloriesSum: number | null = null;
	public priceSum: number | null = null;
	public priceSumTotal: number | null = null;
	public carbonDioxideValueSum: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public name: LocalizedTranslation<string> | null = null;
	public itemList: ItemList = new ItemList();
	public tagList: TagList = new TagList();
	public nutrientList: NutrientList = new NutrientList();
	public allergenList: AllergenList = new AllergenList();

	public constructor(input?: apiGet.Row) {
		if (!input) return;
		this.mapFromApiGet(input);
	}

	public removeItem(day: number): void {
		this.itemList.removeItemByDay(day);
	}

	public mapFromApiGet(input: apiGet.Row): void {
		this.id = input.id;
		this.isPriceConstraintMet = input.isPriceConstraintMet;
		this.isCaloriesConstraintMet = input.isCaloriesConstraintMet;
		this.caloriesSum = input.caloriesSum;
		this.priceSum = input.priceSum;
		this.carbonDioxideValueSum = input.carbonDioxideValueSum;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.name = input.nameTranslations;
		this.itemList.mapFromApi(input.items);
		this.tagList.mapFromApi(input.tags);
		this.nutrientList.mapFromApi(input.nutrients);
		this.allergenList.mapFromApi(input.allergens);
	}

	public recalculateConstraints(priceLimit: number, caloriesLimit: number): void {
		this.isPriceConstraintMet = this.isConstraintMet(this.priceSum ?? 0, priceLimit);
		this.isCaloriesConstraintMet = this.isConstraintMet(this.caloriesSum ?? 0, caloriesLimit);
	}

	private isConstraintMet(value: number, limit: number): boolean {
		return value <= limit;
	}

	public mapToApiPut(): apiPut.Row {
		const id: string | null = this.id === '' || this.id.startsWith(idPrefix) ? null : this.id;
		return {
			id: id,
			nameTranslations: this.name ?? {},
			items: this.itemList.mapToApiPut(),
			tags: this.tagList.mapToApiPut(),
		};
	}

	public mapToApiCalculate(total: boolean): apiPostCalculate.Item {
		return this.itemList.all.flatMap((e) => e.mapToApiCalculate(total));
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.nutrientList.getNutrientCategoryGroups();
	}

	public getNutrientPerPart(_nutrientValue: number): number {
		return 0;
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		return nutrientValue;
	}

	public getNutrientTotal(_nutrientValue: number): number {
		return 0;
	}

	public mapFromApiPut(input: apiGet.Row) {
		this.isPriceConstraintMet = input.isPriceConstraintMet;
		this.isCaloriesConstraintMet = input.isCaloriesConstraintMet;
		this.caloriesSum = input.caloriesSum;
		this.priceSum = input.priceSum;
		this.carbonDioxideValueSum = input.carbonDioxideValueSum;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.nutrientList.mapFromApi(input.nutrients);
		this.allergenList.mapFromApi(input.allergens);
	}

	public mapFromApiCalculate(input: apiPostCalculate.Data, total: boolean) {
		if (total) {
			this.priceSumTotal = input.priceSum;
		} else {
			this.priceSum = input.priceSum;
			this.caloriesSum = input.calorieSum;
			this.allergenList.mapFromApiCalculate(input.allergens);
			this.nutrientList.mapFromApiCalculate(input.nutrientValues);
			this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
			this.carbonDioxideValueSum = input.carbonDioxideValueSum;
		}
	}

	public getConstraintStatusPrice(): ConstraintStatus {
		if (this.isPriceConstraintMet) {
			return ConstraintStatus.FullyMet;
		} else {
			return ConstraintStatus.NotMet;
		}
	}

	public getConstraintStatusCalories(): ConstraintStatus {
		if (this.isCaloriesConstraintMet) {
			return ConstraintStatus.FullyMet;
		} else {
			return ConstraintStatus.NotMet;
		}
	}
}
