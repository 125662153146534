import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import Image from 'classes/MenuPlan/Detail/Image';

export default class ImageList {
	public all: Image[] = [];

	public constructor(input?: apiGet.Image[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiGet.Image[] | null): void {
		this.all = [];
		if (input) {
			for (const i of input) {
				const image: Image = new Image(i);
				this.all.push(image);
			}
		}
	}
}
