import { Dispatch, SetStateAction } from 'react';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { RecipeCalculation } from 'components/desktop/Recipe/RecipeCalculation/RecipeCalculation';
import RecipeCalories from 'components/desktop/Recipe/RecipeCalories';
import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	recipeCalculation: Calculation | null;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
}

const RecipeNutrientsComponent = (props: IProps) => {
	return (
		<>
			<div className="margin-top-25">
				<RecipeCalories
					calories={props.recipe.calories ?? props.recipeCalculation?.calories ?? 0}
					defaultLocked={!props.recipe.calories}
					parts={props.recipe.parts ?? 0}
					persons={props.recipe.persons ?? 0}
					updateCalories={handlers.createUpdateCaloriesHandler(props.setRecipe)}
					weight={props.recipe.weight ?? props.recipe.ingredientWeight ?? 0}
				/>
				<RecipeCalculation
					recipe={props.recipe}
					recipeCalculation={props.recipeCalculation}
				/>
			</div>
		</>
	);
};

export default RecipeNutrientsComponent;
