import * as apiGetStartingWithV1 from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import { ValueScope } from 'enums/valueScope';
import * as Co2Label from 'types/_general/Store/Co2Label';

export type Type = Co2Label.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGetStartingWithV1.callApi('', ValueScope.HundredGrams, false);
	return response.getOrDefault([]);
}
