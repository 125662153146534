import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	pageIndex: number,
	pageSize: number
): Promise<Optional<ResponseData>> {
	const url = '/backend-service/MailTemplate';
	const params: Parameter = {
		Name: searchTerm,
		PageIndex: pageIndex,
		PageSize: pageSize,
	};
	return axios.get(url, { params: params }).toOptionalMapped((x) => x.data);
}

interface Parameter {
	Name: string;
	PageIndex: number;
	PageSize: number;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseMailTemplate[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type ResponseMailTemplate = {
	id: string;
	name: string;
};
