import { t } from 'i18next';

import BtnDownloadPdf from 'components/desktop/_general/Button/BtnDownloadPdf/BtnDownloadPdf';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';

interface IProps {
	disabled?: boolean;
	handleSave: () => Promise<void>;
	id?: string;
	isLoading: boolean;
	setDisplayOverlay: (input: boolean) => void;
}

const Title = (props: IProps) => {
	const getTitle = (): JSX.Element => {
		if (props.disabled) {
			return (
				<>
					<h1>{t('menuPlan:VIEW_MENU_PLAN')}</h1>
				</>
			);
		} else {
			if (props.id) {
				return (
					<>
						<h1>{t('menuPlan:EDIT_MENU_PLAN')}</h1>
					</>
				);
			} else {
				return (
					<>
						<h1>{t('menuPlan:NEW_MENU_PLAN')}</h1>
					</>
				);
			}
		}
	};

	return (
		<>
			<div className="d-flex flex-row justify-content-between">
				{getTitle()}
				<div>
					<BtnDownloadPdf setDisplayOverlay={props.setDisplayOverlay} id={props.id} />
					<RenderIf condition={!props.disabled}>
						<BtnSave
							handleSave={() => props.handleSave()}
							isLoading={props.isLoading}
						/>
					</RenderIf>
				</div>
			</div>
		</>
	);
};

export default Title;
