import * as apiDelete from 'api/allergen/DeleteAllergenIdV1';
import * as apiRestore from 'api/allergen/PostAllergenRestoreV1';
import * as apiPostSearch from 'api/allergen/PostAllergenSearchV1';

export default class Allergen {
	public display: boolean = false;
	public id: string = '';
	public mayContainName: string = '';
	public mayContainIconSvgUrl: string | null = null;
	public negativeIconSvgUrl: string | null = null;
	public negativeName: string = '';
	public positiveIconSvgUrl: string | null = null;
	public positiveName: string = '';

	public constructor(input?: apiPostSearch.ResponseAllergen) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseAllergen) {
		this.display = input.display;
		this.id = input.id;
		this.mayContainName = input.mayContainName;
		this.mayContainIconSvgUrl = input.mayContainIconSvgUrl;
		this.negativeIconSvgUrl = input.negativeIconSvgUrl;
		this.negativeName = input.negativeName;
		this.positiveIconSvgUrl = input.positiveIconSvgUrl;
		this.positiveName = input.positiveName;
	}
}
