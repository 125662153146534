import { Optional } from './promiseExtensions';

const promises = new Map<string, Promise<any>>();

function createPromise<T>(key: string, f: () => Promise<T>) {
	const promise = new Promise((resolve) => {
		return f()
			.then((t) => {
				return resolve(t);
			})
			.finally(() => {
				promises.delete(key);
			});
	});
	promises.set(key, promise);
	return promise as Promise<T>;
}

export async function runOnce<T>(key: string, f: () => Promise<T>): Promise<T> {
	return Optional.Maybe(promises.get(key)).orElse(() => createPromise(key, f));
}
