import * as apiPutMailTemplate from 'api/mailTemplate/PutMailTemplateV1';
import { MailTemplate } from 'types/MailTemplate/MailTemplate';
import { MailTemplateRequest } from 'types/MailTemplate/MailTemplateRequest';

export const mapToRequest = (
	input: MailTemplate
): apiPutMailTemplate.Request & MailTemplateRequest => {
	return {
		emailAccountId: input.emailAccount.id,
		localizedBodies: input.localizedBodies,
		localizedSubjects: input.localizedSubjects,
		placeholders: input.placeholders,
	};
};
