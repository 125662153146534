import * as apiPost from 'api/recipe/PostRecipeAnalyseV1';
import * as SegmentList from 'types/Recipe/Print/Analysis/SegmentList';
import * as Recipe from 'types/Recipe/Print/Recipe';

export type Type = {
	segments: SegmentList.Type;
};

export function create(): Type {
	return {
		segments: SegmentList.create(),
	};
}

export async function getFromApi(recipe: Recipe.Type, cultureCode: string): Promise<Type> {
	const request: apiPost.Request = Recipe.mapToApiAnalysis(recipe, cultureCode);
	if (!request.ingredients || !request.instructions) return create();
	const response = await apiPost.callApi(request);
	return response.map(mapFromApi).orElse(create);
}

function mapFromApi(input: apiPost.ResponseData): Type {
	return input;
}
