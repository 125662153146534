import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faImageSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import SegmentImageCarouselButtons from 'components/desktop/_general/ImageCarousel/SegmentImageCarouselButtons';
import MediaCarousel from 'components/desktop/_general/MediaCarousel/MediaCarousel';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { MEDIATYPES } from 'enums/mediaTypes';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';

interface IProps {
	handleDeleteImage: (id: string) => void;
	images: SegmentMedia[];
	readOnly: boolean;
	uploadImage: (input: File[], video: boolean) => void;
}

const SegmentImageCarousel = (props: IProps) => {
	const [selectedImage, setSelectedImage] = useState<SegmentMedia | null>(null);
	const [displayImage, setDisplayImage] = useState<boolean>(false);
	const [showButtons, setShowButtons] = useState<boolean>(false);

	const handleImageSelect = (image: SegmentMedia): void => setSelectedImage(image);
	const handleOpenOverlay = (): void => setDisplayImage(true);
	const handleCloseOverlay = (): void => setDisplayImage(false);
	const onMouseOver = (): void => setShowButtons(true);
	const onMouseLeave = (): void => setShowButtons(false);

	const actionButtonClass = `thumbnail-action-buttons text-center ${showButtons ? '' : 'hide'}`;

	useEffect(() => {
		if (props.images.length > 0) {
			setSelectedImage(props.images[0]);
		}
	}, [props.images, props.images.length]);

	const handleImageRemove = (id: string): void => {
		setSelectedImage(null);
		props.handleDeleteImage(id);
	};

	const handleUploadMedia = async (input: FileList): Promise<void> =>
		props.uploadImage(Array.from(input), false);

	const renderThumbnail = (image: SegmentMedia, i: number): JSX.Element => {
		return (
			<div
				onClick={() => handleImageSelect(image)}
				key={i}
				style={{
					cursor: 'pointer',
					boxShadow:
						selectedImage && selectedImage.url === image.url
							? '0px 0px 0px 8px #ddd'
							: 'inherit',
				}}
			>
				<img
					src={image.url}
					style={{
						objectFit: 'cover',
						width: '100%',
						height: '150px',
					}}
				/>
			</div>
		);
	};

	const renderImagePreview = (): JSX.Element | null => {
		if (!selectedImage)
			return (
				<div>
					<FontAwesomeIcon icon={faImageSlash as IconProp} size="7x" />
				</div>
			);
		return (
			<div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
				<img src={selectedImage.url} />
				<div className={actionButtonClass} style={{ zIndex: 200 }}>
					<SegmentImageCarouselButtons
						handleView={handleOpenOverlay}
						handleDelete={handleImageRemove}
						id={selectedImage.id}
					/>
				</div>
			</div>
		);
	};

	const renderImageThumbnails = (): JSX.Element | null => {
		return <>{props.images.map((image, i) => renderThumbnail(image, i))}</>;
	};

	return (
		<>
			<MediaCarousel
				accept={[MEDIATYPES.TIFF, MEDIATYPES.JPEG, MEDIATYPES.GIF, MEDIATYPES.PNG]}
				buttonLabel={t('_general:UPLOAD_NEW_IMAGE')}
				disabled={props.readOnly}
				handleUploadMedia={handleUploadMedia}
				renderMediaPreview={renderImagePreview}
				renderMediaThumbnails={renderImageThumbnails}
			/>
			<Overlay
				contentStyle={{ maxHeight: '100vh' }}
				containerStyle={{ maxHeight: '100vh' }}
				handleClose={handleCloseOverlay}
				displayOverlay={displayImage}
			>
				<img src={selectedImage?.url} style={{ height: '50vh' }} />
			</Overlay>
		</>
	);
};

export default SegmentImageCarousel;
