import * as apiSW from 'api/ingredientCategory/GetIngredientCategoryStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class IngredientCategory {
	id: string = '';
	name: string = '';

	public constructor(input?: apiSW.ResponseIngredientCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public toSelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name,
		};
	}

	private mapFromApi(input: apiSW.ResponseIngredientCategory): void {
		this.id = input.id;
		this.name = input.description;
	}
}
