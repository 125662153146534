import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiPostGetAutoFill from 'api/menuPlanner/PostMenuPlannerGetAutoFillV1';
import * as apiPut from 'api/menuPlanner/PutMenuPlannerIdV1';
import Item from 'classes/MenuPlan/Detail/Item';

export default class ItemList {
	public all: Item[] = [];

	public constructor(input?: apiGet.Item[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public getItemByDay(day: number): Item | undefined {
		return this.all.find((e: Item | null) => {
			if (e == null) return undefined;
			return e.day === day;
		});
	}

	public removeItemByDay(day: number) {
		const output: Item[] = [];
		for (const i of this.all) {
			if (i.day !== day) {
				output.push(i);
			}
		}
		this.all = output;
	}

	public mapFromApi(input: apiGet.Item[] | null) {
		if (input) {
			for (const i of input) {
				const item: Item = new Item(i);
				this.all.push(item);
			}
		}
	}

	public mapToApiPut(): apiPut.Item[] {
		const output: apiPut.Item[] = [];
		for (const i of this.all) {
			if (i) {
				output.push(i.mapToApi());
			}
		}
		return output;
	}

	public async callApiAutoFill(request: apiPostGetAutoFill.Request): Promise<void> {
		const response = await apiPostGetAutoFill.callApi(request);
		response.hasValue() && this.mapFromApiAutoFill(response.get());
	}

	public mapFromApiAutoFill(input: apiPostGetAutoFill.ResponseData[]): void {
		this.all = input.map((e) => {
			return new Item(e);
		});
	}
}
