import React from 'react';
import { useTranslation } from 'react-i18next';

import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import { EAlertType } from 'enums/alertType';
import { fireAlert } from 'functions/fireAlert';
import isEmailValid from 'functions/validation/isEmailValid';

interface IProps {
	email: string;
	setEmail: (email: string) => void;
	confirm: () => void;
}

const ResendConfirmationForm = (props: IProps) => {
	const { t } = useTranslation();

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			handleConfirm();
		}
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.setEmail(e.currentTarget.value);
	};

	const handleConfirm = () => {
		if (validateForm()) {
			props.confirm();
		}
	};

	const validateForm = (): boolean => {
		var result = true;
		if (!isEmailValid(props.email)) {
			result = false;
			fireAlert(
				EAlertType.warning,
				t('alerts:ALERT_VALIDATION_ERROR'),
				t('alerts:ALERT_EMAIL_ERROR'),
				10000
			);
		}
		return result;
	};

	const renderContent = () => {
		return (
			<>
				<p className="text-center">{t('user:MESSAGE_MAIL_CONFIRMATION')}</p>
				<ValidatedInput
					id="email"
					type="email"
					label={t('_general:EMAIL')}
					value=""
					isValid={isEmailValid}
					onKeyDown={onKeyDown}
					handleValueChange={handleValueChange}
				/>
				<div className="d-grip gap-2">
					<button className="btn btn-secondary" onClick={handleConfirm}>
						{t('user:BTN_SEND_CONFIRMATION_CODE')}
					</button>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ResendConfirmationForm;
