import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

import 'components/desktop/Recipe/RecipeTabs/Components/RecipeScaling/RecipeScaling.scss';

interface IProps {
	scaledParts: number;
	setScaledParts: (scaledParts: number) => void;
}

const RecipeScaling = (props: IProps) => {
	const handleIncreaseScaledParts = () => {
		props.setScaledParts(props.scaledParts + 1);
	};

	const handleDecreaseScaledParts = () => {
		if (props.scaledParts > 1) props.setScaledParts(props.scaledParts - 1);
	};

	const getDecreaseScaledPartsClassName = (): string => {
		if (props.scaledParts === 1) {
			return 'scaled-parts-button inactive';
		}
		return 'scaled-parts-button';
	};

	return (
		<div style={{ paddingTop: '22px' }}>
			<FontAwesomeIcon
				icon={faMinusCircle as IconProp}
				onClick={handleDecreaseScaledParts}
				className={getDecreaseScaledPartsClassName()}
			/>
			<FontAwesomeIcon
				icon={faPlusCircle as IconProp}
				onClick={handleIncreaseScaledParts}
				className="scaled-parts-button"
			/>
			{props.scaledParts} {t('recipe:PIECES')}
		</div>
	);
};

export default RecipeScaling;
