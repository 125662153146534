import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import Column from 'classes/MenuPlan/Detail/Column';

export default class ColumnList {
	public all: Column[] = [];

	public constructor(input?: apiPostCalculate.ResponseData) {
		if (!input) return;
		this.mapFromApiCalculate(input, false);
	}

	public mapFromApiCalculate(input: apiPostCalculate.ResponseData, total: boolean) {
		input.forEach((data, i) => {
			const column: Column | undefined = this.all.find((e) => e.id === i);
			if (column) column.mapFromApiCalculate(i, data, total);
			if (!column) this.all.push(new Column(i, data));
		});
	}
}
