import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetLanguage from 'api/language/GetLanguageV1';
import * as apiGetSettingKey from 'api/setting/GetSettingKeyV1';
import * as apiGetUserCornerInfo from 'api/userCornerInfo/GetUserCornerInfoV1';
import * as apiGetUserSettingKey from 'api/userSetting/GetUserSettingKeyV1';
import Footer from 'components/desktop/_general/Footer/Footer';
import MainNavigationLeft from 'components/desktop/_general/MainNavigationLeft/MainNavigationLeft';
import MainNavigationTop from 'components/desktop/_general/MainNavigationTop/MainNavigationTop';
import { setReduxAutoCompleteLanguages } from 'features/AllowedAutoCompleteLanguagesFeature';
import { setReduxAllowedEntryLanguages } from 'features/AllowedEntryLanguagesFeature';
import { setReduxAllowedUiLanguages } from 'features/AllowedUiLanguagesFeature';
import { setReduxCultureCode } from 'features/CultureCodeFeature';
import { setReduxCurrency } from 'features/CurrencyFeature';
import { setReduxLanguages } from 'features/LanguagesFeature';
import { setReduxMenuBarOrder } from 'features/MenuBarOrderFeature';
import { setQuantityMethodCurrency } from 'features/QuantityMethodFeature';
import { setReduxUser } from 'features/UserFeature';
import { setReduxUserSetting } from 'features/UserSettingsFeature';
import { useThunkDispatch } from 'features/featuresHelper';
import { fireAlert } from 'functions/fireAlert';
import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { getLanguageString } from 'functions/getLanguageString';
import { Optional } from 'functions/promiseExtensions';
import { restart } from 'functions/tokenFunctions';
import { Language } from 'types/Language/Language';
import { UserCornerInfo } from 'types/User/UserCornerInfo/UserCornerInfo';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

type IProps = {
	children: JSX.Element;
};

const ProtectedRouteContainer = ({ children }: IProps) => {
	const { i18n } = useTranslation();
	const dispatch = useThunkDispatch();

	useEffect((): void => {
		getQuantityMethodToRedux();
		getCurrencyToRedux();
		getAllowedEntryLanguagesToRedux();
		getRecipeListValueScope();
		getRecipeNutrientColumns();
		getAllowedUiLanguageToRedux();
		getAutoCompleteLanguagesToRedux();
		getMenuBarOrderToRedux();
	}, []);

	useEffect((): void => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		let cultureCode: string | null = getCultureCodeFromLocalStorage();
		let languages: Language[] = await getLanguagesFromApi();
		const allowedUiLanguages: string[] | null = await getAllowedUiLanguagesFromApi();
		cultureCode = getValidCultureCode(cultureCode ?? '', allowedUiLanguages ?? []);
		if (cultureCode) {
			if (isCultureCodeAllowed(allowedUiLanguages, cultureCode)) {
				const userCornerInfo = await getUserCornerInfoFromApi();
				const notNullCultureCode: string = cultureCode;
				userCornerInfo.do((x) => {
					const languageId = getLanguageString(notNullCultureCode);
					i18n.changeLanguage(languageId);

					dispatch(setReduxUser(x));
					dispatch(setReduxLanguages(languages));
					dispatch(setReduxCultureCode(notNullCultureCode));
				});
			} else {
				const userCornerInfo = await getUserCornerInfoFromApi();
				const notNullCultureCode: string = allowedUiLanguages![0];
				userCornerInfo.do((x) => {
					const languageId = getLanguageString(notNullCultureCode);
					i18n.changeLanguage(languageId);

					dispatch(setReduxUser(x));
					dispatch(setReduxLanguages(languages));
					dispatch(setReduxCultureCode(notNullCultureCode));
				});
			}
		} else {
			const userCornerInfo = await getUserCornerInfoFromApi();
			userCornerInfo.doAsync(async (x) => {
				if (isCultureCodeAllowed(allowedUiLanguages, x.cultureCode)) {
					localStorage.setItem('cultureCode', x.cultureCode);
					languages = await getLanguagesFromApi();

					const languageId = getLanguageString(x.cultureCode);
					i18n.changeLanguage(languageId);

					dispatch(setReduxUser(x));
					dispatch(setReduxLanguages(languages));
					dispatch(setReduxCultureCode(x.cultureCode));
				} else {
					x.cultureCode = allowedUiLanguages![0];
					localStorage.setItem('cultureCode', x.cultureCode);

					languages = await getLanguagesFromApi();

					const languageId = getLanguageString(x.cultureCode);
					i18n.changeLanguage(languageId);

					dispatch(setReduxUser(x));
					dispatch(setReduxLanguages(languages));
					dispatch(setReduxCultureCode(x.cultureCode));
				}
			});
		}
	};

	const getAllowedUiLanguagesFromApi = async (): Promise<string[] | null> => {
		const response = await apiGetSettingKey.callApi('UI.Languages');
		if (response.hasValue()) {
			return response.get().split(',');
		} else {
			return null;
		}
	};

	const isCultureCodeAllowed = (languages: string[] | null, cultureCode: string): boolean => {
		if (languages) {
			if (languages.some((x) => x === cultureCode)) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const getUserCornerInfoFromApi = async (): Promise<Optional<UserCornerInfo>> => {
		const response = await apiGetUserCornerInfo.callApi();
		return response;
	};

	const getLanguagesFromApi = async (): Promise<Language[]> => {
		const response = await apiGetLanguage.callApi();
		return response.getOrDefault([]);
	};

	const getQuantityMethodToRedux = async (): Promise<Optional<any>> => {
		const response = await apiGetSettingKey.callApi('CalculationSettings.QuantityMethod');
		response.do((x: any) => {
			dispatch(setQuantityMethodCurrency(x));
		});
		return response;
	};

	const getCurrencyToRedux = async (): Promise<void> => {
		const userCurrency = await apiGetUserSettingKey.callApi('Price.Currency');
		if (userCurrency.hasValue()) {
			userCurrency.do((x) => dispatch(setReduxCurrency(x)));
		} else {
			const currency = await apiGetSettingKey.callApi('CurrencySettings.PrimaryCurrency');
			currency.do((x) => dispatch(setReduxCurrency(x)));
		}
	};

	const getAllowedEntryLanguagesToRedux = async (): Promise<void> => {
		const response = await apiGetSettingKey.callApi('RecipeSettings.EntryLanguages');
		response.do((x) => dispatch(setReduxAllowedEntryLanguages(x.split(','))));
	};

	const getMenuBarOrderToRedux = async (): Promise<void> => {
		const response = await apiGetSettingKey.callApi('UI.MenuBarOrder');
		response.do((x) => dispatch(setReduxMenuBarOrder(x.split(','))));
	};

	const getAutoCompleteLanguagesToRedux = async (): Promise<void> => {
		const response = await apiGetSettingKey.callApi('RecipeSettings.AutoCompleteLanguages');
		response.do((x) => dispatch(setReduxAutoCompleteLanguages(x.split(','))));
	};

	const getRecipeListValueScope = async (): Promise<void> => {
		dispatch(
			setReduxUserSetting(
				await UserSettings.getRecipeListValueScopeFromApi(UserSettings.create())
			)
		);
	};

	const getRecipeNutrientColumns = async (): Promise<void> => {
		dispatch(
			setReduxUserSetting(
				await UserSettings.getRecipeNutrientColumnsFromApi(UserSettings.create())
			)
		);
	};

	const getAllowedUiLanguageToRedux = async (): Promise<void> => {
		const response = await apiGetSettingKey.callApi('UI.Languages');
		response.do((x) => {
			if (x === null || x === '') {
				restart();
				fireAlert(
					2,
					t('alerts:ALERT_UI_LANGUAGES_TITLE'),
					t('alerts:ALERT_UI_LANGUAGES_TEXT')
				);
			} else {
				dispatch(setReduxAllowedUiLanguages(x.split(',')));
			}
		});
	};

	const getValidCultureCode = (cultureCode: string, languages: string[]): string | null => {
		const language: string | undefined = languages.find((language: string) => {
			return language === cultureCode;
		});
		if (language) return language;
		return null;
	};

	return (
		<>
			<MainNavigationTop />
			<div id="root-child">
				<MainNavigationLeft />
				<div id="main-area">
					<div id="main-container">{children}</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default ProtectedRouteContainer;
