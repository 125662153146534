import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import { ISelectItem } from 'interfaces/ISelectItem';
import { UserRole } from 'types/User/Admin/UserRole/UserRole';

interface IProps {
	rolesAll: UserRole[];
	rolesUser: UserRole[];
	handleAddRole: (input: ISelectItem[]) => void;
}

const ChangeRoles = (props: IProps) => {
	const { t } = useTranslation();
	const [suggestedRoles, setSuggestedRoles] = useState<ISelectItem[]>([]);

	const mapToISelectItem = (input: UserRole[]): ISelectItem[] => {
		return input.map((role) => {
			return {
				id: role.id,
				name: role.name,
			};
		});
	};

	const getRolesStartingWith = async (searchTerm: string): Promise<void> => {
		const filteredRoles: UserRole[] = props.rolesAll.filter((e: UserRole) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);

		setSuggestedRoles(filteredRoles);
	};

	return (
		<CardTransparent
			title={t('user:ROLE_CONFIGURATION')}
			className="mb-3"
			content={
				<>
					<MultiSearchSelect
						savedItems={mapToISelectItem(props.rolesUser)}
						setSavedItems={props.handleAddRole}
						displaySavedItems="bottom"
						backgroundColor={'#efefef'}
						suggestedItems={suggestedRoles}
						handleChangeSearchTerm={getRolesStartingWith}
						label={t('user:ROLE_ADD')}
						triggerSearchLetterAmount={0}
					/>
				</>
			}
		/>
	);
};

export default ChangeRoles;
