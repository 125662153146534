import { ChannelLight } from 'types/Channel/ChannelLight';

export type ChannelMedia = {
	id: string;
	url: string;
	availableSizes: number[];
	channels: ChannelLight[];
};

export const defaultChannelMedia = (): ChannelMedia => {
	return {
		id: '',
		url: '',
		availableSizes: [],
		channels: [],
	};
};
