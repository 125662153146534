import axios from 'axios';

import { UnitType } from 'enums/unitType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url: string = '/backend-service/Unit';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	type: UnitType | null;
	factorToMilliliter: number | null;
	factorToGram: number | null;
	factorToOne: number | null;
	nameSingularTranslations: {
		[key: string]: string;
	};
	namePluralTranslations: {
		[key: string]: string;
	};
	shortNamePluralTranslations: {
		[key: string]: string;
	};
	shortNameSingularTranslations: {
		[key: string]: string;
	};
	synonyms: Synonyms;
	isAirDefinition: boolean;
}

export type Synonyms = {
	[key: string]: string[];
};

export type UnitSynonymTranslation = {
	[key: string]: string;
};

export interface Response {
	data: string;
}
