import * as Checked from 'types/Validation/Checked';
import * as Error from 'types/Validation/Error';
import * as Validation from 'types/Validation/Validation';

export type Type = {
	currentId: string;
	checked: Checked.Type;
};

export function create(): Type {
	return {
		currentId: '',
		checked: Checked.create(),
	};
}

export function changeId(state: Type, input: string): Type {
	return { ...state, currentId: input };
}

export function updateOne(
	state: Type,
	input: {
		error: Error.Type;
		value: boolean;
	}
): Type {
	Error.patchToApi(input.error, input.value);
	return {
		...state,
		checked: Checked.updateOne(state.checked, input.error, input.value),
	};
}

export function updateAll(
	state: Type,
	input: { validation: Validation.Type; value: boolean }
): Type {
	Validation.patchToApi(input.validation, input.value);
	return {
		...state,
		checked: Checked.updateAll(state.checked, input.validation, input.value),
	};
}
