import CarbonDioxideLabelManagementComponent from 'components/desktop/StandingData/CarbonDioxideLabel/Management/CarbonDioxideLabelManagementComponent';

const CarbonDioxideLabelArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<CarbonDioxideLabelManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default CarbonDioxideLabelArchiveContainer;
