import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';

export default class TagCategory {
	public id: string = '';
	public name: string = '';

	public constructor(input?: postMenuSearchV1.TagCategory | null) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: postMenuSearchV1.TagCategory | null): void {
		if (input) {
			this.id = input.id;
			this.name = input.name ?? '';
		}
	}
}
