import * as apiPost from 'api/nutriScoreNutrientPoints/PostNutriScoreNutrientPointsCreateNutriScoreNutrientPointsForNutrientAndMethodV1';
import * as apiPut from 'api/nutriScoreNutrientPoints/PutNutriScoreNutrientPointsUpdateAllPointsForNutrientAndMethodV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { NutriScoreNutrient } from 'types/NutriScoreNutrient/NutriScoreNutrient';

export const mapToRequest = (input: NutriScoreNutrient): apiPut.Request & apiPost.Request => {
	return {
		nutrientId: input.nutrientId,
		pointType: input.pointType as NutriScoreCategory,
		isPositive: input.isPositive,
		isProtein: input.isProtein,
		points: input.points,
	};
};
