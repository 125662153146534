import * as apiGet from 'api/ingredientCategory/GetIngredientCategoryIdV1';
import * as apiPost from 'api/ingredientCategory/PostIngredientCategoryV1';
import * as apiPut from 'api/ingredientCategory/PutIngredientCategoryV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class IngredientCategory {
	public id: string = '';
	public parent: apiGet.ParentCategory | null = null;
	public nameTranslations: LocalizedTranslation<string> = {};
	public nutriScoreCategory: NutriScoreCategory = NutriScoreCategory.StandardFood;

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			parentId: this.parent ? this.parent.id : null,
			nutriScoreCategory: this.nutriScoreCategory,
			nameTranslations: this.nameTranslations,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			parentId: this.parent ? this.parent.id : null,
			nutriScoreCategory: this.nutriScoreCategory,
			nameTranslations: this.nameTranslations,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.nameTranslations = input.nameTranslations;
		this.id = input.id;
		this.nutriScoreCategory = input.nutriScoreCategory;
		this.parent = input.parent;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
	}
}
