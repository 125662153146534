import * as api from 'api/recipe/PostRecipeCalculateV1';
import PricePerAccessory from 'classes/Recipe/Detail/Calculation/PricePerAccessory';

export default class PricePerAccessoryList {
	public items: PricePerAccessory[] = [];

	public constructor(input?: api.PricePerAccessory[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.PricePerAccessory[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new PricePerAccessory(i));
		}
	}
}
