import { AirUrlParams } from 'hooks/useAirNavigation';
import * as Range from 'types/_general/Range/Range';

export type Type = {
	id: string;
	min: number | null;
	max: number | null;
	left: number | null;
	right: number | null;
	minArea: number | null;
	maxArea: number | null;
};

export function create() {
	return {
		id: '',
		min: null,
		max: null,
		left: null,
		right: null,
		minArea: null,
		maxArea: null,
	};
}

export function reset(input: Type): Type {
	return {
		id: input.id,
		left: input.min,
		max: input.max,
		maxArea: input.max,
		min: input.min,
		minArea: input.min,
		right: input.max,
	};
}

export function initialize(limit: number | null): Type {
	return {
		...create(),
		max: limit,
		right: limit,
	};
}

export function mapToApi(input: Type): Range.Type | undefined {
	const result: Range.Type = {
		minimum: null,
		maximum: null,
	};
	if (input.left !== input.min) result.minimum = input.left;
	if (input.right !== input.max) result.maximum = input.right;
	if (result.minimum || result.maximum) return result;
	return undefined;
}

export function mapFromApiSpecial(
	slider: Type,
	rangeMenu: Range.Type,
	rangeRecipe: Range.Type
): Type {
	const min = Range.getMinimum(rangeRecipe, rangeMenu);
	const max = Range.getMaximum(rangeRecipe, rangeMenu);
	return {
		...create(),
		min: slider.min ?? min,
		max: slider.max ?? max,
		left: slider.left,
		right: slider.right,
		minArea: min,
		maxArea: max,
	};
}

export function mapFromUrl(slider: Type, input: AirUrlParams): Type {
	let left: number = 0;
	let right: number = 0;
	let min: number = 0;
	let max: number = 0;

	for (const [key, value] of Object.entries(input)) {
		switch (key) {
			case slider.id + 'Left':
				left = Number(value);
				break;
			case slider.id + 'Right':
				right = Number(value);
				break;
			case slider.id + 'Min':
				min = Number(value);
				break;
			case slider.id + 'Max':
				max = Number(value);
				break;
		}
	}
	return {
		...slider,
		min: min,
		max: max,
		left: left,
		right: right,
		minArea: min,
		maxArea: max,
	};
}
