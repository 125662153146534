import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import NutriScoreLabel from 'classes/StandingData/NutriScoreLabel/Search/NutriScoreLabel';
import NutriScoreLabelList from 'classes/StandingData/NutriScoreLabel/Search/NutriScoreLabelList';
import Search from 'classes/StandingData/NutriScoreLabel/Search/Search';
import NutriScoreLabelDetail from 'components/desktop/StandingData/NutriScoreLabel/Detail/NutriScoreLabelDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const NutriScoreLabelManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [nutriScoreLabelList, setNutriScoreLabelList] = useState<NutriScoreLabelList>(
		new NutriScoreLabelList()
	);
	const [search, setSearch] = useState<Search>(new Search());

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getNutriScoreLabels();
		} else {
			await getNutriScoreLabels();
		}
		setSearch(clone(search));
	};

	const getNutriScoreLabels = async () => {
		setIsLoading(true);
		const response = await nutriScoreLabelList.callApi(search.mapToRequest());
		response.do((x) => {
			nutriScoreLabelList.mapFromApi(x);
			setNutriScoreLabelList(clone(nutriScoreLabelList));
		});
		setIsLoading(false);
	};

	const getSuggestedNutriScoreLabels = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await nutriScoreLabelList.search(searchTerm, props.archive ?? false);
		} else {
			nutriScoreLabelList.filtered = [];
		}

		setNutriScoreLabelList(clone(nutriScoreLabelList));
		return nutriScoreLabelList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getNutriScoreLabels();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;

		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveNutriScoreLabel = async (
		nutriScoreLabel: NutriScoreLabel
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:NUTRISCORELABEL_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await nutriScoreLabel.callApiDelete();
				await Swal.fire({
					title: t('standingData:NUTRISCORELABEL_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getNutriScoreLabels();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreNutriScoreLabel = async (
		nutriScoreLabel: NutriScoreLabel
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:NUTRISCORELABEL_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await nutriScoreLabel.callApiRestore();
				await Swal.fire({
					title: t('standingData:NUTRISCORELABEL_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getNutriScoreLabels();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getYesOrNo = (input: boolean): string => {
		if (input) {
			return t('_general:YES');
		} else {
			return t('_general:NO');
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataDelete = (input: NutriScoreLabel): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveNutriScoreLabel(input),
			};
		}
	};

	const getButtonDataRestore = (input: NutriScoreLabel): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreNutriScoreLabel(input),
			};
		}
	};

	const renderButtons = (input: NutriScoreLabel): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const ListRows = (): Row[] => {
		return nutriScoreLabelList.all.map((label: NutriScoreLabel) => {
			return {
				id: label.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="d-flex flex-row" style={{ gap: '0.8em' }}>
							<NutriScoreDiagram
								nutriScore={
									{ color: label.color, label: label.label } as NutriScore
								}
							/>
						</div>
						<div className="flex-fill">
							<span className="grey">{t('standingData:FLUID')}: </span>
							{getYesOrNo(label.isFluid)}
						</div>
						<div>{renderButtons(label)}</div>
					</div>
				),
			};
		});
	};

	return (
		<>
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:NUTRISCORELABEL_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_NUTRISCORELABEL')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:NUTRISCORELABEL_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedNutriScoreLabels}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{nutriScoreLabelList.totalCount + ' ' + t('_general:NUTRISCORELABEL')}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={ListRows()}
					overlay={<NutriScoreLabelDetail />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={nutriScoreLabelList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		</>
	);
};

export default NutriScoreLabelManagementComponent;
