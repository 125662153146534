import AllergenManagementComponent from 'components/desktop/StandingData/Allergen/Management/AllergenManagementComponent';

const AllergenArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<AllergenManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default AllergenArchiveContainer;
