import Item from 'classes/MenuPlan/Detail/Item';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import clone from 'functions/clone';
import { regexValidationServings } from 'regex/validation/Servings';

interface IProps {
	item: Item;
	menuPlan: MenuPlan;
	setMenuPlan: (input: MenuPlan) => void;
}

const ItemInputField = (props: IProps) => {
	const handleValueChange = async (input: string) => {
		props.item.amountOfServings = Number(input);
		await props.menuPlan.recalculateColumnsAndRows();
		props.setMenuPlan(clone(props.menuPlan));
	};

	return (
		<InputFieldSimple
			defaultValue={String(props.item.amountOfServings)}
			className="form-control-sm"
			handleValueChange={handleValueChange}
			validateRegex={regexValidationServings}
			onClick={(e: React.MouseEvent<HTMLDivElement>) => {
				e.stopPropagation();
			}}
		/>
	);
};

export default ItemInputField;
