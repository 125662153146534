import stringify from 'json-stable-stringify';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';
import Swal from 'sweetalert2';

import { Optional } from 'functions/promiseExtensions';
import { useStateAsync } from 'hooks/useStateAsync';

type SaveFunction<T> = () => Promise<Optional<T>>;

const useAirBlocker = <T>(object: T, saveFunction: SaveFunction<T>) => {
	const [cache, setCache] = useStateAsync(stringify(object));
	const { t } = useTranslation();
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return hasChanges() && currentLocation.pathname !== nextLocation.pathname;
	});

	useEffect(() => {
		probablyShowDialog();
	}, [blocker.state]);

	useEffect(() => {
		window.addEventListener('beforeunload', beforeUnloadEventListener);
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadEventListener);
		};
	}, [cache, object]);

	function beforeUnloadEventListener(e: BeforeUnloadEvent) {
		if (shouldStay()) {
			stay(e);
		} else {
			leave();
		}

		function shouldStay(): boolean {
			return hasChanges();
		}

		function stay(e: BeforeUnloadEvent) {
			e.preventDefault();
			// Chrome requires returnValue to be set
			e.returnValue = '';
		}

		function leave() {
			return;
		}
	}

	async function probablyShowDialog() {
		if (blocker.state !== 'blocked') return;
		const response = await showConfirmationDialog();
		if (response.isDismissed) blocker.reset();
		if (response.isDenied) blocker.proceed();
		if (response.isConfirmed) {
			const response = await saveFunction();
			if (response.hasValue()) {
				blocker.proceed();
			} else {
				blocker.reset();
			}
		}
	}

	function hasChanges(): boolean {
		return stringify(object) !== cache;
	}

	async function updateCache(newObject: T) {
		await setCache(stringify(newObject));
	}

	async function showConfirmationDialog() {
		return await Swal.fire({
			icon: 'warning',
			iconColor: '#ff0004',
			background: 'white',
			confirmButtonText: t('_general:BTN_SAVE'),
			denyButtonText: t('_general:BTN_LEAVE'),
			cancelButtonText: t('_general:CANCEL'),
			showCancelButton: true,
			showDenyButton: true,
			showConfirmButton: true,
			title: t('_general:LEAVE_WITHOUT_SAVE'),
			confirmButtonColor: '#7da8d4',
			denyButtonColor: '#ff0004',
			customClass: {
				confirmButton: 'btn btn-primary',
				denyButton: 'btn btn-danger',
				cancelButton: 'btn',
				popup: 'alert alert-1',
			},
			position: 'top',
		});
	}

	return { updateCache, hasChanges };
};

export default useAirBlocker;
