import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiSearch from 'api/recipeCategory/PostRecipeCategorySearchV1';
import RecipeCategory from 'classes/StandingData/RecipeCategory/Search/RecipeCategory';
import RecipeCategoryList from 'classes/StandingData/RecipeCategory/Search/RecipeCategoryList';
import Search from 'classes/StandingData/RecipeCategory/Search/Search';
import RecipeCategoryDetailComponent from 'components/desktop/StandingData/RecipeCategory/Detail/RecipeCategoryDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const RecipeCategoryManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [recipeCategoryList, setRecipeCategoryList] = useState<RecipeCategoryList>(
		new RecipeCategoryList()
	);
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.name.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getRecipeCategories();
		} else {
			await getRecipeCategories();
		}
		setSearch(clone(search));
	};

	const handleRestoreRecipeCategory = async (recipeCategory: RecipeCategory): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:RECIPECATEGORY_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await recipeCategory.callApiRestore();
				await Swal.fire({
					title: t('standingData:RECIPECATEGORY_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getRecipeCategories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveRecipeCategory = async (recipeCategory: RecipeCategory): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:RECIPECATEGORY_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await recipeCategory.callApiDelete();
				await Swal.fire({
					title: t('standingData:RECIPECATEGORY_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getRecipeCategories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const getRecipeCategories = async () => {
		setIsLoading(true);
		try {
			const response: apiSearch.ResponseData | null = await recipeCategoryList.callApi(
				search.mapToRequest()
			);
			if (response) {
				recipeCategoryList.mapFromApi(response);
				setRecipeCategoryList(clone(recipeCategoryList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getRecipeCategories();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getSuggestedRecipeCategories = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await recipeCategoryList.search(searchTerm, props.archive ?? false);
		} else {
			recipeCategoryList.filtered = [];
		}

		setRecipeCategoryList(clone(recipeCategoryList));
		return recipeCategoryList.filtered;
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataDelete = (input: RecipeCategory): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveRecipeCategory(input),
			};
		}
	};

	const getButtonDataRestore = (input: RecipeCategory): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreRecipeCategory(input),
			};
		}
	};

	const renderButtons = (input: RecipeCategory): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return recipeCategoryList.all.map((category: RecipeCategory) => {
			return {
				id: category.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="flex-fill">{category.name}</div>
						<div>{renderButtons(category)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = (): JSX.Element => {
		return (
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:RECIPECATEGORY_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_RECIPECATEGORY')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:RECIPECATEGORY_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedRecipeCategories}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{`${recipeCategoryList.totalCount} ${t('_general:RECIPECATEGORIES')}`}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={renderListRows()}
					overlay={<RecipeCategoryDetailComponent />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={recipeCategoryList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeCategoryManagementComponent;
