import { t } from 'i18next';

import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Row from 'classes/MenuPlan/Detail/Row';
import TranslatableRowInput from 'components/desktop/_general/Input/TranslatableInput/TranslatableRowInput';
import clone from 'functions/clone';
import { Language } from 'types/Language/Language';

interface IProps {
	disabled?: boolean;
	entryLanguage: string;
	languages: Language[] | undefined;
	menuPlan: MenuPlan;
	selectedCultureCode: string;
	setMenuPlan: (input: MenuPlan) => void;
}

const RowManagement = (props: IProps) => {
	function handleChangeRowTitle(input: string, rowIndex: number): void {
		const value: string = input;
		props.menuPlan.changeRowTitle(rowIndex, props.selectedCultureCode, value);
		props.setMenuPlan(clone(props.menuPlan));
	}

	async function handleTranslate(rowIndex: number): Promise<void> {
		await props.menuPlan.translateRowTitle(
			rowIndex,
			props.entryLanguage,
			props.selectedCultureCode
		);
		props.setMenuPlan(clone(props.menuPlan));
	}

	const isTranslatable = (): boolean => {
		if (props.disabled) return false;
		if (props.languages) {
			if (props.entryLanguage === props.selectedCultureCode) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	return (
		<>
			<label>{t('menuPlan:ROWS')}</label>
			{props.menuPlan.rowList.all.map((row: Row, i: number) => {
				return (
					<div className="input-group mb-3" key={i}>
						<TranslatableRowInput
							name=""
							value={row.name?.[props.selectedCultureCode] || ''}
							handleValueChange={handleChangeRowTitle}
							onTranslateClick={handleTranslate}
							translatable={isTranslatable()}
							disabled={props.disabled}
							errorKey={'Rows[' + i + '].NameTranslations'}
							rowIndex={i}
						/>
					</div>
				);
			})}
		</>
	);
};

export default RowManagement;
