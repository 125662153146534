import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<Role[]>> {
	const url: string = '/backend-service/Role';
	return axios.get(url).toOptionalMapped((x) => x.data.roles);
}

export interface Response {
	data: ResponseData;
}

export interface ResponseData {
	roles: Role[];
}

export type Role = {
	id: string;
	name: string;
};
