import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/Season/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	iconSvgId: string | null;
	display: boolean;
	associatedMonths: string | null;
	sortOrder: number;
	nameTranslations: {
		[key: string]: string;
	};
}
