import Archive from 'containers/desktop/StandingData/BaseIngredientCategory/Archive/BaseIngredientCategoryArchive';
import Management from 'containers/desktop/StandingData/BaseIngredientCategory/Management/BaseIngredientCategoryManagement';
import NutriScoreBaseIngredientCategoryManagement from 'containers/desktop/StandingData/BaseIngredientCategoryPoints/NutriScoreBaseIngredientCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const baseIngredientRoutes = [
	{
		path: '/baseIngredientCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/baseIngredientCategory/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/baseIngredientCategoryPoints',
		element: <NutriScoreBaseIngredientCategoryManagement />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
