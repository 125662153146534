import { useTranslation } from 'react-i18next';

import 'components/desktop/_general/SeasonalityBar/SeasonalityBar.scss';

interface IProps {
	seasonalityCode: number | null;
	setSeasonalityCode?: (input: number) => void;
	className?: string;
	showIfSeasonalityCodeIsZero?: boolean;
	withErrorKey?: boolean;
}

type Season = {
	name: number;
	active: boolean;
	value: number;
};

const SeasonalityBar = (props: IProps) => {
	const { t } = useTranslation();

	const handleClick = (input: number): void => {
		if (props.setSeasonalityCode) {
			if (props.seasonalityCode === null) {
				const result: number = 1 ^ input;
				props.setSeasonalityCode(result);
			} else {
				const result: number = props.seasonalityCode ^ input;
				props.setSeasonalityCode(result);
			}
		}
	};

	if (props.seasonalityCode !== 0 || props.showIfSeasonalityCodeIsZero) {
		const months: string[] = [
			t('months:JANUARY'),
			t('months:FEBRUARY'),
			t('months:MARCH'),
			t('months:APRIL'),
			t('months:MAY'),
			t('months:JUNE'),
			t('months:JULY'),
			t('months:AUGUST'),
			t('months:SEPTEMBER'),
			t('months:OCTOBER'),
			t('months:NOVEMBER'),
			t('months:DECEMBER'),
		];
		const seasons: Season[] = [];
		const binaryNumber: string = props.seasonalityCode
			? props.seasonalityCode!.toString(2).split('').reverse().join('')
			: '0';

		for (let i = 0; i < 12; i++) {
			seasons.push({
				name: i + 1,
				active: Boolean(binaryNumber[i] === '1'),
				value: 1 << i,
			});
		}

		return (
			<div className={props.className ? props.className : ''}>
				<label>{t('_general:SEASONS')}</label>
				<div
					className="seasonality-bar"
					error-key={props.withErrorKey ? 'AssociatedMonths' : undefined}
				>
					{seasons.map((e: Season, i: number) => (
						<div
							key={i}
							className="seasonality-bar-element"
							title={months[e.name - 1]}
							onClick={() => handleClick(e.value)}
						>
							<div id="square" className={e.active ? 'active' : ''}></div>
							<div id="symbol" className={e.active ? 'active' : ''}>
								{e.name}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
};

export default SeasonalityBar;
