import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import AllergenList from 'classes/MenuPlan/Detail/AllergenList';
import CarbonDioxideLabel from 'classes/MenuPlan/Detail/CarbonDioxideLabel';
import NutrientList from 'classes/MenuPlan/Detail/NutrientList';
import { ConstraintStatus } from 'enums/ConstraintStatus';
import { AllergenLogic } from 'enums/allergenLogic';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';

export default class Column {
	public allergenList: AllergenList = new AllergenList();
	public calorieSum: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public carbonDioxideValueSum: number | null = null;
	public id: number = 0;
	public isCaloriesConstraintMet: boolean = true;
	public isPriceConstraintMet: boolean = true;
	public nutrientList: NutrientList = new NutrientList();
	public priceSum: number | null = null;
	public priceSumTotal: number | null = null;

	public constructor(index?: number, data?: apiPostCalculate.Data) {
		if (!data) return;
		this.mapFromApiCalculate(index ?? 0, data, false);
	}

	public getNutrientPerPart(_nutrientValue: number): number {
		return 0;
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		return nutrientValue;
	}

	public getNutrientTotal(_nutrientValue: number): number {
		return 0;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.nutrientList.getNutrientCategoryGroups();
	}

	public mapFromApiCalculate(index: number, input: apiPostCalculate.Data, total: boolean) {
		if (total) {
			this.priceSumTotal = input.priceSum;
		} else {
			this.id = index;
			this.calorieSum = input.calorieSum;
			this.priceSum = input.priceSum;
			this.carbonDioxideValueSum = input.carbonDioxideValueSum;
			this.carbonDioxideLabel.mapFromApi(input?.carbonDioxideLabel);
			this.nutrientList.mapFromApi(input.nutrientValues);
			if (input.allergens) {
				this.allergenList.mapFromApi(
					input.allergens.map((e) => {
						return { ...e, logic: e.logic as AllergenLogic };
					})
				);
			}
		}
	}

	public recalculateConstraints(priceLimit: number, caloriesLimit: number): void {
		this.isPriceConstraintMet = this.isConstraintMet(this.priceSum ?? 0, priceLimit);
		this.isCaloriesConstraintMet = this.isConstraintMet(this.calorieSum ?? 0, caloriesLimit);
	}

	public getConstraintStatusPrice(): ConstraintStatus {
		if (this.isPriceConstraintMet) {
			return ConstraintStatus.FullyMet;
		} else {
			return ConstraintStatus.NotMet;
		}
	}

	public getConstraintStatusCalories(): ConstraintStatus {
		if (this.isCaloriesConstraintMet) {
			return ConstraintStatus.FullyMet;
		} else {
			return ConstraintStatus.NotMet;
		}
	}

	private isConstraintMet(value: number, limit: number): boolean {
		return value <= limit;
	}
}
