import { useSelector } from 'react-redux';

import LanguageNavigationPills from 'components/desktop/_general/Navigation/LanguageNavigationPills';
import { isUserWriter } from 'functions/tokenFunctions';
import { ILanguageNavigationPill } from 'interfaces/ILanguageNavigationPill';
import { RootState } from 'reducers/rootReducer';
import { Language, defaultLanguage } from 'types/Language/Language';

interface IProps {
	content: JSX.Element;
	selectedLanguage: string;
	handleSelectLanguage: (e: any, selectedLanguage: string) => void;
	translatable: boolean;
	onTranslateClick?: () => void;
	entryLanguage?: string;
}

const LanguageSwitch = (props: IProps) => {
	const readOnly: boolean = !isUserWriter();
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);

	const isTranslatable = (): boolean => {
		if (props.translatable) {
			if (reduxLanguages.length > 0) {
				if (props.entryLanguage == props.selectedLanguage) {
					return false;
				} else {
					return true;
				}
			} else {
				if (defaultLanguage().cultureCode == props.selectedLanguage) {
					return false;
				} else {
					return true;
				}
			}
		}
		return false;
	};

	const renderNavigationPills = () => {
		const navPillArray: ILanguageNavigationPill[] = Array.from(reduxLanguages).map(
			(language) => ({
				name: language.name,
				nameId: 'nav-' + language.cultureCode,
				content: <>{props.content}</>,
				cultureCode: language.cultureCode,
			})
		);

		return (
			<LanguageNavigationPills
				navigationPills={navPillArray}
				handleSelectLanguage={props.handleSelectLanguage}
				selectedLanguage={props.selectedLanguage}
				translatable={isTranslatable() && !readOnly}
				onTranslateClick={() => {
					if (props.onTranslateClick) props.onTranslateClick();
				}}
			/>
		);
	};

	return <>{renderNavigationPills()}</>;
};

export default LanguageSwitch;
