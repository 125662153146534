import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import 'components/desktop/_general/RangeDouble/RangeDouble.scss';
import { useAdvancedSearchHeight } from 'hooks/useWindowHeight';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { regexValidationCalories } from 'regex/validation/Calories';

interface IProps {
	areaMin: number;
	areaMax: number;
	totalMin: number;
	totalMax: number;
	defaultControlLeft: number;
	defaultControlRight: number;
	handleChange: (left: number | null, right: number | null, id?: string) => void;
	id?: string;
}

const RangeDouble = (props: IProps) => {
	const { t } = useTranslation();

	const [controlLeft, setControlLeft] = useState<number | null>(null);
	const [controlRight, setControlRight] = useState<number | null>(null);
	const [showPopup, setShowPopup] = useState<boolean>(false);

	const slider = useRef<HTMLDivElement>(null);
	const area = useRef<HTMLDivElement>(null);
	const pointLeft = useRef<HTMLDivElement>(null);
	const pointRight = useRef<HTMLDivElement>(null);
	const windowWidth = useWindowWidth();
	const windowHeight = useAdvancedSearchHeight();

	let controlLeftGlobal: number | null = null;
	let controlRightGlobal: number | null = null;

	let shiftX: number = 0;

	useEffect((): void => {}, [windowWidth]);

	useEffect((): void => {}, [windowHeight]);

	useEffect(() => {
		setControlLeft(props.defaultControlLeft);
		setControlRight(props.defaultControlRight);
	}, [props.defaultControlLeft, props.defaultControlRight]);

	const onMouseDownLeft = (event: any): void => {
		event.preventDefault();

		if (pointLeft.current) {
			shiftX = event.clientX - pointLeft.current.getBoundingClientRect().left;
			document.addEventListener('mousemove', onMouseMoveLeft);
			document.addEventListener('mouseup', onMouseUpLeft);
		}
	};

	const onMouseMoveLeft = (event: any) => {
		if (slider.current && pointLeft.current && pointRight.current) {
			let newMin: number =
				event.clientX - shiftX - slider.current.getBoundingClientRect().left;
			if (newMin < 0) newMin = 0;

			const rightEdge = slider.current.offsetWidth - pointLeft.current.offsetWidth;

			if (newMin > rightEdge) newMin = rightEdge;

			const pointMaxLeft = Number.parseFloat(pointRight.current.style.left) - 20;
			if (newMin > pointMaxLeft) newMin = pointMaxLeft;

			setControlLeft(calculate(newMin + 20));
			controlLeftGlobal = calculate(newMin + 20);
		}
	};

	const onMouseDownRight = (event: any): void => {
		event.preventDefault();

		if (pointRight.current) {
			shiftX = event.clientX - pointRight.current.getBoundingClientRect().left;
			document.addEventListener('mousemove', onMouseMoveRight);
			document.addEventListener('mouseup', onMouseUpRight);
		}
	};

	const onMouseMoveRight = (event: any) => {
		if (slider.current && pointLeft.current && pointRight.current) {
			let newMax: number =
				event.clientX - shiftX - slider.current.getBoundingClientRect().left;
			if (newMax < 0) newMax = 0;

			const rightEdge = slider.current.offsetWidth - pointRight.current.offsetWidth;

			if (newMax > rightEdge) newMax = rightEdge;

			const pointMinLeft = Number.parseFloat(pointLeft.current.style.left) + 20;
			if (newMax < pointMinLeft) newMax = pointMinLeft;

			setControlRight(calculate(newMax));
			controlRightGlobal = calculate(newMax);
		}
	};

	const onMouseUpRight = () => {
		props.handleChange(null, controlRightGlobal, props.id);
		document.removeEventListener('mouseup', onMouseUpRight);
		document.removeEventListener('mousemove', onMouseMoveRight);
	};

	const onMouseUpLeft = () => {
		props.handleChange(controlLeftGlobal, null, props.id);
		document.removeEventListener('mouseup', onMouseUpLeft);
		document.removeEventListener('mousemove', onMouseMoveLeft);
	};

	const calculate = (input: number): number => {
		if (slider.current) {
			const end: number = slider.current.offsetWidth - 20;
			const start: number = 20;
			const percent: number = 100 * ((input - 20) / (end - start));
			const result = Math.round(
				((props.totalMax - props.totalMin) / 100) * percent + props.totalMin
			);
			if (result > props.totalMax) return props.totalMax;
			if (result < props.totalMin) return props.totalMin;
			return result;
		}
		return 0;
	};

	const calculateStyleLeftMin = (input: number): number => {
		if (slider.current) {
			const percent: number =
				100 * ((input - props.totalMin) / (props.totalMax - props.totalMin));
			const result = Math.round(((slider.current.offsetWidth - 20 - 20) / 100) * percent);

			if (result > slider.current.offsetWidth - 40) return slider.current.offsetWidth - 40;
			if (result < 0) return 0;
			return result;
		}
		return 0;
	};

	const calculateStyleLeftMax = (input: number): number => {
		if (slider.current) {
			const percent: number =
				100 * ((input - props.totalMin) / (props.totalMax - props.totalMin));
			const result = Math.round(((slider.current.offsetWidth - 20) / 100) * percent);
			if (result > slider.current.offsetWidth - 20) return slider.current.offsetWidth - 20;
			if (result < 0) return 0;
			return result;
		}
		return 0;
	};

	const handleInputFieldLeftChange = (input: string): void => {
		if (Number(input) === controlLeft) return;
		setControlLeft(Number(input));
		props.handleChange(Number(input), null, props.id);
	};

	const handleInputFieldRightChange = (input: string): void => {
		if (Number(input) === controlRight) return;
		setControlRight(Number(input));
		props.handleChange(null, Number(input), props.id);
	};

	const activatePopup = (): void => {
		setShowPopup(true);
	};

	const deactivatePopup = (): void => {
		setShowPopup(false);
	};

	const renderArea = (minArea: number, maxArea: number): JSX.Element => {
		let text = minArea + ' – ' + maxArea;
		if (minArea === 0 && maxArea === 0) text = t('_general:NO_VALUES');
		return (
			<div
				ref={area}
				id="area"
				style={{
					backgroundColor: showPopup ? 'black' : '',
					left: calculateStyleLeftArea() + 'px',
					width: calculateStyleWidthArea() + 'px',
					display: windowHeight ? 'inherit' : 'none',
				}}
			>
				<div id="area-popup" style={{ display: showPopup ? 'block' : 'none' }}>
					<div>
						<div>{text}</div>
					</div>
				</div>
			</div>
		);
	};

	const calculateStyleLeftArea = (): number => {
		let minArea: number = props.areaMin;
		if (minArea < (controlLeft ?? props.defaultControlLeft))
			minArea = controlLeft ?? props.defaultControlLeft;
		const left = calculateStyleLeftMin(minArea);
		return left + 10;
	};

	const calculateStyleWidthArea = (): number => {
		let minArea: number = props.areaMin;
		if (minArea < (controlLeft ?? props.defaultControlLeft))
			minArea = controlLeft ?? props.defaultControlLeft;
		if (minArea > (controlRight ?? props.defaultControlRight)) minArea = 0;

		let maxArea: number = props.areaMax;
		if (maxArea > (controlRight ?? props.defaultControlRight))
			maxArea = controlRight ?? props.defaultControlRight;
		if (maxArea < props.areaMin) maxArea = 0;

		const left = calculateStyleLeftMin(minArea);
		const right = calculateStyleLeftMax(maxArea);
		return right - left;
	};

	const calculateStyleLeftGap = (): number => {
		const left = calculateStyleLeftMin(controlLeft ?? props.defaultControlLeft ?? 0);
		return left + 10;
	};

	const calculateStyleWidthGap = (): number => {
		const left = calculateStyleLeftMin(controlLeft ?? props.defaultControlLeft ?? 0);
		const right = calculateStyleLeftMax(controlRight ?? props.defaultControlRight ?? 0);
		return right - left;
	};

	return (
		<>
			<div className="double-range-component">
				<InputFieldSimple
					defaultValue={String(Math.floor(controlLeft ?? props.defaultControlLeft ?? 0))}
					handleValueChange={handleInputFieldLeftChange}
					handleEnterDown={handleInputFieldLeftChange}
					validateRegex={regexValidationCalories}
				/>
				<div
					ref={slider}
					className="double-range-slider"
					onMouseEnter={activatePopup}
					onMouseLeave={deactivatePopup}
				>
					<div
						id="gap"
						style={{
							left: calculateStyleLeftGap() + 'px',
							width: calculateStyleWidthGap() + 'px',
							display: windowHeight ? 'inherit' : 'none',
						}}
					></div>
					{renderArea(props.areaMin, props.areaMax)}
					<div
						ref={pointLeft}
						style={{
							left:
								calculateStyleLeftMin(
									controlLeft !== null ? controlLeft : props.defaultControlLeft
								) + 'px',
							display: windowHeight ? 'inherit' : 'none',
						}}
						onMouseDown={onMouseDownLeft}
						id="pointMin"
					></div>
					<div
						ref={pointRight}
						style={{
							left:
								calculateStyleLeftMax(controlRight ?? props.defaultControlRight) +
								'px',
							display: windowHeight ? 'inherit' : 'none',
						}}
						onMouseDown={onMouseDownRight}
						id="pointMax"
					></div>
				</div>
				<InputFieldSimple
					defaultValue={String(Math.ceil(controlRight ?? props.defaultControlRight ?? 0))}
					handleValueChange={handleInputFieldRightChange}
					handleEnterDown={handleInputFieldRightChange}
					validateRegex={regexValidationCalories}
				/>
			</div>
		</>
	);
};

export default RangeDouble;
