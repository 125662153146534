import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import 'components/desktop/_general/CardSlider/CardSlider.scss';

export type SliderCard = {
	content: JSX.Element;
};

interface IProps {
	cards: SliderCard[];
}

const CardSlider = (props: IProps) => {
	const { t } = useTranslation();
	const sliderRef = useRef<HTMLDivElement>(null);

	const slideLeft = () => {
		if (sliderRef.current) sliderRef.current.scrollLeft -= 500;
	};

	const slideRight = () => {
		if (sliderRef.current) sliderRef.current.scrollLeft += 500;
	};

	const renderContent = () => {
		return (
			<div className="card-slider-container">
				{props.cards.length > 0 ? (
					<>
						<div className="card-slider-icon" onClick={slideLeft}>
							<FontAwesomeIcon icon={faChevronLeft} size="2x" />
						</div>
						<div className="card-slider" ref={sliderRef}>
							{props.cards.map((element, index) => (
								<React.Fragment key={index}>{element.content}</React.Fragment>
							))}
						</div>
						<div className="card-slider-icon" onClick={slideRight}>
							<FontAwesomeIcon icon={faChevronRight} size="2x" />
						</div>
					</>
				) : (
					<h2>{t('_general:NO_ITEMS')}</h2>
				)}
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default CardSlider;
