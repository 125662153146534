import { t } from 'i18next';
import { useSelector } from 'react-redux';

import Co2Select from 'components/desktop/_general/Co2Select/Co2SelectNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import RangeDouble from 'components/desktop/_general/RangeDouble/RangeDoubleNew';
import ENTITLEMENTS from 'enums/entitlements';
import { RootState } from 'reducers/rootReducer';
import * as ProductList from 'types/Product/Search/ProductList';
import * as Range from 'types/Product/Search/Range';
import * as Search from 'types/Product/Search/Search';
import * as Slider from 'types/Product/Search/Slider';
import * as Co2LabelList from 'types/_general/Store/Co2LabelList';
import * as ProductRanges from 'types/_general/Store/ProductRanges';

interface IProps {
	disabled: boolean;
	handleChangeCo2: (left: number | null, right: number | null) => void;
	handleChangeCo2Labels: (id: string) => void;
	handleChangePrice: (left: number | null, right: number | null) => void;
	handleReset: () => void;
	initialCo2Labels: Co2LabelList.Type;
	initialRanges: ProductRanges.Type;
	productList: ProductList.Type;
	search: Search.Type;
}

const AdvancedSearch = (props: IProps) => {
	const reduxCurrency = useSelector((state: RootState) => state.currency);

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<label className="mb-2 mt-2">{`${t('_general:COSTS')} ${t(
						'_general:TOTAL'
					)}`}</label>
					<RangeDoubleLabelWrapper
						rangeMinMax={props.initialRanges.price}
						rangeArea={props.productList.priceRange}
						slider={props.search.sliderPrice}
						label={`${t('_general:COSTS')} (${reduxCurrency})`}
						handleChange={props.handleChangePrice}
					/>
				</RenderIf>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<label className="mb-2 mt-2">{t('_general:CARBON_DIOXIDE')}</label>
					<RangeDoubleLabelWrapper
						rangeMinMax={props.initialRanges.co2}
						rangeArea={props.productList.co2Range}
						slider={props.search.sliderCo2}
						label={`${t('_general:CARBON_DIOXIDE')} (g)`}
						handleChange={props.handleChangeCo2}
					/>
				</RenderIf>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<Co2Select
						carbonDioxideLabels={props.initialCo2Labels}
						activeIds={props.search.co2LabelIds}
						setActiveId={props.handleChangeCo2Labels}
					/>
				</RenderIf>
			</div>
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-lg-8">{renderCol1()}</div>
				<div className="col-lg-4">{renderCol2()}</div>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{ textAlign: 'end' }}>
					<button className="btn btn-primary" onClick={props.handleReset}>
						{t('_general:RESET')}
					</button>
				</div>
			</div>
		</>
	);
};

export default AdvancedSearch;

const RangeDoubleLabelWrapper = (props: {
	rangeArea: Range.Type;
	rangeMinMax: Range.Type;
	slider: Slider.Type;
	label: string;
	handleChange: (left: number | null, right: number | null) => void;
}) => {
	const { rangeArea, rangeMinMax, slider } = props;

	return (
		<RangeDouble
			areaMax={rangeArea.maximum ?? rangeMinMax.maximum ?? 0}
			areaMin={rangeArea.minimum ?? rangeMinMax.minimum ?? 0}
			defaultControlLeft={slider.left ?? rangeMinMax.minimum ?? 0}
			defaultControlRight={slider.right ?? rangeMinMax.maximum ?? 0}
			handleChange={props.handleChange}
			totalMax={Slider.getMaxRoundUp(rangeMinMax.maximum ?? 0)}
			totalMin={Slider.getMinRoundDown(rangeMinMax.minimum ?? 0)}
		/>
	);
};
