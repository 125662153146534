import { useTranslation } from 'react-i18next';

import Allergen from 'classes/StandingData/Allergen/Allergen';

interface IProps {
	allergen: Allergen;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AllergenLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<b>{t('_general:POSITIVE')}</b>
						<div className="row mb-3">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={
										props.allergen.positiveNameTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="positiveNameTranslations"
									error-key={'PositiveNameTranslations'}
								/>
							</div>
						</div>
						<b>{t('_general:NEGATIVE')}</b>
						<div className="row mb-3">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={
										props.allergen.negativeNameTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="negativeNameTranslations"
									error-key={'NegativeNameTranslations'}
								/>
							</div>
						</div>
						<b>{t('_general:MAYCONTAIN')}</b>
						<div className="row">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={
										props.allergen.mayContainNameTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="mayContainNameTranslations"
									error-key={'MayContainNameTranslations'}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AllergenLanguageInput;
