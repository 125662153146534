import * as apiSW from 'api/activity/GetActivityStartingWithV1';
import * as apiPostSearch from 'api/activity/PostActivitySearchV1';
import Activity from 'classes/StandingData/Activity/Search/Activity';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class ActivityList {
	public all: Activity[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const activities = await apiSW.callApi(searchTerm, showOnlyDeleted);
		activities.hasValue() && this.mapFromApiSW(activities.get());
	}

	public async callApi(
		input: apiPostSearch.Request
	): Promise<Optional<apiPostSearch.ResponseData>> {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const activity: Activity = new Activity(i);
			this.all.push(activity);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseActivity[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
