import * as apiSW from 'api/status/GetStatusStartingWithV1';
import * as apiPostSearch from 'api/status/PostStatusSearchV1';
import * as apiPutList from 'api/status/PutStatusV1';
import Status from 'classes/StandingData/Status/Search/Status';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class StatusList {
	public all: Status[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const status: apiSW.ResponseStatus[] = await this.callApiSW(searchTerm, showOnlyDeleted);
		this.mapFromApiSW(status);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	public async callApiPutList(): Promise<void> {
		await apiPutList.callApi(this.mapToApiPutList());
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseStatus[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	private mapToApiPutList(): apiPutList.RequestStatus[] {
		const output: apiPutList.RequestStatus[] = [];
		this.all.forEach((status: Status) => {
			output.push({ id: status.id, order: status.order });
		});
		return output;
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const status: Status = new Status(i);
			this.all.push(status);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseStatus[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
