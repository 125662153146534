import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/Version';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	version: string;
	buildDateUtc: string;
	buildId: number;
	branch: string;
	hash: string;
	componentVersions: ComponentVersion[];
};

export type ComponentVersion = {
	componentName: string;
	version: string;
	buildDateUtc: string;
	buildId: number;
	branch: string;
	hash: string;
};
