import * as apiSW from 'api/accessory/GetAccessoryStartingWithV1';
import * as apiSearch from 'api/accessory/PostAccessorySearchV1';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

import Accessory from './Accessory';

export default class AccessoryList {
	public all: Accessory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const accessories = await apiSW.callApi(searchTerm, showOnlyDeleted);
		accessories.hasValue() && this.mapFromApiSW(accessories.get());
	}

	public async callApi(input: apiSearch.Request): Promise<Optional<apiSearch.ResponseData>> {
		return await apiSearch.callApi(input);
	}

	public mapFromApi(input: apiSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const accessory: Accessory = new Accessory(i);
			this.all.push(accessory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseAccessory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
