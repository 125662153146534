import { kiloCaloriesToKilojouleFactor } from 'constants/kiloCaloriesToKilojouleFactor';
import { getNumberString } from 'functions/numberToString';

export function calculateParts(
	input: number,
	parts: number,
	cultureCode: string,
	convertToKilojoule: boolean = false
): string {
	if (parts <= 0) {
		return '-';
	}

	if (convertToKilojoule) {
		return getNumberString((input / parts) * kiloCaloriesToKilojouleFactor, cultureCode);
	} else {
		return getNumberString(input / parts, cultureCode);
	}
}

export function calculatePersons(
	input: number,
	persons: number,
	cultureCode: string,
	convertToKilojoule: boolean = false
): string {
	if (persons <= 0) {
		return '-';
	}

	if (convertToKilojoule) {
		return getNumberString((input / persons) * kiloCaloriesToKilojouleFactor, cultureCode);
	} else {
		return getNumberString(input / persons, cultureCode);
	}
}

export function calculatePer100Gram(
	input: number,
	weight: number,
	cultureCode: string,
	convertToKilojoule: boolean = false
): string {
	if (weight <= 0) {
		return '-';
	}

	let result = (input / weight) * 100;

	if (convertToKilojoule) {
		result = result * kiloCaloriesToKilojouleFactor;
	}
	return getNumberString(result, cultureCode);
}
