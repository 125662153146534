import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { ISelectItem } from 'interfaces/ISelectItem';

interface IProps {
	items: ISelectItem[];
	noItemsMessage: string;
}

const ISelectItemDisplay = (props: IProps) => {
	const renderContent = () => {
		return (
			<>
				<div className="overflow-auto">
					{props.items.map((item: ISelectItem, i: number) => (
						<ListItem
							iconSvgUrl={item.iconSvgUrl}
							key={i}
							text={item.name}
							title={item.title ?? undefined}
						/>
					))}
				</div>
				{props.items.length === 0 && (
					<>
						<span className="grey">{props.noItemsMessage}</span>
					</>
				)}
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ISelectItemDisplay;
