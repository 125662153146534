import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	url: string | null;
	width?: number;
	height?: number;
}

const SvgBig = (props: IProps) => {
	if (props.url) {
		return (
			<div className="d-flex justify-content-center">
				<object
					data={props.url}
					type="image/svg+xml"
					style={{
						width: props.width ? props.width + 'px' : undefined,
						height: props.height ? props.height + 'px' : undefined,
					}}
				></object>
			</div>
		);
	} else {
		return (
			<div className="d-flex justify-content-center">
				<div
					style={{
						width: props.width ? props.width + 'px' : undefined,
						height: props.height ? props.height + 'px' : undefined,
						minHeight: '56px',
						backgroundColor: '#808080',
						color: 'white',
					}}
					className="d-flex flex-row justify-content-center align-items-center"
				>
					<FontAwesomeIcon icon={faFileSlash as IconProp} size="lg" />
				</div>
			</div>
		);
	}
};

export default SvgBig;
