import * as apiSW from 'api/allergen/GetAllergenStartingWithV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Allergen {
	id: string = '';
	name: string = '';
	display: boolean = false;
	iconSvgUrl: string = '';
	logic: AllergenLogic = AllergenLogic.Positive;

	public constructor(input?: apiSW.ResponseAllergen) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public toSelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name,
			iconSvgUrl: this.iconSvgUrl,
			display: this.display,
		};
	}

	private mapFromApi(input: apiSW.ResponseAllergen): void {
		this.id = input.id;
		this.name = input.name;
		this.display = input.display;
		this.iconSvgUrl = input.iconSvgUrl;
		this.logic = input.logic;
	}
}
