import * as apiGet from 'api/nutrient/GetNutrientIdV1';
import * as apiPost from 'api/nutrient/PostNutrientV1';
import * as apiPut from 'api/nutrient/PutNutrientIdV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import NutrientCategory from 'classes/StandingData/Nutrient/NutrientCategory';
import Unit from 'classes/StandingData/Nutrient/Unit';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Nutrient {
	public id: string = '';
	public isMacroNutrient: boolean = false;
	public sortOrder: number = 0;
	public unit: Unit = new Unit();
	public nutrientCategory: NutrientCategory = new NutrientCategory();
	public nameTranslations: LocalizedTranslation<string> = {};
	public shortNameTranslations: LocalizedTranslation<string> = {};

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			isMacroNutrient: this.isMacroNutrient,
			sortOrder: this.sortOrder,
			shortNameTranslations: this.shortNameTranslations,
			nameTranslations: this.nameTranslations,
			unitId: this.unit.id,
			nutrientCategoryId: this.nutrientCategory.id,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			isMacroNutrient: this.isMacroNutrient,
			sortOrder: this.sortOrder,
			shortNameTranslations: this.shortNameTranslations,
			nameTranslations: this.nameTranslations,
			unitId: this.unit.id,
			nutrientCategoryId: this.nutrientCategory.id,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.nameTranslations = input.nameTranslations;
		this.id = input.id;
		this.isMacroNutrient = input.isMacroNutrient;
		this.nutrientCategory.mapFromApi(input.nutrientCategory);
		this.shortNameTranslations = input.shortNameTranslations;
		this.sortOrder = input.sortOrder;
		this.unit.mapFromApi(input.unit);
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
		this.shortNameTranslations[toCultureCode] = input;
	}
}
