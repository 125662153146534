export const setReduxAllowedEntryLanguages = (allowedEntryLanguages: string[]) => {
	return {
		type: 'allowedEntryLanguages/SET',
		payload: allowedEntryLanguages,
	};
};

export type AllowedEntryLanguagesAction = {
	type: string;
	payload: string[];
};

export const allowedEntryLanguagesReducer = (
	state: string[] = ['en-US'],
	action: AllowedEntryLanguagesAction
): string[] => {
	switch (action.type) {
		case 'allowedEntryLanguages/SET':
			return action.payload;
		default:
			return state;
	}
};
