import { getTextWidth } from 'functions/getTextWidth';

import {
	ColumnInformation,
	ColumnTypeWithWidth,
	TableData,
	isColumnTypeWithWidth,
} from './AirTableTypes';

const maxColumnWidth = 120;

function round(number: number, increment: number, offset: number) {
	return Math.ceil((number - offset) / increment) * increment + offset;
}

export const getColumnWidth = (column: ColumnInformation, data: TableData) => {
	const dataColumns = data.map((x) => x[column.key]);
	const padding = 16;
	return (
		round(
			Math.min(maxColumnWidth, [...dataColumns, column.columnName].maxBy(getTextWidth)),
			20,
			0
		) + padding
	);
};

export const getColumnStyle = (
	col: ColumnTypeWithWidth | ColumnInformation
): { [key: string]: string } => {
	if (isColumnTypeWithWidth(col)) {
		const width = col.width;
		const left = col.start;

		return {
			width: `${width}px`,
			minWidth: `${width}px`,
			left: `${left}px`,
		};
	}
	return {};
};
