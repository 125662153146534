import * as api from 'api/recipe/PostRecipeCalculateV1';

import Season from './Season';

export default class SeasonsPerIngredient {
	public ingredientIndex: number = 0;
	public ingredientId: string = '';
	public seasons: Season[] = [];

	public constructor(input?: api.SeasonsPerIngredient) {
		if (input) this.mapFromApi(input);
	}

	public getSeasonalityCode(): number {
		let output: number = 0;
		for (const i of this.seasons) {
			if (i.associatedMonthsFlag) {
				output = output + i.associatedMonthsFlag;
			}
		}
		return output;
	}

	private mapFromApi(input: api.SeasonsPerIngredient) {
		this.ingredientIndex = input.ingredientIndex;
		this.ingredientId = input.ingredientId;
		this.seasons = [];
		for (const i of input.seasons) {
			this.seasons.push(new Season(i));
		}
	}
}
