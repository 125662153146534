import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCalendarWeek,
	faCarrot,
	faSeedling,
	faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { faBurgerSoda, faKitchenSet } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ModificationList } from 'api/analytics/GetModificationsV1';
import { Statistic } from 'api/analytics/GetStatisticsV1';
import RecipeList from 'classes/Recipe/Search/RecipeList';
import ButtonCard from 'components/desktop/Dashboard/ButtonCard/ButtonCard';
import ChartDoughnut, {
	DoughnutData,
} from 'components/desktop/Dashboard/ChartDoughnut/ChartDoughnut';
import 'components/desktop/Dashboard/DashboardComponent.scss';
import LastRecipes from 'components/desktop/Dashboard/LastRecipes/LastRecipes';
import ChartBar from 'components/desktop/Dashboard/ModifiedResourcesChartBar/ModifiedResourcesChartBar';
import Card from 'components/desktop/_general/Card/Card';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';

interface IProps {
	isLoading: boolean;
	recipeList: RecipeList;
	recipeStatus: { isLoading: boolean; data: DoughnutData[] };
	modifications: ModificationList;
	statistics: Statistic;
}

const DashboardComponent = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<main className="container">
				<h1>{t('header:DASHBOARD')}</h1>
				<div className="dashboard-rows">
					<RenderIf permissions={[PERMISSIONS.READRECIPE]}>
						<div className="row">
							<div className="col-12">
								<LastRecipes
									recipes={props.recipeList}
									isLoading={props.isLoading}
								/>
							</div>
						</div>
					</RenderIf>
					<div className="row">
						<RenderIf permissions={[PERMISSIONS.READRECIPE]}>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:RECIPES')}
									icon={<FontAwesomeIcon icon={faUtensils} className="fa-2x" />}
									btn1={{
										title: t('dashboard:RECIPE_SEARCH'),
										link: '/recipe/search',
									}}
									btn2={{
										title: t('dashboard:RECIPE_CREATE'),
										link: '/recipe/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITERECIPE,
										]),
									}}
									btn3={{
										title: t('dashboard:RECIPE_ARCHIVE'),
										link: '/recipe/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITERECIPE,
										]),
									}}
								/>
							</div>
						</RenderIf>
						<RenderIf permissions={[PERMISSIONS.READINGREDIENT]}>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:INGREDIENTS')}
									icon={<FontAwesomeIcon icon={faCarrot} className="fa-2x" />}
									btn1={{
										title: t('dashboard:INGREDIENT_SEARCH'),
										link: '/ingredient/search',
									}}
									btn2={{
										title: t('dashboard:INGREDIENT_CREATE'),
										link: '/ingredient/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEINGREDIENT,
										]),
									}}
									btn3={{
										title: t('dashboard:INGREDIENT_ARCHIVE'),
										link: '/ingredient/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEINGREDIENT,
										]),
									}}
								/>
							</div>
						</RenderIf>
						<RenderIf permissions={[PERMISSIONS.READBASEINGREDIENT]}>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:BASEINGREDIENTS')}
									icon={<FontAwesomeIcon icon={faSeedling} className="fa-2x" />}
									btn1={{
										title: t('dashboard:BASEINGREDIENT_SEARCH'),
										link: '/baseingredient/search',
									}}
									btn2={{
										title: t('dashboard:BASEINGREDIENT_CREATE'),
										link: '/baseingredient/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEBASEINGREDIENT,
										]),
									}}
									btn3={{
										title: t('dashboard:BASEINGREDIENT_ARCHIVE'),
										link: '/baseIngredient/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEBASEINGREDIENT,
										]),
									}}
								/>
							</div>
						</RenderIf>
					</div>
					<div className="row">
						<RenderIf
							entitlements={[ENTITLEMENTS.MENU]}
							permissions={[PERMISSIONS.READMENU]}
						>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:MENUS')}
									icon={
										<FontAwesomeIcon
											icon={faBurgerSoda as IconProp}
											className="fa-2x"
										/>
									}
									btn1={{
										title: t('dashboard:MENU_SEARCH'),
										link: '/menu/search',
									}}
									btn2={{
										title: t('dashboard:MENU_CREATE'),
										link: '/menu/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEMENU,
										]),
									}}
									btn3={{
										title: t('dashboard:MENU_ARCHIVE'),
										link: '/menu/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEMENU,
										]),
									}}
								/>
							</div>
						</RenderIf>
						<RenderIf
							entitlements={[ENTITLEMENTS.MENU_PLAN]}
							permissions={[PERMISSIONS.READMENUPLAN]}
						>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:MENU_PLANS')}
									icon={
										<FontAwesomeIcon icon={faCalendarWeek} className="fa-2x" />
									}
									btn1={{
										title: t('dashboard:MENUPLAN_SEARCH'),
										link: '/menuPlan/search',
									}}
									btn2={{
										title: t('dashboard:MENUPLAN_CREATE'),
										link: '/menuPlan/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEMENUPLAN,
										]),
									}}
									btn3={{
										title: t('dashboard:MENUPLAN_ARCHIVE'),
										link: '/menuPlan/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEMENUPLAN,
										]),
									}}
								/>
							</div>
						</RenderIf>
						<RenderIf permissions={[PERMISSIONS.READACCESSORY]}>
							<div className="col-lg-4 col-md-6">
								<ButtonCard
									title={t('_general:ACCESSORIES')}
									icon={
										<FontAwesomeIcon
											icon={faKitchenSet as IconProp}
											className="fa-2x"
										/>
									}
									btn1={{
										title: t('dashboard:ACCESSORY_SEARCH'),
										link: '/accessory/search',
									}}
									btn2={{
										title: t('dashboard:ACCESSORY_CREATE'),
										link: '/accessory/new',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEACCESSORY,
										]),
									}}
									btn3={{
										title: t('dashboard:ACCESSORY_ARCHIVE'),
										link: '/accessory/archive',
										disabled: !arePermissionsInUserPermissions([
											PERMISSIONS.WRITEACCESSORY,
										]),
									}}
								/>
							</div>
						</RenderIf>
					</div>
					<RenderIf permissions={[PERMISSIONS.READSTATISTICSANALYTICS]}>
						<div
							className="row justify-content-between"
							style={{
								border: '1px solid #dfdfdf',
								marginLeft: '0',
								marginRight: '0',
								color: '#696969',
							}}
						>
							<RenderIf
								permissions={[PERMISSIONS.READRECIPE, PERMISSIONS.WRITERECIPE]}
							>
								<div
									className="col-lg-2"
									style={{ textAlign: 'center', paddingTop: '10px' }}
								>
									<p>{t('dashboard:NUMBER_OF_RECIPES')}</p>
									<h2>
										{props.statistics['Recipe'] !== undefined
											? props.statistics['Recipe'].count
											: 0}
									</h2>
								</div>
							</RenderIf>
							<RenderIf
								permissions={[
									PERMISSIONS.READINGREDIENT,
									PERMISSIONS.WRITEINGREDIENT,
								]}
							>
								<div
									className="col-lg-2"
									style={{ textAlign: 'center', paddingTop: '10px' }}
								>
									<p>{t('dashboard:NUMBER_OF_INGREDIENTS')}</p>
									<h2>
										{props.statistics['Ingredient'] !== undefined
											? props.statistics['Ingredient'].count
											: 0}
									</h2>
								</div>
							</RenderIf>
							<RenderIf
								permissions={[
									PERMISSIONS.READBASEINGREDIENT,
									PERMISSIONS.WRITEBASEINGREDIENT,
								]}
							>
								<div
									className="col-lg-2"
									style={{ textAlign: 'center', paddingTop: '10px' }}
								>
									<p>{t('dashboard:NUMBER_OF_BASEINGREDIENTS')}</p>
									<h2>
										{props.statistics['BaseIngredient'] !== undefined
											? props.statistics['BaseIngredient'].count
											: 0}
									</h2>
								</div>
							</RenderIf>
							<RenderIf
								entitlements={[ENTITLEMENTS.MENU]}
								permissions={[PERMISSIONS.READMENU, PERMISSIONS.WRITEMENU]}
							>
								<div
									className="col-lg-2"
									style={{ textAlign: 'center', paddingTop: '10px' }}
								>
									<p>{t('dashboard:NUMBER_OF_MENUS')}</p>
									<h2>
										{props.statistics['Menu'] !== undefined
											? props.statistics['Menu'].count
											: 0}
									</h2>
								</div>
							</RenderIf>
							<RenderIf
								entitlements={[ENTITLEMENTS.MENU_PLAN]}
								permissions={[PERMISSIONS.READMENUPLAN, PERMISSIONS.WRITEMENUPLAN]}
							>
								<div
									className="col-lg-2"
									style={{ textAlign: 'center', paddingTop: '10px' }}
								>
									<p>{t('dashboard:NUMBER_OF_MENUPLANS')}</p>
									<h2>
										{props.statistics['MenuPlanner'] !== undefined
											? props.statistics['MenuPlanner'].count
											: 0}
									</h2>
								</div>
							</RenderIf>
						</div>
					</RenderIf>
					<div className="row">
						<RenderIf permissions={[PERMISSIONS.READRECIPESTATUSANALYTICS]}>
							<div className="col-lg-4 col-md-4">
								<Card
									title={t('dashboard:STATE_INFORMATION')}
									content={
										props.recipeStatus.isLoading ? (
											<div className="recipe-status-loading-box">
												<LoadingAnimation
													isLoading={props.recipeStatus.isLoading}
												/>
											</div>
										) : (
											<ChartDoughnut
												data={props.recipeStatus.data}
												style={{ maxHeight: '350px' }}
											/>
										)
									}
								/>
							</div>
						</RenderIf>
						<RenderIf permissions={[PERMISSIONS.READMODIFICATIONSANALYTICS]}>
							<div
								className={
									arePermissionsInUserPermissions([
										PERMISSIONS.READRECIPESTATUSANALYTICS,
									])
										? 'col-lg-8 col-md-8'
										: 'col-lg-12 col-md-12'
								}
							>
								<Card
									title={t('dashboard:MODIFIED_RESSOURCES')}
									content={<ChartBar modifications={props.modifications} />}
								/>
							</div>
						</RenderIf>
					</div>
				</div>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default DashboardComponent;
