import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/UserProfile';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export interface ResponseData {
	firstName: string;
	lastName: string;
	languageId: string;
	profilePicture: Image | null;
	nutrients: UserNutrients[];
	userSettings: UserSettings;
}

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type UserNutrients = {
	id: string;
	sortOrder: number;
	name: string;
	unitShortNameSingular: string;
	nutrientCategory: {
		id: string;
		name: string;
	};
};

export type UserSettings = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope': ValueScope;
};

export enum ValueScope {
	Total = 'Total',
	Person = 'Person',
	Parts = 'Parts',
	HundredGrams = 'HundredGrams',
}
