import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SimilarRecipesList from 'components/desktop/Recipe/SimilarRecipes/SimilarRecipesList';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { RootState } from 'reducers/rootReducer';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

type IProps = {
	recipeId: string | null;
	stepsTranslations: LocalizedTranslation<string>;
	shortDescriptionTranslations: LocalizedTranslation<string>;
};
export const SimilarRecipes = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getRecipeText = (): string => {
		if (
			props.shortDescriptionTranslations[reduxCultureCode] &&
			props.stepsTranslations[reduxCultureCode]
		) {
			return (
				props.shortDescriptionTranslations[reduxCultureCode] +
				props.stepsTranslations[reduxCultureCode]
			);
		} else if (
			!props.shortDescriptionTranslations[reduxCultureCode] &&
			props.stepsTranslations[reduxCultureCode]
		) {
			if (props.stepsTranslations[reduxCultureCode]) {
				return props.stepsTranslations[reduxCultureCode];
			} else {
				return '';
			}
		} else {
			return props.shortDescriptionTranslations[reduxCultureCode];
		}
	};

	return (
		<FoldableCard
			defaultOpen={true}
			title={t('recipe:SIMILAR_RECIPES')}
			ai={true}
			content={
				<>
					<SimilarRecipesList
						recipeId={props.recipeId}
						recipeText={getRecipeText()}
						uiLanguage={reduxCultureCode}
					/>
				</>
			}
		/>
	);
};
