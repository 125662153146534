import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import Status from 'classes/MenuPlan/Detail/Status';
import StatusList from 'classes/MenuPlan/Detail/StatusList';
import ISelectItemDisplay from 'components/desktop/MenuPlan/ISelectItemDisplay/ISelectItemDisplay';
import StatusEditor from 'components/desktop/MenuPlan/StatusEditor/StatusEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import LabeledCheckbox from 'components/desktop/_general/Checkbox/LabeledCheckbox';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { getPriceString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

interface IProps {
	object: MenuPlan | Menu | Recipe | Row | Item | Column;
	setObject: (input: Row | Item | null) => void;
	menuPlan: MenuPlan;
	setMenuPlan: (input: MenuPlan) => void;
	statusList: StatusList;
}

const MenuPlanSidebar = (props: IProps) => {
	const { t } = useTranslation();

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	const handleChangeStatus = (status: Status): void => {
		props.menuPlan.status = status;
		props.setMenuPlan(clone(props.menuPlan));
	};

	const handleChangeSystemStatus = (systemStatus: SystemStatus): void => {
		props.menuPlan.systemStatus = systemStatus;
		props.setMenuPlan(clone(props.menuPlan));
	};

	const handleChangeTags = (input: ISelectItem[]): void => {
		if (props.object instanceof Row) {
			props.object.tagList.mapFromISelectItems(input);
			props.setObject(clone(props.object));
		}
	};

	const searchTags = (input: string): void => {
		if (props.object instanceof Row) {
			props.object.tagList.search(input);
			props.setObject(clone(props.object));
		}
	};

	const renderTags = (): JSX.Element => {
		if (props.object instanceof Recipe || props.object instanceof Menu) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:TAGS')}
					content={
						<div className="card-body">
							<ISelectItemDisplay
								items={props.object.tagList.getSavedAsSelectItems()}
								noItemsMessage={t('_general:NO_TAGS')}
							/>
						</div>
					}
				/>
			);
		}
		if (props.object instanceof Row) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:TAGS')}
					content={
						<div className="card-body">
							<MultiSearchSelect
								savedItems={props.object.tagList.getSavedAsSelectItems()}
								displaySavedItems="bottom"
								setSavedItems={handleChangeTags}
								handleChangeSearchTerm={searchTags}
								suggestedItems={props.object.tagList.filtered}
								label={t('_general:TAGS')}
								triggerSearchLetterAmount={0}
								hideInputField={!menuPlanEditor}
								errorKey="Tags"
							/>
						</div>
					}
				/>
			);
		}
		return <></>;
	};

	const renderAllergens = (): JSX.Element => {
		if (
			props.object instanceof Recipe ||
			props.object instanceof Row ||
			props.object instanceof Column ||
			props.object instanceof Menu
		) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:ALLERGENS')}
					content={
						<div className="card-body">
							<ISelectItemDisplay
								items={props.object.allergenList.getSavedAsSelectItems()}
								noItemsMessage={t('_general:NO_ALLERGENS')}
							/>
						</div>
					}
				/>
			);
		}
		return <></>;
	};

	const renderCosts = (): JSX.Element => {
		if (props.object instanceof Menu || props.object instanceof Recipe) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:COSTS')}
					content={
						<div className="card-body">
							{reduxCurrency + ' '}
							<strong>
								{getPriceString(props.object.price ?? 0, reduxCultureCode)}
							</strong>
						</div>
					}
				/>
			);
		}
		return <></>;
	};

	const renderStatus = (): JSX.Element => {
		if (
			props.object instanceof Menu ||
			props.object instanceof MenuPlan ||
			props.object instanceof Recipe
		) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:STATUS')}
					content={
						<div className="card-body">
							<StatusEditor
								object={props.object}
								handleChangeStatus={handleChangeStatus}
								handleChangeSystemStatus={handleChangeSystemStatus}
								statusList={props.statusList}
								disabled={!menuPlanEditor}
							/>
						</div>
					}
				/>
			);
		}
		return <></>;
	};

	const renderSeasons = (): JSX.Element => {
		if (
			props.object instanceof Menu ||
			props.object instanceof Recipe ||
			props.object instanceof MenuPlan
		) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:SEASONS')}
					content={
						<div className="card-body">
							<ISelectItemDisplay
								items={props.object.seasonList.getSavedAsSelectItems()}
								noItemsMessage={t('_general:NO_SEASONS')}
							/>
						</div>
					}
				/>
			);
		}
		return <></>;
	};

	const renderMacroNutrientChartData = (): JSX.Element => {
		if (props.object instanceof Menu || props.object instanceof Recipe) {
			return (
				<FoldableCard
					defaultOpen={true}
					title={t('_general:NUTRI_DIAGRAM')}
					content={<NutritionDiagram data={props.object.getMacroNutrientChartData()} />}
				/>
			);
		}
		return <></>;
	};

	const handleToggle = (input: boolean): void => {
		if (input) {
			props.setObject(null);
		}
	};

	const renderCheckbox = (): JSX.Element => {
		return (
			<>
				<LabeledCheckbox
					label={t('menuPlan:SHOW_MENUPLAN_DETAILS')}
					checked={props.object instanceof MenuPlan}
					setChecked={handleToggle}
					disabled={props.object instanceof MenuPlan}
				/>
			</>
		);
	};

	return (
		<div className="d-flex flex-column" style={{ gap: '15px' }}>
			{renderCheckbox()}
			{renderStatus()}
			<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>{renderCosts()}</RenderIf>
			{renderTags()}
			{renderAllergens()}
			<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>{renderSeasons()}</RenderIf>
			{renderMacroNutrientChartData()}
		</div>
	);
};

export default MenuPlanSidebar;
