import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import Tag from 'classes/Ingredient/Search/Tag';
import { Icon } from 'types/Icon/Icon';

export default class TagList {
	public all: Tag[] = [];

	public constructor(input?: apiPostSearch.Tag[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): (Icon | null)[] {
		return this.all.map((e: Tag) => {
			return e.mapToIcon();
		});
	}

	public mapFromApi(input: apiPostSearch.Tag[] | null) {
		if (input) {
			for (const i of input) {
				const tag: Tag = new Tag(i);
				this.all.push(tag);
			}
		}
	}
}
