import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGetAllEmailAccounts from 'api/mailAccount/GetAllMailAccountsV1';
import * as apiGet from 'api/mailTemplate/GetMailTemplateIdV1';
import * as apiPut from 'api/mailTemplate/PutMailTemplateV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import MailTemplateLanguageInput from 'components/desktop/StandingData/MailTemplate/LanguageInput/MailTemplateLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { mapToRequest } from 'functions/mappers/MailTemplate/mapToRequest';
import { getIdFromUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';
import { MailTemplate, defaultMailTemplate } from 'types/MailTemplate/MailTemplate';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';

const MailTemplateDetail = () => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');
	const [mailTemplate, setMailTemplate] = useState<MailTemplate>(defaultMailTemplate);
	const [id, setId] = useState<string | null>('');
	const [emailAccounts, setEmailAccounts] = useState<
		apiGetAllEmailAccounts.ResponseEmailAccount[]
	>([]);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null) {
			setIsLoading(true);
			const response = await apiGet.callApi(id);
			if (response.hasValue()) {
				setMailTemplate(response.get());
				const responseMailAccounts = await apiGetAllEmailAccounts.callApi();
				responseMailAccounts.do((x) => setEmailAccounts(x.emailAccounts));
			}
			setIsLoading(false);
		}
	};

	const handleSubjectValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const mailTemplateCpy: MailTemplate = { ...mailTemplate };
		mailTemplateCpy.localizedSubjects[selectedCultureCode] = e.currentTarget.value;
		setMailTemplate(mailTemplateCpy);
	};

	const handleBodyValueChange = (value: string) => {
		const mailTemplateCpy: MailTemplate = { ...mailTemplate };
		mailTemplateCpy.localizedBodies[selectedCultureCode] = value;
		setMailTemplate(mailTemplateCpy);
	};

	const handleTranslate = async () => {
		await handleTranslateInput('localizedSubjects');
		await handleTranslateInput('localizedBodies');
	};

	const handleTranslateInput = async (name: string) => {
		const mailTemplateCpy: MailTemplate = { ...mailTemplate };
		const text: string = mailTemplateCpy[name][reduxCultureCode];

		if (!text) {
			return;
		}

		const translationDto = {
			fromCultureCode: reduxCultureCode,
			toCultureCode: selectedCultureCode,
			text: text,
		} as PostTranslationDto;

		const response = await apiPostTranslation.callApi(translationDto);
		mailTemplateCpy[name][selectedCultureCode] = response.getOrDefault('');
		setMailTemplate(mailTemplateCpy);
	};

	const handleSelectLanguage = (
		_e: React.ChangeEvent<HTMLButtonElement>,
		selectedLanguage: string
	) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveMailTemplate = (): void => {
		if (id !== null) {
			putMailTemplate();
		}
	};

	const putMailTemplate = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const putObject: apiPut.Request = mapToRequest(mailTemplate);
			await apiPut.callApi(mailTemplate.id, putObject);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleSelectEmailAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const id: string = e.target.value;
		const mailTemplateCpy = { ...mailTemplate };
		const index = emailAccounts.findIndex((x) => x.id == id);

		mailTemplateCpy.emailAccount = emailAccounts[index];

		setMailTemplate(mailTemplateCpy);
	};

	const renderSenderSelect = (): JSX.Element => {
		return (
			<select
				className="form-select"
				value={mailTemplate.emailAccount.id}
				onChange={handleSelectEmailAccount}
				error-key={'EmailAccount'}
			>
				{emailAccounts.map((e: apiGetAllEmailAccounts.ResponseEmailAccount, i: number) => {
					return (
						<option key={i} value={e.id}>
							{e.senderEmail}
						</option>
					);
				})}
			</select>
		);
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{mailTemplate.id ? (
								<>
									<h1>{t('standingData:EDIT_MAIL_TEMPLATE')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_MAIL_TEMPLATE')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveMailTemplate()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<label>{t('standingData:MAIL_ACCOUNTS')}</label>
						{renderSenderSelect()}
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-mg-12 col-xl-12 col-sm-12 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<MailTemplateLanguageInput
									mailTemplate={mailTemplate}
									selectedLanguage={selectedCultureCode}
									handleSubjectValueChange={handleSubjectValueChange}
									handleBodyValueChange={handleBodyValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MailTemplateDetail;
