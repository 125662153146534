import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import useDebounce from 'hooks/useDebounce';
import { ISelectItem } from 'interfaces/ISelectItem';

interface IProps {
	suggestedItems: ISelectItem[];
	setSavedItem: (savedItem: ISelectItem) => void;
	handleChangeSearchTerm: (searchTerm: string) => void;
	placeholderText?: string;
	label?: string;
	placeholderIsBlack?: boolean;
	triggerSearchLetterAmount?: number;
	className?: string;
	disabled?: boolean;
	debounceMilliSeconds?: number;
	keepSearchTermAfterSelection?: boolean;
	onEnter?: (searchTerm: string) => void;
	errorKey?: string;
}

const SuggestionSearchInput = (props: IProps) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectionIndex, setSelectionIndex] = useState<number>(-1);
	const [showSuggested, setShowSuggested] = useState<boolean>(false);

	const debouncedValue = useDebounce<string>(searchTerm, props.debounceMilliSeconds ?? 500);
	const currentListItem = useRef<HTMLDivElement | null>(null);
	const inputElement = useRef<HTMLInputElement | null>(null);

	useEffect((): void => {
		if (currentListItem.current)
			currentListItem.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
				inline: 'nearest',
			});
	}, [selectionIndex]);

	useEffect(() => {
		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (debouncedValue.length >= triggerSearchLetterAmount) {
			props.handleChangeSearchTerm(debouncedValue);
		}
	}, [debouncedValue]);

	const handleSelectItem = (item: ISelectItem) => {
		props.setSavedItem(item);

		if (props.keepSearchTermAfterSelection) {
			setSearchTerm(item.name);
			props.handleChangeSearchTerm(item.name);
		} else {
			setSearchTerm('');
			props.handleChangeSearchTerm('');
		}
		inputElement.current?.blur();
	};

	const onMouseDown = (item: ISelectItem) => {
		handleSelectItem(item);
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		const selectedItem = props.suggestedItems[selectionIndex];
		switch (e.key) {
			case 'ArrowDown':
				if (selectionIndex < props.suggestedItems.length - 1) {
					setSelectionIndex(selectionIndex + 1);
				}
				e.preventDefault();
				break;
			case 'ArrowUp':
				if (selectionIndex > 0) {
					setSelectionIndex(selectionIndex - 1);
				}
				e.preventDefault();
				break;
			case 'Enter':
				if (selectedItem) {
					handleSelectItem(selectedItem);
				} else {
					if (props.onEnter) {
						props.onEnter(searchTerm);
						inputElement.current?.blur();
					}
				}
				break;
			case 'Escape':
				inputElement.current?.blur();
				break;
		}
	};
	const onFocus = () => {
		if (!props.triggerSearchLetterAmount) {
			setSearchTerm('');
			props.handleChangeSearchTerm('');
		}
		setShowSuggested(true);
	};

	const onBlur = () => {
		setShowSuggested(false);
		setSelectionIndex(-1);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		setSearchTerm(value);
		setSelectionIndex(-1);

		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (value.length >= triggerSearchLetterAmount) {
			setShowSuggested(true);
		} else {
			setShowSuggested(false);
		}
	};

	const getInputClassName = (): string => {
		if (props.placeholderIsBlack) {
			return 'form-control black-placeholder';
		}
		return 'form-control';
	};

	return (
		<>
			{props.label ?? <label className="input-label">{props.label}</label>}
			<input
				className={getInputClassName()}
				type="text"
				value={searchTerm}
				onChange={onChange}
				onFocus={onFocus}
				onKeyDown={onKeyDown}
				disabled={props.disabled}
				onBlur={onBlur}
				placeholder={props.placeholderText ?? t('_general:INPUT_PLACEHOLDER_SEARCHTERM')}
				ref={inputElement}
				error-key={props.errorKey}
			/>
			{showSuggested && props.suggestedItems.length > 0 && (
				<div className="select-suggestions">
					{props.suggestedItems.map((item, index) => (
						<div
							className={index === selectionIndex ? 'active' : ''}
							ref={index === selectionIndex ? currentListItem : undefined}
							key={item.name + item.id}
							onMouseDown={() => onMouseDown(item)}
						>
							{item.name}
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default SuggestionSearchInput;
