import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiSW from 'api/season/GetSeasonStartingWithV1';
import Season from 'classes/MenuPlan/Detail/Season';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class SeasonList {
	public all: Season[] = [];
	public saved: Season[] = [];
	public filtered: ISelectItem[] = [];

	public constructor(input?: api.Season[]) {
		if (input) {
			this.mapFromApi(input, 'saved');
		}
	}

	public async initialize(): Promise<void> {
		if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON])) return;
		await this.callApi();
		this.transferAllToFiltered();
	}

	public async callApi(): Promise<void> {
		const response = await apiSW.callApi('', false);
		const seasons = response.get().map((x) => ({
			...x,
			source: null,
		}));
		response.hasValue() && this.mapFromApi(seasons, 'all');
	}

	public mapFromISelectItems(input: ISelectItem[]): void {
		const output: Season[] = [];
		for (const i of input) {
			const season: Season | undefined = this.getById(i.id);
			if (season) output.push(season);
		}
		this.saved = output;
	}

	public search(searchTerm: string): void {
		const filtered: Season[] = this.all.filter(
			(e: Season) => e.name?.toLowerCase().includes(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	public mapFromApi(input: api.Season[] | null, arrayName?: 'all' | 'saved') {
		this[arrayName ?? 'saved'] = [];
		if (input) {
			for (const i of input) {
				const season: Season = new Season(i);
				this[arrayName ?? 'saved'].push(season);
			}
		}
	}

	public mapToApiPut(): string[] {
		return this.saved.map((e: Season) => {
			return e.id;
		});
	}

	public getById(id: string): Season | undefined {
		return this.all.find((e: Season) => {
			return e.id === id;
		});
	}

	public getSavedAsSelectItems() {
		return this.mapToSelectItems(this.saved);
	}

	private mapToSelectItems(input: Season[]): ISelectItem[] {
		return input.map((e: Season) => {
			return e.mapToISelectItem();
		});
	}

	private transferAllToFiltered() {
		this.filtered = this.mapToSelectItems(this.all);
	}
}
