import * as Post from 'api/accessory/PostAccessoryIdV1';
import * as Put from 'api/accessory/PutAccessoryIdV1';
import { Accessory } from 'types/Accessory/Accessory';
import { CarbonDioxide } from 'types/Accessory/Accessory';
import { UnitWeights } from 'types/Accessory/Accessory';
import { Image } from 'types/Media/Image';

export function mapToSaveAccessory(input: Accessory): Put.Request & Post.Request {
	return {
		carbonDioxide: mapToCarbonDioxide(input.carbonDioxide),
		categories: mapToStringArrayOrNull(input.categories),
		characteristic: input.characteristic,
		density: input.density,
		descriptionTranslations: input.descriptionTranslations,
		generalInfoTranslations: input.generalInfoTranslations,
		imageId: mapToImageId(input.image),
		isPriceRecipeRelevant: true,
		namePluralTranslations: input.namePluralTranslations ?? {},
		nameSingularTranslations: input.nameSingularTranslations ?? {},
		price: input.price,
		synonyms: input.synonyms,
		tags: mapToStringArrayOrNull(input.tags),
		unitWeights: mapToUnitWeights(input.unitWeights),
	};
}

function mapToImageId(input: Image | null): string | null {
	if (!input) return null;
	if (input.id !== '') {
		return input.id;
	}
	return null;
}

function mapToUnitWeights(
	input: UnitWeights[] | null
): (Put.UnitWeights & Post.UnitWeights) | null {
	if (input === null) return null;
	const map = {} as Put.UnitWeights & Post.UnitWeights;
	input.forEach((u) => {
		map[u.id] = u.weight;
	});
	return map;
}

type ObjectWithId = {
	id: string;
};

function mapToStringArrayOrNull<T extends ObjectWithId>(input: T[] | null): string[] | null {
	if (input === null) return null;
	return input.map((e: T) => {
		return e.id;
	});
}

function mapToCarbonDioxide(
	input: CarbonDioxide[] | null
): Put.CarbonDioxide[] & Post.CarbonDioxide[] {
	if (input === null) return [];
	return input.map((c: CarbonDioxide) => {
		return {
			originLocationId: c.originLocationId,
			productionMethodId: c.productionMethodId,
			value: c.value,
		};
	});
}
