import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class CarbonDioxideValuePerIngredient {
	public ingredientIndex: number = 0;
	public ingredientId: string = '';
	public value: number = 0;

	public constructor(input?: api.CarbonDioxideValuePerIngredient) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.CarbonDioxideValuePerIngredient) {
		this.ingredientIndex = input.ingredientIndex;
		this.ingredientId = input.ingredientId;
		this.value = input.value;
	}
}
