import { useState } from 'react';

import useClassNameForVisibilityChange from 'components/desktop/_general/MainNavigationLeft/hooks/useClassNameForVisibiltyChange';
import SidebarState from 'types/_general/SidebarState';

interface IProps {
	sidebarState: SidebarState;
}

export default function Header(props: IProps) {
	const [logoPath, setLogoPath] = useState<string>('/img/logoExt/external_logo_login.png');
	const visible = useClassNameForVisibilityChange(props.sidebarState);

	const setFallbackLogoPath = (): void => {
		setLogoPath('/img/logo/logo_sm.png');
	};

	const renderHeader = (): JSX.Element => {
		let marginLeft: string = '0px';
		if (props.sidebarState !== 'closed') marginLeft = '15px';
		return (
			<>
				<img
					src={logoPath}
					onError={setFallbackLogoPath}
					className="d-inline-block align-text-middle"
				/>
				{props.sidebarState !== 'closed' && (
					<div
						style={{
							marginLeft: marginLeft,
							fontWeight: '800',
							display: visible === 'visible' ? 'block' : 'none',
						}}
					>
						AI Recipes
					</div>
				)}
			</>
		);
	};

	return <div className="navigation-header">{renderHeader()}</div>;
}
