import { ChartData, ChartOptions } from 'chart.js';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const options: ChartOptions<'doughnut'> = {
	cutout: '50%',
	animation: {
		duration: 1000,
	},
	datasets: {
		doughnut: {
			hoverOffset: 4,
		},
	},
	plugins: {
		legend: {
			display: false,
			position: 'bottom',
		},
	},
};

const emptyDoughnutPlugin = {
	id: 'emptyDoughnut',
	afterDraw(chart: any, _args: any, options: any) {
		const { datasets } = chart.data;
		const { color, width, radiusDecrease } = options;
		let hasData = false;

		for (let i = 0; i < datasets.length; i++) {
			const dataset = datasets[i];
			hasData ||= dataset.data.length > 0;
		}

		if (!hasData) {
			const {
				chartArea: { left, top, right, bottom },
				ctx,
			} = chart;
			const centerX = (left + right) / 2;
			const centerY = (top + bottom) / 2;
			const radius = Math.min(right - left, bottom - top) / 2;

			ctx.beginPath();
			ctx.lineWidth = width ?? 2;
			ctx.strokeStyle = color ?? 'rgba(211, 211, 211, 0.5)';
			ctx.arc(
				centerX,
				centerY,
				radius - ctx.lineWidth - (radiusDecrease ?? 0),
				0,
				2 * Math.PI
			);
			ctx.stroke();
		}
	},
};

export type DoughnutData = {
	label: string;
	value: number;
	backgroundColor?: string;
};

const getDoughnutChartData = (data: DoughnutData[]): ChartData<'doughnut'> => {
	const labels: string[] = [];
	const values: number[] = [];
	const backgroundColor: string[] = [];
	const backgroundColorRequired = data.some((x) => x.backgroundColor !== undefined);

	data.forEach((x) => {
		labels.push(x.label);
		values.push(x.value);

		if (backgroundColorRequired) {
			backgroundColor.push(x.backgroundColor ?? '');
		}
	});

	return {
		labels: labels,
		datasets: [
			{
				data: values,
				backgroundColor: backgroundColor.length > 0 ? backgroundColor : undefined,
			},
		],
	};
};

interface IProps {
	data: DoughnutData[];
	style?: React.CSSProperties;
}

const ChartDoughnut = (props: IProps) => {
	return (
		<Doughnut
			data={getDoughnutChartData(props.data)}
			options={options}
			style={props.style}
			plugins={[emptyDoughnutPlugin]}
		/>
	);
};

export default ChartDoughnut;
