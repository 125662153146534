import * as apiGetStartingWithV1 from 'api/product/GetProductStartingWithV1';
import * as apiPostSearch from 'api/product/PostProductSearchV1';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as Product from 'types/Product/Search/Product';
import * as ProductList from 'types/Product/Search/ProductList';
import * as Range from 'types/Product/Search/Range';
import * as Search from 'types/Product/Search/Search';

export type Type = {
	data: Product.Type[];
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	co2Range: Range.Type;
	priceRange: Range.Type;
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
};

export function create(): Type {
	return {
		data: [],
		hasNextPage: false,
		hasPreviousPage: false,
		co2Range: Range.create(),
		priceRange: Range.create(),
		pageIndex: 0,
		pageSize: 20,
		totalCount: 0,
		totalPages: 0,
	};
}

export async function getFromApi(search: Search.Type): Promise<Type> {
	const response = await apiPostSearch.callApi(Search.mapToApi(search));
	const responseData = response.toNullable();
	if (responseData) {
		return mapFromApi(responseData);
	}
	return ProductList.create();
}

export async function getFromApiSW(searchTerm: string): Promise<ISelectItem[]> {
	const response = await apiGetStartingWithV1.callApi(searchTerm);
	const responseData = response.toNullable();
	if (responseData) {
		return mapToISelectItem(responseData);
	}
	return [];
}

export function mapFromApi(input: apiPostSearch.ResponseData): Type {
	return {
		...input,
		co2Range: input.minMaxCarbonDioxideRange,
		priceRange: input.minMaxPriceRange,
	};
}

export function mapToISelectItem(input: apiGetStartingWithV1.ResponseProduct[]): ISelectItem[] {
	return input.map<ISelectItem>((x: apiGetStartingWithV1.ResponseProduct) => ({
		id: x.id,
		name: x.description,
	}));
}
