import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'components/desktop/Accessory/UnitWeightEditor/UnitWeightEditor.scss';
import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import PERMISSIONS from 'enums/permissions';
import { getNumberString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import * as inputValidation from 'functions/validation/inputValidation';
import { RootState } from 'reducers/rootReducer';
import { regexValidationWeight } from 'regex/validation/Weight';
import { Accessory } from 'types/Accessory/Accessory';
import { UnitWeights } from 'types/Accessory/Accessory';
import { UnitLight } from 'types/Unit/UnitLight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

interface IProps {
	units: UnitLight[];
	setUnitWeights: (unitWeights: UnitWeight[]) => void;
	accessory: Accessory;
	setAccessory: (input: Accessory) => void;
}

const UnitWeightEditor = (props: IProps) => {
	const { t } = useTranslation();
	const accessoryEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [weight, setWeight] = useState(0);
	const [selectedUnit, setSelectedUnit] = useState<number>(0);

	const handleAddUnitWeight = (): void => {
		if (!weight) {
			inputValidation.show('WeightAmount');
		} else {
			inputValidation.hide('WeightAmount');
			inputValidation.hide('UnitWeights');
			const allUnits: UnitLight[] = getUnitSuggestions();
			if (!allUnits.length) return;

			const unit: UnitLight = allUnits[selectedUnit];
			if (!unit) return;

			const accessoryCpy = { ...props.accessory };
			if (!accessoryCpy.unitWeights) {
				accessoryCpy.unitWeights = [];
			}
			accessoryCpy.unitWeights.push({
				id: unit.id,
				weight: weight,
				name: unit.description,
			} as UnitWeight);

			props.setAccessory(accessoryCpy);
			setWeight(0);
			setSelectedUnit(0);
		}
	};

	const handleSelectUnit = (e: any) => {
		setSelectedUnit(e.currentTarget.value);
	};

	const handleDeleteUnitWeight = (unitWeight: UnitWeights) => {
		const accessoryCpy: Accessory = { ...props.accessory };
		if (accessoryCpy.unitWeights) {
			const index = accessoryCpy.unitWeights.indexOf(unitWeight);

			if (index !== -1) {
				accessoryCpy.unitWeights.splice(index, 1);
				props.setAccessory(accessoryCpy);
			}
		}
	};

	const getTitle = (unitWeight: UnitWeights) => {
		if (unitWeight.source) {
			return unitWeight.source;
		}
	};

	const renderTableRow = (
		unitWeight: UnitWeights,
		unitName: string,
		key: number
	): JSX.Element => {
		return (
			<tr key={key} error-key={'UnitWeights[' + key + ']'} title={getTitle(unitWeight)}>
				<td>
					<span className="table-unit-weight-editor-span-unit-weight">
						{getNumberString(unitWeight.weight, reduxCultureCode)}
					</span>
					<span className="grey">{unitName}</span>
				</td>
				<td className="grey">{t('_general:PER')}</td>
				<td>{unitWeight.name}</td>
				<td className="table-unit-weight-editor-td-trash-can">
					{accessoryEditor && (
						<FontAwesomeIcon
							className="trash-can"
							icon={faTrashCan}
							onClick={() => handleDeleteUnitWeight(unitWeight)}
						/>
					)}
				</td>
			</tr>
		);
	};

	const handleValueChange = (_id: string, value: string): void => {
		setWeight(Number(value));
	};

	const renderSelect = (): JSX.Element => {
		const unitSugggestions: UnitLight[] = getUnitSuggestions();
		return (
			<select
				className="form-select"
				disabled={!getUnitSuggestions().length}
				value={selectedUnit ?? unitSugggestions[0]?.id ?? ''}
				name="unitSelect"
				onChange={(e: any) => handleSelectUnit(e)}
			>
				{getUnitSuggestions().map((unit: UnitLight, key: number) => (
					<option key={key} value={String(key)}>
						{unit.description}
					</option>
				))}
			</select>
		);
	};

	const getUnitSuggestions = (): UnitLight[] => {
		if (props.accessory.unitWeights) {
			const alreadyUsedIds: string[] = props.accessory.unitWeights.map((e: UnitWeights) => {
				return e.id;
			});
			const output: UnitLight[] = props.units.filter((e: UnitLight) => {
				if (!alreadyUsedIds.includes(e.id)) {
					return e;
				}
			});
			return output;
		}
		return [];
	};

	const getUnit = (): string => {
		return t('measurments:GRAMM');
	};

	const getWeightName = (): string => {
		return t('baseIngredient:WEIGHT');
	};

	const getLabel = (): string => {
		return (
			getWeightName() + ' (' + getUnit() + ') ' + t('_general:PER') + ' ' + t('_general:UNIT')
		);
	};

	const renderContent = () => {
		return (
			<>
				{accessoryEditor && (
					<LockableInput
						id="weightInput"
						label={getLabel()}
						placeholder=""
						withLock={false}
						withTrashCan={false}
						defaultValue={String(weight)}
						validateRegex={regexValidationWeight}
						onPlusClick={handleAddUnitWeight}
						handleValueChange={handleValueChange}
						select={renderSelect()}
						disabled={!accessoryEditor || !getUnitSuggestions().length}
						errorKey="UnitWeights"
						errorKeyInput="WeightAmount"
					/>
				)}

				<div style={{ overflow: 'auto' }}>
					<table
						className="table mb-0"
						style={{ backgroundColor: 'white', overflowX: 'auto' }}
					>
						<tbody>
							{(props.accessory.unitWeights ?? []).map(
								(unitWeight: UnitWeights, i: number) => {
									return renderTableRow(unitWeight, getUnit(), i);
								}
							)}
						</tbody>
					</table>
					{!accessoryEditor && (props.accessory.unitWeights ?? []).length === 0 && (
						<>
							<label>{t('baseIngredient:NO_UNIT_WEIGHTS_DEFINED')}</label>
						</>
					)}
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default UnitWeightEditor;
