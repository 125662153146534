import * as Get from 'api/accessory/GetAccessoryIdV1';
import { Accessory } from 'types/Accessory/Accessory';
import { Tags } from 'types/Accessory/Accessory';

export function mapToAccessory(input: Get.ResponseData): Accessory {
	return {
		carbonDioxide: input.carbonDioxide,
		carbonDioxideLabel: input.carbonDioxideLabel,
		carbonDioxideValue: input.carbonDioxideValue,
		categories: input.categories,
		characteristic: input.characteristic,
		density: input.density,
		descriptionTranslations: input.descriptionTranslations,
		generalInfoTranslations: input.generalInfoTranslations,
		id: input.id,
		image: input.image,
		isPriceRecipeRelevant: input.isPriceRecipeRelevant,
		namePluralTranslations: input.namePluralTranslations ?? {},
		nameSingularTranslations: input.nameSingularTranslations ?? {},
		price: input.price,
		source: input.source,
		synonyms: input.synonyms,
		tags: mapTags(input.tags),
		unitWeights: input.unitWeights,
	};
}

function mapTags(tags: Get.Tag[] | null): Tags[] {
	if (tags) {
		return tags.map((e: Get.Tag) => {
			return {
				display: e.display,
				iconSvgUrl: e.iconSvgUrl ?? '',
				id: e.id,
				name: e.name ?? '',
				source: e.source,
				tagCategory: e.tagCategory,
			};
		});
	}
	return [];
}
