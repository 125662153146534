import { Characteristic } from 'enums/characteristic';
import { Image, defaultImage } from 'types/Media/Image';

export type Accessory = {
	[key: string]: any;
	carbonDioxide: CarbonDioxide[] | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number | null;
	categories: Categories[] | null;
	characteristic: Characteristic;
	density: number | null;
	descriptionTranslations: Translation;
	generalInfoTranslations: Translation;
	id: string;
	image: Image;
	isPriceRecipeRelevant: boolean;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	price: number | null;
	source: string | null;
	synonyms: Synonyms;
	tags: Tags[];
	unitWeights: UnitWeights[] | null;
};

export type CarbonDioxideLabel = {
	label: string | null;
	color: string | null;
};

export type Translation = {
	[key: string]: string;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};

export type UnitWeights = {
	id: string;
	name: string | null;
	weight: number;
	source: string | null;
};

export type Tags = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	source: string | null;
	tagCategory: TagCategory | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Categories = {
	id: string;
	name: string | null;
	source: string | null;
};

export type CarbonDioxide = {
	originLocationId: string;
	origin: string | null;
	productionMethodId: string;
	productionMethod: string | null;
	value: number;
	source: string | null;
};

export function defaultAccessory(): Accessory {
	return {
		carbonDioxide: [],
		carbonDioxideLabel: null,
		carbonDioxideValue: null,
		categories: [],
		characteristic: Characteristic.SolidQuantifiable,
		density: null,
		descriptionTranslations: {},
		generalInfoTranslations: {},
		id: '',
		image: defaultImage(),
		isPriceRecipeRelevant: false,
		namePluralTranslations: {},
		nameSingularTranslations: {},
		price: null,
		source: null,
		synonyms: {},
		tags: [],
		unitWeights: [],
	};
}
