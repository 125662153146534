export function getIdFromUrl(): string | null {
	let id: string | null = '';
	if (window.location.href.includes('?')) {
		if (window.history.state.id !== undefined) {
			id = window.history.state.id;
		} else {
			const url = new URL(String(window.location)).searchParams;
			id = url.get('id') === null ? null : url.get('id');
		}
	}
	return id;
}

export function setIdToUrl(id: string): string | null {
	if (window.location.href.includes('?')) {
		const searchParams = new URL(String(window.location)).searchParams;
		searchParams.set('id', id);
		window.history.replaceState(
			{},
			'',
			decodeURIComponent(`${window.location.pathname}?${searchParams}`)
		);
	}
	return id;
}
