import {
	AirListRowDiscriminatorName,
	IAirListRow,
} from 'components/desktop/_general/SearchResultList/IAirListRow';
import { IBaseListRow } from 'components/desktop/_general/SearchResultList/IBaseListRow';
import {
	IUserListRow,
	UserListRowDiscriminatorName,
} from 'components/desktop/_general/SearchResultList/IUserListRow';
import SearchResultListRow from 'components/desktop/_general/SearchResultList/SearchResultListRow';
import 'components/desktop/_general/SearchResultList/SearchResultListRow.scss';
import UserSearchResultListRow from 'components/desktop/_general/SearchResultList/UserSearchResultListRow';

interface IProps {
	data: IBaseListRow[];
}

const SearchResultList = (props: IProps) => {
	return (
		<div className="row gy-4">
			<div className="col-lg-12">
				{props.data.map((row: IBaseListRow) => {
					switch (row.discriminator) {
						case AirListRowDiscriminatorName:
							return <SearchResultListRow key={row.id} {...(row as IAirListRow)} />;
						case UserListRowDiscriminatorName:
							return (
								<UserSearchResultListRow key={row.id} {...(row as IUserListRow)} />
							);
					}
				})}
			</div>
		</div>
	);
};

export default SearchResultList;
