import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faDollarSign, faTimer, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Recipe from 'classes/Menu/Search/Recipe';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnailCard.scss';
import ENTITLEMENTS from 'enums/entitlements';
import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { getPriceString } from 'functions/numberToString';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	recipe: Recipe;
}

const SearchResultThumbnailCard = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const getTitle = (): string => {
		if (props.recipe.title) {
			return props.recipe.title;
		}
		return t('_general:UNKNOWN_TITLE');
	};

	const getAuthor = (): string => {
		if (props.recipe.author) {
			return props.recipe.author;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(ValueScope.Person, null, true);
	};

	const getStatus = (): JSX.Element => {
		if (props.recipe.status) {
			return (
				<>
					{props.recipe.status.name}&ensp;
					<FontAwesomeIcon
						color={props.recipe.status.color}
						icon={faCircle as IconProp}
					/>
				</>
			);
		}
		return (
			<>
				{t('_general:UNKNOWN_STATUS')}&ensp;
				<FontAwesomeIcon icon={faCircle as IconProp} />
			</>
		);
	};

	const getInfos = (): JSX.Element => {
		return (
			<>
				{!!props.recipe.calories && (
					<p>
						<span id="icon">
							<FontAwesomeIcon icon={faUtensils as IconProp} />
						</span>
						<b>{getNumberString(props.recipe.calories, reduxCultureCode, true)}</b>
						{` ${t('_general:CALORIES')} ${getValueScope()}`}
					</p>
				)}
				{!!props.recipe.price && (
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<p>
							<span id="icon">
								<FontAwesomeIcon icon={faDollarSign as IconProp} />
							</span>
							<b>{getPriceString(props.recipe.price, reduxCultureCode)}</b>
							{` ${reduxCurrency} ${getValueScope()}`}
						</p>
					</RenderIf>
				)}
				{props.recipe.prepareTime && (
					<RenderIf entitlements={[ENTITLEMENTS.ACTIVITY_TIMES]}>
						<p>
							<span id="icon">
								<FontAwesomeIcon icon={faTimer as IconProp} />
							</span>
							<b>{Math.round(props.recipe.prepareTime?.timeValue)}</b>
							{` ${props.recipe.prepareTime?.timeUnit}`}
						</p>
					</RenderIf>
				)}
			</>
		);
	};

	return (
		<div className="card recipe-card" style={{ width: '310px' }}>
			<Link to={props.recipe.getLink()} className="unstyled-link">
				<div className="card-body">
					{!props.recipe.image?.url ? (
						<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
							<FontAwesomeIcon
								size="3x"
								icon={faCamera}
								color={props.recipe.status?.color}
							/>
						</div>
					) : (
						<div className="card-picture">
							<img alt="thumbnail" src={props.recipe.image.url} />
						</div>
					)}
					<div>
						<div id="title">
							<strong>{getTitle()}</strong>
						</div>
						<div id="info">{getInfos()}</div>
						<hr />
						<div id="author-status">
							<div>{getAuthor()}</div>
							<div>{getStatus()}</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default SearchResultThumbnailCard;
