import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import 'components/desktop/_general/ImageSlider/ImageSlider.scss';
import { getPicturePath } from 'functions/getPicturePath';
import { Image } from 'types/Media/Image';

interface IProps {
	images: Image[];
	id: string;
}

const ImageSlider = (props: IProps) => {
	const [index, setIndex] = useState<number>(0);

	const slideLeft = (e: any) => {
		e.stopPropagation();
		if (index !== 0) {
			setIndex(index - 1);
		}
	};

	const slideRight = (e: any) => {
		e.stopPropagation();
		if (index !== props.images.length - 1) {
			setIndex(index + 1);
		}
	};

	const getLeftArrowClassName = (): string => {
		if (index === 0) {
			return 'slider-icon left-5 inactive';
		} else {
			return 'slider-icon left-5';
		}
	};

	const getRightArrowClassName = (): string => {
		if (index === props.images.length - 1) {
			return 'slider-icon right-5 inactive';
		} else {
			return 'slider-icon right-5';
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="slider-container">
					<span className={getLeftArrowClassName()} onClick={slideLeft}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</span>
					<img
						src={getPicturePath(props.images[index], 200)}
						className="img"
						key={index}
					/>
					<span className={getRightArrowClassName()} onClick={slideRight}>
						<FontAwesomeIcon icon={faChevronRight} />
					</span>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ImageSlider;
