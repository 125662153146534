import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(formData: FormData): Promise<Optional<void>> {
	if (formData) {
		const url: string = '/backend-service/ExternalLogo';

		const requestConfig = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		return axios.put(url, formData, requestConfig);
	}
	return Optional.None();
}
