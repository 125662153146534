import * as apiSW from 'api/accessory/GetAccessoryStartingWithV1';
import Accessory from 'classes/_general/Search/Accessory/Accessory';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';

export default class AccessoryList {
	public all: Accessory[] = [];
	public filtered: IExclusionLogicSelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public getById(id: string): Accessory | undefined {
		const index = this.all.findIndex((x) => x.id == id);
		if (index) {
			return this.all[index];
		}
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Accessory[] = this.all.filter((e: Accessory) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	private mapToSelectItems(input: Accessory[]): IExclusionLogicSelectItem[] {
		return input.map((e: Accessory) => {
			return e.mapToSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseAccessory[]): void {
		this.all = [];
		for (const i of input) {
			const ingredient: Accessory = new Accessory(i);
			this.all.push(ingredient);
		}
	}
}
