import { IBaseListRow } from 'components/desktop/_general/SearchResultList/IBaseListRow';
import { Characteristic } from 'enums/characteristic';
import { ValueScope } from 'enums/valueScope';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { PrepareTime } from 'types/Recipe/PrepareTime';
import { TagLight } from 'types/Tag/TagLight';
import { NutriScore } from 'types/_general/NutriScore';

export const AirListRowDiscriminatorName = 'AirListRow';

export interface IAirListRow extends IBaseListRow {
	allergens: AllergenLight[];
	archive?: boolean;
	author: string;
	calories?: number | null;
	carbonDioxideColor?: string | undefined;
	carbonDioxideLabel?: string | undefined;
	categories?: string;
	characteristic?: Characteristic;
	currency?: string;
	dateLastEdited: string;
	density?: number;
	discriminator: 'AirListRow';
	hasNutriScore?: boolean;
	link?: JSX.Element;
	nutriScore?: NutriScore;
	prepareTime?: PrepareTime;
	price?: number | null;
	seasonalityCode?: number;
	status?: string;
	statusColor?: string;
	tags: TagLight[];
	valueScope?: ValueScope;
}
