import * as apiSW from 'api/mailAccount/GetMailAccountStartingWithV1';
import * as apiPostSearch from 'api/mailAccount/PostMailAccountSearchV1';
import MailAccount from 'classes/StandingData/MailAccount/Search/MailAccount';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class MailAccountList {
	public all: MailAccount[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const mailAccounts = await apiSW.callApi(searchTerm, showOnlyDeleted);
		mailAccounts.hasValue() && this.mapFromApiSW(mailAccounts.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const mailAccount: MailAccount = new MailAccount(i);
			this.all.push(mailAccount);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseMailAccount[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
