/**
 * Dissociate the given the given key from the object obj
 *
 * @param obj the object to dissociate from
 * @param key the key to dissociate from the object obj.
 * @returns A copy of obj, without the given key
 */
export function dissoc<T>(obj: { [key: number | string]: T }, key: number | string) {
	const result = { ...obj };
	delete result[key];
	return result;
}

/**
 * Associate the given value at the given key into the object obj
 *
 * @param obj
 * @param key
 * @param value
 * @returns
 */
export function assoc<T>(obj: T, key: keyof T, value: any): T {
	const result = { ...obj };
	result[key] = value;
	return result;
}
