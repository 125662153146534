import * as apiDelete from 'api/baseIngredient/DeleteBaseIngredientIdV1';
import * as apiRestore from 'api/baseIngredient/PostBaseIngredientRestoreV1';
import * as apiPostSearch from 'api/baseIngredient/PostBaseIngredientSearchV1';
import AllergenList from 'classes/BaseIngredient/Search/AllergenList';
import BaseIngredientCategoryList from 'classes/BaseIngredient/Search/BaseIngredientCategoryList';
import CarbonDioxideLabel from 'classes/BaseIngredient/Search/CarbonDioxideLabel';
import Image from 'classes/BaseIngredient/Search/Image';
import NutriScore from 'classes/BaseIngredient/Search/NutriScore';
import SeasonList from 'classes/BaseIngredient/Search/SeasonList';
import TagList from 'classes/BaseIngredient/Search/TagList';
import { Characteristic } from 'enums/characteristic';

export default class BaseIngredient {
	public id: string = '';
	public density: number | null = null;
	public calories: number | null = null;
	public characteristic: Characteristic = Characteristic.Fluid;
	public lastEditor: string | null = null;
	public name: string = '';
	public countOfIngredients: number = 0;
	public lastEditedDateUtc: string = '';
	public image: Image | null = new Image();
	public hasNutriScore: boolean = false;
	public nutriScore: NutriScore = new NutriScore();
	public carbonDioxideValue: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public seasons: SeasonList = new SeasonList();
	public allergens: AllergenList = new AllergenList();
	public tags: TagList = new TagList();
	public categories: BaseIngredientCategoryList = new BaseIngredientCategoryList();

	public constructor(input?: apiPostSearch.ResponseBaseIngredient) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseBaseIngredient) {
		this.id = input.id;
		this.density = input.density;
		this.calories = input.calories;
		this.characteristic = input.characteristic;
		this.lastEditor = input.lastEditor;
		this.name = input.name;
		this.countOfIngredients = input.countOfIngredients;
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.hasNutriScore = input.hasNutriScore;
		this.nutriScore.mapFromApi(input.nutriScore);
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.seasons.mapFromApi(input.seasons);
		this.allergens.mapFromApi(input.allergens);
		this.tags.mapFromApi(input.tags);
		this.categories.mapFromApi(input.categories);

		if (input.image) {
			this.image = new Image();
			this.image.mapFromApi(input.image);
		} else {
			this.image = null;
		}
	}
}
