import * as apiSW from 'api/originLocation/GetOriginLocationStartingWithV1';
import * as apiPostSearch from 'api/originLocation/PostOriginLocationSearchV1';
import OriginLocation from 'classes/StandingData/OriginLocation/Search/OriginLocation';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class OriginLocationList {
	public all: OriginLocation[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const originLocations = await apiSW.callApi(searchTerm, showOnlyDeleted);
		originLocations.hasValue() && this.mapFromApiSW(originLocations.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const originLocation: OriginLocation = new OriginLocation(i);
			this.all.push(originLocation);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseOrigin[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
