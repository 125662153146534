import * as apiSW from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import Co2Label from 'classes/_general/Search/Co2Label/Co2Label';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import { ValueScope } from 'enums/valueScope';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Co2LabelList implements Selectable {
	public all: Co2Label[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', ValueScope.HundredGrams, false);
		response.hasValue() && this.translateFromApi(response.get());
	}

	public async search(_searchTerm: string): Promise<void> {}

	public getById(id: string): Co2Label | undefined {
		return this.all.find((e: Co2Label) => {
			return e.id === id;
		});
	}

	private translateFromApi(input: apiSW.ResponseCarbonDioxideLabel[]): void {
		this.all = [];
		for (const i of input) {
			const carbonDioxideLabel: Co2Label = new Co2Label(i);
			this.all.push(carbonDioxideLabel);
		}
	}
}
