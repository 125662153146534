import IngredientCategoryManagementComponent from 'components/desktop/StandingData/IngredientCategory/Management/IngredientCategoryManagementComponent';

const IngredientCategoryManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<IngredientCategoryManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default IngredientCategoryManagementContainer;
