interface IProps {
	color: string | undefined;
	label: string | undefined;
	opacity?: number;
}

const IconCo2Round = (props: IProps) => {
	if (props.color) {
		return (
			<>
				<svg
					version="1.1"
					id="Ebene_1"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 76 76"
					style={{ width: '36px', height: '36px' }}
				>
					<title>{props.label}</title>
					<g>
						<path
							style={{ fill: props.color, opacity: props.opacity ?? 1 }}
							d="M38,0C17,0,0,17,0,38s17,38,38,38s38-17,38-38S59,0,38,0z M36.5,8.1c1.7,0,3,1.7,3,3.9s-1.3,3.9-3,3.9
		s-3-1.7-3-3.9S34.8,8.1,36.5,8.1z M29.8,8.1c1.4,0,2.5,1.5,2.5,3.3s-1.1,3.3-2.5,3.3c-1.4,0-2.5-1.5-2.5-3.3
		C27.3,9.6,28.4,8.1,29.8,8.1z M23.6,10c1.3,0,2.3,1.3,2.3,3s-1,3-2.3,3c-1.3,0-2.3-1.3-2.3-3S22.3,10,23.6,10z M18.4,14
		c1,0,1.7,1,1.7,2.3s-0.8,2.3-1.7,2.3c-1,0-1.7-1-1.7-2.3S17.4,14,18.4,14z M13.2,20.1c0-1,0.7-1.8,1.4-1.8c0.8,0,1.4,0.8,1.4,1.8
		c0,1-0.7,1.8-1.4,1.8C13.9,21.9,13.2,21.1,13.2,20.1z M31.6,67.6c-6.4,2.4-8.4-4.2-10.8-8.4c-1.2-2.2-3-7.7-4.3-13.3
		c0.6-0.9,0.8-2,1-3.4c0.2-1.2,0.4-2.7,0.8-4.6c0.4-1.9,1.7-3.1,2.7-4c0.8-0.5,1.8-1.1,3.2-1.4c-0.4,0.3-4,3.1-3.9,5.9
		c4.3,0.6,7.3-1.2,8.7-3.7c1.6-3.1,2.3-10.7,1.9-10.3c-0.5,0.4-10.6,1-13.1,4c-1.8,2.1-1.9,5.7-1,7.8c0.2,0.4,0.2,0.9,0.1,1.3
		c-0.5,2.1-0.7,3.6-0.8,4.7c-0.1,0.5-0.2,1-0.2,1.4c-0.6-3.3-1-6.6-0.8-9.1c0.6-9.1,4-14.1,9.6-16.4c5.6-2.3,11.5-1.6,12.2,5.4
		c0.8,7-8.1,11.6-8.8,17.3s3.1,8.8,5.2,11.2c1.5,1.7,2.2,3,3.3,6.3C37.8,61.6,35.5,66.2,31.6,67.6z M44.4,38.6c-0.8-1-1.8-1.5-3-1.5
		c-0.5,0-1,0.1-1.4,0.3c-0.5,0.2-0.8,0.5-1.1,0.8c-0.3,0.3-0.6,0.8-0.8,1.2c-0.2,0.5-0.3,0.9-0.3,1.4s0.1,1.1,0.3,1.5
		c0.2,0.5,0.5,0.8,0.8,1.2c0.3,0.3,0.7,0.6,1.1,0.8s0.9,0.3,1.4,0.3c1.1,0,2.1-0.5,3-1.4v4.2L44,47.5c-0.5,0.2-1.1,0.3-1.5,0.5
		c-0.5,0.1-0.9,0.2-1.4,0.2c-0.9,0-1.8-0.2-2.7-0.5c-0.8-0.4-1.6-0.8-2.3-1.5c-0.7-0.6-1.2-1.4-1.6-2.3S34,42,34,40.9
		c0-1.1,0.2-2,0.6-2.9c0.4-0.8,0.9-1.6,1.6-2.3c0.7-0.6,1.4-1.1,2.3-1.4c0.8-0.4,1.7-0.5,2.7-0.5c0.5,0,1.1,0.1,1.6,0.2
		c0.5,0.2,1.1,0.3,1.7,0.5L44.4,38.6L44.4,38.6z M53.4,48c-4.1,0-7.5-3.3-7.5-7.3s3.3-7.3,7.5-7.3s7.5,3.3,7.5,7.3S57.6,48,53.4,48z
		 M62.4,47.3l2.5-2.6c0.7-0.7,1.1-1.3,1.5-1.7s0.7-0.9,0.8-1.2c0.2-0.3,0.3-0.6,0.3-0.8c0.1-0.2,0.1-0.4,0.1-0.5s0-0.3-0.1-0.5
		c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.5-0.3,0.8
		c0,0.1,0,0.2,0.1,0.5h-2.9c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.8-1.2c0.4-0.3,0.8-0.6,1.2-0.8c0.5-0.2,1-0.3,1.6-0.3
		c0.5,0,1.1,0.1,1.5,0.3c0.5,0.2,0.9,0.5,1.2,0.8c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.5,0.3,0.9,0.3,1.4S70.2,41.6,70,42
		c-0.2,0.5-0.6,0.9-1.1,1.4l-1.7,1.6h3.3v2.2L62.4,47.3L62.4,47.3z"
						/>
						<circle
							style={{ fill: props.color, opacity: props.opacity ?? 1 }}
							cx="53.5"
							cy="40.9"
							r="3.8"
						/>
					</g>
				</svg>
			</>
		);
	}
	return <></>;
};

export default IconCo2Round;
