export type LoginDto = {
	[key: string]: any;
	email: string;
	password: string;
};

export const defaultLoginDto = (): LoginDto => {
	return {
		email: '',
		password: '',
	};
};
