import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';

type SynonymKey = 'nameSingular' | 'namePlural';

type Synonyms = {
	[key: string]: Synonym[];
};

type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};

type Input = {
	synonyms: Synonyms;
};

interface IProps<T extends Input> {
	cultureCode: string;
	input: T;
	setInput: (input: T) => void;
	disabled?: boolean;
}

const SynonymsComponent = <T extends Input>(props: IProps<T>) => {
	const { t } = useTranslation();

	const addNewSynonym = () => {
		const input: T = { ...props.input };
		if (!input.synonyms[props.cultureCode]) input.synonyms[props.cultureCode] = [];
		input.synonyms[props.cultureCode].push({
			id: null,
			nameSingular: '',
			namePlural: '',
		});
		props.setInput(input);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input: T = { ...props.input };
		const synonymKey: SynonymKey = e.currentTarget.name as SynonymKey;
		const value: string = e.currentTarget.value;
		const index: number = Number(e.currentTarget.id);
		input.synonyms[props.cultureCode][index][synonymKey] = value;
		props.setInput(input);
	};

	const handleDelete = (index: number) => {
		const input: T = { ...props.input };
		input.synonyms[props.cultureCode].splice(index, 1);
		props.setInput(input);
	};

	const renderSynonym = (input: Synonym, key: number): JSX.Element => {
		return (
			<div className="col-3" key={`${key}-${props.cultureCode}`}>
				<fieldset className="fieldset-white">
					<div className="text-end">
						{!props.disabled && (
							<FontAwesomeIcon
								icon={faTrashCan}
								className="trash-can"
								onClick={() => handleDelete(key)}
							/>
						)}
					</div>
					<div className="form-group">
						<label>{t('_general:SINGULAR')}</label>
						<input
							className="form-control"
							disabled={props.disabled}
							error-key={`Synonyms['${props.cultureCode}'][${key}].NameSingular`}
							id={String(key)}
							name="nameSingular"
							onChange={handleChange}
							type="string"
							value={input.nameSingular}
						/>
						<label>{t('_general:PLURAL')}</label>
						<input
							className="form-control"
							disabled={props.disabled}
							error-key={`Synonyms['${props.cultureCode}'][${key}].NamePlural`}
							id={String(key)}
							name="namePlural"
							onChange={handleChange}
							type="string"
							value={input.namePlural}
						/>
					</div>
				</fieldset>
			</div>
		);
	};

	const getSynonymsWithCultureCode = (): Synonym[] => {
		return props.input.synonyms[props.cultureCode] ?? [];
	};

	const renderContent = () => {
		return (
			<fieldset className="margin-top-25" style={{ minHeight: '4em' }}>
				<div className="d-flex">
					<legend>{t('_general:SYNONYMS')}</legend>
					<RenderIf condition={!props.disabled}>
						<button
							type="button"
							disabled={props.disabled}
							onClick={addNewSynonym}
							className="btn btn-outline-primary"
							style={{ marginBottom: '18px', whiteSpace: 'nowrap' }}
						>
							{t('_general:NEW_SYNONYM')}
						</button>
					</RenderIf>
				</div>
				<div className="row gy-4">
					{getSynonymsWithCultureCode().map((synonym: Synonym, i: number) => {
						return renderSynonym(synonym, i);
					})}
				</div>
			</fieldset>
		);
	};

	return <>{renderContent()}</>;
};

export default SynonymsComponent;
