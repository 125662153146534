import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faChessBoard } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle, faDollarSign, faTimer, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import { IAirListRow } from 'components/desktop/_general/SearchResultList/IAirListRow';
import 'components/desktop/_general/SearchResultList/SearchResultListRow.scss';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import ENTITLEMENTS from 'enums/entitlements';
import { getNumberString } from 'functions/numberToString';
import { getPriceString } from 'functions/numberToString';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { TagLight } from 'types/Tag/TagLight';

const SearchResultListRow = (props: IAirListRow) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getTitle = (): string => {
		if (props.title) {
			return props.title;
		}
		return t('_general:UNKNOWN_TITLE');
	};

	const getAuthor = (): string => {
		if (props.author) {
			return props.author;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(props.valueScope ?? null, props.characteristic, true);
	};

	const getStatus = (): JSX.Element => {
		if (props.status) {
			return (
				<>
					{props.status}&ensp;
					<FontAwesomeIcon color={props.statusColor} icon={faCircle as IconProp} />
				</>
			);
		}
		return (
			<>
				{t('_general:UNKNOWN_STATUS')}&ensp;
				<FontAwesomeIcon icon={faCircle as IconProp} />
			</>
		);
	};

	const getPicture = (): JSX.Element => {
		if (!props.imageUrl) {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} color={props.statusColor} />
				</div>
			);
		} else {
			return (
				<div className="card-picture">
					<img alt="thumbnail" src={props.imageUrl} />
				</div>
			);
		}
	};

	const getCalories = (): JSX.Element => {
		if (props.calories) {
			return (
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faUtensils as IconProp} />
					</span>
					<b>{getNumberString(props.calories, reduxCultureCode, true)}</b>
					{' ' + t('_general:CALORIES') + getValueScope()}
				</p>
			);
		}
		return <></>;
	};

	const getPrice = (): JSX.Element => {
		return (
			<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
				<span id="icon">
					<FontAwesomeIcon icon={faDollarSign as IconProp} />
				</span>
				{props.price && props.currency ? (
					<>
						<b>{getPriceString(props.price, reduxCultureCode)}</b>
						{' ' + props.currency + getValueScope()}
					</>
				) : (
					<>–</>
				)}
			</RenderIf>
		);
	};

	const getTime = (): JSX.Element => {
		return (
			<RenderIf entitlements={[ENTITLEMENTS.ACTIVITY_TIMES]}>
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faTimer as IconProp} />
					</span>
					{props.prepareTime?.timeValue && props.prepareTime?.timeUnit ? (
						<>
							<b>{Math.round(props.prepareTime.timeValue)}</b>
							{' ' + props.prepareTime.timeUnit}
						</>
					) : (
						<>–</>
					)}
				</p>
			</RenderIf>
		);
	};

	const getIcons = (): Array<AllergenLight | TagLight> => {
		const output: Array<AllergenLight | TagLight> = [];
		for (const tag of props.tags) {
			if (tag.iconSvgUrl && tag.display) {
				output.push(tag);
			}
		}
		for (const allergen of props.allergens) {
			if (allergen.iconSvgUrl && allergen.display) {
				output.push(allergen);
			}
		}
		return output;
	};

	if (props.archive !== undefined && props.archive == true) {
		return (
			<div className="recipe-table-item">
				{getPicture()}

				<div id="column-first">
					<div id="title">
						<strong>{getTitle()}</strong>
					</div>
					<div id="info">
						{props.link && <>{props.link}</>}
						<br />
						{getCalories()}
						{getPrice()}
						{getTime()}
						{props.density != null && (
							<p>
								<span id="icon">
									<FontAwesomeIcon icon={faChessBoard as IconProp} />
								</span>
								<b>{getNumberString(props.density, reduxCultureCode)}</b>
								{' ' + t('measurments:GRAMMS_PER_QUBIC_CM')}
							</p>
						)}
						{props.categories && (
							<p>
								<b>{t('_general:CATEGORIES')}: </b>
								{props.categories}
							</p>
						)}
					</div>
				</div>

				<div id="column-second">
					<div className="icon-column justify-content-start">
						<SeasonalityBar seasonalityCode={props.seasonalityCode ?? 0} />
					</div>
					<div className="row">
						<div className="col-5">
							<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
							<br />
							<span className="grey">{t('_general:LAST_EDITOR')}:</span>
							<br />
							{props.status && <span className="grey">Status</span>}
						</div>
						<div className="col-7">
							<FormatedDate date={props.dateLastEdited} />
							<br />
							<span>{getAuthor()}</span>
							<br />
							{props.status && <span>{getStatus()}</span>}
						</div>
					</div>
				</div>
				<div id="column-last">
					{props.hasNutriScore && props.nutriScore && (
						<div className="justify-content-end">
							<NutriScoreDiagram nutriScore={props.nutriScore} />
						</div>
					)}
					<div id="icon-rows">
						{getIcons()
							.slice(0, 4)
							.map((icon: AllergenLight | TagLight, i: number) => (
								<IconComponent icon={icon} key={i} />
							))}
						<IconCo2Round
							color={props.carbonDioxideColor}
							label={props.carbonDioxideLabel}
						/>
					</div>
					<div>{props.buttons}</div>
				</div>
			</div>
		);
	} else {
		return (
			<Link to={props.href} className="unstyled-link">
				<div className="recipe-table-item">
					{getPicture()}

					<div id="column-first">
						<div id="title">
							<strong>{getTitle()}</strong>
						</div>
						<div id="info">
							{props.link && <>{props.link}</>}
							<br />
							{getCalories()}
							{getPrice()}
							{getTime()}
							{props.density != null && (
								<p>
									<span id="icon">
										<FontAwesomeIcon icon={faChessBoard as IconProp} />
									</span>
									<b>{getNumberString(props.density, reduxCultureCode)}</b>
									{' ' + t('measurments:GRAMMS_PER_QUBIC_CM')}
								</p>
							)}
							{props.categories && (
								<p>
									<b>{t('_general:CATEGORIES')}: </b>
									{props.categories}
								</p>
							)}
						</div>
					</div>

					<div id="column-second">
						<div className="icon-column justify-content-start">
							<SeasonalityBar seasonalityCode={props.seasonalityCode ?? 0} />
						</div>
						<div className="row">
							<div className="col-5">
								<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
								<br />
								<span className="grey">{t('_general:LAST_EDITOR')}:</span>
								<br />
								{props.status && <span className="grey">Status</span>}
							</div>
							<div className="col-7">
								<FormatedDate date={props.dateLastEdited} />
								<br />
								<span>{getAuthor()}</span>
								<br />
								{props.status && <span>{getStatus()}</span>}
							</div>
						</div>
					</div>
					<div id="column-last">
						{props.hasNutriScore && props.nutriScore && (
							<div className="justify-content-end">
								<NutriScoreDiagram nutriScore={props.nutriScore} />
							</div>
						)}
						<div id="icon-rows">
							{getIcons()
								.slice(0, 4)
								.map((icon: AllergenLight | TagLight, i: number) => (
									<IconComponent icon={icon} key={i} />
								))}
							<IconCo2Round
								color={props.carbonDioxideColor}
								label={props.carbonDioxideLabel}
							/>
						</div>
						<div>{props.buttons}</div>
					</div>
				</div>
			</Link>
		);
	}
};

export default SearchResultListRow;
