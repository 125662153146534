import CharacteristicToggle from 'classes/_general/Search/Characteristic/CharacteristicToggle';
import { Characteristic } from 'enums/characteristic';
import { QueryParams } from 'functions/getQueryParams';

export default class CharacteristicToggleList {
	public id: string;
	public all: CharacteristicToggle[] = [];

	public constructor(id: string) {
		this.id = id;
	}

	public initialize(): void {
		for (const i of Object.keys(Characteristic)) {
			this.all.push(new CharacteristicToggle({ id: i, name: i }));
		}
	}

	public reset() {
		for (const i of this.all) {
			i.reset();
		}
	}

	public mapToApi(): string[] | undefined {
		const output: CharacteristicToggle[] = this.all.filter((e: CharacteristicToggle) => {
			if (e.checked) {
				return e.checked;
			}
		});
		if (output.length) {
			return output.map((e: CharacteristicToggle) => {
				return e.id;
			});
		}
		return undefined;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (typeof value === 'boolean') return;
			if (key === this.id) {
				for (const i of this.all) {
					i.mapFromUrl(value);
				}
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.everyItemIsTrue()) return null;
		const output: string[] = this.all
			.filter((e: CharacteristicToggle) => {
				if (!e.checked) {
					return e;
				}
			})
			.map((e: CharacteristicToggle) => {
				return e.id;
			});
		return this.id + '=' + output.join(';');
	}

	public everyItemIsFalse(): boolean {
		for (const i of this.all) {
			if (i.checked) {
				return false;
			}
		}
		return true;
	}

	private everyItemIsTrue(): boolean {
		for (const i of this.all) {
			if (!i.checked) {
				return false;
			}
		}
		return true;
	}
}
