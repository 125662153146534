import { useEffect, useState } from 'react';

export function usePathname(): string {
	const [pathname, setPathname] = useState<string>('');

	function getPathnames(): string {
		return window.location.pathname;
	}

	useEffect(() => {
		setPathname(getPathnames());
	}, [window.location.href]);

	return pathname;
}
