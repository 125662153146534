import axios from 'axios';

import { ProductType } from 'enums/productType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/Product/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	carbonDioxideLabelIds?: string[];
	carbonDioxideRange?: Range;
	pageIndex?: number;
	pageSize?: number;
	priceRange?: Range;
	searchTerm?: string;
	sortOrder?: string;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseProduct[];
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	minMaxCarbonDioxideRange: Range;
	minMaxPriceRange: Range;
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
};

export type ResponseProduct = {
	brand: string | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number | null;
	externalId: string | null;
	id: string;
	imageUrl: string | null;
	isActive: boolean;
	lastEditedDateUtc: string;
	lastEditor: string;
	nameTranslation: string;
	price: number | null;
	producer: string | null;
	quantity: string | null;
	type: ProductType;
};

export type CarbonDioxideLabel = {
	color: string;
	label: string;
};

export type Range = {
	maximum: number | null;
	minimum: number | null;
};
