import NutrientManagementComponent from 'components/desktop/StandingData/Nutrient/Management/NutrientManagementComponent';

const NutrientManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<NutrientManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientManagementContainer;
