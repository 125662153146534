import axios from 'axios';

import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/IngredientCategory/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	description: string | null;
	showOnlyDeleted: boolean;
	pageIndex: number | null;
	pageSize: number | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseIngredientCategory[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type ResponseIngredientCategory = {
	id: string;
	name: string;
	parentName: string | null;
	nutriScoreCategory: NutriScoreCategory;
};
