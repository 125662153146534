import UserComponent from 'components/desktop/User/User/UserComponent';

const UserSettingsContainer = () => {
	return (
		<>
			<main className="container">
				<UserComponent type="settings" />
			</main>
		</>
	);
};

export default UserSettingsContainer;
