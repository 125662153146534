import { t } from 'i18next';
import { useEffect, useState } from 'react';

import * as apiStartingWith from 'api/channel/GetChannelStartingWithV1';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import { ISelectItem } from 'interfaces/ISelectItem';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { ChannelMedia } from 'types/Media/ChannelMedia';

interface IProps {
	channelMedia: ChannelMedia;
	updateChannels: (mediaId: string, channels: ChannelLight[]) => void;
}

const MediaChannelEditor = (props: IProps): JSX.Element => {
	const [suggestedChannels, setSuggestedChannels] = useState<ISelectItem[]>([]);
	const [filteredSuggestedChannels, setFilteredSuggestedChannels] = useState<ISelectItem[]>([]);
	const [savedChannels, setSavedChannels] = useState<ISelectItem[]>([]);

	useEffect(() => {
		const loadSuggestionChannels = async () => {
			const channels = await apiStartingWith.callApi('', false);
			if (channels.hasValue()) {
				const mappedItems = mapChannelsToSelectItems(channels.get());

				setSuggestedChannels(mappedItems);
				setFilteredSuggestedChannels(mappedItems);
			}
		};

		loadSuggestionChannels();

		const mappedItems = mapChannelsToSelectItems(props.channelMedia.channels ?? []);
		setSavedChannels(mappedItems);
	}, [props.channelMedia.channels]);

	const mapChannelsToSelectItems = (channels: ChannelLight[]): ISelectItem[] => {
		return channels.map(
			(x): ISelectItem => ({
				id: x.id,
				name: x.name,
				iconSvgUrl: x.iconSvgUrl ?? undefined,
			})
		);
	};

	const mapSelectItemsToChannels = (items: ISelectItem[]): ChannelLight[] => {
		return items.map(
			(x): ChannelLight => ({
				id: x.id,
				name: x.name,
				iconSvgUrl: x.iconSvgUrl ?? null,
			})
		);
	};

	const filterSuggestedItems = (searchString: string) => {
		const filtered = suggestedChannels.filter((x) => x.name.includes(searchString));
		setFilteredSuggestedChannels(filtered);
	};

	const handleSavedItems = (items: ISelectItem[]) => {
		setSavedChannels(items);

		const mappedChannels = mapSelectItemsToChannels(items);
		props.updateChannels(props.channelMedia.id, mappedChannels);
	};

	const renderMediaChannelEditor = (): JSX.Element => {
		return (
			<>
				<div className="row">
					<div className="mb-3">
						<h1>{t('_general:EDIT_MEDIA_CHANNELS')}</h1>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<MultiSearchSelect
							label={t('_general:MEDIA_CHANNELS')}
							handleChangeSearchTerm={filterSuggestedItems}
							suggestedItems={filteredSuggestedChannels}
							savedItems={savedChannels}
							setSavedItems={handleSavedItems}
							displaySavedItems="top"
							errorKey="MediaChannels"
						></MultiSearchSelect>
					</div>
				</div>
			</>
		);
	};

	return renderMediaChannelEditor();
};

export default MediaChannelEditor;
