import * as apiGet from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import UrlParams from 'enums/Product/Search/UrlParams';
import { ValueScope } from 'enums/valueScope';
import { AirUrlParams, AirUrlParamsClass } from 'hooks/useAirNavigation';
import * as Co2Label from 'types/Product/Search/Co2Label';

export type Type = Co2Label.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Co2Label.Type[]> {
	const response = await apiGet.callApi('', ValueScope.HundredGrams, false);
	if (response.hasValue()) {
		return mapFromApi(response.get());
	}
	return [];
}

function mapFromApi(input: apiGet.ResponseCarbonDioxideLabel[]): Co2Label.Type[] {
	return input;
}

export function mapFromUrl(params: AirUrlParams): string[] {
	const temp: string | undefined = params[UrlParams.Co2Label];
	if (!temp) return [];
	return temp.split(';');
}

export function mapToUrl(_obj: Type, params: AirUrlParamsClass, ids: string[]): AirUrlParamsClass {
	return params.add(UrlParams.Co2Label, ids.join(';'));
}
