import Archive from 'containers/desktop/StandingData/TagCategory/Archive/TagCategoryArchive';
import Managemnt from 'containers/desktop/StandingData/TagCategory/Management/TagCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const tagCategoryRoutes = [
	{
		path: '/tagCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/tagCategory/management',
		element: <Managemnt />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
