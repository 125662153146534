import { AllergenLogic } from 'enums/allergenLogic';

export type Type = {
	display: boolean;
	iconSvgUrl?: string;
	id: string;
	logic: AllergenLogic;
	name: string;
};

export function create(): Type {
	return {
		display: false,
		iconSvgUrl: undefined,
		id: '',
		logic: AllergenLogic.Positive,
		name: '',
	};
}

export function createId(obj: Type): string {
	return `${obj.id}:${obj.logic}`;
}

export function decreateId(obj: Type): { id: string; logic: AllergenLogic } {
	const [id, logic] = obj.id.split(':');
	return {
		id: id,
		logic: logic as AllergenLogic,
	};
}
