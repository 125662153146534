import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Season {
	id: string = '';
	iconSvgUrl: string | null = null;
	name: string | null = null;
	display: boolean = false;
	associatedMonthsFlag: number | null = null;
	source: string | null = null;

	public constructor(input?: api.Season) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Season): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl;
		this.name = input.name;
		this.display = input.display;
		this.associatedMonthsFlag = input.associatedMonthsFlag;
		this.source = input.source;
	}

	public mapToISelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name ?? '',
			iconSvgUrl: this.iconSvgUrl ?? '',
			display: this.display,
		};
	}
}
