import * as apiPatch from 'api/validatorResults/PatchValidatorResultsAcknowledgeIdAcknowledgeV1';
import * as CausativeEntity from 'types/Validation/CausativeEntity';
import * as Checked from 'types/Validation/Checked';

export type Type = {
	id: string;
	createdDateUtc: string;
	acknowledgedDateUtc: string | null;
	isAcknowledged: boolean;
	validationMessage: string | null;
	causativeEntity: CausativeEntity.Type | null;
};

export function create(): Type {
	return {
		id: '',
		createdDateUtc: '',
		acknowledgedDateUtc: null,
		isAcknowledged: false,
		validationMessage: null,
		causativeEntity: null,
	};
}

export function isChecked(obj: Type, validationId: string, checked: Checked.Type): boolean {
	if (checked[obj.id] !== undefined) return checked[obj.id];
	if (obj.isAcknowledged) return true;
	if (checked[validationId]) return true;
	return false;
}

export function isAcknowledged(obj: Type, checked: Checked.Type): boolean {
	if (checked[obj.id] !== undefined) return checked[obj.id];
	if (obj.isAcknowledged) return true;
	return false;
}

export async function patchToApi(obj: Type, value: boolean): Promise<void> {
	await apiPatch.callApi(obj.id, value);
}
