import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

export default class NullableNumber implements IAdvancedSearchKey {
	public id: string;
	public value: number | null;
	public defaultValue?: number;

	constructor(id: string, defaultValue?: number) {
		this.id = id;
		this.value = null;
		this.defaultValue = defaultValue;
	}

	public get(): number {
		return this.value ?? this.defaultValue ?? 0;
	}

	public reset(): void {
		this.value = null;
	}

	public mapFromResponse(input: number): void {
		this.value = input;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				this.value = value ? Number(value) : null;
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.value) {
			return this.id + '=' + this.value;
		}
		return null;
	}

	public mapToApi(): number | null {
		if (this.value === null && this.defaultValue) {
			return this.defaultValue;
		}
		return this.value;
	}
}
