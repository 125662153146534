import { t } from 'i18next';
import { useState } from 'react';

import * as apiDeleteExternalLogo from 'api/externalLogo/DeleteExternalLogoV1';
import * as apiPutExternalLogo from 'api/externalLogo/PutExternalLogoV1';
import Loading from 'components/desktop/_general/Loading/Loading';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import 'containers/desktop/StandingData/ExternalLogo/ExternalLogo.scss';
import { fireAlert } from 'functions/fireAlert';

const ExternalLogoContainer = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [picturePath, setPicturePath] = useState<string>('/img/logoExt/external_logo_login.png');

	const handleUploadMedia = async (input: FormData | null) => {
		setIsLoading(true);
		if (input) {
			const file = input.get('Media.File') as File | null;
			if (file) {
				if (file.type === 'image/png') {
					await apiPutExternalLogo.callApi(input);
				} else {
					fireAlert(
						2,
						t('alerts:ALERT_FILE_TYPE'),
						`${t('alerts:ALERT_CHANGE_FILE_TYPE')}: png`
					);
				}
			}
		}
		setIsLoading(false);
		window.location.reload();
	};

	const handleError = (): void => {
		setPicturePath('/img/logo/logo_sm.png');
	};

	const handleDeleteExternalLogo = async () => {
		setIsLoading(true);
		await apiDeleteExternalLogo.callApi();
		setIsLoading(false);
		window.location.reload();
	};

	const getButtonClassName = (): string => {
		if (picturePath === '/img/logo/logo_sm.png') {
			return 'btn btn-danger disabled';
		} else {
			return 'btn btn-danger';
		}
	};

	const renderImage = (): JSX.Element => {
		return (
			<img
				alt="externalLogo"
				className="external-logo-img-thumbnail-custom"
				src={picturePath}
				onError={handleError}
			/>
		);
	};

	const renderUploadForm = (): JSX.Element => {
		return (
			<div className="external-logo-form">
				<h2>{t('admin:UPLOAD_EXTERNAL_LOGO')}</h2>
				{renderImage()}
				<PictureUpload setFormData={handleUploadMedia} accept={'image/png'} />
			</div>
		);
	};

	return (
		<div className="external-logo-container">
			<div className="external-logo-inner-container">
				<div className="external-logo-inner-container-div">
					{!isLoading ? renderUploadForm() : <Loading />}
					{picturePath === ''}
					<button
						type="button"
						className={getButtonClassName()}
						onClick={handleDeleteExternalLogo}
					>
						{t('_general:DELETE')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExternalLogoContainer;
