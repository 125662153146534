import { ComponentVersion } from 'types/VersionInfo/ComponentVersion';

export type VersionInfo = {
	version: string;
	buildDateUtc: string;
	buildId: number;
	branch: string;
	hash: string;
	componentVersions: ComponentVersion[];
};

export const defaultVersionInfo = (): VersionInfo => {
	return {
		version: '',
		buildDateUtc: '',
		buildId: 0,
		branch: '',
		hash: '',
		componentVersions: [],
	};
};
