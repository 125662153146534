import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'components/desktop/Recipe/LanguageInput/RecipeNutrients.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { ValueScope } from 'enums/valueScope';
import {
	calculateParts,
	calculatePer100Gram,
	calculatePersons,
} from 'functions/calculation/Recipe/calculateRecipeCaloriesNutrients';
import { getNumberString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';
import {
	CalculatedNutrient,
	NutrientCategoryGroup,
} from 'types/NutrientValue/CalculatedNutrientValues';

interface IProps {
	parts: number;
	persons: number;
	weight: number;
	nutrients: CalculatedNutrient[];
}

const RecipeNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxColumns = useSelector(
		(state: RootState) => state.userSettings['Recipe.Nutrient.Columns']
	);

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return props.nutrients
			.groupBy((x) => x.category)
			.map((k: string, nutrients: CalculatedNutrient[]) => {
				return {
					name: k,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients.orderBy((x) => x.nutrient),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	return (
		<>
			<fieldset className="margin-top-25">
				<div className="d-flex justify-content-between" style={{ gap: '10px' }}>
					<div className="d-flex flex-gap-10">
						<legend style={{ width: 'auto' }}>{t('_general:NUTRIENTS')}</legend>
						<FontAwesomeIcon
							style={{ marginTop: 4 }}
							title={t('recipe:INFO_CALCULATION_ON_ENTRYLANGUAGE')}
							icon={faCircleInfo}
						></FontAwesomeIcon>
					</div>
				</div>
				<div className="row">
					{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
						<div className="col-md-6" key={i}>
							<div className="table-header">{group.name}</div>
							<div className="scrollable-table-wrapper">
								<table className="align-middle table table-striped table-hover table-sm">
									<thead className="recipe-nutrients-thead">
										<tr>
											<th>
												<label>{t('recipe:NUTRIENT')}</label>
											</th>
											<RenderIf
												condition={reduxColumns.includes(ValueScope.Parts)}
											>
												<th className="text-end">
													<label>{t('measurments:PER_PART')}</label>
												</th>
											</RenderIf>
											<RenderIf
												condition={reduxColumns.includes(ValueScope.Person)}
											>
												<th className="text-end">
													<label>{t('measurments:PER_PERSON')}</label>
												</th>
											</RenderIf>
											<RenderIf
												condition={reduxColumns.includes(
													ValueScope.HundredGrams
												)}
											>
												<th className="text-end">
													<label>{t('measurments:PER_100_G')}</label>
												</th>
											</RenderIf>
											<RenderIf
												condition={reduxColumns.includes(ValueScope.Total)}
											>
												<th className="text-end">
													<label>{t('_general:TOTAL')}</label>
												</th>
											</RenderIf>
										</tr>
									</thead>
									<tbody className="recipe-nutrients-tbody">
										{group.nutrients.map(
											(nutrient: CalculatedNutrient, i: number) => {
												if (nutrient.total >= 0.005) {
													return (
														<tr key={i}>
															<td>
																{`${nutrient.nutrient} `}
																<span className="nutrient-table-unit">{`(${nutrient.unit})`}</span>
															</td>

															<RenderIf
																condition={reduxColumns.includes(
																	ValueScope.Parts
																)}
															>
																<td className="text-end no-wrap">
																	{calculateParts(
																		nutrient.total,
																		props.parts,
																		reduxCultureCode
																	)}
																</td>
															</RenderIf>

															<RenderIf
																condition={reduxColumns.includes(
																	ValueScope.Person
																)}
															>
																<td className="text-end no-wrap">
																	{calculatePersons(
																		nutrient.total,
																		props.persons,
																		reduxCultureCode
																	)}
																</td>
															</RenderIf>

															<RenderIf
																condition={reduxColumns.includes(
																	ValueScope.HundredGrams
																)}
															>
																<td className="text-end no-wrap">
																	{calculatePer100Gram(
																		nutrient.total,
																		props.weight,
																		reduxCultureCode
																	)}
																</td>
															</RenderIf>

															<RenderIf
																condition={reduxColumns.includes(
																	ValueScope.Total
																)}
															>
																<td className="text-end no-wrap">
																	{getNumberString(
																		nutrient.total,
																		reduxCultureCode
																	)}
																</td>
															</RenderIf>
														</tr>
													);
												}
											}
										)}
									</tbody>
								</table>
							</div>
						</div>
					))}
				</div>
			</fieldset>
		</>
	);
};

export default RecipeNutrients;
