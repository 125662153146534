import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiSW from 'api/tag/GetTagStartingWithV1';
import Tag from 'classes/MenuPlan/Detail/Tag';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class TagList {
	public all: Tag[] = [];
	public saved: Tag[] = [];
	public filtered: ISelectItem[] = [];

	public constructor(input?: apiGet.Tag[]) {
		if (input) {
			this.mapFromApi(input, 'saved');
		}
	}

	public async initialize(): Promise<void> {
		await this.callApi();
		this.transferAllToFiltered();
	}

	public async callApi(): Promise<void> {
		const response = await apiSW.callApi('', false);
		const tags = response.get().map((x) => ({
			...x,
			source: null,
		}));
		response.hasValue() && this.mapFromApi(tags, 'all');
	}

	public async mapFromISelectItems(input: ISelectItem[]): Promise<void> {
		const output: Tag[] = [];
		for (const i of input) {
			const tag: Tag | undefined = this.getById(i.id);
			if (tag) output.push(tag);
		}
		this.saved = output;
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Tag[] = this.all.filter(
			(e: Tag) => e.name?.toLowerCase().includes(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	public mapFromApi(input: apiGet.Tag[] | null, arrayName?: 'all' | 'saved') {
		if (input) {
			for (const i of input) {
				const tag: Tag = new Tag(i);
				this[arrayName ?? 'saved'].push(tag);
			}
		}
	}

	public mapToApiPut(): string[] {
		return this.saved.map((e: Tag) => {
			return e.id;
		});
	}

	public getById(id: string): Tag | undefined {
		return this.all.find((e: Tag) => {
			return e.id === id;
		});
	}

	public getSavedAsSelectItems() {
		return this.mapToSelectItems(this.saved);
	}

	public getVisibleTags(): Tag[] {
		return this.saved.filter((e: Tag) => {
			return e.display;
		});
	}

	private mapToSelectItems(input: Tag[]): ISelectItem[] {
		return input.map((e: Tag) => {
			return e.mapToISelectItem();
		});
	}

	private transferAllToFiltered() {
		this.filtered = this.mapToSelectItems(this.all);
	}
}
