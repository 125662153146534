import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Recipe } from 'api/menu/GetMenuIdV1';
import 'components/desktop/Menu/MenuNutrients/MenuNutrients.scss';
import { getNumberString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';
import { Menu } from 'types/Menu/Menu';
import {
	CalculatedNutrient,
	NutrientCategoryGroup,
} from 'types/NutrientValue/CalculatedNutrientValues';

interface IProps {
	displayMenu: boolean;
	object: Recipe | Menu;
	nutrients: CalculatedNutrient[];
}

const MenuNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const calculatePersons = (nutrientValue: number): string => {
		if (props.displayMenu) {
			if ('recipes' in props.object) {
				return calculateMenuPersons(nutrientValue, props.object.recipes);
			} else {
				return '-';
			}
		} else {
			if ('persons' in props.object) {
				return calculateRecipePersons(nutrientValue, props.object.persons ?? 0);
			} else {
				return '-';
			}
		}
	};

	const calculateRecipePersons = (nutrientValue: number, persons: number): string => {
		if (persons <= 0) {
			return '-';
		}
		return getNumberString(nutrientValue / persons, reduxCultureCode);
	};

	const calculateMenuPersons = (nutrientValue: number, recipes: Recipe[]): string => {
		let result: number = 0;
		recipes.forEach((recipe: Recipe) => {
			const persons = recipe.persons ?? 0;
			if (persons <= 0) {
				result += 0;
			} else {
				result += nutrientValue / persons;
			}
		});

		if (result == 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return props.nutrients
			.groupBy((x) => x.category)
			.map((k: string, nutrients: CalculatedNutrient[]) => {
				return {
					name: k,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients.orderBy((x) => x.nutrient),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	const renderContent = () => {
		return (
			<>
				<fieldset className="margin-top-25">
					<div className="d-flex flex-gap-10">
						<legend style={{ width: 'auto' }}>{t('_general:NUTRIENTS')}</legend>
					</div>
					<div className="row">
						{getNutrientCategoryGroups().map(
							(group: NutrientCategoryGroup, i: number) => (
								<div className="col-md-6" key={i}>
									<div className="table-header">{group.name}</div>
									<div className="scrollable-table-wrapper">
										<table className="align-middle table table-striped table-hover table-sm">
											<thead className="menu-nutrients-thead">
												<tr>
													<th>
														<label>{t('recipe:NUTRIENT')}</label>
													</th>
													<th className="text-end">
														<label>{t('measurments:PER_PERSON')}</label>
													</th>
												</tr>
											</thead>
											<tbody className="menu-nutrients-tbody">
												{group.nutrients.map(
													(nutrient: CalculatedNutrient, i: number) => {
														if (nutrient.total >= 0.005) {
															return (
																<tr key={i}>
																	<td>
																		{`${nutrient.nutrient}`}
																		<span className="nutrient-table-unit">{`(${nutrient.unit})`}</span>
																	</td>
																	<td className="text-end no-wrap">
																		{calculatePersons(
																			nutrient.total
																		)}
																	</td>
																</tr>
															);
														}
													}
												)}
											</tbody>
										</table>
									</div>
								</div>
							)
						)}
					</div>
				</fieldset>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuNutrients;
