import { ContextBuilder } from 'context/nutriscoreLabelContext';
import { useLocation, useParams } from 'react-router-dom';

import RecipeComponent from 'components/desktop/Recipe/RecipeComponent';

const RecipeDetailContainer = () => {
	const { id } = useParams();
	const location = useLocation();

	return (
		<main className="container">
			<RecipeComponent
				id={id}
				statusList={[]}
				copy={location.pathname.includes('/copy') ? true : false}
			/>
		</main>
	);
};

export default ContextBuilder.From(RecipeDetailContainer).wrapWithNutriscoreContext().build();
