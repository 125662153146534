import * as apiSW from 'api/accessoryCategory/GetAccessoryCategoryStartingWithV1';
import * as apiPostSearch from 'api/accessoryCategory/PostAccessoryCategorySearchV1';
import AccessoryCategory from 'classes/StandingData/AccessoryCategory/Search/AccessoryCategory';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class AccessoryCategoryList {
	public all: AccessoryCategory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const accessoryCategories = await apiSW.callApi(searchTerm, showOnlyDeleted);
		accessoryCategories.hasValue() && this.mapFromApiSW(accessoryCategories.get());
	}

	public async callApi(
		input: apiPostSearch.Request
	): Promise<Optional<apiPostSearch.ResponseData>> {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const accessoryCategory: AccessoryCategory = new AccessoryCategory(i);
			this.all.push(accessoryCategory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseAccessoryCategory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
