import AccessoryCategoryManagementComponent from 'components/desktop/StandingData/AccessoryCategory/Management/AccessoryCategoryManagementComponent';

const AccessoryCategoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<AccessoryCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryCategoryArchiveContainer;
