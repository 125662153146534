import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';

export default class Image {
	public id: string = '';
	public url: string = '';
	public availableSizes: number[] = [];

	public constructor(input?: api.Image) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Image | null): void {
		if (input) {
			this.id = input.id;
			this.url = input.url;
			this.availableSizes = input.availableSizes;
		}
	}
}
