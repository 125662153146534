import * as apiSW from 'api/accessoryCategory/GetAccessoryCategoryStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';

import { Selectable } from '../MultiSelect';
import AccessoryCategory from './AccessoryCategory';

export default class AccessoryCategoryList implements Selectable {
	public all: AccessoryCategory[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: AccessoryCategory[] = this.all.filter((e: AccessoryCategory) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.toSelectItemList(filtered);
	}

	private toSelectItemList(input: AccessoryCategory[]): ISelectItem[] {
		return input.map((e: AccessoryCategory) => {
			return e.toSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseAccessoryCategory[]): void {
		this.all = [];
		for (const i of input) {
			const accessoryCategory: AccessoryCategory = new AccessoryCategory(i);
			this.all.push(accessoryCategory);
		}
	}

	public getById(id: string): AccessoryCategory | undefined {
		return this.all.find((e: AccessoryCategory) => {
			return e.id === id;
		});
	}
}
