import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { SearchLogic } from 'enums/searchLogic';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/Recipe/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	accessories?: SearchLogicMap;
	allergenIds?: AllergenLogicMap;
	caloriesRange?: Range;
	carbonDioxideLabelIds?: string[];
	carbonDioxideRange?: Range;
	categoryIds?: string[];
	ingredients?: SearchLogicMap;
	nutriScoreLabelIds?: string[];
	nutrientRanges?: RangeWithId[];
	pageIndex?: number | null;
	pageSize?: number | null;
	priceRange?: Range;
	recipeIds?: string[];
	searchTerm?: string | null;
	seasonIds?: string[];
	showOnlyDeleted?: boolean;
	sortOrder?: string;
	statusIds?: string[];
	tagIds?: string[];
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseRecipe[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxCaloriesRange: Range;
	minMaxPriceRange: Range;
	minMaxCarbonDioxideRange: Range;
	minMaxNutrientRanges: ResponseNutrient[];
};

export type ResponseNutrient = {
	nutrientInfo: {
		id: string;
		sortOrder: number;
		name: string;
		unitShortNameSingular: string;
		nutrientCategory: {
			id: string;
			name: string;
		};
	};
	range: Range;
};

export type CarbonDioxideLabel = {
	color: string;
	label: string;
};

export type Image = {
	url: string;
	availableSizes: number[];
};

export type ResponseRecipe = {
	id: string;
	lastEditor: string;
	titleTranslation: string;
	lastEditedDateUtc: string;
	status: Status | null;
	image: Image;
	calories: number | null;
	carbonDioxideValue: number | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	price: number | null;
	prepareTime: PrepareTime | null;
	allergens: Allergen[];
	seasons: Season[];
	tags: Tag[];
	nutriScore: NutriScore;
	hasNutriScore: boolean;
};

export type NutriScore = {
	label: string;
	color: string;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	logic: AllergenLogic;
};

export type PrepareTime = {
	timeUnit: string;
	timeValue: number;
};

export type Status = {
	id: string;
	name: string;
	order: number;
	color: string;
};

type Range = {
	minimum: number | null;
	maximum: number | null;
};

type RangeWithId = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export type SearchLogicMap = {
	[key: string]: SearchLogic;
};
