import * as apiSW from 'api/ingredient/GetIngredientStartingWithV1';
import { SearchLogic } from 'enums/searchLogic';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';

export default class Ingredient {
	public id: string = '';
	public name: string = '';
	public logic: SearchLogic = SearchLogic.Include;

	public constructor(input?: apiSW.ResponseIngredient) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToSelectItem(): IExclusionLogicSelectItem {
		return {
			id: this.id,
			name: this.name,
			logic: this.logic,
		};
	}

	private mapFromApi(input: apiSW.ResponseIngredient): void {
		this.id = input.id;
		this.name = input.description ?? '';
		this.logic = SearchLogic.Include;
	}
}
