import Archive from 'containers/desktop/StandingData/Allergen/Archive/AllergenArchive';
import Management from 'containers/desktop/StandingData/Allergen/Management/AllergenManagement';
import PERMISSIONS from 'enums/permissions';

export const allergenRoutes = [
	{
		path: '/allergen/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/allergen/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
