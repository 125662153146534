import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

interface Props {
	currentLanguageId: string | null;
	handleChangeLanguage: (languageId: string, cultureCode: string) => void;
	className?: string;
}

const ChangeLanguage = (props: Props) => {
	const { t } = useTranslation();
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxAllowedUiLanguages: string[] = useSelector(
		(state: RootState) => state.allowedUiLanguages
	);

	const handleSelectLanguage = (e: React.ChangeEvent<HTMLSelectElement>): void => {
		const languageId: string = e.currentTarget.value;
		const language: Language | undefined = reduxLanguages.find((e: Language) => {
			return e.id === languageId;
		});
		if (language) {
			props.handleChangeLanguage(languageId, language.cultureCode);
		}
	};

	if (props.currentLanguageId !== null) {
		return (
			<div className={props.className ? props.className : ''}>
				<label>{t('_general:LANGUAGE')}</label>
				<select
					className="form-select"
					value={props.currentLanguageId}
					onChange={handleSelectLanguage}
				>
					{reduxLanguages
						.filter((x) => reduxAllowedUiLanguages.includes(x.cultureCode))
						.map((l: Language, key: number) => (
							<option key={key} value={l.id}>
								{l.name}
							</option>
						))}
				</select>
			</div>
		);
	}
	return <></>;
};

export default ChangeLanguage;
