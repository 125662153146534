import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/Accessory/Archive/AccessoryArchive';
import Detail from 'containers/desktop/Accessory/Detail/AccessoryDetail';
import New from 'containers/desktop/Accessory/New/AccessoryNew';
import Search from 'containers/desktop/Accessory/Search/AccessorySearch';
import PERMISSIONS from 'enums/permissions';

const routes = [
	{
		path: '/accessory/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READACCESSORY],
	},
	{
		path: '/accessory/search',
		element: <Search />,
		permissions: [PERMISSIONS.READACCESSORY],
	},
	{
		path: '/accessory/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITEACCESSORY],
	},
	{
		path: '/accessory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITEACCESSORY],
	},
	{
		path: '/accessory/copy/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.WRITEACCESSORY],
	},
];

export default [...routes.map(wrapProtectedRoute)];
