interface IProps {
	title: string;
	content: JSX.Element;
	className?: string;
}

const CardTransparent = (props: IProps) => {
	return (
		<div
			style={{
				border: '1px solid #ced4da',
				padding: '10px',
				paddingTop: '5px',
			}}
			className={props.className ?? ''}
		>
			<div style={{ borderBottom: '1px solid #ced4da', marginBottom: '7px' }}>
				<label
					className="form-check-label"
					style={{ marginBottom: '5px', textTransform: 'uppercase' }}
				>
					{props.title}
				</label>
			</div>
			{props.content}
		</div>
	);
};

export default CardTransparent;
