import i18next from 'i18next';
import { isNumber } from 'lodash';

import { fireAlert } from './fireAlert';
import { Optional } from './promiseExtensions';

const MB = 1024 * 1024;

const maxSizesPerFileType = {
	'image/png': 30 * MB,
	'image/jpeg': 30 * MB,
	'image/gif': 30 * MB,
	'image/tiff': 30 * MB,
	'image/svg+xml': 30 * MB,
	'video/mp4': 300 * MB,
} as { [key: string]: number };

export const validateFileSizes = (files: FileList | null): Optional<FileList> => {
	if (!files) return Optional.None();

	if (files.length == 0) return Optional.None();

	for (const file of files) {
		const maxFileSize = maxSizesPerFileType[file.type];
		if (isNumber(maxFileSize)) {
			if (file.size > maxFileSize) {
				fireAlert(
					2,
					i18next.t('alerts:ALERT_VALIDATION_ERROR'),
					i18next.t('alerts:ALERT_VALIDATION_FILE_TOO_BIG', {
						maxBytes: `${maxFileSize / MB} MB`,
					}),
					10000
				);
				return Optional.None();
			}
		} else {
			fireAlert(
				2,
				i18next.t('alerts:ALERT_VALIDATION_ERROR'),
				i18next.t('alerts:ALERT_VALIDATION_FILE_TYPE_NOT_SUPPORTED', {
					fileType: file.type,
				}),
				10000
			);
			return Optional.None();
		}
	}

	return Optional.Just(files);
};
