import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Ingredient';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: string;
}

export interface Request {
	allergens: Allergens;
	baseIngredientId: string;
	calories: number | null;
	carbonDioxide: CarbonDioxideRequest[];
	categories: string[];
	characteristic: Characteristic | null;
	density: number | null;
	hasNutriScore: boolean;
	imageId: string | null;
	isAllergenOverridden: boolean;
	isCarbonDioxideOverridden: boolean;
	isScalingFactorOverridden: boolean;
	isSeasonOverridden: boolean;
	isTagOverridden: boolean;
	isUnitWeightOverridden: boolean;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValues;
	price: number | null;
	products: string[];
	scalingFactor: number | null;
	seasons: string[];
	synonyms: Synonyms;
	tags: string[];
	unitWeights: UnitWeights;
}

export type Group = {
	id: string;
	name: string;
	parentName: string;
	nutriScoreCategory: NutriScoreCategory;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type NutriScore = {
	label: string;
	color: string;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type UnitWeight = {
	id: string;
	name: string;
	weight: number;
};

export type Category = {
	id: string;
	name: string;
	nutriScoreCategory: NutriScoreCategory;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};

export type Tag = {
	id: string;
	iconSvg: string;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Allergen = {
	id: string;
	iconSvg: string;
	name: string;
	display: boolean;
	logic: AllergenLogic;
};

export type Season = {
	id: string;
	iconSvg: string;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
	sortOrder: number;
};

export type CarbonDioxideRequest = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};

export type CarbonDioxideResponse = {
	originLocationId: string;
	origin: string;
	productionMethodId: string;
	productionMethod: string;
	value: number;
};

export type Variations = {
	articleSingularTranslations: Translation;
	textBeforeDescriptionSingularTranslations: Translation;
	textAfterDescriptionSingularTranslations: Translation;
	articlePluralTranslations: Translation;
	textBeforeDescriptionPluralTranslations: Translation;
	textAfterDescriptionPluralTranslations: Translation;
};

export type IngredientSynonymTranslations = {
	id: string | null;
	singularTranslations: Translation;
	pluralTranslations: Translation;
};

export type NutrientValues = {
	[key: string]: number;
};

export type UnitWeights = {
	[key: string]: number;
};

export type Translation = {
	[key: string]: string;
};

export type Allergens = {
	[key: string]: AllergenLogic;
};

export enum AllergenLogic {
	Positive = 'Positive',
	Negative = 'Negative',
	MayContain = 'MayContain',
}

export enum Characteristic {
	Fluid = 'Fluid',
	SolidQuantifiable = 'SolidQuantifiable',
	SolidNonQuantifiable = 'SolidNonQuantifiable',
}

export enum NutriScoreCategory {
	StandardFood = 'StandardFood',
	Cheese = 'Cheese',
	Beverage = 'Beverage',
	AddedFat = 'AddedFat',
	Water = 'Water',
}
