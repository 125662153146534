import * as apiSW from 'api/menu/GetMenuStartingWithV1';
import * as api from 'api/menu/PostMenuSearchV1';
import Menu from 'classes/Menu/Search/Menu';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class MenuList {
	public all: Menu[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		response.hasValue() && this.mapFromApiSW(response.get());
	}

	public async callApi(input: api.Request) {
		return await api.callApi(input);
	}

	public mapFromApi(input: api.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const menu: Menu = new Menu(i);
			this.all.push(menu);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseMenu[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
