import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserNutrients } from 'api/userProfile/GetUserProfileV1';
import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Nutrient } from 'types/Nutrient/Nutrient';

interface IProps {
	nutrientsAll: Nutrient[];
	nutrientsUser: UserNutrients[];
	handleAddNutrient: (input: ISelectItem[]) => void;
}

const ChangeNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const [suggestedNutrients, setSuggestedNutrients] = useState<ISelectItem[]>([]);

	const mapToISelectItem = (input: UserNutrients[]): ISelectItem[] => {
		return input.map((nutrient) => {
			return {
				id: nutrient.id,
				name: nutrient.name,
				title: nutrient.nutrientCategory.name,
			};
		});
	};

	const getNutrientsStartingWith = async (searchTerm: string): Promise<void> => {
		const filteredNutrients: Nutrient[] = props.nutrientsAll.filter(
			(e: Nutrient) => e.name && e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);

		setSuggestedNutrients(filteredNutrients);
	};

	return (
		<CardTransparent
			title={t('_general:ADVANCED_FILTER')}
			content={
				<>
					<MultiSearchSelect
						savedItems={mapToISelectItem(props.nutrientsUser)}
						backgroundColor={'#efefef'}
						setSavedItems={props.handleAddNutrient}
						displaySavedItems="bottom"
						suggestedItems={suggestedNutrients}
						handleChangeSearchTerm={getNutrientsStartingWith}
						label={t('user:NUTRIENT_CONFIGURATION')}
						triggerSearchLetterAmount={0}
					/>
				</>
			}
		/>
	);
};

export default ChangeNutrients;
