import 'components/desktop/ERP/Product/Search/SearchResultList/SearchResultList.scss';
import SearchResultListRow from 'components/desktop/ERP/Product/Search/SearchResultList/SearchResultListRow';
import * as Product from 'types/Product/Search/Product';
import * as ProductList from 'types/Product/Search/ProductList';

interface IProps {
	handleAddToIngredient: (id: string) => Promise<void>;
	productList: ProductList.Type;
}

const SearchResultList = (props: IProps) => {
	return (
		<div className="row-gy-4">
			<div className="col-lg-12">
				{props.productList.data.map((e: Product.Type, i: number) => {
					return (
						<div key={i}>
							<SearchResultListRow
								handleAddToIngredient={props.handleAddToIngredient}
								product={e}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SearchResultList;
