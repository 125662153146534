import * as UserSettings from 'types/User/UserProfile/UserSettings';

export const setReduxUserSetting = (userSettings: UserSettings.Type): UserSettingsAction => {
	return {
		type: 'userSettings/SET',
		payload: userSettings,
	};
};

export type UserSettingsAction = {
	type: string;
	payload: UserSettings.Type;
};

export const userSettingsReducer = (
	state = UserSettings.create(),
	action: UserSettingsAction
): UserSettings.Type => {
	switch (action.type) {
		case 'userSettings/SET':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
