import { faEnvelope, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/mailAccount/GetMailAccountIdV1';
import MailAccount from 'classes/StandingData/MailAccount/MailAccount';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getUserEmail } from 'functions/tokenFunctions';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const MailAccountDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [userEmail, setUserEmail] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);

	const [mailAccount, setMailAccount] = useState<MailAccount>(new MailAccount());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		setUserEmail(getUserEmail());
		if (id !== null && id !== 'new') {
			getMailAccount(id);
		}
	};

	const getMailAccount = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setMailAccount(new MailAccount(x)));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		switch (name) {
			case 'port':
				mailAccount.port = Number(e.currentTarget.value);
				break;
			case 'host':
				mailAccount.host = e.currentTarget.value;
				break;
			case 'senderEmail':
				mailAccount.senderEmail = e.currentTarget.value;
				break;
			case 'password':
				mailAccount.password = e.currentTarget.value;
				break;
			case 'username':
				mailAccount.username = e.currentTarget.value;
				break;
		}
		setMailAccount(clone(mailAccount));
	};

	const handleSaveMailAccount = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await mailAccount.callApiPut();
			} else {
				await mailAccount.callApiPost();
				if (!mailAccount.id) return;
				setId(mailAccount.id);
				setIdToUrl(mailAccount.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleSendTestMail = async (): Promise<void> => {
		setIsLoadingSend(true);
		try {
			if (id !== null && id !== 'new') {
				await mailAccount.callApiPostSendTestMail(userEmail, reduxCultureCode);
			}
		} catch {
			setIsLoadingSend(false);
		} finally {
			setIsLoadingSend(false);
		}
	};

	const createHandleToggle = (inputId: string): ((input: boolean) => void) => {
		return (input: boolean): void => {
			switch (inputId) {
				case 'useSsl':
					mailAccount.useSsl = input;
					break;
				case 'useDefaultCredentials':
					mailAccount.useDefaultCredentials = input;
					break;
			}
			setMailAccount(clone(mailAccount));
		};
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{mailAccount.id ? (
								<>
									<h1>{t('standingData:EDIT_MAIL_ACCOUNT')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_MAIL_ACCOUNT')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveMailAccount()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12">
						<Toggle
							checked={mailAccount.useSsl}
							handleToggle={createHandleToggle('useSsl')}
							elementId="useSsl"
							label={'standingData:USE_SSL'}
							errorKey="UseSsl"
						/>
						<Toggle
							checked={mailAccount.useDefaultCredentials}
							handleToggle={createHandleToggle('useDefaultCredentials')}
							elementId="useDefaultCredentials"
							label={'standingData:USE_DEFAULT_CREDENTIALS'}
							errorKey="UseDefaultCredentials"
						/>
						{mailAccount.useDefaultCredentials ? (
							<></>
						) : (
							<>
								<label>{t('standingData:USERNAME')}</label>
								<div>
									<input
										value={mailAccount.username || ''}
										type="text"
										onChange={handleInputValueChange}
										className="form-control"
										name="username"
										error-key={'Username'}
									/>
								</div>
								<label>{t('standingData:PASSWORD')}</label>
								<div>
									<input
										value={mailAccount.password || ''}
										type="password"
										onChange={handleInputValueChange}
										className="form-control"
										name="password"
										error-key={'Password'}
									/>
								</div>
							</>
						)}
					</div>
					<div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12">
						<label>{t('standingData:SENDER_EMAIL')}</label>
						<div>
							<input
								value={mailAccount.senderEmail}
								type="text"
								onChange={handleInputValueChange}
								className="form-control"
								name="senderEmail"
								error-key={'SenderEmail'}
							/>
						</div>
						<label>{t('standingData:HOST')}</label>
						<div>
							<input
								value={mailAccount.host}
								type="text"
								onChange={handleInputValueChange}
								className="form-control"
								error-key={'Host'}
								name="host"
							/>
						</div>
						<label>{t('standingData:PORT')}</label>
						<div>
							<input
								value={mailAccount.port}
								type="number"
								onChange={handleInputValueChange}
								className="form-control"
								name="port"
								error-key={'Port'}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="justify-content-start">
						{isLoadingSend ? (
							<button type="button" className="btn btn-primary" disabled={true}>
								<FontAwesomeIcon className="fa-spin" icon={faSync} />
								<span style={{ paddingLeft: '6px' }}>
									{t('standingData:CHECK_CONFIG')}
								</span>
							</button>
						) : (
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSendTestMail}
							>
								<FontAwesomeIcon icon={faEnvelope} />
								<span style={{ paddingLeft: '6px' }}>
									{t('standingData:CHECK_CONFIG')}
								</span>
							</button>
						)}
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MailAccountDetailComponent;
