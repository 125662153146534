import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import Allergen from 'classes/Ingredient/Search/Allergen';
import { Icon } from 'types/Icon/Icon';

export default class AllergenList {
	public all: Allergen[] = [];

	public constructor(input?: apiPostSearch.Allergen[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcons(): (Icon | null)[] {
		return this.all.map((e: Allergen) => {
			return e.mapToIcon();
		});
	}

	public mapFromApi(input: apiPostSearch.Allergen[] | null) {
		if (input) {
			for (const i of input) {
				const allergen: Allergen = new Allergen(i);
				this.all.push(allergen);
			}
		}
	}
}
