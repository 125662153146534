import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class PricePerIngredient {
	public ingredientIndex: number = 0;
	public ingredientId: string = '';
	public price: number = 0;

	public constructor(input?: api.PricePerIngredient) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.PricePerIngredient) {
		this.ingredientIndex = input.ingredientIndex;
		this.ingredientId = input.ingredientId;
		this.price = input.price;
	}
}
