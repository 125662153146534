import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import PriceCaloriesRendering from 'components/desktop/MenuPlan/PriceCaloriesRendering/PriceCaloriesRendering';
import COLOR from 'enums/COLOR';

interface IProps {
	rowItemWidth: number;
	selectedItem: Recipe | Menu | Row | Item | Column | null;
	setSelectedItem: (input: Row | Item | Column | null) => void;
	column?: Column;
}

const ColumnComponent = (props: IProps) => {
	const handleClick = (): void => {
		if (!props.column) return;
		props.setSelectedItem(props.column);
	};

	return (
		<div
			style={{
				backgroundColor: '#ccc',
				minWidth: `${props.rowItemWidth}px`,
				maxWidth: `${props.rowItemWidth}px`,
				padding: '10px',
				border:
					props.selectedItem?.id === props.column?.id
						? '3px solid ' + COLOR.PRIMARY
						: undefined,
				cursor: 'pointer',
			}}
			onClick={() => handleClick()}
		>
			<PriceCaloriesRendering
				costsTotal={props.column?.priceSumTotal ?? 0}
				costs={props.column?.priceSum ?? 0}
				calories={props.column?.calorieSum ?? 0}
				constraintStatusCosts={props.column?.getConstraintStatusPrice()}
				constraintStatusCalories={props.column?.getConstraintStatusCalories()}
			/>
		</div>
	);
};

export default ColumnComponent;
