import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';

export default class IngredientCategory {
	id: string = '';
	name: string = '';
	nutriScoreCategory: NutriScoreCategory = NutriScoreCategory.StandardFood;

	public constructor(input?: apiPostSearch.IngredientCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.IngredientCategory): void {
		this.id = input.id;
		this.name = input.name ?? '';
		this.nutriScoreCategory = input.nutriScoreCategory;
	}
}
