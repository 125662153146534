import { useEffect, useRef } from 'react';

const useEffectWhenMounted = (
	func: React.EffectCallback,
	dependencies: React.DependencyList | undefined
) => {
	const isMounted = useRef<boolean>(false);

	useEffect(() => {
		if (isMounted.current) {
			func();
		} else {
			isMounted.current = true;
		}
	}, dependencies);
};

export default useEffectWhenMounted;
