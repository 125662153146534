import { Recipe } from 'api/menu/GetMenuIdV1';
import { AllergenMappingDto } from 'types/Allergen/AllergenMappingDto';
import { SeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagMappingDto } from 'types/Tag/TagMappingDto';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export type Menu = {
	[key: string]: any;
	allergens: AllergenMappingDto[];
	calories: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	carbonDioxideValue: number | null;
	id: string;
	nameTranslations: LocalizedTranslation<string>;
	nutrientValues: NutrientValue[];
	price: number | null;
	recipes: Recipe[];
	seasons: SeasonMappingDto[];
	shortDescriptionTranslations: LocalizedTranslation<string>;
	status: Status;
	systemStatus: SystemStatus;
	tags: TagMappingDto[];
	weight: number | null;
};

export type Status = {
	id: string;
	color: string | null;
	order: number;
	name: string | null;
};

export type NutrientValue = {
	category: string;
	categoryId: string;
	isMacroNutrient: boolean;
	nutrient: string;
	nutrientId: string;
	total: number;
	unit: string;
	unitId: string;
	nutrientCategorySortOrder: number;
	source: string | null;
};

export type CarbonDioxideLabel = {
	color: string | null;
	label: string | null;
};

export const defaultMenu = (): Menu => {
	return {
		allergens: [],
		calories: null,
		carbonDioxideLabel: {} as CarbonDioxideLabel,
		carbonDioxideValue: 0,
		id: '',
		nameTranslations: {} as LocalizedTranslation<string>,
		nutrientValues: [],
		price: null,
		recipes: [],
		seasons: [],
		shortDescriptionTranslations: {} as LocalizedTranslation<string>,
		status: {} as Status,
		systemStatus: 'New',
		tags: [],
		weight: null,
	};
};
