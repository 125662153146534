import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { ConstraintStatus } from 'enums/ConstraintStatus';
import ENTITLEMENTS from 'enums/entitlements';
import getConstraintIcon from 'functions/getConstraintIcon';
import { getNumberString } from 'functions/numberToString';
import { getPriceString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	costs: number;
	costsTotal: number;
	calories: number;
	constraintStatusCosts?: ConstraintStatus;
	constraintStatusCalories?: ConstraintStatus;
}

const PriceCaloriesRendering = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const renderConstraintIcon = (input?: ConstraintStatus): JSX.Element => {
		if (input) return getConstraintIcon(input);
		return <></>;
	};

	return (
		<>
			<div>
				<label>{t('menuPlan:CALORIES_PER_PERSON')}</label>
			</div>
			<div>
				{renderConstraintIcon(props.constraintStatusCalories)}
				<strong>{getNumberString(props.calories, reduxCultureCode)}</strong> kcal
			</div>
			<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
				<div>
					<label>{t('menuPlan:COST_PER_PERSON')}</label>
				</div>
				<div>
					{renderConstraintIcon(props.constraintStatusCosts)}
					<strong>{getPriceString(props.costs, reduxCultureCode)}</strong>
					{' ' + reduxCurrency}
				</div>
			</RenderIf>
			<div>
				<label>{t('menuPlan:COSTS_TOTAL')}</label>
			</div>
			<div>
				<strong>{getNumberString(props.costsTotal, reduxCultureCode)}</strong>
				{' ' + reduxCurrency}
			</div>
		</>
	);
};

export default PriceCaloriesRendering;
