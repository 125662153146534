import TagManagementComponent from 'components/desktop/StandingData/Tag/Management/TagManagementComponent';

const TagArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<TagManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default TagArchiveContainer;
