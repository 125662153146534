import * as api from 'api/recipe/PostRecipeSearchV1';

export default class Status {
	id: string = '';
	color: string = '';
	order: number = 0;
	name: string = '';

	public constructor(input?: api.Status) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Status | null): void {
		if (input) {
			this.id = input.id;
			this.color = input.color;
			this.order = input.order;
			this.name = input.name ?? '';
		}
	}
}
