import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(searchTerm: string): Promise<Optional<ResponseProduct[]>> {
	const url: string = '/backend-service/Product/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
	};
	return axios.get(url, { params: params }).toOptionalMapped((x) => x.data.products);
}

interface Parameter {
	SearchTerm?: string;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	products: ResponseProduct[];
};

export type ResponseProduct = {
	id: string;
	description: string;
	sortOrder: number;
};
