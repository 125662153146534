enum COLOR {
	PRIMARY = '#6699cc',
	SECONDARY = '#2d5776',
	DANGER = '#dc3545',
	DARK = '#b8cee6',
	SUCCESS = '#198754',
	WARNING = '#ffc107',
}

export default COLOR;
