import axios from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import ResendConfirmationForm from 'components/desktop/User/ResendConfirmation/ResendConfirmationForm';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import { EAlertType } from 'enums/alertType';
import { fireAlert } from 'functions/fireAlert';
import getQueryParam from 'functions/getQueryParam';

const ResendConfirmation = () => {
	let userId: string | null = getQueryParam('user');
	if (!userId) userId = '';
	const [email, setEmail] = useState<string>(String(userId));
	const navigate = useNavigate();

	const apiPost = async () => {
		const url = '/backend-service/User/SendConfirmationcode';
		try {
			const response = await axios.post(url, {
				email: email,
				confirmationPageUrl: window.origin + '/verify',
			});
			if (response.status == 200) {
				fireAlert(EAlertType.success, t('alerts:ALERT_EMAIL_GOOD_TITLE'), '', 10000);
				navigate('/login');
			} else {
				fireAlert(
					EAlertType.danger,
					t('alerts:ALERT_VALIDATION_ERROR'),
					t('alerts:ALERT_VALIDATION_EMAIL_ERROR_MESSAGE'),
					10000
				);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleConfirm = () => {
		apiPost();
	};

	const renderContent = () => {
		return (
			<>
				<div className="credentials-background-gradient">
					<div className="credentials-white-bar" />
					<div className="credentials-container">
						<LogoAnimation />
						<div className="credentials-form">
							<h1 className="text-center">
								{t('_general:TITLE_EMAIL_CONFIRMATION')}
							</h1>
							<hr />
							<ResendConfirmationForm
								email={email}
								setEmail={setEmail}
								confirm={handleConfirm}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ResendConfirmation;
