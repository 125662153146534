import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import Nutrient from 'classes/MenuPlan/Detail/Nutrient';
import { DoughnutData } from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';

export default class NutrientList {
	public all: Nutrient[] = [];

	public constructor(input?: api.Nutrient[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Nutrient[] | null) {
		if (!input) return;
		this.all = input.map((e) => new Nutrient(e));
	}

	public mapFromApiCalculate(input: apiCalculate.NutrientValues[] | null) {
		if (!input) return;
		this.all = input.map((e) => new Nutrient(e));
	}

	public mapToApi(): api.Nutrient[] {
		const output: api.Nutrient[] = [];
		for (const i of this.all) {
			output.push(i.mapToApi());
		}
		return output;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.all
			.groupBy((x) => x.categoryId)
			.map((_, nutrients) => {
				return {
					name: nutrients[0].category!,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients
						.orderBy((x) => x.nutrient)
						.map((x) => {
							return {
								nutrient: x.nutrient!,
								category: x.category!,
								total: x.total,
								unit: x.unit!,
								isMacroNutrient: x.isMacroNutrient,
								nutrientCategorySortOrder: x.nutrientCategorySortOrder,
							};
						}),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	}

	public getMacroNutrientChartData(): DoughnutData[] {
		const macroWeight = this.all
			.filter((n: Nutrient) => n.isMacroNutrient)
			.reduce((p, c) => p + c.total, 0);
		const macros: DoughnutData[] = this.all
			.filter((n: Nutrient) => n.isMacroNutrient)
			.map((n) => {
				return {
					label: n.nutrient ?? '',
					value: n.total / macroWeight,
				};
			});
		return macros;
	}
}
