import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, PropsWithChildren } from 'react';

import 'components/desktop/_general/Overlay/Overlay.scss';

interface IProps {
	displayOverlay: boolean;
	handleClose: () => void;
	containerStyle?: React.CSSProperties;
	contentStyle?: React.CSSProperties;
}

const Overlay = ({
	displayOverlay,
	handleClose,
	children,
	containerStyle,
	contentStyle,
}: PropsWithChildren<IProps>) => {
	const renderOverlay = (): JSX.Element | null => {
		return (
			<Fragment>
				{displayOverlay && (
					<div
						className="overlay"
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<div className="overlay-background" onClick={handleClose} />
						<div style={containerStyle} className="overlay-container">
							<div className="overlay-controls">
								<FontAwesomeIcon
									icon={faXmark as IconProp}
									className="icon"
									id="icon-close"
									onClick={handleClose}
									size="lg"
								/>
							</div>
							<div style={contentStyle} className="overlay-content">
								{children}
							</div>
						</div>
					</div>
				)}
			</Fragment>
		);
	};

	return displayOverlay ? renderOverlay() : null;
};

export default Overlay;
