import { faCamera, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { useRef } from 'react';

import { MEDIATYPES } from 'enums/mediaTypes';

interface IProps {
	handleUploadMedia: (id: FileList) => void;
	label: string;
	accept: MEDIATYPES[];
}

const BtnMediaUpload = (props: IProps) => {
	const inputMedia = useRef<HTMLInputElement>(null);

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;
		props.handleUploadMedia(files);
		e.target.value = '';
	};

	return (
		<div>
			<label className="form-label" style={{ verticalAlign: 'top' }}>
				{props.label}
			</label>
			<div style={{ display: 'flex', flexDirection: 'row', marginTop: '-6px', gap: '2px' }}>
				<div
					id="basic-addon1"
					style={{ backgroundColor: 'grey', padding: '5px 10px 5px 10px' }}
					onClick={() => handleFileChange}
				>
					<FontAwesomeIcon
						icon={props.accept.includes(MEDIATYPES.MP4) ? faVideoCamera : faCamera}
						style={{ color: 'white' }}
					/>
				</div>
				<input
					onChange={handleFileChange}
					type="file"
					multiple
					accept={props.accept.join(', ')}
					ref={inputMedia}
					style={{ display: 'none' }}
				/>
				<button
					className="btn-primary"
					type="button"
					style={{ flex: '1', border: 'none', fontSize: '0.84em' }}
					onClick={() => inputMedia.current?.click()}
				>
					{t('_general:BROWSE_FILES')}
				</button>
			</div>
		</div>
	);
};

export default BtnMediaUpload;
