import 'components/desktop/BaseIngredient/BaseIngredientComponent.scss';
import * as BaseIngredientState from 'components/desktop/BaseIngredient/BaseIngredientState';
import Co2Footprints from 'components/desktop/_general/Co2Footprint/Co2Footprints';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import * as Co2List from 'types/BaseIngredient/Detail/Co2List';

interface IProps {
	state: BaseIngredientState.Type;
	setCo2Footprints: (input: Co2List.Type) => void;
}

const TabCo2Footprints = ({ state, ...props }: IProps) => {
	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);

	return (
		<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
			<Co2Footprints
				disabled={!baseIngredientEditor}
				characteristic={state.characteristic}
				footprints={state.co2Footprints}
				setFootprints={props.setCo2Footprints}
			/>
		</RenderIf>
	);
};

export default TabCo2Footprints;
