import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import Header from 'containers/desktop/Recipe/Print/Header';
import Instructions from 'containers/desktop/Recipe/Print/Instructions';
import Overview from 'containers/desktop/Recipe/Print/Overview';
import 'containers/desktop/Recipe/Print/RecipePrint.scss';
import useAirNavigation from 'hooks/useAirNavigation';
import { useReaction } from 'hooks/useReaction';
import * as Analysis from 'types/Recipe/Print/Analysis/Analysis';
import * as Recipe from 'types/Recipe/Print/Recipe';
import * as Search from 'types/Recipe/Print/Search';
import Style from 'types/_general/Style';

export const style: Style = {
	h1: { marginTop: '20px', marginBottom: '15px', fontSize: '1.5em', fontWeight: 'bold' },
	h3: { fontSize: '1.2em' },
	text: { fontSize: '0.65em' },
};

const RecipePrint = (): JSX.Element => {
	const { id } = useParams();
	const { params } = useAirNavigation();
	const search = useMemo(() => Search.getFromUrl(params), [params.toString()]);

	const [recipe, isLoaded] = useReaction<Recipe.Type, Search.Type, string>(
		Recipe.create(),
		Recipe.getFromApi,
		search,
		id ?? ''
	);

	const [analysis, isAnalysisLoaded] = useReaction<Analysis.Type, Recipe.Type, string>(
		Analysis.create(),
		Analysis.getFromApi,
		recipe,
		search.cultureCode
	);

	useEffect(() => {
		if (isLoaded && isAnalysisLoaded) {
			window.print();
			window.close();
		}
	}, [isAnalysisLoaded]);

	return (
		<>
			<Header />
			<Overview recipe={recipe} cultureCode={search.cultureCode} />
			<Instructions recipe={recipe} cultureCode={search.cultureCode} analysis={analysis} />
		</>
	);
};

export default RecipePrint;
