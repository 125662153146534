import { Media } from 'api/media/PostMediaV1';
import * as apiPostMedia from 'api/media/PostMediaV1';
import * as apiGet from 'api/tag/GetTagIdV1';
import * as apiPost from 'api/tag/PostTagV1';
import * as apiPut from 'api/tag/PutTagV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import TagCategory from 'classes/StandingData/Tag/TagCategory';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { IconSvg } from 'types/_general/IconSvg';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Tag {
	public id: string = '';
	public nameTranslations: LocalizedTranslation<string> = {};
	public display: boolean = false;
	public iconSvg: IconSvg | null = null;
	public tagCategory: TagCategory = new TagCategory();

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiUploadMedia(input: FormData | null): Promise<void> {
		const response = await apiPostMedia.callApi(
			this.mapToApiMedia(input) as apiPostMedia.Request
		);
		response.hasValue() && this.mapFromApiUploadMedia(response.get());
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiMedia(formData: FormData | null): apiPostMedia.Request {
		return {
			mediaCollection: MEDIACOLLECTION.Icon,
			formData: formData,
		};
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			iconSvgId: this.iconSvg?.id ?? null,
			display: this.display,
			nameTranslations: this.nameTranslations,
			categoryId: this.tagCategory.id,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			iconSvgId: this.iconSvg?.id ?? null,
			display: this.display,
			nameTranslations: this.nameTranslations,
			categoryId: this.tagCategory.id,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.iconSvg = input.iconSvg;
		this.display = input.display;
		this.nameTranslations = input.nameTranslations;
		this.tagCategory.mapFromApi(input.tagCategory);
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
	}

	private mapFromApiUploadMedia(input: Media | null) {
		this.iconSvg = input;
	}
}
