import Allergen from 'classes/_general/Search/Allergen/Allergen';
import AllergenList from 'classes/_general/Search/Allergen/AllergenList';
import { AllergenLogic } from 'enums/allergenLogic';
import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';
import { ISelectItem } from 'interfaces/ISelectItem';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';

export class AllergenSelect implements IAdvancedSearchKey {
	public id: string;
	public suggested: AllergenList;
	public saved: Allergen[] = [];
	public cultureCode: string | null = getCultureCodeFromLocalStorage();

	constructor(id: string) {
		this.id = id;
		this.suggested = new AllergenList();
	}

	public async initialize() {
		const cultureCode: string | null = getCultureCodeFromLocalStorage();
		if (cultureCode !== this.cultureCode) {
			this.cultureCode = cultureCode;
			await this.suggested.initialize();
			this.translateSaved();
		}
		if (this.suggested.all.length === 0) {
			await this.suggested.initialize();
		}
	}

	public reset() {
		this.saved = [];
	}

	private translateSaved() {
		const output: Allergen[] = [];
		for (const i of this.saved) {
			const object: Allergen | undefined = this.suggested.getById(i.id);
			if (!object) continue;
			output.push(object);
		}
		this.saved = output;
	}

	public pushToSaved(list: Allergen[]): void {
		this.saved = list;
	}

	public mapFromISelectItem(items: ISelectItem[]): void {
		const output: Allergen[] = [];
		for (const i of items) {
			const allergen: Allergen | undefined = this.getByIdAndName(i.id, i.name);
			if (allergen) output.push(allergen);
		}
		this.saved = output;
	}

	public getByIdAndName(id: string, name: string): Allergen | undefined {
		return this.suggested.all.find((e: Allergen) => {
			return e.id === id && e.name === name;
		});
	}

	public mapToApi(): AllergenLogicMap {
		const output = {} as AllergenLogicMap;

		this.saved.map((e) => {
			output[e.id] = e.logic;
		});

		return output;
	}

	public mapToUrl(): string | null {
		if (this.saved.length === 0) return null;
		const output: string[] = [];
		output.push(this.saved.map((e) => e.id + e.logic.substring(0, 1)).join(';'));
		return this.id + '=' + output.join(';');
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key !== this.id) continue;
			if (typeof value !== 'string') continue;

			const id: string | undefined = value.slice(0, -1);
			const logicFromUrl: string | undefined = value.slice(-1);
			if (!id) continue;
			if (this.saved.find((e) => e.id === id)) continue;

			let logic: AllergenLogic = AllergenLogic.Positive;
			switch (logicFromUrl) {
				case 'P':
					logic = AllergenLogic.Positive;
					break;
				case 'N':
					logic = AllergenLogic.Negative;
					break;
				case 'M':
					logic = AllergenLogic.MayContain;
					break;
			}
			const object: any = this.suggested.getByIdAndLogic(id, logic);
			if (object) {
				this.saved.push(object);
			}
		}
	}

	public async search(searchTerm: string): Promise<void> {
		await this.suggested.search(searchTerm);
	}
}
