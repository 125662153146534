import Management from 'containers/desktop/StandingData/MailTemplate/MailTemplateManagement';
import PERMISSIONS from 'enums/permissions';

export const mailTemplateRoutes = [
	{
		path: '/mailTemplate',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
