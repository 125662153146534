export interface QueryParams {
	[key: string]: string | boolean;
}

export function getQueryParams(): QueryParams {
	const queryParams: QueryParams = {};
	const search = window.location.search.substring(1);
	const params = search.split('&');

	for (let i = 0; i < params.length; i++) {
		const param = params[i].split('=');
		const key = decodeURIComponent(param[0]);
		const value = decodeURIComponent(param[1]);

		if (value === undefined) {
			queryParams[key] = true;
		} else {
			queryParams[key] = value;
		}
	}

	return queryParams;
}
