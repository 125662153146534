import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';

export default class RecipeStatus {
	public id: string = '';
	public color: string = '';
	public order: number = 0;
	public name: string = '';

	public constructor(input?: postMenuSearchV1.RecipeStatus) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: postMenuSearchV1.RecipeStatus): void {
		this.id = input.id;
		this.color = input.color;
		this.order = input.order;
		this.name = input.name ?? '';
	}
}
