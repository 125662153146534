import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Recipe, Status } from 'api/menu/GetMenuIdV1';
import StatusEditor from 'components/desktop/Menu/Sidebar/StatusEditor/StatusEditor';
import TagEditor from 'components/desktop/Menu/Sidebar/TagEditor/TagEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import LabeledCheckbox from 'components/desktop/_general/Checkbox/LabeledCheckbox';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import NutriScoreSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreSelect';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import ENTITLEMENTS from 'enums/entitlements';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import { getPriceString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { Menu } from 'types/Menu/Menu';
import { SeasonLight } from 'types/Season/SeasonLight';
import { SeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagLight } from 'types/Tag/TagLight';
import { TagMappingDto } from 'types/Tag/TagMappingDto';

interface IProps {
	displayMenu: boolean;
	menu: Menu;
	selectedRecipe: Recipe;
	setDisplayMenu: (input: boolean) => void;
	setMenu: (menu: Menu) => void;
	statusList: Status[];
	tagList: TagLight[];
}

const MenuSidebar = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleSetStatus = (status: Status) => {
		const menuCpy = { ...props.menu };
		menuCpy.status = status;
		props.setMenu(menuCpy);
	};

	const handleSetTags = (tags: TagLight[]) => {
		const menuCpy = { ...props.menu };
		const result: TagMappingDto[] = [];
		for (const tag of tags) {
			result.push({ ...tag, source: null });
		}
		menuCpy.tags = result;
		props.setMenu(menuCpy);
	};

	const handleSetSystemStatus = (status: SystemStatus) => {
		const menuCpy = { ...props.menu };
		menuCpy.systemStatus = status;
		props.setMenu(menuCpy);
	};

	const handleSetHasNutriScore = (input: boolean) => {
		const menuCpy = { ...props.menu };
		const index = menuCpy.recipes.findIndex((x) => x.id === props.selectedRecipe.id);
		menuCpy.recipes[index].hasNutriScore = input;
		props.setMenu(menuCpy);
	};

	const handleSetNutriScoreCategory = (input: NutriScoreCategory) => {
		const menuCpy = { ...props.menu };
		const index = menuCpy.recipes.findIndex((x) => x.id === props.selectedRecipe.id);
		menuCpy.recipes[index].nutriScoreCategory = input;
		props.setMenu(menuCpy);
	};

	const displayRecipe = (): boolean => {
		if (props.selectedRecipe && !props.displayMenu) {
			return true;
		} else {
			return false;
		}
	};

	const getCosts = (): JSX.Element => {
		if (displayRecipe()) {
			return renderPrice(props.selectedRecipe.price);
		} else {
			return renderPrice(props.menu.price);
		}
	};

	const getAllergens = (): JSX.Element => {
		if (displayRecipe()) {
			if (props.selectedRecipe.allergens.length) {
				return (
					<div className="overflow-auto">
						{props.selectedRecipe.allergens.map(
							(allergen: AllergenLight, i: number) => (
								<ListItem
									iconSvgUrl={allergen.iconSvgUrl}
									key={i}
									text={allergen.name}
									textColor={
										allergen.logic === 'Positive' ? '#e35359' : undefined
									}
								/>
							)
						)}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_ALLERGENS')}</span>;
			}
		} else {
			if (props.menu.allergens.length) {
				return (
					<div className="overflow-auto">
						{props.menu.allergens.map((allergen: AllergenLight, i: number) => (
							<ListItem
								iconSvgUrl={allergen.iconSvgUrl}
								key={i}
								text={allergen.name}
								textColor={allergen.logic === 'Positive' ? '#e35359' : undefined}
							/>
						))}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_ALLERGENS')}</span>;
			}
		}
	};

	const getSeasons = (): JSX.Element => {
		if (displayRecipe()) {
			if (props.selectedRecipe.seasons.length) {
				return (
					<div className="overlow-auto">
						{props.selectedRecipe.seasons.map((season: SeasonLight, i: number) => (
							<ListItem key={i} text={season.name} iconSvgUrl={season.iconSvgUrl} />
						))}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_SEASONS')}</span>;
			}
		} else {
			if (props.menu.seasons.length) {
				return (
					<div className="overflow-auto">
						{props.menu.seasons.map((season: SeasonMappingDto, i: number) => (
							<ListItem key={i} text={season.name} iconSvgUrl={season.iconSvgUrl} />
						))}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_SEASONS')}</span>;
			}
		}
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (displayRecipe()) {
			if (props.selectedRecipe.nutrientValues) {
				const macroWeight = props.selectedRecipe.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.reduce((p, c) => p + c.total, 0);
				const macros = props.selectedRecipe.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.map((n) => {
						return {
							label: n.nutrient,
							value: n.total / macroWeight,
						};
					});
				return macros;
			}
			return [];
		} else {
			if (props.menu.nutrientValues) {
				const macroWeight = props.menu.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.reduce((p, c) => p + c.total, 0);
				const macros = props.menu.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.map((n) => {
						return {
							label: n.nutrient,
							value: n.total / macroWeight,
						};
					});
				return macros;
			}
			return [];
		}
	};

	const renderPrice = (input: number | null): JSX.Element => {
		if (input) {
			return (
				<>
					{`${reduxCurrency} `}
					<b>{getPriceString(input, reduxCultureCode)}</b>
				</>
			);
		} else {
			return <span className="grey">{t('recipe:NO_PRICE')}</span>;
		}
	};

	const renderContent = () => {
		return (
			<>
				<LabeledCheckbox
					label={t('menu:DISPLAY_MENU_DETAILS')}
					checked={props.displayMenu}
					setChecked={props.setDisplayMenu}
					disabled={props.selectedRecipe.id ? false : true}
				/>
				<FoldableCard
					defaultOpen={true}
					title={t('recipe:STATUS')}
					content={
						<>
							<div className="card-body">
								<StatusEditor
									disabled={displayRecipe() ? !recipeEditor : !menuEditor}
									handleSetStatus={handleSetStatus}
									handleSetSystemStatus={handleSetSystemStatus}
									object={displayRecipe() ? props.selectedRecipe : props.menu}
									statusList={props.statusList}
								/>
							</div>
						</>
					}
					defaultLocked={displayRecipe()}
					hasLock={true}
					unlockable={!displayRecipe() && menuEditor}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<FoldableCard
						defaultOpen={true}
						title={t('_general:COSTS')}
						content={getCosts()}
						defaultLocked={displayRecipe()}
						hasLock={displayRecipe()}
						unlockable={false}
					/>
				</RenderIf>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:TAGS')}
					content={
						<>
							<div className="card-body">
								<TagEditor
									handleSetTags={handleSetTags}
									object={displayRecipe() ? props.selectedRecipe : props.menu}
									tagList={props.tagList}
									disabled={displayRecipe() ? !recipeEditor : !menuEditor}
								/>
							</div>
						</>
					}
					defaultLocked={displayRecipe()}
					hasLock={true}
					unlockable={!displayRecipe() && menuEditor}
				/>
				<FoldableCard
					content={getAllergens()}
					defaultOpen={true}
					info={'_general:ALLERGEN_INFO_TOOLTIP'}
					title={t('_general:ALLERGENS')}
					defaultLocked={displayRecipe()}
					hasLock={displayRecipe()}
					unlockable={false}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
					<FoldableCard
						content={getSeasons()}
						defaultOpen={true}
						title={t('_general:SEASONS')}
						defaultLocked={displayRecipe()}
						hasLock={displayRecipe()}
						unlockable={false}
					/>
				</RenderIf>
				{displayRecipe() && (
					<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
						<FoldableCard
							defaultOpen={true}
							title={t('_general:NUTRI_SCORE')}
							content={
								<NutriScoreSelect
									hasNutriScore={props.selectedRecipe.hasNutriScore}
									nutriScoreCategory={props.selectedRecipe.nutriScoreCategory}
									setHasNutriScore={handleSetHasNutriScore}
									setNutriScoreCategory={handleSetNutriScoreCategory}
									disabled={!recipeEditor}
								/>
							}
							defaultLocked={true}
							hasLock={displayRecipe()}
							unlockable={false}
						/>
					</RenderIf>
				)}
				<FoldableCard
					defaultOpen={true}
					title={t('_general:NUTRI_DIAGRAM')}
					content={<NutritionDiagram data={getMacroNutrientChartData()} />}
					defaultLocked={displayRecipe()}
					hasLock={displayRecipe()}
					unlockable={false}
				/>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuSidebar;
