import 'components/desktop/_general/Dropdown/Dropdown.scss';

export type DropdownListItem = {
	name: string;
	value: string;
};

interface IProps {
	name: string;
	listFunction: (input: string) => void;
	list: DropdownListItem[];
	currentValue: string;
	className?: string;
}

const Dropdown = (props: IProps) => {
	const getClassName = (item: DropdownListItem): string => {
		if (item.value === props.currentValue) {
			return 'dropdown-item active';
		}
		return 'dropdown-item';
	};

	return (
		<div className={props.className ? 'dropdown ' + props.className : 'dropdown'}>
			<a
				className="btn btn-outline-primary dropdown-toggle"
				role="button"
				id="dropdownMenuLink"
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
				{props.name}
			</a>

			<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
				{props.list.map((item: DropdownListItem, i: number) => (
					<li key={i} onClick={() => props.listFunction(item.value)}>
						<a className={getClassName(item)}>{item.name}</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Dropdown;
