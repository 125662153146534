import * as apiGet from 'api/mailAccount/GetMailAccountIdV1';
import * as apiPost from 'api/mailAccount/PostMailAccountV1';
import * as apiPostSendTestMail from 'api/mailAccount/PostSendTestMailV1';
import * as apiPut from 'api/mailAccount/PutMailAccountV1';

export default class MailAccount {
	public id: string = '';
	public senderEmail: string = '';
	public host: string = '';
	public port: number = 0;
	public username: string | null = null;
	public useSsl: boolean = false;
	public password: string | null = null;
	public useDefaultCredentials: boolean = false;

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiPostSendTestMail(userMail: string, cultureCode: string): Promise<void> {
		await apiPostSendTestMail.callApi(this.mapToApiPostSendTestMail(userMail, cultureCode));
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiPostSendTestMail(
		userMail: string,
		cultureCode: string
	): apiPostSendTestMail.Request {
		return {
			emailAccountId: this.id,
			email: userMail,
			cultureCode: cultureCode,
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			senderEmail: this.senderEmail,
			host: this.host,
			port: this.port,
			username: this.username,
			password: this.password,
			useSsl: this.useSsl,
			useDefaultCredentials: this.useDefaultCredentials,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			senderEmail: this.senderEmail,
			host: this.host,
			port: this.port,
			username: this.username,
			password: this.password,
			useSsl: this.useSsl,
			useDefaultCredentials: this.useDefaultCredentials,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.senderEmail = input.senderEmail;
		this.host = input.host;
		this.port = input.port;
		this.username = input.username;
		this.useSsl = input.useSsl;
		this.useDefaultCredentials = input.useDefaultCredentials;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}
}
