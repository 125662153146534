import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/EmailAccount/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	senderEmail: string;
	host: string;
	port: number;
	username: string | null;
	password: string | null;
	useSsl: boolean;
	useDefaultCredentials: boolean;
}
