import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/Allergen/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	negativeIconSvgId: string | null;
	positiveIconSvgId: string | null;
	mayContainIconSvgId: string | null;
	display: boolean;
	positiveNameTranslations: {
		[key: string]: string;
	};
	negativeNameTranslations: {
		[key: string]: string;
	};
	mayContainNameTranslations: {
		[key: string]: string;
	};
}
