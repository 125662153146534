import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ActivityTimeEditor from 'components/desktop/Recipe/ActivityTimeEditor/ActivityTimeEditor';
import CategoryEditor from 'components/desktop/Recipe/CategoryEditor/CategoryEditor';
import PartsPersonsEditor from 'components/desktop/Recipe/PartsPersonsEditor/PartsPersonsEditor';
import RecipeScalingEditor from 'components/desktop/Recipe/RecipeScalingEditor/RecipeScalingEditor';
import StatusEditor from 'components/desktop/Recipe/StatusEditor/StatusEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import NutriScoreSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreSelect';
import TagEditor from 'components/desktop/_general/TagEditor/TagEditor';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import ENTITLEMENTS from 'enums/entitlements';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { RootState } from 'reducers/rootReducer';
import { ActivityTime } from 'types/ActivityTime/ActivityTime';
import { Recipe } from 'types/Recipe/Recipe';
import { RecipeCategoryLight } from 'types/RecipeCategory/RecipeCategoryLight';
import { StatusLight } from 'types/Status/StatusLight';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagLight } from 'types/Tag/TagLight';

interface IProps {
	handleSeoToggle: (input: boolean) => void;
	handleSetRecipeAttribute: (
		key: keyof Recipe,
		value:
			| StatusLight
			| SystemStatus
			| number
			| string
			| ActivityTime[]
			| NutriScoreCategory
			| boolean
			| null
	) => void;
	handleSetRecipeTags: (recipeTags: TagLight[]) => void;
	handleSetRecipeCategories: (recipeCategories: RecipeCategoryLight[]) => void;
	readOnly: boolean;
	recipe: Recipe;
	statusList: StatusLight[];
	tags: TagLight[];
	recipeCategories: RecipeCategoryLight[];
}

const RecipeMetadataComponent = (props: IProps) => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getRecipeText = (): string => {
		if (
			props.recipe.shortDescriptionTranslations[reduxCultureCode] &&
			props.recipe.stepsTranslations[reduxCultureCode]
		) {
			return (
				props.recipe.shortDescriptionTranslations[reduxCultureCode] +
				props.recipe.stepsTranslations[reduxCultureCode]
			);
		} else if (
			!props.recipe.shortDescriptionTranslations[reduxCultureCode] &&
			props.recipe.stepsTranslations[reduxCultureCode]
		) {
			if (props.recipe.stepsTranslations[reduxCultureCode]) {
				return props.recipe.stepsTranslations[reduxCultureCode];
			} else {
				return '';
			}
		} else {
			return props.recipe.shortDescriptionTranslations[reduxCultureCode];
		}
	};

	return (
		<>
			<div className="margin-top-25">
				<div className="row">
					<div className="col-4">
						<FoldableCard
							defaultOpen={true}
							title={t('recipe:RECIPE_CATEGORY')}
							content={
								<div className="card-body">
									<CategoryEditor
										allRecipeCategories={props.recipeCategories}
										recipeCategories={props.recipe.categories}
										setRecipeCategories={props.handleSetRecipeCategories}
									/>
								</div>
							}
						/>
					</div>
					<div className="col-4">
						<FoldableCard
							defaultOpen={true}
							title={t('recipe:STATUS')}
							content={
								<>
									<div className="card-body">
										<StatusEditor
											handleSetRecipeAttribute={
												props.handleSetRecipeAttribute
											}
											recipe={props.recipe}
											statusList={props.statusList}
										/>
										<Toggle
											checked={props.recipe.isSeoActive}
											disabled={props.readOnly}
											elementId={'seo'}
											errorKey="IsSeoActive"
											handleToggle={props.handleSeoToggle}
											label={'recipe:SEO_ACTIVE'}
										/>
									</div>
								</>
							}
						/>
					</div>
					<div className="col-4">
						<FoldableCard
							defaultOpen={true}
							title={t('recipe:PARTS_PERSONS')}
							content={
								<div className="card-body">
									<PartsPersonsEditor
										handleSetRecipeAttribute={props.handleSetRecipeAttribute}
										recipe={props.recipe}
									/>
								</div>
							}
						/>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-4">
						<FoldableCard
							defaultOpen={true}
							title={t('_general:NUTRI_SCORE')}
							content={
								<NutriScoreSelect
									hasNutriScore={props.recipe.hasNutriScore}
									nutriScoreCategory={props.recipe.nutriScoreCategory}
									setHasNutriScore={props.handleSetRecipeAttribute.bind(
										null,
										'hasNutriScore'
									)}
									setNutriScoreCategory={props.handleSetRecipeAttribute.bind(
										null,
										'nutriScoreCategory'
									)}
									disabled={props.readOnly}
								/>
							}
						/>
					</div>
					<RenderIf entitlements={[ENTITLEMENTS.ACTIVITY_TIMES]}>
						<div className="col-4">
							<FoldableCard
								defaultOpen={true}
								title={t('recipe:TIMES')}
								content={
									<div className="card-body">
										<ActivityTimeEditor
											activityTimes={props.recipe.activityTimes}
											handleSetRecipeAttribute={
												props.handleSetRecipeAttribute
											}
										/>
									</div>
								}
							/>
						</div>
					</RenderIf>
					<div className="col-4">
						<FoldableCard
							defaultOpen={true}
							title={t('_general:TAGS')}
							content={
								<div className="card-body">
									<TagEditor
										allTags={props.tags}
										recipeText={getRecipeText()}
										recipeId={props.recipe.id}
										setTags={props.handleSetRecipeTags}
										tags={props.recipe.tags}
										disabled={props.readOnly}
									/>
								</div>
							}
						/>
					</div>
				</div>
				<br />
				<div className="row">
					<RenderIf entitlements={[ENTITLEMENTS.SCALING]}>
						<div className="col-4">
							<FoldableCard
								disabled={props.readOnly}
								defaultOpen={true}
								title={t('recipe:RECIPE_SCALING_PARTS')}
								info={'_general:SCALING_INFO_TOOLTIP'}
								content={
									<div className="card-body">
										<RecipeScalingEditor
											handleSetRecipeAttribute={
												props.handleSetRecipeAttribute
											}
											recipe={props.recipe}
										/>
									</div>
								}
							/>
						</div>
					</RenderIf>
				</div>{' '}
			</div>
		</>
	);
};
export default RecipeMetadataComponent;
