import * as apiSW from 'api/timeUnit/GetTimeUnitStartingWithV1';
import * as apiPostSearch from 'api/timeUnit/PostTimeUnitSearchV1';
import TimeUnit from 'classes/StandingData/TimeUnit/Search/TimeUnit';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class TimeUnitList {
	public all: TimeUnit[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const timeUnits: apiSW.ResponseTimeUnit[] = await this.callApiSW(
			searchTerm,
			showOnlyDeleted
		);
		this.mapFromApiSW(timeUnits);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseTimeUnit[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const timeUnit: TimeUnit = new TimeUnit(i);
			this.all.push(timeUnit);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseTimeUnit[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
