import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Unit from 'classes/StandingData/Unit/Unit';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import clone from 'functions/clone';

interface IProps {
	cultureCode: string;
	unit: Unit;
	setUnit: (input: Unit) => void;
}

const UnitSynonyms = (props: IProps) => {
	const { t } = useTranslation();

	const addNewSynonym = () => {
		props.unit.synonyms.push(props.cultureCode);
		props.setUnit(clone(props.unit));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.currentTarget.value;
		const index: number = Number(e.currentTarget.id);
		props.unit.synonyms.update(props.cultureCode, index, value);
		props.setUnit(clone(props.unit));
	};

	const handleDelete = (index: number) => {
		props.unit.synonyms.delete(props.cultureCode, index);
		props.setUnit(clone(props.unit));
	};

	const renderSynonym = (input: string, key: number): JSX.Element => {
		return (
			<div className="col-6" key={key}>
				<fieldset className="fieldset-white">
					<RenderIf condition={!props.unit.isDisabled()}>
						<div className="text-end">
							<FontAwesomeIcon
								icon={faTrashCan}
								className="trash-can"
								onClick={() => handleDelete(key)}
							/>
						</div>
					</RenderIf>
					<div className="form-group">
						<label>{t('_general:NAME')}</label>
						<input
							error-key={`Synonyms['${props.cultureCode}'][${key}]`}
							className="form-control"
							id={String(key)}
							type="string"
							name="plural"
							value={input}
							onChange={handleChange}
							disabled={props.unit.isDisabled()}
						/>
					</div>
				</fieldset>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<fieldset className="margin-top-25" style={{ minHeight: '4em' }}>
				<div className="d-flex">
					<legend>{t('_general:SYNONYMS')}</legend>
					<RenderIf condition={!props.unit.isDisabled()}>
						<button
							type="button"
							onClick={addNewSynonym}
							className="btn btn-outline-primary"
							style={{ marginBottom: '18px', whiteSpace: 'nowrap' }}
						>
							{t('_general:NEW_SYNONYM')}
						</button>
					</RenderIf>
				</div>
				<div className="row gy-4">
					{props.unit.synonyms
						.get(props.cultureCode)
						.map((synonym: string, i: number) => {
							return renderSynonym(synonym, i);
						})}
				</div>
			</fieldset>
		);
	};

	return <>{renderContent()}</>;
};

export default UnitSynonyms;
