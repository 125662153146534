import { useTranslation } from 'react-i18next';

import Activity from 'classes/StandingData/Activity/Activity';

interface IProps {
	activity: Activity;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ActivityLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<label>{t('_general:DESCRIPTION')}</label>
						<div>
							<input
								value={
									props.activity.nameTranslations[props.selectedLanguage] || ''
								}
								type="text"
								onChange={props.handleValueChange}
								className="form-control"
								name="nameTranslations"
								error-key={'NameTranslations'}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ActivityLanguageInput;
