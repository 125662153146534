import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGet from 'api/nutriScoreNutrientPoints/GetNutriScoreNutrientPointsGetAllPointsForNutrientAndMethodV1';
import { PointType } from 'api/nutriScoreNutrientPoints/GetNutriScoreNutrientPointsGetAllPointsForNutrientAndMethodV1';
import * as apiPost from 'api/nutriScoreNutrientPoints/PostNutriScoreNutrientPointsCreateNutriScoreNutrientPointsForNutrientAndMethodV1';
import * as apiPut from 'api/nutriScoreNutrientPoints/PutNutriScoreNutrientPointsUpdateAllPointsForNutrientAndMethodV1';
import * as apiGetNutrients from 'api/nutrient/GetNutrientV1';
import NutrientSelect from 'components/desktop/StandingData/NutriScoreNutrients/NutrientSelect';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NutriScorePointsComponent from 'components/desktop/_general/NutriScorePoints/NutriScorePointsComponent';
import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { SelectOption } from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { mapFromRequest } from 'functions/mappers/NutriScoreNutrient/mapFromRequest';
import { mapToRequest } from 'functions/mappers/NutriScoreNutrient/mapToRequest';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { NutriScoreNutrient } from 'types/NutriScoreNutrient/NutriScoreNutrient';
import { defaultNutriScoreNutrient } from 'types/NutriScoreNutrient/NutriScoreNutrient';
import { Nutrient } from 'types/Nutrient/Nutrient';

type ParsedUrl = {
	id: string;
	pointType: string;
};

const NutriScoreNutrientDetail = () => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [nutriScoreNutrient, setNutriScoreNutrient] = useState<NutriScoreNutrient>(
		defaultNutriScoreNutrient()
	);
	const [nutrients, setNutrients] = useState<Nutrient[]>([]);

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		await getActiveNutrients();
		const parsedUrl: ParsedUrl | null = parseUrl();
		if (parsedUrl === null) return;
		setIsLoading(true);
		const response = await apiGet.apiCall(parsedUrl.id, parsedUrl.pointType as PointType);
		response.do((x) => setNutriScoreNutrient(mapFromRequest(x)));
		setIsLoading(false);
	};

	const parseUrl = (): ParsedUrl | null => {
		let output: ParsedUrl | null = null;
		if (window.location.href.includes('?')) {
			if (window.history.state.id !== undefined) {
				const id: string = window.history.state.id;
				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;
				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			} else {
				const url = new URL(String(window.location)).searchParams;
				const id: string | null = url.get('id') === null ? null : url.get('pointType');
				if (!id) return output;

				const idArray: string[] = id.split(':');
				if (!idArray[0] || !idArray[1]) return output;

				output = {
					id: idArray[0],
					pointType: idArray[1],
				};
			}
		}
		return output;
	};

	const handleSaveNutriScoreNutrient = (): void => {
		const id: string | null = getIdFromUrl();
		if (id === null) return;

		if (id === 'new') {
			postNutriScoreNutrient();
		} else {
			putNutriScoreNutrient();
		}
	};

	const getActiveNutrients = async () => {
		const response = await apiGetNutrients.callApi();
		response.do((x) => setNutrients([...x]));
	};

	const renderNutrientSelect = (): JSX.Element => {
		const id: string | null = getIdFromUrl();
		if (id === null) return <></>;

		if (id === 'new') {
			return (
				<NutrientSelect
					nutrients={nutrients}
					nutriScoreNutrient={nutriScoreNutrient}
					setNutriScoreNutrient={setNutriScoreNutrient}
				/>
			);
		}
		return (
			<>
				<label className="input-label">{t('standingData:CHANGE_NUTRIENT')}</label>
				<div className="input-group mb-3">
					<input
						className="form-control"
						placeholder={nutriScoreNutrient.name ?? ''}
						disabled={true}
					/>
				</div>
			</>
		);
	};

	const putNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const putObject: apiPut.Request = mapToRequest(nutriScoreNutrient);
			await apiPut.callApi(putObject);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const postNutriScoreNutrient = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			const postObject: apiPost.Request = mapToRequest(nutriScoreNutrient);
			await apiPost.callApi(postObject);
			if (!nutriScoreNutrient.nutrientId) return;
			setIdToUrl(nutriScoreNutrient.nutrientId + ':' + nutriScoreNutrient.pointType);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const getSelectOptions = (): SelectOption[] => {
		return Object.keys(NutriScoreCategory).map((key) => {
			return {
				id: key,
				text: key,
			};
		});
	};

	const handlePointTypeChange = (pointType: string): void => {
		const nutriScoreNutrientCpy: NutriScoreNutrient = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.pointType = pointType as NutriScoreCategory;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	const handleToggleProtein = (input: boolean): void => {
		const nutriScoreNutrientCpy: NutriScoreNutrient = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.isProtein = input;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	const handleTogglePositive = (input: boolean): void => {
		const nutriScoreNutrientCpy: NutriScoreNutrient = { ...nutriScoreNutrient };
		nutriScoreNutrientCpy.isPositive = input;
		setNutriScoreNutrient(nutriScoreNutrientCpy);
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div style={{ minHeight: '400px' }}>
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							<h2>{nutriScoreNutrient.name ? nutriScoreNutrient.name : '–'}</h2>
							<div>
								<span className="badge bg-secondary">
									{nutriScoreNutrient.pointType}
								</span>
							</div>
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveNutriScoreNutrient()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex" style={{ gap: '20px' }}>
					<div style={{ width: '220px' }}>
						<label style={{ marginTop: 0 }}>{t('standingData:CHANGE_CATEGORY')}:</label>
						<SelectSimple
							options={getSelectOptions()}
							currentValue={nutriScoreNutrient.pointType}
							handleValueChange={handlePointTypeChange}
							disabled={getIdFromUrl() !== 'new'}
							errorKey="PointType"
						/>
						<hr />
						<Toggle
							checked={nutriScoreNutrient.isPositive ?? false}
							handleToggle={handleTogglePositive}
							elementId="isPositive"
							label="_general:POSITIVE"
							className="mb-2"
							errorKey="IsPositive"
						/>
						<Toggle
							checked={nutriScoreNutrient.isProtein ?? false}
							handleToggle={handleToggleProtein}
							elementId="isProtein"
							label="standingData:PROTEIN"
							errorKey="IsProtein"
						/>
					</div>
					<div style={{ width: '900px' }}>
						{renderNutrientSelect()}
						<NutriScorePointsComponent
							input={nutriScoreNutrient}
							setInput={setNutriScoreNutrient}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default NutriScoreNutrientDetail;
