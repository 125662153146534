import { t } from 'i18next';

import * as State from 'components/desktop/BaseIngredient/BaseIngredientState';
import { IconsBar } from 'components/desktop/Recipe/IconsBar/IconsBar';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DetailHeader from 'components/desktop/_general/DetailHeader/DetailHeader';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import ENTITLEMENTS from 'enums/entitlements';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import { Image } from 'types/Media/Image';
import * as AllergenList from 'types/_general/Store/AllergenList';
import * as SeasonList from 'types/_general/Store/SeasonList';
import * as TagList from 'types/_general/Store/TagList';

interface IProps {
	state: State.Type;
	allTags: TagList.Type;
	allAllergens: AllergenList.Type;
	allSeasons: SeasonList.Type;
	cultureCode: string;
	handleSave: () => void;
	handleToggleOverlay?: () => void;
	id: string | null;
	image: Image | null;
	isLoading: boolean;
	readOnly: boolean;
}

const BaseIngredientHeader = (props: IProps) => {
	const getTitle = (): string => {
		if (props.state.name[props.cultureCode]) return props.state.name[props.cultureCode];
		if (!props.id) return t('baseIngredient:NEW_BASEINGREDIENT');
		if (props.readOnly) return t('baseIngredient:VIEW_BASEINGREDIENT');
		return t('baseIngredient:EDIT_BASEINGREDIENT');
	};

	const renderBottomArea = (): JSX.Element => {
		return (
			<div
				className="d-flex flex-row justify-content-between align-items-end flex-grow-1"
				style={{ gap: '15px' }}
			>
				<IconsBar
					tags={TagList.getFromIdArray(props.allTags, props.state.tagIds)}
					allergens={AllergenList.getFromIdArray(
						props.allAllergens,
						props.state.allergenIds
					)}
				/>

				<div className="d-flex" style={{ gap: '15px' }}>
					<RenderIf
						condition={Boolean(props.state.hasNutriScore && props.state.nutriScore)}
						entitlements={[ENTITLEMENTS.NUTRI_SCORE]}
					>
						<div className="d-flex flex-gap-25">
							{props.state.nutriScore && (
								<NutriScoreDiagram nutriScore={props.state.nutriScore} />
							)}
						</div>
					</RenderIf>

					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<Co2Display
							value={props.state.carbonDioxideValue}
							color={props.state.carbonDioxideLabel?.color || '#000'}
							fluid={props.state.characteristic}
							valueScopeInfo={null}
						/>
					</RenderIf>
					<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
						<SeasonalityBar
							seasonalityCode={getSeasonalityCode(
								SeasonList.getFromIdArray(props.allSeasons, props.state.seasonIds)
							)}
						/>
					</RenderIf>
				</div>
			</div>
		);
	};

	return (
		<DetailHeader
			handleSave={props.handleSave}
			title={getTitle()}
			image={props.image}
			isLoading={props.isLoading}
			readOnly={props.readOnly}
			bottomArea={renderBottomArea()}
			handleToggleOverlay={props.handleToggleOverlay}
		/>
	);
};

export default BaseIngredientHeader;
