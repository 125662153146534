import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

interface IProps {
	handleDelete: (id: string) => void;
	handleView: () => void;
	id: string;
}

const SegmentImageCarouselButtons = (props: IProps) => {
	const handleDelete = (): void => props.handleDelete(props.id);

	const renderButton = (
		title: string,
		onClickFunction: ((e?: any) => void) | undefined,
		icon: JSX.Element,
		className: string = ''
	): JSX.Element => {
		return (
			<button
				type="button"
				className={`btn btn-outline-secondary ${className}`}
				data-bs-toggle="tooltip"
				data-bs-placement="top"
				title={title}
				onClick={onClickFunction}
			>
				{icon}
			</button>
		);
	};

	return (
		<div className="btn-group" role="group">
			{renderButton(t('_general:DELETE'), handleDelete, <FontAwesomeIcon icon={faTrash} />)}
			{renderButton(t('_general:VIEW'), props.handleView, <FontAwesomeIcon icon={faEye} />)}
		</div>
	);
};

export default SegmentImageCarouselButtons;
