import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import Language from 'classes/StandingData/Language/Search/Language';
import LanguageList from 'classes/StandingData/Language/Search/LanguageList';
import Search from 'classes/StandingData/Language/Search/Search';
import LanguageDetailComponent from 'components/desktop/StandingData/Language/Detail/LanguageDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const LanguageManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [languageList, setLanguageList] = useState<LanguageList>(new LanguageList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getLanguages();
		} else {
			await getLanguages();
		}
		setSearch(clone(search));
	};

	const handleRestoreLanguage = async (language: Language): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:LANGUAGE_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await language.callApiRestore();
				await Swal.fire({
					title: t('standingData:LANGUAGE_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getLanguages();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveLanguage = async (language: Language): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:LANGUAGE_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await language.callApiDelete();
				await Swal.fire({
					title: t('standingData:LANGUAGE_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getLanguages();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const getLanguages = async () => {
		setIsLoading(true);
		const response = await languageList.callApi(search.mapToRequest());
		response.do((x) => {
			languageList.mapFromApi(x);
			setLanguageList(clone(languageList));
		});
		setIsLoading(false);
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getLanguages();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getSuggestedLanguages = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await languageList.search(searchTerm, props.archive ?? false);
		} else {
			languageList.filtered = [];
		}
		setLanguageList(clone(languageList));
		return languageList.filtered;
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataDelete = (input: Language): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveLanguage(input),
			};
		}
	};

	const getButtonDataRestore = (input: Language): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreLanguage(input),
			};
		}
	};

	const renderButtons = (input: Language): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return languageList.all.map((language: Language) => {
			return {
				id: language.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div style={{ width: '150px' }}>
							<span className="grey">{t('standingData:SORT_ORDER')}: </span>
							{language.sortOrder}
						</div>
						<div className="flex" style={{ width: '350px' }}>
							<span className="grey">{t('standingData:NAME')}: </span>
							{language.name}
						</div>
						<div className="flex-fill">
							<span className="grey">{t('_general:CULTURE_CODE')}: </span>
							{language.cultureCode}
						</div>
						<div>{renderButtons(language)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = (): JSX.Element => {
		return (
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:LANGUAGE_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_LANGUAGE')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:LANGUAGE_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedLanguages}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{languageList.totalCount + ' ' + t('_general:LANGUAGES')}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={renderListRows()}
					overlay={<LanguageDetailComponent />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={languageList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default LanguageManagementComponent;
