import * as apiDelete from 'api/recipe/DeleteRecipeIdV1';
import * as apiRestore from 'api/recipe/PostRecipeRestoreV1';
import * as apiPost from 'api/recipe/PostRecipeSearchV1';
import AllergenList from 'classes/Recipe/Search/AllergenList';
import CarbonDioxideLabel from 'classes/Recipe/Search/CarbonDioxideLabel';
import Image from 'classes/Recipe/Search/Image';
import NutriScore from 'classes/Recipe/Search/NutriScore';
import PrepareTime from 'classes/Recipe/Search/PrepareTime';
import SeasonList from 'classes/Recipe/Search/SeasonList';
import Status from 'classes/Recipe/Search/Status';
import TagList from 'classes/Recipe/Search/TagList';

export default class Recipe {
	public id: string = '';
	public lastEditor: string = '';
	public title: string = '';
	public lastEditedDateUtc: string = '';
	public status: Status = new Status();
	public image: Image | null = null;
	public calories: number = 0;
	public carbonDioxideValue: number = 0;
	public carbonDioxideLabel: CarbonDioxideLabel | null = null;
	public price: number = 0;
	public prepareTime: PrepareTime = new PrepareTime();
	public allergenList: AllergenList = new AllergenList();
	public seasonList: SeasonList = new SeasonList();
	public tagList: TagList = new TagList();
	public nutriScore: NutriScore = new NutriScore();
	public hasNutriScore: boolean = false;

	public constructor(input?: apiPost.ResponseRecipe) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPost.ResponseRecipe) {
		this.id = input.id;
		this.lastEditor = input.lastEditor ?? '';
		this.title = input.titleTranslation ?? '';
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.status.mapFromApi(input.status);
		if (input.image) {
			this.image = new Image();
			this.image.mapFromApi(input.image);
		}
		this.calories = input.calories ?? 0;
		this.price = input.price ?? 0;
		this.prepareTime.mapFromApi(input.prepareTime);
		this.carbonDioxideValue = input.carbonDioxideValue ?? 0;
		this.seasonList.mapFromApi(input.seasons);
		this.allergenList.mapFromApi(input.allergens);
		this.tagList.mapFromApi(input.tags);
		this.nutriScore.mapFromApi(input.nutriScore);
		this.hasNutriScore = input.hasNutriScore;

		if (input.carbonDioxideLabel) {
			this.carbonDioxideLabel = new CarbonDioxideLabel();
			this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		} else {
			this.carbonDioxideLabel = null;
		}
	}
}
