import * as api from 'api/recipe/PostRecipeCalculateV1';
import Allergen from 'classes/Recipe/Detail/Calculation/Allergen';

export default class AllergenList {
	public items: Allergen[] = [];

	public constructor(input?: api.Allergens[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.Allergens[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new Allergen(i));
		}
	}
}
