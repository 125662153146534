import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	search: () => void;
}

const UserSearchInput: React.FC<IProps> = (props: IProps) => {
	const { t } = useTranslation();

	const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			props.search();
		}
	};

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const searchTerm = event.target.value;
		props.setSearchTerm(searchTerm);
	};

	return (
		<>
			<div className="input-group mb-3">
				<input
					type="text"
					onChange={onChange}
					onKeyPress={onKeyPress}
					placeholder={t('_general:INPUT_PLACEHOLDER_SEARCHTERM')}
					className="form-control base-ingredient-search-input"
					aria-label="Text input with checkbox"
				/>
				<button
					className="btn btn-outline-primary base-ingredient-search-input-button"
					type="button"
					onClick={props.search}
				>
					{t('_general:BTN_SEARCH')}
				</button>
			</div>
		</>
	);
};

export default UserSearchInput;
