import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { getBreadCrumbs } from 'components/desktop/_general/MainNavigationLeft/MainNavigationLeftHandlers';
import SIDEBAR_ITEMS from 'components/desktop/_general/MainNavigationLeft/constants/SidebarItems';
import { usePathname } from 'components/desktop/_general/MainNavigationLeft/hooks/usePathname';
import NavigationLanguageSwitch from 'components/desktop/_general/NavigationLanguage/NavigationLanguageSwitch';
import UserAvatar from 'components/desktop/_general/UserAvatar/UserAvatar';
import { getPicturePath } from 'functions/getPicturePath';
import { RootState } from 'reducers/rootReducer';
import { UserCornerInfo } from 'types/User/UserCornerInfo/UserCornerInfo';

const MainNavigationTop = (): JSX.Element => {
	const reduxUser: UserCornerInfo = useSelector((state: RootState) => state.user);
	const reduxAllowedUiLanguages: string[] = useSelector(
		(state: RootState) => state.allowedUiLanguages
	);
	const pathname: string = usePathname();
	const { t } = useTranslation();

	return (
		<div style={mainStyle}>
			<nav aria-label="breadcrumb" style={{ opacity: 0.5 }}>
				<ol className="breadcrumb" style={{ marginBottom: '0px' }}>
					<li className="breadcrumb-item">AIR</li>
					{getBreadCrumbs(SIDEBAR_ITEMS, pathname).map((e: string, i: number) => {
						return (
							<li className="breadcrumb-item" key={i}>
								{t(e)}
							</li>
						);
					})}
				</ol>
			</nav>
			<div className="d-flex" style={{ gap: '25px' }}>
				<RenderIf condition={reduxAllowedUiLanguages.length > 1}>
					<NavigationLanguageSwitch />
				</RenderIf>
				<UserAvatar
					userName={`${reduxUser.firstName} ${reduxUser.lastName}`}
					path={getPicturePath(reduxUser.profilePicture, 26)}
				/>
			</div>
		</div>
	);
};

export default MainNavigationTop;

const mainStyle: React.CSSProperties = {
	backgroundColor: 'white',
	zIndex: 1000,
	borderBottom: '1px solid #ddd',
	minHeight: '48px',
	paddingLeft: '15px',
	paddingRight: '15px',
	position: 'fixed',
	left: '0px',
	right: '0px',
	top: '0px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
};
