import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/errorMessage/GetErrorMessageIdV1';
import ErrorMessage from 'classes/StandingData/ErrorMessage/ErrorMessage';
import ErrorMessageLanguageInput from 'components/desktop/StandingData/ErrorMessage/LanguageInput/ErrorMessageLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const ErrorMessageDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [errorMessage, setErrorMessage] = useState<ErrorMessage>(new ErrorMessage());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getErrorMessage(id);
		}
	};

	const getErrorMessage = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() && setErrorMessage(new ErrorMessage(response.get()));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		switch (name) {
			case 'errorKey':
				errorMessage.errorKey = e.currentTarget.value;
				break;
			case 'errorTranslations':
				errorMessage.errorTranslations[selectedCultureCode] = e.currentTarget.value;
		}
		setErrorMessage(clone(errorMessage));
	};

	const handleTranslate = async () => {
		errorMessage.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveErrorMessage = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await errorMessage.callApiPut();
			} else {
				await errorMessage.callApiPost();
				if (!errorMessage.id) return;
				setId(errorMessage.id);
				setIdToUrl(errorMessage.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{errorMessage.id ? (
								<>
									<h1>{t('standingData:EDIT_ERRORMESSAGE')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_ERRORMESSAGE')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveErrorMessage()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<input
							value={errorMessage.errorKey}
							type="text"
							onChange={handleInputValueChange}
							className="form-control"
							name="errorKey"
							error-key={'ErrorKey'}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<ErrorMessageLanguageInput
									errorMessage={errorMessage}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ErrorMessageDetailComponent;
