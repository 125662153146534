import { useEffect, useState } from 'react';

type StateObject<T> = {
	state: T;
	callback: ((t: T) => void) | null;
};

export function useStateAsync<T>(initialState: T): [T, (t: T) => Promise<T>] {
	const [state, setState] = useState<StateObject<T>>({ callback: null, state: initialState });

	useEffect(() => {
		if (state.callback) state.callback(state.state);
	}, [state.callback]);

	const fn = async (t: T) => {
		const p = new Promise<T>((resolve, _) => {
			setState({ state: t, callback: resolve });
		});

		return p;
	};

	return [state.state, fn];
}
