/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { DependencyList, useEffect, useState } from 'react';

/* eslint-disable no-redeclare */

// ESLINT does not understand overload in this context.

export function useReaction<T>(initialValue: T, f: () => Promise<T>): [T, boolean];
export function useReaction<T, T1>(
	initialValue: T,
	f: (t1: T1) => Promise<T>,
	t1: T1
): [T, boolean];

export function useReaction<T, T1, T2>(
	initialValue: T,
	f: (t1: T1, t2: T2) => Promise<T>,
	t1: T1,
	t2: T2
): [T, boolean];

export function useReaction(
	initialValue: any,
	f: (...s: any) => Promise<any>,
	...s: any
): [any, boolean] {
	const [data, setData] = useState(initialValue);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const updateFunction = async () => {
			setIsLoaded(false);
			const t = await f(...s);
			setData(t);
			setIsLoaded(true);
		};

		updateFunction();
	}, s as DependencyList);

	return [data, isLoaded];
}

/* eslint-enable no-redeclare */
