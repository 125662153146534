import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import 'components/desktop/_general/Input/TreeInput/TreeInput.scss';
import { subTreeContainsSearchTerm } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { getTreeForRendering } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { handleSelectionClick } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { isSelected } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { handleOpenCloseClick } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { isUnClosebale } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { isOpened } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';

export type Tree<T extends Tree<T>> = {
	id: string;
	name: string;
	parent?: string;
	children: T[];
};

interface IProps<T extends Tree<T>> {
	input: T[];
	searchTerm: string;
	setSelected: (input: T[]) => void;
	selected: T[];
	noObjectsFoundText?: string;
}

const TreeComponent = <T extends Tree<T>>(props: IProps<T>) => {
	const [opened, setOpened] = useState<string[]>([]);

	const treeMarginLeft: number = 20;
	const selectionColor: string = '#6699cc';
	const highlightColor: string = '#dc3545';

	const renderOpenClosedIcon = (tree: T, searchTerm: string): JSX.Element => {
		if (!tree.children.length)
			return (
				<span style={{ width: '15px', display: 'inline-block', color: '#ddd' }}>
					<FontAwesomeIcon icon={faCircleSmall as IconProp} size="xs" />
				</span>
			);
		return (
			<span
				onClick={() => handleOpenCloseClick(tree, searchTerm, opened, setOpened)}
				style={{
					width: '15px',
					display: 'inline-block',
					color: isUnClosebale(tree, searchTerm) ? highlightColor : undefined,
					cursor: isUnClosebale(tree, searchTerm) ? 'not-allowed' : undefined,
				}}
			>
				{isOpened(tree, searchTerm, opened) &&
				subTreeContainsSearchTerm(tree, searchTerm) ? (
					<FontAwesomeIcon icon={faCaretDown as IconProp} />
				) : (
					<FontAwesomeIcon icon={faCaretRight as IconProp} />
				)}
			</span>
		);
	};

	const renderSelectionIcon = (id: string): JSX.Element => {
		return (
			<span
				style={{
					marginRight: '5px',
					color: isSelected(id, props.selected) ? selectionColor : 'grey',
				}}
			>
				{isSelected(id, props.selected) ? (
					<FontAwesomeIcon icon={faSquareCheck as IconProp} />
				) : (
					<FontAwesomeIcon icon={faSquare as IconProp} />
				)}
			</span>
		);
	};

	const renderName = (tree: T, searchTerm: string): JSX.Element => {
		const { name, id } = tree;
		const index: number = name.toLowerCase().indexOf(searchTerm.toLowerCase());
		if (index !== -1) {
			const textBefore: string = name.slice(0, index);
			const textHighlight: string = name.slice(index, index + searchTerm.length);
			const textAfter: string = name.slice(index + searchTerm.length);
			return (
				<span
					style={{ color: isSelected(id, props.selected) ? selectionColor : undefined }}
				>
					{textBefore}
					<span style={{ color: highlightColor }}>{textHighlight}</span>
					{textAfter}
				</span>
			);
		}
		return (
			<span style={{ color: isSelected(id, props.selected) ? selectionColor : undefined }}>
				{name}
			</span>
		);
	};

	const renderTreeItem = (treeArray: T[], level: number, searchTerm: string): JSX.Element => {
		return (
			<>
				{treeArray.map((tree: T, i: number) => {
					if (isOpened(tree, searchTerm, opened) || searchTerm === '') {
						return (
							<div
								key={`${i}-${level}`}
								style={{ marginLeft: level ? `${treeMarginLeft}px` : undefined }}
							>
								{renderOpenClosedIcon(tree, searchTerm)}
								<span
									onClick={() =>
										handleSelectionClick(
											tree,
											props.selected,
											props.setSelected
										)
									}
									style={{ cursor: 'pointer' }}
								>
									{renderSelectionIcon(tree.id)}
									{renderName(tree, searchTerm)}
								</span>
								{isOpened(tree, searchTerm, opened) ? (
									renderTreeItem(tree.children, level + 1, searchTerm)
								) : (
									<></>
								)}
							</div>
						);
					}
				})}
			</>
		);
	};

	if (getTreeForRendering(props.input, props.searchTerm).length) {
		return renderTreeItem(
			getTreeForRendering(props.input, props.searchTerm),
			0,
			props.searchTerm
		);
	} else {
		return <div style={{ color: 'grey' }}>{props.noObjectsFoundText ?? '–'}</div>;
	}
};

export default TreeComponent;
