export enum MenuSortOrder {
	NameAsc = 'NameAsc',
	NameDesc = 'NameDesc',
	LastEditedDateAsc = 'LastEditedDateAsc',
	LastEditedDateDesc = 'LastEditedDateDesc',
	PriceAsc = 'PriceAsc',
	PriceDesc = 'PriceDesc',
	CaloriesAsc = 'CaloriesAsc',
	CaloriesDesc = 'CaloriesDesc',
	CarbonDioxideAsc = 'CarbonDioxideAsc',
	CarbonDioxideDesc = 'CarbonDioxideDesc',
}
