import Archive from 'containers/desktop/StandingData/RecipeCategory/Archive/RecipeCategoryArchive';
import Management from 'containers/desktop/StandingData/RecipeCategory/Management/RecipeCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const recipeCategoryRoutes = [
	{
		path: '/recipeCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/recipeCategory/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
