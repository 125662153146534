import TagCategoryManagementComponent from 'components/desktop/StandingData/TagCategory/Management/TagCategoryManagementComponent';

const TagCategoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<TagCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default TagCategoryArchiveContainer;
