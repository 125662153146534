import RangeDouble from 'components/desktop/_general/RangeDouble/RangeDouble';

interface IProps {
	min: number;
	max: number;
	left?: number;
	right?: number;
	minArea: number;
	maxArea: number;
	handleChange: (left: number, right: number, id?: string) => void;
	label: string;
	id?: string;
	className?: string;
}

const RangeDoubleLabel = (props: IProps) => {
	return (
		<div className={props.className ? 'row ' + props.className : 'row'}>
			<div className="col-3 font-sm">{props.label}</div>
			<div className="col-9">
				<RangeDouble
					id={props.id ?? undefined}
					min={props.min}
					max={props.max}
					left={props.left ? Math.floor(props.left) : undefined}
					right={props.right ? Math.ceil(props.right) : undefined}
					handleChange={props.handleChange}
					minArea={Math.floor(props.minArea) ?? 0}
					maxArea={Math.ceil(props.maxArea) ?? 0}
				/>
			</div>
		</div>
	);
};

export default RangeDoubleLabel;
