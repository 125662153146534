import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as apiGetAccessoryStartingWith from 'api/accessory/GetAccessoryStartingWithV1';
import * as apiGetAllergenStartingWith from 'api/allergen/GetAllergenStartingWithV1';
import * as apiGetCo2LabelsStartingWith from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import * as apiGetIngredientStartingWith from 'api/ingredient/GetIngredientStartingWithV1';
import * as apiGetNutriScoreLabelsStartingWith from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import * as apiGetSeasonStartingWith from 'api/season/GetSeasonStartingWithV1';
import * as apiGetAllStatus from 'api/status/GetAllStatusV1';
import * as apiGetTagStartingWith from 'api/tag/GetTagStartingWithV1';
import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import StatusList from 'classes/MenuPlan/Detail/StatusList';
import Header from 'components/desktop/MenuPlan/Detail/Header';
import RowListComponent from 'components/desktop/MenuPlan/Detail/RowListComponent';
import Title from 'components/desktop/MenuPlan/Detail/Title';
import DownloadOverlay from 'components/desktop/MenuPlan/DownloadOverlay/DownloadOverlay';
import MenuPlanNutrientInformations from 'components/desktop/MenuPlan/MenuPlanNutrientInformations/MenuPlanNutrientInformations';
import MenuPlanSidebar from 'components/desktop/MenuPlan/Sidebar/MenuPlanSidebar';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import clone from 'functions/clone';
import scrollToTop from 'functions/scrollToTop';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { useApi } from 'hooks/useApi';
import { RootState } from 'reducers/rootReducer';
import { AccessoryLight } from 'types/Accessory/AccessoryLight';
import { IngredientLight } from 'types/Ingredient/IngredientLight';
import { Language } from 'types/Language/Language';
import { defaultLanguage } from 'types/Language/Language';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';

interface IProps {
	copy?: boolean;
	id?: string;
}

const MenuPlanComponent = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const navigate = useNavigate();
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	const [menuPlan, setMenuPlan] = useState<MenuPlan>(new MenuPlan());
	const [statusList, setStatusList] = useState<StatusList>(new StatusList());
	const [selectedItem, setSelectedItem] = useState<Recipe | Menu | Row | Item | Column | null>(
		null
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLanguage, setSelectedLanguage] = useState<Language>(defaultLanguage(true));
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [search, setSearch] = useState<Search.Type>(Search.create());
	const [ingredients, setIngredients] = useState<IngredientLight[]>([]);
	const [accessories, setAccessories] = useState<AccessoryLight[]>([]);
	const [nutriScoreLabels, setNutriScoreLabels] = useState<NutriScoreLabel[]>([]);

	useEffect(() => {
		if (reduxCultureCode) {
			initialize();
			const language: Language | undefined = reduxLanguages.find((e: Language) => {
				return e.cultureCode === reduxCultureCode;
			});
			if (language) setSelectedLanguage(language);
		}
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		scrollToTop();
		setIsLoading(true);
		try {
			if (props.id) {
				await menuPlan.initialize(props.id);
				if (props.copy) menuPlan.createCopy();
				setMenuPlan(clone(menuPlan));
			} else {
				await menuPlan.initialize();
				setMenuPlan(clone(menuPlan));
			}
			await statusList.callApiGet();
			setStatusList(clone(statusList));
			await initializeSearch();
			await getIngredients();
			await getAccessories();
			await getNutriScoreLabels();
		} finally {
			setIsLoading(false);
		}
	};

	const initializeSearch = async () => {
		const newSearch: Search.Type = await Search.initialize(
			search,
			statusList.mapToToggleItemList(),
			menuPlan.caloriesLimit,
			menuPlan.priceLimit
		);
		setSearch(newSearch);
	};

	const [status] = useApi(
		[],
		() => apiGetAllStatus.callApi().mapAsync((x) => x.getOrDefault([])),
		reduxCultureCode
	);

	const [co2Labels] = useApi(
		[],
		() =>
			apiGetCo2LabelsStartingWith
				.callApi('', ValueScope.Person, false)
				.mapAsync((x) => x.getOrDefault([])),
		reduxCultureCode
	);

	const [tags] = useApi(
		[],
		() => apiGetTagStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([])),
		reduxCultureCode
	);

	const [allergens] = useApi(
		[],
		() => apiGetAllergenStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([])),
		reduxCultureCode
	);
	const [seasons] = useApi(
		[],
		() => apiGetSeasonStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([])),
		reduxCultureCode
	);

	const getNutriScoreLabels = async () => {
		setNutriScoreLabels([]);
		if (!menuPlanEditor) return;
		const nutriScoreLabels = await apiGetNutriScoreLabelsStartingWith.callApi('', false);
		nutriScoreLabels.do((e) => {
			setNutriScoreLabels(e);
		});
	};

	const getIngredients = async () => {
		setIngredients([]);
		if (!menuPlanEditor) return;
		const ingredients = await apiGetIngredientStartingWith.callApi('', false);
		if (ingredients.hasValue()) {
			setIngredients(ingredients.get());
		}
	};

	const getAccessories = async () => {
		setAccessories([]);
		if (!menuPlanEditor) return;
		const accessories = await apiGetAccessoryStartingWith.callApi('', false);
		if (accessories.hasValue()) {
			setAccessories(accessories.get());
		}
	};

	const getSelectedItemFromApi = async (item: Item): Promise<void> => {
		try {
			if (item.type === MENUPLANNERITEMTYPE.MENU) {
				const menu: Menu = new Menu();
				await menu.callApiGet(item.id);
				setSelectedItem(clone(menu));
			}
			if (item.type === MENUPLANNERITEMTYPE.RECIPE) {
				const recipe: Recipe = new Recipe();
				await recipe.callApiGet(item.id);
				setSelectedItem(clone(recipe));
			}
		} catch (e: unknown) {
			console.error(e);
		}
	};

	const handleSave = async (): Promise<void> => {
		setIsLoading(true);
		if (props.id) {
			const response = await menuPlan.callApiPut();
			response.do((x) => {
				menuPlan.mapFromApiGet(x);
				setMenuPlan(clone(menuPlan));
			});
		} else {
			const response = await menuPlan.callApiPost();
			response.do((id) => navigate(`/menuPlan/detail/${id}`));
		}
		await initializeSearch();
		setIsLoading(false);
	};

	const handleDownload = (cultureCode: string): void => {
		const url: string = '/menuPlan/print/' + menuPlan.id + '?cultureCode=' + cultureCode;
		setDisplayOverlay(false);
		window.open(url, '_blank');
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<DownloadOverlay
				handleDownload={handleDownload}
				displayOverlay={displayOverlay}
				setDisplayOverlay={setDisplayOverlay}
			/>
			<div className="row">
				<Title
					disabled={!menuPlanEditor}
					handleSave={handleSave}
					id={props.id}
					isLoading={isLoading}
					setDisplayOverlay={setDisplayOverlay}
				/>
			</div>
			<br />
			<div className="row">
				<Header
					menuPlan={menuPlan}
					setMenuPlan={setMenuPlan}
					selectedLanguage={selectedLanguage}
					setSelectedLanguage={setSelectedLanguage}
				/>
			</div>
			<hr />
			<div className="row">
				<RowListComponent
					accessories={accessories}
					allergens={allergens}
					co2Labels={co2Labels}
					currency={reduxCurrency}
					getSelectedItemFromApi={getSelectedItemFromApi}
					id={props.id}
					ingredients={ingredients}
					menuPlan={menuPlan}
					nutriScoreLabels={nutriScoreLabels}
					seasons={seasons}
					selectedItem={selectedItem}
					setMenuPlan={setMenuPlan}
					setSelectedItem={setSelectedItem}
					status={status}
					tags={tags}
					search={search}
					setSearch={setSearch}
				/>
			</div>
			<div className="row mt-4">
				<div className="col-3">
					<MenuPlanSidebar
						object={selectedItem ?? menuPlan}
						setObject={setSelectedItem}
						menuPlan={menuPlan}
						setMenuPlan={setMenuPlan}
						statusList={statusList}
					/>
				</div>
				<div className="col-9">
					<MenuPlanNutrientInformations selectedItem={selectedItem} menuPlan={menuPlan} />
				</div>
			</div>
		</>
	);
};

export default MenuPlanComponent;
