import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { Optional } from 'functions/promiseExtensions';

export function callApi(
	recipeId: string | null,
	nrOfRecommendations: number,
	recipeText: string | null
): Promise<Optional<Recipe[]>> {
	const url = '/backend-service/AiFunctions/Suggest/SimilarRecipes';
	const params: Parameter = {
		RecipeId: recipeId,
		NrOfRecommendations: nrOfRecommendations,
		RecipeText: recipeText,
	};
	return axios.post(url, params).toOptionalMapped((x) => x.data);
}

interface Parameter {
	RecipeId: string | null;
	NrOfRecommendations: number | null;
	RecipeText: string | null;
}

export interface Response {
	data: Recipe[];
}

export type Recipe = {
	allergens: Allergen[];
	calories: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	carbonDioxideValue: number | null;
	hasNutriScore: boolean;
	id: string;
	image: Image;
	lastEditedDateUtc: string;
	lastEditor: string | null;
	nutriScore: NutriScore;
	prepareTime: PrepareTime;
	price: number | null;
	seasons: Season[];
	status: Status;
	tags: Tag[];
	titleTranslation: string;
	weight: number | null;
};

export type Status = {
	color: string;
	id: string;
	name: string;
	order: number;
};

export type Image = {
	availableSizes: number[];
	url: string;
};

export type PrepareTime = {
	timeUnit: string;
	timeValue: number;
};

export type NutriScore = {
	color: string;
	label: string;
};

export type CarbonDioxideLabel = {
	color: string;
	label: string;
};

export type Season = {
	associatedMonthsFlag: number | null;
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
};

export type Allergen = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	logic: AllergenLogic;
	name: string;
};

export type Tag = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};
