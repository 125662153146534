import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import Ingredient from 'classes/Ingredient/Search/Ingredient';
import IngredientList from 'classes/Ingredient/Search/IngredientList';
import Search from 'classes/Ingredient/Search/Search';
import AdvancedSearch from 'components/desktop/Ingredient/AdvancedSearch/AdvancedSearch';
import SearchResultList from 'components/desktop/Ingredient/Search/SearchResultList';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DropdownPageSize from 'components/desktop/_general/DropdownPageSize/DropdownPageSize';
import DropdownSortOrder from 'components/desktop/_general/DropdownSortOrder/DropdownSortOrder';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import { IngredientSortOrder } from 'enums/SortOrder/IngredientSortOrder';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 10;

const IngredintSearchComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState(false);
	const [ingredientList, setIngredientList] = useState<IngredientList>(new IngredientList());
	const [search, setSearch] = useState<Search>(new Search());
	const [renderRanges, setRenderRanges] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		await search.initialize();
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			openAdvancedSearch();
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			await getIngredients();
		} else {
			await getIngredients();
		}
		setSearch(clone(search));
	};

	const handleRestoreIngredient = async (ingredient: Ingredient): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('ingredient:RESTORE_TITLE'),
			text: t('ingredient:RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await ingredient.callApiRestore();
				await Swal.fire({
					title: t('ingredient:RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getIngredients();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveIngredient = async (ingredient: Ingredient): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('ingredient:ARCHIVE_TITLE'),
			text: t('ingredient:ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await ingredient.callApiDelete();
				await Swal.fire({
					title: t('ingredient:ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getIngredients();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getIngredients();
	};

	const handleChangeAdvancedSearchUrl = () => {
		search.pageIndex.value = 0;
		handleChangeUrl();
	};

	const handleReset = () => {
		search.reset();
		setSearchTerm('');
		handleChangeUrl();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangeSortOrder = (value: string) => {
		search.sortOrder.value = value as IngredientSortOrder;
		handleChangeUrl();
	};

	const handleChangePageSize = (value: string): void => {
		search.pageSize.value = Number(value);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const getSuggestedIngredients = async (): Promise<ISelectItem[]> => {
		await ingredientList.search(searchTerm, props.archive ?? false);
		return ingredientList.filtered;
	};

	const openAdvancedSearch = () => {
		const advancedSearchButton = document.getElementById('advancedSearchButton');
		if (advancedSearchButton) advancedSearchButton.click();
	};

	const getIngredients = async (): Promise<void> => {
		setIsLoading(true);
		const response = await ingredientList.callApi(search.mapToRequest());
		response.do((x) => {
			ingredientList.mapFromApi(x);
			setIngredientList(clone(ingredientList));
			search.mapFromApi(x);
		});

		setIsLoading(false);
	};

	const advancedSearchContainer = document.getElementById('advancedSearch');
	if (advancedSearchContainer) {
		advancedSearchContainer.addEventListener('show.bs.collapse', function () {
			setRenderRanges(true);
		});
		advancedSearchContainer.addEventListener('hidden.bs.collapse', function () {
			setRenderRanges(false);
		});
	}

	return (
		<main className="container">
			<div className="d-flex justify-content-between">
				<RenderIf condition={!props.archive}>
					<h1>{t('ingredient:SEARCH_INGREDIENT')}</h1>
				</RenderIf>
				<RenderIf condition={Boolean(props.archive)}>
					<h1>{t('ingredient:INGREDIENT_ARCHIVE')}</h1>
				</RenderIf>
				<RenderIf condition={ingredientEditor && !props.archive}>
					<BtnNew link={'/ingredient/new'} name={t('ingredient:CREATE_INGREDIENT')} />
				</RenderIf>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-7">
					<div className="input-group mb-3">
						<AdvancedSearchInput
							debounceMilliSeconds={350}
							getSuggestedItems={getSuggestedIngredients}
							handleSearch={handleSearch}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							triggerSearchLetterAmount={1}
						/>
					</div>
				</div>
				<div className="col-lg-2">
					<button
						id="advancedSearchButton"
						className="btn btn-primary"
						type="button"
						onClick={() => setRenderRanges(true)}
						data-bs-toggle="collapse"
						data-bs-target="#advancedSearch"
						aria-expanded="true"
						aria-controls="advancedSearch"
					>
						{t('_general:ADVANCED_FILTER')}
					</button>
				</div>
				<div className="col-lg-3 text-end">
					<div className="d-flex flex-row justify-content-end">
						<DropdownPageSize
							className="me-2"
							listFunction={handleChangePageSize}
							currentValue={String(search.getPageSize())}
						/>
						<DropdownSortOrder
							type={'ingredient'}
							listFunction={handleChangeSortOrder}
							currentValue={search.getSortOrder()}
						/>
					</div>
				</div>
			</div>
			<div className={'collapse'} id="advancedSearch">
				<div className="card card-body mb-3">
					<AdvancedSearch
						disabled={ingredientList.totalCount === 0}
						advancedSearch={search}
						handleChangeUrl={handleChangeAdvancedSearchUrl}
						handleReset={handleReset}
						renderRanges={renderRanges}
						setAdvancedSearch={setSearch}
					/>
				</div>
			</div>
			<LoadingAnimation isLoading={isLoading} />
			<p>{ingredientList.totalCount + ' ' + t('_general:INGREDIENTS')}</p>
			<div className="mb-3">
				<SearchResultList
					archive={props.archive ?? false}
					ingredientList={ingredientList}
					handleDeleteIngredient={handleArchiveIngredient}
					handleRestoreIngredient={handleRestoreIngredient}
				/>
			</div>
			<Pagination
				countOfAllListItems={ingredientList.totalCount}
				pageIndex={search.getPageIndex()}
				setPageIndex={handleChangePageIndex}
				listItemAmount={search.getPageSize()}
				maxNumberOfButtons={maxNumberOfButtons}
			/>
		</main>
	);
};

export default IngredintSearchComponent;
