enum ENTITLEMENTS {
	ACCESSORY_PRODUCT = 'ai.suggester.accessoryProduct',
	ACTIVITY_TIMES = 'entity.activityTimes',
	AI_IMAGE_SEARCH = 'ai.suggester.imageSimilarity',
	CARBONDIOXIDE = 'calculation.carbondioxide',
	LANGUAGES = 'entity.languages',
	MENU = 'entity.menu',
	MENU_PLAN = 'entity.menuPlan',
	NUTRI_SCORE = 'calculation.nutriScore',
	PRICE = 'calculation.price',
	SCALING = 'ai.scaling',
	SEASON = 'entity.season',
	SIMILAR_RECIPES = 'ai.suggester.similarRecipes',
	SIMILAR_TAGS = 'ai.suggester.similarTags',
	TRANSLATION_SERVICE = 'ai.translation',
	UNIT_WEIGHT = 'ai.suggester.unitWeight',
	URL_ANALYSIS = 'ai.urlAnalysis',
}

export default ENTITLEMENTS;
