import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/desktop/BaseIngredient/IngredientEditor/IngredientEditor.scss';
import * as Ingredient from 'types/BaseIngredient/Detail/Ingredient';
import * as IngredientList from 'types/BaseIngredient/Detail/IngredientList';

interface IProps {
	ingredients: IngredientList.Type;
	disabled?: boolean;
	reader?: boolean;
}

const IngredientEditor = (props: IProps) => {
	const renderIcon = (ingredient: Ingredient.Type): JSX.Element | null => {
		if (props.reader) {
			return (
				<a href={`/ingredient/detail/${ingredient.id}`}>
					<FontAwesomeIcon className="icon-eye" icon={faEye} />
				</a>
			);
		} else if (props.disabled) {
			return null;
		} else {
			return (
				<a href={`/ingredient/detail/${ingredient.id}`}>
					<FontAwesomeIcon className="icon-pen" icon={faPen} />
				</a>
			);
		}
	};

	return (
		<div className="ingredient-editor-div-table">
			<table className="table table-ingredient-editor mb-0">
				<tbody>
					{props.ingredients.map((ingredient: Ingredient.Type, key: number) => (
						<tr key={key}>
							<td className="grey">{ingredient.name}</td>
							<td className="table-ingredient-editor-td-pen">
								{renderIcon(ingredient)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default IngredientEditor;
