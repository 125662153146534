import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Recipe } from 'api/menu/GetMenuIdV1';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Menu } from 'types/Menu/Menu';
import { TagLight } from 'types/Tag/TagLight';

interface IProps {
	handleSetTags: (tags: TagLight[]) => void;
	object: Recipe | Menu;
	tagList: TagLight[];
	disabled?: boolean;
}

const TagEditor = (props: IProps) => {
	const { t } = useTranslation();

	const [filteredTags, setFilteredTags] = useState<TagLight[]>([]);

	const handleAddTag = (savedItem: ISelectItem) => {
		var objectTagsCpy = [...props.object.tags];
		var tag = savedItem as TagLight;
		var isFound = objectTagsCpy.some((element) => element.id == tag.id);

		if (!isFound) {
			objectTagsCpy.push({
				id: tag.id,
				name: tag.name,
				iconSvgUrl: tag.iconSvgUrl,
				display: tag.display,
				tagCategory: tag.tagCategory,
				source: null,
			});
		}

		props.handleSetTags(objectTagsCpy);
	};

	const createHandleDeleteTag = (id: string): (() => void) | undefined => {
		if (props.disabled) return undefined;
		return () => {
			const objectTagsCpy: TagLight[] = props.object.tags.filter((tag: TagLight) => {
				if (tag.id !== id) {
					return tag;
				}
			});
			props.handleSetTags(objectTagsCpy);
		};
	};

	const getTagTitle = (tag: TagLight): string => {
		if (tag.tagCategory) {
			return `${tag.name} (${tag.tagCategory.name})`;
		} else {
			return tag.name;
		}
	};

	const filterTagsBySearchTerm = (searchTerm: string): void => {
		const tagIds: string[] = props.object.tags.map((e: TagLight) => e.id);

		const tags: TagLight[] = props.tagList
			.filter((tag) => tag.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
			.filter((tag) => !tagIds.includes(tag.id))
			.map((tag) => {
				return { ...tag, name: getTagTitle(tag) };
			});

		setFilteredTags(tags);
	};

	const renderContent = () => {
		return (
			<>
				<RenderIf condition={!props.disabled}>
					<div className="form-group">
						<label>{t('_general:TAGS')}</label>
						<div className="input-group">
							<SuggestionSearchInput
								className="form-control"
								debounceMilliSeconds={500}
								errorKey="Tags"
								handleChangeSearchTerm={filterTagsBySearchTerm}
								setSavedItem={handleAddTag}
								suggestedItems={filteredTags}
								triggerSearchLetterAmount={0}
							/>
						</div>
					</div>
				</RenderIf>
				<div className="overflow-auto">
					{props.object.tags.map((tag: TagLight, i: number) => (
						<ListItem
							handleDelete={createHandleDeleteTag(tag.id)}
							iconSvgUrl={tag.iconSvgUrl}
							key={i}
							text={tag.name}
							title={tag.tagCategory?.name ?? undefined}
						/>
					))}
				</div>
				{props.disabled && props.object.tags.length === 0 && (
					<>
						<span className="grey">{t('_general:NO_TAGS')}</span>
					</>
				)}
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TagEditor;
