import * as apiSW from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import * as apiPostSearch from 'api/carbonDioxideLabel/PostCarbonDioxideLabelSearchV1';
import CarbonDioxideLabel from 'classes/StandingData/CarbonDioxideLabel/Search/CarbonDioxideLabel';
import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class CarbonDioxideLabelList {
	public all: CarbonDioxideLabel[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(
		searchTerm: string,
		calculationMethod: ValueScope,
		showOnlyDeleted: boolean
	): Promise<void> {
		const carbonDioxideLabels = await apiSW.callApi(
			searchTerm,
			calculationMethod,
			showOnlyDeleted
		);
		carbonDioxideLabels.hasValue() && this.mapFromApiSW(carbonDioxideLabels.get());
	}

	public async callApi(
		input: apiPostSearch.Request
	): Promise<Optional<apiPostSearch.ResponseData>> {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel(i);
			this.all.push(carbonDioxideLabel);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseCarbonDioxideLabel[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.label,
			});
		}
	}
}
