import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import 'components/desktop/_general/PictureUpload/PictureUpload.scss';
import { validateFileSizes } from 'functions/validateFileSizes';

interface IProps {
	setFormData: (input: FormData | null) => void;
	accept?: string;
	label?: string;
}

const PictureUpload = (props: IProps) => {
	const { t } = useTranslation();
	const inputFile = useRef<any>(null);

	const onImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		validateFileSizes(event.target.files).do((files) => {
			const file: File = files[0];

			const newFormData = new FormData();
			newFormData.append('Media.File', file);
			props.setFormData(newFormData);
		});
	};

	const handleChooseFile = () => {
		inputFile.current.click();
	};

	const getLabel = (): string => {
		if (props.label) return props.label;
		return t('_general:UPLOAD_NEW_PICTURE');
	};

	return (
		<div className="d-flex">
			<div className="flex-grow-1">
				<div className="mb-3">
					<label className="form-label" style={{ marginBottom: '3px', marginTop: '5px' }}>
						{getLabel()}
					</label>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<button
								className="input-group-text"
								id="basic-addon1"
								style={{
									paddingBottom: '7px',
									backgroundColor: 'grey',
								}}
								onClick={handleChooseFile}
							>
								<FontAwesomeIcon icon={faCamera} style={{ color: 'white' }} />
							</button>
						</div>
						<input
							className="form-control form-control-sm custom-file-input"
							ref={inputFile}
							onChange={onImageChange}
							type="file"
							name="image"
							accept={
								props.accept ??
								'image/png, image/jpeg, image/gif, image/tiff, image/svg+xml'
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PictureUpload;
