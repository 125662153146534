import 'moment/min/locales';
import Moment from 'react-moment';

Moment.globalLocale = navigator.language || '';

interface IProps {
	date: Date | string;
	format?: string | undefined;
}

const FormatedDate = (props: IProps) => {
	return <Moment format={props.format || 'L [-] LT'}>{props.date}</Moment>;
};

export default FormatedDate;
