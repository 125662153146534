import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/AccessoryCategory/Search';
	return axios.post<ResponseData>(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	searchTerm: string | null;
	showOnlyDeleted: boolean;
	pageIndex: number | null;
	pageSize: number | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseAccessoryCategory[];
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
};

export type ResponseAccessoryCategory = {
	id: string;
	name: string;
	parentId: string | null;
	parentName: string | null;
};
