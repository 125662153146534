import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import * as Item from 'components/desktop/_general/ItemListEditor/Item';
import * as ItemList from 'components/desktop/_general/ItemListEditor/ItemList';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { useIsHandlerLoading } from 'hooks/useIsLoading';

interface IProps {
	allItems: ItemList.Type;
	readOnly?: boolean;
	itemIds: string[];
	errorKey?: string;
	setItemIds: (itemIds: string[]) => void;
	handleAiButtonClick?: () => Promise<void>;
	noItemsMessage?: string;
}

const ItemListEditor = (props: IProps) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isLoading, wrappedHandleAiButtonClick] = useIsHandlerLoading(props.handleAiButtonClick);

	const handleAddItemId = (id: string) => {
		if (props.itemIds.includes(id)) {
			props.setItemIds(props.itemIds.filter((e) => e !== id));
		} else {
			props.setItemIds([...props.itemIds, id]);
		}
	};

	const handleDeleteItemId = (id: string): void => {
		if (props.readOnly) return;
		props.setItemIds(props.itemIds.filter((e) => e !== id));
	};

	const renderAiButton = (): JSX.Element | null => {
		if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SIMILAR_TAGS])) return null;
		if (!props.handleAiButtonClick) return null;
		return (
			<span
				className="input-group-text select-button btn-primary"
				id="basic-addon1"
				onClick={wrappedHandleAiButtonClick}
			>
				<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
			</span>
		);
	};

	return (
		<SpinnerWhenLoading isLoading={isLoading}>
			<>
				<RenderIf condition={!props.readOnly}>
					<div className="form-group">
						<div className="input-group">
							<SuggestionSearchInput
								className="form-control"
								handleChangeSearchTerm={setSearchTerm}
								setSavedItem={(item) => handleAddItemId(item.id)}
								triggerSearchLetterAmount={0}
								suggestedItems={ItemList.getSuggestedItems(
									props.allItems,
									props.itemIds,
									searchTerm
								)}
								debounceMilliSeconds={0.1}
								errorKey={props.errorKey}
							/>
							{renderAiButton()}
						</div>
					</div>
				</RenderIf>
				<RenderIf condition={!(props.itemIds.length === 0)}>
					<div className="overflow-auto mt-2">
						{ItemList.mapFromIdArray(props.allItems, props.itemIds).map(
							(item: Item.Type, i: number) => (
								<ListItem
									key={i}
									text={item.name}
									title={''}
									iconSvgUrl={item.icon}
									handleDelete={
										props.readOnly
											? undefined
											: () => handleDeleteItemId(item.id)
									}
									textColor={Item.getColorFromLogic(item)}
								/>
							)
						)}
					</div>
				</RenderIf>
				<RenderIf condition={Boolean(props.readOnly && props.itemIds.length === 0)}>
					<div className="grey" style={{ fontSize: '0.8em' }}>
						{props.noItemsMessage ?? '–'}
					</div>
				</RenderIf>
			</>
		</SpinnerWhenLoading>
	);
};

export default ItemListEditor;
