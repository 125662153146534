import { Selectable } from 'classes/_general/Search/MultiSelect';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

export interface Saveable {
	ids: string[];
}

export class NutriScoreLabelSelect<SuggestedType extends Selectable, SavedType extends Saveable>
	implements IAdvancedSearchKey
{
	public id: string;
	public suggested: SuggestedType;
	public saved: SavedType[] = [];

	constructor(c: new () => SuggestedType, id: string) {
		this.id = id;
		this.suggested = new c();
	}

	public async initialize() {
		if (this.suggested.all.length === 0) {
			await this.suggested.initialize();
		}
	}

	public reset() {
		this.saved = [];
	}

	public pushToSaved(list: SavedType[]): void {
		this.saved = list;
	}

	public mapToApi(): string[] {
		const output: string[] = [];
		for (const i of this.saved) {
			for (const id of i.ids) {
				output.push(id);
			}
		}
		return output;
	}

	public mapToUrl(): string | null {
		if (this.saved.length === 0) return null;
		const output: string[] = [];
		for (const i of this.saved) {
			for (const id of i.ids) {
				output.push(id);
			}
		}
		return this.id + '=' + output.join(';');
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (key === this.id) {
				if (typeof value === 'string') {
					for (const i of value.split(';')) {
						const object: any = this.suggested.getById(i);
						if (object) {
							this.saved.push(object);
						}
					}
				}
			}
		}
	}

	public async search(searchTerm: string): Promise<void> {
		await this.suggested.search(searchTerm);
	}
}
