import { faLock, faTrash, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import 'components/desktop/_general/Input/LockableInput/LockableInput.scss';

interface IProps {
	aiButton?: JSX.Element;
	defaultLocked?: boolean;
	defaultValue: string;
	disabled?: boolean;
	errorKey?: string;
	errorKeyInput?: string;
	handleEnterDown?: (() => void) | undefined;
	handleValueChange: (id: string, value: string) => void;
	id: string;
	label?: string;
	handleLockClick?: ((id: string, isLocked: boolean) => number) | undefined;
	onPlusClick?: (id?: string) => void;
	onTrashCanClick?: (id: string) => void;
	placeholder: string;
	select?: JSX.Element;
	title?: string | null;
	validateRegex: RegExp;
	withLock: boolean;
	withPlusSign?: boolean;
	withTrashCan: boolean;
}

const LockableInput = (props: IProps) => {
	const [lock, setLock] = useState<boolean>(Boolean(props.defaultLocked));
	const [value, setValue] = useState<string>(props.defaultValue);

	useEffect((): void => {
		setValue(props.defaultValue);
		if (props.defaultLocked !== undefined) {
			setLock(props.defaultLocked);
		}
	}, [props.defaultValue, props.defaultLocked]);

	const getTitle = () => {
		if (props.title) {
			return props.title;
		}
	};

	const validateValue = (value: string): boolean => {
		return props.validateRegex.test(value);
	};

	const onBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value: string = event.target.value;
		if (validateValue(value)) {
			props.handleValueChange(props.id, value);
		} else {
			setValue(props.defaultValue);
		}
	};

	const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value: string = event.target.value;
		setValue(value);
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (props.handleEnterDown) {
			if (event.key === 'Enter') {
				props.handleEnterDown();
			}
		}
	};

	const onLockClick = (): void => {
		if (props.handleLockClick) {
			if (!lock) {
				setValue(String(props.handleLockClick(props.id, true)));
			} else {
				setValue(String(props.handleLockClick(props.id, false)));
			}
		}
		setLock(!lock);
	};

	const onTrashCanClick = (): void => {
		props.onTrashCanClick?.(props.id);
	};

	const onPlusClick = (): void => {
		props.onPlusClick?.(props.id);
	};

	const getDisabled = (): boolean => {
		if (props.disabled) return true;
		if (props.withLock) return lock;
		return false;
	};

	const renderLock = (): JSX.Element => {
		if (props.withLock) {
			return (
				<span
					className="input-group-text input-label-field-button"
					id="basic-addon1"
					onClick={onLockClick}
				>
					{lock ? (
						<FontAwesomeIcon icon={faLock} className="icon" />
					) : (
						<FontAwesomeIcon icon={faUnlock} className="icon" />
					)}
				</span>
			);
		} else {
			return <></>;
		}
	};

	const renderTrashCan = (): JSX.Element => {
		if (props.withTrashCan) {
			return (
				<span
					className="input-group-text input-label-field-button"
					id="basic-addon1"
					onClick={onTrashCanClick}
				>
					<FontAwesomeIcon icon={faTrash} className="icon" />
				</span>
			);
		} else {
			return <></>;
		}
	};

	const renderAiButton = (): JSX.Element => {
		if (props.aiButton) {
			return props.aiButton;
		} else {
			return <></>;
		}
	};

	const renderSelect = (): JSX.Element => {
		if (props.select) {
			return props.select;
		} else {
			return <></>;
		}
	};

	const renderPlusButton = (): JSX.Element => {
		if (props.onPlusClick) {
			return (
				<span
					className="input-group-text input-label-field-button btn-primary"
					id="basic-addon1"
					onClick={onPlusClick}
				>
					<FontAwesomeIcon icon={faPlus} />
				</span>
			);
		} else {
			return <></>;
		}
	};

	return (
		<>
			<div className="form-group">
				{props.label && <label>{props.label}</label>}
				<div className="input-group" error-key={props.errorKey} title={getTitle()}>
					<input
						className="form-control"
						value={value}
						disabled={getDisabled()}
						id={props.id}
						onBlur={onBlur}
						onChange={onChange}
						onKeyDown={onKeyDown}
						placeholder={props.placeholder}
						error-key={props.errorKeyInput}
						type="text"
					/>
					{renderAiButton()}
					{renderSelect()}
					{!props.disabled && renderTrashCan()}
					{!props.disabled && renderLock()}
					{!props.disabled && renderPlusButton()}
				</div>
			</div>
		</>
	);
};

export default LockableInput;
