import { t } from 'i18next';

import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import * as Currency from 'types/Currency/User/Currency';

interface IProps {
	handleSetSelectedCurrency: (currencyId: string) => void;
	selectedCurrency: string | null;
	allCurrencies: Currency.Type[];
}

const ChangeCurrency = (props: IProps) => {
	const getOptions = (): SelectOption[] => {
		return props.allCurrencies.map((currency: Currency.Type) => ({
			id: currency.id,
			text: currency.name,
		}));
	};

	return (
		<CardTransparent
			title={t('user:CURRENCY')}
			content={
				<>
					<SelectSimple
						options={getOptions()}
						currentValue={props.selectedCurrency}
						handleValueChange={props.handleSetSelectedCurrency}
					/>
				</>
			}
		/>
	);
};

export default ChangeCurrency;
