import { useTranslation } from 'react-i18next';

import Ingredients from 'containers/desktop/Recipe/Print/Ingredients';
import { style } from 'containers/desktop/Recipe/Print/RecipePrint';
import * as Analysis from 'types/Recipe/Print/Analysis/Analysis';
import * as Recipe from 'types/Recipe/Print/Recipe';

type RenderGuidanceProps = {
	recipe: Recipe.Type;
	cultureCode: string;
	analysis: Analysis.Type;
};

const Instructions = ({ recipe, cultureCode, analysis }: RenderGuidanceProps): JSX.Element => {
	const { t } = useTranslation();

	const getSteps = (): JSX.Element => {
		const stepArray: string[] = (recipe.stepsTranslations[cultureCode] ?? '').split(
			/\r\r|\n\n/
		);

		return (
			<div className="d-flex flex-column" style={{ gap: '15px' }}>
				{stepArray.map((text: string, i: number) => {
					return <div key={i}>{text}</div>;
				})}
			</div>
		);
	};

	return (
		<div className="row" style={{ marginTop: '20px' }}>
			<div className="col-sm-5">
				<h3 style={style.h3}> {t('_general:INGREDIENTS')}</h3>
				<Ingredients analysis={analysis} />
			</div>
			<div className="col-sm-7">
				<h3 style={style.h3}>{t('_general:INSTRUCTIONS')}</h3>
				<div style={style.text}>{getSteps()}</div>
			</div>
		</div>
	);
};

export default Instructions;
