export type UserVerificationDto = {
	[key: string]: any;
	confirmationCode: string;
	password: string;
};

export const defaultUserVerificationDto = (): UserVerificationDto => {
	return {
		confirmationCode: '',
		password: '',
	};
};
