import * as NutrientValue from 'types/BaseIngredient/Detail/NutrientValue';

export type Type = NutrientValue.Type[];

export function create(): Type {
	return [];
}

export function mapToApi(obj: Type): {
	[key: string]: number;
} {
	const output: {
		[key: string]: number;
	} = {};
	for (const i of obj) {
		output[i.id] = i.value;
	}
	return output;
}
