import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/User';
	return axios
		.get(url, {
			params: {
				searchTerm: request.searchTerm,
				pageIndex: request.pageIndex,
				pageSize: request.pageSize,
			},
		})
		.toOptionalMapped((x) => x.data);
}

export interface Request {
	searchTerm: string;
	pageIndex: number;
	pageSize: number;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: User[];
	pageIndex: number;
	totalCount: number;
};

export type User = {
	id: string;
	languageId: string;
	fullName?: string;
	firstName?: string;
	lastName?: string;
	email: string;
	isActive: boolean;
	roles: UserRole[];
};

export type UserRole = {
	id: string;
	name: string;
};
