import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'components/desktop/BaseIngredient/UnitWeightEditor/UnitWeightEditor.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import { getNumberString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import * as inputValidation from 'functions/validation/inputValidation';
import { RootState } from 'reducers/rootReducer';
import { regexValidationWeight } from 'regex/validation/Weight';
import { UnitLight } from 'types/Unit/UnitLight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

interface IProps {
	baseIngredientCharacteristic: Characteristic | null;
	baseIngredientUnitWeights: UnitWeight[];
	disabled?: boolean;
	setBaseIngredientUnitWeights: (unitWeights: UnitWeight[]) => void;
	units: UnitLight[];
	id: string | null;
	getSuggestedUnitWeight: (selectedUnit: UnitLight) => Promise<Optional<number>>;
}

const UnitWeightEditor = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [weight, setWeight] = useState(0);
	const [selectedUnit, setSelectedUnit] = useState<number>(0);
	const [isLoadingAi, setIsLoadingAi] = useState<boolean>(false);

	useEffect((): void => {
		setSelectedUnit(0);
	}, [props.baseIngredientCharacteristic]);

	const handleAddUnitWeight = (): void => {
		if (!weight) {
			inputValidation.show('WeightAmount');
		} else {
			inputValidation.hide('WeightAmount');
			inputValidation.hide('UnitWeights');
			const allUnits: UnitLight[] = getUnitSuggestions();
			if (!allUnits.length) return;

			const unit: UnitLight = allUnits[selectedUnit];
			if (!unit) return;

			const unitWeightsCpy = [...props.baseIngredientUnitWeights];
			unitWeightsCpy.push({
				id: unit.id,
				weight: weight,
				name: unit.description,
			} as UnitWeight);

			props.setBaseIngredientUnitWeights(unitWeightsCpy);
			setWeight(0);
			setSelectedUnit(0);
		}
	};

	const handleSelectUnit = (e: any) => {
		setSelectedUnit(e.currentTarget.value);
	};

	const handleDeleteUnitWeight = (unitWeight: UnitWeight) => {
		var baseIngredientUnitWeightsCpy = [...props.baseIngredientUnitWeights];

		var index = baseIngredientUnitWeightsCpy.indexOf(unitWeight);

		if (index !== -1) {
			baseIngredientUnitWeightsCpy.splice(index, 1);
			props.setBaseIngredientUnitWeights(baseIngredientUnitWeightsCpy);
		}
	};

	const getTitle = (unitWeight: UnitWeight) => {
		if (unitWeight.source) {
			return unitWeight.source;
		}
	};

	const renderTableRow = (unitWeight: UnitWeight, unitName: string, key: number): JSX.Element => {
		return (
			<tr key={key} error-key={`UnitWeights[${key}]`} title={getTitle(unitWeight)}>
				<td>
					<span className="table-unit-weight-editor-span-unit-weight">
						{getNumberString(unitWeight.weight, reduxCultureCode)}
					</span>
					<span className="grey">{unitName}</span>
				</td>
				<td className="grey">{t('_general:PER')}</td>
				<td>{unitWeight.name}</td>
				<td className="table-unit-weight-editor-td-trash-can">
					{!props.disabled && (
						<FontAwesomeIcon
							className="trash-can"
							icon={faTrashCan}
							onClick={() => handleDeleteUnitWeight(unitWeight)}
						/>
					)}
				</td>
			</tr>
		);
	};

	const handleValueChange = (_id: string, value: string): void => {
		setWeight(Number(value));
	};

	const handleGetSuggestedUnitWeightClick = async (): Promise<void> => {
		setIsLoadingAi(true);

		const suggestedUnitWeight = await props.getSuggestedUnitWeight(props.units[selectedUnit]);

		const weightCpy = suggestedUnitWeight;
		setWeight(weightCpy.getOrDefault(0));
		setIsLoadingAi(false);
	};

	const renderAiButton = (): JSX.Element => {
		return (
			<RenderIf entitlements={[ENTITLEMENTS.UNIT_WEIGHT]} condition={!props.disabled}>
				<span
					className={`input-group-text select-button btn-primary ${
						props.id ? '' : 'disabled'
					}`}
					id="basic-addon1"
					onClick={handleGetSuggestedUnitWeightClick}
				>
					<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
				</span>
			</RenderIf>
		);
	};

	const renderSelect = (): JSX.Element => {
		const unitSugggestions: UnitLight[] = getUnitSuggestions();
		return (
			<select
				className="form-select"
				disabled={!getUnitSuggestions().length}
				value={selectedUnit ?? unitSugggestions[0]?.id ?? ''}
				name="unitSelect"
				onChange={(e: any) => handleSelectUnit(e)}
			>
				{getUnitSuggestions().map((unit: UnitLight, key: number) => (
					<option key={key} value={String(key)}>
						{unit.description}
					</option>
				))}
			</select>
		);
	};

	const getUnitSuggestions = (): UnitLight[] => {
		const alreadyUsedIds: string[] = props.baseIngredientUnitWeights.map((e: UnitWeight) => {
			return e.id;
		});
		const output: UnitLight[] = props.units.filter((e: UnitLight) => {
			if (!alreadyUsedIds.includes(e.id)) {
				return e;
			}
		});
		return output;
	};

	const getUnit = (): string => {
		if (props.baseIngredientCharacteristic === Characteristic.Fluid) {
			return t('measurments:MILLILITERS');
		} else {
			return t('measurments:GRAMM');
		}
	};

	const getWeightName = (): string => {
		if (props.baseIngredientCharacteristic === Characteristic.Fluid) {
			return t('baseIngredient:VOLUME');
		} else {
			return t('baseIngredient:WEIGHT');
		}
	};

	const getLabel = (): string => {
		return `${getWeightName()} (${getUnit()}) ${t('_general:PER')} ${t('_general:UNIT')}`;
	};

	return (
		<SpinnerWhenLoading isLoading={isLoadingAi}>
			<>
				{!props.disabled && (
					<LockableInput
						aiButton={renderAiButton()}
						defaultValue={String(weight)}
						disabled={props.disabled || !getUnitSuggestions().length}
						errorKey="UnitWeights"
						errorKeyInput="WeightAmount"
						handleValueChange={handleValueChange}
						id="weightInput"
						label={getLabel()}
						onPlusClick={handleAddUnitWeight}
						placeholder=""
						select={renderSelect()}
						validateRegex={regexValidationWeight}
						withLock={false}
						withTrashCan={false}
					/>
				)}
				<div style={{ overflow: 'auto' }}>
					<table
						className="table mb-0"
						style={{ backgroundColor: 'white', overflowX: 'auto' }}
					>
						<tbody>
							{Array.from(props.baseIngredientUnitWeights).map(
								(unitWeight: UnitWeight, i: number) => {
									return renderTableRow(unitWeight, getUnit(), i);
								}
							)}
						</tbody>
					</table>
					{props.disabled && props.baseIngredientUnitWeights.length === 0 && (
						<>
							<label>{t('baseIngredient:NO_UNIT_WEIGHTS_DEFINED')}</label>
						</>
					)}
				</div>
			</>
		</SpinnerWhenLoading>
	);
};

export default UnitWeightEditor;
