import { useSelector } from 'react-redux';

import IconCo2Square from 'components/desktop/_general/IconCo2/IconCo2Square';
import { Characteristic } from 'enums/characteristic';
import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { ValueScopeInfo } from 'functions/valueScopeConversion';
import { convertToValueScope } from 'functions/valueScopeConversion';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	value: number | null;
	color: string;
	valueScopeInfo: ValueScopeInfo | null;
	fluid?: Characteristic | null;
}

const Co2Display = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const getValue = (valueScope?: ValueScope): string => {
		let valueScopeInfo: ValueScopeInfo | null = null;
		if (props.valueScopeInfo) {
			valueScopeInfo = { ...props.valueScopeInfo };
		}
		if (valueScopeInfo && valueScope) {
			valueScopeInfo.valueScope = valueScope;
		}
		if (valueScopeInfo && valueScope) {
			valueScopeInfo.valueScope = valueScope;
		}
		if (props.value !== null) {
			return getNumberString(
				convertToValueScope(props.value, valueScopeInfo),
				reduxCultureCode
			);
		} else {
			return '0';
		}
	};

	const getLabelString = (): string => {
		if (props.valueScopeInfo === null) {
			return getValueScopeLabel(ValueScope.HundredGrams ?? null, props.fluid);
		}
		if (props.valueScopeInfo?.valueScope === ValueScope.HundredGrams) {
			return getValueScopeLabel(ValueScope.HundredGrams ?? null, props.fluid);
		}
		return `${getValueScopeLabel(
			ValueScope.HundredGrams ?? null,
			props.fluid
		)} (${getValueScopeLabel(props.valueScopeInfo?.valueScope ?? null, props.fluid)})`;
	};

	const getValueString = (): string => {
		if (props.valueScopeInfo === null) {
			return `${getNumberString(props.value ?? 0, reduxCultureCode)}\u2006g`;
		}
		if (props.valueScopeInfo?.valueScope === ValueScope.HundredGrams) {
			return `${getValue()}\u2006g`;
		}
		return `${getValue(ValueScope.HundredGrams)}\u2006g (${getValue()}\u2006g)`;
	};

	if (props.value) {
		return (
			<div>
				<label style={{ whiteSpace: 'nowrap' }}>{getLabelString()}</label>
				<div
					className="d-flex"
					style={{
						border: '1px solid #ced4da',
					}}
				>
					<IconCo2Square color={props.color} />
					<div
						style={{
							lineHeight: '1.5em',
							padding: '0.375rem 0.75rem',
							width: '100%',
							textAlign: 'center',
							whiteSpace: 'nowrap',
						}}
					>
						{getValueString()}
					</div>
				</div>
			</div>
		);
	}
	return <></>;
};

export default Co2Display;
