import * as apiDelete from 'api/ingredient/DeleteIngredientIdV1';
import * as apiRestore from 'api/ingredient/PostIngredientRestoreV1';
import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import AllergenList from 'classes/Ingredient/Search/AllergenList';
import CarbonDioxideLabel from 'classes/Ingredient/Search/CarbonDioxideLabel';
import Image from 'classes/Ingredient/Search/Image';
import IngredientCategoryList from 'classes/Ingredient/Search/IngredientCategoryList';
import NutriScore from 'classes/Ingredient/Search/NutriScore';
import SeasonList from 'classes/Ingredient/Search/SeasonList';
import TagList from 'classes/Ingredient/Search/TagList';
import { Characteristic } from 'enums/characteristic';

export default class Ingredient {
	public id: string = '';
	public density: number | null = null;
	public calories: number | null = null;
	public characteristic: Characteristic = Characteristic.Fluid;
	public price: number | null = null;
	public lastEditor: string | null = '';
	public nameSingularTranslation: string = '';
	public countOfRecipes: number = 0;
	public lastEditedDateUtc: string = '';
	public image: Image | null = null;
	public hasNutriScore: boolean = false;
	public nutriScore: NutriScore = new NutriScore();
	public carbonDioxideValue: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public seasons: SeasonList = new SeasonList();
	public allergens: AllergenList = new AllergenList();
	public tags: TagList = new TagList();
	public categories: IngredientCategoryList = new IngredientCategoryList();

	public constructor(input?: apiPostSearch.ResponseIngredient) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseIngredient) {
		this.id = input.id;
		this.density = input.density;
		this.calories = input.calories;
		this.characteristic = input.characteristic;
		this.price = input.price;
		this.lastEditor = input.lastEditor;
		this.nameSingularTranslation = input.nameSingularTranslation;
		this.countOfRecipes = input.countOfRecipes;
		this.lastEditedDateUtc = input.lastEditedDateUtc;
		this.hasNutriScore = input.hasNutriScore;
		this.nutriScore.mapFromApi(input.nutriScore);
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.seasons.mapFromApi(input.seasons);
		this.allergens.mapFromApi(input.allergens);
		this.tags.mapFromApi(input.tags);
		this.categories.mapFromApi(input.categories);

		if (input.image) {
			this.image = new Image();
			this.image.mapFromApi(input.image);
		} else {
			this.image = null;
		}
	}
}
