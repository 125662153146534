import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	checked: boolean;
	elementId: string;
	handleToggle: (input: boolean) => void;
	label: string;
	disabled?: boolean;
	className?: string;
	errorKey?: string;
}

const Toggle = (props: IProps) => {
	const { t } = useTranslation();

	const handleToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const output: boolean = e.target.checked;
		props.handleToggle(output);
	};

	const getClassName = (): string => {
		if (props.className) {
			return `form-check form-switch ${props.className}`;
		} else {
			return 'form-check form-switch';
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className={getClassName()}>
					<label className="form-check-label" htmlFor={props.elementId}>
						{t(props.label)}
					</label>
					<input
						className="form-check-input"
						type="checkbox"
						role="switch"
						id={props.elementId}
						onChange={handleToggle}
						checked={props.checked}
						disabled={props.disabled ?? false}
						error-key={props.errorKey}
					/>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default Toggle;
