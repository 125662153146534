import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ModificationList>> {
	const url: string = '/backend-service/Analytics/Modifications';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ModificationList;
}

export type ModificationList = {
	[date: string]: Modification[];
};

export type Modification = {
	resourceName: string;
	count: number;
	color: string | null;
};
