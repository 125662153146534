import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<Nutrient[]>> {
	const url: string = '/backend-service/Nutrient';
	return axios.get(url).toOptionalMapped((x) => x.data.nutrients);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	nutrients: Nutrient[];
};

export type Nutrient = {
	id: string;
	sortOrder: number;
	name: string;
	isMacroNutrient: boolean;
	unitShortNameSingular: string;
	nutrientCategory: NutrientCategory;
	value: number;
	source: string | null;
};

export type NutrientCategory = {
	id: string;
	name: string;
	sortOrder: number;
};
