import React from 'react';
import { useTranslation } from 'react-i18next';

import Tag from 'classes/StandingData/Tag/Tag';

interface IProps {
	tag: Tag;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TagLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<div className="row mb-3">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={props.tag.nameTranslations[props.selectedLanguage] || ''}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="nameTranslations"
									error-key={'NameTranslations'}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TagLanguageInput;
