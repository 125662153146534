import axios from 'axios';

import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { Optional } from 'functions/promiseExtensions';

export async function callApi(params: Request): Promise<Optional<Media>> {
	if (params.formData) {
		const url = '/backend-service/Media';

		const requestConfig = {
			timeout: 0,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		params.formData.set('Media.Collection', params.mediaCollection);

		return axios
			.post<Media>(url, params.formData, requestConfig)
			.toOptionalMapped((x) => x.data);
	}
	return Optional.None();
}

export interface Request {
	mediaCollection: MEDIACOLLECTION;
	formData: FormData | null;
}

export interface Response {
	data: Media;
}

export type Media = {
	id: string;
	url: string;
	availableSizes: number[];
};
