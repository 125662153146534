import { Values } from 'components/desktop/_general/NutritionFactsLabel/NutritionFactsLabel';

export const nutritionFactsLabelInformations: Array<{
	name: keyof Values;
	id: string[];
	dailyValue: number[];
}> = [
	{
		id: ['5645d120-9ee5-4392-925e-e1db8c9b131c', 'b9b7e5a9-f4d5-e911-b838-00505684d210'],
		name: 'calories',
		dailyValue: [],
	},
	{
		id: ['d54c13c1-b300-40ce-b4ba-2ff134b87c05', 'bbb7e5a9-f4d5-e911-b838-00505684d210'],
		name: 'fatTotal',
		dailyValue: [78, 78000],
	},
	{
		id: ['e9965b64-a824-44ae-885c-f462565a69a3', '95bd5208-460f-4296-b7e0-667ec6cb7c6d'],
		name: 'fatSaturated',
		dailyValue: [20, 20000],
	},
	{
		id: ['c3f2d9a5-b84f-4211-af76-7a65d5b6f8be', 'dcea72b4-b2fa-4fcc-9f52-bebe462a773d'],
		name: 'fatTrans',
		dailyValue: [],
	},
	{
		id: ['59ba6660-7604-431e-8daf-ef3abe152b59', 'dff508a7-c0eb-4759-b7ea-052fcb7cfe1e'],
		name: 'cholesterol',
		dailyValue: [300, 300],
	},
	{
		id: ['17c177a0-85e1-4b8f-b3ad-877203828624', '1ca576ef-cfe7-4812-949e-e1508f7d8844'],
		name: 'sodium',
		dailyValue: [2300, 2300],
	},
	{
		id: ['e19af87e-d4ee-4808-be64-674fcd957424', 'bab7e5a9-f4d5-e911-b838-00505684d210'],
		name: 'carbohydrateTotal',
		dailyValue: [275, 275000],
	},
	{
		id: ['1077c739-0f60-411e-8870-f97b08b407d8', 'd52af111-a601-4be6-ac84-16935dfd5d2b'],
		name: 'fiberDietary',
		dailyValue: [28, 28000],
	},
	{
		id: ['670dfae4-38d8-466b-9152-f71145f388b3', 'c2b7b49d-fe1d-4058-9e83-372021f3d5b7'],
		name: 'sugarsTotal',
		dailyValue: [],
	},
	{
		id: ['07df67ca-2870-4442-b81a-9520eddd16c5', 'bcb7e5a9-f4d5-e911-b838-00505684d210'],
		name: 'protein',
		dailyValue: [50, 50000],
	},
	{
		id: ['0603e3f5-1507-46d9-8349-b4a6d219eff7', 'ac967acb-4778-453c-b132-bde1b70eac28'],
		name: 'vitaminD',
		dailyValue: [20, 20],
	},
	{
		id: ['00a47337-bdba-4fed-a64d-b166df6a0111', 'c8946d95-8bf8-42b8-aa80-2040b91bf069'],
		name: 'calcium',
		dailyValue: [1300, 1300],
	},
	{
		id: ['286e2d14-8d5f-478f-829b-d46dd0cf2a46', '6b27c04b-9dd4-4900-9f05-57b39d733bad'],
		name: 'iron',
		dailyValue: [18, 18000],
	},
	{
		id: ['13e1cc77-5930-4c05-b3f6-8748f50cf429', '772162b9-605d-4828-878f-d3eb0f920e57'],
		name: 'potassium',
		dailyValue: [4700, 4700],
	},
];
