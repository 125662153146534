import ErrorMessageManagementComponent from 'components/desktop/StandingData/ErrorMessage/Management/ErrorMessageManagementComponent';

const ErrorMessageManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ErrorMessageManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ErrorMessageManagementContainer;
