import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { WithSelectedCulture } from 'components/desktop/Hocs/WithSelectedCulture';
import { HighlightingSegment } from 'components/desktop/Recipe/RecipeTabs/Components/HighlightingSegment/HighlightingSegment';
import { ToolTipCell } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import LinkCell from 'components/desktop/Recipe/RecipeTabs/Components/LinkCell/LinkCell';
import {
	ingredientAmountKey,
	ingredientNameKey,
	ingredientUnitKey,
} from 'components/desktop/Recipe/RecipeTabs/Components/NutrientFilterSearchComponentWrapper';
import {
	Translation,
	calculateSegmentLengths,
	enrichIngredients,
	flattenSegments,
	mapToTableData,
} from 'components/desktop/Recipe/RecipeTabs/recipeComputations';
import { AirTable } from 'components/desktop/_general/AirTable/AirTable';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RefreshType } from 'hooks/useRefresh';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { Segment } from 'types/Recipe/Segment';

export const createDefaultColumns = (t: Translation) => {
	const defaultColumns = [
		{ columnName: t('_general:AMOUNT'), key: ingredientAmountKey, numeric: true, sticky: true },
		{ columnName: t('_general:UNIT'), key: ingredientUnitKey, numeric: false, sticky: true },
		{
			columnName: t('_general:AMOUNT_BEFORE'),
			key: 'additionBefore',
			numeric: false,
			sticky: true,
		},
		{ columnName: t('_general:INGREDIENT'), key: ingredientNameKey, sticky: true },
		{
			columnName: t('_general:AMOUNT_AFTER'),
			key: 'additionAfter',
			numeric: false,
			sticky: true,
		},
	];

	return defaultColumns;
};

interface IProps {
	refresh: RefreshType;
	selectedCultureCode: string;
	readOnly: boolean;
	calculation: Calculation | null;
	quantityMethod: string;
	segments: Segment[];
	incorporationShares: IncorporationShareType[];
}

const DefaultView = (props: IProps) => {
	const { t } = useTranslation();
	const flatIngredients = flattenSegments(props.segments);

	const [element, setElement] = useState<JSX.Element>(<></>);

	const joinedIngredients = enrichIngredients(
		props.calculation,
		props.incorporationShares,
		flatIngredients
	);

	const segmentLengths = calculateSegmentLengths(props.segments);

	const tableData = joinedIngredients.map((ingredient) => ({
		...mapToTableData(ingredient, [], props.selectedCultureCode, props.quantityMethod),
		customCell: {
			ingredientName: (
				<ToolTipCell
					refresh={props.refresh}
					ingredient={ingredient.ingredient}
					setElement={setElement}
					lastCell={segmentLengths.includes(ingredient.index + 1)}
				/>
			),
			ingredientUnit: (
				<LinkCell
					link={
						ingredient.ingredient.unitId
							? `/unit/management?id=${ingredient.ingredient.unitId}`
							: '/unit/management'
					}
					title={ingredient.ingredient.unit}
					id={ingredient.ingredient.unitId}
					lastCell={segmentLengths.includes(ingredient.index + 1)}
					disabled={!arePermissionsInUserPermissions([PERMISSIONS.READUNIT])}
				/>
			),
		},
	}));

	const columns = createDefaultColumns(t);

	return (
		<>
			<div style={{ padding: '20px' }} />
			{element}
			<AirTable
				columns={columns}
				data={tableData}
				dividers={segmentLengths}
				readOnly={props.readOnly}
			>
				{props.segments.map((x, i) => (
					<div
						className="segment-image-colspan"
						key={i}
						style={{ width: '100%', height: '100%' }}
					>
						<HighlightingSegment key={i} segment={x} />
					</div>
				))}
			</AirTable>
		</>
	);
};

export default WithSelectedCulture(DefaultView);
