import { TFunction } from 'i18next';

import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import { Table } from 'containers/desktop/MenuPlan/Print/MenuPlanPrint';
import { Value } from 'containers/desktop/MenuPlan/Print/MenuPlanPrint';

export function extractTableFromMenuPlan(
	input: apiGet.ResponseData,
	cultureCode: string,
	t: TFunction
): Table[] {
	const output: Table[] = [
		{
			type: 'WEEKDAYS',
			values: getWeekDaysStringFromMenuPlan(input, t),
		},
	];

	const indexes: number[] = [0, 1, 2, 3, 4];
	for (const index of indexes) {
		output.push({
			type: 'DATES',
			values: getDatesFromMenuPlan(input, index),
		});

		const items = getItemsFromMenuPlan(input, index, cultureCode);
		for (const item of items) {
			output.push({
				type: 'ITEMS',
				values: item,
			});
		}
	}
	return output;
}

function getWeekDaysStringFromMenuPlan(input: apiGet.ResponseData, t: TFunction): Value[] {
	const weekdays: number[] = getWeekDaysFromMenuPlan(input);
	const output: Value[] = [{ value: '' }];
	if (weekdays.includes(1)) output.push({ value: t('_general:WEEKDAY_MONDAY') });
	if (weekdays.includes(2)) output.push({ value: t('_general:WEEKDAY_TUESDAY') });
	if (weekdays.includes(3)) output.push({ value: t('_general:WEEKDAY_WEDNESDAY') });
	if (weekdays.includes(4)) output.push({ value: t('_general:WEEKDAY_THURSDAY') });
	if (weekdays.includes(5)) output.push({ value: t('_general:WEEKDAY_FRIDAY') });
	if (weekdays.includes(6)) output.push({ value: t('_general:WEEKDAY_SATURDAY') });
	if (weekdays.includes(0)) output.push({ value: t('_general:WEEKDAY_SUNDAY') });
	return output;
}

function getWeekDaysFromMenuPlan(input: apiGet.ResponseData): number[] {
	if (!input.rows) return [];
	const output: number[] = [];
	for (const z of input.rows) {
		if (!z.items) continue;
		for (const i of z.items) {
			const date: Date = new Date(input.startDate);
			date.setDate(date.getDate() + i.day);
			const day = date.getDay();
			if (!output.includes(day)) output.push(day);
		}
	}
	return output;
}

function getItemsFromMenuPlan(
	input: apiGet.ResponseData,
	index: number,
	cultureCode: string
): Value[][] {
	if (!input.rows) return [];
	const weekdays: number[] = getWeekDaysFromMenuPlan(input);
	const dates: Date[] = getWeek(input, index);
	const output: Value[][] = [];
	for (const z of input.rows) {
		const rowName: string = z.nameTranslations?.[cultureCode] ?? '';
		const temp: Value[] = [];
		temp.push({ value: rowName });
		for (const i of dates) {
			if (!weekdays.includes(i.getDay())) continue;
			const item = getItemWithRowNameAndDate(input, rowName, i, cultureCode);
			temp.push({
				value: item?.titleTranslations?.[cultureCode] ?? '–',
			});
		}
		output.push(temp);
	}
	return output;
}

function getItemWithRowNameAndDate(
	input: apiGet.ResponseData,
	rowName: string,
	date: Date,
	cultureCode: string
): apiGet.Item | null {
	const row = input.rows?.find((e) => e.nameTranslations?.[cultureCode] === rowName);
	if (!row) return null;
	if (!row.items) return null;
	for (const item of row.items) {
		const itemDate: Date = new Date(input.startDate);
		itemDate.setDate(itemDate.getDate() + item.day);
		if (itemDate.toString() === date.toString()) {
			return item;
		}
	}
	return null;
}

function getDatesFromMenuPlan(input: apiGet.ResponseData, index: number): Value[] {
	const output: Value[] = [{ value: '' }];
	const weekdays: number[] = getWeekDaysFromMenuPlan(input);
	const weekDates: Date[] = getWeek(input, index);

	for (const i of weekDates) {
		if (!weekdays.includes(i.getDay())) continue;
		output.push({ value: getDateString(i) });
	}
	return output;
}

function getWeek(input: apiGet.ResponseData, index: number): Date[] {
	const output: Date[] = [];
	const startDate: Date = new Date(input.startDate);
	while (startDate.getDay() !== 1) {
		startDate.setDate(startDate.getDate() - 1);
	}
	startDate.setDate(startDate.getDate() + 7 * index);
	for (let i = 0; i < 7; i++) {
		const myDate: Date = new Date(startDate.getTime());
		if (i) {
			myDate.setDate(myDate.getDate() + i);
		}
		output.push(myDate);
	}
	return output;
}

function getDateString(date: Date) {
	const year = date.getFullYear();
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const day = ('0' + date.getDate()).slice(-2);
	return `${day}.${month}.${year}`;
}
