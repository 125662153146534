const font = '16px system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial';
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d')!;
context.font = font;

export const getTextWidth = (text: string | undefined | null) => {
	if (text) {
		return context.measureText(text).width;
	}
	return 0;
};
