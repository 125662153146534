import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faBarcode, faDollarSign, faWeightHanging } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import 'components/desktop/Ingredient/IngredientProducts/ProductDetailCard/ProductDetailCard.scss';
import { getPriceString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';
import * as IngredientProduct from 'types/Product/IngredientProduct';

interface IProps {
	handleDelete: (e: any, index: number) => void;
	index: number;
	product: IngredientProduct.Type;
}

const ProductDetailCard = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const getInfos = (): JSX.Element => {
		return (
			<>
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faDollarSign as IconProp} />
					</span>
					<b>
						{props.product.price
							? getPriceString(props.product.price, reduxCultureCode)
							: '-'}
					</b>
					{' ' + reduxCurrency}
					&nbsp;
				</p>
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faWeightHanging as IconProp} />
					</span>
					{props.product.quantity ? ' ' + props.product.quantity : '-'}
				</p>
				<p style={{ marginBottom: '-8px' }}>
					<span id="icon" style={{ marginRight: '-7px' }}>
						<FontAwesomeIcon icon={faBarcode as IconProp} />
					</span>
					<span style={{ color: '#aaa', fontFamily: 'monospace' }}>
						{props.product.externalId ? ' ' + props.product.externalId : '-'}
					</span>
				</p>
			</>
		);
	};

	return (
		<div className="card product-card">
			<div className="card-body">
				<>
					<div className="text-end">
						<FontAwesomeIcon
							className="trash-can"
							icon={faTrashCan}
							onClick={(e: any) => props.handleDelete(e, props.index)}
						/>
					</div>
					{!props.product.imageUrl ? (
						<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
							<FontAwesomeIcon size="3x" icon={faCamera} />
						</div>
					) : (
						<div className="card-picture">
							<img title={props.product.name} src={props.product.imageUrl} />
						</div>
					)}
					<div>
						<div id="title">
							<strong>{props.product.name}</strong>
						</div>
						<div id="info">{getInfos()}</div>
					</div>
				</>
			</div>
		</div>
	);
};

export default ProductDetailCard;
