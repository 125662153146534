import { QueryParams } from 'functions/getQueryParams';
import { hasSliderChanges } from 'functions/sliderFunctions';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

type Range = {
	minimum: number | null;
	maximum: number | null;
};

export default class Slider implements IAdvancedSearchKey {
	public id: string;
	public min: number | null = null;
	public max: number | null = null;
	public left: number | null = null;
	public right: number | null = null;
	public minArea: number | null = null;
	public maxArea: number | null = null;

	constructor(id: string) {
		this.id = id;
	}

	public getMaxRoundUp(): number {
		if (this.max) {
			return Math.ceil(this.max);
		} else {
			return 0;
		}
	}

	public getMinRoundDown(): number {
		if (this.min) {
			return Math.floor(this.min);
		} else {
			return 0;
		}
	}

	public hasSliderChanges(left: number, right: number): boolean {
		return hasSliderChanges(left, right, this);
	}

	public reset() {
		this.min = null;
		this.max = null;
		this.left = null;
		this.right = null;
		this.minArea = null;
		this.maxArea = null;
	}

	public mapFromApi(input: Range) {
		this.minArea = input.minimum;
		this.maxArea = input.maximum;
		if (this.min === null) {
			this.min = input.minimum;
		}
		if (this.max === null) {
			this.max = input.maximum;
		}
		if (this.left === null) {
			this.left = input.minimum;
		}
		if (this.right === null) {
			this.right = input.maximum;
		}
	}

	public mapToApi(): Range {
		let minimum: number | null = null;
		let maximum: number | null = null;
		if (this.left !== this.min) minimum = this.left;
		if (this.right !== this.max) maximum = this.right;
		return {
			minimum: minimum,
			maximum: maximum,
		};
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			switch (key) {
				case this.id + 'Left':
					this.left = Number(value);
					break;
				case this.id + 'Right':
					this.right = Number(value);
					break;
				case this.id + 'Min':
					this.min = Number(value);
					break;
				case this.id + 'Max':
					this.max = Number(value);
					break;
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.hasNoValues()) return null;
		const output: string[] = [];
		output.push(this.id + 'Left=' + (this.left ? this.left : this.min));
		output.push(this.id + 'Right=' + (this.right ? this.right : this.max));
		if (this.min) output.push(this.id + 'Min=' + this.min);
		if (this.max) output.push(this.id + 'Max=' + this.max);
		return output.join('&');
	}

	public hasNoValues(): boolean {
		return !this.min && !this.max && !this.left && !this.right;
	}
}
