import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';
import { Icon } from 'types/Icon/Icon';

export default class Season {
	id: string = '';
	iconSvgUrl: string = '';
	name: string = '';
	display: boolean = false;
	associatedMonthsFlag: number = 0;

	public constructor(input?: postMenuSearchV1.Season) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcon(): Icon | null {
		if (this.display) {
			return {
				name: this.name,
				iconSvgUrl: this.iconSvgUrl,
			};
		}
		return null;
	}

	public mapFromApi(input: postMenuSearchV1.Season): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl ?? '';
		this.name = input.name ?? '';
		this.display = input.display;
		this.associatedMonthsFlag = input.associatedMonthsFlag ?? 0;
	}
}
