import { useTranslation } from 'react-i18next';

import { ResponseNutriScoreLabel } from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import 'components/desktop/_general/NutriScoreSelect/NutriScoreDiagramSelect.scss';

interface IProps {
	nutriScoreLabels: ResponseNutriScoreLabel[];
	savedItems: ResponseNutriScoreLabel[];
	setSavedItems: (items: ResponseNutriScoreLabel[]) => void;
}

const NutriScoreDiagramSelect = (props: IProps) => {
	const { t } = useTranslation();

	const handleClick = (input: ResponseNutriScoreLabel) => {
		const savedItemsCpy = [...props.savedItems];
		if (savedItemsCpy.some((x) => x.label == input.label)) {
			const index = savedItemsCpy.findIndex((x) => x.label == input.label);
			savedItemsCpy.splice(index, 1);
		} else {
			savedItemsCpy.push(input);
		}
		props.setSavedItems(savedItemsCpy);
	};

	const renderLabel = (input: ResponseNutriScoreLabel, key: number): JSX.Element => {
		if (props.savedItems.some((x) => x.label == input.label)) {
			return (
				<div
					key={key}
					className="nutri-score-diagram-score score"
					onClick={() => handleClick(input)}
					style={{ backgroundColor: input.color, opacity: 1 }}
				>
					<span className="nutri-score-label">{input.label}</span>
				</div>
			);
		} else {
			return (
				<div
					key={key}
					className="nutri-score-diagram-score score"
					onClick={() => handleClick(input)}
					style={{ backgroundColor: input.color, opacity: 0.3 }}
				>
					<span className="nutri-score-label">{input.label}</span>
				</div>
			);
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="nutri-score-diagram-container-outer">
					<label>{t('_general:NUTRI_SCORE')}</label>
					<div className="nutri-score-diagram-container-inner">
						{props.nutriScoreLabels.map((n: ResponseNutriScoreLabel, i: number) =>
							renderLabel(n, i)
						)}
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default NutriScoreDiagramSelect;
