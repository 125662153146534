import { DependencyList, useEffect, useState } from 'react';

// should we consider this? https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useDebounce/useDebounce.ts
function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

/* eslint-disable no-redeclare */
export function useDebouncedFn<T, T1>(initialValue: T, f: (t1: T1) => Promise<T>, t1: T1): T;
export function useDebouncedFn<T, T1, T2>(
	initialValue: T,
	f: (t1: T1, t2: T2) => Promise<T>,
	t1: T1,
	t2: T2
): T;

export function useDebouncedFn<T>(initialValue: T, f: (...s: any) => Promise<any>, ...s: any): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

	useEffect(() => {
		const timer = setTimeout(async () => {
			const value = await f(...s);
			setDebouncedValue(value);
		}, 350);

		return () => {
			clearTimeout(timer);
		};
	}, s as DependencyList);

	return debouncedValue;
}

export default useDebounce;
