import { useTranslation } from 'react-i18next';

import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { Recipe } from 'types/Recipe/Recipe';
import { StatusLight } from 'types/Status/StatusLight';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

interface IProps {
	recipe: Recipe;
	statusList: StatusLight[];
	handleSetRecipeAttribute: (
		key: keyof Recipe,
		value: StatusLight | SystemStatus | number | string
	) => void;
}

const systemStatusList: StatusLight[] = [
	{
		id: '0',
		name: 'New',
	},
	{
		id: '1',
		name: 'Published',
	},
];

const StatusEditor = (props: IProps) => {
	const { t } = useTranslation();
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleStatusChange = (id: string): void => {
		const status: StatusLight | undefined = props.statusList.find((e: StatusLight) => {
			return e.id === id;
		});
		if (status) props.handleSetRecipeAttribute('status', status);
	};

	const handleSystemStatusChange = (id: string): void => {
		const status: StatusLight | undefined = systemStatusList.find((e: StatusLight) => {
			return e.id === id;
		});
		if (status) props.handleSetRecipeAttribute('systemStatus', status.name as SystemStatus);
	};

	const getSystemStatusIdFromRecipe = (): StatusLight | undefined => {
		return systemStatusList.find((e: StatusLight) => {
			return e.name === props.recipe.systemStatus;
		});
	};

	return (
		<>
			<div className="form-group">
				<label>{t('recipe:STATUS')}</label>
				<div className="input-group">
					<SelectSimple
						options={props.statusList.map((status: StatusLight) => ({
							id: status.id,
							text: status.name,
						}))}
						currentValue={props.recipe.status?.id ?? null}
						handleValueChange={handleStatusChange}
						disabled={!recipeEditor}
						errorKey="Status"
					/>
				</div>
			</div>
			<div className="form-group">
				<label>{t('recipe:SYSTEM_STATUS')}</label>
				<div className="input-group">
					<SelectSimple
						options={systemStatusList.map((status: StatusLight) => ({
							id: status.id,
							text: t('systemStatus:' + status.name),
						}))}
						currentValue={getSystemStatusIdFromRecipe()?.id ?? null}
						handleValueChange={handleSystemStatusChange}
						disabled={!recipeEditor}
						errorKey="SystemStatus"
					/>
				</div>
			</div>
		</>
	);
};

export default StatusEditor;
