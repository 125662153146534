import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import ForgotPasswordForm from 'components/desktop/User/PasswordRecovery/ForgotPasswordForm';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import 'containers/desktop/User/PasswordRecovery/PasswordRecovery.scss';
import {
	ForgotPasswordDto,
	defaultForgotPasswordDto,
} from 'types/User/PasswordRecovery/ForgotPasswordDto';

const ForgotPassword = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [forgotPasswordDto, _setForgotPasswordDto] = useState<ForgotPasswordDto>(
		defaultForgotPasswordDto()
	);
	const navigate = useNavigate();

	const handleSendLink = async () => {
		setIsLoading(true);
		const url = '/backend-service/User/ForgotPassword';

		forgotPasswordDto.passwordRecoveryPageUrl = window.origin + '/passwordReset';
		forgotPasswordDto.email = email;

		try {
			await axios.post(url, forgotPasswordDto);
			navigate('/linkSentConfirmation');
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="password-recovery-container">
					<h2>Artificial Intelligence Recipes</h2>
					<div className="password-recovery-inner-container">
						<LogoAnimation />
						<div className="password-recovery-inner-container-div">
							{!isLoading ? (
								<ForgotPasswordForm
									email={email}
									setEmail={setEmail}
									handleSendLink={handleSendLink}
								/>
							) : (
								<LoadingSpinner />
							)}
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ForgotPassword;
