import axios from 'axios';

import { UnitType } from 'enums/unitType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/Unit/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	type: UnitType | null;
	factorToMilliliter: number | null;
	factorToGram: number | null;
	factorToOne: number | null;
	nameSingularTranslations: {
		[key: string]: string;
	};
	namePluralTranslations: {
		[key: string]: string;
	};
	shortNameSingularTranslations: {
		[key: string]: string;
	};
	shortNamePluralTranslations: {
		[key: string]: string;
	};
	synonyms: Synonyms;
	isAirDefinition: boolean;
}

export type Synonyms = {
	[key: string]: string[];
};
