export enum IngredientSortOrder {
	NameAsc = 'NameAsc',
	NameDesc = 'NameDesc',
	LastEditedDateAsc = 'LastEditedDateAsc',
	LastEditedDateDesc = 'LastEditedDateDesc',
	PriceAsc = 'PriceAsc',
	PriceDesc = 'PriceDesc',
	DensityAsc = 'DensityAsc',
	DensityDesc = 'DensityDesc',
	CaloriesAsc = 'CaloriesAsc',
	CaloriesDesc = 'CaloriesDesc',
	CarbonDioxideAsc = 'CarbonDioxideAsc',
	CarbonDioxideDesc = 'CarbonDioxideDesc',
	Relevance = 'Relevance',
}
