import { ValueScope } from 'enums/valueScope';

export type Type = ValueScope[];

export function create(): Type {
	return [ValueScope.Person, ValueScope.Total];
}

export function update(obj: Type, key: ValueScope): Type {
	if (obj.includes(key)) {
		if (obj.length === 1) return obj;
		return obj.filter((e) => e !== key);
	} else {
		return [key, ...obj.slice(0, 2)];
	}
}
