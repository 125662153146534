import MailAccountManagementComponent from 'components/desktop/StandingData/MailAccount/Management/MailAccountManagementComponent';

const MailAccountManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<MailAccountManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default MailAccountManagementContainer;
