import BaseIngredientCategoryManagementComponent from 'components/desktop/StandingData/BaseIngredientCategory/Management/BaseIngredientCategoryManagementComponent';

const BaseIngredientCategoryManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<BaseIngredientCategoryManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default BaseIngredientCategoryManagementContainer;
