import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';

export type IngredientCategoryPoint = {
	ingredientCategoryId: string;
	pointType: NutriScoreCategoryIngredientCategory;
	ingredientCategoryName: string | null;
	isPositive: boolean;
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};

export const defaultIngredientCategoryPoint = (): IngredientCategoryPoint => {
	return {
		ingredientCategoryId: '',
		pointType: NutriScoreCategoryIngredientCategory.StandardFood,
		ingredientCategoryName: null,
		isPositive: false,
		points: [],
	};
};
