import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductList from 'classes/Recipe/Detail/_general/ProductList';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import ProductCard from 'components/desktop/_general/Card/ProductCard/ProductCard';
import CardSlider, { SliderCard } from 'components/desktop/_general/CardSlider/CardSlider';
import { Match } from 'components/desktop/_general/Conditional/Match';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import * as RecipeProduct from 'types/Product/RecipeProduct';

export const IngredientsProductSlider = ({ products }: { products: RecipeProduct.Type[] }) => {
	const { t } = useTranslation();

	const [cleanProducts, setCleanProducts] = useState<RecipeProduct.Type[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (!products.length) {
			setIsLoading(false);
			return;
		}
		const productList = new ProductList(setNewState, null);
		productList.initialize(products);
	}, [products]);

	const setNewState = (input: RecipeProduct.Type[]): void => {
		setCleanProducts(input);
		setIsLoading(false);
	};

	const getContent = (): JSX.Element => {
		if (isLoading) return <LoadingSpinner />;
		return (
			<Match conditions={[cleanProducts.length === 0]}>
				<div style={{ textAlign: 'center' }}>
					<h2>{t('recipe:NO_SIMILAR_PRODUCTS_FOUND')}</h2>
				</div>
				<CardSlider
					cards={cleanProducts.map(
						(x) =>
							({
								content: <ProductCard product={x} />,
							}) as SliderCard
					)}
				></CardSlider>
			</Match>
		);
	};

	return (
		<FoldableCard
			defaultOpen={true}
			title={t('recipe:PRODUCTS')}
			ai={true}
			content={getContent()}
		/>
	);
};
