import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Nutrient/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	source: string | null;
	id: string;
	isMacroNutrient: boolean;
	sortOrder: number;
	unit: Unit;
	nutrientCategory: NutrientCategory;
	nameTranslations: {
		[key: string]: string;
	};
	shortNameTranslations: {
		[key: string]: string;
	};
};

export type Unit = {
	id: string;
	name: string;
};

export type NutrientCategory = {
	id: string;
	name: string;
	source: string | null;
};
