import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiRecipeImageSearch from 'api/aiFunctions/PostSimilarRecipesByImageV1';
import * as apiGetMenuIdV1 from 'api/menu/GetMenuIdV1';
import * as apiPutMenuV1 from 'api/menu/PutMenuV1';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/Recipe/Search/Recipe';
import RecipeList from 'classes/Recipe/Search/RecipeList';
import Search from 'classes/Recipe/Search/Search';
import RecipeAdvancedSearch from 'components/desktop/Recipe/AdvancedSearch/AdvancedSearch';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DropdownPageSize from 'components/desktop/_general/DropdownPageSize/DropdownPageSize';
import DropdownSortOrder from 'components/desktop/_general/DropdownSortOrder/DropdownSortOrder';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NavigationPillsButtons from 'components/desktop/_general/NavigationPills/NavigationPillsButtons';
import NavigationPillsContent from 'components/desktop/_general/NavigationPills/NavigationPillsContent';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import {
	AirListRowDiscriminatorName,
	IAirListRow,
} from 'components/desktop/_general/SearchResultList/IAirListRow';
import SearchResultList from 'components/desktop/_general/SearchResultList/SearchResultList';
import SearchResultThumbnail, {
	ThumbnailCard,
} from 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnail';
import { numberOfRecommendations } from 'constants/imageSearchNumberOfRecommendations';
import { RecipeSortOrder } from 'enums/SortOrder/RecipeSortOrder';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import clone from 'functions/clone';
import { getPicturePath } from 'functions/getPicturePath';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import { mapToSaveMenuDto } from 'functions/mappers/Menu/mapToSaveMenuDto';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 10;

const RecipeSearchComponent = (props: IProps) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [recipeList, setRecipeList] = useState<RecipeList>(new RecipeList());
	const [search, setSearch] = useState<Search>(new Search());
	const [renderRanges, setRenderRanges] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	const params = useParams();
	const navigate = useNavigate();

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);

	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		await search.initialize();
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			openAdvancedSearch();
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			await getRecipes();
		} else {
			await getRecipes();
		}
		setSearch(clone(search));
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getRecipes();
	};

	const hadleChangeAdvancedSearchUrl = () => {
		search.pageIndex.value = 0;
		handleChangeUrl();
	};

	const openAdvancedSearch = () => {
		const advancedSearchButton = document.getElementById('advancedSearchButton');
		if (advancedSearchButton) advancedSearchButton.click();
	};

	const getRecipes = async (): Promise<void> => {
		setIsLoading(true);
		const response = await recipeList.callApi(search.mapToRequest());
		response.do((x) => {
			recipeList.mapFromApi(x);
			setRecipeList(clone(recipeList));
			search.mapFromApi(x);
		});
		setIsLoading(false);
	};

	const handleReset = () => {
		search.reset();
		setSearchTerm('');
		handleChangeUrl();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangeSortOrder = (value: string) => {
		search.sortOrder.value = value as RecipeSortOrder;
		handleChangeUrl();
	};

	const handleChangePageSize = (value: string): void => {
		search.pageSize.value = Number(value);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const getSuggestedRecipes = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await recipeList.search(searchTerm, props.archive ?? false);
		} else {
			recipeList.filtered = [];
		}
		setRecipeList(clone(recipeList));
		return recipeList.filtered;
	};

	const advancedSearchContainer = document.getElementById('advancedSearch');
	if (advancedSearchContainer) {
		advancedSearchContainer.addEventListener('shown.bs.collapse', function () {
			setRenderRanges(true);
		});
		advancedSearchContainer.addEventListener('hidden.bs.collapse', function () {
			setRenderRanges(false);
		});
	}

	const isDefaultView = (descriptor: string): boolean => {
		const defaultView: string | null = localStorage.getItem('recipe-search-view');
		if (descriptor === defaultView) {
			return true;
		}
		if (defaultView === null) {
			if (descriptor === 'list') {
				return true;
			}
		}
		return false;
	};

	const handleRestoreRecipe = async (recipe: Recipe): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('recipe:RESTORE_TITLE'),
			text: t('recipe:RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await recipe.callApiRestore();
				await Swal.fire({
					title: t('recipe:RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
			} finally {
				await initialize();
				setIsLoading(false);
			}
		}
	};

	const handleArchiveRecipe = async (recipe: Recipe): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('recipe:ARCHIVE_TITLE'),
			text: t('recipe:ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await recipe.callApiDelete();
				await Swal.fire({
					title: t('recipe:ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
			} finally {
				await initialize();
				setIsLoading(false);
			}
		}
	};

	const getSearchResultListData = (): IAirListRow[] => {
		return recipeList.all.map((recipe: Recipe) => {
			return {
				allergens: recipe.allergenList.all,
				archive: props.archive ?? false,
				author: recipe.lastEditor,
				buttons: renderButtons(recipe),
				calories: recipe.calories,
				carbonDioxideColor: recipe.carbonDioxideLabel?.color ?? '',
				carbonDioxideLabel: recipe.carbonDioxideLabel?.label ?? '',
				currency: reduxCurrency,
				dateLastEdited: recipe.lastEditedDateUtc,
				discriminator: AirListRowDiscriminatorName,
				hasNutriScore: recipe.hasNutriScore,
				href: `/recipe/detail/${recipe.id}`,
				id: recipe.id,
				imageUrl: getPicturePath(recipe.image, 348.5),
				nutriScore: recipe.nutriScore,
				prepareTime: recipe.prepareTime,
				price: recipe.price,
				seasonalityCode: getSeasonalityCode(recipe.seasonList.all),
				status: recipe.status?.name || '',
				statusColor: recipe.status?.color || '',
				tags: recipe.tagList.all,
				title: recipe.title,
				valueScope: reduxUserSetting['Recipe.List.ValueScope'] as ValueScope,
			};
		});
	};

	const handleAddToMenuOrMenuPlan = async (id: string): Promise<void> => {
		if (params.menuId) {
			await handleAddToMenu(id);
		}
		if (params.menuPlanId) {
			await handleAddToMenuPlan(id);
		}
	};

	const handleAddToMenu = async (id: string): Promise<void> => {
		if (params.menuId) {
			const menu = await apiGetMenuIdV1.callApi(params.menuId);
			if (menu.hasValue()) {
				const postMenu = mapToSaveMenuDto(menu.get());
				postMenu.recipes.push(id);
				await apiPutMenuV1.callApi(params.menuId, postMenu);
			}
		}

		navigate(`/menu/detail/${params.menuId}`);
	};

	const handleAddToMenuPlan = async (id: string): Promise<void> => {
		const menuPlanId: string | undefined = params.menuPlanId;
		const rowIndex: string | undefined = params.menuPlanRowIndex;
		const day: string | undefined = params.menuPlanDay;
		if (menuPlanId && rowIndex && day) {
			const menuPlan = new MenuPlan();
			await menuPlan.callApiGet(menuPlanId);
			menuPlan.setRecipeToRow(Number(day), Number(rowIndex), id);
			await menuPlan.saveToApi();
		}
		navigate(`/menuPlan/detail/${params.menuPlanId}`);
	};

	const createClickFunction = (id: string): (() => void) => {
		return () => {
			navigate(`/recipe/detail/${id}`);
		};
	};

	const handleCopyRecipe = (id: string): (() => void) => {
		return () => {
			navigate(`/recipe/copy/${id}`);
		};
	};

	const handleImageSearch = async (input: FormData | null) => {
		if (input) {
			setIsLoading(true);
			const response = await apiRecipeImageSearch.callApi(input, numberOfRecommendations);
			if (response.hasValue()) {
				search.recipeIds = response.get();
				search.sortOrder.value = RecipeSortOrder.RecipeFilterOrder;
				handleChangeUrl();
			}
		}
	};

	const getButtonDataRestore = (recipe: Recipe): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreRecipe(recipe),
			};
		}
	};

	const getButtonDataCopy = (id: string): BtnData | undefined => {
		if (!props.archive && !params.menuId && !params.menuPlanId && recipeEditor) {
			return {
				onClick: handleCopyRecipe(id),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive && !params.menuId && !params.menuPlanId && recipeEditor) {
			return {
				onClick: createClickFunction(id),
			};
		}
	};

	const getButtonDataView = (id: string): BtnData | undefined => {
		if (!props.archive && !params.menuId && !params.menuPlanId && !recipeEditor) {
			return {
				onClick: createClickFunction(id),
			};
		}
	};

	const getButtonDataDelete = (recipe: Recipe): BtnData | undefined => {
		if (!props.archive && !params.menuId && !params.menuPlanId && recipeEditor) {
			return {
				onClick: () => handleArchiveRecipe(recipe),
			};
		}
	};

	const getButtonDataAdd = (id: string): BtnData | undefined => {
		if (!props.archive) {
			if (params.menuId || params.menuPlanId) {
				return {
					onClick: () => handleAddToMenuOrMenuPlan(id),
				};
			}
		}
	};

	const renderButtons = (recipe: Recipe): JSX.Element => {
		return (
			<BtnGroup
				add={getButtonDataAdd(recipe.id)}
				copy={getButtonDataCopy(recipe.id)}
				delete={getButtonDataDelete(recipe)}
				edit={getButtonDataEdit(recipe.id)}
				restore={getButtonDataRestore(recipe)}
				view={getButtonDataView(recipe.id)}
			/>
		);
	};

	const getSearchResultThumbnailData = (): ThumbnailCard[] => {
		return recipeList.all.map((recipe: Recipe) => {
			return {
				archive: props.archive ?? false,
				author: recipe.lastEditor,
				buttons: renderButtons(recipe),
				calories: recipe.calories,
				currency: reduxCurrency,
				dateLastEdited: recipe.lastEditedDateUtc,
				href: `/recipe/detail/${recipe.id}`,
				id: recipe.id,
				imageUrl: getPicturePath(recipe.image, 348.5),
				prepareTime: recipe.prepareTime,
				price: recipe.price,
				status: recipe.status?.name || '',
				statusColor: recipe.status?.color || '',
				title: recipe.title,
				valueScope: reduxUserSetting['Recipe.List.ValueScope'] as ValueScope,
			};
		});
	};

	const renderTitle = (): JSX.Element => {
		if (props.archive) {
			return <h1>{t('_general:RECIPE_ARCHIVE')}</h1>;
		} else {
			if (params.menuId || params.menuPlanId) {
				return <h1>{t('menu:ADD_RECIPE')}</h1>;
			} else {
				return <h1>{t('_general:SEARCH_RECIPE')}</h1>;
			}
		}
	};

	return (
		<main className="container">
			<div className="d-flex justify-content-between">
				{renderTitle()}
				<RenderIf
					condition={
						!props.archive && !params.menuId && !params.menuPlanId && recipeEditor
					}
				>
					<BtnNew link={'/recipe/new'} name={t('recipe:CREATE_RECIPE')} />
				</RenderIf>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-6">
					<div className="input-group mb-3">
						<AdvancedSearchInput
							debounceMilliSeconds={350}
							getSuggestedItems={getSuggestedRecipes}
							handleImageSearch={handleImageSearch}
							handleSearch={handleSearch}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							triggerSearchLetterAmount={1}
						/>
					</div>
				</div>
				<div className="col-lg-3">
					<button
						id="advancedSearchButton"
						className="btn btn-primary"
						type="button"
						onClick={() => setRenderRanges(true)}
						data-bs-toggle="collapse"
						data-bs-target="#advancedSearch"
						aria-expanded="true"
						aria-controls="advancedSearch"
					>
						{t('_general:ADVANCED_FILTER')}
					</button>
				</div>
				<div className="col-lg-3 text-end">
					<div className="d-flex flex-row justify-content-end">
						<DropdownPageSize
							className="me-2"
							listFunction={handleChangePageSize}
							currentValue={String(search.getPageSize())}
						/>
						<DropdownSortOrder
							type={'recipe'}
							className="me-3"
							listFunction={handleChangeSortOrder}
							currentValue={search.getSortOrder()}
							showRelevance={Boolean(search.recipeIds.length)}
						/>
						<NavigationPillsButtons
							className="justify-content-end"
							button1={{
								icon: <i className="bi bi-border-all"></i>,
								descriptor: 'list',
								default: isDefaultView('list'),
							}}
							button2={{
								icon: <i className="bi bi-list-task"></i>,
								descriptor: 'thumbnail',
								default: isDefaultView('thumbnail'),
							}}
							localStorageItemName="recipe-search-view"
						/>
					</div>
				</div>
			</div>
			<div className={'collapse'} id="advancedSearch">
				<div className="card card-body mb-3">
					<RecipeAdvancedSearch
						disabled={recipeList.totalCount === 0}
						search={search}
						setSearch={setSearch}
						handleChangeUrl={hadleChangeAdvancedSearchUrl}
						handleReset={handleReset}
						renderRanges={renderRanges}
					/>
				</div>
			</div>
			<LoadingAnimation isLoading={isLoading} />
			<p>{`${recipeList.totalCount} ${t('_general:RECIPES')}`}</p>

			<NavigationPillsContent
				isLoading={isLoading}
				content1={{
					content: <SearchResultList data={getSearchResultListData()} />,
					descriptor: 'thumbnail',
					default: isDefaultView('thumbnail'),
				}}
				content2={{
					content: <SearchResultThumbnail data={getSearchResultThumbnailData()} />,
					descriptor: 'list',
					default: isDefaultView('list'),
				}}
			/>
			<Pagination
				countOfAllListItems={recipeList.totalCount}
				pageIndex={search.getPageIndex()}
				setPageIndex={handleChangePageIndex}
				listItemAmount={search.getPageSize()}
				maxNumberOfButtons={maxNumberOfButtons}
			/>
		</main>
	);
};

export default RecipeSearchComponent;
