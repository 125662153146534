import { ContextBuilder } from 'context/nutriscoreLabelContext';

import IngredientComponent from 'components/desktop/Ingredient/IngredientComponent';

const IngredientNewContainer = () => {
	return (
		<main className="container">
			<IngredientComponent />
		</main>
	);
};

export default ContextBuilder.From(IngredientNewContainer).wrapWithNutriscoreContext().build();
