import * as apiDelete from 'api/nutriScoreLabel/DeleteNutriScoreLabelIdV1';
import * as apiRestore from 'api/nutriScoreLabel/PostNutriScoreLabelRestoreV1';
import * as apiPostSearch from 'api/nutriScoreLabel/PostNutriScoreLabelSearchV1';

export default class NutriScoreLabel {
	public id: string = '';
	public isFluid: boolean = false;
	public label: string = '';
	public color: string = '';
	public rank: number = 0;

	public constructor(input?: apiPostSearch.ResponseNutriScoreLabel) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseNutriScoreLabel) {
		this.id = input.id;
		this.isFluid = input.isFluid;
		this.label = input.label;
		this.color = input.color;
		this.rank = input.rank;
	}
}
