import * as apiPostSearch from 'api/product/PostProductSearchV1';
import UrlParams from 'enums/Product/Search/UrlParams';
import { ProductSortOrder } from 'enums/SortOrder/ProductSortOrder';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';
import * as Co2LabelList from 'types/Product/Search/Co2LabelList';
import * as MultiSelect from 'types/Product/Search/MultiSelect';
import * as Slider from 'types/Product/Search/Slider';
import * as StringList from 'types/Product/Search/StringList';

const defaultPageSize = 20;
const defaultPageIndex = 0;
const defaultSortOrder = ProductSortOrder.NameAsc;

export type Type = {
	co2LabelIds: StringList.Type;
	pageIndex: number;
	pageSize: number;
	searchTerm: string | null;
	selectCo2Label: MultiSelect.Type;
	sliderCo2: Slider.Type;
	sliderPrice: Slider.Type;
	sortOrder: ProductSortOrder;
	ingredientId: string | null;
};

export function create(): Type {
	return {
		co2LabelIds: StringList.create(),
		pageIndex: defaultPageIndex,
		pageSize: defaultPageSize,
		searchTerm: null,
		selectCo2Label: MultiSelect.create(),
		sliderCo2: Slider.create(),
		sliderPrice: Slider.create(),
		sortOrder: defaultSortOrder,
		ingredientId: null,
	};
}

export function mapToApi(obj: Type): apiPostSearch.Request {
	return {
		carbonDioxideLabelIds: obj.co2LabelIds,
		carbonDioxideRange: Slider.mapToApi(obj.sliderCo2),
		pageIndex: obj.pageIndex,
		pageSize: obj.pageSize,
		priceRange: Slider.mapToApi(obj.sliderPrice),
		searchTerm: obj.searchTerm ?? undefined,
		sortOrder: obj.sortOrder,
	};
}

export function getFromUrl(p: AirUrlParamsClass): Type {
	const params = p.params;
	const priceSlider = Slider.create();
	const co2Slider = Slider.create();
	priceSlider.id = 'pr';
	co2Slider.id = 'co2';
	const co2LabelSelect = MultiSelect.create();
	return {
		pageIndex: params.pi ? Number(params.pi) : defaultPageIndex,
		pageSize: params.ps ? Number(params.ps) : defaultPageSize,
		searchTerm: params.st ? params.st : '',
		selectCo2Label: MultiSelect.getFromUrl(params, co2LabelSelect),
		sliderCo2: Slider.getFromUrl(params, co2Slider, UrlParams.Co2),
		sliderPrice: Slider.getFromUrl(params, priceSlider, UrlParams.Price),
		sortOrder: params.so as ProductSortOrder,
		co2LabelIds: Co2LabelList.mapFromUrl(params),
		ingredientId: params.iId ? params.iId : '',
	};
}

export function reset(ingredientId: string | null): AirUrlParamsClass {
	let params = new AirUrlParamsClass({});
	if (ingredientId) params = params.add(UrlParams.IngredientId, ingredientId);
	return params;
}
