import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import ColumnComponent from 'components/desktop/MenuPlan/Detail/ColumnComponent';
import ItemComponent from 'components/desktop/MenuPlan/Detail/ItemComponent';
import RowInformationComponent from 'components/desktop/MenuPlan/Detail/RowInformationComponent';
import PriceCaloriesRendering from 'components/desktop/MenuPlan/PriceCaloriesRendering/PriceCaloriesRendering';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { AccessoryLight } from 'types/Accessory/AccessoryLight';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { IngredientLight } from 'types/Ingredient/IngredientLight';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { SeasonLight } from 'types/Season/SeasonLight';
import { GetAllStatusDto } from 'types/Status/GetAllStatusDto';
import { TagLight } from 'types/Tag/TagLight';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';

interface IProps {
	accessories: AccessoryLight[];
	allergens: AllergenLight[];
	co2Labels: Co2Label[];
	currency: string;
	getSelectedItemFromApi: (input: Item) => Promise<void>;
	id?: string;
	ingredients: IngredientLight[];
	menuPlan: MenuPlan;
	nutriScoreLabels: NutriScoreLabel[];
	search: Search.Type;
	seasons: SeasonLight[];
	selectedItem: Recipe | Menu | Row | Item | Column | null;
	setMenuPlan: (input: MenuPlan) => void;
	setSearch: Dispatch<SetStateAction<Search.Type>>;
	setSelectedItem: (input: Row | Item | Column | null) => void;
	status: GetAllStatusDto[];
	tags: TagLight[];
}

const RowListComponent = (props: IProps) => {
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	const rowHeight: number = 300;
	const rowItemWidth: number = 190;

	const renderDate = (input: Date): JSX.Element => {
		return (
			<div style={{ width: rowItemWidth + 'px' }}>
				<label>
					<FormatedDate format={'dddd MMM DD'} date={input} />
				</label>
			</div>
		);
	};

	const handleRemoveItem = async (day: number, rowId: string): Promise<void> => {
		props.menuPlan.rowList.removeItem(day, rowId);
		await props.menuPlan.recalculateColumnsAndRows();
		props.setMenuPlan(clone(props.menuPlan));
	};

	const addRow = (): void => {
		props.menuPlan.addRow();
		props.setMenuPlan(clone(props.menuPlan));
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<div
					className="d-flex flex-column"
					style={{ overflow: 'auto', gap: '5px', marginRight: '5px' }}
				>
					<div className="d-flex" style={{ gap: '5px' }}>
						{props.menuPlan.getDateArray().map((e: Date, i: number) => {
							return <div key={i}>{renderDate(e)}</div>;
						})}
					</div>
					{props.menuPlan.rowList.all.map((row: Row, rowIndex: number) => {
						return (
							<div key={rowIndex} className="d-flex" style={{ gap: '5px' }}>
								{props.menuPlan.getDateArray().map((_date: Date, day: number) => {
									return (
										<div key={day}>
											<ItemComponent
												id={props.id}
												day={day}
												rowIndex={rowIndex}
												menuPlan={props.menuPlan}
												setMenuPlan={props.setMenuPlan}
												selectedItem={props.selectedItem}
												setSelectedItem={props.setSelectedItem}
												handleRemoveItem={handleRemoveItem}
												getSelectedItemFromApi={
													props.getSelectedItemFromApi
												}
												item={props.menuPlan.getItemFromRow(day, rowIndex)}
												row={row}
												rowHeight={rowHeight}
												rowItemWidth={rowItemWidth}
											/>
										</div>
									);
								})}
							</div>
						);
					})}
					<div className="d-flex" style={{ gap: '5px' }}>
						{props.menuPlan.getDateArray().map((_e: Date, i: number) => {
							return (
								<ColumnComponent
									rowItemWidth={rowItemWidth}
									selectedItem={props.selectedItem}
									setSelectedItem={props.setSelectedItem}
									column={props.menuPlan.columnList.all[i]}
									key={i}
								/>
							);
						})}
					</div>
				</div>
				<div className="d-flex flex-column" style={{ gap: '5px' }}>
					<div style={{ height: '24px' }}></div>
					{props.menuPlan.rowList.all.map((e: Row, i: number) => {
						return (
							<RowInformationComponent
								accessories={props.accessories}
								allergens={props.allergens}
								co2Labels={props.co2Labels}
								currency={props.currency}
								ingredients={props.ingredients}
								key={i}
								menuPlan={props.menuPlan}
								nutriScoreLabels={props.nutriScoreLabels}
								row={e}
								rowHeight={rowHeight}
								rowIndex={i}
								search={props.search}
								seasons={props.seasons}
								selectedItem={props.selectedItem}
								setMenuPlan={props.setMenuPlan}
								setSearch={props.setSearch}
								setSelectedItem={props.setSelectedItem}
								status={props.status}
								tags={props.tags}
							/>
						);
					})}
					<div style={{ backgroundColor: '#ccc', width: '200px', padding: '10px' }}>
						<PriceCaloriesRendering
							costs={props.menuPlan.getPricePerPerson()}
							costsTotal={props.menuPlan.getPriceTotal()}
							calories={props.menuPlan.getCaloriesPerPerson()}
							constraintStatusCosts={props.menuPlan.getConstraintStatusPrice()}
							constraintStatusCalories={props.menuPlan.getConstraintStatusCalories()}
						/>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<button
					type="button"
					className={['btn', 'btn-primary', !menuPlanEditor ? 'disabled' : ''].join(' ')}
					onClick={addRow}
					style={{ width: '100%' }}
				>
					<FontAwesomeIcon icon={faPlus as IconProp} style={{ marginRight: '8px' }} />
					{t('menuPlan:NEW_ROW')}
				</button>
			</div>
		</>
	);
};

export default RowListComponent;
