import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';

import ENTITLEMENTS from 'enums/entitlements';
import { Optional } from 'functions/promiseExtensions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import useCultureCode from 'hooks/redux/useCultureCode';
import * as AllergenList from 'types/_general/Store/AllergenList';
import * as CategoryList from 'types/_general/Store/CategoryList';
import * as Co2LabelList from 'types/_general/Store/Co2LabelList';
import * as NutrientList from 'types/_general/Store/NutrientList';
import * as ProductRanges from 'types/_general/Store/ProductRanges';
import * as SeasonList from 'types/_general/Store/SeasonList';
import * as TagList from 'types/_general/Store/TagList';
import * as UnitList from 'types/_general/Store/UnitList';

// --------------------------------------------------------------------------------------------------------
// THESE HOOKS HAVE NOTHING TO DO WITH THE STORE, CAN BE RE-USED SOMEWHERE ELSE.
// --------------------------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------------------------
// INFRASTRUCTURE code........................
// --------------------------------------------------------------------------------------------------------

type Meta<T> = {
	payload: Optional<T>;
	language: string;
};

type StoreKeyType<T> = [Meta<T>, Dispatch<SetStateAction<Meta<T>>>];

export function useKeyState<T>(): StoreKeyType<T> {
	return useState<Meta<T>>({ payload: Optional.None(), language: 'default' });
}

export type Store = {
	allergens: StoreKeyType<AllergenList.Type>;
	co2Labels: StoreKeyType<Co2LabelList.Type>;
	seasons: StoreKeyType<SeasonList.Type>;
	tags: StoreKeyType<TagList.Type>;
	units: StoreKeyType<UnitList.Type>;
	nutrients: StoreKeyType<NutrientList.Type>;
	categories: StoreKeyType<CategoryList.Type>;
	productRanges: StoreKeyType<ProductRanges.Type>;
};

export const StoreContext = createContext<Store | null>(null);

export function useStoreHook<T>(
	f: (s: Store) => StoreKeyType<T>,
	g: () => Promise<T>
): Optional<T> {
	const store: Store | null = useContext(StoreContext);
	if (!store) {
		return Optional.None();
	}
	const [val, setter] = f(store);
	const cultureCode: string = useCultureCode();

	useEffect(() => {
		const f = async () => {
			const result = await g();
			const x = {
				payload: Optional.Just(result),
				language: cultureCode,
			};
			setter(x);
		};
		if (val.language !== cultureCode) f();
	}, [cultureCode]);

	return val.payload;
}

export function useStoreJustOnceInTheWholeApp() {
	return {
		allergens: useKeyState<AllergenList.Type>(),
		co2Labels: useKeyState<Co2LabelList.Type>(),
		productRanges: useKeyState<ProductRanges.Type>(),
		seasons: useKeyState<SeasonList.Type>(),
		tags: useKeyState<TagList.Type>(),
		units: useKeyState<UnitList.Type>(),
		nutrients: useKeyState<NutrientList.Type>(),
		categories: useKeyState<CategoryList.Type>(),
	};
}

// --------------------------------------------------------------------------------------------------------
// CUSTOM HOOKS BASED ON CONTEXT
// --------------------------------------------------------------------------------------------------------

export function useSeasons() {
	if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON])) return [];
	const val = useStoreHook((s) => s.seasons, SeasonList.getFromApi);
	return val.getOrDefault([]);
}

export function useAllergens() {
	const val = useStoreHook((s) => s.allergens, AllergenList.getFromApi);
	return val.getOrDefault([]);
}

export function useTags() {
	const val = useStoreHook((s) => s.tags, TagList.getFromApi);
	return val.getOrDefault([]);
}

export function useUnits() {
	const val = useStoreHook((s) => s.units, UnitList.getAllFromApi);
	return val.getOrDefault([]);
}

export function useNutrients() {
	const val = useStoreHook((s) => s.nutrients, NutrientList.getFromApi);
	return val.getOrDefault([]);
}

export function useCategories() {
	const val = useStoreHook((s) => s.categories, CategoryList.getFromApi);
	return val.getOrDefault([]);
}

export function useCo2Labels() {
	const value = useStoreHook((store) => store.co2Labels, Co2LabelList.getFromApi);
	return value.getOrDefault([]);
}

export function useProductRanges() {
	const value = useStoreHook((store) => store.productRanges, ProductRanges.getFromApi);
	return value.getOrDefault(ProductRanges.create());
}

// --------------------------------------------------------------------------------------------------------
