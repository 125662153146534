import * as RecipeProduct from 'types/Product/RecipeProduct';

type ProductWithImgState = RecipeProduct.Type & {
	imgState?: 'good' | 'bad';
};

export default class ProductList {
	maxProducts: number | null = null;
	list: ProductWithImgState[] = [];
	externFunction: (input: RecipeProduct.Type[]) => void;

	public constructor(
		externFunction: (input: RecipeProduct.Type[]) => void,
		maxProducts: number | null
	) {
		this.externFunction = externFunction;
		this.maxProducts = maxProducts;
	}

	public initialize(products: RecipeProduct.Type[]) {
		this.list = products.distinctBy((e) => e.id);
		this.initList();
	}

	private reactIfListIsInitialized() {
		if (!this.isListInitialized()) return;
		this.externFunction(this.mapListToProduct());
	}

	private mapListToProduct(): RecipeProduct.Type[] {
		if (this.maxProducts !== null) {
			return this.list
				.filter((e) => e.imgState === 'good')
				.map((e) => {
					delete e.imgState;
					return e;
				})
				.slice(0, this.maxProducts);
		}
		return this.list
			.filter((e) => e.imgState === 'good')
			.map((e) => {
				delete e.imgState;
				return e;
			});
	}

	private checkImage(product: ProductWithImgState) {
		if (product.imageUrl === null) return;
		const img = new Image();
		img.onload = () => {
			product.imgState = 'good';
			this.reactIfListIsInitialized();
		};
		img.onerror = () => {
			product.imgState = 'bad';
			this.reactIfListIsInitialized();
		};
		img.src = product.imageUrl;
	}

	private initList() {
		for (const i of this.list) {
			if (i.imageUrl === null) {
				i.imgState = 'bad';
				continue;
			}
			this.checkImage(i);
		}
	}

	private isListInitialized(): boolean {
		const firstProductWithoutImgState: ProductWithImgState | undefined = this.list.find(
			(e) => e.imgState !== 'good' && e.imgState !== 'bad'
		);
		return Boolean(!firstProductWithoutImgState);
	}
}
