import * as apiDelete from 'api/nutrient/DeleteNutrientIdV1';
import * as apiRestore from 'api/nutrient/PostNutrientRestoreV1';
import * as apiPostSearch from 'api/nutrient/PostNutrientSearchV1';

export default class Nutrient {
	public id: string = '';
	public name: string = '';
	public shortName: string = '';
	public unit: string = '';
	public isMacroNutrient: boolean = false;
	public nutrientCategory: string = '';

	public constructor(input?: apiPostSearch.ResponseNutrient) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseNutrient) {
		this.id = input.id;
		this.name = input.name;
		this.shortName = input.shortName;
		this.unit = input.unit;
		this.isMacroNutrient = input.isMacroNutrient;
		this.nutrientCategory = input.nutrientCategory;
	}
}
