import Archive from 'containers/desktop/StandingData/Channel/Archive/ChannelArchive';
import Management from 'containers/desktop/StandingData/Channel/Management/ChannelManagement';
import PERMISSIONS from 'enums/permissions';

export const channelRoutes = [
	{
		path: '/channel/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/channel/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
