import Archive from 'containers/desktop/StandingData/IngredientCategory/Archive/IngredientCategoryArchive';
import Management from 'containers/desktop/StandingData/IngredientCategory/Management/IngredientCategoryManagement';
import NutriScoreIngredientCategoryManagement from 'containers/desktop/StandingData/IngredientCategoryPoints/NutriScoreIngredientCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const ingredientCategoryRoutes = [
	{
		path: '/ingredientCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/ingredientCategory/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/ingredientCategoryPoints',
		element: <NutriScoreIngredientCategoryManagement />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
