import * as apiGet from 'api/recipe/GetRecipeIdV1';
import AllergenList from 'classes/MenuPlan/Detail/AllergenList';
import CarbonDioxideLabel from 'classes/MenuPlan/Detail/CarbonDioxideLabel';
import Image from 'classes/MenuPlan/Detail/Image';
import NutrientList from 'classes/MenuPlan/Detail/NutrientList';
import SeasonList from 'classes/MenuPlan/Detail/SeasonList';
import Status from 'classes/MenuPlan/Detail/Status';
import TagList from 'classes/MenuPlan/Detail/TagList';
import { DoughnutData } from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import { kiloCaloriesToKilojouleFactor } from 'constants/kiloCaloriesToKilojouleFactor';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Recipe {
	public id: string = '';
	public allergenList: AllergenList = new AllergenList();
	public calories: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public carbonDioxideValue: number = 0;
	public hasNutriScore: boolean = false;
	public image: Image = new Image();
	public parts: number | null = null;
	public persons: number | null = null;
	public price: number | null = null;
	public seasonList: SeasonList = new SeasonList();
	public status: Status = new Status();
	public systemStatus: SystemStatus = 'New';
	public tagList: TagList = new TagList();
	public titleTranslations: LocalizedTranslation<string> | null = null;
	public weight: number | null = null;
	public nutrientList: NutrientList = new NutrientList();

	public async callApiGet(id?: string): Promise<void> {
		const response = await apiGet.callApi(id ?? this.id);
		response.hasValue() && this.mapFromApi(response.get());
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.allergenList.mapFromApi(input.allergens);
		this.calories = input.calories ?? input.ingredientCalories;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.hasNutriScore = input.hasNutriScore;
		this.image.mapFromApi(input.image);
		this.parts = input.parts;
		this.persons = input.persons;
		this.price = input.price;
		this.seasonList.mapFromApi(input.seasons);
		this.status.mapFromApi(input.status);
		this.systemStatus = input.systemStatus;
		this.tagList.mapFromApi(input.tags);
		this.titleTranslations = input.titleTranslations;
		this.weight = input.weight ?? input.ingredientWeight;
		this.nutrientList.mapFromApi(input.nutrientValues);
	}

	public getNutrientPerPart(nutrientValue: number): number {
		if (this.parts) {
			return nutrientValue / this.parts;
		} else {
			return 0;
		}
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		if (this.persons) {
			return nutrientValue / this.persons;
		} else {
			return 0;
		}
	}

	public getNutrientTotal(nutrientValue: number): number {
		return nutrientValue;
	}

	public getCaloriesPerPart(convertToKilojoule: boolean): number {
		if (!this.parts) return 0;
		if (!this.calories) return 0;
		if (this.parts <= 0) return 0;

		let result = this.calories / this.parts;
		if (convertToKilojoule) {
			result = result * kiloCaloriesToKilojouleFactor;
		}
		return result;
	}

	public getCaloriesPerPerson(convertToKilojoule: boolean): number {
		if (!this.persons) return 0;
		if (!this.calories) return 0;
		if (this.persons <= 0) return 0;

		let result = this.calories / this.persons;
		if (convertToKilojoule) {
			result = result * kiloCaloriesToKilojouleFactor;
		}
		return result;
	}

	public getCaloriesPer100Gramm(convertToKilojoule: boolean): number {
		if (!this.weight) return 0;
		if (!this.calories) return 0;
		if (this.weight <= 0) return 0;

		let result = (this.calories / this.weight) * 100;
		if (convertToKilojoule) {
			result = result * kiloCaloriesToKilojouleFactor;
		}
		return result;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.nutrientList.all
			.groupBy((x) => x.categoryId)
			.map((_, nutrients) => {
				return {
					name: nutrients[0].category!,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients
						.orderBy((x) => x.nutrient)
						.map((x) => {
							return {
								nutrient: x.nutrient!,
								category: x.category!,
								total: x.total,
								unit: x.unit!,
								isMacroNutrient: x.isMacroNutrient,
								nutrientCategorySortOrder: x.nutrientCategorySortOrder,
							};
						}),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	}

	public getMacroNutrientChartData(): DoughnutData[] {
		return this.nutrientList.getMacroNutrientChartData();
	}
}
