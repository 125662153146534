import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<Tag[]>> {
	const url = '/backend-service/AiFunctions/Suggest/SimilarTags';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	cultureCode: string;
	description: string;
}

export interface Response {
	data: Tag[];
}

export type Tag = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};
