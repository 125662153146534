import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import 'components/desktop/_general/Tabs/Tabs.scss';

export type Tab = {
	name: string;
	content: JSX.Element;
	display?: boolean;
	ai?: boolean;
};

interface IProps {
	tabs: Tab[];
	localStorageKey?: string;
}

const Tabs = (props: IProps) => {
	const [activeTab, setActiveTab] = useState<number>(0);
	const [activeTabName, setActiveTabName] = useState<string>(props.tabs.first().name);

	useEffect(() => {
		if (props.tabs.first().name !== activeTabName) {
			setStateFromActiveTabName();
		} else {
			setStateFromLocalStorage();
		}
	}, [props.tabs.length]);

	const setStateFromActiveTabName = (): void => {
		const newIndex = props.tabs.findIndex((x) => x.name === activeTabName);
		if (newIndex >= 0) {
			setActiveTab(newIndex);
		} else {
			setActiveTab(0);
			setActiveTabName(props.tabs.first().name);
		}
	};

	const setStateFromLocalStorage = (): void => {
		if (!props.localStorageKey) return;
		const value: string | null = localStorage.getItem(props.localStorageKey);
		if (!value) return;
		const initialActiveTab: number = Number(value);
		setActiveTab(initialActiveTab);
		setActiveTabName(props.tabs[initialActiveTab].name);
	};

	const handleClick = (tab: Tab, activeTabNumber: number): void => {
		setActiveTab(activeTabNumber);
		setActiveTabName(tab.name);
		if (props.localStorageKey)
			localStorage.setItem(props.localStorageKey, String(activeTabNumber));
	};

	return (
		<div className="tab-container">
			<nav className="d-flex flex-row flex-fill">
				{props.tabs.map((e: Tab, i: number) => {
					return (
						<div
							key={i}
							onClick={() => handleClick(e, i)}
							className="flex-fill tab-button"
							style={{
								color: i === activeTab ? '#6699cc' : undefined,
								backgroundColor: i !== activeTab ? '#fafafa' : undefined,
								borderBottom: i !== activeTab ? '1px solid #ced4da' : undefined,
								borderRight:
									i === activeTab || i === props.tabs.length - 1
										? '1px solid #ced4da'
										: undefined,
								borderLeft:
									i === activeTab || i === 0 ? '1px solid #ced4da' : undefined,
							}}
						>
							<span>{e.name}</span>
							{e.ai && (
								<span style={{ paddingLeft: '5px' }}>
									<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
								</span>
							)}
						</div>
					);
				})}
			</nav>
			<div>{props.tabs[activeTab].content}</div>
		</div>
	);
};

export default Tabs;
