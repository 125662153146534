import * as api from 'api/menu/PostMenuSearchV1';

export default class CarbonDioxideLabel {
	label: string | null = null;
	color: string | null = null;

	public constructor(input?: api.CarbonDioxideLabel) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.CarbonDioxideLabel | null) {
		if (input) {
			this.label = input.label;
			this.color = input.color;
		}
	}
}
