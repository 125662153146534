import * as apiSW from 'api/recipeCategory/GetRecipeCategoryStartingWithV1';
import * as apiPostSearch from 'api/recipeCategory/PostRecipeCategorySearchV1';
import { ISelectItem } from 'interfaces/ISelectItem';

import RecipeCategory from './RecipeCategory';

export default class RecipeCategoryList {
	public all: RecipeCategory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const recipeCategories: apiSW.ResponseRecipeCategory[] = await this.callApiSW(
			searchTerm,
			showOnlyDeleted
		);
		this.mapFromApiSW(recipeCategories);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseRecipeCategory[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const recipeCategory: RecipeCategory = new RecipeCategory(i);
			this.all.push(recipeCategory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseRecipeCategory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
