import { useParams } from 'react-router-dom';

import AccessoryComponent from 'components/desktop/Accessory/AccessoryComponent';

const AccessoryDetailContainer = () => {
	const { id } = useParams();

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<AccessoryComponent id={id} />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryDetailContainer;
