import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import { ValueScope } from 'enums/valueScope';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import * as Recipe from 'types/Recipe/Print/Recipe';
import Style from 'types/_general/Style';

interface RenderInformationProps {
	recipe: Recipe.Type;
}

const Informations = ({ recipe }: RenderInformationProps) => {
	const reduxUserSetting: ValueScope = ValueScope.Person;

	return (
		<div style={style.wrapper}>
			<div style={style.parent}>
				<SeasonalityBar seasonalityCode={getSeasonalityCode(recipe.seasons)} />
				<div className="d-flex" style={style.co2Display}>
					<Co2Display
						value={recipe.carbonDioxideValue ?? 0}
						color={recipe.carbonDioxideLabel?.color ?? '#888'}
						valueScopeInfo={{
							valueScope: reduxUserSetting,
							persons: recipe.persons,
							parts: recipe.parts,
							weight: recipe.weight ?? recipe.ingredientWeight,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Informations;

const style: Style = {
	parent: {
		transform: 'scale(0.7, 0.7)',
		position: 'absolute',
		left: '-48px',
	},
	co2Display: {
		gap: '10px',
		marginTop: '10px',
	},
	wrapper: {
		position: 'relative',
		width: '100%',
	},
};
