import * as api from 'api/recipe/PostRecipeCalculateV1';

import NutrientValuesPerIngredient from './NutrientValuesPerIngredient';

export default class NutrientValuesPerIngredientList {
	public items: NutrientValuesPerIngredient[] = [];

	public constructor(input?: api.NutrientValuesPerIngredient[]) {
		if (input) this.mapFromApi(input);
	}

	public calcualteByNutrientId(nutrientId: string): number {
		let output: number = 0;
		for (const item of this.items) {
			for (const value of item.nutrientValues) {
				if (value.nutrientId !== nutrientId) continue;
				output = output + value.total;
			}
		}
		return output;
	}

	private mapFromApi(input: api.NutrientValuesPerIngredient[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new NutrientValuesPerIngredient(i));
		}
	}
}
