import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import LoginForm from 'components/desktop/User/Login/LoginForm';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import 'containers/desktop/User/Login/Login.scss';
import { getTokensFromLocalStorage } from 'functions/tokenFunctions';
import { setTokensToLocalStorage } from 'functions/tokenFunctions';
import ITokens from 'interfaces/ITokens';
import { defaultLoginDto } from 'types/User/Login/LoginDto';

const Login = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loginDto, setLoginDto] = useState(defaultLoginDto);
	const [logoNotFound, setLogoNotFound] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect((): void => {
		const tokens = getTokensFromLocalStorage();
		tokens.do((_) => {
			navigateToUrl();
		});
	}, []);

	const navigateToUrl = (): void => {
		const pathname: string | null = localStorage.getItem('pathname');
		localStorage.removeItem('pathname');
		navigate(pathname ?? '/dashboard');
	};

	const handleLogin = async () => {
		setIsLoading(true);
		const url = '/backend-service/User/Login';
		try {
			const response = await axios.post(url, loginDto);
			const tokens: ITokens = response.data;
			setTokensToLocalStorage(tokens);
			if (response.status == 200) {
				navigateToUrl();
			}
			if (response.status == 422) {
				navigate(`/resendConfirmation?user=${loginDto.email}`);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const changeToStandardLogo = (): void => {
		setLogoNotFound(true);
	};

	const renderContent = () => {
		return (
			<>
				<div className="login-container">
					<h2>Artificial Intelligence Recipes</h2>
					<div className="login-inner-container">
						{logoNotFound ? (
							<LogoAnimation />
						) : (
							<img
								src={'/img/logoExt/external_logo_login.png'}
								onError={changeToStandardLogo}
								alt=""
								className="d-inline-block align-text-middle"
								style={{
									maxHeight: '220px',
									maxWidth: '220px',
								}}
							/>
						)}
						<div className="login-inner-container-div">
							<LoginForm
								loginDto={loginDto}
								setLoginDto={setLoginDto}
								handleLogin={handleLogin}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default Login;
