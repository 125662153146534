import { useTranslation } from 'react-i18next';

import RenderPasswordInformation from 'components/desktop/User/RenderPasswordInformation';
import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import { fireAlert } from 'functions/fireAlert';
import isPasswordValid from 'functions/validation/isPasswordValid';

interface IProps {
	password1: string;
	password2: string;
	setPassword1: (password: string) => void;
	setPassword2: (password: string) => void;
	handleResetPassword: () => void;
}

const PasswordResetForm = (props: IProps) => {
	const { t } = useTranslation();

	const validateAllInputs = () => {
		const messageArray: string[] = [];
		var result: boolean = true;

		if (props.password1 && props.password2) {
			if (!isPasswordValid(props.password1)) {
				messageArray.push(
					`<b>${t('user:NEW_PASSWORD')}</b>: ${t('alerts:ALERT_PASSWORD_ERROR_1')}`
				);
				result = false;
			}
			if (props.password1 !== props.password2) {
				messageArray.push(
					`<b>${t('user:NEW_PASSWORD')}</b>: ${t('alerts:ALERT_PASSWORD_ERROR_2')}`
				);
				result = false;
			}
		}
		if (!result) {
			fireAlert(
				2,
				t('alerts:ALERT_VALIDATION_ERROR'),
				messageArray.join('<br /><br />'),
				20000
			);
		}
		return result;
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			handleResetPassword();
		}
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.id == 'password1') {
			props.setPassword1(e.currentTarget.value);
		} else if (e.currentTarget.id == 'password2') {
			props.setPassword2(e.currentTarget.value);
		}
	};

	const handleResetPassword = () => {
		if (validateAllInputs()) {
			props.handleResetPassword();
		}
	};

	const renderContent = () => {
		return (
			<div className="password-recovery-form">
				<div style={{ marginBottom: '12px' }}>
					<RenderPasswordInformation />
				</div>
				<ValidatedInput
					id="password1"
					type="password"
					label={t('user:NEW_PASSWORD')}
					value={props.password1}
					isValid={isPasswordValid}
					onKeyDown={onKeyDown}
					handleValueChange={handleValueChange}
					className="mb-2"
				/>
				<ValidatedInput
					id="password2"
					type="password"
					label={t('user:NEW_PASSWORD_VERIFY')}
					value={props.password2}
					isValid={isPasswordValid}
					onKeyDown={onKeyDown}
					handleValueChange={handleValueChange}
					className="mb-2"
				/>
				<div className="d-grid gap-2">
					<button className="btn btn-primary mb-2" onClick={handleResetPassword}>
						{t('user:RECOVER_PASSWORD')}
					</button>
				</div>
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default PasswordResetForm;
