import { useCo2Labels, useProductRanges } from 'context/Store';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import * as apiGetIngredientIdV1 from 'api/ingredient/GetIngredientIdV1';
import * as apiPutIngredientV1 from 'api/ingredient/PutIngredientIdV1';
import AdvancedSearch from 'components/desktop/ERP/Product/Search/AdvancedSearch/AdvancedSearch';
import SearchResultList from 'components/desktop/ERP/Product/Search/SearchResultList/SearchResultList';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DropdownPageSize from 'components/desktop/_general/DropdownPageSize/DropdownPageSize';
import DropdownSortOrder from 'components/desktop/_general/DropdownSortOrder/DropdownSortOrder';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import UrlParams from 'enums/Product/Search/UrlParams';
import { ProductSortOrder } from 'enums/SortOrder/ProductSortOrder';
import { mapToSaveIngredientDto } from 'functions/mappers/Ingredient/mapToSaveIngredientDto';
import useAirNavigation, { uriToAirUrl } from 'hooks/useAirNavigation';
import { useReaction } from 'hooks/useReaction';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as ProductList from 'types/Product/Search/ProductList';
import * as Search from 'types/Product/Search/Search';
import * as SimpleString from 'types/Product/Search/SimpleString';
import * as Slider from 'types/Product/Search/Slider';
import * as StringList from 'types/Product/Search/StringList';

const maxNumberOfButtons = 10;

const ProductSearchContainer = () => {
	// Store-Stuff
	const initialRanges = useProductRanges();
	const initialCo2Labels = useCo2Labels();

	// Navigation-Stuff
	const { params, navigate } = useAirNavigation();
	const search = useMemo(() => Search.getFromUrl(params), [params.toString()]);
	// useState-Stuff
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm ?? '');

	const [productList, isLoaded] = useReaction<ProductList.Type, Search.Type>(
		ProductList.create(),
		ProductList.getFromApi,
		search
	);

	useEffect(() => {
		if (window.location.href.includes('?')) {
			if (!window.location.href.includes('iId')) openAdvancedSearch();
		}
	}, []);

	const handleSearch = (input?: string): void => {
		if (input) {
			setSearchTerm(input);
			navigate(
				SimpleString.mapToUrl(
					input,
					params.delete(UrlParams.PageIndex),
					UrlParams.SearchTerm
				)
			);
		} else {
			navigate(
				SimpleString.mapToUrl(
					searchTerm,
					params.delete(UrlParams.PageIndex),
					UrlParams.SearchTerm
				)
			);
		}
	};

	const handleReset = (): void => {
		setSearchTerm('');
		navigate(Search.reset(search.ingredientId));
	};

	const handleChangePageIndex = (index: number): void => {
		const newParams = params.delete(UrlParams.PageIndex);

		if (index === 0) {
			navigate(newParams);
		} else {
			navigate(newParams.add(UrlParams.PageIndex, String(index)));
		}
	};

	const handleChangePageSize = (value: string): void => {
		const newParams = params.delete(UrlParams.PageSize);
		if (Number(value) === 20) {
			navigate(newParams.delete(UrlParams.PageIndex));
		} else {
			navigate(newParams.delete(UrlParams.PageIndex).add(UrlParams.PageSize, value));
		}
	};

	const handleChangeSortOrder = (value: string): void => {
		const newParams = params.delete(UrlParams.SortOrder);
		if ((value as ProductSortOrder) === ProductSortOrder.NameAsc) {
			navigate(newParams.delete(UrlParams.PageIndex));
		} else {
			navigate(newParams.delete(UrlParams.PageIndex).add(UrlParams.SortOrder, value));
		}
	};

	const handleChangePrice = (left: number | null, right: number | null): void => {
		navigate(
			Slider.mapToUrl(
				params.delete(UrlParams.PageIndex),
				initialRanges.price,
				UrlParams.Price,
				left,
				right
			)
		);
	};

	const handleChangeCo2 = (left: number | null, right: number | null): void => {
		navigate(
			Slider.mapToUrl(
				params.delete(UrlParams.PageIndex),
				initialRanges.co2,
				UrlParams.Co2,
				left,
				right
			)
		);
	};

	const handleChangeCo2Labels = (id: string) => {
		const newIds: string[] = StringList.addOrRemove(search.co2LabelIds, id);
		navigate(
			StringList.mapToUrl(newIds, params.delete(UrlParams.PageIndex), UrlParams.Co2Label)
		);
	};

	const handleAddToIngredient = async (id: string): Promise<void> => {
		if (search.ingredientId) {
			setIsLoading(true);
			const ingredient = await apiGetIngredientIdV1.callApi(search.ingredientId);
			if (ingredient.hasValue()) {
				const postIngredient = mapToSaveIngredientDto(ingredient.get());
				postIngredient.products.push(id);
				await apiPutIngredientV1.callApi(search.ingredientId, postIngredient);
			}
		}

		navigate(uriToAirUrl(`/ingredient/detail/${params.get(UrlParams.IngredientId)}`));
	};

	const openAdvancedSearch = () => {
		const advancedSearchButton = document.getElementById('advancedSearchButton');
		if (advancedSearchButton) advancedSearchButton.click();
	};

	const getSuggestedProducts = async (): Promise<ISelectItem[]> => {
		return ProductList.getFromApiSW(searchTerm);
	};

	return (
		<main className="container">
			<div className="d-flex justify-content-between">
				<h1>{t('product:SEARCH_PRODUCT')}</h1>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-7">
					<div className="input-group mb-3">
						<AdvancedSearchInput
							debounceMilliSeconds={350}
							getSuggestedItems={getSuggestedProducts}
							handleSearch={handleSearch}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							triggerSearchLetterAmount={1}
						/>
					</div>
				</div>
				<div className="col-lg-2">
					<button
						id="advancedSearchButton"
						className="btn btn-primary"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#advancedSearch"
						aria-expanded="true"
						aria-controls="advancedSearch"
					>
						{t('_general:ADVANCED_FILTER')}
					</button>
				</div>
				<div className="col-lg-3 text-end">
					<div className="d-flex flex-row justify-content-end">
						<DropdownPageSize
							className="me-2"
							listFunction={handleChangePageSize}
							currentValue={String(search.pageSize)}
						/>
						<DropdownSortOrder
							type={'product'}
							listFunction={handleChangeSortOrder}
							currentValue={search.sortOrder}
						/>
					</div>
				</div>
			</div>
			<div className={'collapse'} id="advancedSearch">
				<div className="card card-body mb-3">
					<AdvancedSearch
						disabled={productList.totalCount === 0}
						handleChangeCo2={handleChangeCo2}
						handleChangeCo2Labels={handleChangeCo2Labels}
						handleChangePrice={handleChangePrice}
						handleReset={handleReset}
						initialCo2Labels={initialCo2Labels}
						initialRanges={initialRanges}
						productList={productList}
						search={search}
					/>
				</div>
			</div>
			<div className="mb-3">
				<RenderIf condition={isLoaded}>
					<SearchResultList
						productList={productList}
						handleAddToIngredient={handleAddToIngredient}
					/>
				</RenderIf>
				<RenderIf condition={!isLoaded || isLoading}>
					<LoadingAnimation isLoading={!isLoaded || isLoading} />
				</RenderIf>
			</div>
			<Pagination
				countOfAllListItems={productList.totalCount}
				pageIndex={search.pageIndex}
				setPageIndex={handleChangePageIndex}
				listItemAmount={search.pageSize}
				maxNumberOfButtons={maxNumberOfButtons}
			/>
		</main>
	);
};

export default ProductSearchContainer;
