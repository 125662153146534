import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as apiGetMenuIdV1 from 'api/menu/GetMenuIdV1';
import { Recipe, Status } from 'api/menu/GetMenuIdV1';
import * as apiPostMenuV1 from 'api/menu/PostMenuV1';
import * as apiPutMenuV1 from 'api/menu/PutMenuV1';
import * as apiGetAllStatus from 'api/status/GetAllStatusV1';
import * as apiGetTagStartingWithV1 from 'api/tag/GetTagStartingWithV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import MenuLanguageInput from 'components/desktop/Menu/LanguageInput/MenuLanguageInput';
import 'components/desktop/Menu/MenuComponent.scss';
import MenuNutrients from 'components/desktop/Menu/MenuNutrients/MenuNutrients';
import RecipeList from 'components/desktop/Menu/RecipeList/RecipeList';
import MenuSidebar from 'components/desktop/Menu/Sidebar/MenuSidebar';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PERMISSIONS from 'enums/permissions';
import { mapToSaveMenuDto } from 'functions/mappers/Menu/mapToSaveMenuDto';
import scrollToTop from 'functions/scrollToTop';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { Menu, defaultMenu } from 'types/Menu/Menu';
import { SaveMenuDto } from 'types/Menu/SaveMenuDto';
import { TagLight } from 'types/Tag/TagLight';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';

interface IProps {
	copy?: boolean;
	id?: string;
}

const MenuComponent = (props: IProps) => {
	const navigate = useNavigate();
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayMenu, setDisplayMenu] = useState<boolean>(true);

	const [statusList, setStatusList] = useState<Status[]>([]);
	const [tagList, setTagList] = useState<TagLight[]>([]);
	const [menu, setMenu] = useState<Menu>(defaultMenu());
	const [selectedRecipe, setSelectedRecipe] = useState<Recipe>({} as Recipe);

	useEffect(() => {
		if (reduxLanguages) {
			initialize();
		}
	}, [reduxLanguages]);

	const initialize = async (): Promise<void> => {
		scrollToTop();
		if (reduxLanguages.length === 0) return;
		setSelectedCultureCode(reduxCultureCode);
		if (props.id) {
			await getMenu(props.id);
		} else {
			const menuCpy = { ...menu };
			setMenu(menuCpy);
		}
		await getStatusList();
		await getTagList();
	};

	const handleSetDisplayMenu = (input: boolean) => {
		if (input === true) {
			setSelectedRecipe({} as Recipe);
			setDisplayMenu(input);
		} else {
			setDisplayMenu(input);
		}
	};

	const handleSetSelectedRecipe = (index: number) => {
		let selectedRecipeCpy = { ...selectedRecipe };
		selectedRecipeCpy = menu.recipes[index];
		setSelectedRecipe(selectedRecipeCpy);
		setDisplayMenu(false);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSave = async (suppressNavigation?: boolean): Promise<void | null | undefined> => {
		const menuCpy: Menu = { ...menu };

		if (menuCpy.status.name == undefined && statusList.length) {
			menuCpy.status = statusList[0];
		}

		if (props.copy) {
			menuCpy.id = '';
		}

		let response: void | null;
		if (props.id && !props.copy) {
			response = await putMenu(props.id, mapToSaveMenuDto(menuCpy));
		} else {
			response = await postMenu(mapToSaveMenuDto(menuCpy), suppressNavigation);
		}
		return response;
	};

	const handleValueChange = (key: keyof Menu, value: string) => {
		const menuCpy: Menu = { ...menu };
		menuCpy[key][selectedCultureCode] = value;
		setMenu(menuCpy);
	};

	const handleTranslate = async () => {
		await handleTranslateInput('nameTranslations');
		await handleTranslateInput('shortDescriptionTranslations');
	};

	const handleTranslateInput = async (name: string) => {
		const menuCpy: Menu = { ...menu };
		const text: string = menuCpy[name][reduxCultureCode];

		if (!text) {
			return;
		}

		const translationDto = {
			fromCultureCode: reduxCultureCode,
			toCultureCode: selectedCultureCode,
			text: text,
		} as PostTranslationDto;

		const response = await apiPostTranslation.callApi(translationDto);
		menuCpy[name][selectedCultureCode] = response.getOrDefault('');
		setMenu(menuCpy);
	};

	const handleAddRecipe = async (): Promise<void> => {
		const response: null | void | undefined = await handleSave(true);
		if (response !== null) {
			navigate(`/recipe/search/menu/${menu.id}`);
		}
	};

	const handleSetRecipes = (recipes: Recipe[]) => {
		const menuCpy: Menu = { ...menu };
		menuCpy.recipes = recipes;
		setMenu(menuCpy);
	};

	const getTitle = (): string => {
		if (!props.id) return t('menu:MENU_NEW');
		if (!menuEditor) return t('menu:MENU_VIEW');
		return t('menu:MENU_EDIT');
	};

	const getStatusList = async (): Promise<void> => {
		const response = await apiGetAllStatus.callApi();
		response.do((x) => setStatusList(x));
	};

	const getTagList = async (): Promise<void> => {
		const tags = await apiGetTagStartingWithV1.callApi('', false);
		tags.do((x) => setTagList(x));
	};

	const postMenu = async (
		dto: SaveMenuDto,
		suppressNavigation?: boolean
	): Promise<void | null> => {
		setIsLoading(true);
		const id = await apiPostMenuV1.callApi(dto);
		id.do((x) => {
			menu.id = x;
			if (!suppressNavigation) {
				navigate(`/menu/detail/${x}`);
			}
		});
		setIsLoading(false);
	};

	const putMenu = async (id: string, dto: SaveMenuDto): Promise<void | null> => {
		setIsLoading(true);
		const response = await apiPutMenuV1.callApi(id, dto);
		response.do(setMenu);

		setIsLoading(false);
	};

	const getMenu = async (id: string): Promise<void> => {
		const response = await apiGetMenuIdV1.callApi(id);
		response.do((x) => {
			if (props.copy) {
				reduxLanguages.map((l: Language) => {
					if (x.nameTranslations[l.cultureCode]) {
						x.nameTranslations[l.cultureCode] = `${
							x.nameTranslations[l.cultureCode]
						} - Copy`;
					}
				});
			}
			setMenu(x);
		});
	};

	const renderNutrients = () => {
		if (displayMenu) {
			if (menu.nutrientValues && menu.nutrientValues.length > 0) {
				return (
					<>
						<MenuNutrients
							displayMenu={displayMenu}
							object={menu}
							nutrients={menu.nutrientValues}
						/>
					</>
				);
			}
		} else {
			if (selectedRecipe.nutrientValues && selectedRecipe.nutrientValues.length > 0) {
				return (
					<>
						<MenuNutrients
							displayMenu={displayMenu}
							object={selectedRecipe}
							nutrients={selectedRecipe.nutrientValues}
						/>
					</>
				);
			}
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between">
						<div>
							<h1>{getTitle()}</h1>
						</div>
						<div>
							<RenderIf condition={menuEditor}>
								<BtnSave handleSave={() => handleSave()} isLoading={isLoading} />
							</RenderIf>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-md-4 col-xl-3 col-sm-12 col-12 sidebar">
						<MenuSidebar
							displayMenu={displayMenu}
							menu={menu}
							selectedRecipe={selectedRecipe}
							setDisplayMenu={handleSetDisplayMenu}
							setMenu={setMenu}
							statusList={statusList}
							tagList={tagList}
						/>
					</div>
					<div className="col-md-8 col-xl-9 col-sm-12 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<MenuLanguageInput
									onTranslateClick={handleTranslateInput}
									handleValueChange={handleValueChange}
									menu={menu}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
						<br />
						<RecipeList
							addNewRecipe={handleAddRecipe}
							recipes={menu.recipes.length > 0 ? menu.recipes : []}
							setRecipes={handleSetRecipes}
							setSelectedRecipe={handleSetSelectedRecipe}
							selectedRecipe={menu.recipes.findIndex((x) => x == selectedRecipe)}
						/>
						<br />
						{renderNutrients()}
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuComponent;
