import * as apiSW from 'api/channel/GetChannelStartingWithV1';
import * as apiPostSearch from 'api/channel/PostChannelSearchV1';
import Channel from 'classes/StandingData/Channel/Search/Channel';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class ChannelList {
	public all: Channel[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const channels = await apiSW.callApi(searchTerm, showOnlyDeleted);
		channels.hasValue() && this.mapFromApiSW(channels.get());
	}

	public callApi(input: apiPostSearch.Request) {
		return apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const channel: Channel = new Channel(i);
			this.all.push(channel);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseChannel[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
