import axios from 'axios';

import { AccessorySortOrder } from 'enums/SortOrder/AccessorySortOrder';
import { Characteristic } from 'enums/characteristic';
import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/Accessory/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	carbonDioxideLabelIds: string[] | null;
	carbonDioxideRange: Range;
	categoryIds: string[] | null;
	characteristics?: string[] | null;
	pageIndex: number | null;
	pageSize: number | null;
	priceRange: Range;
	searchTerm: string | null;
	showOnlyDeleted?: boolean | null;
	sortOrder: AccessorySortOrder;
	tagIds: string[] | null;
}

export interface Response {
	data: ResponseData;
}

export interface ResponseData {
	data: Accessory[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxPriceRange: Range;
	minMaxCarbonDioxideRange: Range;
}

export type Accessory = {
	carbonDioxideLabel: CarbonDioxideLabel;
	carbonDioxideValue: number | null;
	categories: Category[] | null;
	characteristic: Characteristic;
	countOfRecipes: number;
	density: number | null;
	id: string;
	image: Image;
	lastEditedDateUtc: string;
	lastEditor: string | null;
	name: string | null;
	price: number | null;
	tags: Tag[] | null;
};

export type CarbonDioxideLabel = {
	label: string | null;
	color: string | null;
};

export type Category = {
	id: string;
	description: string;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string | null;
	tagCategory: TagCategory;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[] | null;
};

export type TagCategory = {
	id: string;
	name: string | null;
};

export type Range = {
	minimum: number | null;
	maximum: number | null;
};
