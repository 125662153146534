import i18n from 'i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import 'containers/desktop/MenuPlan/Print/MenuPlanPrint.scss';
import { extractTableFromMenuPlan } from 'containers/desktop/MenuPlan/Print/MenuPlanPrintHandler';
import { QueryParams } from 'functions/getQueryParams';
import { getQueryParams } from 'functions/getQueryParams';

const MenuPlanPrint = () => {
	const { id } = useParams();
	const [menuPlan, setMenuPlan] = useState<null | apiGet.ResponseData>(null);
	const [table, setTable] = useState<Table[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { t } = useTranslation();

	useEffect(() => {
		initialize();
	}, []);

	useEffect(() => {
		if (!isLoading) {
			window.print();
			window.close();
		}
	}, [isLoading]);

	const initialize = async (): Promise<void> => {
		i18n.changeLanguage(getCultureCodeFromUrl().split('-')[0]);
		await getRecipe();
		setIsLoading(false);
	};

	const getCultureCodeFromUrl = (): string => {
		const queryParams: QueryParams = getQueryParams();
		return (queryParams.cultureCode as string) ?? 'en-US';
	};

	const getRecipe = async (): Promise<void> => {
		if (!id) return;
		const response = await apiGet.apiCall(id);
		response.hasValue() && setMenuPlan(response.get()),
			setTable(extractTableFromMenuPlan(response.get(), getCultureCodeFromUrl(), t));
	};

	const renderHeader = (): JSX.Element => {
		return (
			<div
				className={'d-flex align-items-center justify-content-end'}
				style={{
					marginBottom: '10px',
					paddingBottom: '5px',
					gap: '5px',
					borderBottom: '1px solid #eee',
				}}
			>
				<div style={{ fontSize: '12px', color: 'grey' }}>AI Recipes</div>
				<img
					src="/img/logo/logo_sm.png"
					alt=""
					height="20"
					className="d-inline-block align-text-middle"
				/>
			</div>
		);
	};

	const getBackgroundColor = (type: string, value: string): string => {
		if (type === 'WEEKDAYS' && value) return '#586368';
		if (type === 'DATES' && value) return '#4CA0D3';
		return 'white';
	};

	const getColor = (type: string, value: string): string => {
		if (type === 'WEEKDAYS' && value) return 'white';
		if (type === 'DATES' && value) return 'white';
		return 'inherit';
	};

	const getPageBreakBefore = (type: string): 'avoid' | 'auto' => {
		if (type === 'WEEKDAYS') return 'avoid';
		if (type === 'DATES') return 'auto';
		return 'avoid';
	};

	const getPageBreakAfter = (type: string): 'avoid' | 'auto' => {
		if (type === 'WEEKDAYS') return 'avoid';
		if (type === 'DATES') return 'avoid';
		return 'auto';
	};

	if (!id) return <></>;
	if (!menuPlan) return <></>;

	const renderTableArray = (input: Table[]): JSX.Element => {
		return (
			<table
				style={{
					fontSize: '0.6em',
				}}
			>
				<tbody>
					{input.map((q, i: number) => {
						return (
							<tr
								key={i}
								style={{
									pageBreakBefore: getPageBreakBefore(q.type),
									pageBreakAfter: getPageBreakAfter(q.type),
									pageBreakInside: 'avoid',
									verticalAlign: 'top',
								}}
							>
								{q.values.map((e, i) => {
									return (
										<td
											key={i}
											style={{
												border: e.value ? '1px solid #aaa' : '0',
												color: getColor(q.type, e.value),
												backgroundColor: getBackgroundColor(
													q.type,
													e.value
												),
												padding: '2px 4px',
											}}
										>
											<div>{e.value}</div>
											{e.additionalValues && (
												<div style={{ fontSize: '7px', color: 'grey' }}>
													{e.additionalValues}
												</div>
											)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	return (
		<>
			{renderHeader()}
			<div
				style={{
					fontWeight: '800',
					margin: '0px 0px 10px 0px',
					fontSize: '1.4em',
					color: '#333',
				}}
			>
				{menuPlan.titleTranslations?.[getCultureCodeFromUrl()] ?? ''}
			</div>
			{renderTableArray(table)}
		</>
	);
};

export default MenuPlanPrint;

export type Table = {
	type: 'WEEKDAYS' | 'DATES' | 'ITEMS';
	values: Value[];
};

export type Value = {
	value: string;
	additionalValues?: string;
};
