import * as apiGetStartingWithV1 from 'api/season/GetSeasonStartingWithV1';
import * as ItemList from 'components/desktop/_general/ItemListEditor/ItemList';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import * as Season from 'types/_general/Store/Season';

export type Type = Season.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON])) return [];
	const response = await apiGetStartingWithV1.callApi('', false);
	return response.getOrDefault([]);
}

export function search(obj: Type, searchTerm: string): Type {
	return obj.filter((e: Season.Type) =>
		e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
	);
}

export function mapToItemList(obj: Type): ItemList.Type {
	return obj.map((e) => {
		return {
			...e,
			icon: e.iconSvgUrl,
		};
	});
}

export function getFromIdArray(obj: Type, ids: string[]): Type {
	return obj.filter((e) => {
		return ids.includes(e.id);
	});
}
