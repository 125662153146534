import { ContextBuilder } from 'context/nutriscoreLabelContext';

import NutriScoreLabelManagementComponent from 'components/desktop/StandingData/NutriScoreLabel/Management/NutriScoreLabelManagementComponent';

const NutriScoreLabelArchiveContainer = () => {
	return (
		<main className="container">
			<NutriScoreLabelManagementComponent archive={true} />
		</main>
	);
};

export default ContextBuilder.From(NutriScoreLabelArchiveContainer)
	.wrapWithNutriscoreContext()
	.build();
