export const setReduxCurrency = (currency: string) => {
	return {
		type: 'currency/SET',
		payload: currency,
	};
};

export type CurrencyAction = {
	type: string;
	payload: string;
};

export const currencyReducer = (state: string = '$', action: CurrencyAction): string => {
	switch (action.type) {
		case 'currency/SET':
			return action.payload;
		default:
			return state;
	}
};
