import * as apiGet from 'api/nutrient/GetNutrientV1';
import * as Nutrient from 'types/_general/Store/Nutrient';

export type Type = Nutrient.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGet.callApi();
	return response.getOrDefault([]);
}

export function search(obj: Type, searchTerm: string): Type {
	return obj.filter((e: Nutrient.Type) =>
		e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
	);
}
