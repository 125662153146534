import UnitManagementComponent from 'components/desktop/StandingData/Unit/Management/UnitManagementComponent';

const UnitManagementContainer = () => {
	return (
		<main className="container">
			<UnitManagementComponent />
		</main>
	);
};

export default UnitManagementContainer;
