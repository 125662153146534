import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Recipe from 'classes/Recipe/Search/Recipe';
import RecipeList from 'classes/Recipe/Search/RecipeList';
import 'components/desktop/Dashboard/LastRecipes/LastRecipes.scss';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { GridBreakpoints } from 'enums/gridBreakpoints';
import { getPicturePath } from 'functions/getPicturePath';
import { useMediaQuery } from 'hooks/useMediaQuery';

interface IProps {
	recipes: RecipeList;
	isLoading: boolean;
}

const LastRecipes = (props: IProps) => {
	const { t } = useTranslation();

	const isSm = useMediaQuery(GridBreakpoints.sm);
	const isMd = useMediaQuery(GridBreakpoints.md);
	const isLg = useMediaQuery(GridBreakpoints.lg);
	const isXl = useMediaQuery(GridBreakpoints.xl);
	const isXxl = useMediaQuery(GridBreakpoints.xxl);

	const getPicture = (recipe: Recipe): JSX.Element => {
		if (recipe.image !== null) {
			return (
				<div className="dashboard-recipe-picture">
					<img
						alt={recipe.title ? recipe.title : ''}
						src={getPicturePath(recipe.image, 220.667)}
					/>
				</div>
			);
		}
		return (
			<div className="dashboard-recipe-picture-placeholder d-flex justify-content-center align-items-center">
				<FontAwesomeIcon size="2x" icon={faCamera} />
			</div>
		);
	};

	const getRecipes = (): Recipe[] => {
		if (isXxl) return props.recipes.all.slice(0, 6);
		if (isXl) return props.recipes.all.slice(0, 4);
		if (isLg) return props.recipes.all.slice(0, 4);
		if (isMd) return props.recipes.all.slice(0, 3);
		if (isSm) return props.recipes.all.slice(0, 2);
		return props.recipes.all.slice(0, 1);
	};

	return (
		<>
			<LoadingAnimation isLoading={props.isLoading} />
			<div className="card-header card-header-secondary">
				{t('dashboard:RECIPES_LAST_EDITED')}
			</div>
			<div className="row dashboard-recipe-row">
				{getRecipes().map((recipe: Recipe, i: number) => {
					return (
						<div key={i} className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6">
							<Link to={`/recipe/detail/${recipe.id}`} className="unstyled-link">
								<div className="dashboard-recipe-thumbnail">
									<div>{getPicture(recipe)}</div>
									<p className="unstyled-link">
										{recipe.title ? recipe.title : t('_general:UNKNOWN_TITLE')}
									</p>
								</div>
							</Link>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default LastRecipes;
