import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeftFromLine } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToBracket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setSidebarStateToLocalStorage } from 'functions/sidebarStateFunctions';
import SidebarState from 'types/_general/SidebarState';

interface IProps {
	sidebarState: SidebarState;
	setSidebarState: (input: SidebarState) => void;
}

export default function Footer(props: IProps) {
	const close = (): void => {
		setSidebarState('closed');
	};

	const open = (): void => {
		setSidebarState('opened');
	};

	const setSidebarState = (input: 'opened' | 'closed'): void => {
		setSidebarStateToLocalStorage(input);
		props.setSidebarState(input);
	};

	const renderToggle = (): JSX.Element => {
		return (
			<div onClick={props.sidebarState !== 'opened' ? open : close} className="button">
				<FontAwesomeIcon
					icon={
						props.sidebarState !== 'opened'
							? (faArrowRightToBracket as IconProp)
							: (faArrowLeftFromLine as IconProp)
					}
					size="lg"
				/>
			</div>
		);
	};

	return (
		<div
			className="navigation-footer"
			style={{ justifyContent: props.sidebarState === 'opened' ? 'end' : 'start' }}
		>
			{renderToggle()}
		</div>
	);
}
