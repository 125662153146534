import Archive from 'containers/desktop/StandingData/Activity/Archive/ActivityArchive';
import Management from 'containers/desktop/StandingData/Activity/Management/ActivityManagement';
import PERMISSIONS from 'enums/permissions';

export const activityRoutes = [
	{
		path: '/activity/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/activity/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
