import * as apiSW from 'api/allergen/GetAllergenStartingWithV1';
import * as apiPostSearch from 'api/allergen/PostAllergenSearchV1';
import Allergen from 'classes/StandingData/Allergen/Search/Allergen';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class AllergenList {
	public all: Allergen[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const allergens = await apiSW.callApi(searchTerm, showOnlyDeleted);
		allergens.hasValue() && this.mapFromApiSW(allergens.get());
	}

	public async callApi(
		input: apiPostSearch.Request
	): Promise<Optional<apiPostSearch.ResponseData>> {
		return await apiPostSearch.callApi(input);
	}
	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const allergen: Allergen = new Allergen(i);
			this.all.push(allergen);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseAllergen[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
