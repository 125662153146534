import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchLogic } from 'enums/searchLogic';
import useDebounce from 'hooks/useDebounce';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';

interface IProps {
	className?: string;
	debouncedMilliSeconds?: number;
	errorKey?: string;
	disabled?: boolean;
	handleChangeSearchTerm: (searchTerm: string) => void;
	keepSearchTermAfterSelection?: boolean;
	label?: string;
	onEnter?: (searchTerm: string) => void;
	placeholder?: string;
	placeholderIsBlack?: boolean;
	placeholderText?: string;
	setSavedItem: (item: IExclusionLogicSelectItem) => void;
	suggestedItems: ISelectItem[];
	triggerSearchLetterAmount?: number;
}

const ExclusionLogicSearchInput = (props: IProps) => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectionIndex, setSelectionIndex] = useState<number>(-1);
	const [showSuggested, setShowSuggested] = useState<boolean>(false);

	const currentListItem = useRef<HTMLDivElement | null>(null);
	const inputElement = useRef<HTMLInputElement | null>(null);
	const debouncedValue = useDebounce<string>(searchTerm, props.debouncedMilliSeconds ?? 500);

	useEffect(() => {
		if (currentListItem.current)
			currentListItem.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
				inline: 'nearest',
			});
	}, [selectionIndex]);

	useEffect(() => {
		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (debouncedValue.length >= triggerSearchLetterAmount) {
			props.handleChangeSearchTerm(debouncedValue);
		}
	}, [debouncedValue]);

	const handleAddItem = (item: ISelectItem, include: boolean): void => {
		props.setSavedItem({
			id: item.id,
			name: item.name,
			logic: include ? SearchLogic.Include : SearchLogic.Exclude,
		} as IExclusionLogicSelectItem);

		if (props.keepSearchTermAfterSelection) {
			setSearchTerm(item.name);
			props.handleChangeSearchTerm(item.name);
		} else {
			setSearchTerm('');
			props.handleChangeSearchTerm('');
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		const selectedItem = props.suggestedItems[selectionIndex];
		switch (e.key) {
			case 'ArrowDown':
				if (selectionIndex < props.suggestedItems.length - 1) {
					setSelectionIndex(selectionIndex + 1);
				}
				e.preventDefault();
				break;
			case 'ArrowUp':
				if (selectionIndex > 0) {
					setSelectionIndex(selectionIndex - 1);
				}
				e.preventDefault();
				break;
			case 'Enter':
				if (selectedItem) {
					handleAddItem(selectedItem, true);
				} else {
					if (props.onEnter) {
						props.onEnter(searchTerm);
						inputElement.current?.blur();
					}
				}
				break;
			case 'Escape':
				inputElement.current?.blur();
				break;
		}
	};

	const onBlur = (): void => {
		setShowSuggested(false);
		setSelectionIndex(-1);
	};

	const onFocus = (): void => {
		if (!props.triggerSearchLetterAmount) {
			setSearchTerm('');
			props.handleChangeSearchTerm('');
		}
		setShowSuggested(true);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.currentTarget.value;

		setSearchTerm(value);
		setSelectionIndex(-1);

		const triggerSearchLetterAmount: number = props.triggerSearchLetterAmount ?? 0;
		if (value.length >= triggerSearchLetterAmount) {
			setShowSuggested(true);
		} else {
			setShowSuggested(false);
		}
	};

	const getInputClassName = (): string => {
		if (props.placeholderIsBlack) {
			return 'form-control black-placeholder';
		}
		return 'form-control';
	};

	return (
		<>
			{props.label ?? <label className="input-label">{props.label}</label>}
			<input
				className={getInputClassName()}
				type="text"
				value={searchTerm}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={onKeyDown}
				placeholder={props.placeholderText ?? t('_general:INPUT_PLACEHOLDER_SEARCHTERM')}
				ref={inputElement}
				error-key={props.errorKey}
			/>
			{showSuggested && props.suggestedItems.length > 0 && (
				<div className="select-suggestions">
					{props.suggestedItems.map((item, index) => (
						<div
							className={index === selectionIndex ? 'active' : ''}
							key={item.name + item.id}
							ref={index === selectionIndex ? currentListItem : undefined}
							onMouseDown={() => {
								handleAddItem(item, true);
							}}
							style={{ display: 'flex', cursor: 'pointer' }}
						>
							<div>{item.name}</div>
							<div
								style={{
									fontSize: '.775rem',
									color: 'grey',

									marginRight: '5px',
									marginLeft: 'auto',
								}}
							>
								<span
									onMouseDown={(e) => {
										e.stopPropagation();
										handleAddItem(item, true);
									}}
									style={{ cursor: 'pointer' }}
								>
									{t('_general:INCLUDE')}
								</span>
								<span style={{ marginLeft: '2px', marginRight: '2px' }}>|</span>
								<span
									onMouseDown={(e) => {
										e.stopPropagation();
										handleAddItem(item, false);
									}}
								>
									{t('_general:EXCLUDE')}
								</span>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default ExclusionLogicSearchInput;
