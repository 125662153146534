import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/tag/GetTagIdV1';
import Tag from 'classes/StandingData/Tag/Tag';
import TagCategoryList from 'classes/StandingData/TagCategory/Search/TagCategoryList';
import TagLanguageInput from 'components/desktop/StandingData/Tag/LanguageInput/TagLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

const TagDetail = () => {
	const { t } = useTranslation();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [tag, setTag] = useState<Tag>(new Tag());
	const [id, setId] = useState<string | null>(null);
	const [tagCategoryList, setTagCategoryList] = useState<TagCategoryList>(new TagCategoryList());

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		getTagCategories();
		setId(id);
		if (id !== null && id !== 'new') {
			getTag(id);
		}
	};

	const getTag = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.do((x) => setTag(new Tag(x)));
		} finally {
			setIsLoading(false);
		}
	};

	const getTagCategories = async (): Promise<void> => {
		await tagCategoryList.search('', false);
		setTagCategoryList(clone(tagCategoryList));
	};

	const handleTagCategoryChange = (tagCategoryId: string): void => {
		const tagCategory = tagCategoryList.filtered.find(
			(x: ISelectItem) => x.id == tagCategoryId
		);
		tag.tagCategory.id = tagCategoryId;
		tag.tagCategory.name = tagCategory?.name ?? '';
		setTag(clone(tag));
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		tag.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setTag(clone(tag));
	};

	const handleTranslate = async () => {
		tag.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveTag = async (): Promise<void> => {
		setIsLoadingSave(true);
		if (tag.tagCategory.id === '') {
			tag.tagCategory.id = tagCategoryList.filtered[0].id;
			tag.tagCategory.name = tagCategoryList.filtered[0].name;
		}
		try {
			if (id !== null && id !== 'new') {
				await tag.callApiPut();
			} else {
				await tag.callApiPost();
				if (!tag.id) return;
				setId(tag.id);
				setIdToUrl(tag.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleToggle = (input: boolean): void => {
		tag.display = input;
		setTag(clone(tag));
	};

	const setPictureUpload = async (input: FormData | null): Promise<void> => {
		if (input) {
			await tag.callApiUploadMedia(input);
			setTag(clone(tag));
		}
	};

	const getTagCategoryOptions = (): SelectOption[] => {
		return tagCategoryList.filtered.map((tagCategory: ISelectItem) => ({
			id: tagCategory.id,
			text: tagCategory.name,
		}));
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{tag.id ? (
								<>
									<h1>{t('standingData:EDIT_TAG')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_TAG')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave handleSave={() => handleSaveTag()} isLoading={isLoadingSave} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<SvgBig url={tag.iconSvg?.url ?? null} width={120} height={120} />
						<PictureUpload setFormData={setPictureUpload} accept="image/svg+xml" />
						<label>{t('standingData:TAG_CATEGORY')}:</label>
						<SelectSimple
							options={getTagCategoryOptions()}
							currentValue={tag.tagCategory.id}
							handleValueChange={handleTagCategoryChange}
							errorKey="TagCategory"
						/>
						<Toggle
							checked={tag.display ?? false}
							handleToggle={handleToggle}
							elementId="display"
							className="mt-1"
							label="standingData:DISPLAY"
							errorKey={'Display'}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<TagLanguageInput
									tag={tag}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TagDetail;
