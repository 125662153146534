import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { Characteristic } from 'enums/characteristic';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { ISelectItem } from 'interfaces/ISelectItem';
import { regexValidationNutrientValues } from 'regex/validation/NutrientValues';
import { Ingredient } from 'types/Ingredient/Ingredient';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { NutrientCategoryGroup } from 'types/Nutrient/NutrientCategoryGroup';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';

interface IProps {
	characteristic: Characteristic | null;
	nutrients: Nutrient[];
	ingredient: Ingredient;
	setIngredient: (input: Ingredient) => void;
}

const IngredientNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	let nutrientsOfBaseIngredient: string[] = [];
	if (props.ingredient.baseIngredient.nutrientValues) {
		nutrientsOfBaseIngredient = props.ingredient.baseIngredient.nutrientValues.map(
			(x: NutrientValue) => x.id
		);
	}

	const nutrientsOfIngredient: string[] = props.ingredient.nutrientValues.map(
		(x: NutrientValue) => x.id
	);

	const concatNutrients: NutrientValue[] = props.ingredient.baseIngredient.nutrientValues.concat(
		props.ingredient.nutrientValues
	);

	const handleNutrientValueChange = (nutrientId: string, nutrientValue: string): void => {
		const ingredient: Ingredient = { ...props.ingredient };
		const index = ingredient.nutrientValues.findIndex((x) => x.id == nutrientId);
		ingredient.nutrientValues[index].value = Number.parseFloat(nutrientValue);
		props.setIngredient(ingredient);
	};

	const getNutrientFromPropsWithValueAndSource = (
		id: string,
		value: number,
		source: string | null
	): Optional<Nutrient> => {
		const nutrient = getNutrientFromProps(id);
		return Optional.Maybe(nutrient).map((n) => {
			return { ...n, value, source };
		});
	};

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return concatNutrients
			.keepOptional((x) => getNutrientFromPropsWithValueAndSource(x.id, x.value, x.source))
			.groupBy((x) => x.nutrientCategory.id)
			.map((k: string, b: Nutrient[]) => {
				return {
					id: k,
					name: b[0].nutrientCategory.name,
					sortOrder: b[0].nutrientCategory.sortOrder,
					nutrients: b.orderBy((x) => x.sortOrder),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	const getNutrientFromProps = (nutrientId: string): Nutrient | null => {
		const nutrient: Nutrient | undefined = props.nutrients.find((nutrient: Nutrient) => {
			return nutrient.id === nutrientId;
		});
		return nutrient ? nutrient : null;
	};

	const addNutrientToIngredient = (savedItem: ISelectItem): void => {
		handleNutrientValueChange(savedItem.id, '0');
	};

	const removeNutrientFromIngredient = (nutrientId: string): void => {
		const ingredient: Ingredient = { ...props.ingredient };
		ingredient.nutrientValues = ingredient.nutrientValues.filter((x) => x.id !== nutrientId);
		props.setIngredient(ingredient);
	};

	const onLockClick = (nutrientId: string, isLocked: boolean): number => {
		const ingredientCpy: Ingredient = { ...props.ingredient };

		if (isLocked) {
			ingredientCpy.nutrientValues = props.ingredient.nutrientValues.filter(
				(x) => x.id !== nutrientId
			);
			props.setIngredient(ingredientCpy);
			const index = props.ingredient.baseIngredient.nutrientValues.findIndex(
				(x) => x.id == nutrientId
			);
			return props.ingredient.baseIngredient.nutrientValues[index].value;
		} else {
			const index = props.ingredient.baseIngredient.nutrientValues.findIndex(
				(x) => x.id == nutrientId
			);
			ingredientCpy.nutrientValues.push(ingredientCpy.baseIngredient.nutrientValues[index]);
			props.setIngredient(ingredientCpy);
			return ingredientCpy.baseIngredient.nutrientValues[index].value;
		}
	};

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingNutrients: Nutrient[] = props.nutrients.filter((nutrient: Nutrient) => {
			const inBaseIngredient: boolean = nutrientsOfBaseIngredient.includes(nutrient.id);
			const inIngredient: boolean = nutrientsOfIngredient.includes(nutrient.id);
			if (inBaseIngredient || inIngredient) return false;
			if (searchTerm) {
				const name = nutrient.name.toLowerCase();
				searchTerm = searchTerm.toLowerCase();
				return name.startsWith(searchTerm);
			} else {
				return true;
			}
		});

		return matchingNutrients.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				name: `${nutrient.name} (${nutrient.nutrientCategory.name})`,
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const renderInputNutrientValues = (nutrient: Nutrient): JSX.Element => {
		const inBaseIngredient: boolean = nutrientsOfBaseIngredient.includes(nutrient.id);
		const inIngredient: boolean = nutrientsOfIngredient.includes(nutrient.id);
		return (
			<LockableInput
				title={nutrient.source}
				id={nutrient.id}
				label={`${nutrient.name} (${nutrient.unitShortNameSingular})`}
				defaultValue={String(nutrient.value)}
				validateRegex={regexValidationNutrientValues}
				placeholder=""
				onTrashCanClick={removeNutrientFromIngredient}
				withTrashCan={!inBaseIngredient}
				handleLockClick={onLockClick}
				withLock={inBaseIngredient}
				defaultLocked={inBaseIngredient && !inIngredient}
				handleValueChange={handleNutrientValueChange}
				disabled={!ingredientEditor}
				errorKey="NutrientValues"
			/>
		);
	};

	return (
		<>
			<fieldset className="margin-top-50">
				<legend>{t('_general:NUTRIENTS')}</legend>{' '}
				{getValueScopeLabel(ValueScope.HundredGrams, props.characteristic)}
				<br />
				{ingredientEditor && (
					<>
						<label className="input-label">{t('_general:ADD_NUTRIENT')}</label>
						<div className="input-group mb-3">
							<SuggestionSearchInput
								handleChangeSearchTerm={handleChangeSearchTerm}
								setSavedItem={addNutrientToIngredient}
								triggerSearchLetterAmount={0}
								suggestedItems={suggestedItems}
							/>
						</div>
					</>
				)}
				<div className="row">
					{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
						<div className="col-6" key={i} style={{ paddingTop: '24px' }}>
							<fieldset
								className="fieldset-white"
								style={{
									height: '700px',
									overflowY: 'auto',
								}}
							>
								<legend>{group.name}</legend>
								{group.nutrients.map((nutrient: Nutrient, i: number) => (
									<div key={i}>{renderInputNutrientValues(nutrient)}</div>
								))}
							</fieldset>
						</div>
					))}
				</div>
			</fieldset>
		</>
	);
};

export default IngredientNutrients;
