import axios from 'axios';

import { NutriScoreCategoryBaseIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryBaseIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<void>> {
	const url: string = '/backend-service/NutriScoreBaseIngredientCategoryPoints';
	return axios.put(url, requestBody).toOptionalVoid();
}

export type Request = {
	baseIngredientCategoryId: string;
	pointType: NutriScoreCategoryBaseIngredientCategory;
	points?: Point[];
	isPositive: boolean;
};

export type Point = {
	points: number;
	minValue: number | null;
};
