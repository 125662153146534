import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import StatusList from 'classes/MenuPlan/Detail/StatusList';

export default class Status {
	public id: string = '';
	public color: string | null = null;
	public order: number = 0;
	public name: string | null = null;
	private statusList: StatusList = new StatusList();

	public constructor(input?: api.Status) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async initialize(): Promise<void> {
		await this.statusList.callApiGet();
		if (this.id === '') {
			this.mapStatusToThis(this.statusList.all[0]);
		}
	}

	public mapStatusToThis(status: Status) {
		this.id = status.id;
		this.color = status.color;
		this.order = status.order;
		this.name = status.name;
	}

	public mapFromApi(input: api.Status): void {
		this.id = input.id;
		this.color = input.color;
		this.order = input.order;
		this.name = input.name;
	}
}
