import { useTranslation } from 'react-i18next';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { AllergenMappingDto } from 'types/Allergen/AllergenMappingDto';

interface IProps {
	allergens: AllergenMappingDto[];
	setSavedAllergens: (savedItems: AllergenLight[]) => void;
	handleChangeSearchTerm: (searchTerm: string) => void;
	suggestedAllergens: AllergenLight[];
	disabled?: boolean;
}

const AllergenSelect = (props: IProps) => {
	const { t } = useTranslation();

	const getLabelAllergens = (): string => {
		if (props.disabled) {
			if (props.allergens.length === 0) {
				return t('_general:NO_ALLERGENS');
			}
			return '';
		}
		return t('_general:ALLERGENS');
	};

	const addToSavedAllergens = (item: ISelectItem) => {
		const check = props.allergens.find((x) => x.id === item.id);
		const allergen = props.suggestedAllergens.find(
			(x) => x.id === item.id && x.name === item.name
		);
		if (!check && allergen) {
			const items = [...props.allergens];
			items.push({
				id: allergen.id,
				name: allergen.name,
				display: item.display ?? true,
				iconSvgUrl: allergen.iconSvgUrl,
				logic: allergen.logic,
				source: null,
			});
			props.setSavedAllergens(items);
		}
	};

	const getSuggestedAllergens = (): ISelectItem[] => {
		return props.suggestedAllergens.filter(
			(allergen) => !props.allergens.some((a) => a.id === allergen.id)
		);
	};

	const createHandleRemoveAllergen = (id: string) => {
		return () => {
			var savedItemsCpy = [...props.allergens];
			var index = savedItemsCpy.findIndex((element) => element.id == id);

			savedItemsCpy.splice(index, 1);

			props.setSavedAllergens(savedItemsCpy);
		};
	};

	return (
		<>
			<div className="row">
				<label>{getLabelAllergens()}</label>
				<div className="col-12">
					<div
						className="input-group"
						style={{ display: props.disabled ? 'none' : 'flex' }}
					>
						{!props.disabled && (
							<SuggestionSearchInput
								className="form-control"
								handleChangeSearchTerm={props.handleChangeSearchTerm}
								setSavedItem={addToSavedAllergens}
								triggerSearchLetterAmount={0}
								suggestedItems={getSuggestedAllergens()}
								debounceMilliSeconds={350}
								errorKey="Allergens"
							/>
						)}
					</div>
					<div className="mt-2 overflow-auto">
						{props.allergens.map((item: AllergenMappingDto, i: number) => (
							<ListItem
								title={item.source}
								key={i}
								text={item.name}
								iconSvgUrl={item.iconSvgUrl}
								textColor={item.logic === 'Positive' ? '#e35359' : undefined}
								handleDelete={
									props.disabled ? undefined : createHandleRemoveAllergen(item.id)
								}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default AllergenSelect;
