import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url = '/backend-service/EmailAccount/GetAll';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	emailAccounts: ResponseEmailAccount[];
};

export type ResponseEmailAccount = {
	id: string;
	senderEmail: string;
};
