import React, { useState } from 'react';

import 'components/desktop/_general/MainNavigationLeft/MainNavigationLeft.scss';
import Body from 'components/desktop/_general/MainNavigationLeft/components/Body';
import Footer from 'components/desktop/_general/MainNavigationLeft/components/Footer';
import Header from 'components/desktop/_general/MainNavigationLeft/components/Header';
import { getSidebarStateFromLocalStorage } from 'functions/sidebarStateFunctions';
import SidebarState from 'types/_general/SidebarState';

export default function MainNavigationLeft() {
	const [sidebarState, setSidebarState] = useState<SidebarState>(
		getSidebarStateFromLocalStorage()
	);

	const open = (_event: React.MouseEvent<HTMLDivElement>) => {
		if (sidebarState === 'closed') {
			setSidebarState('hovered');
		}
	};

	const close = (_event: React.MouseEvent<HTMLDivElement>) => {
		if (sidebarState === 'hovered') {
			setSidebarState('closed');
		}
	};

	return (
		<div
			className={[
				'navigation-container',
				sidebarState === 'opened' || sidebarState === 'hovered' ? 'opened' : 'closed',
			].join(' ')}
			onMouseEnter={open}
			onMouseLeave={close}
		>
			<div className="navigation">
				<Header sidebarState={sidebarState} />
				<Body sidebarState={sidebarState} />
				<Footer sidebarState={sidebarState} setSidebarState={setSidebarState} />
			</div>
		</div>
	);
}
