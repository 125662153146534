import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';

export default class Status {
	id: string = '';
	color: string = '';
	order: number = 0;
	name: string = '';

	public constructor(input?: postMenuSearchV1.Status) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: postMenuSearchV1.Status): void {
		this.id = input.id;
		this.color = input.color;
		this.order = input.order;
		this.name = input.name ?? '';
	}
}
