export default function isEmailValid(input: string): boolean {
	const regexp =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
	const foundPattern: string[] | null = input.toLowerCase().match(regexp);

	if (foundPattern !== null) {
		return true;
	}
	return false;
}
