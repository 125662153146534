import * as getUser from 'api/user/GetUserIdV1';
import * as postUser from 'api/user/PostUserCreateV1';
import * as putUser from 'api/user/PutUserIdV1';
import * as getUserProfile from 'api/userProfile/GetUserProfileV1';
import * as putUserProfile from 'api/userProfile/PutUserProfileV1';
import * as apiGet from 'api/userSetting/GetUserSettingKeyV1';
import * as apiPut from 'api/userSetting/PutUserSettingKeyV1';
import { ValueScope } from 'enums/valueScope';
import * as ValueScopeList from 'types/User/UserProfile/ValueScopeList';

export type Type = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope': ValueScope;
	'Recipe.Nutrient.Columns': ValueScopeList.Type;
};

export function create(): Type {
	return {
		'Recipe.List.ValueScope': ValueScope.Total,
		'Recipe.Nutrient.Columns': ValueScopeList.create(),
	};
}

export function setRecipeNutrientColumns(obj: Type, key: ValueScope): Type {
	return {
		...obj,
		'Recipe.Nutrient.Columns': ValueScopeList.update(obj['Recipe.Nutrient.Columns'], key),
	};
}

export async function putToApi(obj: Type): Promise<void> {
	await apiPut.callApi('Recipe.Nutrient.Columns', obj['Recipe.Nutrient.Columns'].join(';'));
}

export async function getRecipeListValueScopeFromApi(obj: Type): Promise<Type> {
	const response = await apiGet.callApi('Recipe.List.ValueScope');
	const responseData = response.toNullable();
	if (!responseData) return obj;
	return {
		...obj,
		'Recipe.List.ValueScope': responseData as ValueScope,
	};
}

export async function getRecipeNutrientColumnsFromApi(obj: Type): Promise<Type> {
	const response = await apiGet.callApi('Recipe.Nutrient.Columns');
	const responseData = response.toNullable();
	if (!responseData) return obj;
	return {
		...obj,
		'Recipe.Nutrient.Columns': responseData.split(';').map((e) => e as ValueScope),
	};
}

export function mapUserSettingsFromApi(userSettings: getUser.UserSettings): Type {
	return { ...userSettings, ['Recipe.Nutrient.Columns']: ValueScopeList.create() };
}

export function mapUserSettingsToApi(userSettings: Type | null): putUser.UserSettings | undefined {
	if (!userSettings) {
		return undefined;
	}

	return {
		['Price.Currency']: userSettings['Price.Currency'],
		['Recipe.List.ValueScope']: userSettings['Recipe.List.ValueScope'],
	};
}

export function mapUserSettingsOfNewUserToApi(
	userSettings: Type | null
): postUser.UserSettings | undefined {
	if (!userSettings) {
		return undefined;
	}

	return {
		['Price.Currency']: userSettings['Price.Currency'],
		['Recipe.List.ValueScope']: userSettings['Recipe.List.ValueScope'],
	};
}

export function mapUserProfileSettingsFromApi(userSettings: getUserProfile.UserSettings): Type {
	return { ...userSettings, ['Recipe.Nutrient.Columns']: ValueScopeList.create() };
}

export function mapUserProfileSettingsToApi(
	userSettings: Type | null
): putUserProfile.UserSettings | undefined {
	if (!userSettings) {
		return undefined;
	}

	return {
		['Price.Currency']: userSettings['Price.Currency'],
		['Recipe.List.ValueScope']: userSettings['Recipe.List.ValueScope'],
	};
}
