import { useState } from 'react';

import randomUUIDUnsafe from 'functions/randomUUIDUnsafe';

export type RefreshType = (f: () => Promise<any>) => Promise<void>;

export function useRefreshAsync() {
	const [data, setData] = useState(randomUUIDUnsafe());

	const refresh = async (f: () => Promise<any>) => {
		await f();
		setData(randomUUIDUnsafe());
	};

	return [data, refresh] as [string, RefreshType];
}

/* eslint-enable no-redeclare */
