import TimeUnitManagementComponent from 'components/desktop/StandingData/TimeUnit/Management/TimeUnitManagementComponent';

const TimeUnitManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<TimeUnitManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default TimeUnitManagementContainer;
