import * as apiDelete from 'api/status/DeleteStatusIdV1';
import * as apiRestore from 'api/status/PostStatusRestoreV1';
import * as apiPostSearch from 'api/status/PostStatusSearchV1';

export default class Status {
	public id: string = '';
	public name: string = '';
	public order: number = 0;
	public color: string = '';

	public constructor(input?: apiPostSearch.ResponseStatus) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseStatus) {
		this.id = input.id;
		this.name = input.name;
		this.order = input.order;
		this.color = input.color;
	}
}
