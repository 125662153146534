import * as apiPostSearch from 'api/accessory/PostAccessorySearchV1';

export default class Category {
	id: string = '';
	name: string = '';

	public constructor(input?: apiPostSearch.Category) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.Category): void {
		this.id = input.id;
		this.name = input.description;
	}
}
