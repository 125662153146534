import * as apiGet from 'api/nutrientCategory/GetNutrientCategoryIdV1';
import * as apiPost from 'api/nutrientCategory/PostNutrientCategoryV1';
import * as apiPut from 'api/nutrientCategory/PutNutrientCategoryIdV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class NutrientCategory {
	public id: string = '';
	public nameTranslations: LocalizedTranslation<string> = {};
	public parentId: string | null = null;
	public sortOrder: number = 0;

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			parentId: this.parentId,
			nameTranslations: this.nameTranslations,
			sortOrder: this.sortOrder,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			parentId: this.parentId,
			nameTranslations: this.nameTranslations,
			sortOrder: this.sortOrder,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.nameTranslations = input.nameTranslations;
		this.parentId = input.parentId;
		this.sortOrder = input.sortOrder;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
	}
}
