import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Column from 'classes/MenuPlan/Detail/Column';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import 'components/desktop/MenuPlan/Nutrients/MenuPlanNutrients.scss';
import { getNumberString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';

interface IProps {
	object: Menu | Recipe | MenuPlan | Row | Column;
}

const MenuPlanNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getParts = (nutrientValue: number): string => {
		const result: number = props.object.getNutrientPerPart(nutrientValue);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const getPersons = (nutrientValue: number): string => {
		const result: number = props.object.getNutrientPerPerson(nutrientValue);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const getTotal = (nutrientValue: number): string => {
		const result: number = props.object.getNutrientTotal(nutrientValue);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return props.object.getNutrientCategoryGroups();
	};

	const renderTableHeadRow = (): JSX.Element => {
		if (props.object instanceof Recipe) {
			return (
				<tr>
					<th>
						<label>{t('recipe:NUTRIENT')}</label>
					</th>
					<th className="text-end">
						<label>{t('measurments:PER_PART')}</label>
					</th>
					<th className="text-end">
						<label>{t('measurments:PER_PERSON')}</label>
					</th>
					<th className="text-end">
						<label>{t('_general:TOTAL')}</label>
					</th>
				</tr>
			);
		}
		if (
			props.object instanceof MenuPlan ||
			props.object instanceof Row ||
			props.object instanceof Column ||
			props.object instanceof Menu
		) {
			return (
				<tr>
					<th>
						<label>{t('recipe:NUTRIENT')}</label>
					</th>
					<th className="text-end">
						<label>{t('measurments:PER_PERSON')}</label>
					</th>
				</tr>
			);
		}
		return <></>;
	};

	const renderTableBodyRow = (nutrient: CalculatedNutrient, index: number): JSX.Element => {
		if (props.object instanceof Recipe) {
			return (
				<tr key={index}>
					<td>
						{`${nutrient.nutrient} `}
						<span className="nutrient-table-unit">{`(${nutrient.unit})`}</span>
					</td>
					<td className="text-end no-wrap">{getParts(nutrient.total)}</td>
					<td className="text-end no-wrap">{getPersons(nutrient.total)}</td>
					<td className="text-end no-wrap">{getTotal(nutrient.total)}</td>
				</tr>
			);
		}
		if (
			props.object instanceof MenuPlan ||
			props.object instanceof Row ||
			props.object instanceof Column ||
			props.object instanceof Menu
		) {
			return (
				<tr key={index}>
					<td>
						{`${nutrient.nutrient} `}
						<span className="nutrient-table-unit">{`(${nutrient.unit})`}</span>
					</td>
					<td className="text-end no-wrap">{getPersons(nutrient.total)}</td>
				</tr>
			);
		}
		return <></>;
	};

	return (
		<fieldset>
			<div className="d-flex flex-gap-10">
				<legend style={{ width: 'auto' }}>{t('_general:NUTRIENTS')}</legend>
			</div>
			<div className="row">
				{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
					<div className="col-md-6" key={i}>
						<div className="table-header">{group.name}</div>
						<div className="scrollable-table-wrapper">
							<table className="align-middle table table-striped table-hover table-sm">
								<thead className="menu-nutrients-thead">
									{renderTableHeadRow()}
								</thead>
								<tbody className="menu-nutrients-tbody">
									{group.nutrients.map(
										(nutrient: CalculatedNutrient, i: number) => {
											if (nutrient.total >= 0.005) {
												return renderTableBodyRow(nutrient, i);
											}
										}
									)}
								</tbody>
							</table>
						</div>
					</div>
				))}
			</div>
		</fieldset>
	);
};

export default MenuPlanNutrients;
