export type NutriScoreNutrientsAdvancedSearch = {
	searchTerm: string;
	pageIndex: number;
	pageSize: number;
	id: string | null;
};

export const defaultNutriScoreNutrientsAdvancedSearch = (): NutriScoreNutrientsAdvancedSearch => {
	return {
		searchTerm: '',
		pageIndex: 0,
		pageSize: 12,
		id: null,
	};
};
