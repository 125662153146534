import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/unit/GetUnitIdV1';
import Unit from 'classes/StandingData/Unit/Unit';
import UnitLanguageInput from 'components/desktop/StandingData/Unit/LanguageInput/UnitLanguageInput';
import UnitSynonyms from 'components/desktop/StandingData/Unit/UnitSynonyms';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { UnitType } from 'enums/unitType';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const UnitDetail = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [unit, setUnit] = useState<Unit>(new Unit());
	const [id, setId] = useState<string | null>(null);
	const [unitTypes, setUnitTypes] = useState<{ name: string; value: string }[]>([]);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		initializeUnitTypes();
		if (id !== null && id !== 'new') {
			getUnit(id);
		}
	};

	const getUnit = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setUnit(new Unit(x)));
		setIsLoading(false);
	};

	const initializeUnitTypes = () => {
		const unitTypesCpy = [];
		unitTypesCpy.push({ value: '', name: '-' });
		unitTypesCpy.push({ value: UnitType.Container, name: t('standingData:CONTAINER') });
		unitTypesCpy.push({ value: UnitType.Quantifier, name: t('standingData:QUANTIFIER') });
		unitTypesCpy.push({ value: UnitType.Volume, name: t('standingData:VOLUME') });
		unitTypesCpy.push({ value: UnitType.Weight, name: t('standingData:WEIGHT') });
		setUnitTypes(unitTypesCpy);
	};

	const handleUnitTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		unit.type = (e.target.value as UnitType) || null;

		if (unit.type != UnitType.Container) {
			if (unit.type != UnitType.Volume) unit.factorToMilliliter = null;
			if (unit.type != UnitType.Weight) unit.factorToGram = null;
			if (unit.type != UnitType.Quantifier) unit.factorToOne = null;
		}

		setUnit(clone(unit));
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		switch (name) {
			case 'nameSingularTranslations':
				unit.nameSingularTranslations[selectedCultureCode] = value;
				break;
			case 'namePluralTranslations':
				unit.namePluralTranslations[selectedCultureCode] = value;
				break;
			case 'shortNameSingularTranslations':
				unit.shortNameSingularTranslations[selectedCultureCode] = value;
				break;
			case 'shortNamePluralTranslations':
				unit.shortNamePluralTranslations[selectedCultureCode] = value;
				break;
			case 'factorToMilliliter':
				unit.factorToMilliliter = Number(value);
				break;
			case 'factorToGram':
				unit.factorToGram = Number(value);
				break;
			case 'factorToOne':
				unit.factorToOne = Number(value);
				break;
		}
		setUnit(clone(unit));
	};

	const handleTranslate = async () => {
		unit.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveUnit = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await unit.callApiPut();
			} else {
				await unit.callApiPost();
				if (!unit.id) return;
				setId(unit.id);
				setIdToUrl(unit.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{unit.id ? (
								<>
									<h1>{t('standingData:EDIT_UNIT')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_UNIT')}</h1>
								</>
							)}
						</div>
						<RenderIf condition={!unit.isDisabled()}>
							<div>
								<BtnSave
									handleSave={() => handleSaveUnit()}
									isLoading={isLoadingSave}
								/>
							</div>
						</RenderIf>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label>{t('standingData:TYPE')}</label>
						<select
							className="form-select"
							value={unit.type ?? ''}
							name="unitTypeSelect"
							onChange={handleUnitTypeChange}
							error-key={'Type'}
							disabled={unit.isDisabled()}
						>
							{unitTypes.map(
								(
									unitType: { name: string; value: string | undefined },
									key: number
								) => (
									<option key={key} value={unitType.value}>
										{unitType.name}
									</option>
								)
							)}
						</select>
						<br />
						<div className="row mb-3">
							<label>{t('standingData:FACTOR_TO_ONE')}</label>
							<div>
								<input
									value={unit.factorToOne ?? ''}
									type="number"
									onChange={handleInputValueChange}
									className="form-control"
									name="factorToOne"
									error-key={'FactorToOne'}
									disabled={
										!(
											unit.type == UnitType.Container ||
											unit.type == UnitType.Quantifier
										) || unit.isDisabled()
									}
								/>
							</div>
						</div>
						<div className="row mb-3">
							<label>{t('standingData:FACTOR_TO_MILLILITER')}</label>
							<div>
								<input
									value={unit.factorToMilliliter ?? ''}
									type="number"
									onChange={handleInputValueChange}
									className="form-control"
									name="factorToMilliliter"
									error-key={'FactorToMilliliter'}
									disabled={
										!(
											unit.type == UnitType.Container ||
											unit.type == UnitType.Volume
										) || unit.isDisabled()
									}
								/>
							</div>
						</div>
						<div className="row mb-3">
							<label>{t('standingData:FACTOR_TO_GRAM')}</label>
							<div>
								<input
									value={unit.factorToGram ?? ''}
									type="number"
									onChange={handleInputValueChange}
									className="form-control"
									name="factorToGram"
									error-key={'FactorToGram'}
									disabled={
										!(
											unit.type == UnitType.Container ||
											unit.type == UnitType.Weight
										) || unit.isDisabled()
									}
								/>
							</div>
						</div>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12" style={{ width: '720px' }}>
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<>
									<UnitLanguageInput
										unit={unit}
										selectedLanguage={selectedCultureCode}
										handleValueChange={handleInputValueChange}
									/>
									<UnitSynonyms
										unit={unit}
										setUnit={setUnit}
										cultureCode={selectedCultureCode}
									/>
								</>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default UnitDetail;
