import * as apiPost from 'api/validatorResults/PostValidatorResultsListV1';
import * as Search from 'types/Validation/Search';
import * as Validation from 'types/Validation/Validation';
import * as ValidationList from 'types/Validation/ValidationList';

export type Type = {
	data: Validation.Type[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export function create(): Type {
	return {
		data: [],
		pageIndex: 1,
		pageSize: 10,
		totalCount: 0,
		totalPages: 0,
		hasPreviousPage: false,
		hasNextPage: false,
	};
}

export async function getFromApi(search: Search.Type): Promise<Type> {
	const response = await apiPost.callApi(Search.mapToApi(search));
	const responseData = response.toNullable();
	if (responseData) {
		return mapFromApi(responseData);
	}
	return ValidationList.create();
}

function mapFromApi(input: apiPost.ResponseData): Type {
	return input;
}
