import * as apiDelete from 'api/unit/DeleteUnitIdV1';
import * as apiRestore from 'api/unit/PostUnitRestoreV1';
import * as apiPostSearch from 'api/unit/PostUnitSearchV1';
import PERMISSIONS from 'enums/permissions';
import { UnitType } from 'enums/unitType';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';

export default class Unit {
	public id: string = '';
	public nameSingular: string = '';
	public factorToGram: number | null = null;
	public factorToMilliliter: number | null = null;
	public factorToOne: number | null = null;
	public shortNameSingular: string = '';
	public type: UnitType = UnitType.Container;
	public isAirDefinition: boolean = true;

	public constructor(input?: apiPostSearch.ResponseUnit) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public isDisabled(): boolean {
		if (arePermissionsInUserPermissions([PERMISSIONS.ADMINISTRATE])) return false;
		if (this.isAirDefinition) return true;
		if (!arePermissionsInUserPermissions([PERMISSIONS.WRITEUNIT])) return true;
		return false;
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseUnit) {
		this.id = input.id;
		this.nameSingular = input.nameSingular;
		this.factorToGram = input.factorToGram;
		this.factorToMilliliter = input.factorToMilliliter;
		this.factorToOne = input.factorToOne;
		this.shortNameSingular = input.shortNameSingular;
		this.type = input.type;
		this.isAirDefinition = input.isAirDefinition;
	}
}
