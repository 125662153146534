import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import 'components/desktop/_general/RangeDouble/RangeDouble.scss';
import delay from 'functions/delay';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { regexValidationCalories } from 'regex/validation/Calories';

interface IProps {
	min: number;
	max: number;
	minArea: number;
	maxArea: number;
	handleChange: (left: number, right: number, id?: string) => void;
	id?: string;
	left?: number;
	right?: number;
}

const RangeDouble = (props: IProps) => {
	const { t } = useTranslation();

	const [min, setMin] = useState<number>(props.min);
	const [max, setMax] = useState<number>(props.max);
	const [showPopup, setShowPopup] = useState<boolean>(false);

	const slider = useRef<HTMLDivElement>(null);
	const gap = useRef<HTMLDivElement>(null);
	const area = useRef<HTMLDivElement>(null);
	const pointMin = useRef<HTMLDivElement>(null);
	const pointMax = useRef<HTMLDivElement>(null);
	const width = useWindowWidth();

	let shiftX: number = 0;
	let minGlobal: number = props.left ?? 0;
	let maxGlobal: number = props.right ?? 0;

	useEffect(() => {
		calculateMinMax();
	}, [props.left, props.right]);

	useEffect(() => {
		calculateArea();
	}, [props.minArea, props.maxArea]);

	useEffect((): void => {
		if (gap.current && pointMin.current && pointMax.current && slider.current) {
			setMin(Number(min));
			if (pointMin.current) {
				pointMin.current.style.left = calculateStyleLeftMin(getDefaultMin()) + 'px';
				adjustgap();
			}
			setMax(Number(max));
			if (pointMax.current) {
				pointMax.current.style.left = calculateStyleLeftMax(getDefaultMax()) + 'px';
				adjustgap();
			}
		}
	}, [width]);

	useEffect((): void => {
		if (gap.current && pointMin.current && pointMax.current && slider.current) {
			setMin(Number(String(getDefaultMin())));
			pointMin.current.style.left = calculateStyleLeftMin(Number(getDefaultMin())) + 'px';
			setMax(Number(String(getDefaultMax())));
			pointMax.current.style.left = calculateStyleLeftMax(Number(getDefaultMax())) + 'px';
			adjustgap();
		}
	}, []);

	const calculateMinMax = async () => {
		await delay(500);
		setMin(props.left ?? 0);
		if (pointMin.current) {
			pointMin.current.style.left = calculateStyleLeftMin(props.left ?? 0) + 'px';
			adjustgap();
		}
		setMax(props.right ?? 0);
		if (pointMax.current) {
			pointMax.current.style.left = calculateStyleLeftMax(props.right ?? 0) + 'px';
			adjustgap();
		}
	};

	const getDefaultMin = (): number => {
		if (props.left) {
			if (props.left > props.min) {
				return props.left;
			}
		}
		return props.min;
	};

	const getDefaultMax = (): number => {
		if (props.right) {
			if (props.right < props.max) {
				return props.right;
			}
		}
		return props.max;
	};

	const onMouseDownMin = (event: any): void => {
		event.preventDefault();

		if (pointMin.current) {
			shiftX = event.clientX - pointMin.current.getBoundingClientRect().left;
			document.addEventListener('mousemove', onMouseMoveMin);
			document.addEventListener('mouseup', onMouseUpMin);
		}
	};

	const onMouseMoveMin = (event: any) => {
		if (slider.current && pointMin.current && pointMax.current) {
			let newMin: number =
				event.clientX - shiftX - slider.current.getBoundingClientRect().left;
			if (newMin < 0) newMin = 0;

			const rightEdge = slider.current.offsetWidth - pointMin.current.offsetWidth;

			if (newMin > rightEdge) newMin = rightEdge;

			const pointMaxLeft = Number.parseFloat(pointMax.current.style.left) - 20;
			if (newMin > pointMaxLeft) newMin = pointMaxLeft;

			pointMin.current.style.left = newMin + 'px';
			setMin(calculate(newMin + 20));
			minGlobal = calculate(newMin + 20);
			adjustgap();
		}
	};

	const onMouseDownMax = (event: any): void => {
		event.preventDefault();

		if (pointMax.current) {
			shiftX = event.clientX - pointMax.current.getBoundingClientRect().left;
			document.addEventListener('mousemove', onMouseMoveMax);
			document.addEventListener('mouseup', onMouseUpMax);
		}
	};

	const onMouseMoveMax = (event: any) => {
		if (slider.current && pointMin.current && pointMax.current) {
			let newMax: number =
				event.clientX - shiftX - slider.current.getBoundingClientRect().left;
			if (newMax < 0) newMax = 0;

			const rightEdge = slider.current.offsetWidth - pointMax.current.offsetWidth;

			if (newMax > rightEdge) newMax = rightEdge;

			const pointMinLeft = Number.parseFloat(pointMin.current.style.left) + 20;
			if (newMax < pointMinLeft) newMax = pointMinLeft;

			pointMax.current.style.left = newMax + 'px';
			setMax(calculate(newMax));
			maxGlobal = calculate(newMax);
			adjustgap();
		}
	};

	const onMouseUpMax = () => {
		props.handleChange(getDefaultMin(), maxGlobal, props.id ?? undefined);
		document.removeEventListener('mouseup', onMouseUpMax);
		document.removeEventListener('mousemove', onMouseMoveMax);
	};

	const onMouseUpMin = () => {
		props.handleChange(minGlobal, getDefaultMax(), props.id ?? undefined);
		document.removeEventListener('mouseup', onMouseUpMin);
		document.removeEventListener('mousemove', onMouseMoveMin);
	};

	const adjustgap = () => {
		if (gap.current && pointMin.current && pointMax.current) {
			const left = Number.parseFloat(pointMin.current.style.left);
			const right = Number.parseFloat(pointMax.current.style.left);
			gap.current.style.left = left + 10 + 'px';
			gap.current.style.width = right - left + 'px';
		}
		calculateArea();
	};

	const calculate = (input: number): number => {
		if (slider.current) {
			const end: number = slider.current.offsetWidth - 20;
			const start: number = 20;
			const percent: number = 100 * ((input - 20) / (end - start));
			const result = Math.round(((props.max - props.min) / 100) * percent + props.min);
			if (result > props.max) return props.max;
			if (result < props.min) return props.min;
			return result;
		}
		return 0;
	};

	const calculateStyleLeftMin = (input: number): number => {
		if (slider.current) {
			const percent: number = 100 * ((input - props.min) / (props.max - props.min));
			const result = Math.round(((slider.current.offsetWidth - 20 - 20) / 100) * percent);

			if (result > slider.current.offsetWidth - 40) return slider.current.offsetWidth - 40;
			if (result < 0) return 0;
			return result;
		}
		return 0;
	};

	const calculateStyleLeftMax = (input: number): number => {
		if (slider.current) {
			const percent: number = 100 * ((input - props.min) / (props.max - props.min));
			const result = Math.round(((slider.current.offsetWidth - 20) / 100) * percent);
			if (result > slider.current.offsetWidth - 20) return slider.current.offsetWidth - 20;
			if (result < 0) return 0;
			return result;
		}
		return 0;
	};

	const calculateArea = (): void => {
		if (slider.current && area.current) {
			let minArea: number = props.minArea;
			let maxArea: number = props.maxArea;
			if (minArea > props.max || minArea < props.min) minArea = props.min;
			if (maxArea > props.max || maxArea < props.min) maxArea = props.max;
			const percentMin: number = (100 / (props.max - props.min)) * (minArea - props.min);
			const percentMax: number = (100 / (props.max - props.min)) * (maxArea - props.min);
			const width: number = slider.current.offsetWidth;
			const newMinArea: number = ((width - 20) / 100) * percentMin;
			const newMaxArea: number = ((width - 20) / 100) * percentMax;

			area.current.style.left = newMinArea + 10 + 'px';
			area.current.style.width = newMaxArea - newMinArea + 'px';
		}
	};

	const handleMinChange = (input: string): void => {
		if (Number(input) !== min) {
			setMin(Number(input));
			props.handleChange(Number(input), max, props.id ?? undefined);
			if (pointMin.current) {
				pointMin.current.style.left = calculateStyleLeftMin(Number(input)) + 'px';
				adjustgap();
			}
		}
	};

	const handleMaxChange = (input: string): void => {
		if (Number(input) !== max) {
			setMax(Number(input));
			props.handleChange(min, Number(input), props.id ?? undefined);
			if (pointMax.current) {
				pointMax.current.style.left = calculateStyleLeftMax(Number(input)) + 'px';
				adjustgap();
			}
		}
	};

	const activatePopup = (): void => {
		setShowPopup(true);
	};

	const deactivatePopup = (): void => {
		setShowPopup(false);
	};

	const renderArea = (minArea: number, maxArea: number): JSX.Element => {
		if (minArea === 0 && maxArea === 0) {
			return (
				<div
					ref={area}
					id="area"
					style={{
						backgroundColor: 'transparent',
					}}
				>
					<div id="area-popup" style={{ display: showPopup ? 'block' : 'none' }}>
						<div>
							<div>{t('_general:NO_VALUES')}</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div
					ref={area}
					id="area"
					style={{
						backgroundColor: showPopup ? 'black' : '',
					}}
				>
					<div id="area-popup" style={{ display: showPopup ? 'block' : 'none' }}>
						<div>
							<div>
								{minArea} – {maxArea}
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<>
			<div className="double-range-component">
				<InputFieldSimple
					defaultValue={String(Math.floor(min))}
					handleValueChange={handleMinChange}
					handleEnterDown={handleMinChange}
					validateRegex={regexValidationCalories}
				/>
				<div
					ref={slider}
					className="double-range-slider"
					onMouseEnter={activatePopup}
					onMouseLeave={deactivatePopup}
				>
					<div ref={gap} id="gap"></div>
					{renderArea(props.minArea, props.maxArea)}
					<div ref={pointMin} id="pointMin" onMouseDown={onMouseDownMin}></div>
					<div ref={pointMax} onMouseDown={onMouseDownMax} id="pointMax"></div>
				</div>
				<InputFieldSimple
					defaultValue={String(Math.ceil(max))}
					handleValueChange={handleMaxChange}
					handleEnterDown={handleMaxChange}
					validateRegex={regexValidationCalories}
				/>
			</div>
		</>
	);
};

export default RangeDouble;
