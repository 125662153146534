import ActivityManagementComponent from 'components/desktop/StandingData/Activity/Management/ActivityManagementComponent';

const ActivityArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ActivityManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ActivityArchiveContainer;
