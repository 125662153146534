import axios from 'axios';

import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function apiCall(id: string, putObject: Request): Promise<Optional<apiGet.ResponseData>> {
	const url: string = `/backend-service/MenuPlanner/${id}`;
	return axios.put<apiGet.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	systemStatus?: SystemStatus;
	statusId: string;
	caloriesLimit?: number | null;
	priceLimit?: number | null;
	startDate?: string;
	durationDays?: number;
	titleTranslations: Translation;
	rows?: Row[] | null;
}

export type Row = {
	id?: string | null;
	nameTranslations: Translation;
	items: Item[] | null;
	tags: string[] | null;
};

export type Item = {
	id: string;
	type: MENUPLANNERITEMTYPE;
	day: number;
};

export type Translation = {
	[key: string]: string;
};
