import * as api from 'api/recipe/PostRecipeCalculateV1';
import NutrientValue from 'classes/Recipe/Detail/Calculation/NutrientValue';

export default class NutrientValuesPerIngredient {
	public ingredientIndex: number = 0;
	public ingredientId: string = '';
	public nutrientValues: NutrientValue[] = [];

	public constructor(input?: api.NutrientValuesPerIngredient) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.NutrientValuesPerIngredient) {
		this.ingredientIndex = input.ingredientIndex;
		this.ingredientId = input.ingredientId;
		this.nutrientValues = [];
		for (const i of input.nutrientValues) {
			this.nutrientValues.push(new NutrientValue(i));
		}
		this.nutrientValues;
	}
}
