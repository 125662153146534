import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Dashboard from 'containers/desktop/Dashboard/Dashboard';
import SystemInfo from 'containers/desktop/SystemInfo/SystemInfo';

const routes = [
	{
		path: '/dashboard',
		element: <Dashboard />,
		permissions: [],
	},
	{
		path: '/systemInfo',
		element: <SystemInfo />,
		permissions: [],
	},
];

export default routes.map(wrapProtectedRoute);
