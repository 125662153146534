import ActivityManagementComponent from 'components/desktop/StandingData/Activity/Management/ActivityManagementComponent';

const ActivityManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ActivityManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ActivityManagementContainer;
