import { ISelectItem } from 'interfaces/ISelectItem';

import * as Item from './Item';

export type Type = Item.Type[];

export function create(): Type {
	return [];
}

export function mapToISelectItemsFromIdArray(obj: Type, ids: string[]): ISelectItem[] {
	const output = obj.filter((e) => ids.includes(e.id));
	return mapToISelectItems(output);
}

export function mapFromIdArray(obj: Type, ids: string[]): Type {
	return obj.filter((e) => ids.includes(e.id));
}

export function mapToISelectItems(obj: Type): ISelectItem[] {
	return obj.map((e) => Item.mapToISelectItem(e));
}

export function getSuggestedItems(obj: Type, ids: string[], searchTerm: string): ISelectItem[] {
	const output = obj
		.filter((e) => !ids.includes(e.id))
		.filter((e) => e.name.toLowerCase().includes(searchTerm.toLowerCase()))
		.filter((e) => {
			if (e.id.includes(':')) {
				const id = e.id.split(':')[0];
				if (ids.some((e) => e.includes(id))) return false;
			}
			return true;
		});
	return mapToISelectItems(output);
}
