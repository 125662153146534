import { ContextBuilder } from 'context/nutriscoreLabelContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import * as apiGetUserSearch from 'api/user/GetUserSearchV1';
import UserSearchInput from 'components/desktop/User/Admin/Search/UserSearchInput';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import {
	IUserListRow,
	UserListRowDiscriminatorName,
} from 'components/desktop/_general/SearchResultList/IUserListRow';
import SearchResultList from 'components/desktop/_general/SearchResultList/SearchResultList';
import 'containers/desktop/User/Admin/Search/UserSearch.scss';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { UserSearchUserDto } from 'types/User/Admin/UserSearch/UserSearchUserDto';

const listItemAmount = 5;
const maxNumberOfButtons = 10;

const UserSearch = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [users, setUsers] = useState<UserSearchUserDto[]>([]);
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

	const navigate = useNavigate();
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);

	useEffect(() => {
		apiGet();
	}, []);

	useEffectWhenMounted(() => {
		apiGet();
	}, [pageIndex]);

	const apiGet = async () => {
		setIsLoading(true);
		const response = await apiGetUserSearch.callApi({
			searchTerm: searchTerm,
			pageIndex: pageIndex,
			pageSize: listItemAmount,
		});
		if (response.hasValue()) {
			setUsers(response.get().data);
			setTotalUsersCount(response.get().totalCount);
			setPageIndex(response.get().pageIndex);
		}
		setIsLoading(false);
	};

	const handleSearch = () => {
		if (pageIndex === 0) {
			apiGet();
		} else {
			setPageIndex(0);
			apiGet();
		}
	};

	const editUserClickFunction = (id: string): (() => void) => {
		return () => {
			navigate(`/user/detail/${id}`);
		};
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		return {
			onClick: editUserClickFunction(id),
		};
	};

	const renderButtons = (user: UserSearchUserDto): JSX.Element => {
		return <BtnGroup edit={getButtonDataEdit(user.id)} />;
	};

	const getSearchResultListData = (): IUserListRow[] => {
		return users.map((user: UserSearchUserDto) => {
			const currentLanguage = reduxLanguages.find((x) => x.id === user.languageId);
			return {
				discriminator: UserListRowDiscriminatorName,
				id: user.id,
				buttons: renderButtons(user),
				imageUrl: user.profilePicture?.url,
				title: user.fullName ?? '',
				href: `/user/detail/${user.id}`,
				email: user.email,
				roles: user.roles.map((x) => x.name),
				isActive: user.isActive,
				cultureCode: currentLanguage ? currentLanguage.cultureCode : '',
			};
		});
	};

	return (
		<>
			<main className="container">
				<div className="d-flex justify-content-between">
					<h1>{t('_general:USER_MANAGEMENT')}</h1>
					<BtnNew link={'/user/new'} name={t('admin:CREATE_USER')} />
				</div>
				<br />
				<div className="row">
					<UserSearchInput
						search={handleSearch}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{`${totalUsersCount} ${t('admin:USERS')}`}</p>
				<SearchResultList data={getSearchResultListData()} />
				<br />
				<Pagination
					countOfAllListItems={totalUsersCount}
					pageIndex={pageIndex}
					setPageIndex={setPageIndex}
					listItemAmount={listItemAmount}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		</>
	);
};

export default ContextBuilder.From(UserSearch).wrapWithNutriscoreContext().build();
