import { NutriScoreCategory } from 'enums/nutriScoreCategory';

export type NutriScoreNutrient = {
	nutrientId: string;
	name: string | null;
	pointType: NutriScoreCategory;
	points: Point[];
	isPositive: boolean;
	isProtein: boolean;
};

export type Point = {
	points: number;
	minValue: number | null;
};

export const defaultNutriScoreNutrient = (): NutriScoreNutrient => {
	return {
		nutrientId: '',
		name: '',
		pointType: NutriScoreCategory.StandardFood,
		points: [],
		isPositive: false,
		isProtein: false,
	};
};
