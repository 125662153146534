import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModificationList } from 'api/analytics/GetModificationsV1';
import { barChartBorderColor, barChartFillColor } from 'enums/barChartColor';
import { mapMonth } from 'functions/mappers/Charts/mapMonths';
import { RootState } from 'reducers/rootReducer';
import { defaultModifiedResourcesBarChartData } from 'types/Analytics/BarChartData';

interface IProps {
	modifications: ModificationList;
}

const ModifiedResourcesChartBar = (props: IProps) => {
	const { t } = useTranslation();
	const [data, setData] = useState(defaultModifiedResourcesBarChartData);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const options = {
		animation: {
			duration: 1000,
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	};

	ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

	useEffect(() => {
		getData();
	}, [props.modifications]);

	useEffect(() => {
		getData();
	}, [reduxCultureCode]);

	const getData = () => {
		var cpyData = { ...data };

		cpyData = defaultModifiedResourcesBarChartData();

		var baseIngredientName = t('_general:BASEINGREDIENT');
		var ingredientName = t('_general:INGREDIENT');
		var recipeName = t('_general:RECIPE');

		const acceptedResources = [
			{
				name: baseIngredientName,
				fillColor: barChartFillColor.BaseIngredient,
				borderColor: barChartBorderColor.BaseIngredient,
			},
			{
				name: ingredientName,
				fillColor: barChartFillColor.Ingredient,
				borderColor: barChartBorderColor.Ingredient,
			},
			{
				name: recipeName,
				fillColor: barChartFillColor.Recipe,
				borderColor: barChartBorderColor.Recipe,
			},
		];

		const convertHexColorToRgba = (color: string, alpha: number): string => {
			if (!color.startsWith('#')) {
				return color;
			}
			const r = parseInt(color.substring(1, 3), 16);
			const g = parseInt(color.substring(3, 5), 16);
			const b = parseInt(color.substring(5, 7), 16);
			return `rgba(${r}, ${g}, ${b}, ${alpha})`;
		};

		Array.from(Object.keys(props.modifications))
			.slice(0, 6)
			.map((item: string) => {
				cpyData.labels.push(mapMonth(item));
				const modifications = props.modifications[item];
				acceptedResources.forEach((resource, index) => {
					const currentModification = modifications.find(
						(modification) => modification.resourceName === resource.name
					);

					if (currentModification !== undefined) {
						cpyData.datasets[index].data.push(currentModification.count);
						cpyData.datasets[index].backgroundColor.push(
							convertHexColorToRgba(currentModification.color!, 0.2)
						);
						cpyData.datasets[index].borderColor.push(
							convertHexColorToRgba(currentModification.color!, 1)
						);
					} else {
						cpyData.datasets[index].data.push(0);
						cpyData.datasets[index].backgroundColor.push(resource.fillColor);
						cpyData.datasets[index].borderColor.push(resource.borderColor);
					}
				});
			});

		setData(cpyData);
	};

	const renderContent = () => {
		return (
			<>
				<Bar options={options} data={data} style={{ maxHeight: '350px' }} />
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ModifiedResourcesChartBar;
