import { ContextBuilder } from 'context/nutriscoreLabelContext';

import IngredientSearchComponent from 'components/desktop/Ingredient/Search/IngredientSearchComponent';

const IngredientSearchContainer = () => {
	return (
		<main className="container">
			<IngredientSearchComponent />
		</main>
	);
};

export default ContextBuilder.From(IngredientSearchContainer).wrapWithNutriscoreContext().build();
