import * as api from 'api/recipe/PostRecipeCalculateV1';
import PricePerIngredient from 'classes/Recipe/Detail/Calculation/PricePerIngredient';

export default class PricePerIngredientList {
	public items: PricePerIngredient[] = [];

	public constructor(input?: api.PricePerIngredient[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.PricePerIngredient[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new PricePerIngredient(i));
		}
	}
}
