import AccessorySearchComponent from 'components/desktop/Accessory/Search/AccessorySearchComponent';

const AccessoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<AccessorySearchComponent archive={true} />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryArchiveContainer;
