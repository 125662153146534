import Swal from 'sweetalert2';

export async function showConfirmationDialog(
	title: string,
	confirmText: string,
	cancelText: string
) {
	return await Swal.fire({
		icon: 'info',
		iconColor: '#ff0004',
		background: 'white',
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
		showConfirmButton: true,
		title: title,
		confirmButtonColor: '#7da8d4',
		denyButtonColor: '#ff0004',
		position: 'top',
	});
}
