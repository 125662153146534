import { Store } from 'context/Store';
import { useEffect, useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import accessoryRoutes from 'routing/AccessoryRoutes';
import baseIngredientRoutes from 'routing/BaseIngredientRoutes';
import dashboardRoutes from 'routing/DashboardRoutes';
import ingredientRoutes from 'routing/IngredientRoutes';
import masterDataRoutes from 'routing/MasterData/index';
import menuPlanRoutes from 'routing/MenuPlanRoutes';
import menuRoutes from 'routing/MenuRoutes';
import productRoutes from 'routing/ProductRoutes';
import recipeRoutes from 'routing/RecipeRoutes';
import specialRoutes from 'routing/SpecialRoutes';
import userAccountRoutes from 'routing/UserAccountRoutes';

const standardRoutes = [
	{ path: '/', Component: () => <Navigate replace to="/login" /> },
	{ path: '*', Component: () => <Navigate to="/dashboard" /> },
];

interface IProps {
	store: Store;
}

const Router = ({ store }: IProps) => {
	const [router, setRouter] = useState<any>();

	useEffect(() => {
		const router = createBrowserRouter([
			...standardRoutes,
			...dashboardRoutes,
			...masterDataRoutes,
			...accessoryRoutes,
			...baseIngredientRoutes,
			...ingredientRoutes,
			...menuPlanRoutes,
			...menuRoutes,
			...recipeRoutes,
			...userAccountRoutes,
			...specialRoutes,
			...productRoutes,
		]);
		setRouter(router);
	}, [store]);
	if (router) return <RouterProvider router={router} />;

	return <></>;
};

export default Router;
