import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/tagCategory/GetTagCategoryIdV1';
import TagCategory from 'classes/StandingData/TagCategory/TagCategory';
import TagCategoryLanguageInput from 'components/desktop/StandingData/TagCategory/LanguageInput/TagCategoryLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const TagCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [tagCategory, setTagCategory] = useState<TagCategory>(new TagCategory());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getTagCategory(id);
		}
	};

	const getTagCategory = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setTagCategory(new TagCategory(x)));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		tagCategory.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setTagCategory(clone(tagCategory));
	};

	const handleTranslate = async () => {
		tagCategory.callApiTranslation(reduxCultureCode, selectedCultureCode);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveTagCategory = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await tagCategory.callApiPut();
			} else {
				await tagCategory.callApiPost();
				if (!tagCategory.id) return;
				setId(tagCategory.id);
				setIdToUrl(tagCategory.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{tagCategory.id ? (
								<>
									<h1>{t('standingData:EDIT_TAG_CATEGORY')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_TAG_CATEGORY')}</h1>
								</>
							)}
						</div>
						<div style={{ paddingLeft: '20px' }}>
							<BtnSave
								handleSave={() => handleSaveTagCategory()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<TagCategoryLanguageInput
									tagCategory={tagCategory}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TagCategoryDetailComponent;
