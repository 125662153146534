import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<string>> {
	const url = '/backend-service/NutriScoreIngredientCategoryPoints';
	return axios.post(url, requestBody).toOptionalMapped((x) => x.data);
}

export interface Request {
	ingredientCategoryId: string;
	pointType: NutriScoreCategoryIngredientCategory;
	points?: Point[];
	isPositive: boolean;
}

export interface Response {
	data: string;
}

export type Point = {
	points: number;
	minValue: number | null;
};
