import * as apiSW from 'api/tagCategory/GetTagCategoryStartingWithV1';
import * as apiPostSearch from 'api/tagCategory/PostTagCategorySearchV1';
import TagCategory from 'classes/StandingData/TagCategory/Search/TagCategory';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class TagCategoryList {
	public all: TagCategory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const tagCategories: apiSW.ResponseTagCategory[] = await this.callApiSW(
			searchTerm,
			showOnlyDeleted
		);
		this.mapFromApiSW(tagCategories);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseTagCategory[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const tagCategory: TagCategory = new TagCategory(i);
			this.all.push(tagCategory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseTagCategory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
