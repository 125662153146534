import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import * as apiGetLanguage from 'api/language/GetLanguageV1';
import * as apiGetNutrients from 'api/nutrient/GetNutrientV1';
import * as apiGetRole from 'api/role/GetRoleV1';
import * as apiGetSetting from 'api/setting/GetSettingKeyV1';
import * as apiGetUserId from 'api/user/GetUserIdV1';
import * as apiPostUser from 'api/user/PostUserCreateV1';
import * as apiPutUserId from 'api/user/PutUserIdV1';
import * as apiGetUserProfile from 'api/userProfile/GetUserProfileV1';
import * as apiPutUserProfile from 'api/userProfile/PutUserProfileV1';
import ChangeCurrency from 'components/desktop/User/User/ChangeCurrency';
import ChangeLanguage from 'components/desktop/User/User/ChangeLanguage';
import ChangeNutrients from 'components/desktop/User/User/ChangeNutrients';
import ChangePassword from 'components/desktop/User/User/ChangePassword';
import ChangeRecipeListValueScope from 'components/desktop/User/User/ChangeRecipeListValueScope';
import ChangeRoles from 'components/desktop/User/User/ChangeRole';
import 'components/desktop/User/User/UserComponent.scss';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import LoadingOverarching from 'components/desktop/_general/Loading/LoadingOverarching';
import PictureBig from 'components/desktop/_general/PictureBig/PictureBig';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { setReduxCultureCode } from 'features/CultureCodeFeature';
import { setReduxCurrency } from 'features/CurrencyFeature';
import { setReduxLanguages } from 'features/LanguagesFeature';
import { setReduxUser } from 'features/UserFeature';
import { setReduxUserSetting } from 'features/UserSettingsFeature';
import { useThunkDispatch } from 'features/featuresHelper';
import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { getLanguageString } from 'functions/getLanguageString';
import { getPicturePath } from 'functions/getPicturePath';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { uploadMedia } from 'functions/uploadMedia';
import isEmailValid from 'functions/validation/isEmailValid';
import isNameValid from 'functions/validation/isNameValid';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import * as Currency from 'types/Currency/User/Currency';
import { Language } from 'types/Language/Language';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { UserRole } from 'types/User/Admin/UserRole/UserRole';
import {
	User,
	defaultUser,
	mapNewUserToApi,
	mapUserFromApi,
	mapUserProfileFromApi,
	mapUserProfileToApi,
	mapUserToApi,
} from 'types/User/User/User';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

type ComponentType = 'new' | 'detail' | 'settings';

interface Props {
	type: ComponentType;
	id?: string;
}

const UserComponent = (props: Props) => {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [user, setUser] = useState<User>(defaultUser());
	const [nutrients, setNutrients] = useState<Nutrient[]>([]);
	const [roles, setRoles] = useState<UserRole[]>([]);
	const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
	const [allCurrencies, setAllCurrencies] = useState<Currency.Type[]>([]);
	const [primaryCurrency, setPrimaryCurrency] = useState<Currency.Type>(Currency.create());

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxAllowedUiLanguages: string[] = useSelector(
		(state: RootState) => state.allowedUiLanguages
	);
	const dispatch = useThunkDispatch();
	const navigate = useNavigate();

	useEffect((): void => {
		initialize();
	}, []);

	useEffectWhenMounted((): void => {
		initialize();
	}, [reduxCultureCode, reduxLanguages]);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		try {
			if (props.type === 'detail' && props.id) {
				const response = await apiGetUserId.callApi(props.id);
				response.map((x) => mapUserFromApi(x)).do((x) => setUser(x));
			}
			if (props.type === 'settings') {
				const currencies = await getCurrencies();
				setAllCurrencies(currencies);

				const response = await apiGetUserProfile.callApi();
				response
					.map((x) => mapUserProfileFromApi(x))
					.doAsync(async (x) => {
						setUser(x);
						await getPrimaryCurrency(currencies);
						await getCurrency(currencies, x);
					});
			}
			if (props.type === 'new' && reduxLanguages && reduxCultureCode && !user.languageId) {
				const activeLang = reduxLanguages.find((l) => l.cultureCode == reduxCultureCode);
				if (activeLang) {
					handleChangeLanguage(activeLang.id, reduxCultureCode);
				}
			}
		} finally {
			setIsLoading(false);
		}
		getActiveNutrients();

		if (props.type !== 'settings') {
			getRoles();
		}
	};

	const getPrimaryCurrency = async (currencies: Currency.Type[]) => {
		const response = await apiGetSetting.callApi('CurrencySettings.PrimaryCurrency');
		if (response.hasValue()) {
			const currency = currencies.find(
				(x: Currency.Type) => x.currencyCode == response.get()
			);
			if (currency) {
				setPrimaryCurrency(currency);
			}
		}
	};

	const getCurrencies = async () => {
		return await Currency.getAllFromApi();
	};

	const getCurrency = async (currencies: Currency.Type[], userProfile: User) => {
		let currency: Currency.Type | undefined = undefined;
		if (userProfile.userSettings) {
			const userSetting = userProfile.userSettings['Price.Currency'];
			if (userSetting) {
				currency = currencies.find((x: Currency.Type) => x.currencyCode == userSetting);
				if (currency) {
					setSelectedCurrency(currency.id);
				}
			} else {
				currency = currencies.find(
					(x: Currency.Type) => x.currencyCode == primaryCurrency.currencyCode
				);
				if (currency) {
					setSelectedCurrency(currency.id);
				}
			}
		} else {
			currency = currencies.find(
				(x: Currency.Type) => x.currencyCode == primaryCurrency.currencyCode
			);
			if (currency) {
				setSelectedCurrency(currency.id);
			}
		}
	};

	const updateReduxUser = (user: User): void => {
		dispatch(
			setReduxUser({
				firstName: user.firstName,
				lastName: user.lastName,
				cultureCode: user.cultureCode ?? '',
				profilePicture: user.profilePicture,
			})
		);
	};

	const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const userCpy: User = { ...user };
		userCpy.firstName = e.target.value;
		setUser(userCpy);
	};

	const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const userCpy: User = { ...user };
		userCpy.lastName = e.target.value;
		setUser(userCpy);
	};

	const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const userCpy: User = { ...user };
		userCpy.email = e.target.value;
		setUser(userCpy);
	};

	const handleChangePasswordCurrent = (e: React.ChangeEvent<HTMLInputElement>): void => {
		let value: string | null = e.target.value;
		if (value === '') value = null;
		const userCpy: User = { ...user };
		userCpy.passwordCurrent = value;
		setUser(userCpy);
	};

	const handleChangePasswordNew1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
		let value: string | null = e.target.value;
		if (value === '') value = null;
		const userCpy: User = { ...user };
		userCpy.passwordNew1 = value;
		setUser(userCpy);
	};

	const handleChangePasswordNew2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
		let value: string | null = e.target.value;
		if (value === '') value = null;
		const userCpy: User = { ...user };
		userCpy.passwordNew2 = value;
		setUser(userCpy);
	};

	const handleChangeLanguage = (languageId: string, cultureCode: string) => {
		const userCpy: User = { ...user };
		userCpy.languageId = languageId;
		userCpy.cultureCode = cultureCode;
		setUser(userCpy);
	};

	const handleChangeValueScope = (enumName: ValueScope): void => {
		const userCpy: User = { ...user };
		if (userCpy.userSettings === null) {
			userCpy.userSettings = {} as UserSettings.Type;
		}
		userCpy.userSettings['Recipe.List.ValueScope'] = enumName;
		setUser(userCpy);
	};

	const handleChangeIsActive = (input: boolean): void => {
		const userCpy: User = { ...user };
		userCpy.isActive = input;
		setUser(userCpy);
	};

	const handleAddNutrient = (items: ISelectItem[]) => {
		const userCpy: User = { ...user };
		userCpy.nutrients = [];
		for (const i of items) {
			const nutrient: Nutrient | undefined = nutrients.find((e) => e.id === i.id);
			if (nutrient) userCpy.nutrients.push(nutrient);
		}
		setUser(userCpy);
	};

	const handleAddRole = (items: ISelectItem[]) => {
		const userCpy: User = { ...user };
		userCpy.roles = [];
		for (const i of items) {
			const role: UserRole | undefined = roles.find((e) => e.id === i.id);
			if (role) userCpy.roles.push(role);
		}
		setUser(userCpy);
	};

	const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			handleSave();
		}
	};

	const getActiveNutrients = async () => {
		if (
			arePermissionsInUserPermissions([
				PERMISSIONS.READBASEINGREDIENT,
				PERMISSIONS.READINGREDIENT,
				PERMISSIONS.READMENU,
				PERMISSIONS.READMENUPLAN,
				PERMISSIONS.READRECIPE,
			])
		) {
			const activeNutrients = await apiGetNutrients.callApi();
			activeNutrients.do((n) => setNutrients([...n.filter((x) => !x.isMacroNutrient)]));
		}
	};

	const getRoles = async () => {
		const roles = await apiGetRole.callApi();
		roles.do((x) => setRoles(x));
	};

	const handleSavePicture = async (input: FormData | null): Promise<void> => {
		setIsLoading(true);
		let userCpy: User = { ...user };
		if (input) {
			userCpy = await uploadMedia<User>(userCpy, input, MEDIACOLLECTION.User);
		}
		setIsLoading(false);
		setUser(userCpy);
	};

	const handleSave = async () => {
		let id: string = '';
		setIsLoading(true);
		try {
			if (props.type === 'settings') {
				const userCpy = { ...user };
				const currency = allCurrencies.find((x: Currency.Type) => x.id == selectedCurrency);
				if (currency) {
					if (selectedCurrency != primaryCurrency.id) {
						if (!userCpy.userSettings) userCpy.userSettings = UserSettings.create();
						userCpy.userSettings['Price.Currency'] = currency.currencyCode;
					} else {
						if (userCpy.userSettings) {
							userCpy.userSettings['Price.Currency'] = undefined;
						}
					}
					dispatch(setReduxCurrency(currency.currencyCode));
					setUser(userCpy);
				}
				await apiPutUserProfile.callApi(mapUserProfileToApi(userCpy));
				updateReduxUser(userCpy);
				await changeLanguage(getCultureCodeFromLocalStorage() ?? 'de-CH');
				dispatch(setReduxUserSetting(userCpy.userSettings ?? UserSettings.create()));
				setIsLoading(false);
			}
			if (props.type === 'detail') {
				if (props.id) {
					await apiPutUserId.callApi(props.id, mapUserToApi(user));
					setIsLoading(false);
				}
			}
			if (props.type === 'new') {
				const response = await apiPostUser.callApi(mapNewUserToApi(user));
				if (response.hasValue()) {
					id = response.get();
					setIsLoading(false);
					navigate('/user/detail/' + id);
				}
			}
		} finally {
			setIsLoading(false);
		}
	};

	const changeLanguage = async (cultureCode: string) => {
		const language: string = getLanguageString(cultureCode);
		localStorage.setItem('cultureCode', cultureCode);
		i18n.changeLanguage(language);
		const languages: Language[] = await getLanguagesFromApi();
		dispatch(setReduxCultureCode(cultureCode));
		dispatch(setReduxLanguages(languages));
	};

	const getLanguagesFromApi = async (): Promise<Language[]> => {
		return await apiGetLanguage.callApi().mapAsync((x) => x.getOrDefault([]));
	};

	const handleSetFormData = (input: FormData | null) => {
		handleSavePicture(input);
	};

	const handleChangeSelectedCurrency = async (currencyId: string) => {
		setIsLoading(true);
		setSelectedCurrency(currencyId);
		setIsLoading(false);
	};

	const checkPermission = (permissions: PERMISSIONS[]): boolean => {
		if (props.type === 'settings') {
			return arePermissionsInUserPermissions(permissions);
		} else {
			return true;
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingOverarching isLoading={isLoading} />
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						<div>
							<h1>{t('_general:SETTINGS')}</h1>
						</div>
						<div>
							<BtnSave handleSave={handleSave} isLoading={isLoading} />
						</div>
					</div>
					<br />
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3">
							<PictureBig
								path={getPicturePath(user.profilePicture, 370)}
								round={true}
							/>
							<div className="margin-top-5">
								<PictureUpload setFormData={handleSetFormData} />
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<CardTransparent
								title={t('user:ALLGEMEIN')}
								className="mb-3"
								content={
									<>
										<ValidatedInput
											id="firstName"
											label={t('_general:FIRSTNAME')}
											value={user.firstName}
											type="text"
											isValid={isNameValid}
											onKeyDown={handleKeyboardEvent}
											handleValueChange={handleChangeFirstName}
											className="mb-2"
											errorKey="FirstName"
										/>
										<ValidatedInput
											id="lastName"
											label={t('_general:LASTNAME')}
											value={user.lastName}
											type="text"
											isValid={isNameValid}
											onKeyDown={handleKeyboardEvent}
											handleValueChange={handleChangeLastName}
											className="mb-2"
											errorKey="LastName"
										/>
										{['detail', 'new'].includes(props.type) && (
											<ValidatedInput
												id="email"
												label={t('_general:EMAIL')}
												value={user.email ?? ''}
												type="text"
												isValid={isEmailValid}
												onKeyDown={handleKeyboardEvent}
												handleValueChange={handleChangeEmail}
												className="mb-2"
												errorKey="Email"
											/>
										)}
										<RenderIf condition={reduxAllowedUiLanguages.length > 1}>
											<ChangeLanguage
												currentLanguageId={user.languageId}
												handleChangeLanguage={handleChangeLanguage}
											/>
										</RenderIf>
										{['detail', 'new'].includes(props.type) && (
											<Toggle
												checked={user.isActive ?? true}
												handleToggle={handleChangeIsActive}
												elementId={'isActive'}
												label={'admin:IS_ACTIVE'}
												className="mt-3"
												error-key="Admin"
											/>
										)}
									</>
								}
							/>
							{props.type === 'settings' && (
								<ChangePassword
									handleKeyboardEvent={handleKeyboardEvent}
									passwordCurrent={user.passwordCurrent}
									passwordNew1={user.passwordNew1}
									passwordNew2={user.passwordNew2}
									handleChangePasswordCurrent={handleChangePasswordCurrent}
									handleChangePasswordNew1={handleChangePasswordNew1}
									handleChangePasswordNew2={handleChangePasswordNew2}
								/>
							)}
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							{['detail', 'new'].includes(props.type) && (
								<ChangeRoles
									rolesAll={roles}
									rolesUser={user.roles ?? []}
									handleAddRole={handleAddRole}
								/>
							)}

							{['settings', 'detail', 'new'].includes(props.type) && (
								<ChangeRecipeListValueScope
									valueScope={
										user.userSettings
											? user.userSettings['Recipe.List.ValueScope']
											: null
									}
									changeRecipeListValueScope={handleChangeValueScope}
								/>
							)}
							{props.type === 'settings' && (
								<ChangeCurrency
									allCurrencies={allCurrencies}
									handleSetSelectedCurrency={handleChangeSelectedCurrency}
									selectedCurrency={selectedCurrency}
								/>
							)}
						</div>
						<RenderIf
							condition={checkPermission([
								PERMISSIONS.READBASEINGREDIENT,
								PERMISSIONS.READINGREDIENT,
								PERMISSIONS.READMENU,
								PERMISSIONS.READMENUPLAN,
								PERMISSIONS.READRECIPE,
							])}
						>
							<div className="col-12 col-md-6 col-lg-3">
								<ChangeNutrients
									nutrientsAll={nutrients}
									nutrientsUser={user.nutrients ?? []}
									handleAddNutrient={handleAddNutrient}
								/>
							</div>
						</RenderIf>
					</div>
				</main>
			</>
		);
	};

	return renderContent();
};

export default UserComponent;
