import * as apiAll from 'api/status/GetAllStatusV1';
import Toggle from 'classes/_general/Search/Toggle/Toggle';
import { QueryParams } from 'functions/getQueryParams';
import IAdvancedSearchKey from 'interfaces/IAdvancedSearchKey';

export default class ToggleList implements IAdvancedSearchKey {
	public id: string;
	public all: Toggle[] = [];

	constructor(id: string) {
		this.id = id;
	}

	public async initialize(): Promise<void> {
		try {
			const response = await apiAll.callApi();
			response.hasValue() && this.mapFromApi(response.get());
		} catch (e: unknown) {
			console.error(e);
		}
	}

	public reset() {
		for (const i of this.all) {
			i.reset();
		}
	}

	public mapFromApi(input: apiAll.ResponseStatus[]) {
		this.all = [];
		for (const i of input) {
			const toggle = new Toggle(i);
			this.all.push(toggle);
		}
	}

	public mapToApi(): string[] | undefined {
		const output: Toggle[] = this.all.filter((e: Toggle) => {
			if (e.checked) {
				return e.checked;
			}
		});
		if (output.length) {
			return output.map((e: Toggle) => {
				return e.id;
			});
		}
		return undefined;
	}

	public mapFromUrl(input: QueryParams): void {
		for (const [key, value] of Object.entries(input)) {
			if (typeof value === 'boolean') return;
			if (key === this.id) {
				for (const i of this.all) {
					i.mapFromUrl(value);
				}
			}
		}
	}

	public mapToUrl(): string | null {
		if (this.everyItemIsTrue()) return null;
		const output: string[] = this.all
			.filter((e: Toggle) => {
				if (!e.checked) {
					return e;
				}
			})
			.map((e: Toggle) => {
				return e.id;
			});
		return this.id + '=' + output.join(';');
	}

	public getById(id: string): Toggle | undefined {
		return this.all.find((e: Toggle) => {
			return e.id === id;
		});
	}

	public everyItemIsFalse(): boolean {
		for (const i of this.all) {
			if (i.checked) {
				return false;
			}
		}
		return true;
	}

	private everyItemIsTrue(): boolean {
		for (const i of this.all) {
			if (!i.checked) {
				return false;
			}
		}
		return true;
	}
}
