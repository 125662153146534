import * as apiSW from 'api/nutrientCategory/GetNutrientCategoryStartingWithV1';
import * as apiPostSearch from 'api/nutrientCategory/PostNutrientCategorySearchV1';
import NutrientCategory from 'classes/StandingData/NutrientCategory/Search/NutrientCategory';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class NutrientCategoryList {
	public all: NutrientCategory[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const nutrientCategories = await apiSW.callApi(searchTerm, showOnlyDeleted);
		nutrientCategories.hasValue() && this.mapFromApiSW(nutrientCategories.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const nutrientCategory: NutrientCategory = new NutrientCategory(i);
			this.all.push(nutrientCategory);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseNutrientCategory[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
