import axios from 'axios';

import * as Get from 'api/accessory/GetAccessoryIdV1';
import { Characteristic } from 'enums/characteristic';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<Get.ResponseData>> {
	const url: string = `/backend-service/Accessory/${id}`;
	return axios.put<Get.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export type Request = {
	carbonDioxide: CarbonDioxide[] | null;
	categories: string[] | null; // required
	characteristic: Characteristic;
	density: number | null;
	descriptionTranslations: Translation;
	generalInfoTranslations: Translation;
	imageId: string | null;
	isPriceRecipeRelevant: boolean;
	namePluralTranslations: Translation; // required
	nameSingularTranslations: Translation; // required
	price: number | null;
	synonyms: Synonyms;
	tags: string[] | null; // required
	unitWeights: UnitWeights | null;
};

export type Translation = {
	[key: string]: string; // required
};

export type UnitWeights = {
	[key: string]: number;
};

export type CarbonDioxide = {
	originLocationId: string; // required
	productionMethodId: string; // required
	value: number;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};
