import RecipeCategoryManagementComponent from 'components/desktop/StandingData/RecipeCategory/Management/RecipeCategoryManagementComponent';

const RecipeCategoryManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<RecipeCategoryManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeCategoryManagementContainer;
