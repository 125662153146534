import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(key: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/UserSetting/${key}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export type Response = {
	data: string; // required
};

export type ResponseData = string;
