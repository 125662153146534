import axios from 'axios';

import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url = `/backend-service/IngredientCategory/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	parentId: string | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nameTranslations: {
		[key: string]: string;
	};
}
