import * as api from 'api/recipe/PostRecipeSearchV1';

export default class PrepareTime {
	public timeUnit: string = '';
	public timeValue: number = 0;

	public constructor(input?: api.PrepareTime) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.PrepareTime | null): void {
		if (input) {
			this.timeUnit = input.timeUnit;
			this.timeValue = input.timeValue;
		}
	}
}
