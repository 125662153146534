import { useEffect } from 'react';
import { useState } from 'react';

export const useAdvancedSearchHeight = () => {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const collapsible = document.getElementById('advancedSearch');
		if (!collapsible) {
			setHeight(10);
		} else {
			collapsible.addEventListener('shown.bs.collapse', updateHeight);
		}
		return () => {
			const collapsible = document.getElementById('advancedSearch');
			if (!collapsible) return;
			collapsible.removeEventListener('shown.bs.collapse', updateHeight);
		};
	}, []);

	function updateHeight() {
		const collapsible = document.getElementById('advancedSearch');
		if (collapsible) {
			setHeight(collapsible.clientHeight);
		} else {
			setHeight(0);
		}
	}
	return height;
};
