import * as apiGetIngredientUnitWeightByText from 'api/aiFunctions/IngredientUnitWeightByTextV1';
import { Characteristic } from 'enums/characteristic';
import * as Translation from 'types/_general/Translation';

export type Type = {
	id: string;
	description: string;
};

export function create() {
	return {
		id: '',
		description: '',
	};
}

export async function getFromApiSuggested(
	selectedUnit: Type,
	cultureCode: string,
	name: Translation.Type<string>,
	id: string | null,
	density: number,
	characteristic: Characteristic | null
): Promise<number> {
	if (!id || !characteristic) {
		return 0;
	}
	const ingredientName = name[cultureCode];
	if (!ingredientName) {
		return 0;
	}
	const response = await apiGetIngredientUnitWeightByText.callApi(
		ingredientName,
		selectedUnit.description,
		density,
		characteristic,
		cultureCode
	);
	return response.getOrDefault(0);
}
