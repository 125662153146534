import { Navigate } from 'react-router-dom';

import ProtectedRouteContainer from 'components/desktop/_general/ProtectedRouteContainer/ProtectedRouteContainer';
import PERMISSIONS from 'enums/permissions';
import {
	arePermissionsInUserPermissions,
	getTokensFromLocalStorage,
} from 'functions/tokenFunctions';

type IProps = {
	children: JSX.Element;
	permissions: PERMISSIONS[];
};

const ProtectedRoute = (props: IProps) => {
	if (!getTokensFromLocalStorage().hasValue()) {
		localStorage.setItem('pathname', window.location.pathname);
		return <Navigate to="/login" replace />;
	}
	if (!arePermissionsInUserPermissions([...props.permissions])) {
		return <Navigate to="/" replace />;
	}
	return <ProtectedRouteContainer>{props.children}</ProtectedRouteContainer>;
};

export const wrapProtectedRoute = (props: {
	element: JSX.Element;
	path: string;
	permissions: PERMISSIONS[];
}) => {
	return {
		element: <ProtectedRoute permissions={props.permissions}>{props.element}</ProtectedRoute>,
		path: props.path,
	};
};

export default ProtectedRoute;
