import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import { AllergenLogic } from 'enums/allergenLogic';
import { Icon } from 'types/Icon/Icon';

export default class Allergen {
	public id: string = '';
	public iconSvgUrl: string = '';
	public name: string = '';
	public display: boolean = false;
	public logic: AllergenLogic = AllergenLogic.Positive;

	public constructor(input?: apiPostSearch.Allergen) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapToIcon(): Icon | null {
		if (this.display) {
			return {
				name: this.name,
				iconSvgUrl: this.iconSvgUrl,
			};
		}
		return null;
	}

	public mapFromApi(input: apiPostSearch.Allergen): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl ?? '';
		this.name = input.name ?? '';
		this.display = input.display;
		this.logic = input.logic;
	}
}
