import * as apiPostSuggestedRecipeTag from 'api/aiFunctions/PostAiFunctionsSuggestRecipeTagV1';
import * as apiPostSuggestedTag from 'api/aiFunctions/PostAiFunctionsSuggestSimilarTagsV1';
import * as apiGetStartingWithV1 from 'api/tag/GetTagStartingWithV1';
import { ISelectItem } from 'interfaces/ISelectItem';
import { TagLight } from 'types/Tag/TagLight';
import * as Tag from 'types/_general/Store/Tag';

export type Type = Tag.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGetStartingWithV1.callApi('', false);
	return response.getOrDefault([]);
}

export async function getFromApiSuggestedRecipe(text: string, id: string): Promise<Type> {
	const response = await apiPostSuggestedRecipeTag.callApi({
		nrOfRecommendations: 8,
		recipeId: id ?? '',
		recipeText: text ?? null,
	});
	return response.map(mapFromApi).getOrDefault([]);
}

export async function getFromApiSuggested(text: string, cultureCode: string): Promise<Type> {
	const response = await apiPostSuggestedTag.callApi({
		cultureCode: cultureCode,
		description: text,
	});
	return response.map(mapFromApi).getOrDefault([]);
}

export function applySuggestedTags(suggestedTags: Type, tagIds: string[]): string[] {
	let output: string[] = [...tagIds];

	for (const i of suggestedTags) {
		if (tagIds.includes(i.id)) continue;
		output = [...output, i.id];
	}
	return output;
}

function mapFromApi(input: apiPostSuggestedRecipeTag.Tag[]): Type {
	return input.map((e) => {
		return {
			...e,
			iconSvgUrl: e.iconSvgUrl ?? undefined,
		};
	});
}

export function getFromIdArray(obj: Type, ids: string[]): TagLight[] {
	const output = obj.filter((e) => {
		return ids.includes(e.id);
	});
	return mapToTagLightList(output);
}

export function mapToISelectItemsFromIdArray(obj: Type, ids: string[]): ISelectItem[] {
	const output = obj.filter((e) => ids.includes(e.id));
	return mapToISelectItems(output);
}

export function mapToISelectItems(obj: Type): ISelectItem[] {
	return obj.map((e) => Tag.mapToISelectItem(e));
}

export function mapToTagLightList(obj: Type): TagLight[] {
	return obj.map((e) => {
		return { ...e, iconSvgUrl: e.iconSvgUrl ?? null };
	});
}
