import { t } from 'i18next';

import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	handleSetRecipeAttribute: (key: keyof Recipe, value: number | null) => void;
}

const RecipeScalingEditor = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleValueChange = (id: string, value: string): void => {
		const valueNumber = Number(value);
		const valueIsZero = valueNumber === 0;

		if (id === 'scaledPartsInput') {
			props.handleSetRecipeAttribute('scaledParts', valueIsZero ? null : valueNumber);
		} else if (id === 'scaledPersonsInput') {
			props.handleSetRecipeAttribute('scaledPersons', valueIsZero ? null : valueNumber);
		}
	};

	return (
		<>
			<LockableInput
				id="scaledPartsInput"
				label={t('recipe:SCALED_PARTS_COUNT')}
				placeholder=""
				withLock={false}
				withTrashCan={false}
				defaultValue={String(props.recipe.scaledParts ?? '')}
				validateRegex={/^[0-9]{1,}(\.[0-9]{1,}){0,1}$/}
				handleValueChange={handleValueChange}
				disabled={!recipeEditor}
				errorKey="ScaledParts"
			/>
			<LockableInput
				id="scaledPersonsInput"
				label={t('recipe:SCALED_PARTS_PERSONS')}
				placeholder=""
				withLock={false}
				withTrashCan={false}
				defaultValue={String(props.recipe.scaledPersons ?? '')}
				validateRegex={/^[0-9]{1,}(\.[0-9]{1,}){0,1}$/}
				handleValueChange={handleValueChange}
				disabled={!recipeEditor}
				errorKey="ScaledPersons"
			/>
		</>
	);
};

export default RecipeScalingEditor;
