export type MailTemplate = {
	[key: string]: any;
	id: string;
	name: string;
	emailAccount: {
		id: string;
		senderEmail: string;
	};
	localizedSubjects: {
		[key: string]: string;
	};
	localizedBodies: {
		[key: string]: string;
	};
	placeholders: string[];
};

export const defaultMailTemplate = (): MailTemplate => {
	return {
		id: '',
		name: '',
		emailAccount: {
			id: '',

			senderEmail: '',
		},
		localizedSubjects: {},
		localizedBodies: {},
		placeholders: [],
	};
};
