import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { ISelectItem } from 'interfaces/ISelectItem';
import { IngredientCategoryLight } from 'types/Ingredient/IngredientCategory/IngredientCategoryLight';
import { IngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';

interface IProps {
	ingredientCategorys: IngredientCategoryLight[];
	ingredientCategoryPoint: IngredientCategoryPoint;
	setIngredientCategoryPoint: (input: IngredientCategoryPoint) => void;
}

const IngredientCategorySelect = (props: IProps) => {
	const { t } = useTranslation();

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingGroups: IngredientCategoryLight[] = props.ingredientCategorys.filter(
			(group: IngredientCategoryLight) => {
				const current: boolean =
					props.ingredientCategoryPoint.ingredientCategoryId === group.id;
				if (current) return false;
				if (searchTerm) {
					const name = group.name.toLowerCase();
					searchTerm = searchTerm.toLowerCase();
					return name.startsWith(searchTerm);
				} else {
					return true;
				}
			}
		);

		return matchingGroups.map((category: IngredientCategoryLight) => {
			const displayName: string = category.nutriScoreCategory
				? `${category.name} (${category.nutriScoreCategory})`
				: `${category.name}`;
			return {
				id: category.id,
				name: displayName,
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const changeGroup = (savedItem: ISelectItem): void => {
		const input: IngredientCategoryPoint = { ...props.ingredientCategoryPoint };
		input.ingredientCategoryId = savedItem.id;
		input.ingredientCategoryName = savedItem.name;
		props.setIngredientCategoryPoint(input);
	};

	return (
		<>
			<label className="input-label">{t('standingData:CHANGE_CATEGORY')}</label>
			<div className="input-group mb-3">
				<SuggestionSearchInput
					className="form-control"
					placeholderText={
						props.ingredientCategoryPoint.ingredientCategoryName ?? undefined
					}
					placeholderIsBlack={true}
					handleChangeSearchTerm={handleChangeSearchTerm}
					setSavedItem={changeGroup}
					triggerSearchLetterAmount={0}
					suggestedItems={suggestedItems}
				/>
			</div>
		</>
	);
};

export default IngredientCategorySelect;
