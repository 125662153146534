import { useSelector } from 'react-redux';

import { Characteristic } from 'enums/characteristic';
import { getPriceString } from 'functions/numberToString';
import {
	ValueScopeInfo,
	convertToValueScope,
	getValueScopeLabel,
} from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	value: number | null;
	currency: string;
	valueScopeInfo: ValueScopeInfo | null;
	characteristic?: Characteristic | null;
}

const PriceDisplay = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const getValue = (): string => {
		if (props.value !== null) {
			return getPriceString(
				convertToValueScope(props.value, props.valueScopeInfo),
				reduxCultureCode
			);
		} else {
			return '0';
		}
	};

	const renderContent = () => {
		return (
			<>
				<div>
					<label style={{ whiteSpace: 'nowrap' }}>
						{getValueScopeLabel(
							props.valueScopeInfo?.valueScope ?? null,
							props.characteristic
						)}
					</label>
					<div className="form-control" style={{ whiteSpace: 'nowrap' }}>
						<b>{props.currency + ' ' + getValue()}</b>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default PriceDisplay;
