export enum AccessorySortOrder {
	CarbonDioxideAsc = 'CarbonDioxideAsc',
	CarbonDioxideDesc = 'CaloriesAsc',
	DensityAsc = 'DensityAsc',
	DensityDesc = 'DensityDesc',
	LastEditedDateAsc = 'LastEditedDateAsc',
	LastEditedDateDesc = 'LastEditedDateDesc',
	NameAsc = 'NameAsc',
	NameDesc = 'NameDesc',
	PriceAsc = 'PriceAsc',
	PriceDesc = 'PriceDesc',
}
