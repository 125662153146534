/**
 * Compose 2 functions together.
 *
 * @param f
 * @param g
 * @returns
 */
export function compose<T, U, V>(f: (x: T) => U, g: (y: U) => V): (x: T) => V {
	return (x: T) => g(f(x));
}

export const enforceNonNullable = <T>(a: T | null): T => {
	if (!a) {
		throw new Error('Illegal argument. You may not enforce a null to a non-nullable.');
	}
	return a!;
};
