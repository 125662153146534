import * as api from 'api/recipe/PostRecipeSearchV1';

export default class NutriScore {
	label: string = '';
	color: string = '';

	public constructor(input?: api.NutriScore) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.NutriScore | null): void {
		if (input) {
			this.label = input.label ?? '';
			this.color = input.color ?? '';
		}
	}
}
