import { useTranslation } from 'react-i18next';

import TimeUnit from 'classes/StandingData/TimeUnit/TimeUnit';

interface IProps {
	timeUnit: TimeUnit;
	selectedLanguage: string;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TimeUnitLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-12">
					<div className="col-12 mt-2">
						<div className="row mb-3">
							<b>{t('_general:SINGULAR')}</b>
							<div className="row">
								<label>{t('_general:DESCRIPTION')}</label>
								<div>
									<input
										value={
											props.timeUnit.nameSingularTranslations[
												props.selectedLanguage
											] || ''
										}
										type="text"
										onChange={props.handleValueChange}
										className="form-control"
										name="descriptionSingularTranslations"
										error-key={'DescriptionSingularTranslations'}
									/>
								</div>
								<br />
								<label>{t('_general:SHORT_DESCRIPTION')}</label>
								<div>
									<input
										value={
											props.timeUnit.shortNameSingularTranslations[
												props.selectedLanguage
											] || ''
										}
										type="text"
										onChange={props.handleValueChange}
										className="form-control"
										name="shortDescriptionSingularTranslations"
										error-key={'ShortDescriptionSingularTranslations'}
									/>
								</div>
							</div>
							<br />
							<b></b>
							<div className="row">
								<label>{t('_general:DESCRIPTION')}</label>
								<div>
									<input
										value={
											props.timeUnit.namePluralTranslations[
												props.selectedLanguage
											] || ''
										}
										type="text"
										onChange={props.handleValueChange}
										className="form-control"
										name="descriptionPluralTranslations"
										error-key={'DescriptionPluralTranslations'}
									/>
								</div>
								<br />
								<label>{t('_general:SHORT_DESCRIPTION')}</label>
								<div>
									<input
										value={
											props.timeUnit.shortNamePluralTranslations[
												props.selectedLanguage
											] || ''
										}
										type="text"
										onChange={props.handleValueChange}
										className="form-control"
										name="shortDescriptionPluralTranslations"
										error-key={'ShortDescriptionPluralTranslations'}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TimeUnitLanguageInput;
