import 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip.scss';

const LinkCell = (props: {
	id: string | null;
	lastCell: boolean;
	link: string;
	title: string | null;
	disabled?: boolean;
}) => {
	const handleLinkClicked = () => {
		window.open(props.link, '_blank');
	};

	const lastCellClassName = props.lastCell ? 'ingredient-tooltip-last-child' : '';

	if (!props.disabled) {
		return (
			<div
				className={`ingredient-tooltip ingredient-tooltip-link ${lastCellClassName}`}
				onClick={() => handleLinkClicked()}
			>
				<div style={{ display: 'flex' }}>
					<span style={{ flexGrow: 1, alignSelf: 'center' }}>{props.title}</span>
				</div>
			</div>
		);
	} else {
		return (
			<div className={`ingredient-tooltip ${lastCellClassName}`}>
				<div style={{ display: 'flex' }}>
					<span style={{ flexGrow: 1, alignSelf: 'center' }}>{props.title}</span>
				</div>
			</div>
		);
	}
};

export default LinkCell;
