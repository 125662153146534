import { ISelectItem } from 'interfaces/ISelectItem';

type Input = {
	id: string;
	name?: string;
	description?: string | null;
	label?: string;
	iconSvgUrl?: string | null;
	source?: string | null;
};

export function mapToISelectItem(input: Input[]): ISelectItem[] {
	return input.map<ISelectItem>((x) => ({
		id: x.id,
		name: mapName(x),
		iconSvgUrl: x.iconSvgUrl,
		source: x.source,
	}));
}

function mapName(input: Input): string {
	if (input.name) {
		return input.name;
	} else if (input.description) {
		return input.description;
	} else if (input.label) {
		return input.label;
	} else {
		return '';
	}
}
