import * as apiGet from 'api/nutriScoreNutrientPoints/GetNutriScoreNutrientPointsGetAllPointsForNutrientAndMethodV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { NutriScoreNutrient } from 'types/NutriScoreNutrient/NutriScoreNutrient';

export const mapFromRequest = (input: apiGet.ResponseData): NutriScoreNutrient => {
	return {
		nutrientId: input.nutrientId,
		name: input.nutrientName,
		pointType: input.pointType as NutriScoreCategory,
		points: input.points,
		isPositive: input.isPositive,
		isProtein: input.isProtein,
	};
};
