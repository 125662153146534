import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { ISelectItem } from 'interfaces/ISelectItem';

interface IProps {
	selectedItem: ISelectItem | null;
	setSavedItem: (savedItem: ISelectItem | null) => void;
	handleChangeSearchTerm: (searchTerm: string) => void;
	suggestedItems: ISelectItem[];
	label?: string;
	triggerSearchLetterAmount?: number;
	debounceMilliSeconds?: number;
	disabled?: boolean;
	hideField?: boolean;
	linkTo?: string;
	errorKey?: string;
}

const SingleSearchSelect = (props: IProps) => {
	const { t } = useTranslation();

	const handleRemoveItem = () => {
		props.setSavedItem(null);
	};

	const getTitle = (input: ISelectItem) => {
		if (input.source) {
			return input.source;
		}
	};

	return (
		<>
			<div className="row">
				{props.label && <label className="input-label">{props.label}</label>}
				<div className="col-12">
					{props.selectedItem !== null && props.selectedItem.id !== '' ? (
						<>
							<div
								className="d-block d-flex justify-content-between select-saveditems"
								title={getTitle(props.selectedItem)}
							>
								{props.linkTo ? (
									<>
										<Link
											to={props.linkTo}
											style={{ color: 'white', textDecoration: 'none' }}
										>
											{props.selectedItem.name}
										</Link>
									</>
								) : (
									<>{props.selectedItem.name}</>
								)}
								<span
									onClick={handleRemoveItem}
									style={{ display: props.hideField ? 'none' : 'block' }}
								>
									<i className="bi bi-x" />
								</span>
							</div>
						</>
					) : (
						<></>
					)}
					<div
						className="input-group"
						style={{ display: props.hideField ? 'none' : 'flex' }}
					>
						<SuggestionSearchInput
							disabled={props.disabled}
							className="form-control"
							placeholderText={
								props.disabled
									? t('_general:INPUT_PLACEHOLDER_NO_MORE_ENTRIES')
									: t('_general:INPUT_PLACEHOLDER_SEARCHTERM')
							}
							handleChangeSearchTerm={props.handleChangeSearchTerm}
							setSavedItem={props.setSavedItem}
							triggerSearchLetterAmount={props.triggerSearchLetterAmount}
							suggestedItems={props.suggestedItems}
							debounceMilliSeconds={props.debounceMilliSeconds}
							errorKey={props.errorKey}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SingleSearchSelect;
