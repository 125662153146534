import { faHouse, faSquareShareNodes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

import 'components/desktop/_general/ImageCarousel/ImageCarouselButtons.scss';
import { ChannelMedia } from 'types/Media/ChannelMedia';

interface IProps {
	handleDelete: (id: string) => void;
	id: string;
	setMainImage: (image: ChannelMedia) => void;
	onMediaButtonClick: () => void;
}

const ImageCarouselButtons = (props: IProps) => {
	const handleDelete = (): void => props.handleDelete(props.id);

	const renderButton = (
		title: string,
		onClickFunction: ((e?: any) => void) | undefined,
		icon: JSX.Element,
		className: string = ''
	): JSX.Element => {
		return (
			<button
				type="button"
				className={`btn btn-outline-secondary ${className}`}
				data-bs-toggle="tooltip"
				data-bs-placement="top"
				title={title}
				onClick={onClickFunction}
			>
				{icon}
			</button>
		);
	};

	return (
		<div>
			<div className="btn-group" role="group">
				{renderButton(
					t('_general:SET_MAIN_IMAGE'),
					props.setMainImage,
					<FontAwesomeIcon icon={faHouse} />
				)}
				{renderButton(
					t('_general:DELETE'),
					handleDelete,
					<FontAwesomeIcon icon={faTrash} />
				)}
				{renderButton(
					t('_general:MEDIA_CHANNELS'),
					() => props.onMediaButtonClick(),
					<FontAwesomeIcon
						icon={faSquareShareNodes}
						style={{ height: '100%', width: '23px' }}
					/>,
					'media-channel-button'
				)}
			</div>
		</div>
	);
};

export default ImageCarouselButtons;
