import * as apiGetByType from 'api/unit/GetUnitGetByTypeV1';
import { Characteristic } from 'enums/characteristic';
import { UnitType } from 'enums/unitType';
import { UnitLight } from 'types/Unit/UnitLight';
import * as Unit from 'types/_general/Store/Unit';

export type Type = Unit.Type[];

export function create(): Type {
	return [];
}

export function getByCharacteristic(obj: Type, characteristic: Characteristic): Type {
	const unitTypeList = getUnitTypeListByChararcteristic(characteristic);
	return obj.filter((e) => unitTypeList.includes(e.type));
}

export async function getAllFromApi(): Promise<Type> {
	let output: Type = [];
	for (const type of [
		UnitType.Container,
		UnitType.Volume,
		UnitType.Weight,
		UnitType.Quantifier,
	]) {
		output = [...output, ...(await getFromApi(type))];
	}
	return output;
}

function getUnitTypeListByChararcteristic(characteristic: Characteristic): UnitType[] {
	switch (characteristic) {
		case Characteristic.Fluid:
			return [UnitType.Container];
		case Characteristic.SolidQuantifiable:
			return [UnitType.Quantifier];
		default:
			return [UnitType.Quantifier, UnitType.Container];
	}
}

async function getFromApi(type: UnitType): Promise<Type> {
	const response = await apiGetByType.callApi(type);
	return response.getOrDefault([]).map((e) => {
		return Unit.mapFromApi(e, type);
	});
}

export function mapToUnitLightList(obj: Type): UnitLight[] {
	return obj.map(Unit.mapToUnitLight);
}
