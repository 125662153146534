import stringify from 'json-stable-stringify';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';
import Swal from 'sweetalert2';

import { Optional } from 'functions/promiseExtensions';
import { useStateAsync } from 'hooks/useStateAsync';

type Return<T> = {
	setObjectCachedAsync: (t: T) => Promise<void>;
};

let hasChangesWarnOnLeave: boolean = false;

export function useWarnOnLeave<T extends { id: string | null }>(
	object: T,
	saveFunction: () => Promise<Optional<any>>,
	isDirtyByDefault: boolean = false
): Return<T> {
	const [cache, setCache] = useStateAsync<string | null>(null);

	const { t } = useTranslation();

	useEffect(() => {
		hasChangesWarnOnLeave = isDirtyByDefault;

		if (!isDirtyByDefault) {
			initialize();
		}
	}, []);

	useEffect(() => {
		updateHasChanges();
	}, [object, cache]);

	useEffect(() => {
		window.addEventListener('beforeunload', beforeUnloadEventListener);
		return () => {
			window.removeEventListener('beforeunload', beforeUnloadEventListener);
		};
	}, []);

	const blocker = useBlocker(() => {
		return shouldStay();

		function shouldStay(): boolean {
			return hasChangesWarnOnLeave;
		}
	});

	useEffect(() => {
		reactToBlockerStateChange();
	}, [blocker.state]);

	async function reactToBlockerStateChange() {
		if (blocker.state === 'blocked') {
			if (!hasChangesWarnOnLeave) {
				const response = await saveFunction();
				if (response.hasValue()) {
					blocker.proceed();
				} else {
					blocker.reset();
				}
				return;
			}
			const userResponse = await showConfirmationDialog();
			if (userResponse.isDenied) {
				blocker.proceed();
			}
			if (userResponse.isDismissed) {
				blocker.reset();
			}
			if (userResponse.isConfirmed) {
				const response = await saveFunction();
				if (response.hasValue()) {
					blocker.proceed();
				} else {
					blocker.reset();
				}
			}
		}
	}

	function initialize(): void {
		setCache(stringify(object));
	}

	function updateHasChanges() {
		hasChangesWarnOnLeave = stringify(object) !== cache;
	}

	return {
		setObjectCachedAsync: async (t: T) => {
			await setCache(stringify(t));
		},
	};

	async function showConfirmationDialog() {
		return await Swal.fire({
			icon: 'warning',
			iconColor: '#ff0004',
			background: 'white',
			confirmButtonText: t('_general:BTN_SAVE'),
			denyButtonText: t('_general:BTN_LEAVE'),
			cancelButtonText: t('_general:CANCEL'),
			showCancelButton: true,
			showDenyButton: true,
			showConfirmButton: true,
			title: t('_general:LEAVE_WITHOUT_SAVE'),
			confirmButtonColor: '#7da8d4',
			denyButtonColor: '#ff0004',
			customClass: {
				confirmButton: 'btn btn-primary',
				denyButton: 'btn btn-danger',
				cancelButton: 'btn',
				popup: 'alert alert-1',
			},
			position: 'top',
		});
	}
}

function beforeUnloadEventListener(e: BeforeUnloadEvent) {
	if (shouldStay()) {
		stay(e);
	} else {
		leave();
	}

	function shouldStay(): boolean {
		return hasChangesWarnOnLeave;
	}

	function stay(e: BeforeUnloadEvent) {
		e.preventDefault();
		// Chrome requires returnValue to be set
		e.returnValue = '';
	}

	function leave() {
		return;
	}
}
