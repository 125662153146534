import * as apiSW from 'api/productionMethod/GetProductionMethodStartingWithV1';
import * as apiPostSearch from 'api/productionMethod/PostProductionMethodSearchV1';
import ProductionMethod from 'classes/StandingData/ProductionMethod/Search/ProductionMethod';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class ProductionMethodList {
	public all: ProductionMethod[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const accessoryCategories = await apiSW.callApi(searchTerm, showOnlyDeleted);
		accessoryCategories.hasValue() && this.mapFromApiSW(accessoryCategories.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const productionMethod: ProductionMethod = new ProductionMethod(i);
			this.all.push(productionMethod);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseProductionMethod[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
