export const setQuantityMethodCurrency = (method: string) => {
	return {
		type: 'quantityMethod/SET',
		quantityMethod: method,
	};
};

export type QuantityMethodAction = {
	type: string;
	quantityMethod: string;
};

export const quantityMethodReducer = (
	state: string = 'Min',
	action: QuantityMethodAction
): string => {
	switch (action.type) {
		case 'quantityMethod/SET':
			return action.quantityMethod;
		default:
			return state;
	}
};
