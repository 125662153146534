import React from 'react';

import { TableCell } from 'components/desktop/_general/AirTable/AirTableCell';
import {
	ColumnInformation,
	ColumnTypeIndex,
	RowData,
} from 'components/desktop/_general/AirTable/AirTableTypes';
import { DividerRow } from 'components/desktop/_general/AirTable/DividerRow';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';

import { getColumnStyle } from './AirTableStyles';

type IProps = {
	columns: ColumnInformation[];
	dividers: number[];
	data: { [key: string]: any }[];
	readOnly: boolean;
};

const TableRow = (
	props: IProps & { rowData: RowData; index: number; columnsIndex: ColumnTypeIndex } & {
		children: JSX.Element[];
	}
) => {
	return (
		<>
			{props.columns.map((col, j) => (
				<TableCell
					key={j}
					column={props.columnsIndex[col.key]}
					index={props.index}
					rowData={props.rowData}
					firstCell={j === 0}
					lastRow={props.rowData.isLastRow}
					readOnly={props.readOnly}
				/>
			))}
			<RenderIf
				condition={props.rowData.isFirstRow && props.children[props.rowData.segmentIndex]}
			>
				<td rowSpan={props.rowData.rowSpan} className="air-table-colspan-segment">
					{props.children[props.rowData.segmentIndex]}
				</td>
			</RenderIf>
		</>
	);
};

export const TableRows = (
	props: IProps & { columnsIndex: ColumnTypeIndex; children: JSX.Element[] }
) => (
	<>
		{props.data.map((x, i) => {
			return (
				<React.Fragment key={i}>
					<RenderIf condition={x.isFirstRow}>
						<DividerRow />
					</RenderIf>
					{x.overflowSegments.map((y: any, j: number) => {
						return (
							<React.Fragment key={j}>
								<tr>
									<OverflowSegment
										segment={props.children[y]}
										columns={props.columns}
										columnsIndex={props.columnsIndex}
									/>
								</tr>
								<DividerRow />
							</React.Fragment>
						);
					})}
					<tr>
						<TableRow
							{...props}
							index={i}
							rowData={x}
							columnsIndex={props.columnsIndex}
							children={props.children}
						/>
					</tr>
				</React.Fragment>
			);
		})}
		<DividerRow />
	</>
);

export const OverflowSegment = (props: {
	segment: JSX.Element;
	columns: ColumnInformation[];
	columnsIndex: ColumnTypeIndex;
}) => {
	return (
		<>
			{props.columns.map((col, i) => {
				const stickyClassName = col.sticky ? 'air-table-sticky-column' : '';
				const numericClassName = col.numeric ? 'air-table-numeric-column' : '';
				return (
					<td
						key={i}
						className={`air-table-header-cell ${stickyClassName} ${numericClassName}`}
						style={getColumnStyle(props.columnsIndex[col.key])}
					></td>
				);
			})}
			<td className="air-table-colspan-segment">{props.segment}</td>
		</>
	);
};
