import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<void>> {
	const url: string = '/backend-service/Ingredient/AddUnitWeight';
	return axios.patch(url, request).toOptionalVoid();
}

interface Request {
	ingredientId: string;
	unitId: string;
	weight: number;
}
