import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(searchTerm: string): Promise<Optional<ResponseMailTemplate[]>> {
	const url: string = '/backend-service/Mailtemplate/StartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data.mailTemplates);
}

interface Parameter {
	SearchTerm: string;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	mailTemplates: ResponseMailTemplate[];
};

export type ResponseMailTemplate = {
	id: string;
	name: string;
};
