import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { Values } from 'components/desktop/_general/NutritionFactsLabel/NutritionFactsLabel';
import { Value } from 'components/desktop/_general/NutritionFactsLabel/NutritionFactsLabel';
import NutritionFactsLabel from 'components/desktop/_general/NutritionFactsLabel/NutritionFactsLabel';
import { nutritionFactsLabelInformations } from 'constants/nutritionFactsLabelInformations';
import { Recipe } from 'types/Recipe/Recipe';

interface Props {
	calculation: Calculation | null;
	recipe: Recipe;
}

const NutritionFactsLabelWrapper = ({ calculation, recipe }: Props) => {
	if (!calculation) return <></>;

	const values: Values = {} as Values;

	for (const i of nutritionFactsLabelInformations) {
		values[i.name] = {} as Value;
		values[i.name].value = calculation.nutrientValues.getTotalById(i.id, recipe.parts ?? 1);
		values[i.name].unit = calculation.nutrientValues.getUnitById(i.id);
		if (i.dailyValue) {
			values[i.name].percent = calculation.nutrientValues.calculateDailyValueById(
				i.id,
				recipe.parts ?? 1,
				i.dailyValue
			);
		}
	}

	const calculateServingSize = {
		value:
			(calculation.weight ?? recipe.ingredientWeight ?? recipe.weight ?? 1) /
			(recipe.parts ?? 1),
		unit: 'g',
	};

	return <NutritionFactsLabel values={values} servingSize={calculateServingSize} />;
};

export default NutritionFactsLabelWrapper;
