import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import ProductionMethod from 'classes/StandingData/ProductionMethod/Search/ProductionMethod';
import ProductionMethodList from 'classes/StandingData/ProductionMethod/Search/ProductionMethodList';
import Search from 'classes/StandingData/ProductionMethod/Search/Search';
import ProductionMethodDetailComponent from 'components/desktop/StandingData/ProductionMethod/Detail/ProductionMethodDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const ProductionMethodContainerComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [productionMethodList, setProductionMethodList] = useState<ProductionMethodList>(
		new ProductionMethodList()
	);
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getProductionMethods();
		} else {
			await getProductionMethods();
		}
		setSearch(clone(search));
	};

	const getProductionMethods = async () => {
		setIsLoading(true);
		const response = await productionMethodList.callApi(search.mapToRequest());
		response.do((x) => {
			productionMethodList.mapFromApi(x);
			setProductionMethodList(clone(productionMethodList));
		});
		setIsLoading(false);
	};

	const getSuggestedProductionMethods = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await productionMethodList.search(searchTerm, props.archive ?? false);
		} else {
			productionMethodList.filtered = [];
		}

		setProductionMethodList(clone(productionMethodList));
		return productionMethodList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getProductionMethods();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveProductionMethod = async (
		productionMethod: ProductionMethod
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:PRODUCTION_METHOD_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await productionMethod.callApiDelete();
				await Swal.fire({
					title: t('standingData:PRODUCTION_METHOD_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getProductionMethods();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreProductionMethod = async (
		productionMethod: ProductionMethod
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:PRODUCTION_METHOD_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await productionMethod.callApiRestore();
				await Swal.fire({
					title: t('standingData:PRODUCTION_METHOD_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getProductionMethods();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getButtonDataDelete = (input: ProductionMethod): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveProductionMethod(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: ProductionMethod): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreProductionMethod(input),
			};
		}
	};

	const renderButtons = (input: ProductionMethod): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return productionMethodList.all.map((method: ProductionMethod) => {
			return {
				id: method.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="flex-fill">{method.name}</div>
						<div>{renderButtons(method)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						{!props.archive ? (
							<>
								<h1>{t('standingData:PRODUCTION_METHOD_MANAGEMENT')}</h1>
								<div className="d-flex flex-gap-25">
									<BtnNew
										onClick={handleChangeIdToNew}
										name={t('standingData:CREATE_PRODUCTION_METHOD')}
									/>
								</div>
							</>
						) : (
							<h1>{t('standingData:PRODUCTION_METHOD_ARCHIVE')}</h1>
						)}
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedProductionMethods}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>
						{productionMethodList.totalCount + ' ' + t('_general:PRODUCTION_METHODS')}
					</p>
					<StandingDataList
						archive={props.archive}
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<ProductionMethodDetailComponent />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={productionMethodList.totalCount}
						pageIndex={search.getPageIndex()}
						setPageIndex={handleChangePageIndex}
						listItemAmount={search.getPageSize()}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ProductionMethodContainerComponent;
