import { t } from 'i18next';

import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	handleSetRecipeAttribute: (key: keyof Recipe, value: number | null) => void;
}

const PartsPersonsEditor = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleValueChange = (id: string, value: string): void => {
		const valueNumber = Number(value);
		const valueIsZero = valueNumber === 0;

		if (id === 'partsInput') {
			props.handleSetRecipeAttribute('parts', valueIsZero ? null : valueNumber);
		} else if (id === 'personsInput') {
			props.handleSetRecipeAttribute('persons', valueIsZero ? null : valueNumber);
		}
	};

	return (
		<>
			<LockableInput
				id="partsInput"
				label={t('recipe:PARTS_COUNT')}
				placeholder=""
				withLock={false}
				withTrashCan={false}
				defaultValue={String(props.recipe.parts ?? 0)}
				validateRegex={/^[0-9]{1,}(\.[0-9]{1,}){0,1}$/}
				handleValueChange={handleValueChange}
				disabled={!recipeEditor}
				errorKey="Parts"
			/>
			<LockableInput
				id="personsInput"
				label={t('recipe:PERSONS_COUNT')}
				placeholder=""
				withLock={false}
				withTrashCan={false}
				defaultValue={String(props.recipe.persons ?? 0)}
				validateRegex={/^[0-9]{1,}(\.[0-9]{1,}){0,1}$/}
				handleValueChange={handleValueChange}
				disabled={!recipeEditor}
				errorKey="Persons"
			/>
		</>
	);
};

export default PartsPersonsEditor;
