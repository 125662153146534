import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Recipe from 'classes/MenuPlan/Detail/Recipe';
import 'components/desktop/MenuPlan/Calories/MenuPlanCalories.scss';
import { kiloCaloriesToKilojouleFactor } from 'constants/kiloCaloriesToKilojouleFactor';
import { getNumberString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	object: Recipe;
}

const MenuPlanCalories = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const calculateParts = (convertToKilojoule: boolean = false): string => {
		const result: number = props.object.getCaloriesPerPerson(convertToKilojoule);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const calculatePersons = (convertToKilojoule: boolean = false): string => {
		const result: number = props.object.getCaloriesPerPart(convertToKilojoule);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const calculatePer100Gram = (convertToKilojoule: boolean = false): string => {
		const result: number = props.object.getCaloriesPer100Gramm(convertToKilojoule);
		if (result === 0) {
			return '-';
		} else {
			return getNumberString(result, reduxCultureCode);
		}
	};

	const renderContent = () => {
		return (
			<>
				<fieldset id="menu-calories-view" style={{ marginBottom: '25px' }}>
					<legend className="mb-3">
						<span>{t('recipe:ENERGY')}</span>
					</legend>
					<table
						className="align-middle table table-hover table-sm mb-0"
						style={{ marginBottom: '0px' }}
					>
						<thead className="menu-nutrients-thead">
							<tr>
								<th style={{ minWidth: '100px' }}>
									<label>{t('_general:UNIT')}</label>
								</th>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_PART')}</label>
								</th>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_PERSON')}</label>
								</th>
								<th className="text-align-right" style={{ minWidth: '100px' }}>
									<label>{t('measurments:PER_100_G')}</label>
								</th>
								<th className="text-align-right">
									<label>{t('_general:TOTAL')}</label>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr style={{ backgroundColor: '#ededed', borderColor: 'white' }}>
								<td className="text-align-left">kcal</td>
								<td className="text-align-right">{calculateParts()}</td>
								<td className="text-align-right">{calculatePersons()}</td>
								<td className="text-align-right">{calculatePer100Gram()}</td>
								<td className="text-align-right">
									{getNumberString(props.object.calories ?? 0, reduxCultureCode)}
								</td>
							</tr>
							<tr style={{ backgroundColor: '#ededed' }}>
								<td className="text-align-left">kJ</td>
								<td className="text-align-right">{calculateParts(true)}</td>
								<td className="text-align-right">{calculatePersons(true)}</td>
								<td className="text-align-right">{calculatePer100Gram(true)}</td>
								<td className="text-align-right">
									{getNumberString(
										props.object.calories ?? 0 * kiloCaloriesToKilojouleFactor,
										reduxCultureCode
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</fieldset>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuPlanCalories;
