import * as apiDelete from 'api/baseIngredientCategory/DeleteBaseIngredientCategoryIdV1';
import * as apiRestore from 'api/baseIngredientCategory/PostBaseIngredientCategoryRestoreV1';
import * as apiPostSearch from 'api/baseIngredientCategory/PostBaseIngredientCategorySearchV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';

export default class BaseIngredientCategory {
	public id: string = '';
	public name: string = '';
	public parentName: string | null = null;
	public nutriScoreCategory: NutriScoreCategory = NutriScoreCategory.StandardFood;

	public constructor(input?: apiPostSearch.ResponseCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseCategory) {
		this.id = input.id;
		this.name = input.name;
		this.parentName = input.parentName;
		this.nutriScoreCategory = input.nutriScoreCategory;
	}
}
