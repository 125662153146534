import { t } from 'i18next';

import 'components/desktop/SystemInfo/SystemInfoComponent.scss';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import { ComponentVersion } from 'types/VersionInfo/ComponentVersion';
import { VersionInfo } from 'types/VersionInfo/VersionInfo';

interface IProps {
	versionInfo: VersionInfo;
}

const SystemInfoComponent = (props: IProps) => {
	const renderComponentVersion = (componentVersion: ComponentVersion) => {
		return (
			<div key={componentVersion.buildId}>
				<h2>{componentVersion.componentName}</h2>
				<hr />
				<div className="info-container">
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:COMPONENT_NAME')}:</label>
						</div>
						<div className="col-6">
							<label>{componentVersion.componentName}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:VERSION')}:</label>
						</div>
						<div className="col-6">
							<label>{componentVersion.version}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BUILD_DATE')}:</label>
						</div>
						<div className="col-6">
							<label>
								<FormatedDate date={componentVersion.buildDateUtc} />
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BUILD_ID')}:</label>
						</div>
						<div className="col-6">
							<label>{componentVersion.buildId}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BRANCH')}:</label>
						</div>
						<div className="col-6">
							<label>{componentVersion.branch}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:HASH')}:</label>
						</div>
						<div className="col-6">
							<label>{componentVersion.hash}</label>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="system-info-container">
			<fieldset className="inner-container" style={{ marginTop: '5%' }}>
				<div className="inner-title-container">
					<LogoAnimation />
					<h2>Artificial Intelligence Recipes</h2>
				</div>
				<h2>AIR API</h2>
				<hr />
				<div className="info-container">
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:VERSION')}:</label>
						</div>
						<div className="col-6">
							<label>{props.versionInfo.version}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BUILD_DATE')}:</label>
						</div>
						<div className="col-6">
							<label>
								<FormatedDate date={props.versionInfo.buildDateUtc} />
							</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BUILD_ID')}:</label>
						</div>
						<div className="col-6">
							<label>{props.versionInfo.buildId}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:BRANCH')}:</label>
						</div>
						<div className="col-6">
							<label>{props.versionInfo.branch}</label>
						</div>
					</div>
					<div className="row">
						<div className="col-6 label-col">
							<label>{t('systemInfo:HASH')}:</label>
						</div>
						<div className="col-6">
							<label>{props.versionInfo.hash}</label>
						</div>
					</div>
				</div>
				<div className="component-version-container">
					{props.versionInfo.componentVersions.map((e: ComponentVersion) => {
						return <>{renderComponentVersion(e)}</>;
					})}
				</div>
			</fieldset>
		</div>
	);
};

export default SystemInfoComponent;
