import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import MenuPlan from 'classes/MenuPlan/Search/MenuPlan';
import Row from 'classes/MenuPlan/Search/Row';
import { RowItem } from 'classes/MenuPlan/Search/Row';
import 'components/desktop/MenuPlan/Search/SearchResultList.scss';
import BtnGroup from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import getConstraintIcon from 'functions/getConstraintIcon';
import { getNumberString } from 'functions/numberToString';
import { getPriceString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Icon } from 'types/Icon/Icon';

interface IProps {
	archive: boolean;
	handleDeleteMenuPlan: (input: MenuPlan) => Promise<void>;
	handleRestoreMenuPlan: (input: MenuPlan) => Promise<void>;
	menuPlan: MenuPlan;
}

const SearchResultListRow = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const renderInformations = (): JSX.Element => {
		return (
			<>
				<div>
					{props.menuPlan.caloriesLimit && (
						<>
							{getConstraintIcon(props.menuPlan.isCaloriesConstraintMet)}
							<span>
								<b>
									{getNumberString(
										props.menuPlan.caloriesLimit,
										reduxCultureCode,
										true
									)}
								</b>
								{' ' + t('_general:CALORIES') + ' '}
							</span>
						</>
					)}
				</div>
				<div>
					{props.menuPlan.priceLimit && (
						<>
							{getConstraintIcon(props.menuPlan.isPriceConstraintMet)}
							<span>
								<b>{getPriceString(props.menuPlan.priceLimit, reduxCultureCode)}</b>
								{' ' + reduxCurrency + ' '}
							</span>
						</>
					)}
				</div>
			</>
		);
	};

	const renderDate = (input: MenuPlan): JSX.Element => {
		if (input.startDate && input.endDate) {
			return (
				<div>
					<div>
						<span className="grey">{t('menuPlan:START')}:</span>{' '}
						<FormatedDate date={input.startDate} format="L" />
					</div>
					<div>
						<span className="grey">{t('menuPlan:END')}:</span>{' '}
						<FormatedDate date={input.endDate} format="L" />
					</div>
				</div>
			);
		}
		return <></>;
	};

	const renderButtons = (): JSX.Element => {
		return (
			<>
				<div>
					<BtnGroup
						edit={getButtonDataEdit()}
						delete={getButtonDataDelete()}
						copy={getButtonDataCopy()}
						restore={getButtonDataRestore()}
						view={getButtonDataView()}
					/>
				</div>
			</>
		);
	};

	const getButtonDataCopy = (): BtnData | undefined => {
		if (!props.archive && menuPlanEditor) {
			return {
				onClick: () => {
					navigate(`/menuPlan/copy/${props.menuPlan.id}`);
				},
			};
		}
	};

	const getButtonDataDelete = (): BtnData | undefined => {
		if (!props.archive && menuPlanEditor) {
			return {
				onClick: () => props.handleDeleteMenuPlan(props.menuPlan),
			};
		}
	};

	const getButtonDataEdit = (): BtnData | undefined => {
		if (!props.archive && menuPlanEditor) {
			return {
				onClick: () => {
					navigate(`/menuPlan/detail/${props.menuPlan.id}`);
				},
			};
		}
	};

	const getButtonDataView = (): BtnData | undefined => {
		if (!props.archive && !menuPlanEditor) {
			return {
				onClick: () => {
					navigate(`/menuPlan/detail/${props.menuPlan.id}`);
				},
			};
		}
	};

	const getButtonDataRestore = (): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => props.handleRestoreMenuPlan(props.menuPlan),
			};
		}
	};

	const renderRows = (input: Row): JSX.Element => {
		return (
			<div>
				<strong>{input.name}</strong>
				{input.items.map((e: RowItem, i: number) => {
					return <div key={i}>{e.name}</div>;
				})}
			</div>
		);
	};

	const renderStatus = (): JSX.Element => {
		if (props.menuPlan.status.name) {
			return (
				<>
					{props.menuPlan.status.name}&ensp;
					<FontAwesomeIcon
						color={props.menuPlan.status.color}
						icon={faCircle as IconProp}
					/>
				</>
			);
		}
		return (
			<>
				{t('_general:UNKNOWN_STATUS')}&ensp;
				<FontAwesomeIcon icon={faCircle as IconProp} />
			</>
		);
	};

	const renderIcons = (): JSX.Element => {
		return (
			<div className="d-flex flex-gap-10 align-items-center">
				{props.menuPlan.getIcons().map((icon: Icon, i: number) => (
					<IconComponent icon={icon} key={i} />
				))}
			</div>
		);
	};

	const renderCo2Display = (): JSX.Element => {
		return (
			<Co2Display
				value={props.menuPlan.carbonDioxideValue}
				color={props.menuPlan.carbonDioxideLabel?.color || '#000'}
				valueScopeInfo={null}
			/>
		);
	};

	const row = (
		<div id="menu-plan-search-item">
			<div
				className="d-flex flex-column justify-content-between"
				style={{ height: '100%', width: '350px' }}
			>
				<div style={{ fontSize: '1.4rem', marginTop: '-5px' }}>
					<strong>{props.menuPlan.title}</strong>
				</div>
				<div>{renderInformations()}</div>
				<div>{renderDate(props.menuPlan)}</div>
			</div>
			<div
				className="d-flex flex-column justify-content-between"
				style={{ height: '100%', width: '350px' }}
			>
				<div>
					<SeasonalityBar
						seasonalityCode={props.menuPlan.seasonList.getSeasonalityCode()}
					/>
				</div>
				<div className="row">
					<div className="col-5">
						<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
						<br />
						<span className="grey">{t('_general:LAST_EDITOR')}:</span>
						<br />
						{props.menuPlan.status.name && <span className="grey">Status</span>}
					</div>
					<div className="col-7">
						<FormatedDate date={props.menuPlan.lastEditedDateUtc} />
						<br />
						<span>{props.menuPlan.lastEditor}</span>
						<br />
						{<span>{renderStatus()}</span>}
					</div>
				</div>
			</div>
			<div className="d-flex flex-column menu-plan-items" style={{ minWidth: '300px' }}>
				{props.menuPlan.rowList.all.map((e: Row, i: number) => {
					return <div key={i}>{renderRows(e)}</div>;
				})}
			</div>
			<div
				className="d-flex flex-column align-items-end justify-content-between"
				style={{ height: '100%', width: '200px' }}
			>
				<div>{renderIcons()}</div>
				<div>
					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						{renderCo2Display()}
					</RenderIf>
				</div>
				<div>{renderButtons()}</div>
			</div>
		</div>
	);

	if (props.archive) {
		return row;
	}

	return (
		<Link to={`/menuPlan/detail/${props.menuPlan.id}`} className="unstyled-link">
			{row}
		</Link>
	);
};

export default SearchResultListRow;
