import * as apiSW from 'api/language/GetLanguageStartingWithV1';
import * as apiPostSearch from 'api/language/PostLanguageSearchV1';
import Language from 'classes/StandingData/Language/Search/Language';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class LanguageList {
	public all: Language[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const languages = await apiSW.callApi(searchTerm, showOnlyDeleted);
		languages.hasValue() && this.mapFromApiSW(languages.get());
	}

	public async callApi(input: apiPostSearch.Request) {
		return await apiPostSearch.callApi(input);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const language: Language = new Language(i);
			this.all.push(language);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseLanguage[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
