import * as apiPostSearch from 'api/ingredientCategory/PostIngredientCategorySearchV1';
import NullableNumber from 'classes/_general/Search/NullableNumber';
import NullableString from 'classes/_general/Search/NullableString';
import { QueryParams } from 'functions/getQueryParams';

export default class Search {
	public id: NullableString = new NullableString('id');
	public pageIndex: NullableNumber = new NullableNumber('pi', 0);
	public pageSize: NullableNumber = new NullableNumber('ps', 12);
	public description: NullableString = new NullableString('st');
	public showOnlyDeleted: boolean = false;

	public getPageIndex(): number {
		return this.pageIndex.get();
	}

	public getPageSize(): number {
		return this.pageSize.get();
	}

	public setSearchTerm(input: string) {
		this.description.value = input;
		this.pageIndex.value = 0;
	}

	public setId(input: string) {
		this.id.value = input;
	}

	public mapToRequest(): apiPostSearch.Request {
		return {
			pageIndex: this.pageIndex.mapToApi(),
			pageSize: this.pageSize.mapToApi(),
			description: this.description.mapToApi(),
			showOnlyDeleted: this.showOnlyDeleted,
		};
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input);
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = [];
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl());
			}
		}
		return this.mapUrlArrayToString(output);
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e;
			}
		});
		return '?' + outputFiltered.join('&');
	}
}
