import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as apiGetIngredientUnitWeight from 'api/aiFunctions/IngredientUnitWeightV1';
import * as apiGetCategoryTree from 'api/ingredientCategory/GetIngredientCategoryTreeViewStartingWithV1';
import { Category as CategoryTree } from 'api/ingredientCategory/GetIngredientCategoryTreeViewStartingWithV1';
import AllergenSelect from 'components/desktop/BaseIngredient/AllergenSelect/AllergenSelect';
import UnitWeightEditor from 'components/desktop/BaseIngredient/UnitWeightEditor/UnitWeightEditor';
import { getSelectedCategoriesTree } from 'components/desktop/Ingredient/IngredientComponentHandlers';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import CharacteristicRadioSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicRadioSelect';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import TreeInput from 'components/desktop/_general/Input/TreeInput/TreeInput';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import NutriScoreSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreSelect';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import SingleSearchSelect from 'components/desktop/_general/Select/SingleSearchSelect/SingleSearchSelect';
import TagEditor from 'components/desktop/_general/TagEditor/TagEditor';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { regexValidationCalories } from 'regex/validation/Calories';
import { regexValidationDensity } from 'regex/validation/Density';
import { regexValidationPrice } from 'regex/validation/Price';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { Ingredient, defaultBaseIngredient } from 'types/Ingredient/Ingredient';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { SeasonLight } from 'types/Season/SeasonLight';
import { mapSeasonLightToSeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { TagLight } from 'types/Tag/TagLight';
import { UnitLight } from 'types/Unit/UnitLight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

interface IProps {
	categoryTree: CategoryTree[];
	cultureCode: string;
	getAllergensStartingWith: (searchTerm: string) => Promise<AllergenLight[]>;
	getBaseIngredientsStartingWith: (searchTerm: string) => Promise<ISelectItem[]>;
	getSeasonsStartingWith: (searchTerm: string) => Promise<ISelectItem[]>;
	handleBaseIngredientChange: (id: string) => void;
	handleChangeCategory: (tree: apiGetCategoryTree.Category[]) => void;
	handleCharacteristicChange: (checked: boolean, elementId: string) => void;
	ingredient: Ingredient;
	nutrients: Nutrient[];
	seasons: SeasonLight[];
	setIngredient: (ingredient: Ingredient) => void;
	tags: TagLight[];
	units: UnitLight[];
}

const IngredientSidebar = (props: IProps) => {
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const [suggestedBaseIngredients, setSuggestedBaseIngredients] = useState<ISelectItem[]>([]);
	const [suggestedAllergens, setSuggestedAllergens] = useState<AllergenLight[]>([]);
	const [suggestedSeasons, setSuggestedSeasons] = useState<ISelectItem[]>([]);

	const mapBaseIngredientToItem = (): ISelectItem => {
		if (props.ingredient.baseIngredient && props.ingredient.baseIngredient.id) {
			return {
				id: props.ingredient.baseIngredient.id,
				name: props.ingredient.baseIngredient.name,
				source: props.ingredient.baseIngredient.source,
			};
		} else {
			return {
				id: '',
				name: '',
			};
		}
	};

	const handleSetBaseIngredient = (savedItem: ISelectItem | null) => {
		if (savedItem !== null) {
			props.handleBaseIngredientChange(savedItem.id);
		} else {
			var ingredientCpy = { ...props.ingredient };
			ingredientCpy.baseIngredient = defaultBaseIngredient();
			props.setIngredient(ingredientCpy);
		}
	};

	const handleAddSeason = (items: ISelectItem[]) => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.seasons = [];
		for (const i of items) {
			const season: SeasonLight | undefined = props.seasons.find((e) => i.id === e.id);
			if (season) ingredientCpy.seasons.push(mapSeasonLightToSeasonMappingDto(season));
		}
		props.setIngredient(ingredientCpy);
	};

	const handleAddAllergen = (items: AllergenLight[]) => {
		var ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.allergens = [];
		for (const i of items) {
			ingredientCpy.allergens.push({ ...i, source: null });
		}
		props.setIngredient(ingredientCpy);
	};

	const handleAddTag = (items: TagLight[]) => {
		var ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.tags = [];
		for (const i of items) {
			ingredientCpy.tags.push({ ...i, source: null });
		}
		props.setIngredient(ingredientCpy);
	};

	const handleAddUnitWeight = (items: UnitWeight[]) => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.unitWeights = [];
		for (const uw of items) {
			ingredientCpy.unitWeights.push(uw);
		}
		props.setIngredient(ingredientCpy);
	};

	const handleGetSuggestedUnitWeight = async (
		selectedUnit: UnitLight
	): Promise<Optional<number>> => {
		if (!props.ingredient.id) {
			return Optional.Just(0);
		}

		return await apiGetIngredientUnitWeight.callApi(
			props.ingredient.id,
			selectedUnit.id,
			props.cultureCode
		);
	};

	const handleSetHasNutriScore = (hasNutriScore: boolean) => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.hasNutriScore = hasNutriScore;
		props.setIngredient(ingredientCpy);
	};

	const handleSetNutriScoreCategory = (category: NutriScoreCategory | null) => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.nutriScoreCategory = category;
		props.setIngredient(ingredientCpy);
	};

	const handleLockClickNutriScore = (isLocked: boolean) => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy.nutriScoreCategoryIsSetByUser = !isLocked;

		if (!isLocked) {
			ingredientCpy.nutriScoreCategory ??= ingredientCpy.baseIngredient.nutriScoreCategory;
		}

		props.setIngredient(ingredientCpy);
	};

	const changeIngredientAttribute = (key: string, value: string): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy[key] = Number.parseFloat(value);
		props.setIngredient(ingredientCpy);
	};

	const resetIngredientAttribute = (key: string): number => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		ingredientCpy[key] = null;
		props.setIngredient(ingredientCpy);

		const value: number | undefined = props.ingredient.baseIngredient[key];
		if (value !== undefined) {
			return value;
		}
		return 0;
	};

	const getDefaultValue = (ingredientKey: string): string => {
		if (props.ingredient[ingredientKey] !== null) {
			return String(props.ingredient[ingredientKey]);
		}
		if (props.ingredient.baseIngredient[ingredientKey]) {
			return String(props.ingredient.baseIngredient[ingredientKey]);
		}
		return '0';
	};

	const handleLockClickSeason = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.isSeasonOverridden = false;
			ingredientCpy.seasons = [];
		} else {
			ingredientCpy.isSeasonOverridden = true;
			ingredientCpy.seasons = props.ingredient.baseIngredient.seasons;
		}
		props.setIngredient(ingredientCpy);
	};

	const handleLockClickAllergens = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.isAllergenOverridden = false;
			ingredientCpy.allergens = [];
		} else {
			ingredientCpy.isAllergenOverridden = true;
			ingredientCpy.allergens = props.ingredient.baseIngredient.allergens;
		}
		props.setIngredient(ingredientCpy);
	};

	const handleLockClickTags = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.isTagOverridden = false;
			ingredientCpy.tags = [];
		} else {
			ingredientCpy.isTagOverridden = true;
			ingredientCpy.tags = props.ingredient.baseIngredient.tags;
		}
		props.setIngredient(ingredientCpy);
	};

	const handleLockClickUnitWeights = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.isUnitWeightOverridden = false;
			ingredientCpy.unitWeights = [];
		} else {
			ingredientCpy.isUnitWeightOverridden = true;
			ingredientCpy.unitWeights = props.ingredient.baseIngredient.unitWeights;
		}
		props.setIngredient(ingredientCpy);
	};

	const handleLockClickScalingFactor = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.isScalingFactorOverridden = false;
			ingredientCpy.scalingFactor = null;
		} else {
			ingredientCpy.isScalingFactorOverridden = true;
			ingredientCpy.scalingFactor = props.ingredient.baseIngredient.scalingFactor;
		}
		props.setIngredient(ingredientCpy);
	};

	const handleCharacteristicLockClick = (isLocked: boolean): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (isLocked) {
			ingredientCpy.characteristic = null;
		} else {
			ingredientCpy.characteristic = props.ingredient.baseIngredient.characteristic;
		}
		props.setIngredient(ingredientCpy);
	};

	const getLabelDensity = (): string => {
		return `${t('_general:DENSITY')} (${t('measurments:GRAMMS_PER_QUBIC_CM')})`;
	};

	const getLabelPrice = (): string => {
		if (props.ingredient.characteristic == Characteristic.Fluid) {
			return `${reduxCurrency} ${t('_general:PER')} 100ml`;
		} else {
			return `${reduxCurrency} ${t('_general:PER')} 100g`;
		}
	};

	const getLabelCalories = (): string => {
		if (props.ingredient.characteristic == Characteristic.Fluid) {
			return `${t('_general:CALORIES')} (${t('measurments:CAL_PER_100_MILLILITERS')})`;
		} else {
			return `${t('_general:CALORIES')} (${t('measurments:CAL_PER_100_GRAMMS')})`;
		}
	};

	const getSuggestedSeasons = async (input: string): Promise<void> => {
		const items: ISelectItem[] = await props.getSeasonsStartingWith(input);
		setSuggestedSeasons(items);
	};

	const getAllergensStartingWith = async (input: string): Promise<void> => {
		const items = await props.getAllergensStartingWith(input);
		setSuggestedAllergens(items);
	};

	const getSuggestedBaseIngredients = async (input: string): Promise<void> => {
		const items: ISelectItem[] = await props.getBaseIngredientsStartingWith(input);
		setSuggestedBaseIngredients(items);
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (props.nutrients) {
			const macroWeight = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.reduce(
					(p, c) =>
						p +
						(props.ingredient.nutrientValues.find((x) => x.id == c.id)?.value ??
							props.ingredient.baseIngredient.nutrientValues.find((x) => x.id == c.id)
								?.value ??
							0),
					0
				);

			const macros = props.nutrients
				.filter((n) => n.isMacroNutrient)
				.map((n) => {
					return {
						label: n.name,
						value:
							(props.ingredient.nutrientValues.find((x) => x.id == n.id)?.value ??
								props.ingredient.baseIngredient.nutrientValues.find(
									(x) => x.id == n.id
								)?.value ??
								0) / macroWeight,
					};
				});

			return macros;
		}
		return [];
	};

	const getLabelSeasons = (): string => {
		if (!ingredientEditor) {
			if (
				props.ingredient.isSeasonOverridden
					? props.ingredient.seasons.length === 0
					: props.ingredient.baseIngredient.seasons.length === 0
			) {
				return t('_general:NO_SEASONS');
			}
			return '';
		}
		return t('_general:SEASONS');
	};

	const getLabelCategories = (): string => {
		if (!ingredientEditor) {
			if (props.ingredient.categories.length === 0) {
				return t('ingredient:NO_CATEGORIES');
			}
			return '';
		}
		return t('ingredient:CATEGORIES');
	};

	const getLinkToBaseIngredient = (): string | undefined => {
		if (arePermissionsInUserPermissions([PERMISSIONS.READBASEINGREDIENT])) {
			if (props.ingredient.baseIngredient) {
				return `/baseIngredient/detail/${props.ingredient.baseIngredient.id}`;
			}
		}
		return undefined;
	};

	const renderContent = () => {
		return (
			<>
				<FoldableCard
					defaultOpen={true}
					title={t('ingredient:BASE_INGREDIENT')}
					content={
						<>
							<div className="card-body">
								<SingleSearchSelect
									disabled={!ingredientEditor}
									errorKey={'BaseIngredientId'}
									handleChangeSearchTerm={getSuggestedBaseIngredients}
									hideField={!ingredientEditor}
									label={!ingredientEditor ? '' : t('ingredient:BASE_INGREDIENT')}
									linkTo={getLinkToBaseIngredient()}
									selectedItem={mapBaseIngredientToItem()}
									setSavedItem={handleSetBaseIngredient}
									suggestedItems={suggestedBaseIngredients}
									triggerSearchLetterAmount={0}
								/>
							</div>
						</>
					}
				/>
				<FoldableCard
					unlockable={ingredientEditor}
					defaultOpen={true}
					defaultLocked={props.ingredient.characteristic ? false : true}
					hasLock={true}
					handleLockClick={handleCharacteristicLockClick}
					title={t('ingredient:CHARACTERISTICS')}
					content={
						<>
							<div className="card-body">
								<CharacteristicRadioSelect
									checked={
										props.ingredient.characteristic ??
										props.ingredient.baseIngredient.characteristic
									}
									disabled={!ingredientEditor}
									handleCharacteristicChange={props.handleCharacteristicChange}
								/>
							</div>
						</>
					}
				/>
				<FoldableCard
					defaultOpen={true}
					title={t('ingredient:OVERRIDE_BASE_INGREDIENT')}
					content={
						<>
							<div className="card-body">
								<LockableInput
									id="density"
									label={getLabelDensity()}
									placeholder=""
									withLock={true}
									withTrashCan={false}
									defaultLocked={props.ingredient.density === null}
									defaultValue={getDefaultValue('density')}
									validateRegex={regexValidationDensity}
									handleValueChange={changeIngredientAttribute}
									handleLockClick={resetIngredientAttribute}
									disabled={!ingredientEditor}
									errorKey="Density"
								/>
								<LockableInput
									id="calories"
									label={getLabelCalories()}
									placeholder=""
									withLock={true}
									withTrashCan={false}
									defaultLocked={props.ingredient.calories === null}
									defaultValue={getDefaultValue('calories')}
									validateRegex={regexValidationCalories}
									handleValueChange={changeIngredientAttribute}
									handleLockClick={resetIngredientAttribute}
									disabled={!ingredientEditor}
									errorKey="Calories"
								/>
							</div>
						</>
					}
				/>
				<FoldableCard
					unlockable={ingredientEditor}
					defaultOpen={true}
					title={t('baseIngredient:WEIGHT')}
					hasLock={true}
					defaultLocked={!props.ingredient.isUnitWeightOverridden}
					handleLockClick={handleLockClickUnitWeights}
					content={
						<div className="card-body">
							<UnitWeightEditor
								id={props.ingredient.id}
								units={props.units}
								baseIngredientCharacteristic={props.ingredient.characteristic}
								baseIngredientUnitWeights={
									props.ingredient.isUnitWeightOverridden
										? props.ingredient.unitWeights
										: props.ingredient.baseIngredient.unitWeights
								}
								setBaseIngredientUnitWeights={handleAddUnitWeight}
								getSuggestedUnitWeight={handleGetSuggestedUnitWeight}
								disabled={!ingredientEditor}
							/>
						</div>
					}
				/>
				<FoldableCard
					unlockable={ingredientEditor}
					defaultOpen={true}
					title={t('ingredient:SCALING_FACTOR')}
					hasLock={true}
					defaultLocked={!props.ingredient.isScalingFactorOverriden}
					handleLockClick={handleLockClickScalingFactor}
					content={
						<div className="card-body">
							<LockableInput
								id="scalingFactor"
								label={t('ingredient:NON_LINEAR_SCALING_FACTOR')}
								placeholder=""
								withLock={true}
								withTrashCan={false}
								defaultLocked={props.ingredient.scalingFactor === null}
								defaultValue={getDefaultValue('scalingFactor')}
								validateRegex={regexValidationCalories}
								handleValueChange={changeIngredientAttribute}
								handleLockClick={resetIngredientAttribute}
								disabled={!ingredientEditor}
								errorKey="ScalingFactor"
							/>
						</div>
					}
				/>
				<FoldableCard
					defaultOpen={true}
					title={t('ingredient:CATEGORIES')}
					content={
						<>
							<div className="card-body">
								<TreeInput
									input={props.categoryTree}
									setSelected={props.handleChangeCategory}
									selected={getSelectedCategoriesTree(
										props.ingredient,
										props.categoryTree
									)}
									errorKey={'Categories'}
									disabled={!ingredientEditor}
									label={getLabelCategories()}
									noObjectsFoundText={t('_general:NO_CATEGORIES')}
								/>
							</div>
						</>
					}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<FoldableCard
						defaultOpen={true}
						title={t('_general:COSTS')}
						content={
							<>
								<div className="card-body">
									<LockableInput
										id="price"
										label={getLabelPrice()}
										placeholder=""
										withLock={false}
										withTrashCan={false}
										defaultLocked={false}
										defaultValue={
											props.ingredient.price
												? String(props.ingredient.price)
												: ''
										}
										validateRegex={regexValidationPrice}
										handleValueChange={changeIngredientAttribute}
										handleLockClick={resetIngredientAttribute}
										disabled={!ingredientEditor}
										errorKey="Price"
									/>
								</div>
							</>
						}
					/>
				</RenderIf>
				<FoldableCard
					unlockable={ingredientEditor}
					defaultOpen={true}
					defaultLocked={!props.ingredient.isTagOverridden}
					hasLock={true}
					handleLockClick={handleLockClickTags}
					title={t('_general:TAGS')}
					content={
						<>
							<div className="card-body">
								<TagEditor
									cultureCode={props.cultureCode}
									allTags={props.tags}
									tags={
										props.ingredient.isTagOverridden
											? props.ingredient.tags
											: props.ingredient.baseIngredient.tags
									}
									setTags={handleAddTag}
									description={
										props.ingredient.nameSingularTranslations[props.cultureCode]
									}
									disabled={!ingredientEditor}
								/>
							</div>
						</>
					}
				/>
				<FoldableCard
					defaultOpen={true}
					defaultLocked={!props.ingredient.isAllergenOverridden}
					hasLock={true}
					handleLockClick={handleLockClickAllergens}
					title={t('_general:ALLERGENS')}
					info={'_general:ALLERGEN_INFO_TOOLTIP'}
					content={
						<>
							<div className="card-body">
								<AllergenSelect
									allergens={
										props.ingredient.isAllergenOverridden
											? props.ingredient.allergens
											: props.ingredient.baseIngredient.allergens
									}
									setSavedAllergens={handleAddAllergen}
									handleChangeSearchTerm={getAllergensStartingWith}
									suggestedAllergens={suggestedAllergens}
									disabled={!ingredientEditor}
								/>
							</div>
						</>
					}
					unlockable={ingredientEditor}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
					<FoldableCard
						defaultOpen={true}
						defaultLocked={!props.ingredient.isSeasonOverridden}
						handleLockClick={handleLockClickSeason}
						hasLock={true}
						title={t('_general:SEASONS')}
						content={
							<>
								<div className="card-body">
									<MultiSearchSelect
										savedItems={mapToISelectItem(
											props.ingredient.isSeasonOverridden
												? props.ingredient.seasons
												: props.ingredient.baseIngredient.seasons
										)}
										displaySavedItems="bottom"
										setSavedItems={handleAddSeason}
										handleChangeSearchTerm={getSuggestedSeasons}
										suggestedItems={suggestedSeasons}
										label={getLabelSeasons()}
										triggerSearchLetterAmount={0}
										hideInputField={!ingredientEditor}
										errorKey={'Seasons'}
									/>
								</div>
							</>
						}
						unlockable={ingredientEditor}
					/>
				</RenderIf>
				<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
					<FoldableCard
						defaultOpen={true}
						hasLock={true}
						defaultLocked={!props.ingredient.nutriScoreCategoryIsSetByUser}
						handleLockClick={handleLockClickNutriScore}
						title={t('_general:NUTRI_SCORE')}
						content={
							<NutriScoreSelect
								hasNutriScore={props.ingredient.hasNutriScore}
								nutriScoreCategory={
									props.ingredient.nutriScoreCategoryIsSetByUser
										? props.ingredient.nutriScoreCategory
										: null
								}
								setHasNutriScore={handleSetHasNutriScore}
								setNutriScoreCategory={handleSetNutriScoreCategory}
								canImplyNutriScoreMethod={true}
								disabled={!ingredientEditor}
							/>
						}
						unlockable={ingredientEditor}
					/>
				</RenderIf>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:NUTRI_DIAGRAM')}
					content={
						<NutritionDiagram data={getMacroNutrientChartData()}></NutritionDiagram>
					}
				></FoldableCard>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default IngredientSidebar;
