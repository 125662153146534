export function hasSliderChanges(
	left: number,
	right: number,
	input: {
		left: number | null;
		right: number | null;
	}
): boolean {
	if (input.left !== null) {
		if (Math.floor(input.left) !== left) return true;
	}
	if (input.right !== null) {
		if (Math.ceil(input.right) !== right) return true;
	}
	return false;
}
