import NutrientCategoryManagementComponent from 'components/desktop/StandingData/NutrientCategory/Management/NutrientCategoryManagementComponent';

const NutrientCategoryManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<NutrientCategoryManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientCategoryManagementContainer;
