import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Menu';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	nameTranslations: Translation;
	shortDescriptionTranslations: Translation;
	recipes: string[];
	statusId: string;
	systemStatus: SystemStatus;
	tags: string[];
}

export interface Response {
	data: string;
}

export type Translation = {
	[key: string]: string;
};
