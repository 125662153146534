import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/AccessoryCategory/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ParentCategory = {
	id: string;
	name: string;
};

export type ResponseData = {
	id: string;
	parent: ParentCategory | null;
	names: {
		[key: string]: string;
	};
	source: string | null;
};
