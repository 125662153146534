import * as apiDelete from 'api/season/DeleteSeasonIdV1';
import * as apiRestore from 'api/season/PostSeasonRestoreV1';
import * as apiPostSearch from 'api/season/PostSeasonSearchV1';

export default class Season {
	public id: string = '';
	public iconSvgUrl: string | null = null;
	public name: string = '';
	public display: boolean = false;
	public associatedMonthsFlag: number = 0;
	public sortOrder: number = 0;

	public constructor(input?: apiPostSearch.ResponseSeason) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseSeason) {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl;
		this.name = input.name;
		this.display = input.display;
		this.associatedMonthsFlag = input.associatedMonthsFlag;
		this.sortOrder = input.sortOrder;
	}
}
