import * as apiSW from 'api/menuPlanner/GetMenuPlannerStartingWithV1';
import * as api from 'api/menuPlanner/PostMenuPlannerSearchV1';
import MenuPlan from 'classes/MenuPlan/Search/MenuPlan';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class MenuPlanList {
	public all: MenuPlan[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const response = await apiSW.apiCall(searchTerm, showOnlyDeleted);
		response.hasValue() && this.mapFromApiSW(response.get());
	}

	public async callApi(input: api.Request) {
		return await api.apiCall(input);
	}

	public mapFromApi(input: api.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const menuPlan: MenuPlan = new MenuPlan(i);
			this.all.push(menuPlan);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseMenuPlanner[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
