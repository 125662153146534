import Archive from 'containers/desktop/StandingData/NutriScoreLabel/Archive/NutriScoreLabelArchive';
import Management from 'containers/desktop/StandingData/NutriScoreLabel/Management/NutriScoreLabelManagement';
import PERMISSIONS from 'enums/permissions';

export const nutriscoreLabelRoutes = [
	{
		path: '/nutriScoreLabel/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/nutriScoreLabel/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
