type Button = {
	icon: JSX.Element;
	descriptor: string;
	default: boolean;
};

interface IProps {
	className?: string;
	button1: Button;
	button2: Button;
	localStorageItemName: string;
}

const NavigationPillsButtons = (props: IProps) => {
	const onClick = (descriptor: string): void => {
		localStorage.setItem(props.localStorageItemName, descriptor);
	};

	return (
		<div
			className={props.className ? 'nav nav-pills ' + props.className : 'nav nav-pills'}
			id="nav-tab"
			role="tablist"
		>
			<button
				className={
					props.button1.default ? 'nav-link active btn-switch' : 'nav-link btn-switch'
				}
				id={'nav-' + props.button1.descriptor + '-tab'}
				data-bs-toggle="tab"
				data-bs-target={'#nav-' + props.button1.descriptor}
				type="button"
				role="tab"
				aria-controls={'nav-' + props.button1.descriptor}
				aria-selected="true"
				onClick={() => onClick(props.button1.descriptor)}
			>
				{props.button1.icon}
			</button>
			<button
				className={
					props.button2.default ? 'nav-link active btn-switch' : 'nav-link btn-switch'
				}
				id={'nav-' + props.button2.descriptor + '-tab'}
				data-bs-toggle="tab"
				data-bs-target={'#nav-' + props.button2.descriptor}
				type="button"
				role="tab"
				aria-controls={'nav-' + props.button2.descriptor}
				aria-selected="false"
				onClick={() => onClick(props.button2.descriptor)}
			>
				{props.button2.icon}
			</button>
		</div>
	);
};

export default NavigationPillsButtons;
