import { ContextBuilder } from 'context/nutriscoreLabelContext';
import { useParams } from 'react-router-dom';

import IngredientComponent from 'components/desktop/Ingredient/IngredientComponent';

const IngredientDetailContainer = () => {
	const { id } = useParams();

	return (
		<main className="container">
			<IngredientComponent id={id} />
		</main>
	);
};

export default ContextBuilder.From(IngredientDetailContainer).wrapWithNutriscoreContext().build();
