import ProductionMethodManagementComponent from 'components/desktop/StandingData/ProductionMethod/Management/ProductionMethodManagementComponent';

const ProductionMethodArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<ProductionMethodManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default ProductionMethodArchiveContainer;
