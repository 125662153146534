import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import RecipeLanguageInput from 'components/desktop/Recipe/LanguageInput/RecipeLanguageInput';
import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import RecipeRendering from 'components/desktop/Recipe/RecipeTabs/RecipeTabs';
import RecipeTextInput from 'components/desktop/Recipe/RecipeTextInput/RecipeTextInput';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import { RefreshType } from 'hooks/useRefresh';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	loadingStatus: {
		isSegmentsLoadingDone: boolean;
		isScaledSegmentsLoadingDone: boolean;
	};
	refresh: RefreshType;
	entryLanguageCultureCode: string;
	getIngredientSuggestions: (input: string, language: string) => void;
	handleSelectEntryLanguage: (e: any) => void;
	incorporationShares: IncorporationShareType[];
	ingredientSuggestions: string[];
	languageSwitcherCultureCode: string;
	recipe: Recipe;
	recipeCalculation: Calculation | null;
	scaledRecipeCalculation: Calculation | null;
	scaledSegmentsInLanguageSwitcherLanguage: Segment[];
	segmentInLanguageSwitcherLanguage: Segment[];
	setLanguageSwitcherCultureCode: Dispatch<SetStateAction<string>>;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
	languages: Language[];
	nutrients: CalculatedNutrient[];
	readOnly: boolean;
	nutriScore: NutriScore | null;
}

const RecipeInformationsComponent = (props: IProps) => {
	const reduxAllowedEntryLanguages: string[] = useSelector(
		(state: RootState) => state.allowedEntryLanguages
	);

	const getIngredientSuggestionsCallback = useCallback(
		(input: string) => {
			props.getIngredientSuggestions(input, props.languageSwitcherCultureCode);
		},
		[props.languageSwitcherCultureCode]
	);

	const renderEntryLanguageSelect = (): JSX.Element => {
		return (
			<div>
				<div className="d-flex justify-content-end">
					<label>{t('recipe:ENTRYLANGUAGE')}</label>
				</div>
				<div className="d-flex" style={{ gap: '5px' }}>
					<div className="d-flex align-items-center">
						<FontAwesomeIcon
							title={t('recipe:INFO_CALCULATION_ON_ENTRYLANGUAGE')}
							icon={faCircleInfo as IconProp}
						/>
					</div>
					<select
						className="form-select"
						value={
							props.languages
								.filter((x) => x.id === props.recipe.entryLanguage.id)
								.firstOrDefault()?.cultureCode || ''
						}
						onChange={(e: any) => props.handleSelectEntryLanguage(e)}
						disabled={props.readOnly}
					>
						{props.languages.map((l: Language, key: number) => (
							<option key={key} value={l.cultureCode}>
								{l.name}
							</option>
						))}
					</select>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="margin-top-25">
				<fieldset>
					<div className="d-flex justify-content-between">
						<LanguageSwitch
							content={<></>}
							entryLanguage={props.entryLanguageCultureCode}
							handleSelectLanguage={handlers.createSelectLanguageHandler(
								props.setLanguageSwitcherCultureCode
							)}
							onTranslateClick={handlers.createTranslationHandler(
								props.setRecipe,
								props.entryLanguageCultureCode,
								props.languageSwitcherCultureCode
							)}
							selectedLanguage={props.languageSwitcherCultureCode}
							translatable={true}
						/>
						<RenderIf condition={reduxAllowedEntryLanguages.length > 1}>
							{renderEntryLanguageSelect()}
						</RenderIf>
					</div>
					<RecipeLanguageInput
						entryLanguage={props.entryLanguageCultureCode}
						handleValueChange={handlers.createInputValueChangeHandler(
							props.setRecipe,
							props.languageSwitcherCultureCode
						)}
						onTranslateClick={handlers.createTranslationHandler(
							props.setRecipe,
							props.entryLanguageCultureCode,
							props.languageSwitcherCultureCode
						)}
						recipe={props.recipe}
						selectedLanguage={props.languageSwitcherCultureCode}
					/>
					<RecipeTextInput
						entryLanguage={props.entryLanguageCultureCode}
						getIngredientSuggestions={getIngredientSuggestionsCallback}
						handleValueChange={handlers.createInputValueChangeHandler(
							props.setRecipe,
							props.languageSwitcherCultureCode
						)}
						ingredientSuggestions={props.ingredientSuggestions}
						onTranslateClick={handlers.createTranslationHandler(
							props.setRecipe,
							props.entryLanguageCultureCode,
							props.languageSwitcherCultureCode
						)}
						onIngredientReformatClick={handlers.createIngredientReformatHandler(
							props.setRecipe
						)}
						recipe={props.recipe}
						selectedLanguage={props.languageSwitcherCultureCode}
						segments={props.segmentInLanguageSwitcherLanguage}
					/>
				</fieldset>
				<br />
				<fieldset>
					<RecipeRendering
						handleChangeScaledParts={handlers.createChangeScaledPartsHandler(
							props.setRecipe
						)}
						loadingStatus={props.loadingStatus}
						refresh={props.refresh}
						selectedLanguage={props.languageSwitcherCultureCode}
						calculation={props.recipeCalculation}
						handleUploadSegmentMedia={handlers.createSetSegmentFormDataHandler(
							props.setRecipe
						)}
						handleDeleteSegmentMedia={handlers.createDeleteSegmentMediaHandler(
							props.setRecipe
						)}
						readOnly={props.readOnly}
						handleIncorporationShareChange={handlers.createIncorporationHandler(
							props.setRecipe
						)}
						incorporationShares={props.incorporationShares}
						recipe={props.recipe}
						scaledCalculation={props.scaledRecipeCalculation}
						scaledSegments={props.scaledSegmentsInLanguageSwitcherLanguage}
						segmentMedia={props.recipe.segmentMedia}
						segments={props.segmentInLanguageSwitcherLanguage}
						nutrients={props.nutrients}
						nutriScore={props.nutriScore}
					/>
				</fieldset>
			</div>
		</>
	);
};

export default RecipeInformationsComponent;
