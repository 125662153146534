import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Allergen';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	negativeIconSvgId: string | null;
	positiveIconSvgId: string | null;
	mayContainIconSvgId: string | null;
	display: boolean;
	positiveNameTranslations: {
		[key: string]: string;
	};
	negativeNameTranslations: {
		[key: string]: string;
	};
	mayContainNameTranslations: {
		[key: string]: string;
	};
}

export interface Response {
	data: string;
}
