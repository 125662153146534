import * as apiSW from 'api/recipe/GetRecipeStartingWithV1';
import Recipe from 'classes/_general/Search/Recipe/Recipe';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class RecipeList {
	public all: Recipe[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Recipe[] = this.all.filter((e: Recipe) =>
			e.description.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	public getById(id: string): Recipe | undefined {
		return this.all.find((e: Recipe) => {
			return e.id === id;
		});
	}

	private mapToSelectItems(input: Recipe[]): ISelectItem[] {
		return input.map((e: Recipe) => {
			return e.mapToSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseRecipe[]): void {
		this.all = [];
		for (const i of input) {
			const recipe: Recipe = new Recipe(i);
			this.all.push(recipe);
		}
	}
}
