import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Language';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	cultureCode: string;
	sortOrder: number;
	isoCode6391: string;
	isoCode6393: string;
	hasArticleBeforeIngredientName: boolean;
	translationCode: string | null;
	nameTranslations: {
		[key: string]: string;
	};
}

export interface Response {
	data: string;
}
