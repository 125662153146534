import * as apiSW from 'api/allergen/GetAllergenStartingWithV1';
import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import Allergen from 'classes/MenuPlan/Detail/Allergen';
import { AllergenLogic } from 'enums/allergenLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class AllergenList {
	public all: Allergen[] = [];
	public saved: Allergen[] = [];
	public filtered: ISelectItem[] = [];

	public constructor(input?: api.Allergen[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async initialize(): Promise<void> {
		await this.callApi();
		this.transferAllToFiltered();
	}

	public async callApi(): Promise<void> {
		const response = await apiSW.callApi('', false);
		const allergens = response.get().map((x) => ({
			...x,
			source: null,
		}));
		response.hasValue() && this.mapFromApi(allergens, 'all');
	}

	public async mapFromISelectItems(input: ISelectItem[]): Promise<void> {
		const output: Allergen[] = [];
		for (const i of input) {
			const allergen: Allergen | undefined = this.getById(i.id);
			if (allergen) output.push(allergen);
		}
		this.saved = output;
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Allergen[] = this.all.filter(
			(e: Allergen) => e.name?.toLowerCase().includes(searchTerm.toLowerCase())
		);
		this.filtered = this.mapToSelectItems(filtered);
	}

	public mapFromApi(input: api.Allergen[] | null, arrayName?: 'all' | 'saved') {
		if (!input) return;
		for (const i of input) {
			const allergen: Allergen = new Allergen(i);
			this[arrayName ?? 'saved'].push(allergen);
		}
	}

	public mapFromApiCalculate(input: apiCalculate.Allergens[] | null) {
		if (!input) return;
		this['saved'] = input.map(
			(e) =>
				new Allergen({
					...e,
					logic: e.logic as AllergenLogic,
				})
		);
	}

	public mapToApiPut(): string[] {
		return this.saved.map((e: Allergen) => {
			return e.id;
		});
	}

	public getById(id: string): Allergen | undefined {
		return this.all.find((e: Allergen) => {
			return e.id === id;
		});
	}

	public getSavedAsSelectItems() {
		return this.mapToSelectItems(this.saved);
	}

	public getVisibleAllergens(): Allergen[] {
		return this.saved.filter((e: Allergen) => {
			return e.display && e.logic === AllergenLogic.Negative;
		});
	}

	private mapToSelectItems(input: Allergen[]): ISelectItem[] {
		return input.map((e: Allergen) => {
			return e.mapToISelectItem();
		});
	}

	private transferAllToFiltered() {
		this.filtered = this.mapToSelectItems(this.all);
	}
}
