import { RefObject, useEffect, useRef, useState } from 'react';

import { TextInfo, getCursorOffset, getTextInfoFromSelection } from 'functions/textAreaFunctions';

interface IProps {
	textArea: RefObject<HTMLTextAreaElement>;
	suggestions: string[];
	getSuggestions?: (input: string) => void;
	handleValueChange: (input: string) => void;
}

const textInfo: TextInfo = {
	indexCursor: 0,
	indexWordStart: 0,
	indexWordEnd: 0,
	word: '',
	textBeforeWord: '',
	textAfterWord: '',
	textBeforeCursor: '',
	textAfterCursor: '',
	textUntilAfterWord: '',
};

const SuggestionList = (props: IProps) => {
	const [focus, setFocus] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(0);
	const [display, setDisplay] = useState<boolean>(false);
	const [offsetTop, setOffsetTop] = useState<number>(48);
	const [offsetLeft, setOffsetLeft] = useState<number>(0);

	const currentListItem = useRef<HTMLDivElement | null>(null);
	const shadowElements = useRef<HTMLDivElement>(null);
	const dummyHeight = useRef<HTMLDivElement>(null);
	const dummyWidth = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		document.addEventListener('click', handleOnClickOutside);
		return () => {
			document.removeEventListener('click', handleOnClickOutside);
		};
	}, []);

	useEffect((): void => {
		if (currentListItem.current)
			currentListItem.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
				inline: 'nearest',
			});
	}, [index]);

	useEffect((): (() => void) | void => {
		setDisplay(false);
		if (props.textArea.current) {
			props.textArea.current.addEventListener('keyup', handleEventKeyUpTextArea);
		}
		return () => {
			if (props.textArea.current) {
				props.textArea.current.removeEventListener('keyup', handleEventKeyUpTextArea);
			}
		};
	}, [props.getSuggestions]);

	useEffect((): any => {
		setFocus(false);
		setIndex(0);
		if (
			props.suggestions.length === 0 ||
			textInfo.indexCursor !== textInfo.indexWordEnd ||
			textInfo.word === ' ' ||
			textInfo.word.length < 2
		) {
			setDisplay(false);
		} else {
			setDisplay(true);
			setOffsetTop(getListPosition().top);
			setOffsetLeft(getListPosition().left);
		}
	}, [props.suggestions]);

	useEffect((): (() => void) | void => {
		if (props.textArea.current) {
			props.textArea.current.addEventListener('keydown', handleEventKeyDownTextArea);
		}
		return () => {
			if (props.textArea.current) {
				props.textArea.current.removeEventListener('keydown', handleEventKeyDownTextArea);
			}
		};
	}, [index, focus, display]);

	const handleOnClickOutside = (e: MouseEvent) => {
		if (currentListItem.current && !currentListItem.current.contains(e.target as Node)) {
			hideList();
		}
	};

	const handleEventKeyUpTextArea = (event: KeyboardEvent): any => {
		if (!['ArrowDown', 'ArrowUp', 'Escape', 'Enter'].includes(event.key)) {
			if (props.textArea.current) {
				const textArea: HTMLTextAreaElement = props.textArea.current;
				const value: string = textArea.value;
				const selectionStart: number = textArea.selectionStart;
				updateTextInfo(value, selectionStart);
				if (textInfo.word === ' ' || textInfo.word.length < 2) {
					setDisplay(false);
				} else {
					getNewSuggestions();
				}
			}
		}
		updateOffsets();
	};

	const handleEventKeyDownTextArea = (event: KeyboardEvent): any => {
		switch (event.key) {
			case 'ArrowDown':
				if (display === true) {
					if (index === 0 && focus === false) {
						setFocus(true);
					} else {
						if (index + 1 < props.suggestions.length) {
							setIndex(index + 1);
						}
					}
					event.preventDefault();
				}
				break;
			case 'ArrowUp':
				if (display === true) {
					if (index > 0) {
						setIndex(index - 1);
					} else {
						hideList();
						event.preventDefault();
					}
					event.preventDefault();
				}
				break;
			case 'Escape':
				hideList();
				event.preventDefault();
				break;
			case 'Enter':
				if (display) {
					if (currentListItem.current?.textContent) {
						onListEnter();
						event.preventDefault();
					}
				}
				break;
		}
	};

	const updateOffsets = (): void => {
		if (props.textArea.current) {
			setOffsetTop(getListPosition().top);
			setOffsetLeft(getListPosition().left);
		}
	};

	const hideList = (): void => {
		setIndex(0);
		setFocus(false);
		setDisplay(false);
	};

	const getNewSuggestions = async (): Promise<void> => {
		if (props.getSuggestions) {
			if (textInfo.word && textInfo.word.length >= 2) {
				props.getSuggestions(textInfo.word);
			}
		}
	};

	const getSelectedSuggestion = (selectedIndex?: number) => {
		if (selectedIndex && props.suggestions[selectedIndex]) {
			return props.suggestions[selectedIndex];
		}
		return currentListItem.current?.textContent;
	};

	const getListPosition = () => {
		if (props.textArea.current) {
			const { top, left } = getCursorOffset(
				dummyWidth,
				dummyHeight,
				shadowElements,
				props.textArea,
				textInfo
			);
			hideDummyTags();
			return { top: top, left: left };
		}
		return { top: 48, left: 0 };
	};

	const hideDummyTags = (): void => {
		if (shadowElements.current) {
			shadowElements.current.style.display = 'none';
		}
	};

	const updateTextInfo = (value: string, currentCursorIndex: number): void => {
		Object.assign(textInfo, getTextInfoFromSelection(value, currentCursorIndex));
	};

	const onListEnter = (inputIndex?: number): void => {
		if (props.getSuggestions && props.suggestions) {
			if (props.textArea.current) {
				const suggestion = getSelectedSuggestion(inputIndex);
				if (suggestion) {
					textInfo.indexCursor = textInfo.indexWordStart + suggestion.length + 1;
					props.handleValueChange(
						textInfo.textBeforeWord + suggestion + ' ' + textInfo.textAfterWord
					);
					props.textArea.current.selectionStart = textInfo.indexCursor;
					props.textArea.current.selectionEnd = textInfo.indexCursor;
					hideList();
				} else {
					return;
				}
			}
			props.getSuggestions('');
		}
	};

	const onMouseDown = (input: number): void => {
		onListEnter(input);
	};

	const getClassName = (inputIndex: number): string => {
		if (focus) {
			if (inputIndex === index) {
				return 'select-item select-active';
			} else {
				return 'select-item';
			}
		} else {
			if (inputIndex === index) {
				return 'select-item select-active-passive';
			} else {
				return 'select-item';
			}
		}
	};

	return (
		<>
			{display && (
				<div
					className="selection-list"
					style={{
						top: offsetTop + 'px',
						left: offsetLeft + 'px',
					}}
					tabIndex={display ? 0 : undefined}
				>
					{props.suggestions.map((item, i) => (
						<div
							className={getClassName(i)}
							key={i}
							ref={i === index ? currentListItem : undefined}
							onMouseDown={() => onMouseDown(i)}
						>
							{item}
						</div>
					))}
				</div>
			)}
			<>
				<div ref={shadowElements} className="shadow-elements">
					<span ref={dummyWidth}></span>
					<div ref={dummyHeight}></div>
				</div>
			</>
		</>
	);
};

export default SuggestionList;
