import * as apiPostSearch from 'api/ingredient/PostIngredientSearchV1';
import IngredientCategory from 'classes/Ingredient/Search/IngredientCategory';

export default class IngredientCategoryList {
	public all: IngredientCategory[] = [];

	public constructor(input?: apiPostSearch.IngredientCategory[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.IngredientCategory[] | null) {
		if (input) {
			for (const i of input) {
				const category: IngredientCategory = new IngredientCategory(i);
				this.all.push(category);
			}
		}
	}
}
