import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import COLOR from 'enums/COLOR';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import * as Checked from 'types/Validation/Checked';
import * as Error from 'types/Validation/Error';
import * as Validation from 'types/Validation/Validation';

interface IProps {
	validation: Validation.Type;
	updateOne: (input: { error: Error.Type; value: boolean }) => void;
	updateAll: (input: { validation: Validation.Type; value: boolean }) => void;
	checked: Checked.Type;
}

const RecipeValidationDetail = (props: IProps) => {
	const { t } = useTranslation();
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const updateOne = (error: Error.Type, checked: Checked.Type) => {
		const isAcknowledged = Error.isAcknowledged(error, checked);
		props.updateOne({
			error: error,
			value: !isAcknowledged,
		});
	};

	const renderIcon = (input: Error.Type): JSX.Element => {
		const marginRight: string = '5px';
		if (Error.isChecked(input, props.validation.entityId, props.checked)) {
			return (
				<FontAwesomeIcon
					icon={faCircleCheck as IconProp}
					style={{ color: COLOR.SUCCESS, marginRight: marginRight }}
				/>
			);
		}
		return (
			<FontAwesomeIcon
				icon={faCircleXmark as IconProp}
				style={{ color: COLOR.DANGER, marginRight: marginRight }}
			/>
		);
	};

	const renderBadges = (): JSX.Element => {
		const amountOfUnacknowledged: number = Validation.getAmountOfUnacknowledged(
			props.validation,
			props.checked
		);
		const amountOfAcknowledged: number = Validation.getAmountOfAcknowledged(
			props.validation,
			props.checked
		);
		const badgeStyle: React.CSSProperties = {
			padding: '0.375rem 1rem',
			color: 'white',
			fontWeight: 'bold',
		};
		return (
			<>
				<RenderIf condition={Boolean(amountOfUnacknowledged)}>
					<div style={{ ...badgeStyle, backgroundColor: COLOR.DANGER }}>
						{amountOfUnacknowledged}
					</div>
				</RenderIf>
				<RenderIf condition={Boolean(amountOfAcknowledged)}>
					<div style={{ ...badgeStyle, backgroundColor: COLOR.SUCCESS }}>
						{amountOfAcknowledged}
					</div>
				</RenderIf>
			</>
		);
	};

	const renderButton = (): JSX.Element => {
		if (!recipeEditor) {
			return <></>;
		}

		const amountOfUnacknowledged: number = Validation.getAmountOfUnacknowledged(
			props.validation,
			props.checked
		);
		if (amountOfUnacknowledged) {
			return (
				<button
					type="button"
					className="btn btn-outline-success"
					onClick={() =>
						props.updateAll({
							validation: props.validation ?? Validation.create(),
							value: true,
						})
					}
					disabled={Boolean(!amountOfUnacknowledged)}
				>
					<FontAwesomeIcon
						icon={faCircleCheck as IconProp}
						style={{ marginRight: '5px' }}
					/>
					{t('_general:ACCEPT_ALL')}
				</button>
			);
		}
		return (
			<button
				type="button"
				className="btn btn-outline-danger"
				onClick={() =>
					props.updateAll({
						validation: props.validation ?? Validation.create(),
						value: false,
					})
				}
			>
				<FontAwesomeIcon icon={faCircleCheck as IconProp} style={{ marginRight: '5px' }} />
				{t('_general:DECLINE_ALL')}
			</button>
		);
	};

	return (
		<div style={{ minWidth: '800px' }}>
			<div className="d-flex justify-content-between flex-wrap" style={{ gap: '20px' }}>
				<div style={{ maxWidth: '700px' }}>
					<h2>{props.validation.name}</h2>
				</div>
				<Link to={'/recipe/detail/' + props.validation?.entityId} target="_blank">
					<button type="button" className="btn btn-outline-primary">
						{t('_general:OPEN')}
					</button>
				</Link>
			</div>
			<hr />
			<div className="d-flex" style={{ gap: '3px' }}>
				{renderBadges()}
				{renderButton()}
			</div>
			<hr />
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				{props.validation.validationErrors.map((e, i) => {
					return (
						<div key={i}>
							<strong>{e.validationType}</strong>
							{e.errors.map((e, i) => {
								return (
									<div
										key={i}
										onClick={() => recipeEditor && updateOne(e, props.checked)}
									>
										{renderIcon(e)}
										{e.validationMessage}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RecipeValidationDetail;
