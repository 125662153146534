import { ContextBuilder } from 'context/nutriscoreLabelContext';
import { useParams } from 'react-router-dom';

import BaseIngredientComponent from 'components/desktop/BaseIngredient/BaseIngredientComponent';
import { Optional } from 'functions/promiseExtensions';
import { useReaction } from 'hooks/useReaction';
import * as BaseIngredient from 'types/BaseIngredient/Detail/BaseIngredient';

const BaseIngredientDetailContainer = () => {
	const { id } = useParams();

	const [baseIngredient, isLoaded] = useReaction(
		Optional.None<BaseIngredient.Type>(),
		BaseIngredient.getFromApiOrCreate,
		id
	);
	return (
		<main className="container">
			{baseIngredient.hasValue() && (
				<BaseIngredientComponent
					initialState={baseIngredient.get()}
					isLoading={!isLoaded}
				/>
			)}
		</main>
	);
};

export default ContextBuilder.From(BaseIngredientDetailContainer)
	.wrapWithNutriscoreContext()
	.build();
