import { SidebarItem } from './constants/SidebarItems';

export function getBreadCrumbs(nav: SidebarItem[], path: string): string[] {
	for (const i of nav) {
		const keys: string[] | null = getTranslationKeyIfSelected(i, path, []);
		if (keys) return keys;
	}
	return [];
}

function getTranslationKeyIfSelected(
	item: SidebarItem,
	path: string,
	output: string[]
): string[] | null {
	if (pathCheck(path, item.url)) return [item.translationKey];
	if (!item.children) return null;
	for (const i of item.children) {
		const translationKeys: string[] | null = getTranslationKeyIfSelected(i, path, output);
		if (translationKeys) return [item.translationKey, ...translationKeys];
	}
	return null;
}

export function isItemSelected(item: SidebarItem, path: string): boolean {
	if (pathCheck(path, item.url)) return true;
	if (!item.children) return false;
	for (const i of item.children) {
		const isSelected: boolean = isItemSelected(i, path);
		if (isSelected) return true;
	}
	return false;
}

function pathCheck(path: string, url: string): boolean {
	if (path.includes('detail')) {
		path = path.split('/').slice(0, -1).join('/');
	}
	return path === url;
}

export function getAllInvolvedUrls(nav: SidebarItem[], id: string): string[] {
	for (const i of nav) {
		const keys: string[] | null = getUrlsIfInvolved(i, id, []);
		if (keys) {
			return keys;
		}
	}
	return [];
}

function getUrlsIfInvolved(item: SidebarItem, id: string, output: string[]): string[] | null {
	if (item.url === id) return [item.url];
	if (!item.children) return null;
	for (const i of item.children) {
		const ids: string[] | null = getUrlsIfInvolved(i, id, output);
		if (ids?.length) return [item.url, ...ids];
	}
	return null;
}
