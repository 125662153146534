import { useSelector } from 'react-redux';

import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

interface IProps {
	id?: string;
	handleDownload: (cultureCode: string) => void;
	displayOverlay: boolean;
	setDisplayOverlay: (input: boolean) => void;
}

const DownloadOverlay = (props: IProps) => {
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);

	const handleClick = (cultureCode: string) => {
		props.handleDownload(cultureCode);
	};

	return (
		<Overlay
			contentStyle={{ overflow: 'visible', maxHeight: '100vh', width: '400px' }}
			containerStyle={{ maxHeight: '100vh' }}
			handleClose={() => props.setDisplayOverlay(false)}
			displayOverlay={props.displayOverlay}
		>
			<div className={'d-flex flex-column'} style={{ gap: '5px' }}>
				{reduxLanguages.map((e: Language, i: number) => {
					return (
						<button
							key={i}
							className={'btn btn-outline-primary'}
							onClick={() => handleClick(e.cultureCode)}
						>
							{e.name}
						</button>
					);
				})}
			</div>
		</Overlay>
	);
};

export default DownloadOverlay;
