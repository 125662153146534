import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/UserCornerInfo';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	cultureCode: string;
	firstName: string;
	lastName: string;
	profilePicture: Image | null;
};

export type Image = {
	availableSizes: number[];
	id: string;
	url: string;
};
