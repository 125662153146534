export const Match = (props: { conditions: boolean[]; children: JSX.Element[] }) => {
	for (let i = 0; i < props.children.length; i++) {
		if (i >= props.conditions.length) {
			return props.children[i];
		}
		if (props.conditions[i]) {
			return props.children[i];
		}
	}
	return <></>;
};
