import { t } from 'i18next';

import ProductAddCard from 'components/desktop/Ingredient/IngredientProducts/ProductAddCard/ProductAddCard';
import ProductCardSlider from 'components/desktop/Ingredient/IngredientProducts/ProductCardSlider/ProductCardSlider';
import ProductDetailCard from 'components/desktop/Ingredient/IngredientProducts/ProductDetailCard/ProductDetailCard';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { SliderCard } from 'components/desktop/_general/CardSlider/CardSlider';
import * as IngredientProduct from 'types/Product/IngredientProduct';

interface IProps {
	disabled: boolean;
	handleAddProduct: () => Promise<void>;
	products: IngredientProduct.Type[];
	setProducts: (products: IngredientProduct.Type[]) => void;
}

const IngredientProducts = (props: IProps) => {
	const handleDelete = (_e: any, index: number) => {
		const productsCpy = [...props.products];
		productsCpy.splice(index, 1);
		props.setProducts(productsCpy);
	};

	const getCards = (products: IngredientProduct.Type[]): SliderCard[] => {
		const cards: SliderCard[] = [];

		products.map((x, index) => {
			cards.push({
				content: (
					<ProductDetailCard handleDelete={handleDelete} index={index} product={x} />
				),
			});
		});

		cards.push({ content: <ProductAddCard handleAdd={props.handleAddProduct} /> });

		return cards;
	};

	const getContent = (): JSX.Element => {
		return <ProductCardSlider cards={getCards(props.products)} />;
	};

	return (
		<div className="margin-top-25">
			<FoldableCard
				defaultOpen={true}
				title={t('ingredient:PRODUCTS')}
				ai={true}
				content={getContent()}
				disabled={props.disabled}
			/>
		</div>
	);
};

export default IngredientProducts;
