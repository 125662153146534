import * as apiDelete from 'api/errorMessage/DeleteErrorMessageIdV1';
import * as apiRestore from 'api/errorMessage/PostErrorMessageRestoreV1';
import * as apiPostSearch from 'api/errorMessage/PostErrorMessageSearchV1';

export default class ErrorMessage {
	public id: string = '';
	public errorKey: string = '';
	public errorMessage: string | null = null;

	public constructor(input?: apiPostSearch.ResponseErrorMessage) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseErrorMessage) {
		this.id = input.id;
		this.errorKey = input.errorKey;
		this.errorMessage = input.errorMessage;
	}
}
