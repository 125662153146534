import { ToggleItem } from 'types/_general/ToggleItem';

type ApiResponse = {
	id: string;
	name: string;
};

export default class Toggle {
	public id: string = '';
	public name: string = '';
	public checked: boolean = true;

	public constructor(input?: ApiResponse) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public reset(): void {
		this.checked = true;
	}

	public mapToToggleItem(): ToggleItem {
		return {
			id: this.id,
			checked: false,
			label: this.name,
		};
	}

	private mapFromApi(input: ApiResponse): void {
		this.id = input.id;
		this.name = input.name;
	}

	public mapFromUrl(ids: string): void {
		const idArray: string[] = ids.split(';');
		for (const id of idArray) {
			if (id === this.id) {
				this.checked = false;
			}
		}
	}
}
