import Accessory from 'classes/Accessory/Search/Accessory';
import AccessoryList from 'classes/Accessory/Search/AccessoryList';
import 'components/desktop/Accessory/Search/SearchResultList.scss';
import SearchResultListRow from 'components/desktop/Accessory/Search/SearchResultListRow';

interface IProps {
	accessoryList: AccessoryList;
	archive: boolean;
	handleDeleteAccessory: (input: Accessory) => Promise<void>;
	handleRestoreAccessory: (input: Accessory) => Promise<void>;
}

const SearchResultList = (props: IProps) => {
	return (
		<div className="row-gy-4">
			<div className="col-lg-12">
				{props.accessoryList.all.map((e: Accessory, i: number) => {
					return (
						<div key={i}>
							<SearchResultListRow
								accessory={e}
								archive={props.archive}
								handleDeleteAccessory={props.handleDeleteAccessory}
								handleRestoreAccessory={props.handleRestoreAccessory}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SearchResultList;
