import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/Input/TranslatableInput/TranslatableInput.scss';
import ENTITLEMENTS from 'enums/entitlements';

interface IProps {
	name: string;
	value: string;
	translatable: boolean;
	handleValueChange: (input: string) => void;
	onTranslateClick: (name: string) => void;
	disabled?: boolean;
	errorKey?: string;
}

const TranslatableInput = (props: IProps) => {
	const renderGlobe = (): JSX.Element => {
		return (
			<RenderIf entitlements={[ENTITLEMENTS.TRANSLATION_SERVICE]}>
				<span
					className="input-group-text translatable-input-button"
					id="basic-addon1"
					onClick={() => props.onTranslateClick(props.name)}
				>
					<FontAwesomeIcon icon={faGlobe} className="icon" />
				</span>
			</RenderIf>
		);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		props.handleValueChange(e.target.value);
	};

	const renderContent = (): JSX.Element => {
		return (
			<div className="input-group">
				<input
					className="form-control"
					type="text"
					value={props.value}
					name={props.name}
					onChange={handleValueChange}
					disabled={props.disabled ?? false}
					error-key={props.errorKey}
				/>
				{props.translatable ? <>{renderGlobe()}</> : <></>}
			</div>
		);
	};

	return <>{renderContent()}</>;
};

export default TranslatableInput;
