import * as apiPost from 'api/nutriScoreIngredientCategoryPoints/PostNutriScoreIngredientCategoryPointsV1';
import * as apiPut from 'api/nutriScoreIngredientCategoryPoints/PutNutriScoreIngredientCategoryPointsV1';
import { IngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';

export const mapToRequest = (input: IngredientCategoryPoint): apiPut.Request & apiPost.Request => {
	return {
		ingredientCategoryId: input.ingredientCategoryId,
		pointType: input.pointType,
		points: input.points,
		isPositive: input.isPositive,
	};
};
