import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import VerifyForm from 'components/desktop/User/Verify/VerifyForm';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import { EAlertType } from 'enums/alertType';
import { fireAlert } from 'functions/fireAlert';
import getQueryParam from 'functions/getQueryParam';
import {
	UserVerificationDto,
	defaultUserVerificationDto,
} from 'types/User/Verification/UserVerificationDto';

interface IProps {
	passwordLess?: boolean;
}

const Verify = (props: IProps) => {
	const { t } = useTranslation();
	const [verificationDto, setVerificationDto] = useState<UserVerificationDto>(
		defaultUserVerificationDto()
	);
	const navigate = useNavigate();

	const apiPost = async (userId: string) => {
		const url = `/backend-service/User/${userId}/ConfirmEmail`;
		try {
			const response = await axios.post(url, verificationDto);
			if (response.status === 200) {
				fireAlert(
					EAlertType.success,
					t('alerts:ALERT_VALIDATION_GOOD'),
					t('alerts:ALERT_VALIDATION_GOOD_MESSAGE'),
					10000
				);
				navigate('/login');
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleConfirm = () => {
		const userId = getQueryParam('user');
		if (userId) {
			apiPost(userId);
		} else {
			fireAlert(
				EAlertType.danger,
				t('alerts:ALERT_VALIDATION_ERROR'),
				t('alerts:ALERT_VALIDATION_ERROR_MESSAGE'),
				10000
			);
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="credentials-background-gradient">
					<div className="credentials-white-bar" />
					<div className="credentials-container">
						<LogoAnimation />
						<div className="credentials-form">
							<h1 className="text-center">
								{t('_general:TITLE_EMAIL_CONFIRMATION')}
							</h1>
							<hr />
							<VerifyForm
								dto={verificationDto}
								setDto={setVerificationDto}
								confirm={handleConfirm}
								passwordLess={props.passwordLess}
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default Verify;
