import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/ErrorMessage/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	errorKey: string;
	errorTranslations: {
		[key: string]: string;
	};
}
