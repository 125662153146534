import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import { ILanguageNavigationPill } from 'interfaces/ILanguageNavigationPill';

interface IProps {
	navigationPills: ILanguageNavigationPill[];
	selectedLanguage: string;
	translatable: boolean;
	handleSelectLanguage: (e: any, selectedLanguage: string) => void;
	onTranslateClick?: () => void;
}

const LanguageNavigationPills = (props: IProps) => {
	const renderGlobe = (key: number): JSX.Element => {
		return (
			<span
				key={key}
				className="input-group-text translatable-input-button"
				id="basic-addon1"
				style={{ cursor: 'pointer' }}
				onClick={props.onTranslateClick}
			>
				<FontAwesomeIcon icon={faGlobe} className="icon" />
			</span>
		);
	};

	return (
		<>
			<RenderIf entitlements={[ENTITLEMENTS.LANGUAGES]}>
				<nav>
					<div className="nav nav-pills" id="nav-tab" role="tablist">
						{props.navigationPills.map((navPill, i) => (
							<React.Fragment key={i}>
								<button
									className={
										navPill.cultureCode === props.selectedLanguage
											? 'nav-link active'
											: 'nav-link'
									}
									id={navPill.nameId + '-tab'}
									data-bs-toggle="tab"
									data-bs-target={'#' + navPill.nameId}
									type="button"
									role="tab"
									aria-controls={navPill.nameId}
									aria-selected={navPill.cultureCode === props.selectedLanguage}
									onClick={(e: any) =>
										props.handleSelectLanguage(e, navPill.cultureCode)
									}
								>
									{navPill.name}
								</button>
								{props.translatable ? (
									<RenderIf entitlements={[ENTITLEMENTS.TRANSLATION_SERVICE]}>
										<>
											{navPill.cultureCode === props.selectedLanguage &&
												renderGlobe(i)}
										</>
									</RenderIf>
								) : (
									<></>
								)}
							</React.Fragment>
						))}
					</div>
				</nav>
			</RenderIf>
			<div className="tab-content" id="nav-tabContent">
				{props.navigationPills.map((navPill, i) => (
					<div
						key={i}
						className={
							navPill.cultureCode === props.selectedLanguage
								? 'tab-pane fade show active'
								: 'tab-pane fade show'
						}
						id={navPill.nameId}
						role="tabpanel"
						aria-labelledby={navPill.nameId + '-tab'}
					>
						{navPill.content}
					</div>
				))}
			</div>
		</>
	);
};

export default LanguageNavigationPills;
