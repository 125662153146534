import * as postMenuSearchV1 from 'api/menu/PostMenuSearchV1';
import Image from 'classes/Menu/Search/Image';
import RecipePrepareTime from 'classes/Menu/Search/RecipePrepareTime';
import RecipeStatus from 'classes/Menu/Search/RecipeStatus';

export default class Recipe {
	public id: string = '';
	public title: string = '';
	public author: string = '';
	public status: RecipeStatus | null = null;
	public image: Image | null = null;
	public calories: number = 0;
	public price: number = 0;
	public prepareTime: RecipePrepareTime | null = null;

	public constructor(input?: postMenuSearchV1.Recipe) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public getLink() {
		return '/recipe/detail/' + this.id;
	}

	public mapFromApi(input: postMenuSearchV1.Recipe): void {
		this.id = input.id;
		this.title = input.title ?? '';
		this.author = input.lastEditor ?? '';
		this.calories = input.calories ?? 0;
		this.price = input.price ?? 0;

		if (input.image) {
			this.image = new Image();
			this.image.mapFromApi(input.image);
		} else {
			this.image = null;
		}

		if (input.status) {
			this.status = new RecipeStatus();
			this.status.mapFromApi(input.status);
		} else {
			this.status = null;
		}

		if (input.prepareTime) {
			this.prepareTime = new RecipePrepareTime();
			this.prepareTime.mapFromApi(input.prepareTime);
		} else {
			this.prepareTime = null;
		}
	}
}
