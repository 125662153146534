import RecipeValidationComponent from 'components/desktop/Recipe/Validation/RecipeValidationComponent';

const RecipeValidationContainer = () => {
	return (
		<main className="container">
			<RecipeValidationComponent />
		</main>
	);
};

export default RecipeValidationContainer;
