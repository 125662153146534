import { useTranslation } from 'react-i18next';

import RenderPasswordInformation from 'components/desktop/User/RenderPasswordInformation';
import CardTransparent from 'components/desktop/_general/Card/CardTransparent';
import ValidatedInput from 'components/desktop/_general/Input/ValidatedInput/ValidatedInput';
import isPasswordValid from 'functions/validation/isPasswordValid';

interface Props {
	handleKeyboardEvent: (e: React.KeyboardEvent<HTMLDivElement>) => void;
	passwordCurrent: string | null;
	passwordNew1: string | null;
	passwordNew2: string | null;
	handleChangePasswordCurrent: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangePasswordNew1: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangePasswordNew2: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChangePassword = (props: Props) => {
	const { t } = useTranslation();

	return (
		<CardTransparent
			title={t('user:CHANGE_PASSWORD')}
			content={
				<>
					<div style={{ margin: '12px 0px' }}>
						<RenderPasswordInformation />
					</div>
					<ValidatedInput
						id="passwordCurrent"
						label={t('user:CURRENT_PASSWORD')}
						value={props.passwordCurrent ?? ''}
						type="password"
						isValid={isPasswordValid}
						onKeyDown={props.handleKeyboardEvent}
						handleValueChange={props.handleChangePasswordCurrent}
						errorKey="NewPassword"
					/>
					<ValidatedInput
						id="passwordNew1"
						label={t('user:NEW_PASSWORD')}
						value={props.passwordNew1 ?? ''}
						type="password"
						isValid={isPasswordValid}
						onKeyDown={props.handleKeyboardEvent}
						handleValueChange={props.handleChangePasswordNew1}
						errorKey="NewPassword"
					/>
					<ValidatedInput
						id="passwordNew2"
						label={t('user:NEW_PASSWORD_VERIFY')}
						value={props.passwordNew2 ?? ''}
						type="password"
						isValid={isPasswordValid}
						onKeyDown={props.handleKeyboardEvent}
						handleValueChange={props.handleChangePasswordNew2}
						errorKey="NewPassword"
					/>
				</>
			}
		/>
	);
};

export default ChangePassword;
