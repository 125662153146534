import { useState } from 'react';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import SelectedItems from 'components/desktop/_general/Input/TreeInput/SelectedItems';
import { Tree } from 'components/desktop/_general/Input/TreeInput/Tree';
import TreeComponent from 'components/desktop/_general/Input/TreeInput/Tree';
import 'components/desktop/_general/Input/TreeInput/TreeInput.scss';
import { createOnFocus } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { createOnBlur } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { createRemoveFromSelected } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';
import { handleChangeSearchTerm } from 'components/desktop/_general/Input/TreeInput/TreeInputHandlers';

interface IProps<T extends Tree<T>> {
	input: T[];
	setSelected: (input: T[]) => void;
	selected: T[];
	disabled?: boolean;
	errorKey?: string;
	label: string;
	noObjectsFoundText?: string;
}

const TreeInput = <T extends Tree<T>>(props: IProps<T>) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [showSuggested, setShowSuggested] = useState<boolean>(false);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<div error-key={props.errorKey} style={{ marginTop: '0px' }}>
				<SelectedItems
					selected={props.selected}
					removeFromSelected={createRemoveFromSelected(props.selected, props.setSelected)}
					readOnly={props.disabled}
				/>
				<RenderIf condition={!props.disabled}>
					<div
						className="input-group"
						style={{ display: 'flex', marginTop: '0px' }}
						tabIndex={1}
						onFocus={createOnFocus(setShowSuggested)}
						onBlur={createOnBlur(setShowSuggested)}
					>
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={(e) => handleChangeSearchTerm(e, setSearchTerm)}
						/>
						<div
							className="tree-input-box"
							style={{ display: showSuggested ? 'block' : 'none' }}
						>
							<TreeComponent<T>
								input={props.input}
								setSelected={props.setSelected}
								selected={props.selected}
								searchTerm={searchTerm}
								noObjectsFoundText={props.noObjectsFoundText}
							/>
						</div>
					</div>
				</RenderIf>
			</div>
		</>
	);
};

export default TreeInput;
