import * as apiPostSearch from 'api/accessory/PostAccessorySearchV1';

export default class TagCategory {
	public id: string = '';
	public name: string = '';

	public constructor(input?: apiPostSearch.TagCategory | null) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.TagCategory | null): void {
		if (input) {
			this.id = input.id;
			this.name = input.name ?? '';
		}
	}
}
