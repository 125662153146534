export const setReduxAllowedUiLanguages = (allowedUiLanguages: string[]) => {
	return {
		type: 'allowedUiLanguages/SET',
		payload: allowedUiLanguages,
	};
};

export type AllowedUiLanguagesAction = {
	type: string;
	payload: string[];
};

export const allowedUiLanguagesReducer = (
	state: string[] = ['en-US'],
	action: AllowedUiLanguagesAction
): string[] => {
	switch (action.type) {
		case 'allowedUiLanguages/SET':
			return action.payload;
		default:
			return state;
	}
};
