import { useEffect, useState } from 'react';

/**
 * A hook that gives you a calculated state if your handler is running or if it has stopped
 * running. You can use this hook e.g. to bind your UI to a loading icon when a process is
 * running.
 * @param handler An asynchronous function to run (inner handler)
 * @returns a tuple [bool, handler], where bool indicates whether the handler is active/running
 * or not, and the handler is a function that you can invoke when you need to pass in the 'inner handler'
 */
export const useIsHandlerLoaded = <T>(
	handler?: (...args: any[]) => Promise<T>
): [boolean, () => Promise<T>] => {
	const [isLoaded, setIsLoaded] = useState(true);

	const g = async (...args: any[]) => {
		if (!handler) return;
		setIsLoaded(false);
		return handler(...args)
			.then((response) => response)
			.finally(() => setIsLoaded(true));
	};
	return [isLoaded, g as () => Promise<T>];
};

export const useIsHandlerLoading = <T>(
	handler?: (...args: any[]) => Promise<T>
): [boolean, () => Promise<T>] => {
	const [isLoaded, g] = useIsHandlerLoaded(handler);
	return [!isLoaded, g];
};

export const useMemoizeUntilTrue = (f: (...args: any[]) => boolean, ...args: any[]) => {
	const [state, setState] = useState(false);

	useEffect(() => {
		if (state) return;
		const result = f(...args);
		if (result) setState(true);
	}, args);

	return state;
};
