import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<Language[]>> {
	const url: string = '/backend-service/Language';
	return axios.get(url).toOptionalMapped((x) => x.data.languages);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	languages: Language[];
};

export type Language = {
	id: string;
	sortOrder: number;
	name: string;
	cultureCode: string;
	hasArticleBeforeIngredientName: boolean;
};
