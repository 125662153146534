import * as apiSW from 'api/recipe/GetRecipeStartingWithV1';
import * as api from 'api/recipe/PostRecipeSearchV1';
import Recipe from 'classes/Recipe/Search/Recipe';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class RecipeList {
	public all: Recipe[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		response.hasValue() && this.mapFromApiSW(response.get());
	}

	public async callApi(input: api.Request) {
		return await api.callApi(input);
	}

	public mapFromApi(input: api.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const recipe: Recipe = new Recipe(i);
			this.all.push(recipe);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseRecipe[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
