import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import { ResponseNutriScoreLabel } from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import Search from 'classes/Recipe/Search/Search';
import SliderWithId from 'classes/_general/Search/SliderWithId/SliderWithId';
import Co2Select from 'components/desktop/_general/Co2Select/Co2Select';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ExclusionLogicMultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/ExclusionLogicMultiSearchSelect';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import NutriScoreDiagramSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreDiagramSelect';
import RangeDoubleLabel from 'components/desktop/_general/RangeDoubleLabel/RangeDoubleLabel';
import StatusSelect from 'components/desktop/_general/StatusSelect/StatusSelect';
import ENTITLEMENTS from 'enums/entitlements';
import { ValueScope } from 'enums/valueScope';
import clone from 'functions/clone';
import { getValueScope } from 'functions/getValueScope';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

interface IProps {
	search: Search;
	setSearch: (input: Search) => void;
	handleChangeUrl: () => void;
	handleReset: () => void;
	renderRanges: boolean;
	disabled: boolean;
}

const RecipeAdvancedSearch = (props: IProps) => {
	const { t } = useTranslation();

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);
	const valueScope: ValueScope = reduxUserSetting['Recipe.List.ValueScope'] as ValueScope;

	const handleChangeTags = (items: ISelectItem[]) => {
		props.search.selectTag.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCategories = (items: ISelectItem[]) => {
		props.search.selectCategory.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeAllergens = (items: ISelectItem[]) => {
		props.search.selectAllergen.mapFromISelectItem(items);
		props.handleChangeUrl();
	};

	const handleChangeSeasons = (items: ISelectItem[]) => {
		props.search.selectSeason.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeIngredients = (items: IExclusionLogicSelectItem[]) => {
		props.search.selectIngredient.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeAccessories = (items: IExclusionLogicSelectItem[]) => {
		props.search.selectAccessory.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxideLabels = (items: ResponseCarbonDioxideLabel[]) => {
		props.search.selectCo2Label.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeNutriScoreLabels = (items: ResponseNutriScoreLabel[]) => {
		props.search.selectNutriScoreLabel.pushToSaved(items);
		props.handleChangeUrl();
	};

	const getSavedTags = (): ISelectItem[] => {
		return props.search.selectTag.saved;
	};

	const getSavedCategories = (): ISelectItem[] => {
		return props.search.selectCategory.saved;
	};

	const getSavedSeasons = (): ISelectItem[] => {
		return props.search.selectSeason.saved;
	};

	const getSavedIngredients = (): IExclusionLogicSelectItem[] => {
		return props.search.selectIngredient.saved;
	};

	const getSavedAccessories = (): IExclusionLogicSelectItem[] => {
		return props.search.selectAccessory.saved;
	};

	const getSavedAllergens = (): ISelectItem[] => {
		return props.search.selectAllergen.saved;
	};

	const getSuggestedTags = (): ISelectItem[] => {
		return props.search.selectTag.suggested.filtered;
	};

	const getSuggestedCategories = (): ISelectItem[] => {
		return props.search.selectCategory.suggested.filtered;
	};

	const getSuggestedAllergens = (): ISelectItem[] => {
		return props.search.selectAllergen.suggested.filtered;
	};

	const getSuggestedSeasons = (): ISelectItem[] => {
		return props.search.selectSeason.suggested.filtered;
	};

	const getSuggestedIngredients = (): ISelectItem[] => {
		return props.search.selectIngredient.suggested.filtered;
	};

	const getSuggestedAccessories = (): ISelectItem[] => {
		return props.search.selectAccessory.suggested.filtered;
	};

	const setSuggestedTags = async (input: string): Promise<void> => {
		await props.search.selectTag.search(input);
		props.setSearch(clone(props.search));
	};

	const setSuggestedCategories = async (input: string): Promise<void> => {
		await props.search.selectCategory.search(input);
		props.setSearch(clone(props.search));
	};

	const setSuggestedAllergens = async (input: string): Promise<void> => {
		await props.search.selectAllergen.search(input);
		props.setSearch(clone(props.search));
	};

	const setSuggestedSeasons = async (input: string): Promise<void> => {
		await props.search.selectSeason.search(input);
		props.setSearch(clone(props.search));
	};

	const setSuggestedIngredients = async (input: string): Promise<void> => {
		await props.search.selectIngredient.search(input);
		props.setSearch(clone(props.search));
	};

	const setSuggestedAccessories = async (input: string): Promise<void> => {
		await props.search.selectAccessory.search(input);
		props.setSearch(clone(props.search));
	};

	const handleChangeCost = (left: number, right: number, _id?: string): void => {
		if (!props.search.sliderCost.hasSliderChanges(left, right)) return;
		props.search.sliderCost.right = right;
		props.search.sliderCost.left = left;
		props.handleChangeUrl();
	};

	const handleChangeCalories = (left: number, right: number, _id?: string): void => {
		if (!props.search.sliderCalories.hasSliderChanges(left, right)) return;
		props.search.sliderCalories.right = right;
		props.search.sliderCalories.left = left;
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxide = (left: number, right: number, _id?: string): void => {
		if (!props.search.sliderCo2.hasSliderChanges(left, right)) return;
		props.search.sliderCo2.right = right;
		props.search.sliderCo2.left = left;
		props.handleChangeUrl();
	};

	const handleChangeStatus = (): void => {
		props.handleChangeUrl();
	};

	const handleChangeNutrients = (left: number, right: number, id?: string): void => {
		if (id) {
			const sliderWithId: SliderWithId | undefined =
				props.search.sliderNutrient.getSlider(id);
			if (sliderWithId) {
				if (!sliderWithId.hasSliderChanges(left, right)) return;
				sliderWithId.right = right;
				sliderWithId.left = left;
				props.handleChangeUrl();
			}
		}
	};

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<ExclusionLogicMultiSearchSelect
					className="mb-2"
					label={t('_general:INGREDIENTS')}
					suggestedItems={getSuggestedIngredients()}
					displaySavedItems="top"
					disabled={props.disabled}
					savedItems={getSavedIngredients()}
					setSavedItems={handleChangeIngredients}
					handleChangeSearchTerm={setSuggestedIngredients}
					triggerSearchLetterAmount={0}
					errorKey="Ingredients"
				/>
				<ExclusionLogicMultiSearchSelect
					className="mb-2"
					label={t('_general:ACCESSORIES')}
					suggestedItems={getSuggestedAccessories()}
					displaySavedItems="top"
					disabled={props.disabled}
					savedItems={getSavedAccessories()}
					setSavedItems={handleChangeAccessories}
					handleChangeSearchTerm={setSuggestedAccessories}
					triggerSearchLetterAmount={0}
					errorKey="Accessories"
				/>
				<MultiSearchSelect
					className="mb-2"
					label={t('_general:ALLERGENS')}
					suggestedItems={getSuggestedAllergens()}
					displaySavedItems="top"
					disabled={props.disabled}
					savedItems={getSavedAllergens()}
					setSavedItems={handleChangeAllergens}
					handleChangeSearchTerm={setSuggestedAllergens}
					triggerSearchLetterAmount={0}
					errorKey="Allergens"
				/>
				<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
					<MultiSearchSelect
						className="mb-2"
						label={t('_general:SEASONS')}
						suggestedItems={getSuggestedSeasons()}
						displaySavedItems="top"
						disabled={props.disabled}
						savedItems={getSavedSeasons()}
						setSavedItems={handleChangeSeasons}
						handleChangeSearchTerm={setSuggestedSeasons}
						triggerSearchLetterAmount={0}
						errorKey="Seasons"
					/>
				</RenderIf>
				<MultiSearchSelect
					className="mb-2"
					label={t('_general:TAGS')}
					suggestedItems={getSuggestedTags()}
					displaySavedItems="top"
					disabled={props.disabled}
					savedItems={getSavedTags()}
					setSavedItems={handleChangeTags}
					handleChangeSearchTerm={setSuggestedTags}
					triggerSearchLetterAmount={0}
					errorKey="Tags"
				/>
				<MultiSearchSelect
					className="mb-2"
					label={t('_general:CATEGORIES')}
					suggestedItems={getSuggestedCategories()}
					displaySavedItems="top"
					disabled={props.disabled}
					savedItems={getSavedCategories()}
					setSavedItems={handleChangeCategories}
					handleChangeSearchTerm={setSuggestedCategories}
					triggerSearchLetterAmount={0}
					errorKey="Categories"
				/>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		if (props.renderRanges) {
			return (
				<>
					<label className="mb-2">
						{t('_general:NUTRITION_FACTS') + getValueScope(valueScope)}
					</label>
					<RangeDoubleLabel
						className="mb-2"
						label={`${t('_general:CALORIES')} (kcal)`}
						min={props.search.sliderCalories.getMinRoundDown()}
						max={props.search.sliderCalories.getMaxRoundUp()}
						minArea={props.search.sliderCalories.minArea ?? 0}
						maxArea={props.search.sliderCalories.maxArea ?? 0}
						left={props.search.sliderCalories.left ?? undefined}
						right={props.search.sliderCalories.right ?? undefined}
						handleChange={handleChangeCalories}
					/>

					{props.search.sliderNutrient.all.map((e: SliderWithId, i: number) => {
						return (
							<div key={i}>
								<RangeDoubleLabel
									id={e.id}
									className="mb-2"
									label={`${e.name} (${e.unit})`}
									min={e.getMinRoundDown()}
									max={e.getMaxRoundUp()}
									minArea={e.minArea ?? 0}
									maxArea={e.maxArea ?? 0}
									left={e.left ?? undefined}
									right={e.right ?? undefined}
									handleChange={handleChangeNutrients}
								/>
							</div>
						);
					})}
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<label className="mb-2 mt-2">
							{t('_general:COSTS') + getValueScope(valueScope)}
						</label>
						<RangeDoubleLabel
							className="mb-2"
							label={`${t('_general:COSTS')} (${reduxCurrency})`}
							min={props.search.sliderCost.getMinRoundDown()}
							max={props.search.sliderCost.getMaxRoundUp()}
							minArea={props.search.sliderCost.minArea ?? 0}
							maxArea={props.search.sliderCost.maxArea ?? 0}
							left={props.search.sliderCost.left ?? undefined}
							right={props.search.sliderCost.right ?? undefined}
							handleChange={handleChangeCost}
						/>
					</RenderIf>
					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<label className="mb-2 mt-2">
							{t('_general:CARBON_DIOXIDE') + getValueScope(valueScope)}
						</label>
						<RangeDoubleLabel
							className="mb-2"
							label={t('_general:CARBON_DIOXIDE') + ' (g)'}
							min={props.search.sliderCo2.getMinRoundDown()}
							max={props.search.sliderCo2.getMaxRoundUp()}
							minArea={props.search.sliderCo2.minArea ?? 0}
							maxArea={props.search.sliderCo2.maxArea ?? 0}
							left={props.search.sliderCo2.left ?? undefined}
							right={props.search.sliderCo2.right ?? undefined}
							handleChange={handleChangeCarbonDioxide}
						/>
					</RenderIf>
				</>
			);
		}
		return <></>;
	};

	const renderCol3 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<div>
					<StatusSelect
						toggleList={props.search.toggleListStatus}
						handleChange={handleChangeStatus}
					/>
				</div>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<div>
						<Co2Select
							carbonDioxideLabels={props.search.selectCo2Label.suggested.all}
							savedItems={props.search.selectCo2Label.saved}
							setSavedItems={handleChangeCarbonDioxideLabels}
						/>
					</div>
				</RenderIf>
				<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
					<div>
						<NutriScoreDiagramSelect
							nutriScoreLabels={props.search.selectNutriScoreLabel.suggested.all}
							savedItems={props.search.selectNutriScoreLabel.saved}
							setSavedItems={handleChangeNutriScoreLabels}
						/>
					</div>
				</RenderIf>
			</div>
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-lg-4">{renderCol1()}</div>
				<div className="col-lg-6">{renderCol2()}</div>
				<div className="col-lg-2">{renderCol3()}</div>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{ textAlign: 'end' }}>
					<button className="btn btn-primary" onClick={props.handleReset}>
						{t('_general:RESET')}
					</button>
				</div>
			</div>
		</>
	);
};

export default RecipeAdvancedSearch;
