import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<void>> {
	const url: string = '/backend-service/NutriScoreNutrientPoints';
	return axios.post(url, requestBody).toOptionalVoid();
}

export type Request = {
	nutrientId: string; // required
	isPositive: boolean;
	isProtein: boolean;
	pointType: 'StandardFood' | 'Cheese' | 'Beverage' | 'AddedFat' | 'Water';
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};
