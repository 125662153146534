interface IProps {
	width?: string;
	height?: string;
}

const LoadingSpinner = (props: IProps) => {
	return (
		<div className="text-center">
			<div
				className="spinner-border"
				style={{ width: props.width ?? '5em', height: props.height ?? '5em' }}
				role="status"
			>
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default LoadingSpinner;
