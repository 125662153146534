import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';

import * as apiAiFunctions from 'api/aiFunctions/IngredientUnitWeightV1';
import * as patchIngredientUnitWeight from 'api/ingredient/PatchIngredientAddUnitWeightV1';
import 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip.scss';
import {
	SegmentIngredientType,
	Translation,
} from 'components/desktop/Recipe/RecipeTabs/recipeComputations';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import Tooltip from 'components/desktop/_general/Tooltip/Tooltip';
import ENTITLEMENTS from 'enums/entitlements';
import { IngredientType } from 'enums/ingredientType';
import { MatchStatus } from 'enums/matchStatus';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions, isUserWriter } from 'functions/tokenFunctions';
import { RefreshType } from 'hooks/useRefresh';
import { RootState } from 'reducers/rootReducer';
import { ActionData } from 'types/Recipe/ActionData';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';

const delayOption = { show: 650, hide: 400 };

const getIngredientClassName = (ingredient: SegmentIngredient): string => {
	if (ingredient.status.ingredient === MatchStatus.PartiallyRecognized) {
		return 'ingredient-partially-recognized';
	}

	if (ingredient.status.ingredient === MatchStatus.Recognized) {
		if (ingredient.errorMessages.length > 0) {
			return 'ingredient-recognized-with-errors';
		}

		return 'ingredient-recognized';
	}

	if (ingredient.status.ingredient === MatchStatus.Unrecognized) {
		return 'ingredient-unrecognized';
	}

	return 'ingredient-recognized-legacy';
};

const getAccessoryClassName = (ingredient: SegmentIngredient): string => {
	if (ingredient.errorMessages.length !== 0) {
		return 'accessory-partially-recognized';
	}
	if (ingredient.status.ingredient === MatchStatus.Recognized) {
		return 'accessory-recognized';
	}

	if (ingredient.status.ingredient === MatchStatus.Unrecognized) {
		return 'accessory-unrecognized';
	}
	return 'accessory-recognized-legacy';
};

export const getIngredientOrAccessoryClassName = (ingredient: SegmentIngredient): string => {
	if (ingredient.type === IngredientType.Accessory) {
		return getAccessoryClassName(ingredient);
	}

	return getIngredientClassName(ingredient);
};

export const getIngredientTooltipText = (t: Translation, i: SegmentIngredientType): string => {
	if (i.errorMessages.length === 0) {
		return t('recipe:' + i.status.ingredient.toUpperCase() + '_' + i.type.toUpperCase());
	} else {
		return i.errorMessages.join('\n');
	}
};

const IngredientDisplay = (props: {
	ingredient: SegmentIngredient | null;
	tooltip: string;
	refresh: RefreshType;
	setElement: (input: JSX.Element) => void;
}): JSX.Element => {
	const { t } = useTranslation();

	const [actionableIngredient, setActionableIngredient] = useState<SegmentIngredient | null>(
		null
	);
	const [actionData, setActionData] = useState<ActionData>({});
	const [left, setLeft] = useState<number>(0);
	const [top, setTop] = useState<number>(0);

	const readOnly: boolean = !isUserWriter();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const ref = useRef(null);

	const handleClickOutside = () => {
		setActionableIngredient(null);
	};

	useOnClickOutside(ref, handleClickOutside);

	const handleIngredientActionClicked = async (e: any, ingredient: SegmentIngredient) => {
		e.stopPropagation();
		setActionData({});
		if (actionableIngredient == ingredient) {
			setActionableIngredient(null);
		} else {
			setActionableIngredient(ingredient);
			const actionDataCpy = {} as ActionData;
			for (const action of ingredient.errorActions) {
				switch (action) {
					case 'IngredientUnitWeight':
						if (ingredient.ingredientId && ingredient.unitId) {
							const response = await apiAiFunctions.callApi(
								ingredient.ingredientId,
								ingredient.unitId,
								reduxCultureCode
							);
							response.do((x) => {
								actionDataCpy.unitWeightSuggestion = x;
							});
						}
						break;
					default:
						break;
				}
			}
			setActionData(actionDataCpy);
		}
	};

	const handleChangeUnitWeightSuggestion = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const actionDataCpy = { ...actionData };
		actionDataCpy.unitWeightSuggestion = parseFloat(ev.target.value);
		setActionData(actionDataCpy);
	};

	const applyUnitWeightToIngredient = async (ingredient: SegmentIngredient, weight: number) => {
		// How can this have an effect? We call the API and don't use the result. Does it eventually change something in
		// the remote database?

		const request = {
			ingredientId: ingredient.ingredientId!,
			unitId: ingredient.unitId!,
			weight,
		};

		props.refresh(async () => {
			await patchIngredientUnitWeight.callApi(request);
			setActionableIngredient(null);
		});
	};

	if (!props.ingredient) return <div />;

	const element: JSX.Element = (
		<div style={{ position: 'absolute', left: `${left}px`, top: `${top}px`, zIndex: 3000000 }}>
			<div className="air-action-popup" onClick={(e) => e.stopPropagation()} ref={ref}>
				{actionData.unitWeightSuggestion === undefined ? (
					<div className="result-loading spinner-border"></div>
				) : (
					<>
						<span>{t('recipe:UNITWEIGHT_SUGGESTION')}</span>
						<div>
							<input
								type="number"
								defaultValue={actionData.unitWeightSuggestion}
								onChange={handleChangeUnitWeightSuggestion}
							></input>
							<span>
								{props.ingredient.isFluid
									? t('measurments:MILLILITERS')
									: t('measurments:GRAMM')}
							</span>
							<button
								className="btn btn-primary"
								onClick={() =>
									applyUnitWeightToIngredient(
										props.ingredient!,
										actionData.unitWeightSuggestion!
									)
								}
							>
								{t('_general:BTN_SAVE')}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);

	useEffect(() => {
		if (actionableIngredient) {
			props.setElement(element);
		} else {
			props.setElement(<></>);
		}
	}, [actionableIngredient, actionData.unitWeightSuggestion]);

	return (
		<Tooltip
			placement={'bottom-start'}
			delay={delayOption}
			content={props.tooltip}
			show={actionableIngredient ? false : undefined}
		>
			<div style={{ display: 'flex' }}>
				<span style={{ flexGrow: 1, alignSelf: 'center' }}>
					{props.ingredient.ingredient}
				</span>
				<RenderIf
					entitlements={[ENTITLEMENTS.UNIT_WEIGHT]}
					condition={!readOnly && !!props.ingredient.errorActions.length}
				>
					<div style={{ height: '48px', paddingTop: '4px', paddingLeft: '4px' }}>
						<FontAwesomeIcon
							style={{ zIndex: '1000' }}
							size="lg"
							className="ai-action-icon"
							icon={faMicrochipAi as IconProp}
							onClick={(e: any) => {
								if (readOnly) return;
								handleIngredientActionClicked(e, props.ingredient!);
								if (e.target) {
									const scrollTop: number = document.documentElement.scrollTop;
									const scrollLeft: number = document.documentElement.scrollLeft;
									setTop(e.target.getBoundingClientRect().top + scrollTop);
									setLeft(
										e.target.getBoundingClientRect().left + scrollLeft - 112
									);
								}
							}}
						/>
					</div>
				</RenderIf>
			</div>
		</Tooltip>
	);
};

export const ToolTipCell = (props: {
	ingredient: SegmentIngredientType;
	setElement: (input: JSX.Element) => void;
	lastCell: boolean;
	refresh: RefreshType;
}) => {
	const { t } = useTranslation();

	const handleIngredientLinkClicked = (ingredient: SegmentIngredientType) => {
		if (ingredient.type === 'Ingredient' && ingredient.ingredientId) {
			window.open(`/ingredient/detail/${ingredient.ingredientId}`, '_blank');
		} else if (ingredient.type === 'Accessory' && ingredient.ingredientId) {
			window.open(`/accessory/detail/${ingredient.ingredientId}`, '_blank');
		}
	};

	const tooltip = getIngredientTooltipText(t, props.ingredient);
	const className = getIngredientOrAccessoryClassName(props.ingredient);
	const lastCellClassName = props.lastCell ? 'ingredient-tooltip-last-child' : '';
	const linkClassName = props.ingredient.ingredientId ? 'ingredient-tooltip-link' : '';
	if (arePermissionsInUserPermissions([PERMISSIONS.READINGREDIENT])) {
		return (
			<div
				className={`${className} ingredient-tooltip ${lastCellClassName} ${linkClassName}`}
				onClick={() => handleIngredientLinkClicked(props.ingredient)}
			>
				<IngredientDisplay
					refresh={props.refresh}
					ingredient={props.ingredient}
					tooltip={tooltip}
					setElement={props.setElement}
				/>
			</div>
		);
	} else {
		return (
			<div
				className={`${className} ingredient-tooltip ${lastCellClassName} ${linkClassName}`}
			>
				<IngredientDisplay
					refresh={props.refresh}
					ingredient={props.ingredient}
					tooltip={tooltip}
					setElement={props.setElement}
				/>
			</div>
		);
	}
};
