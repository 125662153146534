import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/desktop/_general/ListItem/ListItem.scss';

interface IProps {
	text: string;
	title?: string | null;
	iconSvgUrl?: string | null;
	textColor?: string;
	backgroundColor?: string;
	handleDelete?: () => void;
}

const ListItem = (props: IProps) => {
	const getTitle = () => {
		if (props.title) {
			return props.title;
		}
	};

	return (
		<div className="list-item-component">
			<div
				className="d-flex flex-row align-items-center"
				style={props.backgroundColor ? { backgroundColor: '#efefef' } : {}}
				title={getTitle()}
			>
				{props.iconSvgUrl && (
					<img
						src={props.iconSvgUrl ?? ''}
						style={{
							width: '24px',
							height: '24px',
							marginRight: '13px',
						}}
					/>
				)}
				<div className="flex-grow-1" style={{ color: props.textColor }}>
					{props.title && (
						<div className="list-item-component-category">{props.title}</div>
					)}
					<div className="list-item-component-text">{props.text}</div>
				</div>
				{props.handleDelete && (
					<FontAwesomeIcon
						className="list-item-component-svg-small"
						icon={faTrashCan}
						onClick={props.handleDelete}
					/>
				)}
			</div>
		</div>
	);
};

export default ListItem;
