import * as apiDelete from 'api/menuPlanner/DeleteMenuPlannerIdV1';
import * as apiGet from 'api/menuPlanner/GetMenuPlannerIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import * as apiPostAutoFill from 'api/menuPlanner/PostMenuPlannerGetAutoFillV1';
import * as apiPost from 'api/menuPlanner/PostMenuPlannerV1';
import * as apiPut from 'api/menuPlanner/PutMenuPlannerIdV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import CarbonDioxideLabel from 'classes/MenuPlan/Detail/CarbonDioxideLabel';
import ColumnList from 'classes/MenuPlan/Detail/ColumnList';
import ImageList from 'classes/MenuPlan/Detail/ImageList';
import Item from 'classes/MenuPlan/Detail/Item';
import Row from 'classes/MenuPlan/Detail/Row';
import RowList from 'classes/MenuPlan/Detail/RowList';
import SeasonList from 'classes/MenuPlan/Detail/SeasonList';
import Status from 'classes/MenuPlan/Detail/Status';
import { ConstraintStatus } from 'enums/ConstraintStatus';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { Optional } from 'functions/promiseExtensions';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class MenuPlan {
	public id: string = '';
	public systemStatus: SystemStatus = 'New';
	public status: Status = new Status();
	public caloriesLimit: number | null = null;
	public priceLimit: number | null = null;
	public isPriceConstraintMet: ConstraintStatus = ConstraintStatus.FullyMet;
	public isCaloriesConstraintMet: ConstraintStatus = ConstraintStatus.FullyMet;
	public carbonDioxideValue: number | null = null;
	public startDate: Date = new Date();
	public endDate: Date = this.getDefaultEndDate();
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public title: LocalizedTranslation<string> | null = null;
	public rowList: RowList = new RowList();
	public columnList: ColumnList = new ColumnList();
	public seasonList: SeasonList = new SeasonList();

	public constructor(input?: apiGet.ResponseData) {
		if (input) this.mapFromApiGet(input);
	}

	private getDefaultEndDate(): Date {
		const date: Date = new Date();
		date.setDate(this.startDate.getDate() + 6);
		return date;
	}

	public async initialize(input?: string) {
		if (input) await this.callApiGet(input);
		await this.seasonList.initialize();
		await this.rowList.initialize();
		await this.status.initialize();
		await this.recalculateColumnsAndRows();
		this.recalculateConstraints();
	}

	public async saveToApi(): Promise<string | null> {
		if (this.id) {
			await this.callApiPut();
			return null;
		} else {
			const id = await this.callApiPost();
			return id.toNullable();
		}
	}

	public createCopy(): void {
		this.id = '';
		if (this.title) {
			for (const [key, value] of Object.entries(this.title)) {
				this.title[key] = value + ' - Copy';
			}
		}
	}

	public removeRow(row: Row): void {
		this.rowList.removeRow(row);
	}

	public addRow(): void {
		this.rowList.addRow();
	}

	public recalculateConstraints(): void {
		for (const row of this.rowList.all) {
			row.recalculateConstraints(this.priceLimit ?? 0, this.caloriesLimit ?? 0);
		}
		for (const column of this.columnList.all) {
			column.recalculateConstraints(
				this.getPriceLimitPerDayNumber(),
				this.getCaloriesLimitPerDayNumber()
			);
		}
		this.recalculatePriceConstraint();
		this.recalculateCaloriesConstraint();
	}

	public setCaloriesLimit(caloriesLimit: number) {
		this.caloriesLimit = caloriesLimit;
		this.recalculateConstraints();
	}

	public setPriceLimit(priceLimit: number) {
		this.priceLimit = priceLimit;
		this.recalculateConstraints();
	}

	private recalculatePriceConstraint(): void {
		const constraints: boolean[] = this.rowList.all.map((e) => e.isPriceConstraintMet);
		this.isPriceConstraintMet = this.getConstraintsFromBooleanArray(constraints);
	}

	private recalculateCaloriesConstraint(): void {
		const constraints: boolean[] = this.rowList.all.map((e) => e.isCaloriesConstraintMet);
		this.isCaloriesConstraintMet = this.getConstraintsFromBooleanArray(constraints);
	}

	private getConstraintsFromBooleanArray(array: boolean[]): ConstraintStatus {
		if (array.includes(true) && array.includes(false)) {
			return ConstraintStatus.PartiallyMet;
		}
		if (array.includes(true)) {
			return ConstraintStatus.FullyMet;
		}
		return ConstraintStatus.NotMet;
	}

	public getPricePerPerson(): number {
		let output: number = 0;
		for (const i of this.rowList.all) {
			output = output + (i.priceSum ?? 0);
		}
		return output;
	}

	public getPriceTotal(): number {
		let output: number = 0;
		for (const i of this.rowList.all) {
			output = output + (i.priceSumTotal ?? 0);
		}
		return output;
	}

	public getCountOfEmptySpotsInRow(rowIndex: number): number {
		const amountOfDays: number = this.getDateArray().length;
		const amountOfNonEmptyDatesFromRow: number = this.rowList.all[rowIndex].itemList.all.length;
		return amountOfDays - amountOfNonEmptyDatesFromRow;
	}

	public async checkAutoFillOfRow(rowIndex: number, search: Search.Type) {
		const countOfNonEmptyDatesInRow: number = this.rowList.all[rowIndex].itemList.all.length;
		const shouldHave: number = this.getCountOfEmptySpotsInRow(rowIndex);
		const check: Check = {
			shouldHave: shouldHave,
			have: 0,
			okay: false,
		};
		const response = await apiPostAutoFill.callApi(
			Search.mapToApiAutoFill({
				...search,
				pinnedItems: this.rowList.all[rowIndex].itemList.all,
				numberOfItems: this.getDurationDays() ?? 7,
				priceLimit: this.priceLimit,
				caloriesLimit: this.caloriesLimit,
			})
		);
		if (response.hasValue()) check.have = response.get().length - countOfNonEmptyDatesInRow;
		if (check.shouldHave <= check.have) check.okay = true;
		return check;
	}

	public getCaloriesPerPerson(): number {
		let output: number = 0;
		for (const i of this.rowList.all) {
			output = output + (i.caloriesSum ?? 0);
		}
		return output;
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiGet(id?: string): Promise<void> {
		const response = await apiGet.apiCall(id ?? this.id);
		response.hasValue() && this.mapFromApiGet(response.get());
	}

	public async callApiPut(): Promise<Optional<apiGet.ResponseData>> {
		this.removeUnusedItems();
		return await apiPut.apiCall(this.id, this.mapToApi());
	}

	public async callApiPost(): Promise<Optional<string>> {
		this.removeUnusedItems();
		return await apiPost.apiCall(this.mapToApi());
	}

	public async recalculateColumnsAndRows(): Promise<void> {
		await this.callApiPostCalculate('columns', false);
		await this.callApiPostCalculate('columns', true);
		await this.callApiPostCalculate('rows', false);
		await this.callApiPostCalculate('rows', true);
		this.recalculateConstraints();
	}

	public async callApiPostCalculate(key: 'columns' | 'rows', total: boolean): Promise<void> {
		this.removeUnusedItems();
		const response = await apiPostCalculate.callApi(this.mapToApiCalculate(key, total));
		response.hasValue() && this.mapFromApiCalculate(response.get(), key, total);
	}

	private mapToApiCalculate(key: 'columns' | 'rows', total: boolean): apiPostCalculate.Request {
		if (key === 'rows') {
			return {
				items: this.rowList.all.map((e) => e.mapToApiCalculate(total)),
			};
		}
		const output: apiPostCalculate.Request = { items: [] };
		for (let i = 0; i < this.getDateArray().length; i++) {
			output.items.push([]);
			for (const row of this.rowList.all) {
				const item: Item | undefined = row.itemList.getItemByDay(i);
				if (!item) continue;
				for (const z of item.mapToApiCalculate(total)) {
					output.items[output.items.length - 1].push(z);
				}
			}
		}
		return output;
	}

	private mapFromApiCalculate(
		data: apiPostCalculate.ResponseData,
		key: 'columns' | 'rows',
		total: boolean
	): void {
		if (key === 'rows') {
			this.rowList.mapFromApiCalculate(data, total);
		}
		if (key === 'columns') {
			this.columnList.mapFromApiCalculate(data, total);
		}
	}

	public getNutrientPerPart(_nutrientValue: number): number {
		return 0;
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		return nutrientValue;
	}

	public getNutrientTotal(_nutrientValue: number): number {
		return 0;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.rowList.getNutrientCategoryGroups();
	}

	private cleanLocalizationFromEmptyString(
		localization: LocalizedTranslation<string> | null
	): LocalizedTranslation<string> | null {
		if (localization) {
			const result: { [key: string]: string } = {};

			for (const key in localization) {
				if (localization[key] !== '') {
					result[key] = localization[key];
				}
			}

			return result;
		}
		return null;
	}

	private removeUnusedItems(): void {
		const amountOfDays: number | undefined = this.getDurationDays();
		if (amountOfDays) {
			for (const row of this.rowList.all) {
				row.itemList.all = row.itemList.all.filter((e: Item) => {
					if (e.day < amountOfDays) {
						return e;
					}
				});
				row.name = this.cleanLocalizationFromEmptyString(row.name);
			}
		}
		this.title = this.cleanLocalizationFromEmptyString(this.title);
	}

	private mapToApi(): apiPut.Request {
		return {
			systemStatus: this.systemStatus,
			statusId: this.status.id,
			caloriesLimit: this.caloriesLimit,
			priceLimit: this.priceLimit,
			startDate: this.startDate ? this.startDate.toJSON() : undefined,
			durationDays: this.getDurationDays(),
			titleTranslations: this.title ?? {},
			rows: this.rowList.mapToApiPut(),
		};
	}

	public getDateArray(): Date[] {
		if (this.startDate && this.endDate) {
			const startDate: Date = new Date(this.startDate.getTime());
			const endDate: Date = new Date(this.endDate.getTime());
			startDate.setHours(0, 0, 0, 0);
			const datesArray = [];
			while (startDate <= endDate) {
				datesArray.push(new Date(startDate));
				startDate.setDate(startDate.getDate() + 1);
			}
			return datesArray;
		}
		return [];
	}

	public changeRowTitle(rowIndex: number, cultureCode: string, value: string): void {
		const description: LocalizedTranslation<string> | null = { [cultureCode]: value };
		this.rowList.all[rowIndex].name = {
			...this.rowList.all[rowIndex].name,
			...description,
		};
	}

	public async translateMenuPlan(
		entryLanguage: string,
		translateLanguage: string
	): Promise<void> {
		await this.translateTitle(entryLanguage, translateLanguage);
		for (let i = 0; i < this.rowList.all.length; i++) {
			await this.translateRowTitle(i, entryLanguage, translateLanguage);
		}
	}

	public async translateRowTitle(
		rowIndex: number,
		entryLanguage: string,
		translateLanguage: string
	): Promise<void> {
		const text: string | null | undefined = this.rowList.all[rowIndex].name?.[entryLanguage];

		if (text) {
			const translationDto = {
				fromCultureCode: entryLanguage,
				toCultureCode: translateLanguage,
				text: text,
			};

			const response = await apiPostTranslation.callApi(translationDto);
			if (response.hasValue()) {
				const description = { [translateLanguage]: response.get() };
				this.rowList.all[rowIndex].name = {
					...this.rowList.all[rowIndex].name,
					...description,
				};
			}
		}
	}

	public async translateTitle(entryLanguage: string, translateLanguage: string): Promise<void> {
		const text: string | null | undefined = this.title?.[entryLanguage];

		if (text) {
			const translationDto = {
				fromCultureCode: entryLanguage,
				toCultureCode: translateLanguage,
				text: text,
			};

			const response = await apiPostTranslation.callApi(translationDto);
			if (response.hasValue()) {
				const title = { [translateLanguage]: response.get() };
				this.title = {
					...this.title,
					...title,
				};
			}
		}
	}

	public getItemFromRow(day: number, rowIndex: number): Item | undefined {
		const output: Item | undefined = this.rowList.all[rowIndex].itemList.getItemByDay(day);
		return output;
	}

	public setRecipeToRow(
		day: number,
		rowIndex: number,
		recipeId: string,
		title?: LocalizedTranslation<string>,
		images?: ImageList
	): void {
		const item = new Item();
		item.id = recipeId;
		item.type = MENUPLANNERITEMTYPE.RECIPE;
		item.day = day;
		if (title) item.title = title;
		if (images) item.images = images;
		this.rowList.all[rowIndex].itemList.all.push(item);
	}

	public setMenuToRow(
		day: number,
		rowIndex: number,
		recipeId: string,
		title?: LocalizedTranslation<string>,
		images?: ImageList
	): void {
		const item = new Item();
		item.id = recipeId;
		item.type = MENUPLANNERITEMTYPE.MENU;
		item.day = day;
		if (title) item.title = title;
		if (images) item.images = images;
		this.rowList.all[rowIndex].itemList.all.push(item);
	}

	public stringifyCaloriesLimitPerDay(): string {
		const caloriesLimitPerDay: number = this.getCaloriesLimitPerDayNumber();
		if (caloriesLimitPerDay > 0) {
			return String(caloriesLimitPerDay);
		}
		return '-';
	}

	private getCaloriesLimitPerDayNumber(): number {
		const duration: number | undefined = this.getDurationDays();
		if (duration && this.caloriesLimit) {
			if (duration > 0) {
				return Math.round((this.caloriesLimit / duration) * 100) / 100;
			}
			return 0;
		}
		return 0;
	}

	public setCaloriesLimitPerDay(input: number): void {
		const duration: number | undefined = this.getDurationDays();
		if (duration) {
			this.caloriesLimit = input * duration;
		}
	}

	public stringifyPriceLimitPerDay(): string {
		const priceLimitPerDay: number = this.getPriceLimitPerDayNumber();
		if (priceLimitPerDay > 0) {
			return String(priceLimitPerDay);
		}
		return '-';
	}

	private getPriceLimitPerDayNumber(): number {
		const duration: number | undefined = this.getDurationDays();
		if (duration && this.priceLimit) {
			if (duration > 0) {
				return Math.round((this.priceLimit / duration) * 100) / 100;
			}
			return 0;
		}
		return 0;
	}

	public setPriceLimitPerDay(input: number): void {
		const duration: number | undefined = this.getDurationDays();
		if (duration) {
			this.priceLimit = input * duration;
		}
	}

	public getConstraintStatusPrice(): ConstraintStatus {
		return this.isPriceConstraintMet;
	}

	public getConstraintStatusCalories(): ConstraintStatus {
		return this.isCaloriesConstraintMet;
	}

	public getDurationDays(): number | undefined {
		if (this.startDate && this.endDate) {
			const dateA: Date | null = new Date(this.startDate.getTime());
			const dateB: Date | null = new Date(this.endDate.getTime());
			const oneDay: number = 24 * 60 * 60 * 1000;
			const diffInMilliseconds: number = dateB.getTime() - dateA.getTime();
			const diffInDays: number = Math.round(diffInMilliseconds / oneDay) + 1;
			return diffInDays;
		}
		return undefined;
	}

	private calculateEndDate(startDate: Date, durationDays: number): Date {
		const tempDate: Date = new Date(startDate);
		const endDateNumber: number = tempDate.setDate(startDate.getDate() + (durationDays - 1));
		return new Date(endDateNumber);
	}

	public mapFromApiGet(input: apiGet.ResponseData) {
		this.id = input.id;
		this.systemStatus = input.systemStatus;
		this.status.mapFromApi(input.status);
		this.caloriesLimit = input.caloriesLimit;
		this.priceLimit = input.priceLimit;
		this.isPriceConstraintMet = input.isPriceConstraintMet;
		this.isCaloriesConstraintMet = input.isCaloriesConstraintMet;
		this.carbonDioxideValue = input.carbonDioxideValue;
		if (input.startDate) {
			this.startDate = new Date(input.startDate.split('T')[0]);
			this.endDate = this.calculateEndDate(new Date(this.startDate), input.durationDays);
		}
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.title = input.titleTranslations;
		this.rowList.mapFromApiGet(input.rows);
		this.seasonList.mapFromApi(input.seasons);
	}
}

export type Check = { shouldHave: number; have: number; okay: boolean };
