import * as apiSW from 'api/allergen/GetAllergenStartingWithV1';
import Allergen from 'classes/_general/Search/Allergen/Allergen';
import { Selectable } from 'classes/_general/Search/MultiSelect';
import { AllergenLogic } from 'enums/allergenLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class AllergenList implements Selectable {
	public all: Allergen[] = [];
	public filtered: ISelectItem[] = [];

	public async initialize(): Promise<void> {
		const response = await apiSW.callApi('', false);
		response.hasValue() && this.mapFromApi(response.get());
	}

	public async search(searchTerm: string): Promise<void> {
		const filtered: Allergen[] = this.all.filter((e: Allergen) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		this.filtered = this.toSelectItemList(filtered);
	}

	private toSelectItemList(input: Allergen[]): ISelectItem[] {
		return input.map((e: Allergen) => {
			return e.toSelectItem();
		});
	}

	private mapFromApi(input: apiSW.ResponseAllergen[]): void {
		this.all = [];
		for (const i of input) {
			const allergen: Allergen = new Allergen(i);
			this.all.push(allergen);
		}
	}

	public getById(id: string): Allergen | undefined {
		return this.all.find((e: Allergen) => {
			return e.id === id;
		});
	}

	public getByIdAndLogic(id: string, logic: AllergenLogic): Allergen | undefined {
		return this.all.find((e: Allergen) => {
			return e.id === id && e.logic === logic;
		});
	}
}
