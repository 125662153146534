import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as apiGetModifications from 'api/analytics/GetModificationsV1';
import * as apiGetRecipeStatus from 'api/analytics/GetRecipeStatusV1';
import * as apiGetStatistics from 'api/analytics/GetStatisticsV1';
import RecipeList from 'classes/Recipe/Search/RecipeList';
import Search from 'classes/Recipe/Search/Search';
import { DoughnutData } from 'components/desktop/Dashboard/ChartDoughnut/ChartDoughnut';
import DashboardComponent from 'components/desktop/Dashboard/DashboardComponent';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { RecipeSortOrder } from 'enums/SortOrder/RecipeSortOrder';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';

const DashboardContainer = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [recipeList, setRecipeList] = useState<RecipeList>(new RecipeList());
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [recipeStatus, setRecipeStatus] = useState<{
		isLoading: boolean;
		data: DoughnutData[];
	}>({ isLoading: false, data: [] });
	const [modifications, setModifications] = useState<apiGetModifications.ModificationList>(
		{} as apiGetModifications.ModificationList
	);
	const [statistics, setStatistics] = useState<apiGetStatistics.Statistic>(
		{} as apiGetStatistics.Statistic
	);

	useEffect(() => {
		if (reduxCultureCode) {
			initialize();
		}
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		if (arePermissionsInUserPermissions([PERMISSIONS.READRECIPE])) {
			await getRecipes();
		}
		if (arePermissionsInUserPermissions([PERMISSIONS.READMODIFICATIONSANALYTICS])) {
			await getModificationList();
		}
		if (arePermissionsInUserPermissions([PERMISSIONS.READRECIPESTATUSANALYTICS])) {
			await getRecipeStatus();
		}
		if (arePermissionsInUserPermissions([PERMISSIONS.READSTATISTICSANALYTICS])) {
			await getStatistics();
		}
		setIsLoading(false);
	};

	const getStatistics = async (): Promise<void> => {
		const response = await apiGetStatistics.callApi();
		response.hasValue() && setStatistics(response.get());
	};

	const getRecipes = async (): Promise<void> => {
		const search = new Search();
		search.pageIndex.value = 0;
		search.pageSize.value = 6;
		search.sortOrder.value = RecipeSortOrder.LastEditedDateDesc;
		const response = await recipeList.callApi(search.mapToRequest());
		response.do((x) => {
			recipeList.mapFromApi(x);
			setRecipeList(clone(recipeList));
		});
	};

	const getModificationList = async () => {
		const response = await apiGetModifications.callApi();
		response.hasValue() && setModifications(response.get());
	};

	const getRecipeStatus = async (): Promise<void> => {
		const recipeStatusData: DoughnutData[] = [];
		setRecipeStatus({ isLoading: true, data: recipeStatusData });
		const response = await apiGetRecipeStatus.callApi();
		response.hasValue() &&
			response.get().forEach((x) =>
				recipeStatusData.push({
					label: x.name,
					value: x.count,
					backgroundColor: x.color,
				})
			);
		setRecipeStatus({ isLoading: false, data: recipeStatusData });
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<DashboardComponent
					isLoading={isLoading}
					recipeList={recipeList}
					recipeStatus={recipeStatus}
					modifications={modifications}
					statistics={statistics}
				/>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default DashboardContainer;
