import * as apiPostSearch from 'api/baseIngredient/PostBaseIngredientSearchV1';
import BaseIngredientCategory from 'classes/BaseIngredient/Search/BaseIngredientCategory';

export default class BaseIngredientCategoryList {
	public all: BaseIngredientCategory[] = [];

	public constructor(input?: apiPostSearch.Category[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiPostSearch.Category[] | null) {
		if (input) {
			for (const i of input) {
				const group: BaseIngredientCategory = new BaseIngredientCategory(i);
				this.all.push(group);
			}
		}
	}
}
