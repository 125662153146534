import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<Tag[]>> {
	const url = '/backend-service/AiFunctions/Suggest/RecipeTag';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	nrOfRecommendations: number;
	recipeId: string | null;
	recipeText: string | null;
}

export interface Response {
	data: Tag[];
}

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string;
};
