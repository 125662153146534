import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import PasswordResetForm from 'components/desktop/User/PasswordRecovery/PasswordResetForm';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import LogoAnimation from 'components/desktop/_general/LogoAnimation/LogoAnimation';
import 'containers/desktop/User/PasswordRecovery/PasswordRecovery.scss';
import getQueryParam from 'functions/getQueryParam';
import {
	PasswordResetDto,
	defaultPasswordResetDto,
} from 'types/User/PasswordRecovery/PasswordResetDto';

const PasswordReset = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [password1, setPassword1] = useState<string>('');
	const [password2, setPassword2] = useState<string>('');
	const passwordResetDto: PasswordResetDto = defaultPasswordResetDto();
	const navigate = useNavigate();

	const handleResetPassword = async () => {
		setIsLoading(true);
		const url = `/backend-service/User/${getQueryParam('user')}/ChangePassword`;

		passwordResetDto.password = password1;
		passwordResetDto.token = getQueryParam('token');

		try {
			await axios.post(url, passwordResetDto);
			navigate('/login');
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<div className="password-recovery-container">
					<h2>Artificial Intelligence Recipes</h2>
					<div className="password-recovery-inner-container">
						<LogoAnimation />
						<div className="password-recovery-inner-container-div">
							{!isLoading ? (
								<PasswordResetForm
									handleResetPassword={handleResetPassword}
									password1={password1}
									password2={password2}
									setPassword1={setPassword1}
									setPassword2={setPassword2}
								/>
							) : (
								<LoadingSpinner />
							)}
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default PasswordReset;
