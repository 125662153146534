import BaseIngredientCategoryManagementComponent from 'components/desktop/StandingData/BaseIngredientCategory/Management/BaseIngredientCategoryManagementComponent';

const BaseIngredientCategoryArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<BaseIngredientCategoryManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default BaseIngredientCategoryArchiveContainer;
