import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faCircle, faDollarSign, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faTimer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Recipe } from 'api/menu/GetMenuIdV1';
import 'components/desktop/Menu/RecipeList/RecipeListItem.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ENTITLEMENTS from 'enums/entitlements';
import { ValueScope } from 'enums/valueScope';
import { getNumberString, getPriceString } from 'functions/numberToString';
import { isUserWriter } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	buttons: JSX.Element;
	index: number;
	isActive: boolean;
	recipe: Recipe;
	setSelectedRecipe: (e: any, index: number) => void;
}

const RecipeListItem = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const readOnly: boolean = !isUserWriter();

	const [showButtons, setShowButtons] = useState<boolean>(false);

	const onMouseOver = (): void => {
		if (!readOnly) {
			setShowButtons(true);
		}
	};

	const onMouseLeave = (): void => {
		setShowButtons(false);
	};

	const getTitle = (): string => {
		if (props.recipe.titleTranslations[reduxCultureCode]) {
			return props.recipe.titleTranslations[reduxCultureCode];
		}
		return t('_general:UNKNOWN_TITLE');
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(ValueScope.Person, null, true);
	};

	const getActionButtonClass = (): string => {
		if (showButtons) {
			return 'card-action-buttons text-center';
		}
		return 'card-action-buttons hide text-center';
	};

	const getStatus = () => {
		if (props.recipe.status?.name) {
			return (
				<>
					{props.recipe.status.name}&ensp;
					<FontAwesomeIcon
						color={props.recipe.status.color!}
						icon={faCircle as IconProp}
					/>
				</>
			);
		}
		return (
			<>
				{t('_general:UNKNOWN_STATUS')}
				<FontAwesomeIcon icon={faCircle as IconProp} />
			</>
		);
	};

	const getInfos = () => {
		return (
			<>
				<p>
					{props.recipe.calories && (
						<>
							<span id="icon">
								<FontAwesomeIcon icon={faUtensils as IconProp} />
							</span>
							<b>{getNumberString(props.recipe.calories, reduxCultureCode, true)}</b>
							{` ${t('_general:CALORIES')} ${getValueScope()}`}
						</>
					)}
					&nbsp;
				</p>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<p>
						{props.recipe.price && (
							<>
								<span id="icon">
									<FontAwesomeIcon icon={faDollarSign as IconProp} />
								</span>
								<b>{getPriceString(props.recipe.price, reduxCultureCode)}</b>
								{` ${reduxCurrency} ${getValueScope()}`}
							</>
						)}
						&nbsp;
					</p>
				</RenderIf>
				<p>
					{props.recipe.prepareTime && (
						<>
							<span id="icon">
								<FontAwesomeIcon icon={faTimer as IconProp} />
							</span>
							<b>{Math.round(props.recipe.prepareTime.timeValue)}</b>
							{` ${props.recipe.prepareTime.timeUnit}`}
						</>
					)}
				</p>
			</>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div
					className={props.isActive ? 'card recipe-card active' : 'card recipe-card'}
					onClick={(e: any) => props.setSelectedRecipe(e, props.index)}
					onMouseLeave={onMouseLeave}
					onMouseOver={onMouseOver}
				>
					<div className={getActionButtonClass()} style={{ zIndex: 3000 }}>
						{props.buttons}
					</div>{' '}
					<div className="card-body">
						{!props.recipe.image ? (
							<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
								<FontAwesomeIcon size="3x" icon={faCamera} />
							</div>
						) : (
							<div className="card-picture">
								<img alt="thumbnail" src={props.recipe.image.url} />
							</div>
						)}
						<div>
							<div id="title">
								<strong>{getTitle()}</strong>
							</div>
							<div id="info">{getInfos()}</div>
							<hr />
							<div id="author-status">{getStatus()}</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeListItem;
