import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	pointType: NutriScoreCategoryIngredientCategory | undefined,
	pageIndex: number,
	pageSize: number
): Promise<Optional<ResponseData>> {
	const url = '/backend-service/NutriScoreIngredientCategoryPoints';
	const params: Parameter = {
		SearchTerm: searchTerm,
		PointType: pointType,
		PageIndex: pageIndex,
		PageSize: pageSize,
	};
	return axios
		.get(url, {
			params: params,
		})
		.toOptionalMapped((x) => x.data);
}

interface Parameter {
	SearchTerm: string;
	PointType?: NutriScoreCategoryIngredientCategory;
	PageIndex: number;
	PageSize: number;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: NutriScoreIngredientCategoryPoint[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: number;
	hasNextPage: number;
};

export type NutriScoreIngredientCategoryPoint = {
	ingredientCategoryId: string;
	pointType: NutriScoreCategoryIngredientCategory;
	ingredientCategoryName: string | null;
	isPositive: boolean;
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};
