import { IBaseListRow } from 'components/desktop/_general/SearchResultList/IBaseListRow';

export const UserListRowDiscriminatorName = 'UserListRow';
export interface IUserListRow extends IBaseListRow {
	discriminator: 'UserListRow';
	id: string;
	title: string;
	imageUrl: string;
	href: string;
	buttons: JSX.Element | null;
	email: string;
	roles: string[];
	isActive: boolean;
	cultureCode: string;
}
