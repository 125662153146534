import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { AirUrlParams } from 'hooks/useAirNavigation';
import * as SelectItem from 'types/Product/Search/SelectItem';
import * as SelectItemList from 'types/Product/Search/SelectItemList';

export type Type = {
	id: string;
	suggested: SelectItemList.Type;
	saved: SelectItem.Type[];
	cultureCode: string | null;
};

export function create(): Type {
	return {
		cultureCode: null,
		id: '',
		saved: [],
		suggested: SelectItemList.create(),
	};
}

export async function initialize(obj: Type): Promise<Type> {
	const cultureCode: string | null = getCultureCodeFromLocalStorage();
	if (cultureCode !== obj.cultureCode) {
		obj.cultureCode = cultureCode;
		obj.suggested = await SelectItemList.initialize();
		translateSaved(obj);
	}
	if (obj.suggested.all.length === 0) {
		obj.suggested = await SelectItemList.initialize();
	}
	return obj;
}

export function mapToApi(obj: Type): string[] {
	return obj.saved.map((e: SelectItem.Type) => {
		return e.id;
	});
}

export function getFromUrl(params: AirUrlParams, obj: Type): Type {
	for (const [key, value] of Object.entries(params)) {
		if (key !== obj.id) continue;
		if (typeof value !== 'string') continue;

		const id = value.split(';')[0];
		if (!id) continue;

		if (obj.saved.find((e) => e.id === id)) continue;
		const object: any = getById(id, obj.suggested);
		if (!object) continue;

		obj.saved.push(object);
	}
	return obj;
}

export function getById(id: string, obj: SelectItemList.Type): SelectItem.Type | undefined {
	return obj.all.find((e: SelectItem.Type) => {
		return e.id === id;
	});
}

export function pushToSaved(items: SelectItem.Type[], obj: Type): Type {
	return {
		...obj,
		saved: items,
	};
}

function translateSaved(obj: Type): Type {
	const output: SelectItem.Type[] = [];
	for (const i of obj.saved) {
		const object: SelectItem.Type | undefined = getById(i.id, obj.suggested);
		if (!object) continue;
		output.push(object);
	}
	obj.saved = output;
	return obj;
}
