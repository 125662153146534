export enum UrlParams {
	Co2 = 'co2',
	Co2Label = 'co2Lbls',
	Co2Left = 'co2Left',
	Co2Right = 'co2Right',
	IngredientId = 'iId',
	PageIndex = 'pi',
	PageSize = 'ps',
	Price = 'pr',
	PriceLeft = 'prLeft',
	PriceRight = 'prRight',
	SearchTerm = 'st',
	SortOrder = 'so',
}

export default UrlParams;
