interface IProps {
	checked: boolean;
	label: string;
	setChecked: (checked: boolean) => void;
	disabled?: boolean;
}

const LabeledCheckbox = (props: IProps) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.setChecked(e.target.checked);
	};

	const renderContent = () => {
		return (
			<>
				<div className="form-check">
					<input
						className="form-check-input"
						type="checkbox"
						id="checkbox"
						checked={props.checked}
						onChange={handleChange}
						disabled={props.disabled ? true : false}
					/>
					<label className="form-check-label" htmlFor="checkbox">
						{props.label}
					</label>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default LabeledCheckbox;
