import UserComponent from 'components/desktop/User/User/UserComponent';

const UserNewContainer = () => {
	return (
		<>
			<main className="container">
				<UserComponent type="new" />
			</main>
		</>
	);
};

export default UserNewContainer;
