/**
 * Prefer the crypto.randomUUID() function for strong UUID safety guarantees.
 * This private function's goal is to create a random identifier token for the useRefresh
 * hook.
 * @returns
 */
export default function randomUUIDUnsafe() {
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
	return uuid;
}
