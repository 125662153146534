import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/MenuPlan/Archive/MenuPlanArchive';
import Detail from 'containers/desktop/MenuPlan/Detail/MenuPlanDetail';
import New from 'containers/desktop/MenuPlan/New/MenuPlanNew';
import Print from 'containers/desktop/MenuPlan/Print/MenuPlanPrint';
import Search from 'containers/desktop/MenuPlan/Search/MenuPlanSearch';
import PERMISSIONS from 'enums/permissions';

const publicRoutes = [
	{
		path: '/menuPlan/print/:id',
		element: <Print />,
	},
];

const protectedRoutes = [
	{
		path: '/menuPlan/search',
		element: <Search />,
		permissions: [PERMISSIONS.READMENUPLAN],
	},
	{
		path: '/menuPlan/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READMENUPLAN],
	},
	{
		path: '/menuPlan/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITEMENUPLAN],
	},
	{
		path: '/menuPlan/copy/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.WRITEMENUPLAN],
	},
	{
		path: '/menuPlan/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITEMENUPLAN],
	},
];

export default [...publicRoutes, ...protectedRoutes.map(wrapProtectedRoute)];
