import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import * as apiGetRecipeCategoryStartingWith from 'api/recipeCategory/GetRecipeCategoryStartingWithV1';
import * as apiGetAll from 'api/status/GetAllStatusV1';
import * as apiGetTagStartingWith from 'api/tag/GetTagStartingWithV1';
import {
	WithSelectedCulture,
	WithSelectedCultureProps,
} from 'components/desktop/Hocs/WithSelectedCulture';
import DownloadOverlay from 'components/desktop/MenuPlan/DownloadOverlay/DownloadOverlay';
import 'components/desktop/Recipe/RecipeComponent.scss';
import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import RecipeGeneralInformationsComponent from 'components/desktop/Recipe/RecipeGeneralInformations/RecipeGeneralInformationsComponent';
import RecipeHeader from 'components/desktop/Recipe/RecipeHeader/RecipeHeader';
import RecipeImportOverlay from 'components/desktop/Recipe/RecipeImportOverlay/RecipeImportOverlay';
import RecipeInformationsComponent from 'components/desktop/Recipe/RecipeInformations/RecipeInformationsComponent';
import RecipeMediaComponent from 'components/desktop/Recipe/RecipeMedia/RecipeMediaComponent';
import RecipeMetadataComponent from 'components/desktop/Recipe/RecipeMetadata/RecipeMetadataComponent';
import RecipeNutrientsComponent from 'components/desktop/Recipe/RecipeNutrients/RecipeNutrientsComponent';
import RecipeSuggestionsComponent from 'components/desktop/Recipe/RecipeSuggestions/RecipeSuggestionsComponent';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Tabs, { Tab } from 'components/desktop/_general/Tabs/Tabs';
import ENTITLEMENTS from 'enums/entitlements';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { Optional } from 'functions/promiseExtensions';
import { useApi } from 'hooks/useApi';
import useDebounce, { useDebouncedFn } from 'hooks/useDebounce';
import { useEntryLanguageCode } from 'hooks/useEntryLanguageCode';
import { useIsHandlerLoaded } from 'hooks/useIsLoading';
import { useLanguageDependentApi } from 'hooks/useLanguageDependentApi';
import { useOnce } from 'hooks/useOnce';
import { useRefreshAsync } from 'hooks/useRefresh';
import { useWarnOnLeave } from 'hooks/useWarnOnLeave';
import { RootState } from 'reducers/rootReducer';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { Language } from 'types/Language/Language';
import * as Product from 'types/Product/RecipeProduct';
import { IncorporationShare, Recipe, RecipeProduct, defaultRecipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import { StatusLight } from 'types/Status/StatusLight';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

const mapToProduct = (products: RecipeProduct[], cultureCode: string): Product.Type[] =>
	products.map<Product.Type>((rp) => ({
		...rp,
		name: rp.nameTranslations[cultureCode] ? rp.nameTranslations[cultureCode] : '',
	}));

type IProps = WithSelectedCultureProps & {
	copy?: boolean;
	id?: string;
	statusList: apiGetAll.ResponseStatus[];
};
const RecipeComponent = (props: IProps & { initialRecipe: Recipe }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	// Redux state
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxAllowedEntryLanguages: string[] = useSelector(
		(state: RootState) => state.allowedEntryLanguages
	);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [refreshToken, refresh] = useRefreshAsync();
	// local state
	const [recipe, setRecipe] = useState(props.initialRecipe);
	const [mediaMarkedForPatch, setMediaMarkedForPatch] = useState<string[]>([]);
	const [searchTerm, setSearchTerm] = useState<{ searchTerm: string; language: string } | null>(
		null
	);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [displayPrintOverlay, setDisplayPrintOverlay] = useState<boolean>(false);

	const [languageSwitcherCultureCode, setLanguageSwitcherCultureCode] = useState(
		props.selectedCultureCode
	);

	const ingredientSuggestions = useDebouncedFn(
		[],
		(term: string, cultureCode: string) => handlers.suggestIngredients(term, cultureCode),
		searchTerm?.searchTerm!,
		languageSwitcherCultureCode
	);

	const entryLanguageCultureCode = useEntryLanguageCode(recipe.entryLanguage.id!);
	const ingredientTranslations = useDebounce(recipe.ingredientsTranslations);
	const stepsTranslations = useDebounce(recipe.stepsTranslations);

	const [segmentInEntryLanguage, isSegmentsAnalyzed] = useApi(
		[],
		handlers.parseSegments,
		recipe.id,
		ingredientTranslations,
		stepsTranslations,
		entryLanguageCultureCode,
		refreshToken
	);

	const [nutriScore] = useApi(
		null,
		async (segments: Segment[], hasNutriScore: boolean) => {
			if (hasNutriScore) return await handlers.getNutriScore(segments);
			else return null;
		},
		segmentInEntryLanguage,
		recipe.hasNutriScore,
		recipe.nutriScoreCategory
	);

	const segmentInEntryLanguageInitial = useOnce([], segmentInEntryLanguage);

	const incorporationShares = useMemo(() => {
		const result = recipe.incorporationShares.relativePositions(
			(g) => `${g.segmentIndex}`,
			(el) => el.ingredientId,
			(el, p) => ({ ...el, relativePosition: p })
		);
		return result;
	}, [recipe.incorporationShares, segmentInEntryLanguageInitial]);

	const [segmentInLanguageSwitcherLanguage, isSegmentsInSwitcherLanguageAnalyzed] = useApi(
		[],
		handlers.potentiallyParseAdditionalLanguage,
		segmentInEntryLanguage,
		recipe.id,
		ingredientTranslations,
		stepsTranslations,
		reduxAllowedEntryLanguages.includes(languageSwitcherCultureCode)
			? languageSwitcherCultureCode
			: entryLanguageCultureCode,
		entryLanguageCultureCode
	);

	const [recipeCalculation, isRecipeCalculated] = useApi(
		null,
		(segments: Segment[], incorporationShares: IncorporationShare[]) =>
			handlers.calculate(segments, incorporationShares),
		segmentInEntryLanguage,
		incorporationShares
	);

	const [scaledSegmentsInLanguageSwitcherLanguage, isScaledSegmentsAnalyzed] = useApi(
		[],
		(scaledPersons: number | null, persons: number | null, segments: Segment[]) =>
			handlers.parseScaledSegments(
				scaledPersons,
				persons,
				languageSwitcherCultureCode,
				segments
			),
		recipe.scaledPersons,
		recipe.persons,
		segmentInLanguageSwitcherLanguage
	);

	const [scaledRecipeCalculation, isScaledSegmentsCalculated] = useApi(
		null,
		handlers.calculate,
		scaledSegmentsInLanguageSwitcherLanguage,
		incorporationShares
	);

	const [isRecipeSaved, recipeSaveHandler] = useIsHandlerLoaded(
		async (): Promise<Optional<Recipe>> => {
			if (recipe.id && !props.copy) {
				const newRecipe = await handlers.handleSave(recipe, mediaMarkedForPatch);
				return newRecipe;
			}

			const newRecipeId = await handlers.handleCreateRecipe(
				{ ...recipe, id: null },
				mediaMarkedForPatch
			);
			return newRecipeId.map((x) => ({ ...recipe, id: x }));
		}
	);

	const { setObjectCachedAsync } = useWarnOnLeave(recipe, recipeSaveHandler, props.copy);

	const recipeSaveHandlerWithResetCache = async () => {
		const isNewRecipe = recipe.id == null;
		const newRecipe = await recipeSaveHandler();

		newRecipe.do(async (recipe) => {
			setRecipe(recipe);
			await setObjectCachedAsync(recipe);
			if (props.copy || isNewRecipe) {
				navigate(`/recipe/detail/${recipe.id}`);
			}
		});
	};

	const isLoading = useMemo(() => !isRecipeSaved, [isRecipeSaved]);

	const [tags] = useLanguageDependentApi([], () =>
		apiGetTagStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([]))
	);

	const [recipeCategories] = useLanguageDependentApi([], () =>
		apiGetRecipeCategoryStartingWith.callApi('', false).mapAsync((x) => x.getOrDefault([]))
	);

	const ingredientProducts = useMemo(
		(): Product.Type[] => mapToProduct(recipe.ingredientProducts, props.selectedCultureCode),
		[recipe.ingredientProducts, props.selectedCultureCode]
	);

	const nutrients = recipeCalculation?.nutrientValues
		? recipeCalculation.nutrientValues.items
		: [];

	const updateChannels = (mediaId: string, channels: ChannelLight[]) => {
		const updateChannelsHandler = handlers.createUpdateChannelsHandler(setRecipe);
		if (!mediaMarkedForPatch.includes(mediaId)) {
			setMediaMarkedForPatch([...mediaMarkedForPatch, mediaId]);
		}
		updateChannelsHandler(mediaId, channels);
	};

	const isSegmentsLoadingDone = useDebounce(
		isSegmentsAnalyzed && isRecipeCalculated && isSegmentsInSwitcherLanguageAnalyzed,
		50
	);

	const isScaledSegmentsLoadingDone = useDebounce(
		isScaledSegmentsAnalyzed && isScaledSegmentsCalculated,
		50
	);

	const loadingStatus = {
		isSegmentsLoadingDone,
		isScaledSegmentsLoadingDone,
	};

	const handleToggleOverlay = (): void => {
		setDisplayOverlay(!displayOverlay);
	};

	const getAllowedEntryLanguages = () => {
		const result: Language[] = [];
		reduxLanguages.map((x) => {
			if (reduxAllowedEntryLanguages.includes(x.cultureCode)) {
				result.push(x);
			}
		});
		return result;
	};

	const tabs: Tab[] = [
		{
			name: t('recipe:RECIPE_INFORMATIONS'),
			content: (
				<RecipeInformationsComponent
					readOnly={props.readOnly}
					refresh={refresh}
					loadingStatus={loadingStatus}
					languages={getAllowedEntryLanguages()}
					entryLanguageCultureCode={entryLanguageCultureCode}
					handleSelectEntryLanguage={handlers.createSelectEntryLanguageHandler(
						setRecipe,
						reduxLanguages
					)}
					incorporationShares={incorporationShares}
					ingredientSuggestions={ingredientSuggestions}
					languageSwitcherCultureCode={languageSwitcherCultureCode}
					recipe={recipe}
					recipeCalculation={recipeCalculation}
					scaledRecipeCalculation={scaledRecipeCalculation}
					scaledSegmentsInLanguageSwitcherLanguage={
						scaledSegmentsInLanguageSwitcherLanguage
					}
					segmentInLanguageSwitcherLanguage={segmentInLanguageSwitcherLanguage}
					setLanguageSwitcherCultureCode={setLanguageSwitcherCultureCode}
					setRecipe={setRecipe}
					nutrients={nutrients}
					nutriScore={nutriScore}
					getIngredientSuggestions={(input: string, language: string) =>
						setSearchTerm({ searchTerm: input, language: language })
					}
				/>
			),
			ai: false,
		},
		{
			name: t('recipe:NUTRIENTS'),
			content: (
				<RecipeNutrientsComponent
					recipe={recipe}
					recipeCalculation={recipeCalculation}
					setRecipe={setRecipe}
				/>
			),
			ai: false,
		},
		{
			name: t('recipe:METADATA'),
			content: (
				<RecipeMetadataComponent
					handleSeoToggle={handlers.createSeoActiveHandler(setRecipe)}
					handleSetRecipeAttribute={handlers.createRecipeAttributeHandler(setRecipe)}
					handleSetRecipeCategories={handlers.createRecipeCategoriesHandler(setRecipe)}
					handleSetRecipeTags={handlers.createTagsHandler(setRecipe)}
					readOnly={props.readOnly}
					recipe={recipe}
					recipeCategories={mapToISelectItem(recipeCategories)}
					statusList={props.statusList}
					tags={tags}
				/>
			),
			ai: false,
		},
		{
			name: t('recipe:INFORMATIONS'),
			content: (
				<RecipeGeneralInformationsComponent
					calculation={recipeCalculation}
					nutrients={nutrients}
					price={recipe.price}
					currency={reduxCurrency}
					cultureCode={props.selectedCultureCode}
				/>
			),
			ai: false,
		},
		{
			name: t('recipe:AI_RECOMMENDER'),
			content: (
				<RecipeSuggestionsComponent
					selectedCultureCode={props.selectedCultureCode}
					ingredientProducts={ingredientProducts}
					recipe={recipe}
				/>
			),
			ai: true,
			display: Boolean(recipe.id),
		},
		{
			name: t('recipe:MEDIA'),
			content: (
				<RecipeMediaComponent
					readOnly={props.readOnly}
					recipe={recipe}
					setRecipe={setRecipe}
					updateChannels={updateChannels}
				/>
			),
			ai: false,
		},
	];

	const handleDownload = (cultureCode: string): void => {
		const url: string = '/recipe/print/' + recipe.id + '?cultureCode=' + cultureCode;
		setDisplayPrintOverlay(false);
		window.open(url, '_blank');
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<DownloadOverlay
				handleDownload={handleDownload}
				displayOverlay={displayPrintOverlay}
				setDisplayOverlay={setDisplayPrintOverlay}
			/>
			<RenderIf entitlements={[ENTITLEMENTS.URL_ANALYSIS]}>
				<RecipeImportOverlay
					handleToggleOverlay={handleToggleOverlay}
					displayOverlay={displayOverlay}
					recipe={recipe}
					setRecipe={setRecipe}
					selectedCultureCode={props.selectedCultureCode}
				/>
			</RenderIf>
			<RecipeHeader
				entryLanguageCultureCode={entryLanguageCultureCode}
				handleSave={recipeSaveHandlerWithResetCache}
				handleToggleOverlay={handleToggleOverlay}
				id={recipe.id}
				image={recipe.image}
				isLoading={isLoading}
				masterLanguageCultureCode={reduxCultureCode}
				readOnly={props.readOnly}
				recipe={recipe}
				recipeCalculation={recipeCalculation}
				setDisplayOverlay={setDisplayPrintOverlay}
				segmentInEntryLanguage={segmentInEntryLanguage}
				nutriScore={nutriScore}
			/>
			<div className="margin-top-25">
				<Tabs
					tabs={tabs.filter((t: Tab) => {
						if (t.display === undefined || t.display === true) {
							return t;
						}
					})}
				/>
			</div>
		</>
	);
};

const RecipeLoaderComponent = (props: IProps) => {
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);

	const [statusList] = useApi(
		[],
		async () => (await apiGetAll.callApi()).map((x) => x).getOrDefault([]),
		props.selectedCultureCode
	);

	if (!props.id) {
		if (statusList.isEmpty()) return <></>;

		const defaultStatus: StatusLight = statusList
			.orderBy((x) => x.order)
			.map((x) => ({ id: x.id, name: x.name }))
			.first();

		const initialRecipe = defaultRecipe(
			defaultStatus,
			reduxLanguages.filter((x) => x.cultureCode === props.selectedCultureCode).first()
		);

		return <RecipeComponent {...props} statusList={statusList} initialRecipe={initialRecipe} />;
	}

	const [recipe] = useApi(null, handlers.fetchRecipe, props.id);

	if (!recipe) return <LoadingAnimation isLoading={true} />;

	if (props.copy) {
		return (
			<RecipeComponent
				{...props}
				statusList={statusList}
				initialRecipe={{
					...recipe,
					titleTranslations: makeCopyText(recipe.titleTranslations),
				}}
			/>
		);
	}

	return <RecipeComponent {...props} initialRecipe={recipe} statusList={statusList} />;
};

function makeCopyText(
	titleTranslations: LocalizedTranslation<string>
): LocalizedTranslation<string> {
	const result = {} as LocalizedTranslation<string>;

	for (const [key, value] of Object.entries(titleTranslations)) {
		result[key] = `${value} - Copy`;
	}
	return result;
}

export default WithSelectedCulture(RecipeLoaderComponent);
