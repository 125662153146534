import * as api from 'api/recipe/PostRecipeCalculateV1';

export default class CarbonDioxideValuePerAccessory {
	public accessoryIndex: number = 0;
	public accessoryId: string = '';
	public value: number = 0;

	public constructor(input?: api.CarbonDioxideValuePerAccessory) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.CarbonDioxideValuePerAccessory) {
		this.accessoryIndex = input.accessoryIndex;
		this.accessoryId = input.accessoryId;
		this.value = input.value;
	}
}
