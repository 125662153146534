import * as apiSW from 'api/baseIngredient/GetBaseIngredientStartingWithV1';
import * as api from 'api/baseIngredient/PostBaseIngredientSearchV1';
import BaseIngredient from 'classes/BaseIngredient/Search/BaseIngredient';
import { Optional } from 'functions/promiseExtensions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class BaseIngredientList {
	public all: BaseIngredient[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const baseIngredients = await apiSW.callApi(searchTerm, showOnlyDeleted);
		baseIngredients.hasValue() && this.mapFromApiSW(baseIngredients.get());
	}

	public async callApi(input: api.Request): Promise<Optional<api.ResponseData>> {
		return await api.callApi(input);
	}

	public mapFromApi(input: api.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const baseIngredient: BaseIngredient = new BaseIngredient(i);
			this.all.push(baseIngredient);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseBaseIngredient[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.description,
			});
		}
	}
}
