import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { WithSelectedCulture } from 'components/desktop/Hocs/WithSelectedCulture';
import { ToolTipCell } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import LinkCell from 'components/desktop/Recipe/RecipeTabs/Components/LinkCell/LinkCell';
import {
	NutrientFilterSearchComponentWrapper,
	co2ValueKey,
	incorporationShareKey,
	ingredientAmountKey,
	ingredientNameKey,
	ingredientUnitKey,
	nutrientColumnClassName,
	priceKey,
} from 'components/desktop/Recipe/RecipeTabs/Components/NutrientFilterSearchComponentWrapper';
import NutrientSearchFilter from 'components/desktop/Recipe/RecipeTabs/Components/NutrientSearchFilter/NutrientSearchFilter';
import {
	FilterableColumnInfo,
	JoinedIngredientType,
	Translation,
	calculateSegmentLengths,
	enrichIngredients,
	flattenSegments,
	mapToTableData,
} from 'components/desktop/Recipe/RecipeTabs/recipeComputations';
import { AirTable } from 'components/desktop/_general/AirTable/AirTable';
import { ColumnInformation } from 'components/desktop/_general/AirTable/AirTableTypes';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { RefreshType } from 'hooks/useRefresh';
import { RootState } from 'reducers/rootReducer';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { IncorporationShare } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';

const createIncorporationShareColumns = (t: Translation, currency: string) => {
	const defaultColumns = [
		{
			columnName: t('recipe:PORTION_IN_PERCENT'),
			key: incorporationShareKey,
			sticky: true,
			editable: true,
		},
		{ columnName: t('_general:AMOUNT'), key: ingredientAmountKey, numeric: true, sticky: true },
		{ columnName: t('_general:UNIT'), key: ingredientUnitKey, sticky: true },
		{ columnName: t('_general:INGREDIENT'), key: ingredientNameKey, sticky: true },
		...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
			? [
					{
						columnName: `${t('recipe:PRICE')} (${currency})`,
						key: priceKey,
						numeric: true,
						sticky: true,
						className: nutrientColumnClassName,
					},
			  ]
			: []),
		{
			columnName: t('_general:CO2'),
			key: co2ValueKey,
			numeric: true,
			sticky: false,
			className: nutrientColumnClassName,
		},
	];

	return defaultColumns;
};

const calculateNewIncorporationShareArray = (
	mappedIngredients: JoinedIngredientType[],
	value: string,
	index: number
) => {
	if (Number.isNaN(parseFloat(value))) return;

	const numericValue: number = Number(value);
	if (numericValue > 100) return;
	if (numericValue < 0) return;
	const array = mappedIngredients.map((x) => ({
		segmentIndex: x.ingredient.segment,
		ingredientIndex: x.ingredientIndex,
		incorporationShare: x.incorporationShare?.incorporationShare as any,
		ingredientId: x.ingredient.ingredientId as any,
		unitId: x.ingredient.unitId,
		quantity: x.ingredient.quantity,
		index: x.index,
	}));

	array[index].incorporationShare = parseFloat(value) / 100;
	return array.filter((x) => x.incorporationShare && x.ingredientId);
};

const createIncorporationShareHandler = (
	columnInfo: ColumnInformation,
	joinedIngredients: JoinedIngredientType[],
	handleIncorporationShareChange: (shares: IncorporationShare[]) => void
) => {
	return {
		...columnInfo,
		onInputChange: (value: string, index: number) => {
			const array = calculateNewIncorporationShareArray(joinedIngredients, value, index);
			array && handleIncorporationShareChange(array);
		},
	};
};

const calculateColumnsToDisplay = (
	t: Translation,
	currency: string,
	ingredientNutrientsTableColumnKeys: FilterableColumnInfo[],
	joinedIngredients: JoinedIngredientType[],
	handleIncorporationShareChange: (shares: IncorporationShare[]) => void
) => {
	const fixedColumns = createIncorporationShareColumns(t, currency).map((x) => {
		if (x.key === incorporationShareKey) {
			return createIncorporationShareHandler(
				x,
				joinedIngredients,
				handleIncorporationShareChange
			);
		}
		return x;
	});

	const co2 = fixedColumns.last();

	const nutrientColumns = ingredientNutrientsTableColumnKeys.map((x) => ({
		...x,
		numeric: true,
		className: nutrientColumnClassName,
	}));

	const [kcal, ...remainingColumns] = nutrientColumns;
	const fixedColumnsButLast = fixedColumns.butlast();

	return [
		...fixedColumnsButLast,
		...(kcal ? [{ ...kcal, className: nutrientColumnClassName, sticky: true }] : []),
		co2,
		...remainingColumns,
	];
};

interface IProps {
	handleIncorporationShareChange: (shares: IncorporationShare[]) => void;
	quantityMethod: string;
	selectedCultureCode: string;
	refresh: RefreshType;
	readOnly: boolean;
	calculation: Calculation | null;
	segments: Segment[];
	incorporationShares: IncorporationShareType[];
}

const PriceNutrientCo2View = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const [nutrientsSaved, setNutrientsSaved] = useState<Nutrient[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [element, setElement] = useState<JSX.Element>(<></>);

	const ingredientNutrientsTableColumnKeys = props.calculation
		? props.calculation.getTableColumnKeys(searchTerm, nutrientsSaved)
		: [];

	const flatIngredients = flattenSegments(props.segments);

	const joinedIngredients = enrichIngredients(
		props.calculation,
		props.incorporationShares,
		flatIngredients
	);

	const columns = calculateColumnsToDisplay(
		t,
		reduxCurrency,
		ingredientNutrientsTableColumnKeys,
		joinedIngredients,
		props.handleIncorporationShareChange
	);

	const segmentLengths = calculateSegmentLengths(props.segments);

	const tableData = joinedIngredients.map((ingredient) => ({
		...mapToTableData(
			ingredient,
			ingredientNutrientsTableColumnKeys,
			props.selectedCultureCode,
			props.quantityMethod
		),
		customCell: {
			ingredientName: (
				<ToolTipCell
					refresh={props.refresh}
					ingredient={ingredient.ingredient}
					setElement={setElement}
					lastCell={segmentLengths.includes(ingredient.index + 1)}
				/>
			),
			ingredientUnit: (
				<LinkCell
					link={
						ingredient.ingredient.unitId
							? `/unit/management?id=${ingredient.ingredient.unitId}`
							: '/unit/management'
					}
					title={ingredient.ingredient.unit}
					id={ingredient.ingredient.unitId}
					lastCell={segmentLengths.includes(ingredient.index + 1)}
				/>
			),
		},
	}));

	return (
		<>
			{element}
			<NutrientFilterSearchComponentWrapper>
				<NutrientSearchFilter
					searchTerm={searchTerm}
					onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
					nutrientsSaved={nutrientsSaved}
					onChangeFilter={(e) => setNutrientsSaved(e)}
				/>
			</NutrientFilterSearchComponentWrapper>

			<AirTable
				columns={columns}
				data={tableData}
				dividers={segmentLengths}
				readOnly={props.readOnly}
			></AirTable>
		</>
	);
};

export default WithSelectedCulture(PriceNutrientCo2View);
