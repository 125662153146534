import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(userId: string, requestBody: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/User/${userId}`;
	return axios.put(url, requestBody).toOptionalVoid();
}

export interface Request {
	firstName: string;
	lastName: string;
	email: string;
	languageId: string;
	profilePictureId: string | undefined;
	nutrients: string[];
	userSettings: UserSettings | undefined;
	roles: string[] | undefined;
	isActive: boolean;
}

export type UserSettings = {
	'Price.Currency'?: string;
	'Recipe.List.ValueScope'?: ValueScope;
};

export enum ValueScope {
	Total = 'Total',
	Person = 'Person',
	Parts = 'Parts',
	HundredGrams = 'HundredGrams',
}
