import stringify from 'json-stable-stringify';
import { useEffect, useState } from 'react';

export function useOnce<T>(initialValue: T, newValue: T): T {
	const [data, setData] = useState(initialValue);
	const [valueSet, setValue] = useState(false);

	useEffect(() => {
		if (valueSet) return;

		if (stringify(data) === stringify(newValue)) {
			return;
		}

		setData(newValue);
		setValue(true);
	}, [newValue]);

	return data;
}

/* eslint-enable no-redeclare */
