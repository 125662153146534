import { DependencyList, useEffect, useState } from 'react';

import { useSelectedLanguage } from 'hooks/useSelectedLanguage';
import { Language } from 'types/Language/Language';

/* eslint-disable no-redeclare */

export function useLanguageDependentApi<T>(initialValue: T, f: () => Promise<T>): [T, boolean];

export function useLanguageDependentApi<T, T1, T2>(
	initialValue: T,
	f: (t1: T1, t2: T2) => Promise<T>,
	t1: T1,
	t2: T2
): [T, boolean];

export function useLanguageDependentApi(
	initialValue: any,
	f: (...s: any) => Promise<any>,
	...s: any
): [any, boolean] {
	const [data, setData] = useState(initialValue);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const selectedCultureCode = useSelectedLanguage({} as Language);

	useEffect(() => {
		const updateFunction = async () => {
			setIsLoaded(false);
			const t = await f(...s);
			setData(t);
			setIsLoaded(true);
		};
		updateFunction();
	}, [...(s as DependencyList), selectedCultureCode]);

	return [data, isLoaded];
}

/* eslint-enable no-redeclare */
