import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/CarbonDioxideLabel/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	searchTerm: string | null;
	showOnlyDeleted: boolean;
	pageIndex: number | null;
	pageSize: number | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseCarbonDioxideLabel[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
};

export type ResponseCarbonDioxideLabel = {
	id: string;
	label: string;
	maxValue: number;
	color: string;
};
