import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import 'components/desktop/BaseIngredient/BaseIngredientComponent.scss';
import BaseIngredientHeader from 'components/desktop/BaseIngredient/BaseIngredientHeader/BaseIngredientHeader';
import * as BaseIngredientState from 'components/desktop/BaseIngredient/BaseIngredientState';
import TabCo2Footprints from 'components/desktop/BaseIngredient/Tabs/TabCo2Footprints';
import TabGeneral from 'components/desktop/BaseIngredient/Tabs/TabGeneral';
import TabNutrients from 'components/desktop/BaseIngredient/Tabs/TabNutrients';
import useBaseIngredientData from 'components/desktop/BaseIngredient/useBaseIngredientData';
import useBaseIngredientState from 'components/desktop/BaseIngredient/useBaseIngredientState';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Tabs, { Tab } from 'components/desktop/_general/Tabs/Tabs';
import { Optional } from 'functions/promiseExtensions';
import useCultureCode from 'hooks/redux/useCultureCode';
import useAirBlocker from 'hooks/useAirBlocker';
import { useIsHandlerLoaded } from 'hooks/useIsLoading';
import { useScrollToTop } from 'hooks/useScrollToTop';
import * as BaseIngredient from 'types/BaseIngredient/Detail/BaseIngredient';

interface IProps {
	id?: string;
	initialState: BaseIngredient.Type;
	isLoading: boolean;
}

const BaseIngredientComponent = (props: IProps) => {
	useScrollToTop();
	const { t } = useTranslation();
	const cultureCode: string = useCultureCode();
	const { state, handlers } = useBaseIngredientState(
		BaseIngredient.mapToState(props.initialState, cultureCode)
	);
	const data = useBaseIngredientData();

	const putBaseIngredient = async (): Promise<void> => {
		const newState = await BaseIngredientState.save(state, cultureCode);

		handlers.load(newState);
		await updateCache(newState);
	};

	const postBaseIngredient = async (): Promise<void> => {
		const newState = await BaseIngredientState.save(state, cultureCode);
		if (newState.id) {
			await updateCache(state);
			navigate(`/baseIngredient/detail/${newState.id}`);
		}
	};

	const [isLoaded, handleSave] = useIsHandlerLoaded(
		Optional.WrapToJust(state, state.id ? putBaseIngredient : postBaseIngredient)
	);

	const { updateCache } = useAirBlocker(state, handleSave);
	const navigate = useNavigate();

	const tabs: Tab[] = [
		{
			name: t('_general:GENERAL'),
			content: (
				<TabGeneral
					state={state}
					getAndSetSuggestedTags={handlers.getAndSetSuggestedTags}
					setAllergenIds={handlers.setAllergenIds}
					setCalories={handlers.setCalories}
					setCharacteristic={handlers.setCharacteristic}
					setCo2Footprints={handlers.setCo2Footprints}
					setDensity={handlers.setDensity}
					setName={handlers.setName}
					setNutrientValues={handlers.setNutrientValues}
					setScalingFactor={handlers.setScalingFactor}
					setSeasonIds={handlers.setSeasonIds}
					setSelectedCultureCode={handlers.setSelectedCultureCode}
					setTagIds={handlers.setTagIds}
					setUnitWeights={handlers.setUnitWeights}
					translateAndSetName={handlers.translateAndSetName}
					uploadImage={handlers.uploadImage}
				/>
			),
			ai: false,
		},
		{
			name: t('_general:NUTRIENTS'),
			content: (
				<TabNutrients
					state={state}
					setCalories={handlers.setCalories}
					setCategories={handlers.setCategories}
					setHasNutriScore={handlers.setHasNutriScore}
					setNutriScoreCategory={handlers.setNutriScoreCategory}
					setNutriScoreCategoryIsSetByUser={handlers.setNutriScoreCategoryIsSetByUser}
					setNutrientValues={handlers.setNutrientValues}
				/>
			),
			ai: false,
		},
		{
			name: t('_general:CO2_FOOTPRINTS'),
			content: (
				<TabCo2Footprints state={state} setCo2Footprints={handlers.setCo2Footprints} />
			),
			ai: false,
		},
	];
	return (
		<>
			<LoadingAnimation isLoading={!isLoaded || props.isLoading} />
			<BaseIngredientHeader
				allTags={data.tags}
				allSeasons={data.seasons}
				allAllergens={data.allergens}
				state={state}
				cultureCode={'de-CH'}
				handleSave={() => handleSave()}
				id={state.id}
				image={state.image}
				isLoading={!isLoaded || props.isLoading}
				readOnly={false}
			/>
			<br />
			<Tabs
				tabs={tabs.filter((t: Tab) => {
					if (t.display === undefined || t.display === true) {
						return t;
					}
				})}
			/>
		</>
	);
};

export default BaseIngredientComponent;
