import BtnMediaUpload from 'components/desktop/_general/Button/BtnMediaUpload/BtnMediaUpload';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/MediaCarousel/MediaCarousel.scss';
import { MEDIATYPES } from 'enums/mediaTypes';

interface IProps {
	handleUploadMedia: (input: FileList) => Promise<void>;
	renderMediaThumbnails: () => JSX.Element | null;
	renderMediaPreview: () => JSX.Element | null;
	buttonLabel: string;
	accept: MEDIATYPES[];
	disabled?: boolean;
}

const MediaCarousel = (props: IProps) => {
	return (
		<>
			<div id="media-carousel-container">
				<div id="media">{props.renderMediaPreview()}</div>
				<div id="media-sidebar">
					<div id="media-list">{props.renderMediaThumbnails()}</div>
					<RenderIf condition={!props.disabled}>
						<BtnMediaUpload
							handleUploadMedia={props.handleUploadMedia}
							label={props.buttonLabel}
							accept={props.accept}
						/>
					</RenderIf>
				</div>
			</div>
		</>
	);
};

export default MediaCarousel;
