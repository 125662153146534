import { Dispatch, SetStateAction } from 'react';

import { createHandlerAsync } from 'functions/handlers';
import { IAllergenSelectItem } from 'interfaces/IAllergenSelectItem';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';
import * as SliderWithId from 'types/_general/Slider/SliderWithId';
import { ToggleItem } from 'types/_general/ToggleItem';

export const createNutrientsHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) => {
	const handler = createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, data?: { left: number; right: number; id?: string }) => {
			const resultSearch = { ...search };
			if (data?.id) {
				const sliderIndex = resultSearch.sliderNutrient.findIndex((x) => x.id === data.id);
				const resultSliderWithId: SliderWithId.Type | undefined =
					resultSearch.sliderNutrient[sliderIndex];
				if (resultSliderWithId) {
					resultSearch.sliderNutrient[sliderIndex] = {
						...resultSliderWithId,
						left: data?.left ?? null,
						right: data?.right ?? null,
					};
				}
			}
			return await Search.update({
				...resultSearch,
			});
		}
	);
	return (left: number, right: number, id?: string) => handler({ left, right, id });
};

export const createCarbonDioxideHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) => {
	const handler = createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, data?: { left: number; right: number; _id?: string }) => {
			return await Search.update({
				...search,
				sliderCarbonDioxide: {
					...search.sliderCarbonDioxide,
					left: data?.left ?? null,
					right: data?.right ?? null,
				},
			});
		}
	);
	return (left: number, right: number, _id?: string) => handler({ left, right, _id });
};

export const createCaloriesHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) => {
	const handler = createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, data?: { left: number; right: number; _id?: string }) => {
			return await Search.update({
				...search,
				sliderCalories: {
					...search.sliderCalories,
					left: data?.left ?? null,
					right: data?.right ?? null,
				},
			});
		}
	);
	return (left: number, right: number, _id?: string) => handler({ left, right, _id });
};

export const createPriceHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) => {
	const handler = createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, data?: { left: number; right: number; _id?: string }) => {
			return await Search.update({
				...search,
				sliderPrice: {
					...search.sliderPrice,
					left: data?.left ?? null,
					right: data?.right ?? null,
				},
			});
		}
	);
	return (left: number, right: number, _id?: string) => handler({ left, right, _id });
};

export const createStatusHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) => {
	const handler = createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, data?: { toggle: ToggleItem }) => {
			const resultToggleList = search.statusToggleList;
			if (data) {
				const index = resultToggleList.findIndex((x) => x.id === data.toggle.id);
				resultToggleList[index] = data.toggle;
			}
			return await Search.update({
				...search,
				statusToggleList: resultToggleList,
			});
		}
	);
	return (toggle: ToggleItem) => handler({ toggle });
};

export const createResetStatusHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) => {
	const handler = createHandlerAsync(setAutoFillSearch, async (search: Search.Type) => {
		const resultStatusToggleList = Search.resetStatusList(search.statusToggleList);
		return await Search.update({
			...search,

			statusToggleList: resultStatusToggleList,
		});
	});
	return () => handler({});
};

export const createResetAutoFillSearchHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) => {
	const handler = createHandlerAsync(setAutoFillSearch, async (search: Search.Type) => {
		return await Search.update(Search.reset(search));
	});
	return () => handler({});
};

export const createNutriScoreHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) =>
	createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, nutriScoreLabels?: NutriScoreLabel[]) => {
			return await Search.update({
				...search,
				nutriScoreLabels: nutriScoreLabels ?? [],
			});
		}
	);

export const createCo2Handler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) =>
	createHandlerAsync(setAutoFillSearch, async (search: Search.Type, co2Labels?: Co2Label[]) => {
		return await Search.update({
			...search,
			carbonDioxideLabels: co2Labels ?? [],
		});
	});

export const createTagsHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) =>
	createHandlerAsync(setAutoFillSearch, async (search: Search.Type, tags?: ISelectItem[]) => {
		return await Search.update({
			...search,
			tags: tags ?? [],
		});
	});

export const createAllergensHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) =>
	createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, allergens?: IAllergenSelectItem[]) => {
			return await Search.update({
				...search,
				allergens: allergens ?? [],
			});
		}
	);

export const createSeasonsHandler = (setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>) =>
	createHandlerAsync(setAutoFillSearch, async (search: Search.Type, seasons?: ISelectItem[]) => {
		return await Search.update({
			...search,
			seasons: seasons ?? [],
		});
	});

export const createIngredientsHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) =>
	createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, ingredients?: IExclusionLogicSelectItem[]) => {
			return await Search.update({
				...search,
				ingredients: ingredients ?? [],
			});
		}
	);

export const createAccessoriesHandler = (
	setAutoFillSearch: Dispatch<SetStateAction<Search.Type>>
) =>
	createHandlerAsync(
		setAutoFillSearch,
		async (search: Search.Type, accessories?: IExclusionLogicSelectItem[]) => {
			return await Search.update({
				...search,
				accessories: accessories ?? [],
			});
		}
	);
