import Archive from 'containers/desktop/StandingData/ErrorMessage/Archive/ErrorMessageArchive';
import Management from 'containers/desktop/StandingData/ErrorMessage/Management/ErrorMessageManagement';
import PERMISSIONS from 'enums/permissions';

export const errorMessageRoutes = [
	{
		path: '/errorMessage/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/errorMessage/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
