import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SweetAlertResult } from 'sweetalert2';

import * as apiSearch from 'api/season/PostSeasonSearchV1';
import Search from 'classes/StandingData/Season/Search/Search';
import Season from 'classes/StandingData/Season/Search/Season';
import SeasonList from 'classes/StandingData/Season/Search/SeasonList';
import SeasonDetailComponent from 'components/desktop/StandingData/Season/Detail/SeasonDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import StandingDataList from 'components/desktop/_general/StandingDataList/StandingDataList';
import { Row } from 'components/desktop/_general/StandingDataList/StandingDataList';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const SeasonManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [seasonList, setSeasonList] = useState<SeasonList>(new SeasonList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getSeasons();
		} else {
			await getSeasons();
		}
		setSearch(clone(search));
	};

	const getSeasons = async () => {
		setIsLoading(true);
		try {
			const response: apiSearch.ResponseData | null = await seasonList.callApi(
				search.mapToRequest()
			);
			if (response) {
				seasonList.mapFromApi(response);
				setSeasonList(clone(seasonList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getSuggestedSeasons = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await seasonList.search(searchTerm, props.archive ?? false);
		} else {
			seasonList.filtered = [];
		}

		setSeasonList(clone(seasonList));
		return seasonList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getSeasons();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveSeason = async (season: Season): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:SEASON_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await season.callApiDelete();
				await Swal.fire({
					title: t('standingData:SEASON_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getSeasons();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreSeason = async (season: Season): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:SEASON_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await season.callApiRestore();
				await Swal.fire({
					title: t('standingData:SEASON_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getSeasons();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getYesOrNo = (input: boolean): string => {
		if (input) {
			return t('_general:YES');
		} else {
			return t('_general:NO');
		}
	};

	const getButtonDataDelete = (input: Season): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveSeason(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: Season): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreSeason(input),
			};
		}
	};

	const renderButtons = (input: Season): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				delete={getButtonDataDelete(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return seasonList.all.map((season: Season) => {
			return {
				id: season.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="d-flex flex-row" style={{ gap: '0.8em' }}>
							<SvgBig url={season.iconSvgUrl} width={56} height={56} />
						</div>
						<div className="flex-fill d-flex">{season.name}</div>
						<div>
							<span className="grey">{t('standingData:SORT_ORDER')}: </span>
							{season.sortOrder}
						</div>
						<div>
							<span className="grey">{t('standingData:DISPLAY')}: </span>
							{getYesOrNo(season.display)}
						</div>
						<SeasonalityBar seasonalityCode={season.associatedMonthsFlag} />
						<div>{renderButtons(season)}</div>
					</div>
				),
			};
		});
	};

	return (
		<>
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:SEASON_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_SEASON')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:SEASON_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedSeasons}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{seasonList.totalCount + ' ' + t('_general:SEASONS')}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={renderListRows()}
					overlay={<SeasonDetailComponent />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={seasonList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		</>
	);
};

export default SeasonManagementComponent;
