import * as apiDelete from 'api/nutrientCategory/DeleteNutrientCategoryIdV1';
import * as apiRestore from 'api/nutrientCategory/PostNutrientCategoryRestoreV1';
import * as apiPostSearch from 'api/nutrientCategory/PostNutrientCategorySearchV1';

export default class NutrientCategory {
	public id: string = '';
	public name: string = '';
	public sortOrder: number = 0;
	public parentName: string | null = null;

	public constructor(input?: apiPostSearch.ResponseNutrientCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseNutrientCategory) {
		this.id = input.id;
		this.name = input.name;
		this.parentName = input.parentName;
		this.sortOrder = input.sortOrder;
	}
}
