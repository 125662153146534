import SeasonManagementComponent from 'components/desktop/StandingData/Season/Management/SeasonManagementComponent';

const SeasonManagementContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<SeasonManagementComponent />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default SeasonManagementContainer;
