import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<RecipeStatus[]>> {
	const url: string = '/backend-service/Analytics/RecipeStatus';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: RecipeStatus[];
}

export type RecipeStatus = {
	id: string;
	name: string;
	color: string;
	count: number;
};
