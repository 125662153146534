import axios from 'axios';

import { URL_API } from 'config/config';
import { Optional } from 'functions/promiseExtensions';
import IToken from 'interfaces/IToken';
import ITokens from 'interfaces/ITokens';
import { RefreshTokenDto } from 'types/User/RefreshToken/RefreshTokenDto';

const bypassAxiosInstance = axios.create();
bypassAxiosInstance.defaults.baseURL = URL_API;

export async function getUserRefreshToken(refreshToken: IToken): Promise<Optional<ITokens>> {
	const url: string = '/backend-service/User/RefreshToken';

	const postObject: RefreshTokenDto = {
		token: refreshToken.token,
	};

	try {
		const result = await bypassAxiosInstance.post(url, postObject);
		return Optional.Just(result.data as ITokens);
	} catch (error: any) {
		console.error('failed to refresh token', error.message);
		return Optional.None();
	}
}
