import * as api from 'api/nutriScoreIngredientCategoryPoints/GetNutriScoreIngredientCategoryPointsIdV1';
import { IngredientCategoryPoint } from 'types/IngredientCategoryPoints/IngredientCategoryPoint';

export const mapFromRequest = (input: api.ResponseData): IngredientCategoryPoint => {
	return {
		ingredientCategoryId: input.ingredientCategoryId,
		pointType: input.pointType,
		ingredientCategoryName: input.ingredientCategoryName,
		isPositive: input.isPositive,
		points: input.points ?? [],
	};
};
