import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';

export default class CarbonDioxideLabel {
	label: string | null = null;
	color: string | null = null;

	public constructor(input?: api.CarbonDioxideLabel) {
		if (!input) return;
		this.mapFromApi(input);
	}

	public mapFromApi(input: api.CarbonDioxideLabel | null) {
		if (!input) return;
		this.label = input.label;
		this.color = input.color;
	}
}
