import SidebarState from 'types/_general/SidebarState';

export function getSidebarStateFromLocalStorage(): SidebarState {
	const sidebarState: string | null = localStorage.getItem('sidebarState');
	if (sidebarState === 'opened' || sidebarState === 'hovered' || sidebarState === 'closed') {
		return sidebarState;
	} else return 'opened';
}

export function setSidebarStateToLocalStorage(input: SidebarState): void {
	localStorage.setItem('sidebarState', input);
}
