import { t } from 'i18next';

import Dropdown, { DropdownListItem } from 'components/desktop/_general/Dropdown/Dropdown';

interface IProps {
	listFunction: (value: string) => void;
	currentValue: string;
	className?: string;
}

const DropDownPageSize = (props: IProps) => {
	const getDropdownListItems = (): DropdownListItem[] => {
		return [
			{
				name: '12',
				value: '12',
			},
			{
				name: '20',
				value: '20',
			},
			{
				name: '60',
				value: '60',
			},
			{
				name: '100',
				value: '100',
			},
		];
	};

	return (
		<Dropdown
			className={props.className ?? undefined}
			name={t('_general:PAGE_SIZE')}
			listFunction={props.listFunction}
			list={getDropdownListItems()}
			currentValue={props.currentValue}
		/>
	);
};

export default DropDownPageSize;
