import { Synonyms as SynonymType } from 'api/unit/GetUnitIdV1';
import * as apiGet from 'api/unit/GetUnitIdV1';
import * as apiPut from 'api/unit/PutUnitV1';

export class Synonyms {
	public items: SynonymType = {};

	public push(cultureCode: string) {
		if (!this.items[cultureCode]) this.items[cultureCode] = [];
		this.items[cultureCode].push('');
	}

	public update(cultureCode: string, index: number, value: string) {
		this.items[cultureCode][index] = value;
	}

	public delete(cultureCode: string, index: number) {
		this.items[cultureCode].splice(index, 1);
	}

	public get(cultureCode: string): string[] {
		const array: string[] | undefined = this.items[cultureCode];
		if (array) return array;
		return [];
	}

	public mapFromApi(input: apiGet.Synonyms) {
		this.items = input;
	}

	public mapToApi(): apiPut.Synonyms {
		return this.items;
	}
}
