import COLOR from 'enums/COLOR';
import { AllergenLogic } from 'enums/allergenLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

export type Type = {
	id: string;
	name: string;
	icon?: string;
	logic?: AllergenLogic;
};

export function create(): Type {
	return {
		id: '',
		name: '',
	};
}

export function mapToISelectItem(obj: Type): ISelectItem {
	return {
		id: obj.id,
		name: obj.name,
		source: null,
		iconSvgUrl: obj.icon,
	};
}

export function getColorFromLogic(obj: Type): string | undefined {
	if (obj.logic === AllergenLogic.Positive) return COLOR.DANGER;
	return undefined;
}
