import { ContextBuilder } from 'context/nutriscoreLabelContext';

import BaseIngredientSearchComponent from 'components/desktop/BaseIngredient/Search/BaseIngredientSearchComponent';

const BaseIngredientSearchContainer = () => {
	return (
		<main className="container">
			<BaseIngredientSearchComponent />
		</main>
	);
};

export default ContextBuilder.From(BaseIngredientSearchContainer)
	.wrapWithNutriscoreContext()
	.build();
