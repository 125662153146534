import * as apiSW from 'api/season/GetSeasonStartingWithV1';
import * as apiPostSearch from 'api/season/PostSeasonSearchV1';
import Season from 'classes/StandingData/Season/Search/Season';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class SeasonList {
	public all: Season[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const seasons: apiSW.ResponseSeason[] = await this.callApiSW(searchTerm, showOnlyDeleted);
		this.mapFromApiSW(seasons);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseSeason[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const season: Season = new Season(i);
			this.all.push(season);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseSeason[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
