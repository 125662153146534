import * as api from 'api/recipe/PostRecipeSearchV1';
import Allergen from 'classes/Menu/Search/Allergen';

export default class AllergenList {
	public all: Allergen[] = [];

	public constructor(input?: api.Allergen[]) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Allergen[] | null) {
		if (input) {
			for (const i of input) {
				const allergen: Allergen = new Allergen(i);
				this.all.push(allergen);
			}
		}
	}
}
