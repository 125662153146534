import { useTranslation } from 'react-i18next';

import Toggle from 'classes/_general/Search/Toggle/Toggle';
import ToggleList from 'classes/_general/Search/Toggle/ToggleList';

interface IProps {
	toggleList: ToggleList;
	handleChange: () => void;
}

const StatusSelect = (props: IProps) => {
	const { t } = useTranslation();

	const handleToggle = (toggle: Toggle): void => {
		toggle.checked = !toggle.checked;
		if (props.toggleList.everyItemIsFalse()) {
			props.toggleList.reset();
		}
		props.handleChange();
	};

	const renderToggle = (toggle: Toggle) => {
		return (
			<>
				<div className="form-check form-switch">
					<label className="form-check-label" htmlFor={toggle.id}>
						{t(toggle.name)}
					</label>
					<input
						className="form-check-input"
						type="checkbox"
						role="switch"
						id={toggle.id}
						onChange={() => handleToggle(toggle)}
						checked={toggle.checked}
					/>
				</div>
			</>
		);
	};

	return (
		<>
			<div>
				<label>{t('recipe:STATUS')}</label>
				{props.toggleList.all.map((e: Toggle, i: number) => {
					return (
						<div key={i} className="mt-1">
							{renderToggle(e)}
						</div>
					);
				})}
			</div>
		</>
	);
};

export default StatusSelect;
