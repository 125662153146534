import * as api from 'api/menuPlanner/PostMenuPlannerSearchV1';

export default class Row {
	public name: string = '';
	public items: RowItem[] = [];

	public constructor(input?: api.Row) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	mapFromApi(input: api.Row): void {
		this.name = input.name;
		for (const [key, value] of Object.entries(input.items)) {
			this.items.push({
				id: key,
				name: value,
			});
		}
	}
}

export type RowItem = {
	id: string;
	name: string;
};
