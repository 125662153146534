import * as apiStartingWith from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import { ValueScope } from 'enums/valueScope';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as SelectItem from 'types/Product/Search/SelectItem';

export type Type = {
	all: SelectItem.Type[];
	filtered: ISelectItem[];
};

export function create(): Type {
	return {
		all: [],
		filtered: [],
	};
}

export async function initialize(): Promise<Type> {
	return {
		all: await getFromApiStartingWith(),
		filtered: [],
	};
}

export async function getFromApiStartingWith(): Promise<SelectItem.Type[]> {
	const response = await apiStartingWith.callApi('', ValueScope.HundredGrams, false);
	if (response.hasValue()) {
		return mapFromApiStartingWith(response.get());
	}
	return [];
}

function mapFromApiStartingWith(
	input: apiStartingWith.ResponseCarbonDioxideLabel[]
): SelectItem.Type[] {
	const result: SelectItem.Type[] = [];
	for (const i of input) {
		const carbonDioxideLabel: SelectItem.Type = SelectItem.create();
		carbonDioxideLabel.id = i.id;
		carbonDioxideLabel.color = i.color;
		carbonDioxideLabel.label = i.label;
		result.push(carbonDioxideLabel);
	}
	return result;
}
