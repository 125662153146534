import { useTranslation } from 'react-i18next';

import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Status from 'classes/MenuPlan/Detail/Status';
import StatusList from 'classes/MenuPlan/Detail/StatusList';
import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { SelectOption } from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

type InputObject = {
	systemStatus: SystemStatus;
	status: Status;
};

interface IProps {
	object: InputObject;
	statusList: StatusList;
	handleChangeStatus: (status: Status) => void;
	handleChangeSystemStatus: (input: SystemStatus) => void;
	disabled?: boolean;
}

const systemStatusList: SelectOption[] = [
	{
		id: '0',
		text: 'New',
	},
	{
		id: '1',
		text: 'Published',
	},
];

const StatusEditor = (props: IProps) => {
	const { t } = useTranslation();

	const handleStatusChange = (id: string): void => {
		const status: Status | undefined = props.statusList.getById(id);
		if (status) props.handleChangeStatus(status);
	};

	const handleSystemStatusChange = (id: string): void => {
		const status: SelectOption | undefined = systemStatusList.find((e: SelectOption) => {
			return e.id === id;
		});
		if (status) props.handleChangeSystemStatus(status.text as SystemStatus);
	};

	const getSystemStatusFromObject = (): SelectOption | undefined => {
		return systemStatusList.find((e: SelectOption) => {
			return e.text === props.object.systemStatus;
		});
	};

	const isDisabled = (): boolean => {
		if (props.object instanceof MenuPlan) {
			return false;
		}
		return true;
	};

	return (
		<>
			<div className="form-group">
				<label>{t('recipe:STATUS')}</label>
				<div className="input-group">
					<SelectSimple
						options={props.statusList.mapToSelectOptionArray()}
						currentValue={props.object.status?.id ?? null}
						handleValueChange={handleStatusChange}
						disabled={props.disabled || isDisabled()}
						errorKey="Status"
					/>
				</div>
			</div>
			<div className="form-group">
				<label>{t('recipe:SYSTEM_STATUS')}</label>
				<div className="input-group">
					<SelectSimple
						options={systemStatusList.map((status: SelectOption) => ({
							id: status.id,
							text: t('systemStatus:' + status.text),
						}))}
						currentValue={getSystemStatusFromObject()?.id ?? null}
						handleValueChange={handleSystemStatusChange}
						disabled={props.disabled || isDisabled()}
						errorKey="SystemStatus"
					/>
				</div>
			</div>
		</>
	);
};

export default StatusEditor;
