import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(): Promise<Optional<ResponseData>> {
	const url = '/backend-service/IngredientCategory/GetAll';
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	ingredientCategories: ResponseCategory[];
};

export type ResponseCategory = {
	id: string;
	name: string;
	nutriScoreCategory: NutriScoreCategoryIngredientCategory;
};
