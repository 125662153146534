import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import Search from 'classes/MenuPlan/Search/Search';
import SliderWithId from 'classes/_general/Search/SliderWithId/SliderWithId';
import DateSelect from 'components/desktop/MenuPlan/Search/DateSelect';
import Co2Select from 'components/desktop/_general/Co2Select/Co2Select';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import RangeDoubleLabel from 'components/desktop/_general/RangeDoubleLabel/RangeDoubleLabel';
import StatusSelect from 'components/desktop/_general/StatusSelect/StatusSelect';
import ENTITLEMENTS from 'enums/entitlements';
import clone from 'functions/clone';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	advancedSearch: Search;
	setAdvancedSearch: (input: Search) => void;
	handleChangeUrl: () => void;
	handleReset: () => void;
	disabled: boolean;
	renderRanges: boolean;
}

const MenuPlanAdvancedSearch = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const handleChangeMenus = (items: ISelectItem[]) => {
		props.advancedSearch.selectMenu.pushToSaved(items);
		props.handleChangeUrl();
	};
	const handleChangeRecipes = (items: ISelectItem[]) => {
		props.advancedSearch.selectRecipe.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeTags = (items: ISelectItem[]) => {
		props.advancedSearch.selectTag.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeAllergens = (items: ISelectItem[]) => {
		props.advancedSearch.selectAllergen.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeSeasons = (items: ISelectItem[]) => {
		props.advancedSearch.selectSeason.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxideLabels = (items: ResponseCarbonDioxideLabel[]) => {
		props.advancedSearch.selectCo2Label.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeDateStart = (input: Date | null): void => {
		props.advancedSearch.startDateRange.start = input;
		props.handleChangeUrl();
	};

	const handleChangeDateEnd = (input: Date | null): void => {
		props.advancedSearch.startDateRange.end = input;
		props.handleChangeUrl();
	};

	const getSavedMenus = (): ISelectItem[] => {
		return props.advancedSearch.selectMenu.saved;
	};

	const getSavedRecipes = (): ISelectItem[] => {
		return props.advancedSearch.selectRecipe.saved;
	};

	const getSavedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.saved;
	};

	const getSavedSeasons = (): ISelectItem[] => {
		return props.advancedSearch.selectSeason.saved;
	};

	const getSavedAllergens = (): ISelectItem[] => {
		return props.advancedSearch.selectAllergen.saved;
	};

	const getSuggestedMenus = (): ISelectItem[] => {
		return props.advancedSearch.selectMenu.suggested.filtered;
	};

	const getSuggestedRecipes = (): ISelectItem[] => {
		return props.advancedSearch.selectRecipe.suggested.filtered;
	};

	const getSuggestedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.suggested.filtered;
	};

	const getSuggestedAllergens = (): ISelectItem[] => {
		return props.advancedSearch.selectAllergen.suggested.filtered;
	};

	const getSuggestedSeasons = (): ISelectItem[] => {
		return props.advancedSearch.selectSeason.suggested.filtered;
	};

	const setSuggestedMenus = async (input: string): Promise<void> => {
		await props.advancedSearch.selectMenu.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedRecipes = async (input: string): Promise<void> => {
		await props.advancedSearch.selectRecipe.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedTags = async (input: string): Promise<void> => {
		await props.advancedSearch.selectTag.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedAllergens = async (input: string): Promise<void> => {
		await props.advancedSearch.selectAllergen.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedSeasons = async (input: string): Promise<void> => {
		await props.advancedSearch.selectSeason.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const handleChangeCost = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderCost.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderCost.right = right;
		props.advancedSearch.sliderCost.left = left;
		props.handleChangeUrl();
	};

	const handleChangeCalories = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderCalories.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderCalories.right = right;
		props.advancedSearch.sliderCalories.left = left;
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxide = (left: number, right: number, _id?: string): void => {
		if (!props.advancedSearch.sliderCo2.hasSliderChanges(left, right)) return;
		props.advancedSearch.sliderCo2.right = right;
		props.advancedSearch.sliderCo2.left = left;
		props.handleChangeUrl();
	};

	const handleChangeStatus = (): void => {
		props.handleChangeUrl();
	};

	const handleChangeNutrients = (left: number, right: number, id?: string): void => {
		if (id) {
			const sliderWithId: SliderWithId | undefined =
				props.advancedSearch.sliderNutrient.getSlider(id);
			if (sliderWithId) {
				if (!sliderWithId.hasSliderChanges(left, right)) return;
				sliderWithId.right = right;
				sliderWithId.left = left;
				props.handleChangeUrl();
			}
		}
	};

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<div>
					<RenderIf entitlements={[ENTITLEMENTS.MENU]}>
						<MultiSearchSelect
							className="mb-2"
							disabled={props.disabled}
							label={t('_general:MENUS')}
							suggestedItems={getSuggestedMenus()}
							displaySavedItems="top"
							savedItems={getSavedMenus()}
							setSavedItems={handleChangeMenus}
							handleChangeSearchTerm={setSuggestedMenus}
							triggerSearchLetterAmount={0}
							errorKey="Menus"
						/>
					</RenderIf>
					<MultiSearchSelect
						className="mb-2"
						disabled={props.disabled}
						label={t('_general:RECIPES')}
						suggestedItems={getSuggestedRecipes()}
						displaySavedItems="top"
						savedItems={getSavedRecipes()}
						setSavedItems={handleChangeRecipes}
						handleChangeSearchTerm={setSuggestedRecipes}
						triggerSearchLetterAmount={0}
						errorKey="Recipes"
					/>
					<MultiSearchSelect
						className="mb-2"
						disabled={props.disabled}
						label={t('_general:TAGS')}
						suggestedItems={getSuggestedTags()}
						displaySavedItems="top"
						savedItems={getSavedTags()}
						setSavedItems={handleChangeTags}
						handleChangeSearchTerm={setSuggestedTags}
						triggerSearchLetterAmount={0}
						errorKey="Tags"
					/>
					<MultiSearchSelect
						className="mb-2"
						disabled={props.disabled}
						label={t('_general:ALLERGENS')}
						suggestedItems={getSuggestedAllergens()}
						displaySavedItems="top"
						savedItems={getSavedAllergens()}
						setSavedItems={handleChangeAllergens}
						handleChangeSearchTerm={setSuggestedAllergens}
						triggerSearchLetterAmount={0}
						errorKey="Allergens"
					/>
					<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
						<MultiSearchSelect
							className="mb-2"
							disabled={props.disabled}
							label={t('_general:SEASONS')}
							suggestedItems={getSuggestedSeasons()}
							displaySavedItems="top"
							savedItems={getSavedSeasons()}
							setSavedItems={handleChangeSeasons}
							handleChangeSearchTerm={setSuggestedSeasons}
							triggerSearchLetterAmount={0}
							errorKey="Seasons"
						/>
					</RenderIf>
				</div>
				<div className="mt-4">
					<DateSelect
						label={t('menuPlan:START_DATE_START')}
						value={props.advancedSearch.startDateRange.start}
						changeValue={handleChangeDateStart}
					/>
					<DateSelect
						label={t('menuPlan:START_DATE_END')}
						value={props.advancedSearch.startDateRange.end}
						changeValue={handleChangeDateEnd}
					/>
				</div>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		if (props.renderRanges) {
			return (
				<>
					<RangeDoubleLabel
						className="mb-2"
						label={`${t('_general:CALORIES')} (kcal)`}
						min={props.advancedSearch.sliderCalories.getMinRoundDown()}
						max={props.advancedSearch.sliderCalories.getMaxRoundUp()}
						minArea={props.advancedSearch.sliderCalories.minArea ?? 0}
						maxArea={props.advancedSearch.sliderCalories.maxArea ?? 0}
						left={props.advancedSearch.sliderCalories.left ?? undefined}
						right={props.advancedSearch.sliderCalories.right ?? undefined}
						handleChange={handleChangeCalories}
					/>

					{props.advancedSearch.sliderNutrient.all.map((e: SliderWithId, i: number) => {
						return (
							<div key={i}>
								<RangeDoubleLabel
									id={e.id}
									className="mb-2"
									label={`${e.name} (${e.unit})`}
									min={e.getMinRoundDown()}
									max={e.getMaxRoundUp()}
									minArea={e.minArea ?? 0}
									maxArea={e.maxArea ?? 0}
									left={e.left ?? undefined}
									right={e.right ?? undefined}
									handleChange={handleChangeNutrients}
								/>
							</div>
						);
					})}
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<label className="mb-2 mt-2">{`${t('_general:COSTS')} ${t(
							'_general:TOTAL'
						)}`}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={`${t('_general:COSTS')} (${reduxCurrency})`}
							min={props.advancedSearch.sliderCost.getMinRoundDown()}
							max={props.advancedSearch.sliderCost.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderCost.minArea ?? 0}
							maxArea={props.advancedSearch.sliderCost.maxArea ?? 0}
							left={props.advancedSearch.sliderCost.left ?? undefined}
							right={props.advancedSearch.sliderCost.right ?? undefined}
							handleChange={handleChangeCost}
						/>
					</RenderIf>
					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<label className="mb-2 mt-2">{t('_general:CARBON_DIOXIDE')}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={t('_general:CARBON_DIOXIDE') + ' (g)'}
							min={props.advancedSearch.sliderCo2.getMinRoundDown()}
							max={props.advancedSearch.sliderCo2.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderCo2.minArea ?? 0}
							maxArea={props.advancedSearch.sliderCo2.maxArea ?? 0}
							left={props.advancedSearch.sliderCo2.left ?? undefined}
							right={props.advancedSearch.sliderCo2.right ?? undefined}
							handleChange={handleChangeCarbonDioxide}
						/>
					</RenderIf>
				</>
			);
		}
		return <></>;
	};

	const renderCol3 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<StatusSelect
					toggleList={props.advancedSearch.toggleListStatus}
					handleChange={handleChangeStatus}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<Co2Select
						carbonDioxideLabels={props.advancedSearch.selectCo2Label.suggested.all}
						savedItems={props.advancedSearch.selectCo2Label.saved}
						setSavedItems={handleChangeCarbonDioxideLabels}
					/>
				</RenderIf>
			</div>
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-lg-3">{renderCol1()}</div>
				<div className="col-lg-6">{renderCol2()}</div>
				<div className="col-lg-3">{renderCol3()}</div>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{ textAlign: 'end' }}>
					<button className="btn btn-primary" onClick={props.handleReset}>
						{t('_general:RESET')}
					</button>
				</div>
			</div>
		</>
	);
};

export default MenuPlanAdvancedSearch;
