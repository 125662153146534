export enum barChartFillColor {
	BaseIngredient = 'rgba(102, 153, 204, 0.2)',
	Ingredient = 'rgba(0, 243, 58, 0.2)',
	Recipe = 'rgba(243, 0, 0, 0.2)',
}

export enum barChartBorderColor {
	BaseIngredient = 'rgb(102, 153, 204)',
	Ingredient = 'rgb(0, 243, 58)',
	Recipe = 'rgb(243, 0, 0)',
}
