import Archive from 'containers/desktop/StandingData/Status/Archive/StatusArchive';
import Management from 'containers/desktop/StandingData/Status/Management/StatusManagement';
import PERMISSIONS from 'enums/permissions';

export const statusRoutes = [
	{
		path: '/status/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/status/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
