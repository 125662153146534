import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(
	searchTerm: string,
	showOnlyDeleted: boolean
): Promise<Optional<Category[]>> {
	const url: string = '/backend-service/IngredientCategory/TreeViewStartingWith';
	const params: Parameter = {
		SearchTerm: searchTerm,
		ShowOnlyDeleted: showOnlyDeleted,
	};
	return axios.get(url, { params: params }).toOptionalMapped((x) => x.data.ingredientCategories);
}

interface Parameter {
	SearchTerm: string;
	ShowOnlyDeleted: boolean;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	ingredientCategories: Category[];
};

export type Category = {
	id: string;
	name: string;
	parent: string;
	children: Category[];
};
