import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Tag/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	iconSvg: IconSvg | null;
	display: boolean;
	tagCategory: TagCategory;
	nameTranslations: {
		[key: string]: string;
	};
};

export type TagCategory = {
	id: string;
	name: string;
};

export type IconSvg = {
	id: string;
	url: string;
	availableSizes: number[] | null;
};
