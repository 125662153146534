import { UserCornerInfo } from 'types/User/UserCornerInfo/UserCornerInfo';
import { defaultUserCornerInfo } from 'types/User/UserCornerInfo/UserCornerInfo';

export const setReduxUser = (user: UserCornerInfo): UserAction => {
	return {
		type: 'user/SET',
		payload: user,
	};
};

export type UserAction = {
	type: string;
	payload: UserCornerInfo | string;
};

export const userReducer = (
	state = defaultUserCornerInfo(),
	action: UserAction
): UserCornerInfo => {
	switch (action.type) {
		case 'user/SET':
			return Object.assign({ ...state }, action.payload);
		default:
			return state;
	}
};
