import { useTranslation } from 'react-i18next';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { ISelectItem } from 'interfaces/ISelectItem';

type Display = 'top' | 'bottom';

interface IProps {
	savedItems: ISelectItem[];
	displaySavedItems: Display;
	setSavedItems: (savedItems: ISelectItem[]) => void;
	handleChangeSearchTerm: (searchTerm: string) => void;
	suggestedItems: ISelectItem[];
	label?: string;
	backgroundColor?: string;
	triggerSearchLetterAmount?: number;
	className?: string;
	debounceMilliSeconds?: number;
	hideInputField?: boolean;
	disabled?: boolean;
	errorKey?: string;
}

const MultiSearchSelect = (props: IProps) => {
	const { t } = useTranslation();

	const addToSavedItems = (item: ISelectItem) => {
		const check = props.savedItems.find((x) => x.id === item.id);

		if (!check) {
			const items = [...props.savedItems];
			items.push(item);
			props.setSavedItems(items);
		}
	};

	const handleRemoveItem = (id: string) => {
		var savedItemsCpy = [...props.savedItems];
		var index = savedItemsCpy.findIndex((element) => element.id == id);

		savedItemsCpy.splice(index, 1);

		props.setSavedItems(savedItemsCpy);
	};

	const createHandleRemoveItem = (id: string) => {
		return () => {
			var savedItemsCpy = [...props.savedItems];
			var index = savedItemsCpy.findIndex((element) => element.id == id);

			savedItemsCpy.splice(index, 1);

			props.setSavedItems(savedItemsCpy);
		};
	};

	const getSuggestedItems = (): ISelectItem[] => {
		const output = [];

		for (const suggestedItem of props.suggestedItems) {
			let found: boolean = false;
			for (const savedItem of props.savedItems) {
				if (savedItem.id === suggestedItem.id) found = true;
			}
			if (!found) output.push(suggestedItem);
		}
		return output;
	};

	return (
		<>
			<div className={props.className ? 'row ' + props.className : 'row'}>
				{props.label && <label>{props.label}</label>}
				<div className="col-12">
					{props.displaySavedItems === 'top' && (
						<>
							{props.savedItems.map((item) => (
								<div
									className="d-block d-flex justify-content-between select-saveditems"
									key={item.id}
								>
									{item.name}
									<span
										onClick={() => handleRemoveItem(item.id)}
										style={{
											display: props.hideInputField ? 'none' : 'inherit',
										}}
									>
										<i className="bi bi-x" />
									</span>
								</div>
							))}
						</>
					)}
					<div
						className="input-group"
						style={{ display: props.hideInputField ? 'none' : 'flex' }}
					>
						<SuggestionSearchInput
							className="form-control"
							placeholderText={
								props.disabled
									? t('_general:INPUT_PLACEHOLDER_NO_MORE_ENTRIES')
									: t('_general:INPUT_PLACEHOLDER_SEARCHTERM')
							}
							handleChangeSearchTerm={props.handleChangeSearchTerm}
							setSavedItem={addToSavedItems}
							triggerSearchLetterAmount={props.triggerSearchLetterAmount}
							suggestedItems={getSuggestedItems()}
							debounceMilliSeconds={props.debounceMilliSeconds}
							errorKey={props.errorKey}
						/>
					</div>
					{props.displaySavedItems === 'bottom' && (
						<div className="mt-2 overflow-auto">
							{props.savedItems.map((item: ISelectItem, i: number) => (
								<ListItem
									key={i}
									text={item.name}
									title={item.title ?? undefined}
									backgroundColor={props.backgroundColor}
									iconSvgUrl={item.iconSvgUrl}
									handleDelete={
										props.hideInputField
											? undefined
											: createHandleRemoveItem(item.id)
									}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MultiSearchSelect;
