import { ContextBuilder } from 'context/nutriscoreLabelContext';

import RecipeComponent from 'components/desktop/Recipe/RecipeComponent';

const RecipeNewContainer = () => {
	return (
		<main className="container">
			<RecipeComponent statusList={[]} />
		</main>
	);
};

export default ContextBuilder.From(RecipeNewContainer).wrapWithNutriscoreContext().build();
