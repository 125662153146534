import axios from 'axios';

import { BaseIngredientSortOrder } from 'enums/SortOrder/BaseIngredientSortOrder';
import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/backend-service/BaseIngredient/Search';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	allergenIds?: AllergenLogicMap | null;
	caloriesRange?: Range | null;
	carbonDioxideLabelIds?: string[] | null;
	carbonDioxideRange?: Range | null;
	categories?: string[] | null;
	characteristics?: string[] | null;
	nutriScoreLabelIds?: string[] | null;
	nutrientRanges?: NutrientRange[] | null;
	pageIndex?: number | null;
	pageSize?: number | null;
	searchTerm?: string | null;
	seasonIds?: string[] | null;
	showOnlyDeleted?: boolean | null;
	sortOrder?: BaseIngredientSortOrder | null;
	tagIds?: string[] | null;
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	data: ResponseBaseIngredient[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	totalPages: number;
	hasPreviousPage: boolean;
	hasNextPage: boolean;
	minMaxCaloriesRange: Range;
	minMaxCarbonDioxideRange: Range;
	minMaxNutrientRanges: MinMaxNutrientRange[];
};

export type ResponseBaseIngredient = {
	id: string;
	density: number | null;
	calories: number | null;
	characteristic: Characteristic;
	lastEditor: string | null;
	name: string;
	countOfIngredients: number;
	lastEditedDateUtc: string;
	image: Image | null;
	hasNutriScore: boolean;
	nutriScore: NutriScore;
	carbonDioxideValue: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	seasons: Season[];
	allergens: Allergen[];
	tags: Tag[];
	categories: Category[];
};

export type NutrientRange = {
	id: string;
	minimum: number | null;
	maximum: number | null;
};

export type Range = {
	minimum: number | null;
	maximum: number | null;
};

export type MinMaxNutrientRange = {
	nutrientInfo: NutrientInfo;
	range: Range;
};

export type NutrientInfo = {
	id: string;
	sortOrder: number;
	name: string | null;
	unitShortNameSingular: string | null;
	nutrientCategory: NutrientCategory;
};

export type NutrientCategory = {
	id: string;
	name: string | null;
};

export type Image = {
	url: string;
	availableSizes: number[] | null;
};

export type NutriScore = {
	label: string | null;
	color: string | null;
};

export type CarbonDioxideLabel = {
	label: string | null;
	color: string | null;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	associatedMonthsFlag: number | null;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	logic: AllergenLogic;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string | null;
	tagCategory: TagCategory;
};

export type TagCategory = {
	id: string;
	name: string | null;
};

export type Category = {
	id: string;
	name: string | null;
	parentName: string | null;
	nutriScoreCategory: NutriScoreCategory;
};
