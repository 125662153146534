import { useTranslation } from 'react-i18next';

import Dropdown, { DropdownListItem } from 'components/desktop/_general/Dropdown/Dropdown';
import { AccessorySortOrder } from 'enums/SortOrder/AccessorySortOrder';
import { BaseIngredientSortOrder } from 'enums/SortOrder/BaseIngredientSortOrder';
import { IngredientSortOrder } from 'enums/SortOrder/IngredientSortOrder';
import { MenuSortOrder } from 'enums/SortOrder/MenuSortOrder';
import { ProductSortOrder } from 'enums/SortOrder/ProductSortOrder';
import { RecipeSortOrder } from 'enums/SortOrder/RecipeSortOrder';
import ENTITLEMENTS from 'enums/entitlements';
import { SortOrder } from 'enums/sortOrder';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';

interface IProps {
	type:
		| 'accessory'
		| 'ingredient'
		| 'baseIngredient'
		| 'recipe'
		| 'menu'
		| 'menuPlan'
		| 'product';
	listFunction: (value: string) => void;
	currentValue: string;
	className?: string;
	showRelevance?: boolean;
}

const DropdownSortOrder = (props: IProps) => {
	const { t } = useTranslation();

	const getDropdownListItems = (): DropdownListItem[] => {
		if (props.type === 'menuPlan') {
			return [
				{
					name: t('sortOrder:TITLE_ASC'),
					value: SortOrder.TitleAsc,
				},
				{
					name: t('sortOrder:TITLE_DESC'),
					value: SortOrder.TitleDesc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: SortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: SortOrder.LastEditedDateDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: SortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: SortOrder.PriceDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:CALORIES_ASC'),
					value: SortOrder.CaloriesAsc,
				},
				{
					name: t('sortOrder:CALORIES_DESC'),
					value: SortOrder.CaloriesDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: SortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: SortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:STARTING_DATE_ASC'),
					value: SortOrder.StartingDateAsc,
				},
				{
					name: t('sortOrder:STARTING_DATE_DESC'),
					value: SortOrder.StartingDateDesc,
				},
			];
		}
		if (props.type === 'menu') {
			return [
				{
					name: t('sortOrder:NAME_ASC'),
					value: MenuSortOrder.NameAsc,
				},
				{
					name: t('sortOrder:NAME_DESC'),
					value: MenuSortOrder.NameDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: MenuSortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: MenuSortOrder.PriceDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:CALORIES_ASC'),
					value: MenuSortOrder.CaloriesAsc,
				},
				{
					name: t('sortOrder:CALORIES_DESC'),
					value: MenuSortOrder.CaloriesDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: MenuSortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: MenuSortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: MenuSortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: MenuSortOrder.LastEditedDateDesc,
				},
			];
		}
		if (props.type === 'accessory') {
			return [
				{
					name: t('sortOrder:NAME_ASC'),
					value: AccessorySortOrder.NameAsc,
				},
				{
					name: t('sortOrder:NAME_DESC'),
					value: AccessorySortOrder.NameDesc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: AccessorySortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: AccessorySortOrder.LastEditedDateDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: AccessorySortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: AccessorySortOrder.PriceDesc,
							},
					  ]
					: []),
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: AccessorySortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: AccessorySortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:DENSITY_ASC'),
					value: AccessorySortOrder.DensityAsc,
				},
				{
					name: t('sortOrder:DENSITY_DESC'),
					value: AccessorySortOrder.DensityDesc,
				},
			];
		}
		if (props.type === 'ingredient') {
			return [
				{
					name: t('sortOrder:RELEVANCE'),
					value: IngredientSortOrder.Relevance,
				},
				{
					name: t('sortOrder:NAME_ASC'),
					value: SortOrder.NameAsc,
				},
				{
					name: t('sortOrder:NAME_DESC'),
					value: SortOrder.NameDesc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: IngredientSortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: IngredientSortOrder.LastEditedDateDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: IngredientSortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: IngredientSortOrder.PriceDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:CALORIES_ASC'),
					value: IngredientSortOrder.CaloriesAsc,
				},
				{
					name: t('sortOrder:CALORIES_DESC'),
					value: IngredientSortOrder.CaloriesDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: IngredientSortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: IngredientSortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:DENSITY_ASC'),
					value: IngredientSortOrder.DensityAsc,
				},
				{
					name: t('sortOrder:DENSITY_DESC'),
					value: IngredientSortOrder.DensityDesc,
				},
			];
		}
		if (props.type === 'baseIngredient') {
			return [
				{
					name: t('sortOrder:RELEVANCE'),
					value: BaseIngredientSortOrder.Relevance,
				},
				{
					name: t('sortOrder:NAME_ASC'),
					value: SortOrder.NameAsc,
				},
				{
					name: t('sortOrder:NAME_DESC'),
					value: SortOrder.NameDesc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: BaseIngredientSortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: BaseIngredientSortOrder.LastEditedDateDesc,
				},
				{
					name: t('sortOrder:CALORIES_ASC'),
					value: BaseIngredientSortOrder.CaloriesAsc,
				},
				{
					name: t('sortOrder:CALORIES_DESC'),
					value: BaseIngredientSortOrder.CaloriesDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: BaseIngredientSortOrder.CaloriesAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: BaseIngredientSortOrder.CaloriesDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:DENSITY_ASC'),
					value: BaseIngredientSortOrder.DensityAsc,
				},
				{
					name: t('sortOrder:DENSITY_DESC'),
					value: BaseIngredientSortOrder.DensityDesc,
				},
			];
		}
		if (props.type === 'product') {
			return [
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: ProductSortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: ProductSortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: ProductSortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: ProductSortOrder.LastEditedDateDesc,
				},
				{
					name: t('sortOrder:NAME_ASC'),
					value: ProductSortOrder.NameAsc,
				},
				{
					name: t('sortOrder:NAME_DESC'),
					value: ProductSortOrder.NameDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: ProductSortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: ProductSortOrder.PriceDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:RELEVANCE'),
					value: ProductSortOrder.Relevance,
				},
			];
		}
		if (props.type === 'recipe') {
			const output: DropdownListItem[] = [
				{
					name: t('sortOrder:RELEVANCE'),
					value: RecipeSortOrder.Relevance,
				},
				{
					name: t('sortOrder:CALORIES_ASC'),
					value: RecipeSortOrder.CaloriesAsc,
				},
				{
					name: t('sortOrder:CALORIES_DESC'),
					value: RecipeSortOrder.CaloriesDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE])
					? [
							{
								name: t('sortOrder:CARBONDIOXIDE_ASC'),
								value: RecipeSortOrder.CarbonDioxideAsc,
							},
							{
								name: t('sortOrder:CARBONDIOXIDE_DESC'),
								value: RecipeSortOrder.CarbonDioxideDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:LASTEDITEDDATE_ASC'),
					value: RecipeSortOrder.LastEditedDateAsc,
				},
				{
					name: t('sortOrder:LASTEDITEDDATE_DESC'),
					value: RecipeSortOrder.LastEditedDateDesc,
				},
				...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
					? [
							{
								name: t('sortOrder:PRICE_ASC'),
								value: RecipeSortOrder.PriceAsc,
							},
							{
								name: t('sortOrder:PRICE_DESC'),
								value: RecipeSortOrder.PriceDesc,
							},
					  ]
					: []),
				{
					name: t('sortOrder:TITLE_ASC'),
					value: RecipeSortOrder.TitleAsc,
				},
				{
					name: t('sortOrder:TITLE_DESC'),
					value: RecipeSortOrder.TitleDesc,
				},
			];
			if (props.showRelevance) {
				output.push({
					name: t('sortOrder:RECIPEFILTERORDER'),
					value: RecipeSortOrder.RecipeFilterOrder,
				});
			}
			return output;
		}
		return [];
	};

	return (
		<Dropdown
			className={props.className ?? undefined}
			name={t('sortOrder:SORT')}
			listFunction={props.listFunction}
			list={getDropdownListItems()}
			currentValue={props.currentValue}
		/>
	);
};

export default DropdownSortOrder;
