import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(requestBody: Request): Promise<Optional<void>> {
	const url: string = '/backend-service/NutriScoreIngredientCategoryPoints';
	return axios.put(url, requestBody).toOptionalVoid();
}

export type Request = {
	ingredientCategoryId: string;
	pointType: NutriScoreCategoryIngredientCategory;
	points?: Point[];
	isPositive: boolean;
};

export type Point = {
	points: number;
	minValue: number | null;
};
