import SearchResultThumbnailCard from 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnailCard';
import 'components/desktop/_general/SearchResultThumbnail/SearchResultThumbnailCard.scss';
import { ValueScope } from 'enums/valueScope';
import { PrepareTime } from 'types/Recipe/PrepareTime';

export interface ThumbnailCard {
	archive: boolean;
	author: string;
	buttons: JSX.Element;
	calories: number | null;
	currency: string;
	dateLastEdited: string;
	href: string;
	id: string;
	imageUrl: string;
	prepareTime: PrepareTime;
	price: number | null;
	status: string;
	statusColor: string;
	title: string;
	valueScope?: ValueScope;
}

interface IProps {
	data: ThumbnailCard[];
}

const SearchResultThumbnail = (props: IProps) => {
	return (
		<>
			<div className="row gy-4 thumbnail-list">
				{props.data.map((card: ThumbnailCard, i: number) => {
					return (
						<div className="col-xl-4 col-xxl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
							<SearchResultThumbnailCard
								id={card.id}
								archive={card.archive}
								author={card.author}
								imageUrl={card.imageUrl}
								status={card.status}
								statusColor={card.statusColor}
								title={card.title}
								buttons={card.buttons}
								dateLastEdited={card.dateLastEdited}
								calories={card.calories}
								price={card.price}
								valueScope={card.valueScope}
								currency={card.currency}
								prepareTime={card.prepareTime}
								href={card.href}
							/>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default SearchResultThumbnail;
