import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url = `/backend-service/BaseIngredient/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	allergens: Allergen[];
	calories: number;
	carbonDioxide: CarbonDioxide[];
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number;
	categories: Category[];
	characteristic: Characteristic | null;
	density: number;
	hasNutriScore: boolean;
	id: string;
	image: Image | null;
	ingredients: Ingredient[];
	nameTranslations: LocalizedNames;
	nutriScore: NutriScore | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValue[];
	scalingFactor: number;
	seasons: Season[];
	source: string | null;
	tags: Tag[];
	unitWeights: UnitWeight[];
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type NutriScore = {
	label: string;
	color: string;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type LocalizedNames = {
	[key: string]: string;
};
export type NutrientValue = {
	id: string;
	value: number;
	source: string | null;
};

export type Ingredient = {
	id: string;
	name: string;
};

export type UnitWeight = {
	id: string;
	name: string;
	weight: number;
	source: string | null;
};

export type Season = {
	id: string;
	iconSvgUrl: string;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
	sortOrder: number;
	source: string | null;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string;
	name: string;
	display: boolean;
	logic: AllergenLogic;
	source: string | null;
};

export type Tag = {
	id: string;
	iconSvgUrl: string;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
	source: string | null;
};
export type TagCategory = {
	id: string;
	name: string;
};

export type Category = {
	id: string;
	name: string;
	parentName: string;
	nutriScoreCategory: NutriScoreCategory;
	source: string | null;
};

export type CarbonDioxide = {
	productionMethodId: string;
	originLocationId: string;
	origin: string;
	productionMethod: string;
	value: number;
	source: string | null;
};
