import SegmentMediaManagement from 'components/desktop/_general/MediaManagement/SegmentMediaManagement';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';

interface IProps {
	handleDeleteSegmentMedia: (index: number, id: string, video: boolean) => void;
	handleUploadMedia: (index: number, files: File[], video: boolean) => void;
	images: SegmentMedia[];
	index: number;
	readOnly: boolean;
	videos: SegmentMedia[];
}

const SegmentImageView = (props: IProps) => {
	const handleUploadMedia = (input: File[], video: boolean): void => {
		props.handleUploadMedia(props.index, input, video);
	};

	const handleDeleteImage = (id: string): void => {
		props.handleDeleteSegmentMedia(props.index, id, false);
	};

	const handleDeleteVideo = (id: string): void => {
		props.handleDeleteSegmentMedia(props.index, id, true);
	};

	return (
		<div style={{ paddingLeft: '10px', height: '100%' }}>
			<SegmentMediaManagement
				handleDeleteImage={handleDeleteImage}
				handleDeleteVideo={handleDeleteVideo}
				images={props.images}
				readOnly={props.readOnly}
				uploadMedia={handleUploadMedia}
				videos={props.videos}
			/>
		</div>
	);
};

export default SegmentImageView;
