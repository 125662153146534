import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBowlRice } from '@fortawesome/pro-solid-svg-icons';
import { faGrill } from '@fortawesome/pro-solid-svg-icons';
import { faOven } from '@fortawesome/pro-solid-svg-icons';
import { faPanFrying } from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureHalf } from '@fortawesome/pro-solid-svg-icons';
import { faTimer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function getActivityTimeIcon(input: string): JSX.Element {
	switch (input) {
		case 'Backen' || 'Bake':
			return <FontAwesomeIcon icon={faOven as IconProp} />;
		case 'Grillieren' || 'Barbecue':
			return <FontAwesomeIcon icon={faGrill as IconProp} />;
		case 'Braten' || 'Fry':
			return <FontAwesomeIcon icon={faPanFrying as IconProp} />;
		case 'Kochen' || 'Cook':
			return <FontAwesomeIcon icon={faBowlRice as IconProp} />;
		case 'Beizen' || 'Mordant':
			return <FontAwesomeIcon icon={faTemperatureHalf as IconProp} />;
		default:
			return <FontAwesomeIcon icon={faTimer as IconProp} />;
	}
}
