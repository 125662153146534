import { useHandler } from 'hooks/useHandler';

import * as State from './BaseIngredientState';

export default function useBaseIngredientState(initial: State.Type) {
	const [state, rf] = useHandler<State.Type>(initial);
	return {
		state,
		handlers: {
			getAndSetSuggestedTags: rf.createReducerAsync(State.getAndSetSuggestedTags),
			load: rf.createReducer(State.load),
			setAllergenIds: rf.createReducer(State.setAllergenIds),
			setCalories: rf.createReducer(State.setCalories),
			setCategories: rf.createReducer(State.setCategories),
			setCharacteristic: rf.createReducer(State.setCharacteristic),
			setCo2Footprints: rf.createReducer(State.setCo2Footprints),
			setDensity: rf.createReducer(State.setDensity),
			setHasNutriScore: rf.createReducer(State.setHasNutriScore),
			setName: rf.createReducer(State.setName),
			setNutriScoreCategory: rf.createReducer(State.setNutriScoreCategory),
			setNutriScoreCategoryIsSetByUser: rf.createReducer(
				State.setNutriScoreCategoryIsSetByUser
			),
			setNutrientValues: rf.createReducer(State.setNutrientValues),
			setScalingFactor: rf.createReducer(State.setScalingFactor),
			setSeasonIds: rf.createReducer(State.setSeasonIds),
			setSelectedCultureCode: rf.createReducer(State.setSelectedCultureCode),
			setTagIds: rf.createReducer(State.setTagIds),
			setUnitWeights: rf.createReducer(State.setUnitWeights),
			translateAndSetName: rf.createReducerAsync(State.translateAndSetName),
			uploadImage: rf.createReducerAsync(State.uploadImage),
		},
	};
}
