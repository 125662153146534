import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	label: string;
	value: Date | null;
	changeValue: (input: Date | null) => void;
	notNullable?: boolean;
	disabled?: boolean;
}

const DateSelect = (props: IProps) => {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const value: string = e.currentTarget.value;
		const date: Date = new Date(value);
		if (date.toString() !== 'Invalid Date') {
			props.changeValue(date);
		}
	}

	function getInputDate(): string {
		if (props.value) {
			return props.value.toJSON().slice(0, 10);
		}
		return '';
	}

	function reset(): void {
		props.changeValue(null);
	}

	return (
		<>
			<label>{props.label}</label>
			<div className="input-group mb-3">
				<input
					type="date"
					disabled={props.disabled}
					className="form-control"
					value={getInputDate()}
					onChange={handleChange}
				/>
				{!props.notNullable && (
					<span className="input-group-text" id="basic-addon1" onClick={reset}>
						<FontAwesomeIcon icon={faRotate as IconProp} size="lg" />
					</span>
				)}
			</div>
		</>
	);
};

export default DateSelect;
