import MenuSearchComponent from 'components/desktop/Menu/Search/MenuSearchComponent';

const MenuSearchContainer = () => {
	const renderContent = () => {
		return (
			<>
				<main className="container">
					<MenuSearchComponent />
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuSearchContainer;
