import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string, putObject: Request): Promise<Optional<void>> {
	const url: string = `/backend-service/TimeUnit/${id}`;
	return axios.put(url, putObject).toOptionalVoid();
}

export interface Request {
	factorToSecond: number;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	shortNamePluralTranslations: Translation;
	shortNameSingularTranslations: Translation;
}

export type Translation = {
	[key: string]: string;
};
