import * as apiPostMenuSearch from 'api/menu/PostMenuSearchV1';
import * as apiPostAutoFill from 'api/menuPlanner/PostMenuPlannerGetAutoFillV1';
import * as apiPostRecipeSearch from 'api/recipe/PostRecipeSearchV1';
import * as Range from 'types/_general/Range/Range';
import * as SliderWithId from 'types/_general/Slider/SliderWithId';

export type Type = SliderWithId.Type[];

export function reset(input: Type): Type {
	return input.map((e) => {
		return {
			id: e.id,
			left: e.min,
			right: e.max,
			minArea: e.min,
			maxArea: e.max,
			min: e.min,
			max: e.max,
			name: e.name,
			unit: e.unit,
		};
	});
}

export function mapFromApiSpecial(
	allSliders: Type,
	inputMenu: apiPostMenuSearch.MinMaxNutrientRange[],
	inputRecipe: apiPostRecipeSearch.ResponseNutrient[]
): Type {
	const result: Type = [];
	for (const sliderA of inputRecipe.sort(sortByNutrientInfoName)) {
		const slider = allSliders.find((e) => e.id === sliderA.nutrientInfo.id);
		const sliderB = inputMenu.find((e) => e.nutrientInfo.id === sliderA.nutrientInfo.id);
		if (!sliderB) continue;
		const min: number | null = Range.getMinimum(sliderA.range, sliderB.range);
		const max: number | null = Range.getMaximum(sliderA.range, sliderB.range);
		result.push({
			id: sliderA.nutrientInfo.id,
			name: sliderA.nutrientInfo.name,
			unit: sliderA.nutrientInfo.unitShortNameSingular,
			min: slider?.min ?? min,
			max: slider?.max ?? max,
			left: slider?.left ?? null,
			right: slider?.right ?? null,
			minArea: min,
			maxArea: max,
		});
	}
	return [...result];
}

export function mapToApi(input: Type): apiPostAutoFill.NutrientRange[] {
	return input
		.filter((e) => {
			if (e.left === null && e.right === null) return false;
			if (e.min && e.max && e.left && e.right) {
				if (e.left < e.min && e.right > e.max) return false;
			}
			return true;
		})
		.map((e) => {
			return {
				id: e.id,
				minimum: e.left,
				maximum: e.right,
			};
		});
}

function sortByNutrientInfoName<T extends { nutrientInfo: { name: string } }>(a: T, b: T) {
	if (a.nutrientInfo.name < b.nutrientInfo.name) return -1;
	return 1;
}
