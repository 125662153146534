import { getColumnStyle } from 'components/desktop/_general/AirTable/AirTableStyles';
import {
	ColumnInformation,
	ColumnTypeIndex,
} from 'components/desktop/_general/AirTable/AirTableTypes';

export const TableHeaders = (props: {
	columnsIndex: ColumnTypeIndex;
	columns: ColumnInformation[];
}) => (
	<>
		{props.columns.map((col, i) => {
			const stickyClassName = col.sticky ? 'air-table-sticky-column' : '';
			const numericClassName = col.numeric ? 'air-table-numeric-column' : '';
			return (
				<th
					key={i}
					className={`air-table-header-cell ${stickyClassName} ${numericClassName}`}
					style={getColumnStyle(props.columnsIndex[col.key])}
				>
					{`${col.columnName}`}
				</th>
			);
		})}
	</>
);
