import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/MailTemplate/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	name: string;
	emailAccount: {
		id: string;
		senderEmail: string;
	};
	placeholders: string[];
	localizedSubjects: {
		[key: string]: string;
	};
	localizedBodies: {
		[key: string]: string;
	};
};

export const defaultResponseData = (): ResponseData => {
	return {
		id: '',
		name: '',
		emailAccount: {
			id: '',
			senderEmail: '',
		},
		placeholders: [],
		localizedSubjects: {},
		localizedBodies: {},
	};
};
