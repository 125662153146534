import RadioButton from 'components/desktop/_general/RadioButton/RadioButton';
import { Characteristic } from 'enums/characteristic';

interface IProps {
	checked: Characteristic | null;
	disabled?: boolean;
	handleCharacteristicChange: (checked: boolean, elementId: string) => void;
}

const CharacteristicRadioSelect = (props: IProps) => {
	return (
		<>
			<RadioButton
				checked={props.checked === Characteristic.SolidQuantifiable}
				disabled={props.disabled}
				elementId={Characteristic.SolidQuantifiable}
				errorKey="Characteristics"
				handleChange={props.handleCharacteristicChange}
				label={'baseIngredient:SOLID_QUANTIFIABLE'}
			/>
			<RadioButton
				checked={props.checked === Characteristic.SolidNonQuantifiable}
				disabled={props.disabled}
				elementId={Characteristic.SolidNonQuantifiable}
				errorKey="Characteristics"
				handleChange={props.handleCharacteristicChange}
				label={'baseIngredient:SOLID_NON_QUANTIFIABLE'}
			/>
			<RadioButton
				checked={props.checked === Characteristic.Fluid}
				disabled={props.disabled}
				elementId={Characteristic.Fluid}
				errorKey="Characteristics"
				handleChange={props.handleCharacteristicChange}
				label={'baseIngredient:LIQUID'}
			/>
		</>
	);
};

export default CharacteristicRadioSelect;
