import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';

export type Language = {
	id: string;
	cultureCode: string;
	sortOrder: number;
	name: string;
	hasArticleBeforeIngredientName: boolean;
};

export const defaultLanguage = (tryToGetDefaultFromLocalStorage?: boolean): Language => {
	const standardCultureCode: string = 'de-CH';
	const cultureCode: string = tryToGetDefaultFromLocalStorage
		? getCultureCodeFromLocalStorage() ?? standardCultureCode
		: standardCultureCode;
	return {
		id: '',
		cultureCode: cultureCode,
		sortOrder: 0,
		name: '',
		hasArticleBeforeIngredientName: false,
	};
};
