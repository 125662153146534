import { Image } from 'types/Media/Image';

export type UserCornerInfo = {
	firstName: string;
	lastName: string;
	cultureCode: string;
	profilePicture: Image | null;
};

export const defaultUserCornerInfo = (): UserCornerInfo => {
	return {
		firstName: '',
		lastName: '',
		cultureCode: '',
		profilePicture: null,
	};
};
