import { useTranslation } from 'react-i18next';

import 'components/desktop/BaseIngredient/BaseIngredientComponent.scss';
import * as BaseIngredientState from 'components/desktop/BaseIngredient/BaseIngredientState';
import IngredientEditor from 'components/desktop/BaseIngredient/IngredientEditor/IngredientEditor';
import BaseIngredientLanguageInput from 'components/desktop/BaseIngredient/LanguageInput/BaseIngredientLanguageInput';
import UnitWeightEditor from 'components/desktop/BaseIngredient/UnitWeightEditor/UnitWeightEditor';
import useBaseIngredientData from 'components/desktop/BaseIngredient/useBaseIngredientData';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import CharacteristicRadioSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicRadioSelect';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { showConfirmationDialog } from 'components/desktop/_general/ConfirmationDialog/ConfirmationDialog';
import LockableInput from 'components/desktop/_general/Input/LockableInput/LockableInput';
import ItemListEditor from 'components/desktop/_general/ItemListEditor/ItemListEditor';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import PictureBig from 'components/desktop/_general/PictureBig/PictureBig';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import { EAlertType } from 'enums/alertType';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { fireAlert } from 'functions/fireAlert';
import { getPicturePath } from 'functions/getPicturePath';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useCultureCode from 'hooks/redux/useCultureCode';
import { regexValidationCalories } from 'regex/validation/Calories';
import { regexValidationDensity } from 'regex/validation/Density';
import * as Co2List from 'types/BaseIngredient/Detail/Co2List';
import * as NutrientValueList from 'types/BaseIngredient/Detail/NutrientValueList';
import * as Unit from 'types/BaseIngredient/Detail/Unit';
import * as UnitWeightList from 'types/BaseIngredient/Detail/UnitWeightList';
import { Co2Footprint } from 'types/Co2Footprint/Co2Footprint';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';
import * as AllergenList from 'types/_general/Store/AllergenList';
import * as SeasonList from 'types/_general/Store/SeasonList';
import * as UnitList from 'types/_general/Store/UnitList';

interface IProps {
	state: BaseIngredientState.Type;
	getAndSetSuggestedTags: (input?: string | undefined) => Promise<void>;
	setAllergenIds: (input: string[]) => void;
	setCalories: (input: number) => void;
	setCharacteristic: (input: Characteristic) => void;
	setCo2Footprints: (input: Co2List.Type) => void;
	setDensity: (input: number) => void;
	setName: (input: { cultureCode: string; value: string }) => void;
	setNutrientValues: (input: NutrientValueList.Type) => void;
	setScalingFactor: (input: number) => void;
	setSeasonIds: (input: string[]) => void;
	setSelectedCultureCode: (input: string) => void;
	setTagIds: (input: string[]) => void;
	setUnitWeights: (input: UnitWeightList.Type) => void;
	translateAndSetName: (
		input?: { fromCultureCode: string; text: string } | undefined
	) => Promise<void>;
	uploadImage: (input?: FormData | null | undefined) => Promise<void>;
}

const TabGeneral = ({ state, ...props }: IProps) => {
	const { t } = useTranslation();
	const cultureCode: string = useCultureCode();
	const data = useBaseIngredientData();

	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);

	const handleRecalculateNutrients = (
		characteristic: Characteristic,
		nutrientValues: NutrientValue[]
	): Optional<{
		nutrientValue: NutrientValue[];
		calories: number;
		unitWeights: UnitWeight[];
		carbonDioxide: Co2Footprint[];
	}> => {
		const result: NutrientValue[] = [];
		let calories = state.calories;
		let unitWeights = state.unitWeights;
		let carbonDioxide = state.co2Footprints;
		const factor = characteristic === Characteristic.Fluid ? state.density : 1 / state.density;
		for (const nutrientValue of nutrientValues) {
			result.push({
				...nutrientValue,
				value: nutrientValue.value * factor,
			});
		}
		calories = state.calories * factor;
		unitWeights = state.unitWeights.map((x) => ({
			...x,
			weight: x.weight * factor,
		}));
		carbonDioxide = state.co2Footprints.map((x) => ({
			...x,
			value: x.value * factor,
		}));
		fireAlert(
			EAlertType.success,
			t('alerts:ALERT_RECALCULATE_AUTOMATIC_TITLE'),
			t('alerts:ALERT_RECALCULATE_AUTOMATIC_SUCCESS')
		);

		return Optional.Just({
			nutrientValue: result,
			calories,
			unitWeights,
			carbonDioxide: carbonDioxide,
		});
	};

	const setToSolidNonQuantifiable = (recalculate: boolean): void => {
		props.setCharacteristic(Characteristic.SolidNonQuantifiable);
		if (state.characteristic !== Characteristic.Fluid) return;
		if (!recalculate) return;
		handleRecalculateNutrients(Characteristic.SolidNonQuantifiable, state.nutrientValues)
			.do((x) => {
				props.setNutrientValues(x.nutrientValue);
				props.setCalories(x.calories);
				props.setUnitWeights(x.unitWeights);
				props.setCo2Footprints(x.carbonDioxide);
			})
			.orElseDo(() => {
				if (state.characteristic) props.setCharacteristic(state.characteristic);
			});
	};

	const shouldRecalculate = async () => {
		const answer = await showConfirmationDialog(
			t('alerts:ALERT_RECALCULATE'),
			t('alerts:ALERT_RECALCULATE_OK'),
			t('alerts:ALERT_RECALCULATE_NO')
		);

		return answer.isConfirmed;
	};

	const handleCharacteristicChange = async (checked: boolean, elementId: string) => {
		let recalculate: boolean = false;

		switch (elementId) {
			case Characteristic.Fluid:
				if (state.density === 0) {
					fireAlert(
						EAlertType.danger,
						t('alerts:ALERT_INVALID_INPUT_DATA'),
						t('alerts:ALERT_DENSIITY_NOT_VALID')
					);
					break;
				}
				recalculate = await shouldRecalculate();

				if (checked) {
					props.setCharacteristic(Characteristic.Fluid);
					if (recalculate) {
						handleRecalculateNutrients(Characteristic.Fluid, state.nutrientValues)
							.do((x) => {
								props.setNutrientValues(x.nutrientValue);
								props.setCalories(x.calories);
								props.setUnitWeights(x.unitWeights);
								props.setCo2Footprints(x.carbonDioxide);
							})
							.orElseDo(() => {
								if (state.characteristic)
									props.setCharacteristic(state.characteristic);
							});
					}
				}
				break;
			case Characteristic.SolidNonQuantifiable:
				if (checked) {
					if (state.characteristic == Characteristic.Fluid) {
						recalculate = await shouldRecalculate();
						setToSolidNonQuantifiable(recalculate);
					} else {
						setToSolidNonQuantifiable(false);
					}
				}
				break;
			case Characteristic.SolidQuantifiable:
				if (checked) {
					props.setCharacteristic(Characteristic.SolidQuantifiable);
					if (state.characteristic == Characteristic.Fluid) {
						recalculate = await shouldRecalculate();
						if (recalculate) {
							handleRecalculateNutrients(
								Characteristic.SolidQuantifiable,
								state.nutrientValues
							)
								.do((x) => {
									props.setNutrientValues(x.nutrientValue);
									props.setCalories(x.calories);
									props.setUnitWeights(x.unitWeights);
									props.setCo2Footprints(x.carbonDioxide);
								})
								.orElseDo(() => {
									if (state.characteristic)
										props.setCharacteristic(state.characteristic);
								});
						}
					}
				}
				break;
		}
	};

	const renderLanguageSwitch = (): JSX.Element => {
		return (
			<LanguageSwitch
				handleSelectLanguage={(_e: any, selectedLanguage: string) => {
					props.setSelectedCultureCode(selectedLanguage);
				}}
				selectedLanguage={state.selectedCultureCode}
				translatable={true}
				onTranslateClick={() =>
					props.translateAndSetName({
						fromCultureCode: cultureCode,
						text: state.name[cultureCode],
					})
				}
				entryLanguage={cultureCode}
				content={
					<BaseIngredientLanguageInput
						onTranslateClick={() =>
							props.translateAndSetName({
								fromCultureCode: cultureCode,
								text: state.name[cultureCode],
							})
						}
						text={state.name[state.selectedCultureCode] ?? ''}
						selectedLanguage={state.selectedCultureCode}
						handleValueChange={props.setName}
					/>
				}
			/>
		);
	};

	const renderIngredients = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('_general:INGREDIENTS')}
				content={
					<IngredientEditor
						ingredients={state.ingredients}
						disabled={
							!arePermissionsInUserPermissions([PERMISSIONS.WRITEBASEINGREDIENT])
						}
						reader={arePermissionsInUserPermissions([PERMISSIONS.READINGREDIENT])}
					/>
				}
				isInActive={!state.ingredients.length}
			/>
		);
	};

	const renderScalingFactor = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('baseIngredient:SCALING_FACTOR')}
				content={
					<>
						<div className="card-body">
							<LockableInput
								id="scalingFactor"
								label={t('baseIngredient:NON_LINEAR_SCALING_FACTOR')}
								defaultValue={String(state.scalingFactor ?? 0)}
								handleValueChange={(_id, value) => {
									props.setScalingFactor(Number(value));
								}}
								placeholder=""
								withLock={false}
								withTrashCan={false}
								validateRegex={regexValidationCalories}
								disabled={!baseIngredientEditor}
								errorKey="ScalingFactor"
							/>
						</div>
					</>
				}
			/>
		);
	};

	const renderCharacteristics = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('_general:CHARACTERISTICS')}
				content={
					<>
						<div className="card-body">
							<CharacteristicRadioSelect
								checked={state.characteristic}
								disabled={!baseIngredientEditor}
								handleCharacteristicChange={handleCharacteristicChange}
							/>
						</div>
					</>
				}
			/>
		);
	};

	const renderDensity = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('_general:DENSITY')}
				content={
					<>
						<div className="card-body">
							<LockableInput
								id="density"
								label={`${t('_general:DENSITY')} (${t(
									'measurments:GRAMMS_PER_QUBIC_CM'
								)})`}
								defaultValue={String(state.density)}
								handleValueChange={(_id, value) => {
									props.setDensity(Number(value));
								}}
								placeholder=""
								withLock={false}
								withTrashCan={false}
								validateRegex={regexValidationDensity}
								disabled={!baseIngredientEditor}
								errorKey="Density"
							/>
						</div>
					</>
				}
			/>
		);
	};

	const getSuggestedUnitWeight = async (selectedUnit: Unit.Type): Promise<Optional<number>> => {
		const output: number = await Unit.getFromApiSuggested(
			selectedUnit,
			cultureCode,
			state.name,
			state.id,
			state.density,
			state.characteristic
		);
		return Optional.Just(output);
	};

	const renderAllergens = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('_general:ALLERGENS')}
				info={'_general:ALLERGEN_INFO_TOOLTIP'}
				content={
					<div className="card-body">
						<ItemListEditor
							allItems={AllergenList.mapToUniqIdList(data.allergens)}
							itemIds={state.allergenIds}
							setItemIds={props.setAllergenIds}
							noItemsMessage={t('_general:NO_ALLERGENS')}
						/>
					</div>
				}
			/>
		);
	};

	const renderWeight = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('baseIngredient:WEIGHT')}
				content={
					<>
						<div className="card-body">
							<UnitWeightEditor
								id={state.id}
								units={UnitList.mapToUnitLightList(
									UnitList.getByCharacteristic(
										data.units,
										state.characteristic ?? Characteristic.SolidNonQuantifiable
									)
								)}
								baseIngredientCharacteristic={state.characteristic}
								baseIngredientUnitWeights={state.unitWeights}
								setBaseIngredientUnitWeights={props.setUnitWeights}
								getSuggestedUnitWeight={getSuggestedUnitWeight}
								disabled={!baseIngredientEditor}
							/>
						</div>
					</>
				}
			/>
		);
	};

	const renderTags = (): JSX.Element => {
		return (
			<FoldableCard
				defaultOpen={true}
				title={t('_general:TAGS')}
				content={
					<>
						<div className="card-body">
							<ItemListEditor
								allItems={SeasonList.mapToItemList(data.tags)}
								itemIds={state.tagIds}
								setItemIds={props.setTagIds}
								readOnly={!baseIngredientEditor}
								errorKey="Tags"
								noItemsMessage={t('_general:NO_TAGS')}
								handleAiButtonClick={async () =>
									await props.getAndSetSuggestedTags(cultureCode)
								}
							/>
						</div>
					</>
				}
			/>
		);
	};

	const renderSeasons = (): JSX.Element => {
		return (
			<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:SEASONS')}
					content={
						<>
							<div className="card-body">
								<ItemListEditor
									allItems={SeasonList.mapToItemList(data.seasons)}
									itemIds={state.seasonIds}
									setItemIds={props.setSeasonIds}
									readOnly={!baseIngredientEditor}
									errorKey="Seasons"
									noItemsMessage={t('_general:NO_SEASONS')}
								/>
							</div>
						</>
					}
				/>
			</RenderIf>
		);
	};

	return (
		<div className="row mt-4">
			<div className="col-md-4 col-xl-3 col-sm-12 col-12 sidebar">
				<PictureBig path={getPicturePath(state.image, 376)} />
				<div className="margin-top-5">
					<RenderIf condition={baseIngredientEditor}>
						<PictureUpload setFormData={props.uploadImage} />
					</RenderIf>
				</div>
			</div>
			<div className="col-md-8 col-xl-9 col-sm-12 col-12">
				{renderLanguageSwitch()}
				<br />
				<br />
				<div className="row">
					<div className="col-4 d-flex flex-column" style={{ gap: '15px' }}>
						{renderIngredients()}
						{renderScalingFactor()}
						{renderCharacteristics()}
						{renderWeight()}
						{renderDensity()}
						{renderTags()}
					</div>
					<div className="col-4 d-flex flex-column" style={{ gap: '15px' }}>
						{renderAllergens()}
					</div>
					<div className="col-4 d-flex flex-column" style={{ gap: '15px' }}>
						{renderSeasons()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TabGeneral;
