import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/Season';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	iconSvgId: string | null;
	display: boolean;
	associatedMonths: string | null;
	sortOrder: number;
	nameTranslations: {
		[key: string]: string;
	};
}

export interface Response {
	data: string;
}
