import * as api from 'api/menu/PostMenuSearchV1';
import NullableMenuSortOrder from 'classes/Menu/Search/NullableMenuSortOrder';
import AllergenList from 'classes/_general/Search/Allergen/AllergenList';
import Co2Label from 'classes/_general/Search/Co2Label/Co2Label';
import Co2LabelList from 'classes/_general/Search/Co2Label/Co2LabelList';
import { MultiSelect } from 'classes/_general/Search/MultiSelect';
import NullableNumber from 'classes/_general/Search/NullableNumber';
import NullableString from 'classes/_general/Search/NullableString';
import RecipeList from 'classes/_general/Search/Recipe/RecipeList';
import SeasonList from 'classes/_general/Search/Season/SeasonList';
import Slider from 'classes/_general/Search/Slider';
import SliderWithIdList from 'classes/_general/Search/SliderWithId/SliderWithIdList';
import TagList from 'classes/_general/Search/Tag/TagList';
import ToggleList from 'classes/_general/Search/Toggle/ToggleList';
import { MenuSortOrder } from 'enums/SortOrder/MenuSortOrder';
import ENTITLEMENTS from 'enums/entitlements';
import { QueryParams } from 'functions/getQueryParams';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Search {
	public pageIndex: NullableNumber = new NullableNumber('pi', 0);
	public pageSize: NullableNumber = new NullableNumber('ps', 12);
	public searchTerm: NullableString = new NullableString('st');
	public sortOrder: NullableMenuSortOrder = new NullableMenuSortOrder(
		'so',
		MenuSortOrder.NameAsc
	);
	public showOnlyDeleted: boolean = false;

	public selectAllergen: MultiSelect<AllergenList, ISelectItem> = new MultiSelect(
		AllergenList,
		'alg'
	);
	public selectCo2Label: MultiSelect<Co2LabelList, Co2Label> = new MultiSelect(
		Co2LabelList,
		'co2'
	);
	public selectSeason: MultiSelect<SeasonList, ISelectItem> = new MultiSelect(SeasonList, 'sea');
	public selectTag: MultiSelect<TagList, ISelectItem> = new MultiSelect(TagList, 'tag');
	public selectRecipe: MultiSelect<RecipeList, ISelectItem> = new MultiSelect(RecipeList, 'rec');

	public sliderCalories: Slider = new Slider('cal');
	public sliderCo2: Slider = new Slider('co2');
	public sliderCost: Slider = new Slider('cost');
	public sliderNutrient: SliderWithIdList = new SliderWithIdList();

	public toggleListStatus: ToggleList = new ToggleList('stat');

	public async initialize() {
		await this.selectAllergen.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE]))
			await this.selectCo2Label.initialize();
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON]))
			await this.selectSeason.initialize();
		await this.selectTag.initialize();
		await this.selectRecipe.initialize();
		await this.toggleListStatus.initialize();
	}

	public getPageIndex(): number {
		return this.pageIndex.get();
	}

	public getPageSize(): number {
		return this.pageSize.get();
	}

	public getSortOrder(): MenuSortOrder {
		return this.sortOrder.value ?? this.sortOrder.defaultValue;
	}

	public setSearchTerm(input: string) {
		this.searchTerm.value = input;
		this.pageIndex.value = 0;
	}

	public reset() {
		for (const value of Object.values(this)) {
			if (value.reset) {
				value.reset();
			}
		}
	}

	public mapFromApi(input: api.ResponseData) {
		this.sliderCost.mapFromApi(input.minMaxPriceRange);
		this.sliderCo2.mapFromApi(input.minMaxCarbonDioxideRange);
		this.sliderCalories.mapFromApi(input.minMaxCaloriesRange);
		this.sliderNutrient.mapFromApi(input.minMaxNutrientRanges);
	}

	public mapToRequest(): api.Request {
		return {
			caloriesRange: this.sliderCalories.mapToApi(),
			carbonDioxideLabelIds: this.selectCo2Label.mapToApi(),
			carbonDioxideRange: this.sliderCo2.mapToApi(),
			nutrientRanges: this.sliderNutrient.mapToApi(),
			pageIndex: this.pageIndex.mapToApi(),
			pageSize: this.pageSize.mapToApi(),
			priceRange: this.sliderCost.mapToApi(),
			recipeIds: this.selectRecipe.mapToApi(),
			searchTerm: this.searchTerm.mapToApi(),
			seasonIds: this.selectSeason.mapToApi(),
			showOnlyDeleted: this.showOnlyDeleted,
			sortOrder: this.sortOrder.mapToApi(),
			statusIds: this.toggleListStatus.mapToApi(),
			tagIds: this.selectTag.mapToApi(),
		};
	}

	public mapFromUrl(input: QueryParams): void {
		for (const value of Object.values(this)) {
			if (value.mapFromUrl) {
				value.mapFromUrl(input);
			}
		}
	}

	public mapToUrl(): string | null {
		const output: (string | null)[] = [];
		for (const value of Object.values(this)) {
			if (value.mapToUrl) {
				output.push(value.mapToUrl());
			}
		}
		return this.mapUrlArrayToString(output);
	}

	private mapUrlArrayToString(input: (string | null)[]): string {
		const outputFiltered: (string | null)[] = input.filter((e: string | null) => {
			if (e !== null) {
				return e;
			}
		});
		return '?' + outputFiltered.join('&');
	}
}
