import { Synonyms } from 'api/ingredient/GetIngredientIdV1';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { AllergenMappingDto } from 'types/Allergen/AllergenMappingDto';
import { Co2Footprint } from 'types/Co2Footprint/Co2Footprint';
import { IngredientCategoryLight } from 'types/Ingredient/IngredientCategory/IngredientCategoryLight';
import { Image } from 'types/Media/Image';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';
import * as IngredientProduct from 'types/Product/IngredientProduct';
import { SeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { TagMappingDto } from 'types/Tag/TagMappingDto';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';
import { NutriScore, defaultNutriScore } from 'types/_general/NutriScore';

export type Ingredient = {
	[key: string]: any;
	isScalingFactorOverridden: boolean;
	scalingFactor: number | null;
	id: string | null;
	allergens: AllergenMappingDto[];
	baseIngredient: BaseIngredient;
	calories: number | null;
	carbonDioxide: Co2Footprint[];
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number;
	categories: IngredientCategoryLight[];
	characteristic: Characteristic | null;
	density: number | null;
	namePluralTranslations: LocalizedTranslation<string>;
	nameSingularTranslations: LocalizedTranslation<string>;
	hasNutriScore: boolean;
	image: Image | null;
	isAllergenOverridden: boolean;
	isCarbonDioxideOverridden: boolean;
	isSeasonOverridden: boolean;
	isTagOverridden: boolean;
	isUnitWeightOverridden: boolean;
	nutriScore: NutriScore | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValue[];
	price: number | null;
	products: IngredientProduct.Type[];
	seasons: SeasonMappingDto[];
	source: string | null;
	synonyms: Synonyms;
	tags: TagMappingDto[];
	unitWeights: UnitWeight[];
};

export const defaultIngredient = (): Ingredient => {
	return {
		allergens: [],
		baseIngredient: defaultBaseIngredient(),
		calories: null,
		carbonDioxide: [] as Co2Footprint[],
		carbonDioxideLabel: null,
		carbonDioxideValue: 0,
		categories: [] as IngredientCategoryLight[],
		characteristic: null,
		density: null,
		hasNutriScore: true,
		id: '',
		image: null,
		isAllergenOverridden: false,
		isCarbonDioxideOverridden: false,
		isScalingFactorOverridden: false,
		isSeasonOverridden: false,
		isTagOverridden: false,
		isUnitWeightOverridden: false,
		namePluralTranslations: {} as LocalizedTranslation<string>,
		nameSingularTranslations: {} as LocalizedTranslation<string>,
		nutriScore: defaultNutriScore(),
		nutriScoreCategory: null,
		nutriScoreCategoryIsSetByUser: false,
		nutrientValues: [],
		source: null,
		price: null,
		products: [],
		scalingFactor: null,
		seasons: [],
		synonyms: {},
		tags: [],
		unitWeights: [] as UnitWeight[],
	};
};

export type BaseIngredient = {
	[key: string]: any;
	id: string | null;
	allergens: AllergenMappingDto[];
	calories: number | null;
	carbonDioxide: BaseIngredientCarbonDioxide[];
	categories: BaseIngredientCategory[];
	characteristic: Characteristic | null;
	density: number;
	name: string;
	hasNutriScore: boolean;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValue[];
	scalingFactor: number;
	seasons: SeasonMappingDto[];
	source: string | null;
	tags: TagMappingDto[];
	unitWeights: UnitWeight[];
};

export type BaseIngredientCategory = {
	id: string;
	name: string;
	parentName: string;
	nutriScoreCategory: string;
};

export type BaseIngredientCarbonDioxide = {
	originLocationId: string;
	origin: string;
	productionMethodId: string;
	productionMethod: string;
	value: number;
	source: string | null;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export const defaultBaseIngredient = (): BaseIngredient => {
	return {
		id: null,
		allergens: [],
		calories: null,
		carbonDioxide: [],
		categories: [],
		characteristic: Characteristic.SolidNonQuantifiable,
		density: 1,
		name: '',
		hasNutriScore: true,
		nutriScoreCategory: null,
		nutriScoreCategoryIsSetByUser: false,
		nutrientValues: [],
		source: null,
		scalingFactor: 0,
		seasons: [],
		tags: [],
		unitWeights: [] as UnitWeight[],
	};
};
