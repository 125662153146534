import { accessoryRoutes } from 'routing/MasterData/accessoryRoutes';
import { activityRoutes } from 'routing/MasterData/activityRoutes';
import { allergenRoutes } from 'routing/MasterData/allergenRoutes';
import { baseIngredientRoutes } from 'routing/MasterData/baseIngredientRoutes';
import { carbonDioxideLabelRoutes } from 'routing/MasterData/carbonDioxideLabelRoutes';
import { channelRoutes } from 'routing/MasterData/channelRoutes';
import { errorMessageRoutes } from 'routing/MasterData/errorMessageRoutes';
import { externalLogoRoutes } from 'routing/MasterData/externalLogoRoutes';
import { ingredientCategoryRoutes } from 'routing/MasterData/ingredientCategoryRoutes';
import { languageRoutes } from 'routing/MasterData/languageRoutes';
import { mailAccountRoutes } from 'routing/MasterData/mailAccountRoutes';
import { mailTemplateRoutes } from 'routing/MasterData/mailTemplateRoutes';
import { nutriScoreNutrientsRoutes } from 'routing/MasterData/nutriScoreNutrientsRoutes';
import { nutrientCategoryRoutes } from 'routing/MasterData/nutrientCategoryRoutes';
import { nutrientRoutes } from 'routing/MasterData/nutrientRoutes';
import { nutriscoreLabelRoutes } from 'routing/MasterData/nutriscoreLabelRoutes';
import { originLocationRoutes } from 'routing/MasterData/originLocationRoutes';
import { productionMethodRoutes } from 'routing/MasterData/productionMethodRoutes';
import { recipeCategoryRoutes } from 'routing/MasterData/recipeCategoryRoutes';
import { seasonRoutes } from 'routing/MasterData/seasonRoutes';
import { statusRoutes } from 'routing/MasterData/statusRoutes';
import { tagCategoryRoutes } from 'routing/MasterData/tagCategoryRoutes';
import { tagRoutes } from 'routing/MasterData/tagRoutes';
import { timeUnitRoutes } from 'routing/MasterData/timeUnitRoutes';
import { unitRoutes } from 'routing/MasterData/unitRoutes';

import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';

const masterDataRoutes = [
	...accessoryRoutes,
	...activityRoutes,
	...allergenRoutes,
	...baseIngredientRoutes,
	...carbonDioxideLabelRoutes,
	...channelRoutes,
	...errorMessageRoutes,
	...externalLogoRoutes,
	...ingredientCategoryRoutes,
	...languageRoutes,
	...mailAccountRoutes,
	...mailTemplateRoutes,
	...nutriScoreNutrientsRoutes,
	...nutrientCategoryRoutes,
	...nutrientRoutes,
	...nutriscoreLabelRoutes,
	...originLocationRoutes,
	...productionMethodRoutes,
	...recipeCategoryRoutes,
	...seasonRoutes,
	...statusRoutes,
	...tagCategoryRoutes,
	...tagRoutes,
	...timeUnitRoutes,
	...unitRoutes,
];

export default masterDataRoutes.map(wrapProtectedRoute);
