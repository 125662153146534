import { emptyGuid } from 'constants/emptyGuid';
import { Co2Footprint } from 'types/Co2Footprint/Co2Footprint';

export type SaveCo2Footprint = {
	originLocationId: string;
	productionMethodId: string;
	value: number;
};

export const mapCarbonDioxide = (carbonDioxide: Co2Footprint[]): SaveCo2Footprint[] => {
	return carbonDioxide.map(
		(c) =>
			({
				originLocationId: c.originLocationId ? c.originLocationId : emptyGuid,
				productionMethodId: c.productionMethodId ? c.productionMethodId : emptyGuid,
				value: c.value,
			}) as SaveCo2Footprint
	);
};
