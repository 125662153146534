import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { isUserWriter } from 'functions/tokenFunctions';

type Input = {
	points: Point[];
};

export type Point = {
	points: number;
	minValue: number | null;
};

interface IProps<T extends Input> {
	input: T;
	setInput: (input: T) => void;
}

const NutriScorePointsComponent = <T extends Input>(props: IProps<T>) => {
	const { t } = useTranslation();
	const readOnly: boolean = !isUserWriter();

	const addNewPoint = () => {
		const nutriScoreNutrient: T = { ...props.input };
		const point: Point = {
			points: getNextPointValue(),
			minValue: 0,
		};
		nutriScoreNutrient.points.push(point);
		props.setInput(nutriScoreNutrient);
	};

	const getNextPointValue = (): number => {
		let output: number = 0;
		for (const i of props.input.points) {
			if (i.points > 0) output = i.points;
		}
		return output + 1;
	};

	const handleDelete = (index: number) => {
		const nutriScoreNutrient: T = { ...props.input };
		nutriScoreNutrient.points.splice(index, 1);
		props.setInput(nutriScoreNutrient);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		attribute: 'points' | 'minValue',
		index: number
	): void => {
		const nutriScoreNutrient: T = { ...props.input };
		const value: number = parseFloat(e.currentTarget.value);
		nutriScoreNutrient.points[index][attribute] = value;
		props.setInput(nutriScoreNutrient);
	};

	const renderSynonym = (input: Point, key: number): JSX.Element => {
		return (
			<fieldset className="fieldset-white" key={key} style={{ width: '160px' }}>
				<div className="text-end">
					{!readOnly && (
						<FontAwesomeIcon
							icon={faTrashCan}
							className="trash-can"
							onClick={() => handleDelete(key)}
						/>
					)}
				</div>
				<div className="form-group">
					<label>{t('standingData:POINTS')}</label>
					<input
						className="form-control form-control-sm"
						id={String(key)}
						type="number"
						name="singular"
						value={input.points}
						onChange={(e) => handleChange(e, 'points', key)}
					/>
					<label>{t('standingData:MIN_VALUE')}</label>
					<input
						className="form-control form-control-sm"
						id={String(key)}
						type="number"
						name="plural"
						value={input.minValue ?? ''}
						onChange={(e) => handleChange(e, 'minValue', key)}
					/>
				</div>
			</fieldset>
		);
	};

	return (
		<fieldset style={{ minHeight: '4em' }}>
			<div className="d-flex">
				<legend>{t('standingData:NUTRISCORE_POINTS')}</legend>
				<button
					type="button"
					onClick={addNewPoint}
					className="btn btn-outline-primary"
					style={{ marginBottom: '18px', whiteSpace: 'nowrap' }}
				>
					{t('standingData:NEW_NUTRISCORE_POINT')}
				</button>
			</div>
			<div className="d-flex flex-wrap" style={{ gap: '14px' }}>
				{props.input.points.map((point: Point, i: number) => {
					return renderSynonym(point, i);
				})}
			</div>
		</fieldset>
	);
};

export default NutriScorePointsComponent;
