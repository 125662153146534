import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(postObject: Request): Promise<Optional<string>> {
	const url = '/backend-service/ErrorMessage';
	return axios.post(url, postObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	errorKey: string;
	errorTranslations: {
		[key: string]: string;
	};
}

export interface Response {
	data: string;
}
