import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilters } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetNutrients from 'api/nutrient/GetNutrientV1';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Nutrient } from 'types/Nutrient/Nutrient';

interface IProps {
	searchTerm: string;
	onChangeSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
	nutrientsSaved: Nutrient[];
	setNutrientsSaved?: (input: Nutrient[]) => void;
	onChangeFilter: (nutrientsSaved: Nutrient[]) => void;
}

const NutrientSearchFilter = (props: IProps) => {
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [nutrients, setNutrients] = useState<Nutrient[]>([]);
	const [suggestedNutrients, setSuggestedNutrients] = useState<ISelectItem[]>([]);

	const { t } = useTranslation();
	const openOverlay = (): void => setDisplayOverlay(true);
	const closeOverlay = (): void => setDisplayOverlay(false);

	useEffect((): void => {
		getNutrients();
	}, []);

	const mapToISelectItem = (input: Nutrient[]): ISelectItem[] => {
		return input.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				name: nutrient.name,
				title: nutrient.nutrientCategory.name,
			};
		});
	};

	const getNutrients = async () => {
		const nutrients = await apiGetNutrients.callApi();
		nutrients.do((x) => {
			setNutrients(x.slice(1, -1));
			setSuggestedNutrients(mapToISelectItem(x));
		});
	};

	const handleAddNutrient = (items: ISelectItem[]) => {
		const output: Nutrient[] = [];
		for (const i of items) {
			const nutrient: Nutrient | undefined = nutrients.find((e) => e.id === i.id);
			if (nutrient) output.push(nutrient);
		}
		props.setNutrientsSaved && props.setNutrientsSaved([...output]);
		props.onChangeFilter(output);
	};

	const getSuggestedNutrients = (): ISelectItem[] => {
		return suggestedNutrients;
	};

	const getSavedItems = (): ISelectItem[] => {
		return mapToISelectItem(props.nutrientsSaved);
	};

	const getNutrientsStartingWith = (input: string): void => {
		const bla: ISelectItem[] = mapToISelectItem(
			nutrients.filter((e: Nutrient) => {
				if (e.name.toLowerCase().includes(input.toLowerCase())) {
					return e;
				}
			})
		);
		setSuggestedNutrients(bla);
	};

	return (
		<>
			<div className="input-group input-group-sm mb-3">
				<span className="input-group-text">
					<FontAwesomeIcon icon={faSearch as IconProp} />
				</span>
				<input
					type="text"
					className="form-control"
					placeholder={t('recipe:NUTRIENT_SEARCH')}
					value={props.searchTerm}
					onChange={props.onChangeSearchTerm}
				/>
				<span
					className="input-group-text"
					style={{
						cursor: 'pointer',
						color: props.nutrientsSaved.length ? '#6699cc' : 'black',
					}}
					onClick={openOverlay}
				>
					<FontAwesomeIcon icon={faFilters as IconProp} />
					<span style={{ marginLeft: '5px' }}>{t('_general:FILTER')}</span>
				</span>
			</div>
			<Overlay
				contentStyle={{ overflow: 'visible', maxHeight: '100vh', width: '500px' }}
				containerStyle={{ maxHeight: '100vh' }}
				handleClose={closeOverlay}
				displayOverlay={displayOverlay}
			>
				<MultiSearchSelect
					savedItems={getSavedItems()}
					backgroundColor={'#efefef'}
					setSavedItems={handleAddNutrient}
					displaySavedItems="bottom"
					suggestedItems={getSuggestedNutrients()}
					handleChangeSearchTerm={getNutrientsStartingWith}
					label={t('recipe:DEFINE_NUTRIENT_FILTER')}
					triggerSearchLetterAmount={0}
				/>
			</Overlay>
		</>
	);
};

export default NutrientSearchFilter;
