import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(key: string): Promise<Optional<string>> {
	const url: string = `/backend-service/Setting/${key}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: string;
}
