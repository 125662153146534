import * as apiGet from 'api/language/GetLanguageIdV1';
import * as apiPost from 'api/language/PostLanguageV1';
import * as apiPut from 'api/language/PutLanguageIdV1';
import * as apiTranslate from 'api/translation/PostTranslationV1';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export default class Language {
	public id: string = '';
	public cultureCode: string = '';
	public sortOrder: number = 0;
	public isoCode6391: string = '';
	public isoCode6393: string = '';
	public hasArticleBeforeIngredientName: boolean = false;
	public translationCode: string | null = null;
	public nameTranslations: LocalizedTranslation<string> = {};

	public constructor(input?: apiGet.ResponseData) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiTranslation(fromCultureCode: string, toCultureCode: string): Promise<void> {
		const response = await apiTranslate.callApi(
			this.mapToApiTranslate(fromCultureCode, toCultureCode)
		);
		response.hasValue() && this.mapFromApiTranslate(response.get(), toCultureCode);
	}

	public async callApiPut(): Promise<void> {
		await apiPut.callApi(this.id, this.mapToApiPut());
	}

	public async callApiPost(): Promise<void> {
		const response = await apiPost.callApi(this.mapToApiPost());
		response.hasValue() && this.mapFromApiPost(response.get());
	}

	private mapToApiTranslate(
		fromCultureCode: string,
		toCultureCode: string
	): apiTranslate.Request {
		return {
			fromCultureCode: fromCultureCode,
			toCultureCode: toCultureCode,
			text: this.nameTranslations[fromCultureCode],
		};
	}

	private mapToApiPost(): apiPost.Request {
		return {
			cultureCode: this.cultureCode,
			nameTranslations: this.nameTranslations,
			hasArticleBeforeIngredientName: this.hasArticleBeforeIngredientName,
			isoCode6391: this.isoCode6391,
			isoCode6393: this.isoCode6393,
			sortOrder: this.sortOrder,
			translationCode: this.translationCode,
		};
	}

	private mapToApiPut(): apiPut.Request {
		return {
			cultureCode: this.cultureCode,
			nameTranslations: this.nameTranslations,
			hasArticleBeforeIngredientName: this.hasArticleBeforeIngredientName,
			isoCode6391: this.isoCode6391,
			isoCode6393: this.isoCode6393,
			sortOrder: this.sortOrder,
			translationCode: this.translationCode,
		};
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.cultureCode = input.cultureCode;
		this.sortOrder = input.sortOrder;
		this.isoCode6391 = input.isoCode6391;
		this.isoCode6393 = input.isoCode6393;
		this.hasArticleBeforeIngredientName = input.hasArticleBeforeIngredientName;
		this.translationCode = input.translationCode;
		this.nameTranslations = input.nameTranslations;
	}

	private mapFromApiPost(input: string) {
		this.id = input;
	}

	private mapFromApiTranslate(input: string, toCultureCode: string) {
		this.nameTranslations[toCultureCode] = input;
	}
}
