import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/desktop/_general/PictureBig/PictureBig.scss';

interface IProps {
	path: string;
	round?: boolean;
}

const PictureBig = (props: IProps) => {
	if (props.path) {
		return (
			<img
				alt="thumbnail"
				className="img-thumbnail img-thumbnail-custom"
				style={props.round ? { borderRadius: '50%' } : {}}
				src={props.path}
			/>
		);
	} else {
		return (
			<div className="img-thumbnail img-thumbnail-custom">
				<div
					className="picture-big-placeholder d-flex justify-content-center align-items-center"
					style={props.round ? { borderRadius: '50%' } : {}}
				>
					<FontAwesomeIcon size="5x" icon={faCamera} style={{ color: 'white' }} />
				</div>
			</div>
		);
	}
};

export default PictureBig;
