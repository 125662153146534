import Archive from 'containers/desktop/StandingData/AccessoryCategory/Archive/AccessoryCategoryArchive';
import Management from 'containers/desktop/StandingData/AccessoryCategory/Management/AccessoryCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const accessoryRoutes = [
	{
		path: '/accessoryCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/accessoryCategory/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
