export enum ProductSortOrder {
	CarbonDioxideAsc = 'CarbonDioxideAsc',
	CarbonDioxideDesc = 'CarbonDioxideDesc',
	LastEditedDateAsc = 'LastEditedDateAsc',
	LastEditedDateDesc = 'LastEditedDateDesc',
	NameAsc = 'NameAsc',
	NameDesc = 'NameDesc',
	PriceAsc = 'PriceAsc',
	PriceDesc = 'PriceDesc',
	Relevance = 'Relevance',
}
