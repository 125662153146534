import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/desktop/Ingredient/IngredientProducts/ProductAddCard/ProductAddCard.scss';

interface IProps {
	handleAdd: () => void;
}

const ProductAddCard = (props: IProps) => {
	return (
		<div className="card product-card">
			<div className="card-body text-center">
				<div className="add-button-container">
					<FontAwesomeIcon
						className="add-button"
						icon={faCirclePlus}
						size="10x"
						onClick={props.handleAdd}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductAddCard;
