export enum MatchStatus {
	Unrecognized = 'Unrecognized',
	Recognized = 'Recognized',
	RecognizedLegacy = 'RecognizedLegacy',
	PartiallyRecognized = 'PartiallyRecognized',
}

export const mapMatchStatusFromString = (
	x: 'Unrecognized' | 'Recognized' | 'PartiallyRecognized' | 'RecognizedLegacy'
): MatchStatus => {
	return x as any;
};
