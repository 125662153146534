import * as apiGet from 'api/tag/GetTagIdV1';

export default class TagCategory {
	public id: string = '';
	public name: string = '';

	public constructor(input?: apiGet.TagCategory) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: apiGet.TagCategory): void {
		this.id = input.id;
		this.name = input.name;
	}
}
