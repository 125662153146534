import * as apiDelete from 'api/language/DeleteLanguageIdV1';
import * as apiRestore from 'api/language/PostLanguageRestoreV1';
import * as apiPostSearch from 'api/language/PostLanguageSearchV1';

export default class Language {
	public cultureCode: string = '';
	public name: string = '';
	public id: string = '';
	public sortOrder: number = 0;

	public constructor(input?: apiPostSearch.ResponseLanguage) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseLanguage) {
		this.cultureCode = input.cultureCode;
		this.name = input.name;
		this.id = input.id;
		this.sortOrder = input.sortOrder;
	}
}
