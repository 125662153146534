import { ContextBuilder } from 'context/nutriscoreLabelContext';

import RecipeSearchComponent from 'components/desktop/Recipe/Search/RecipeSearchComponent';

const RecipeSearchContainer = () => {
	return (
		<main className="container">
			<RecipeSearchComponent />
		</main>
	);
};

export default ContextBuilder.From(RecipeSearchContainer).wrapWithNutriscoreContext().build();
