import * as apiSW from 'api/tag/GetTagStartingWithV1';
import * as apiPostSearch from 'api/tag/PostTagSearchV1';
import Tag from 'classes/StandingData/Tag/Search/Tag';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class TagList {
	public all: Tag[] = [];
	public filtered: ISelectItem[] = [];
	public totalCount: number = 0;

	public async search(searchTerm: string, showOnlyDeleted: boolean): Promise<void> {
		const tags: apiSW.ResponseTag[] = await this.callApiSW(searchTerm, showOnlyDeleted);
		this.mapFromApiSW(tags);
	}

	public async callApi(input: apiPostSearch.Request): Promise<apiPostSearch.ResponseData | null> {
		const response = await apiPostSearch.callApi(input);
		return response.toNullable();
	}

	private async callApiSW(
		searchTerm: string,
		showOnlyDeleted: boolean
	): Promise<apiSW.ResponseTag[]> {
		const response = await apiSW.callApi(searchTerm, showOnlyDeleted);
		return response.getOrDefault([]);
	}

	public mapFromApi(input: apiPostSearch.ResponseData): void {
		this.totalCount = input.totalCount;
		this.all = [];
		for (const i of input.data) {
			const tag: Tag = new Tag(i);
			this.all.push(tag);
		}
	}

	public mapFromApiSW(input: apiSW.ResponseTag[]): void {
		this.filtered = [];
		for (const i of input) {
			this.filtered.push({
				id: i.id,
				name: i.name,
			});
		}
	}
}
