import NutrientManagementComponent from 'components/desktop/StandingData/Nutrient/Management/NutrientManagementComponent';

const NutrientArchiveContainer = () => {
	const renderContent = () => {
		return (
			<main className="container">
				<NutrientManagementComponent archive={true} />
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientArchiveContainer;
