import * as apiDelete from 'api/tag/DeleteTagIdV1';
import * as apiRestore from 'api/tag/PostTagRestoreV1';
import * as apiPostSearch from 'api/tag/PostTagSearchV1';

export default class Tag {
	public id: string = '';
	public iconSvgUrl: string | null = null;
	public display: boolean = false;
	public name: string = '';

	public constructor(input?: apiPostSearch.ResponseTag) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public async callApiDelete(): Promise<void> {
		await apiDelete.callApi(this.id);
	}

	public async callApiRestore(): Promise<void> {
		await apiRestore.callApi(this.id);
	}

	private mapFromApi(input: apiPostSearch.ResponseTag) {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl;
		this.display = input.display;
		this.name = input.name;
	}
}
