import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faChessBoard, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Ingredient from 'classes/Ingredient/Search/Ingredient';
import Search from 'classes/Recipe/Search/Search';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import PERMISSIONS from 'enums/permissions';
import { SearchLogic } from 'enums/searchLogic';
import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { RootState } from 'reducers/rootReducer';
import { Icon } from 'types/Icon/Icon';

interface IProps {
	archive: boolean;
	handleDeleteIngredient: (input: Ingredient) => Promise<void>;
	handleRestoreIngredient: (input: Ingredient) => Promise<void>;
	ingredient: Ingredient;
}

const SearchResultListRow = (props: IProps) => {
	const navigate = useNavigate();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const onRecipeLinkClick = async (id: string, name: string, e: any) => {
		e.preventDefault();
		const recipeAdvancedSearch = new Search();
		recipeAdvancedSearch.selectIngredient.saved = [
			{ id: id, name: name, logic: SearchLogic.Include } as IExclusionLogicSelectItem,
		];
		const url: string | null = recipeAdvancedSearch.mapToUrl();
		if (url) navigate(`/recipe/search${url}`);
	};

	const getValueScope = (): string => {
		return getValueScopeLabel(ValueScope.HundredGrams, props.ingredient.characteristic, true);
	};

	const getButtonDataDelete = (): BtnData | undefined => {
		if (!props.archive && ingredientEditor) {
			return {
				onClick: () => props.handleDeleteIngredient(props.ingredient),
			};
		}
	};

	const getButtonDataEdit = (): BtnData | undefined => {
		if (!props.archive && ingredientEditor) {
			return {
				onClick: handleIngredientDetailClick,
			};
		}
	};

	const getButtonDataView = (): BtnData | undefined => {
		if (!props.archive && !ingredientEditor) {
			return {
				onClick: handleIngredientDetailClick,
			};
		}
	};

	const getButtonDataRestore = (): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => props.handleRestoreIngredient(props.ingredient),
			};
		}
	};

	const renderButtons = (): JSX.Element => {
		return (
			<BtnGroup
				delete={getButtonDataDelete()}
				edit={getButtonDataEdit()}
				restore={getButtonDataRestore()}
				view={getButtonDataView()}
			/>
		);
	};

	const handleIngredientDetailClick = (): void => {
		navigate(`/ingredient/detail/${props.ingredient.id}`);
	};

	const getAuthor = (): string => {
		if (props.ingredient.lastEditor) {
			return props.ingredient.lastEditor;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const getRecipeLink = (): JSX.Element => {
		if (props.ingredient.countOfRecipes > 0) {
			if (arePermissionsInUserPermissions([PERMISSIONS.READRECIPE])) {
				return (
					<span
						className="primary"
						onClick={(e: any) =>
							onRecipeLinkClick(
								props.ingredient.id,
								props.ingredient.nameSingularTranslation,
								e
							)
						}
						style={{ zIndex: 1000 }}
					>
						{`${props.ingredient.countOfRecipes} ${t('_general:RECIPE', {
							count: props.ingredient.countOfRecipes,
						})}`}
					</span>
				);
			} else {
				return (
					<span className="primary">
						{`${props.ingredient.countOfRecipes} ${t('_general:RECIPE', {
							count: props.ingredient.countOfRecipes,
						})}`}
					</span>
				);
			}
		}
		return <>{`0 ${t('_general:RECIPES', { count: props.ingredient.countOfRecipes })}`}</>;
	};

	const getPicture = (): JSX.Element => {
		if (props.ingredient.image) {
			return (
				<div className="card-picture">
					<img alt="thumbnail" src={props.ingredient.image.url} />
				</div>
			);
		} else {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		}
	};

	const getCalories = (): JSX.Element => {
		if (props.ingredient.calories) {
			return (
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faUtensils as IconProp} />
					</span>
					<b>{getNumberString(props.ingredient.calories, reduxCultureCode, true)}</b>
					{' ' + t('_general:CALORIES') + getValueScope()}
				</p>
			);
		}
		return <></>;
	};

	const getDensity = (): JSX.Element => {
		if (props.ingredient.density) {
			return (
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faChessBoard as IconProp} />
					</span>
					<b>{getNumberString(props.ingredient.density, reduxCultureCode)}</b>
					{' ' + t('measurments:GRAMMS_PER_QUBIC_CM')}
				</p>
			);
		}
		return <></>;
	};

	const getIcons = (): Icon[] => {
		const output: (Icon | null)[] = [];
		output.push(...props.ingredient.tags.mapToIcons());
		output.push(...props.ingredient.allergens.mapToIcons());
		return output.filter((x) => x?.iconSvgUrl).map((x) => x as Icon);
	};

	if (props.archive) {
		return (
			<div className="recipe-table-item" key={props.ingredient.nameSingularTranslation}>
				{getPicture()}

				<div id="column-first">
					<div id="title">
						<strong>{props.ingredient.nameSingularTranslation}</strong>
					</div>
					<div id="info">
						{getCalories()}
						{getDensity()}
					</div>
				</div>
				<div id="column-second">
					<div className="icon-column justify-content-start">
						<SeasonalityBar
							seasonalityCode={props.ingredient.seasons.getSeasonalityCode()}
						/>
					</div>
					<div className="row">
						<div className="col-5">
							<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
							<br />
							<span className="grey">{t('_general:LAST_EDITOR')}:</span>
							<br />
						</div>
						<div className="col-7">
							<FormatedDate date={props.ingredient.lastEditedDateUtc} />
							<br />
							<span>{getAuthor()}</span>
						</div>
					</div>
				</div>
				<div id="column-last">
					{props.ingredient.hasNutriScore && props.ingredient.nutriScore && (
						<div className="justify-content-end">
							<NutriScoreDiagram nutriScore={props.ingredient.nutriScore} />
						</div>
					)}
					<div id="icon-rows">
						{getIcons()
							.slice(0, 4)
							.map((icon: Icon, i: number) => (
								<IconComponent icon={icon} key={i} />
							))}
						<IconCo2Round
							color={props.ingredient.carbonDioxideLabel.color!}
							label={props.ingredient.carbonDioxideLabel.label!}
						/>
					</div>
					<div>{renderButtons()}</div>
				</div>
			</div>
		);
	} else {
		return (
			<Link to={`/ingredient/detail/${props.ingredient.id}`} className="unstyled-link">
				<div className="recipe-table-item">
					{getPicture()}

					<div id="column-first">
						<div id="title">
							<strong>{props.ingredient.nameSingularTranslation}</strong>
						</div>
						<div id="info">
							{getRecipeLink()}
							<br />
							{getCalories()}
							{getDensity()}
						</div>
					</div>
					<div id="column-second">
						<div className="icon-column justify-content-start">
							<SeasonalityBar
								seasonalityCode={props.ingredient.seasons.getSeasonalityCode()}
							/>
						</div>
						<div className="row">
							<div className="col-5">
								<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
								<br />
								<span className="grey">{t('_general:LAST_EDITOR')}:</span>
								<br />
							</div>
							<div className="col-7">
								<FormatedDate date={props.ingredient.lastEditedDateUtc} />
								<br />
								<span>{getAuthor()}</span>
							</div>
						</div>
					</div>
					<div id="column-last">
						{props.ingredient.hasNutriScore && props.ingredient.nutriScore && (
							<div className="justify-content-end">
								<NutriScoreDiagram nutriScore={props.ingredient.nutriScore} />
							</div>
						)}
						<div id="icon-rows">
							{getIcons()
								.slice(0, 4)
								.map((icon: Icon, i: number) => (
									<IconComponent icon={icon} key={i} />
								))}
							<IconCo2Round
								color={props.ingredient.carbonDioxideLabel.color!}
								label={props.ingredient.carbonDioxideLabel.label!}
							/>
						</div>
						<div>{renderButtons()}</div>
					</div>
				</div>
			</Link>
		);
	}
};

export default SearchResultListRow;
