import { useTranslation } from 'react-i18next';

import ExclusionLogicSearchInput from 'components/desktop/_general/Input/ExclusionLogicSearchInput';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import { SearchLogic } from 'enums/searchLogic';
import { ISelectItem } from 'interfaces/ISelectItem';

type Display = 'top' | 'bottom';

interface IExclusionLogicSelectItem extends ISelectItem {
	logic: SearchLogic;
}

interface IProps {
	backgroundColor?: string;
	className?: string;
	debounceMilliSeconds?: number;
	disabled?: boolean;
	displaySavedItems: Display;
	errorKey?: string;
	handleChangeSearchTerm: (searchTerm: string) => void;
	label?: string;
	savedItems: IExclusionLogicSelectItem[];
	setSavedItems: (savedItems: IExclusionLogicSelectItem[]) => void;
	suggestedItems: ISelectItem[];
	triggerSearchLetterAmount?: number;
}

const ExclusionLogicMultiSearchSelect = (props: IProps) => {
	const { t } = useTranslation();

	const addToSavedItems = (item: IExclusionLogicSelectItem): void => {
		const check = props.savedItems.find((x) => x.id === item.id);

		if (!check) {
			const items = [...props.savedItems];
			items.push(item);
			props.setSavedItems(items);
		}
	};

	const handleRemoveItem = (id: string) => {
		var savedItemsCpy = [...props.savedItems];
		var index = savedItemsCpy.findIndex((element) => element.id == id);

		savedItemsCpy.splice(index, 1);

		props.setSavedItems(savedItemsCpy);
	};

	const createHandleRemoveItem = (id: string) => {
		return () => {
			var savedItemsCpy = [...props.savedItems];
			var index = savedItemsCpy.findIndex((element) => element.id == id);

			savedItemsCpy.splice(index, 1);
			props.setSavedItems(savedItemsCpy);
		};
	};

	const getSuggestedItems = (): ISelectItem[] => {
		const output: ISelectItem[] = [];

		for (const suggestedItem of props.suggestedItems) {
			let found: boolean = false;
			for (const savedItem of props.savedItems) {
				if (savedItem.id === suggestedItem.id) found = true;
			}
			if (!found) output.push(suggestedItem);
		}
		return output;
	};

	const getSavedItemClassName = (item: any): string => {
		let output: string = 'd-flex justify-content-between select-saveditems';
		if (item.logic == SearchLogic.Exclude) {
			output = output + ' background-red';
		}
		return output;
	};

	return (
		<div className={['row', props.className ?? ''].join(' ')}>
			{props.label && <label>{props.label}</label>}
			<div className="col-12">
				{props.displaySavedItems === 'top' && (
					<>
						{props.savedItems.map((item: any) => (
							<div className={getSavedItemClassName(item)} key={item.id}>
								{item.name}
								<span onClick={() => handleRemoveItem(item.id)}>
									<i className="bi bi-x" />
								</span>
							</div>
						))}
					</>
				)}
				<div className="input-group">
					<ExclusionLogicSearchInput
						className="form-control"
						placeholderText={
							props.disabled
								? t('_general:INPUT_PLACEHOLDER_NO_MORE_ENTRIES')
								: t('_general:INPUT_PLACEHOLDER_SEARCHTERM')
						}
						handleChangeSearchTerm={props.handleChangeSearchTerm}
						setSavedItem={addToSavedItems}
						triggerSearchLetterAmount={props.triggerSearchLetterAmount}
						suggestedItems={getSuggestedItems()}
						debouncedMilliSeconds={props.debounceMilliSeconds}
						errorKey={props.errorKey}
					/>
				</div>
				{props.displaySavedItems === 'bottom' && (
					<div className="mt-2 overflow-auto">
						{props.savedItems.map((item: any, i: number) => (
							<ListItem
								key={i}
								text={item.name}
								title={item.title ?? undefined}
								backgroundColor={props.backgroundColor}
								iconSvgUrl={item.iconSvgUrl}
								handleDelete={
									props.disabled ? undefined : createHandleRemoveItem(item.id)
								}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default ExclusionLogicMultiSearchSelect;
