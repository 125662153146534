export enum MEDIACOLLECTION {
	Accessory = 'Accessory',
	BaseIngredient = 'BaseIngredient',
	Icon = 'Icon',
	Ingredient = 'Ingredient',
	Recipe = 'Recipe',
	RecipeSegment = 'RecipeSegment',
	RecipeSegmentVideo = 'RecipeSegmentVideo',
	RecipeVideo = 'RecipeVideo',
	User = 'User',
}
