import * as api from 'api/menuPlanner/GetMenuPlannerIdV1';
import TagCategory from 'classes/MenuPlan/Detail/TagCategory';
import { ISelectItem } from 'interfaces/ISelectItem';

export default class Tag {
	id: string = '';
	iconSvgUrl: string | null = null;
	display: boolean = false;
	name: string | null = null;
	tagCategory: TagCategory = new TagCategory();
	source: string | null = null;

	public constructor(input?: api.Tag) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	public mapFromApi(input: api.Tag): void {
		this.id = input.id;
		this.iconSvgUrl = input.iconSvgUrl;
		this.display = input.display;
		this.name = input.name;
		this.tagCategory.mapFromApi(input.tagCategory);
		this.source = input.source;
	}

	public mapToApi(): api.Tag {
		return {
			id: this.id,
			iconSvgUrl: this.iconSvgUrl,
			display: this.display,
			name: this.name,
			tagCategory: this.tagCategory,
			source: this.source,
		};
	}

	public mapToISelectItem(): ISelectItem {
		return {
			id: this.id,
			name: this.name ?? '',
			iconSvgUrl: this.iconSvgUrl ?? '',
			display: this.display,
		};
	}
}
