import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface IProps {
	setDisplayOverlay: (input: boolean) => void;
	id?: string;
}

const BtnDownloadPdf = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<button
			type="button"
			style={{ marginRight: '10px', display: props.id ? undefined : 'none' }}
			className="btn btn-outline-primary"
			onClick={() => props.setDisplayOverlay(true)}
		>
			<FontAwesomeIcon icon={faFilePdf as IconProp} />
			<span style={{ paddingLeft: '6px' }}>{t('_general:DOWNLOAD')}</span>
		</button>
	);
};

export default BtnDownloadPdf;
