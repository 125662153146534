import React, { useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MailTemplate } from 'types/MailTemplate/MailTemplate';

interface IProps {
	mailTemplate: MailTemplate;
	selectedLanguage: string;
	handleSubjectValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleBodyValueChange: (value: string) => void;
}

export function MailTemplateLanguageInput(props: IProps) {
	const { t } = useTranslation();
	const textareaRef: any = useRef<HTMLTextAreaElement>(null);
	const [cursorPositionStart, setCursorPositionStart] = useState<number>(0);
	const [cursorPositionEnd, setCursorPositionEnd] = useState<number>(0);

	const setNewCursorPosition = (): void => {
		const currentCursorPositionStart: number = textareaRef.current.selectionStart;
		const currentCursorPositionEnd: number = textareaRef.current.selectionEnd;
		setCursorPositionStart(currentCursorPositionStart);
		setCursorPositionEnd(currentCursorPositionEnd);
	};

	const changeText = (e: any): void => {
		const value: string = e.target.value;
		setNewCursorPosition();
		props.handleBodyValueChange(value);
	};

	const selectPlaceholder = (input: string): void => {
		const body: string = props.mailTemplate.localizedBodies[props.selectedLanguage];
		const newTextBefore: string = body.slice(0, cursorPositionStart);
		const newTextAfter: string = body.slice(cursorPositionEnd);
		const newText: string = newTextBefore + input + newTextAfter;
		props.handleBodyValueChange(newText);
	};

	const renderPlaceholderSelect = (): JSX.Element => {
		return (
			<select className="form-select" multiple aria-label="multiple select example">
				{props.mailTemplate.placeholders.map((e: string, i: number) => {
					return (
						<option key={i} onDoubleClick={() => selectPlaceholder(e)}>
							{e}
						</option>
					);
				})}
			</select>
		);
	};

	const hasPlaceholders = (): boolean => {
		return Boolean(props.mailTemplate.placeholders.length);
	};

	const renderTextEditor = () => {
		return (
			<>
				<div className="row mt-3" style={{ minWidth: '850px' }}>
					<div className={'col-' + (hasPlaceholders() ? 9 : 12) + ' mb-3'}>
						<label htmlFor="formControlTextarea1">{t('standingData:TEMPLATE')}</label>
						<textarea
							ref={textareaRef}
							className="form-control"
							id="formControlTextarea1"
							rows={10}
							value={props.mailTemplate.localizedBodies[props.selectedLanguage] || ''}
							onChange={changeText}
							onClick={setNewCursorPosition}
							onKeyUp={setNewCursorPosition}
							error-key={'LocalizedBodies'}
						></textarea>
					</div>
					{hasPlaceholders() && (
						<div className="col-3">
							<label>{t('standingData:PLACEHOLDERS')}</label>
							{renderPlaceholderSelect()}
						</div>
					)}
				</div>
			</>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<div className="row">
							<label>{t('standingData:SUBJECT')}</label>
							<div>
								<input
									value={
										props.mailTemplate.localizedSubjects[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleSubjectValueChange}
									className="form-control"
									name="localizedSubjects"
									error-key={'LocalizedBodies'}
								/>
							</div>
						</div>
						{renderTextEditor()}
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
}

export default MailTemplateLanguageInput;
