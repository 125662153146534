import * as api from 'api/recipe/PostRecipeCalculateV1';
import CarbonDioxideValuePerAccessory from 'classes/Recipe/Detail/Calculation/CarbonDioxideValuePerAccessory';

export default class CarbonDioxideValuePerAccessoryList {
	public items: CarbonDioxideValuePerAccessory[] = [];

	public constructor(input?: api.CarbonDioxideValuePerAccessory[]) {
		if (input) this.mapFromApi(input);
	}

	private mapFromApi(input: api.CarbonDioxideValuePerAccessory[]) {
		this.items = [];
		for (const i of input) {
			this.items.push(new CarbonDioxideValuePerAccessory(i));
		}
	}
}
