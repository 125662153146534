import { ContextBuilder } from 'context/nutriscoreLabelContext';

import BaseIngredientSearchComponent from 'components/desktop/BaseIngredient/Search/BaseIngredientSearchComponent';

const BaseIngredientArchiveContainer = () => {
	return (
		<main className="container">
			<BaseIngredientSearchComponent archive={true} />
		</main>
	);
};

export default ContextBuilder.From(BaseIngredientArchiveContainer)
	.wrapWithNutriscoreContext()
	.build();
