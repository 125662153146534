import * as Error from 'types/Validation/Error';
import * as Validation from 'types/Validation/Validation';

export type Type = {
	[key: string]: boolean;
};

export function create(): Type {
	return {};
}

export function updateOne(obj: Type, error: Error.Type, value: boolean) {
	return {
		...obj,
		[error.id]: value,
	};
}

export function updateAll(obj: Type, validation: Validation.Type, value: boolean) {
	const output = { ...obj };
	for (const i of Validation.getAllErrorIds(validation)) {
		output[i] = value;
	}
	return output;
}
