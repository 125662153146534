import { useSelector } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

export const useEntryLanguageCode = (id: string): string => {
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	if (reduxLanguages.filter((x) => x.id === id).length) {
		return reduxLanguages.filter((x) => x.id === id).first().cultureCode;
	}
	return '';
};
