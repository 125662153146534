import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

export interface WithSelectedCultureProps {
	selectedCultureCode: string;
	readOnly: boolean;
}

export const WithSelectedCulture = <T extends WithSelectedCultureProps = WithSelectedCultureProps>(
	WrappedComponent: React.ComponentType<T>
) => {
	const ComponentWithCulture = (props: Omit<T, keyof WithSelectedCultureProps>) => {
		// Available languages
		const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
		// currently selected culture of overall UI
		const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
		const readOnly: boolean = !arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);
		const selectedCultureCode = useMemo(
			() => reduxLanguages.filter((x) => x.cultureCode === reduxCultureCode).firstOrDefault(),
			[reduxCultureCode, reduxLanguages]
		);

		if (selectedCultureCode)
			return (
				<WrappedComponent
					{...(props as T)}
					selectedCultureCode={selectedCultureCode.cultureCode}
					readOnly={readOnly}
				/>
			);

		return <></>;
	};

	return ComponentWithCulture;
};
