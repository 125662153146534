import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';

type Content = {
	content: JSX.Element;
	descriptor: string;
	default: boolean;
};

interface IProps {
	isLoading: boolean;
	content1: Content;
	content2: Content;
}

const NavigationPillsContent = (props: IProps) => {
	return (
		<>
			<div className="tab-content" id="nav-tabContent">
				<LoadingAnimation isLoading={props.isLoading} />
				<div
					className={
						props.content1.default ? 'tab-pane fade show active' : 'tab-pane fade'
					}
					id={'nav-' + props.content1.descriptor}
					role="tabpanel"
					aria-labelledby={'nav-' + props.content1.descriptor + '-tab'}
				>
					{props.content1.content}
				</div>

				<div
					className={
						props.content2.default ? 'tab-pane fade show active' : 'tab-pane fade'
					}
					id={'nav-' + props.content2.descriptor}
					role="tabpanel"
					aria-labelledby={'nav-' + props.content2.descriptor + '-tab'}
				>
					{props.content2.content}
				</div>
			</div>
		</>
	);
};

export default NavigationPillsContent;
