import Archive from 'containers/desktop/StandingData/NutrientCategory/Archive/NutrientCategoryArchive';
import Management from 'containers/desktop/StandingData/NutrientCategory/Management/NutrientCategoryManagement';
import PERMISSIONS from 'enums/permissions';

export const nutrientCategoryRoutes = [
	{
		path: '/nutrientCategory/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
	{
		path: '/nutrientCategory/management',
		element: <Management />,
		permissions: [PERMISSIONS.ADMINISTRATE],
	},
];
