import axios from 'axios';

import { ConstraintStatus } from 'enums/ConstraintStatus';
import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { AllergenLogic } from 'enums/allergenLogic';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function apiCall(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/MenuPlanner/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	id: string;
	systemStatus: SystemStatus;
	status: Status;
	caloriesLimit: number | null;
	priceLimit: number | null;
	isPriceConstraintMet: ConstraintStatus;
	isCaloriesConstraintMet: ConstraintStatus;
	carbonDioxideValue: number | null;
	startDate: string;
	durationDays: number;
	carbonDioxideLabel: CarbonDioxideLabel;
	titleTranslations: Translation | null;
	rows: Row[] | null;
	seasons: Season[] | null;
};

export type CarbonDioxideLabel = {
	label: string | null;
	color: string | null;
};

export type Status = {
	id: string;
	color: string | null;
	order: number;
	name: string | null;
};

export type Translation = {
	[key: string]: string;
};

export type Row = {
	id: string;
	isPriceConstraintMet: boolean;
	isCaloriesConstraintMet: boolean;
	caloriesSum: number | null;
	priceSum: number | null;
	carbonDioxideValueSum: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	nameTranslations: Translation | null;
	items: Item[] | null;
	tags: Tag[] | null;
	nutrients: Nutrient[] | null;
	allergens: Allergen[] | null;
};

export type Item = {
	id: string;
	type: MENUPLANNERITEMTYPE;
	day: number;
	images: Image[];
	titleTranslations: Translation | null;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string | null;
	tagCategory: TagCategory;
	source: string | null;
};

export type TagCategory = {
	id: string;
	name: string | null;
};

export type Nutrient = {
	nutrientId: string;
	nutrient: string | null;
	categoryId: string;
	category: string | null;
	total: number;
	unitId: string;
	unit: string | null;
	isMacroNutrient: boolean;
	nutrientCategorySortOrder: number;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	logic: AllergenLogic;
	source: string | null;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string | null;
	display: boolean;
	associatedMonthsFlag: number | null;
	source: string | null;
};
