import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import { WithSelectedCulture } from 'components/desktop/Hocs/WithSelectedCulture';
import { ToolTipCell } from 'components/desktop/Recipe/RecipeTabs/Components/IngredientTooltip/IngredientTooltip';
import LinkCell from 'components/desktop/Recipe/RecipeTabs/Components/LinkCell/LinkCell';
import {
	NutrientFilterSearchComponentWrapper,
	co2ValueKey,
	ingredientAmountKey,
	ingredientNameKey,
	ingredientUnitKey,
	nutrientColumnClassName,
	priceKey,
} from 'components/desktop/Recipe/RecipeTabs/Components/NutrientFilterSearchComponentWrapper';
import NutrientSearchFilter from 'components/desktop/Recipe/RecipeTabs/Components/NutrientSearchFilter/NutrientSearchFilter';
import RecipeScaling from 'components/desktop/Recipe/RecipeTabs/Components/RecipeScaling/RecipeScaling';
import {
	FilterableColumnInfo,
	Translation,
	calculateSegmentLengths,
	enrichIngredients,
	flattenSegments,
	mapToTableData,
} from 'components/desktop/Recipe/RecipeTabs/recipeComputations';
import { AirTable } from 'components/desktop/_general/AirTable/AirTable';
import ENTITLEMENTS from 'enums/entitlements';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { RefreshType } from 'hooks/useRefresh';
import { RootState } from 'reducers/rootReducer';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { Segment } from 'types/Recipe/Segment';

export const createNutrientColumns = (t: Translation, currency: string) => {
	const defaultColumns = [
		{ columnName: t('_general:AMOUNT'), key: ingredientAmountKey, numeric: true, sticky: true },
		{ columnName: t('_general:UNIT'), key: ingredientUnitKey, sticky: true, show: true },
		{ columnName: t('_general:INGREDIENT'), key: ingredientNameKey, sticky: true, show: true },
		...(areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE])
			? [
					{
						columnName: `${t('recipe:PRICE')} (${currency})`,
						key: priceKey,
						numeric: true,
						sticky: true,
						className: nutrientColumnClassName,
					},
			  ]
			: []),
		{
			columnName: t('_general:CO2'),
			key: co2ValueKey,
			numeric: true,
			sticky: false,
			className: nutrientColumnClassName,
		},
	];

	return defaultColumns;
};

const calculateColumnsToDisplay = (
	t: Translation,
	currency: string,
	ingredientNutrientsTableColumnKeys: FilterableColumnInfo[]
) => {
	const fixedColumns = createNutrientColumns(t, currency);
	const [kcal, ...rest] = ingredientNutrientsTableColumnKeys.map((x) => ({
		...x,
		numeric: true,
		className: nutrientColumnClassName,
	}));
	const co2 = fixedColumns.last();
	const fixedColumnsButLast = fixedColumns.butlast();
	const remainingColumns = [...rest];

	return [
		...fixedColumnsButLast,
		{ ...kcal, className: nutrientColumnClassName, sticky: true },
		co2,
		...remainingColumns.map((x) => ({
			...x,
			numeric: true,
			className: nutrientColumnClassName,
		})),
	];
};

interface IProps {
	calculation: Calculation;
	incorporationShares: IncorporationShareType[];
	quantityMethod: string;
	readOnly: boolean;
	refresh: RefreshType;
	scaledParts: number;
	segments: Segment[];
	selectedCultureCode: string;
	setScaledParts: (scaledParts: number) => void;
}

const ScalingPriceNutrientCo2View = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const [nutrientsSaved, setNutrientsSaved] = useState<Nutrient[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [element, setElement] = useState<JSX.Element>(<></>);

	const ingredientNutrientsTableColumnKeys = props.calculation.getTableColumnKeys(
		searchTerm,
		nutrientsSaved
	);

	const flatIngredients = flattenSegments(props.segments);

	const joinedIngredients = enrichIngredients(
		props.calculation,
		props.incorporationShares,
		flatIngredients
	);

	const segmentLengths = calculateSegmentLengths(props.segments);

	const tableData = joinedIngredients.map((ingredient) => ({
		...mapToTableData(
			ingredient,
			ingredientNutrientsTableColumnKeys,
			props.selectedCultureCode,
			props.quantityMethod
		),
		customCell: {
			ingredientName: (
				<ToolTipCell
					refresh={props.refresh}
					ingredient={ingredient.ingredient}
					setElement={setElement}
					lastCell={segmentLengths.includes(ingredient.ingredientIndex + 1)}
				/>
			),
			ingredientUnit: (
				<LinkCell
					link={
						ingredient.ingredient.unitId
							? `/unit/management?id=${ingredient.ingredient.unitId}`
							: '/unit/management'
					}
					title={ingredient.ingredient.unit}
					id={ingredient.ingredient.unitId}
					lastCell={segmentLengths.includes(ingredient.index + 1)}
				/>
			),
		},
	}));

	const columns = calculateColumnsToDisplay(t, reduxCurrency, ingredientNutrientsTableColumnKeys);

	return (
		<>
			{element}
			<div className="row">
				<div className="col-1" style={{ paddingRight: '0' }}>
					<RecipeScaling
						scaledParts={props.scaledParts}
						setScaledParts={props.setScaledParts}
					/>
				</div>
				<div className="col-11">
					<NutrientFilterSearchComponentWrapper>
						<NutrientSearchFilter
							searchTerm={searchTerm}
							onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
							nutrientsSaved={nutrientsSaved}
							onChangeFilter={(e) => setNutrientsSaved(e)}
						/>
					</NutrientFilterSearchComponentWrapper>
				</div>
			</div>

			<AirTable
				columns={columns}
				data={tableData}
				dividers={segmentLengths}
				readOnly={props.readOnly}
			></AirTable>
		</>
	);
};

export default WithSelectedCulture(ScalingPriceNutrientCo2View);
