import { Icon } from 'types/Icon/Icon';

interface IProps {
	icon: Icon;
	width?: string;
	height?: string;
}

const IconComponent = (props: IProps) => {
	const renderContent = () => {
		return (
			<>
				<div style={{ display: 'flex' }}>
					<div style={{ position: 'relative' }}>
						<img
							src={props.icon.iconSvgUrl ?? ''}
							style={{ width: props.width ?? '36px', height: props.height ?? '36px' }}
						/>
						<div
							data-bs-toggle="tooltip"
							data-bs-placement="bottom"
							title={props.icon.name}
							style={{ width: '36px', height: '36px', position: 'absolute', top: 0 }}
						></div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default IconComponent;
