import { useTranslation } from 'react-i18next';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import 'components/desktop/_general/Co2Select/Co2Select.scss';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';
import * as Co2Label from 'types/Product/Search/Co2Label';

interface IProps {
	carbonDioxideLabels: Co2Label.Type[];
	activeIds: string[];
	setActiveId: (items: string) => void;
}

const Co2Select = (props: IProps) => {
	const { t } = useTranslation();

	const renderLabel = (input: ResponseCarbonDioxideLabel, key: number): JSX.Element => {
		if (props.activeIds.includes(input.id)) {
			return (
				<div
					className="carbon-dioxide-select-item"
					onClick={() => props.setActiveId(input.id)}
					key={key}
				>
					<IconCo2Round color={input.color} label={input.label} />
				</div>
			);
		} else {
			return (
				<div
					className="carbon-dioxide-select-item"
					onClick={() => props.setActiveId(input.id)}
					key={key}
				>
					<IconCo2Round color={input.color} label={input.label} opacity={0.3} />
				</div>
			);
		}
	};

	return (
		<div className="carbon-dioxide-select-container-outer">
			<label>{t('_general:CARBON_DIOXIDE_LABEL')}</label>
			<div className="carbon-dioxide-select-container-inner">
				{props.carbonDioxideLabels.map((c: ResponseCarbonDioxideLabel, i: number) =>
					renderLabel(c, i)
				)}
			</div>
		</div>
	);
};

export default Co2Select;
